/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import PermissionTable from "./permissionTable";
// import EditPermissions from "./editPermissions";
import {useState} from "react";
import EditPermissions from "./editPermissions";
import { useCreateOrUpdateRoleMutation, useGetRolePermissionsMutation, useGetRolesMutation, useGetUserPermissionsMutation, useUpdateRolePermissionsMutation } from "../../../services/reportApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { LoadingIndicator } from "../../../components/loading-indicator";
import PrimaryButton from "../../../components/primaryButton";
import Popup from "reactjs-popup";
import { FormInput } from "../../../components/formInput";
import { useRef } from "react";


const UserPermissions = ({userRoleId="", companyUpdateUserPermissions, companyReadUserPermissions}) => {
  const [ isEdit, setIsEdit ] = useState(false);
  const [ isEditForm, setIsEditForm ] = useState(false);
  const [ roleName, setRoleName ] = useState();
  const [ roleId, setRoleId ] = useState();
  const ignore = useRef(false);
  useEffect(() => {
    if (!ignore.current){
    getRoles();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
  const [ getRoles, {data:roles, isError, isLoading, error:rolesError} ] = useGetRolesMutation();
  const [ updateRolePermissions, { isSuccess:isRoleSuccess, isError:isRoleError, isLoading:isRoleLoading, error:rolesUpdateError} ] = useUpdateRolePermissionsMutation();
  const [ createOrUpdateRole, { isSuccess:isCreateSuccess, isError:isCreateError, isLoading:isCreateLoading, error:rolesCreateError } ] = useCreateOrUpdateRoleMutation();
  // const [ deleteRole ] = useDeleteRoleMutation();
  const [ getUserPermissions, { data:userPermissions, isSuccess} ] = useGetUserPermissionsMutation();
  const [ getRolePermissions, {data:rolePermissions, isSuccess:isPermissionsSuccess, isError: isPermissionsError, isLoading: isPermissionsLoading, error: permissionError} ] = useGetRolePermissionsMutation();
  const [ formData, setFormData ] = useState({
    role_name:""
  });
  
  const [ open, setOpen ] = useState(false);
  const [ error, setError ] = useState({
    roleError:""
  });
  
  useEffect(() => {
    if (isError) {
      const toastMessage = rolesError?.data?.message
        ? rolesError?.data?.message: "Something went wrong";
        toast.error( {
          render: toastMessage,
               type: "error",
               autoClose: 2000,
             isLoading: false
        });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isError ]); 
  const closeModal = () => {
    setOpen(false);
  };
  const toastId = useRef();
  useEffect(() => {
    if (isCreateLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      autoClose: 2000,
      theme: "colored",
      type: "info"
    });
    }
   
    if (isCreateSuccess) {
      toast.update(
        toastId.current,
        ("Role created successfully!",
        {
          render: "Role created successfully!",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
      document?.getElementById("closePopup").click();
      getRoles();
    }
    if (isCreateError) {
      const toastMessage = rolesCreateError.data.message
        ? rolesCreateError.data.message
        : "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 2000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isCreateSuccess, isCreateError, isCreateLoading ]);

  const handlerChange = (e) => {
    e.preventDefault();
    setFormData((formValue) => { 
      return {
        ...formValue,
        [name] :e.target.value
      };
    });
    const {name} = e.target;
    if(name === 'role_name') {
      if(name === '') {
      setError({...error, ['roleError']: 'Please enter the role name'});
      } else {
        setError({...error, ['roleError']: ''});
      }
    } 
  };
  const validForm = () => {
    const { role_name } = formData;
    let isFormValidated = true;
    if(role_name === '') {
      setError((errorValue) => {
        return {
          errorValue,
          roleError  : 'Please enter the role name '
        };
    });
    isFormValidated = false;
    } 
    return isFormValidated;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if(validForm()){
      const value = {
        "role_name" : formData?.role_name,
        "id":roles?.id
      };
      createOrUpdateRole(value);
      
    }
  };



  return (
     isLoading ? <LoadingIndicator/> :
    <>
      <div className="heading_title px-4 flex justify-between items-center ">
        <h3 className=" font-semibold text-lg my-4 mb-5">User Permissions</h3>
        {/* The 'Add Role' functionality is unnecessary; thus, it has been hidden by updating the logic. */}
        {userRoleId == 100 && (
        <Popup
            open={open}
            onClose={() => closeModal()}
            closeOnDocumentClick={false}
            trigger={
                <button
                    id="closePopup"
                    className="btn-md btn-add"
                    onClick={() => setOpen((o) => !o)}
                >
                    <span className="fa fa-plus mr-2" />
                    Add Role
                </button>
            }
            modal
            nested
        >
            {(close) => (
                <div className="modal">
                    <button
                        className="close"
                        id="addRoleClose"
                        onClick={() => {
                            closeModal();
                            close();
                        }}
                    >
                        &times;
                    </button>
                    <div className="header">
                        {" "}
                        <h3>Add Role</h3>
                    </div>
                    <div className="content">
                        <form onSubmit={(e) => handleFormSubmit(e)}>
                            <div className="flex flex-wrap ">
                                <div className="w-full md:w-1/2 md:pr-6">
                                    <div className="form-group mb-6">
                                        <label className="formBlock">
                                            Role Name{" "}
                                            <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                        </label>
                                        <FormInput
                                            inputType="text"
                                            inputName="role_name"
                                            inputID="role_name"
                                            handlerChange={handlerChange}
                                        />
                                        <div className="help-block text-red-700">
                                            {error.roleError ? error.roleError : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full mt-4">
                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                        <PrimaryButton
                                            Btnclass="btn save mr-2"
                                            btnText="Save"
                                            id="addRoleSave"
                                            onClick={(e) => handleFormSubmit(e, false, "")}
                                        />
                                        <PrimaryButton
                                            Btnclass="btn cancel mr-2"
                                            btnText="Cancel"
                                            id="AddRoleCancel"
                                            onClick={() => close()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
)}

      </div>
      
      <div className="p-4">
      <EditPermissions
       rolePermissions={rolePermissions?.permissions}
       setIsEdit={setIsEdit}
        isEdit={isEdit}
       isEditForm={isEditForm}
       roleName={roleName}
       isPermissionsLoading={isPermissionsLoading}
       updateRolePermissions={updateRolePermissions}
       isRoleError={isRoleError}
       isRoleLoading={isRoleLoading}
       rolesUpdateError={rolesUpdateError}
       isRoleSuccess={isRoleSuccess}
       getUserPermissions={getUserPermissions}
       userPermissions={userPermissions}
       isSuccess={isSuccess}
       roleId={roleId}
       userRoleId={userRoleId}
       companyReadUserPermissions={companyReadUserPermissions}
       companyUpdateUserPermissions={companyUpdateUserPermissions}
         />
        <div className="overflow-x-auto max-h-screen">
         <PermissionTable 
         setIsEdit={setIsEdit}
          roles={roles?.roles}
          setRoleId={setRoleId}
          setRoleName={setRoleName}
          isEditForm ={isEditForm} 
          setIsEditForm={setIsEditForm}
          getRolePermissions={getRolePermissions} 
          isPermissionsError={isPermissionsError}
          isPermissionsSuccess={isPermissionsSuccess}
          permissionError={permissionError}
           />
       
        </div>
      </div>
    </> 
  );
};

export default UserPermissions;
