/* eslint-disable no-unused-vars */

import React from "react";
import { Link } from "react-router-dom";
import moment, * as moments from "moment";

export function EmployeeAnniversariesThisWeek({ thisWeekAnniversaries, tableNames }) {
  return (
    <div className="overflow-x-auto w-full whitespace-nowrap">
      <table className="table-auto table  w-full ">
        <thead className="thead-dark ">
          <tr>
            {tableNames?.map((name, index) => {
              return (<th key={index} className="w-50">{name}</th>);
            })}
          </tr>
        </thead>
        <tbody>
          {thisWeekAnniversaries?.length>0 ?
            thisWeekAnniversaries.map((thisWeek, index) => {

              let fullname = thisWeek?.first_name+' '+thisWeek?.last_name;
              return (
                <tr key={index}>
                  <td>
                      {fullname}
                  </td>
                  <td>
                    {moment(thisWeek?.employment_start_date).isValid()
                      ? moment(thisWeek?.employment_start_date).format("DD MMM")
                      : 'N/A'}
                  </td>
                  <td>
                    {thisWeek?.years_of_employment}
                  </td>
                </tr>
              );
            })
          :
          <tr>
            <td colSpan="12" className="text-center mt-3 text-red-700 "> 
            <div className=" flex justify-center items-center  ">
            <p className="mt-5 pt-2">
            No employee work anniversaries this week.
            </p>
            </div>

            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  );
}
