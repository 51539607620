import { useState} from "react";
import Popup from "reactjs-popup";
//import { Link } from "react-router-dom";
import Select from "react-select";
const LeaveYear = () => {
    const [ open, setOpen ] = useState(false);
    const closeModal = () => {
      setOpen(false);
    };

    const options = [
      { value: 'Sahion', label: 'Sahion' },
      { value: 'Virat', label: 'Virat' },
      { value: 'Yuvi', label: 'Virat' }
    ];
  return (
   <>
 <div className="heading_title px-4 flex justify-between items-center py-4">
      <div>
      <h3 className=" font-semibold text-lg">Employees</h3>    
      </div>
        <Popup
          open={open}
          onClose={() => closeModal()}
          closeOnDocumentClick={false}
          trigger={
            <button
              className="btn-md btn-add"
              onClick={() => setOpen((o) => !o)}
            >
              <span className="fa fa-plus mr-2" />Assign Employees 
            </button>
          }
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <button
                className="close"
                id="assignEmployeesClose "
                onClick={() => {
                  closeModal();
                  close();
                }}
              >
                &times;
              </button>
              <div className="header">
                                  {" "}
                                  <h3>Assign Employee</h3>
                                </div>
                                <div className="content">
                                  <form>
                                    <div className="flex flex-wrap ">
                                      <div className="w-full md:w-1/3 md:pr-6 ">
                                        <div className="form-group mb-6">
                                          <label className="formBlock	">
                                          Employes Name
                                          </label>
                                        <select className="formControl" id="assignEmployeesName">
                                          <option>
                                          Sahion
                                          </option>
                                          <option>
                                          Virat
                                          </option>
                                        </select>
                                          <div className="help-block" />
                                        </div>
                                      </div>

                                      <div className="w-full md:w-1/6 md:pr-6 text-center ">
                                      <span className="fa fa-angle-right text-5xl mt-5 pt-2 primary-text assignIcon" />
                                      </div>


                                      <div className="w-full md:w-1/2 md:pr-0 ">
                                        <div className="form-group mb-6">
                                          <label className="formBlock	">
                                          Assign Employees
                                          </label>
                                          <Select
                                          id="assignEmployees"
                                          isMulti
                                           options={options} />
                                          <div className="help-block" />
                                        </div>
                                      </div>
                                      <div className="w-full mt-4">
                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                          <button className="btn save mr-2" id="assignEmployeesSave">
                                            Save
                                          </button>
                                          <button
                                            type="button"
                                            id="assignEmployeesCancel"
                                            onClick={() => close()}
                                            className="btn cancel"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                   
                                    </div>
                                  </form>
                                </div>
              
            </div>
          )}
        </Popup>
      </div>
      <div className="p-4">
      <div className="overflow-x-auto w-full whitespace-nowrap">
                  <table className="table-auto table  w-full">
                    <thead className="thead-dark ">
                      <tr>
                        <th className="w-40">Employee Name</th>
                        <th>Assign Employee</th>                    
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                       
                        <td>Sahion Kumar</td>
                        <td>Rohit, Mohit</td>
                        

                        <td>
                        <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <div
                                onClick={() => setOpen((o) => !o)}
                                className="actionBtn"
                              >
                                <i className="fa fa-edit" />
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="editAssignEmployeeClose"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <div className="header">
                                  {" "}
                                  <h3>Edit Assign Employee</h3>
                                </div>
                                <div className="content">
                                <form>
                                    <div className="flex flex-wrap ">
                                      <div className="w-full md:w-1/3 md:pr-6 ">
                                        <div className="form-group mb-6">
                                          <label className="formBlock	">
                                          Employee Name
                                          </label>
                                        <select className="formControl" id="editAssignEmployeeName">
                                          <option>
                                          Sahion
                                          </option>
                                          <option>
                                          Virat
                                          </option>
                                        </select>
                                          <div className="help-block" />
                                        </div>
                                      </div>

                                      <div className="w-full md:w-1/6 md:pr-6 text-center ">
                                      <span className="fa fa-angle-right text-5xl mt-5 pt-2 primary-text assignIcon" />
                                      </div>


                                      <div className="w-full md:w-1/2 md:pr-0 ">
                                        <div className="form-group mb-6">
                                          <label className="formBlock	">
                                          Assign Employees
                                          </label>
                                          <Select
                                          isMulti
                                          id="editAssignEmployees"
                                           options={options} />
                                          <div className="help-block" />
                                        </div>
                                      </div>
                                      <div className="w-full mt-4">
                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                          <button className="btn save mr-2" id="editAssignEmployeeSave">
                                            Save
                                          </button>
                                          <button
                                            type="button"
                                            id="editAssignEmployeeCancel"
                                            onClick={() => close()}
                                            className="btn cancel"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                   
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </Popup>
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <div
                                onClick={() => setOpen((o) => !o)}
                                className="actionBtn cancelBtn"
                              >
                                <i className="fa fa-trash" />
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                id="deleteAssignEmployeeClose"
                                  className="close"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <div className="header">
                                  {" "}
                                  <h3>Delete</h3>
                                </div>
                                <div className="content">
                                  <p>
                                    Are you sure you want to delete Departments?
                                  </p>
                                  <div className="w-full mt-8">
                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                      <button className="btn save mr-2" id="deleteAssignEmployeeYes">
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        id="deleteAssignEmployeeNo"
                                        onClick={() => close()}
                                        className="btn cancel"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Popup>
                         
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
      </div>
   </>
  );
};

export default LeaveYear;