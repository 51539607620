/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger */
import React, {useState, useEffect, useRef}  from 'react';
import {
  FormInput,
  FormSelect,
  FormTextarea
} from "../../components/formInput";
import PrimaryButton from "../../components/primaryButton";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Popup from "reactjs-popup";
import { useCreateMainExpensesMutation} from '../../services/expenseApi';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);


const AddMileageExpense = ({allExpenseType, getAllMainExpenses, close, getAllMainExpensesCSVRecord}) => {
  const ignore = useRef(false);  
  const [ createMainExpense, {isSuccess: isSuccessCreate, isLoading: isLoadingCreate, isError: isErrorCreate, error:apiError } ] =  useCreateMainExpensesMutation();
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
    const  toastGeneralId = useRef(null);
    const [ amountOfMile, setAmountOfMile ] = useState();
    const [ fileList, setFileList ] = useState([]);
    const [ perMile, setPerMile ] = useState();
    const [ disable, setDisable ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ isButtonDisabled, setIsButtonDisabled ] = useState(false);
    const closeModal = () => {
      setOpen(false);
    };
    const [ formData, setFormData ] = useState({
       
        "company_id": "",
        "employee_id": employeeIdData?.employee_id,
        "start_date": new Date(),
        "start_time": "",
        "end_time": "00",
        "end_date": new Date(),
        "reason": "",
        "is_mileage": "1",
        "type": "",
        "amount": "",
        "amount_of_miles":"",
        "notes": "notes",
        "bill": "bill",
      
        "status": "0"
        
    });
    const [ error, setError ] = useState({
      amountError :'',
      typeError: '',
      reasonError: '',
      errorEndDate: ''
    });
    
    useEffect(() => {
      if (!ignore.current){
      setStartDate(new Date());
    }
    return () => { ignore.current = true; };
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

    const handlerChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        if (name === 'type') {
          if(name === '') {
            setError({...error, ['typeError'] : 'Please select type'});
          } else {
            setError({...error, ['typeError'] : ''});
            let per_Mile = allExpenseType?.find((f) => f.id == e.target.value );
            setPerMile(per_Mile?.pence_per_mile);
        
            let amountMiles = ((formData?.amount* per_Mile?.pence_per_mile)/100).toFixed(2);

            setAmountOfMile(amountMiles);
          }
        } else if (name === 'amount') {
          if(name === '') {
            setError({...error, ['amountError'] : 'Please enter the amount'});
          }
          else if (name === 'amount' && parseFloat(value) < 0) {
            setError((prevError) => ({
                ...prevError,
                amountError: 'Amount must be greater than zero'
            }));
          }
          else {
            setError({...error, ['amountError'] : ''});
             let amountMiles = ((e.target.value * perMile)/100).toFixed(2);
             setAmountOfMile(amountMiles);
            
          }
        }
        // else if (name === 'reason') {
        //   if(name === '') {
        //     setError({...error, ['reasonError'] : 'Please enter the reason'});
        //   } else {
        //     setError({...error, ['reasonError'] : ''});
        //   }
        // }
         setFormData({...formData, [name] : e.target.value});
    };

    const handlerDateChange = (e, name) => {
     
        if(name === 'start_date') {  
            setFormData((formValue) => {
            return {
             ...formValue, start_date: e
            };
           }); 
            setStartDate(e);
            setFormData((formValue) => {
             return {
              ...formValue, end_date: e
             };
            }); 
            setEndDate(e);
         } else {
          let checkEndDate = moment(moment(startDate).format('YYYY-MM-DD')).isSameOrBefore(moment(e).format('YYYY-MM-DD'));
          if(checkEndDate)  {
          setFormData((formValue) => {
             return {
              ...formValue, end_date: e
             };
            }); 
            setEndDate(e);
           } else {
            setError((errorValue) => {
              return {
                ...errorValue,
                errorEndDate : 'Please Select greater date than start date  '
              };
          });
           }
         }
          
    };

    const handleUpload = (e) => {
      setFileList([ ...e.target.files ]);
    };

    const handlerSingleDate = (e) => {
     if(e.target.checked) {
       setDisable(e.target.checked);
     } else {
      setDisable(e.target.checked);
     }
    };

    const validForm = () => {
      const {amount, type, reason } = formData;
      let isFormValid = true ;
      if(amount === '' || amount === '0') {
          setError((errorValue) => { 
          return {
            ...errorValue,
            amountError: 'Please enter amount (greater than zero) '
          };
        });
        isFormValid = false;
      } 
      if(type === '' || type === '0') {
          setError((errorValue) => { 
          return {
            ...errorValue,
            typeError: 'Please select type '
          };
        });
        isFormValid = false;
      } 
      // if(reason.trim() === '') {
      //     setError((errorValue) => { 
      //     return {
      //       ...errorValue,
      //       reasonError: 'Please enter description '
      //     };
      //   });
      //   isFormValid = false;
      // } 
      return isFormValid;
    };
    useEffect(() => {
      if (isLoadingCreate) {
        toastGeneralId.current = toast.loading("Please wait...", {
        // position: "top-center",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
      }
      if (isSuccessCreate) {
        setTimeout(() => {
          toast.dismiss(toastGeneralId.current);
        }, 10000);
        toast.update(
          toastGeneralId.current,
          (" Expense added successfully.",
          {
            render: "Expense added successfully.",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
        close();
        let payload = `/expenses/filter/search?page=${1}&employee_id=${employeeIdData?.employee_id}`;
          getAllMainExpenses(payload);
          let payload1=`/expenses/filter/search?page=${1}&employee_id=${employeeIdData?.employee_id}&limit=100`;
          getAllMainExpensesCSVRecord(payload1);
      }
      if (isErrorCreate) {
        setTimeout(() => {
          toast.dismiss(toastGeneralId.current);
        }, 10000);
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.update(
          toastGeneralId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessCreate, isErrorCreate, isLoadingCreate ]);
    const handlerSubmit = (e) => {
        e.preventDefault();
        if(validForm()) {
          // Disable the button
          setIsButtonDisabled(true);
          var formdata = new FormData();
          formdata.append("employee_id", employeeIdData?.employee_id);
          formdata.append("start_date", formData?.start_date);
          if(employeeIdData?.department_id !== null){
            formdata.append("department_id", employeeIdData?.department_id);
            }
          // expenses_type:'',
          formdata.append("end_date", formData?.end_date);
          formdata.append("reason", formData?.reason);
          formdata.append("is_mileage", formData?.is_mileage);
          formdata.append("type", formData?.type);
          formdata.append("amount", formData?.amount);
          formdata.append("start_time", formData?.start_time);
          formdata.append("end_time", formData?.end_time);
       
      
          formdata.append("amount_of_miles", amountOfMile);
          formdata.append("notes", formData?.notes);
          formdata.append("bill", formData?.bill);
      
          formdata.append("status", formData?.status);
          fileList.forEach((file) => {
            formdata.append("evidence_file", file);
          });
            createMainExpense(formdata);
          // After 5 seconds, enable the button again
          setTimeout(() => {
          setIsButtonDisabled(false);
          }, 5000);
        }
    };

    const  handlerDelete = (e, fileName, close) => {
      e.preventDefault();
      const removeFile = fileList?.filter((f) => f.name !== fileName?.name);
      setFileList(removeFile);
      close();
    };
    return(
        <>
         <div className="header">
                            <h3>Claim Mileage </h3>
                          </div>
                          <div className="content">
                            <form>
                              <div className="flex flex-wrap ">
                                <div className=" w-full">
                                  <div className="form-group mb-2 ">
                                    <div className="flex">
                                      <div className="w-full pr-4">
                                        <label className="formBlock	">
                                          Date {!disable ? 'from' : ''}
                                        </label>
                                        <DatePicker
                                            // showIcon
                                            dateFormat="dd/MM/yyyy"
                                            id="mileageStartDate"
                                            className="formControl"
                                            name = "start_date"
                                            selected = {startDate? startDate : new Date()}
                                            onChange={(e) =>  handlerDateChange(e, "start_date")}
                                            maxDate={new Date()}
                                            locale="en-GB"
                                       
                                      />
                                      </div>
                                      {!disable &&(<div className="w-full ">
                                        <label className="formBlock	">
                                          Date to
                                        </label>
                                        <DatePicker
                                            // showIcon
                                            dateFormat="dd/MM/yyyy"
                                            id="mileageEndDate"
                                            className="formControl"
                                            name = "end_date"
                                            selected = {endDate ? endDate : new Date()}
                                            onChange={(e) =>  handlerDateChange(e, "end_date")}
                                            maxDate={disable ? startDate : new Date()}
                                            minDate = {disable ? startDate : moment().subtract(500, "years")}
                                            locale="en-GB"
                                        />
                                      </div>)}
                                    </div>

                                    <div className="help-block text-red-700 mt-1">{error?.errorEndDate ? error?.errorEndDate :'' } </div>
                                  </div>
                                  <div className="form-group mb-6  flex">
                                    <label className="formBlock flex mr-2	">
                                      <input
                                        type="checkbox"
                                        id="applySingleDate"
                                        name="  break"
                                        className="mr-2 w-5 h-5"
                                        onChange={(e) => handlerSingleDate(e)}
                                      />
                                      Apply single date?
                                    </label>
                                  </div>
                                </div>
                                <div className="md:w-1/3 md:pr-6 w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Mileage Type
                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                    </label>
                                    <select className="formControl" id="mileageType" name='type' onChange={(e) => handlerChange(e)}>
                                    <FormSelect 
                                    optionValue={"0"}
                                    optionName={"Select Mileage"}
                                    />
                                    {allExpenseType?.length > 0 ? allExpenseType?.map((exp, key) => { 
                                        return   <FormSelect key={key} optionValue={exp?.id} optionName={exp?.expense_name} />;
                                    }) : null}
                                    </select>

                                    <div className="help-block"> 
                                    <div className="help-block text-red-700 mt-1"> {error.typeError ? error.typeError :''} </div> 
                                    </div>
                                  </div>
                                </div>
                                <div className="md:w-1/3 md:pr-6 w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Amount of Miles
                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                    </label>
                                    <FormInput
                                      inputType="number"
                                      inputName="amount"
                                      inputID="AmountMiles"
                                      handlerChange={handlerChange}
                                    />

                                    <div className="help-block text-red-700 mt-1"> {error.amountError ? error.amountError :''} </div>
                                  </div>
                                </div>
                                <div className="md:w-1/3 md:pr-0 w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">£</label>
                                    <FormInput
                                      inputType="number"
                                      inputName="amount_of_miles"
                                      inputID="AmountMiles"
                                      value= {amountOfMile}
                                      handlerChange={handlerChange}
                                    />

                                    <div className="help-block" />
                                  </div>
                                </div>

                                <div className="w-full ">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Description
                                      {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                                    </label>
                                    <FormTextarea textName="reason" handlerChange = {handlerChange} inputID="mileageDescription" />
                                    {/* <div className="help-block text-red-700"> {error.reasonError ? error.reasonError :''} </div> */}
                                  </div>
                                </div>
                                <div className="w-full">
                                  <div className="form-group mb-6 fileUploadInPopup ">
                                    <label className="formBlock	">
                                      Upload Evidence{" "}
                                      <span className="text-[11px]">
                                        (Supported formats: JPEG, PNG, PDF,
                                        Word, PPT, CSV)
                                      </span>
                                    </label>
                                    {/* <FileUploadBtn /> */}
                                    <div className='file_Upload'>
                                    <input 
                                      type="file"
                                      name="file" 
                                      className= "upload-button"
                                      id="exampleFile" 
                                      onChange={handleUpload} multiple
                                      />
                                      <p>Drop files here or click here to upload file.</p>
                                  </div>
                                    <span>Maximum upload file size:25 MB.</span>
                                    <div className="help-block" />
                                  </div>
                                  <div className="w-full ">
              <div className="form-group mb-6 ">
                 <ul>
                  <>
                  { [ ...fileList ]?.map((f, index) => {
                    return(
                      <li key={index}>{f.name}
                      <span>
                      <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-trash"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="mileageClose"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Delete</h3>
                      </div>
                      <div className="content">
                        <p>Are you sure you want to delete file ?</p>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" id="mileageDeleteYes" onClick={(e) => handlerDelete(e, f, close)}>Yes</button>
                            <button
                              type="button"
                              id="mileageDeleteNo"
                              onClick={() => close()}
                              className="btn cancel  "
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                      </Popup> 
                      </span>
                      </li>
                    );
                  })}

                
                </>
                </ul> 
                </div></div>
                                </div>
                                <div className="lg:w w-full  ">
                                  <div className="w-full mt-4">
                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                     
                                       <button
                                        className="btn save mr-2"
                                        id="mileageDeleteSave"
                                        disabled={isButtonDisabled} // Disable the button based on state
                                        onClick={(e) => handlerSubmit(e)}
                                      >Save</button>
                                      <PrimaryButton
                                        btnText="Cancel"
                                        onClick={() => close()}
                                        Btnclass="btn cancel"
                                        btntype="button"
                                        buttonID="mileageDeleteCancel"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
        </>
    );
};

export default AddMileageExpense;