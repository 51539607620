/* eslint-disable */
/* eslint-disable no-debugger*/
import { Route, Outlet, Navigate } from "react-router-dom";
import { authToken } from "../helper/helper";
import Login from "../components/login";
import { Layout } from "../components/layout";
import MasterRoutes from "./masterRoutes";
import SignUp from "../components/signup";
import Dashboard from "../pages/dashboard/dashboard";
import Settings from "../pages/settings/settings";
import Employees from "../pages/Employees/employees";
import EmployeeDetails from "../pages/Employees/employeesDetails";
import Approvals from "../pages/approvals/approvals";
import Absense from "../pages/absense/absense";
import CalendarView from "../pages/myleave/calendarView";
import Report from "../pages/report/report";
import Leave from "../pages/myleave/myleave";
import Expense from "../pages/myexpense/myexpense";
import MyOverTime from "../pages/myovertime/myOvertime";
import ForgotPassword from "../components/forgotPassword";
import ResetPassword from "../components/resetPassword";
import EmployeeInfoReport from "../pages/report/employeeInfo";
import ExpenseReport from "../pages/report/expense";
import OvertimeReport from "../pages/report/overtime";
import LeaveReport from "../pages/report/leaveReport";
import Timeinlieu from "../pages/report/timeinlieu";
import AbsenceReport from "../pages/report/absence";
import ApproverReport from "../pages/report/approver";
import CompanyMessagesReport from "../pages/report/companyMessage";
import Notification from "../pages/notificaion/notification";
import { useSelector } from "react-redux";
import CompanyList from "../pages/superAdmin/company/company";
import AssignTicket from "../pages/superAdmin/assignTicket/assignTicket";
import AssignTicketTeam from "../pages/superAdmin/assignTicketTeam/assignTicketTeam";
import BillingPage from "../pages/settings/billingPage";
import ResetPasswordAtFirstLogin from "./resetPasswordAtFirstLogin";
import Terms from "../components/Terms";
import AllowanceOverview from "../pages/report/Allowance overview";
import CompanyReport from "../pages/report/companyReport";
import AuditorLogin from "../components/auditorLogin";
import AuditorCompanySelect from "../components/auditorCompanySelect";


const ProtectedRoute = ({ redirectPath = '/' }) => {
    if (!authToken()) {
        return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
};

const RedirectRoute = () => {
    return <Outlet />;
};

const routePath = [
    { name: "redirectroute", path: "/", element: <Login /> },
    { name: "redirectroute", path: "/terms-and-conditions", element: <Terms /> },
    { name: "redirectroute", path: "/auditor/company-select", element: <AuditorCompanySelect/> },
    { name: "redirectroute", path: "/auditor", element: <AuditorLogin /> },
    { name: "redirectroute", path: "/billing", element: <BillingPage/> },
    { name: "redirectroute", path: "/login", element: <Login /> },
    { name: "redirectroute", path: "/signup", element: <SignUp /> },
    { name: "redirectroute", path: "/reset-password-at-first-login", element: <ResetPasswordAtFirstLogin /> },
    { name: "redirectroute", path: "/forgot-password", element: <ForgotPassword /> },
    { name: "redirectroute", path: "/reset-password", element: <ResetPassword /> },
    { name: "ProtectedRoute", path: "/dashboard", element: <Dashboard /> },   
    // { name: "ProtectedRoute", path: "/employee-dashboard", element: <EmpDashboard /> },
    { name: "ProtectedRoute", isPermissionCheck: true , path: "/setting", element: <Settings /> },
    { name: "ProtectedRoute", isPermissionCheck: true,  path: "/setting/:tab", element: <Settings /> },
    
    { name: "ProtectedRoute", path: "/employees", element: <Employees /> },
    { name: "ProtectedRoute", path: "/employees/detail/:id", element: <EmployeeDetails /> },
    { name: "ProtectedRoute", path: "/my-profile/:id", element: <EmployeeDetails /> },
    { name: "ProtectedRoute", path: "/approvals", element: <Approvals /> },
    { name: "ProtectedRoute", path: "/absense", element: <Absense /> },
    { name: "ProtectedRoute", path: "/my-leave", element: <CalendarView /> },
    { name: "ProtectedRoute", path: "/my-leave/:id", element: <CalendarView /> },
    { name: "ProtectedRoute", path: "/leave-calendar", element: <Leave /> },
    { name: "ProtectedRoute", path: "/report", element: <Report /> },
    { name: "ProtectedRoute", path: "/report/employee", element: <EmployeeInfoReport /> },
    { name: "ProtectedRoute", path: "/report/expense", element: <ExpenseReport /> },
    { name: "ProtectedRoute", path: "/report/overtime", element: <OvertimeReport /> },
    { name: "ProtectedRoute", path: "/report/leave", element: <LeaveReport /> },
    { name: "ProtectedRoute", path: "/report/timeinlieu", element: <Timeinlieu /> },
    { name: "ProtectedRoute", path: "/report/absense", element: <AbsenceReport /> },
    { name: "ProtectedRoute", path: "/report/approvar", element: <ApproverReport /> },
    { name: "ProtectedRoute", path: "/report/allowanceOverview", element: <AllowanceOverview /> },
    { name: "ProtectedRoute", path: "/report/company-report", element: <CompanyReport /> },
    { name: "ProtectedRoute", path: "/report/company-message", element: <CompanyMessagesReport /> },
    { name: "ProtectedRoute", path: "/expense", element: <Expense /> },
    { name: "ProtectedRoute", path: "/notification", element: <Notification /> },
    { name: "ProtectedRoute", path: "/overtime", element: <MyOverTime /> },
    // { name: "ProtectedRoute", path: "/super/dashboard", element: <SuperDashboard/> },
    { name: "ProtectedRoute", path: "/super/company", element: <CompanyList/> },
    { name: "ProtectedRoute", path: "/super/ticket", element: <AssignTicket/> },
    { name: "ProtectedRoute", path: "/super/team-ticket", element: <AssignTicketTeam/> },
    { name: "ProtectedRoute", path: "/super/ticket", element: <AssignTicket/> }
];



const moduleName = (path) => {
    let modulename;
    switch (true) {
        case path.includes("/setting"):
            modulename = "Company Information";
            break;
        case path.includes("/setting/general"):
            modulename = "General Settings";
            break;
        case path.includes("/setting/info"):
            modulename = "Company Information";
            break;
        case path.includes("/setting/leave"):
            modulename = "Leave Types";
            break;
        case path.includes("/setting/expenses"):
            modulename = "Expense Types";
            break;
        case path.includes("/setting/overtime"):
            modulename = "Overtime Types";
            break;
        case path.includes("/setting/restrciteddays"):
            modulename = "Restricted Days";
            break;
        case path.includes("/setting/department"):
            modulename = "Departments";
            break;
        case path.includes("/setting/carryforward"):
            modulename = "Carry Forwarded";
            break;
        case path.includes("/setting/plansetting"):
            modulename = "Plan Settings";
            break;
        case path.includes("/setting/holiday"):
            modulename = "Bank Holidays";
            break;
        case path.includes("/setting/importdata"):
            modulename = "Import Data";
            break;
        case path.includes("/setting/billing"):
            modulename = "Billing";
            break;
            case path.includes("/setting/billing"):
                modulename = "Billing";
                break;
        case path.includes("/setting/privacy"):
            modulename = "Privacy";
            break;
            case path.includes("/setting/styles"):
                modulename = "CompanyStyles";
                break;
        case path.includes("/employees"):
            modulename = "Active Employee";
            break;
        case path.includes("/my-profile"):
            modulename = "MyProfile";
            break;
        case path.includes("/employees"):
            modulename = "Leavers";
            break;
        case path.includes("/absense"):
            modulename = "Absence";
            break;
        case path.includes("/my-leave"):
            modulename = "My Leave";
            break;
        case path.includes("/approvals"):
            modulename = "Approvals";
            break;
        case path.includes("/employee-dashboard"):
            modulename = "Dashboard";
            break;
        case path.includes("/dashboard"):
            modulename = "Dashboard";
            break;
        case path.includes("/leave-calendar"):
            modulename = "Leave Calenders";
            break;
        case path.includes("/overtime"):
            modulename = "My Overtime";
            break;
        case path.includes("/expense"):
            modulename = "My Expenses";
            break;
        case path.includes("/report"):
            modulename = "Reports";
            break;
        case path.includes("/setting/permissions"):
            modulename = "Permissions";
            break;
            
        case path.includes("/notification"):
            modulename = "Notification";
            break;
            case path.includes("/super/company"):
            modulename = "SuperAdmin";
            break;
            case path.includes("/super/ticket"):
                modulename = "SuperAdmin";
                break;
                case path.includes("/super/team-ticket"):
                modulename = "SuperAdmin";
                break;

            
    }
    return modulename;
};

const PermissionsRoute = ({ redirectPath = '/dashboard', path }) => {
    let modulename = moduleName(path);
    
    if (modulename === "Notification" || modulename === "MyProfile" || modulename === "SuperAdmin") {  // no module name & permission provided in login api 
        return <Outlet />;
    } else {
        const permissionsSelector = useSelector((state) => state.persistedReducer.userPermissionsStates.userPermissions);
        const reportsReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === modulename && obj?.module_permission_name === 'Read'
        )[0]?.status;
        if (!reportsReadPermissions) {
            return <Navigate to={redirectPath} replace />;
        }

        return <Outlet />;

    }

};


const AdminRoutes = (
    <>
        <Route element={<RedirectRoute />}>
            {routePath?.filter((obj) => obj.name === "redirectroute").map((obj) => {
                return (
                    <Route path={obj.path} element={obj.element} exact />
                )
            })
            }
        </Route>

        <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Layout />}>
                {routePath?.filter((obj) => obj.name === "ProtectedRoute" && obj?.isPermissionCheck !== true).map((obj) => {
                    if (obj.name === "ProtectedRoute") {
                        return (
                            <Route element={<PermissionsRoute path={obj.path} />}>
                                <Route path={obj.path} element={obj.element} exact />
                            </Route>
                        );
                    }
                })
                }
                {routePath?.filter((obj) => obj.name === "ProtectedRoute" && obj?.isPermissionCheck === true).map((obj) => {
                    if (obj.name === "ProtectedRoute") {
                        return (
                            <Route>
                                <Route path={obj.path} element={obj.element} exact />
                            </Route>
                        );
                    }
                })
                }

            </Route>
            {MasterRoutes}
        </Route>
    </>
);

export default AdminRoutes;