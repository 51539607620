/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger*/
/* eslint-disable */
import React from "react";
import {useState, useEffect, useCallback, useRef} from "react";
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Popup from "reactjs-popup";
import NoDataFound from "../../helper/noDataFound";
import { useLocation } from 'react-router-dom';
import {
  FormInput,
  FormSelect,
  FormTextarea
} from "../../components/formInput";
import PrimaryButton from "../../components/primaryButton";
import AddEmployee from "./AddEmployee";
import { useSelector, useDispatch } from "react-redux";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import EditEmployee from "./EditEmployee";
import { useGetEmployeeMutation, useReInviteEmployeeMutation,
   useFilterEmployeeDepartmentMutation, useDeleteEmployeeMutation, useAllEmployeeReinviteMutation,
   useTotalReinviteEmployeeCountMutation
} from "../../services/employeeApi";
import LoadingScreen from "../../helper/LoadingScreen";
import  moments from 'moment';
import Pagination from "../../components/inc/pagination";
import { toast } from "react-toastify";
import { departmentListAction, employeeDepartments } from "../../redux/DepartmentSlice/departmentSlice";
import {useSearchParams} from "react-router-dom";
import { useForgotPasswordMutation } from "../../services/authApi";
import _debounce from 'lodash/debounce';
import { useGetRolesMutation } from "../../services/reportApi";
import { employeeInfo } from "../../redux/EmployeeSlice/employeeSlice";

const Employees = () => {
  const ignore = useRef(false);
  // useSelector
  const employeeListData = useSelector((state) => state?.persistedReducer?.employeeSlice?.employee);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const permissionsSelector = useSelector((state) => state.persistedReducer.userPermissionsStates.userPermissions);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);

  // Mutations
  const [ getRoles, {data:roles} ] = useGetRolesMutation();
  const [ getDepartmentList, {data:departmentApiResponse, isSuccess} ] = useGetDepartmentListMutation();
  const [ getEmployee, {data:employeeListAllData, isSuccess:isEmployeeAPISuccess, isLoading} ] = useGetEmployeeMutation();
  const [ reInviteEmployee, {data: reInviteEmployeeApiRepo, isSuccess: isSuccessReinvite, isError: isErrorReinvite, isLoading: isLoadingReinvite} ] =  useReInviteEmployeeMutation();
  const [ filterEmployeeDepartment, {data: filterDepartmentApi, isSuccess: isSuccessFilterDepartment} ] = useFilterEmployeeDepartmentMutation();
  const [ deleteEmployee, {isSuccess: isDeleteSuccess, isError: isDeleteError, error: deleteError} ] = useDeleteEmployeeMutation();
  const [ forgotPassword, { isLoading:forgetIsLoading, isSuccess:forgetIsSuccess, isError:forgetIsError, error:forgetApiError } ] = useForgotPasswordMutation();
  const [ allEmployeeReinvite, {isSuccess: isSuccessReinviteAll, isError: isErrorReinviteAll, isLoading: isLoadingReinviteAll} ] = useAllEmployeeReinviteMutation();
  const [ totalReinviteEmployeeCount, {data:totalReinviteCountRespo, isSuccess:isSucessTotal} ] = useTotalReinviteEmployeeCountMutation();

  const data = useLocation();
  const [ open, setOpen ] = useState(false);
  const [ isOpen, setIsOpen ] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
 
  const [ toggleState, setToggleState ] = useState("ActiveEmployee");
  const [selectedRole, setSelectedRole] = useState(0);
  const [ editData, setEditData ] = useState();
  const [ isEdit, setIsEdit ] = useState(false);

  

  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get("page") || "";
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);
  const [ departmentId, setDepartmentId ] = useState();
  const [ empName, setEmpName ] = useState();
  
  const activeEmployeeUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Active Employee" && obj?.module_permission_name === 'Update');
  const activeEmployeeCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Active Employee" && obj?.module_permission_name === 'Create');
  const LeaversEmployeeCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Leavers" && obj?.module_permission_name === 'Create');
  const LeaversEmployeeDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Leavers" && obj?.module_permission_name === 'Delete');
  
  const [isCustomized, setIsCustomized] = useState(false);
  
  const [ employeeAllData, setEmployeeAllData ] = useState();
  const [predefinedChecked, setPredefinedChecked] = useState(true);
  const [ countReinvite, setCountReinvite ] = useState(0);
  const dispatch = useDispatch();
  const [ allDepartmentListData, setAllDepartmentListData ] =useState({});
  const [default_subject, setDefaultSubject] = useState(`Hi First Name,`);
  const [default_message, setDefaultMessage] = useState(
    `You have been invited to use Schedulize but have unfortunately not registered. 
    \nPlease log in via the link below and type in your email address along with your temporary password. 
    \nhttps://web.schedulize.app/
    \nMany thanks, `
  );
  const [customized_subject, setCustomizedSubject] = useState(default_subject);
  const [customized_message, setCustomizedMessage] = useState(default_message);
  const [ filter, setFilter ] = useState({
    name: '',
    department:'',
    register:"Select",
    roleId: 0,
  });
  


const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
    function handleDebounceFn(inputValue, toggleState) {
    if(departmentId) {
      let payload=`/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}&first_name=${inputValue}&department_id=${departmentId}`;
      getEmployee(payload);
    } else {
      let payload=`/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}&first_name=${inputValue}`;
       getEmployee(payload); 
       setEmpName(inputValue);
      }
    }
  const filterEmployees = useCallback((searchTerm, toggleState) => {
    setFilter({...filter, ['name'] : searchTerm});
    debounceFn(searchTerm, toggleState);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

  const handleChangeSubject = (e) => {
    setCustomizedSubject(e.target.value);
    setIsCustomized(true); 
  };

  const handleChangeMessage = (e) => {
    setCustomizedMessage(e.target.value);
    setIsCustomized(true); 
  };
  
  const handleCheckboxChange = () => {
    setPredefinedChecked(!predefinedChecked);
    if (predefinedChecked) {
      setCustomizedSubject(default_subject);
      setCustomizedMessage(default_message);
    }
  };

 
  const handelDeleteEmployee = (id) => {

  deleteEmployee(id);
  };
  const filterDepartment = (e) => {
    e.preventDefault();
    const selectedRole = filter.roleId || ''; 
  
    if (e.target.value === '0') {
      let payload = `/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}&role_type_id=${selectedRole}`;
      getEmployee(payload);
      setDepartmentId(e.target.value);
      setSelectedRole(0);
    } else {
      let payload = `/employee?page=${1}&status=${toggleState === 'Leavers' ? '0' : '1'}&department_id=${e.target.value}&role_type_id=${selectedRole}&limit=100`;
      if (selectedRole > 0) {
        payload += `&role_type_id=${selectedRole}`;
      }
      getEmployee(payload);
      setDepartmentId(e.target.value);
      setSelectedRole(0);
      setFilter({ ...filter, ['department']: e.target.value });
    }
  };
  

  const filterEmployeesByRole = (e) => {
    e.preventDefault();
    const selectedRoleId = e.target.value;
    const selectedDepartmentId = filter.department || ''; // Get the selected department from the filter state
  
    if (selectedRoleId && selectedRoleId > 0) {
      let payload = `/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}&role_type_id=${selectedRoleId}&department_id=${departmentId ? departmentId : ""}`;
      if (selectedDepartmentId > 0) {
        payload += `&department_id=${selectedDepartmentId}`;
      }
      getEmployee(payload);
      setSelectedRole(selectedRoleId);
      setFilter({ ...filter, roleId: selectedRoleId });
    } else {
      let payload = `/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}`;
      if (selectedDepartmentId > 0) {
        payload += `&department_id=${selectedDepartmentId}`;
      }
      getEmployee(payload);
      setSelectedRole(0);
      setDepartmentId(0)
    }
  };
  
    
   
    const filterRegister = (e) => {
   
      if (e.target.value === "unregister") {
        const unregisteredEmployees = employeeListData?.data?.filter((f) => f.loginInfo === null);
        if (unregisteredEmployees?.length > 0) {
          setEmployeeAllData( unregisteredEmployees );
          setFilter({...filter, ['register'] : e.target.value});

        }
      } else if (e.target.value === "register") {
        const registeredEmployees = employeeListData?.data?.filter((f) => f.loginInfo !== null);
        if (registeredEmployees?.length > 0) {
          setEmployeeAllData(registeredEmployees);
          setFilter({...filter, ['register'] : e.target.value});
        }
      } else if(e.target.value === "Select"){
        setEmployeeAllData(employeeListData?.data);
        setFilter({...filter, ['register'] : e.target.value});
      }
    };
  const decrementPage = () => {
    if(employeeListData?.currentPage - 1 >= 0){
      getEmployee(employeeListData?.currentPage - 1);
       setPage(employeeListData?.currentPage - 1);
    }
};


  useEffect(() => {
    if (!ignore.current){
   getRoles();
   let payload=`/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}&limit=100`;
   totalReinviteEmployeeCount(payload); 
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
useEffect(() => {
  if(isSuccess){
    dispatch(departmentListAction(departmentApiResponse));
  }
 },
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ])

  useEffect(() => {
      if(departmentApiData){
        setAllDepartmentListData(departmentApiData.data);
        dispatch(employeeDepartments(departmentApiData.data));
      }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ departmentApiData ]);

  useEffect(() => {
      if (forgetIsLoading) {
        toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
      }
      if (forgetIsSuccess) {
        toast.update(
          toastId.current,
          ("Reset link sent successfully to your email.",
          {
            
            render: "Reset link sent successfully to your email.",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
      }
      if (forgetIsError) {
          const toastMessage = forgetApiError?.data?.message
          ? forgetApiError?.data?.message
          : "Something went wrong";
          toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[  forgetIsLoading, forgetIsSuccess, forgetIsError ]);


   useEffect(() => {
    if(isSucessTotal) {
      if(totalReinviteCountRespo?.data?.length > 0) {
        const reInviteData = totalReinviteCountRespo?.data?.filter((f) => f.loginInfo === null);
      setCountReinvite(reInviteData.length);
      }
    } 
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSucessTotal ]);

  useEffect(() => {
    getDepartmentList();
   
   if(departmentId && departmentId !== '0') {
    let payload =  `/employee?page=${1}&status=${toggleState === 'Leavers' ? '0' : '1'}&department_id=${departmentId}&role_type_id=${selectedRole}&limit=100`;
    getEmployee(payload); 
    setDepartmentId(departmentId);
   }
   else if(filter.name) {
    let payload=`/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}&first_name=${filter.name}`;
    getEmployee(payload); 
   }
    else {
    let payload =  `/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}`;
      getEmployee(payload);  
   }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

useEffect(() => {

  if(employeeListData){
    setFilter({});
    if(employeeListData?.data?.length > 0) {
      if (employeeListData && data?.state?.filter === "register") {     
        const registeredEmployees = employeeListData?.data?.filter((f) => f.loginInfo !== null);
        if (registeredEmployees?.length > 0) {
          setEmployeeAllData(registeredEmployees);           
          setFilter({...filter, ['register']: data?.state?.filter});
        }
      }
      else if(employeeListData && data?.state?.filter === "unregister"){
        const unregisteredEmployees = employeeListData?.data.filter((f) => f.loginInfo === null);
        if (unregisteredEmployees?.length > 0) {
          setEmployeeAllData( unregisteredEmployees );
          setFilter({...filter, ['register'] : data?.state?.filter});
        }
      }
      else
      {
        setEmployeeAllData(employeeListData?.data);
      }
    }
    else
    {
      setEmployeeAllData();
    }
    setPages(employeeListData?.totalPages);
    const reInviteData =  employeeListData?.data?.filter((f) => f.loginInfo === null);
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ employeeListData ]);




  useEffect(() => {
    if(isEmployeeAPISuccess){
    dispatch(employeeInfo(employeeListAllData));
    }
    // if(isEmployeeAPISuccess){
    //   dispatch(employeeInfo(employeeListAllData));
    //   setFilter({});
    //   if(employeeListData?.data?.length > 0) {
    //     if (employeeListData && data?.state?.filter === "register") {     
    //       const registeredEmployees = employeeListData?.data.filter((f) => f.loginInfo !== null);
    //       if (registeredEmployees?.length > 0) {
    //         setEmployeeAllData(registeredEmployees);           
    //         setFilter({...filter, ['register']: data?.state?.filter});
    //       }
    //     }
    //     else if(employeeListData && data?.state?.filter === "unregister"){
    //       const unregisteredEmployees = employeeListData?.data.filter((f) => f.loginInfo === null);
    //       if (unregisteredEmployees?.length > 0) {
    //         setEmployeeAllData( unregisteredEmployees );
    //         setFilter({...filter, ['register'] : data?.state?.filter});
    //       }
    //     }
    //     else
    //     {
    //       setEmployeeAllData(employeeListData?.data);
    //     }
    //   }
    //   else
    //   {
    //     setEmployeeAllData();
    //   }
    //   setPages(employeeListData?.totalPages);
    //   const reInviteData =  employeeListData?.data?.filter((f) => f.loginInfo === null);
    // }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isEmployeeAPISuccess ]);

  useEffect(() => {
    if(isDeleteSuccess){
      toast.success("Employee Deleted Successfully.", {
        render: " Employee Deleted Successfully.",
             type: "success",
             autoClose: 2000,
           isLoading: false
      });
      let payload=`/employee?page=${page}&status=${toggleState === 'Leavers' ? '0' : '1'}`;
      getEmployee(payload);
      document?.getElementById("deleteModel")?.click();
    }
    if(isDeleteError){
      toast.error("Something went wrong.", {
        render: "Something went wrong.",
             type: "error",
             autoClose: 2000,
           isLoading: false
      });
      document?.getElementById("deleteModel").click();
    }
   
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isDeleteSuccess, isDeleteError ]);

  useEffect(() => {
    if(isSuccessFilterDepartment){
      setEmployeeAllData(filterDepartmentApi);
      setPages(filterDepartmentApi?.totalPages);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessFilterDepartment ]);

  const handelAddEmployeeOpen = () => {
    // setIsOpen((o) => !o);
   
  };

  const [ inputFields, setInputFields ] = useState([
    {
      fullName: "",
      emailAddress: ""
    }
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        fullName: "",
        emailAddress: ""
      }
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [ ...inputFields ];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  // eslint-disable-next-line no-unused-vars
  // const handleChange = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [ ...inputFields ];
  //   list[index][name] = value;
  //   setInputFields(list);
  // };
  const toastId = useRef(null);
  const InviteEmployee = async (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if(isLoadingReinvite) {
      toastId.current = toast.loading("Please wait...", {
        // position: "top-center",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if(isSuccessReinvite) {
      // getCreateSuccessHoliday();
       
      toast.dismiss(toastId.current);
          toast.success("Invitation sent successfully.", {
            render: "Invitation sent successfully.",
                 type: "success",
                 autoClose: 2000,
               isLoading: false,
               theme: "colored"
          });
     
    }
    if (isErrorReinvite) {
      const toastMessage = 
      // apiError.data.message
      //   ? apiError.data.message
      //   :
         "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
    
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[  isLoadingReinvite, isSuccessReinvite, isErrorReinvite ]);

  const handlerInviteClick = (e, employee,  close) => {
    e.preventDefault();
    const data = {
      
      "email": employee.email,
      "employee_id": employee?.id
    };
    reInviteEmployee(data);
    close();
  };

 
  const handlerResetPassword = (e, employee, close) => {
    e.preventDefault();
    const data = {
      
      "email": employee.email
      
    };
    //resetPasswordEmployee(data);
    forgotPassword(data.email);
    close();
  };

 
  const toggleTab = (index) => {
    setToggleState(index);
    if(index === 'Leavers'){
      let payload =  `/employee?page=${page}&status=${0}`;
     
      getEmployee(payload);
    }else {
      let payload =  `/employee?page=${page}&status=${1}`;
      getEmployee(payload);
    }
    
  };
 
  useEffect(() => {
    if(isLoadingReinviteAll) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if(isSuccessReinviteAll) {
      // getCreateSuccessHoliday();
       
      toast.dismiss(toastId.current);
          toast.success("Invitation sent successfully.", {
            render: "Invitation sent successfully.",
                 type: "success",
                 autoClose: 2000,
               isLoading: false,
               theme: "colored"
          });
     
    }
    if (isErrorReinviteAll) {
      const toastMessage = 
      // apiError.data.message\
      //   ? apiError.data.message
      //   :
         "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000, 
          isLoading: false
        })
      );
    }
    
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[  isLoadingReinviteAll, isSuccessReinviteAll, isErrorReinviteAll ]);
  const handlerUnRegister = (e, close) => {
    e.preventDefault();
    if(employeeListData?.data?.length > 0) {
    allEmployeeReinvite({ 
      "company_id": companyId,
      "is_customized": !predefinedChecked,
      "customized_subject":customized_subject,
      "customized_message": customized_message
    });
    close();
    } else {
      
      toast.dismiss(toastId.current);
      const toastMessage = "No employee ";
      toast.error(toastMessage);
      close();
    }
    // setCountReinvite("0");
    
  };
  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Employees</title>
          </Helmet>
        </HelmetProvider>
        <div>
          <div className="py-6">
            <div className="container mx-auto  employeePage">
              <h2 className="text-xl mb-3 font-semibold  ">Employees</h2>
              {isLoading ? <LoadingScreen/> : 
              <div className="card bg-white  mb-3">
                <div className="heading_title px-4 flex justify-between items-center ">
                <div className="approvalTab employeeTab ">
                      <ul>
                        <li>
                          <span
                            onClick={() => toggleTab("ActiveEmployee")}
                            className={
                              toggleState === "ActiveEmployee" ? " active" : "  "
                            }
                          >
                            Active
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => toggleTab("Leavers")}
                            className={
                              toggleState === "Leavers" ? "  active" : " "
                            }
                          >
                           Leavers
                          </span>
                        </li>
                   
                      </ul>
                    </div>
                  <div className="headerButton">
                    {activeEmployeeCreatePermissions[0]?.status === true && 
                    toggleState !== "Leavers" && ( 
                    <Popup
                      open={isOpen}
                      onClose={() => closeModal()}
                      className="addEmployeee"
                      closeOnDocumentClick={false}
                      trigger={
                        <button
                          className="btn-md btn-add"
                          aria-describedby="popup-8"
                          id="addEmployee"
                           onClick={handelAddEmployeeOpen}
                        >
                          <span className="fa fa-plus mr-2" />
                          Add Employee
                        </button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            id="addEmployeeClose"
                            onClick={() => {
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                         <AddEmployee
                          allDepartmentListData={allDepartmentListData}
                          setOpen={setIsOpen}  closeModal={close} getEmployee={getEmployee}
                          toggleState ={toggleState}
                          roles= {roles}
                          totalReinviteEmployeeCount ={totalReinviteEmployeeCount}
                          />
                        </div>
                      )}
                    </Popup>) } 
                    <Popup
                      open={open}
                      onClose={() => closeModal()}
                      closeOnDocumentClick={false}
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            id="inviteEmployeeClose"
                            onClick={() => {
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                          <div className="header">
                            <h3>Invite Employees </h3>
                          </div>
                          <div className="content">
                            <form onSubmit={InviteEmployee}>
                              <div className="flex flex-wrap ">
                                {inputFields.map((data, index) => {
                                  const {fullName, emailAddress, salary} = data;
                                  return (
                                    <>
                                      <div className="w-full flex  flex-wrap md:flex-nowrap addMoreRow items-center">
                                        <div className="w-full md:w-1/2 md:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Name
                                              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                            </label>
                                            <FormInput
                                              inputType="text"
                                              inputName=" Name"
                                              inputID="Name"
                                              handlerChange={(e) => (e.target.value)}
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="w-full md:w-1/2 lg:pr-0">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Email{" "}
                                              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                            </label>
                                            <FormInput
                                              inputType="text"
                                              inputName=" Email"
                                              inputID="Email"
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="text-right relative bottom-3 md:ml-2 md:mt-8 removeBtn">
                                          {inputFields.length !== 1 ? (
                                            <button
                                            id="removeInput"
                                              className=" bg-secondry text-white px-2 rounded "
                                              onClick={removeInputFields}
                                            >
                                              <span className="fa fa-minus text-xl" />
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}

                                <div className="w-full">
                                  <div className="form-group mb-6 ">
                                    <button
                                      className="btn-md btn-add"
                                      onClick={addInputField}
                                      id="addMore"
                                    >
                                      <span className="fa fa-plus mr-2" />
                                      Add More
                                    </button>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Message (optional){" "}
                                    </label>
                                    <FormTextarea inputID="message" />
                                    <div className="help-block" />
                                  </div>
                                </div>

                                <div className="lg:w w-full  ">
                                  <div className="w-full mt-4">
                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                      <PrimaryButton
                                        Btnclass="btn save mr-2"
                                        btnText="Send Invite"
                                        type="button"
                                        buttonID="sendInvite"
                                      />
                                      {/* <PrimaryButton Btnclass="btn cancel " btnText="Cancel" type="button" onClick={() => close()}/> */}

                                      <button
                                        type="button"
                                        id="cancelInvite"
                                        onClick={() => close()}
                                        className="btn cancel "
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </div>
                </div>
              
                <div className="p-4">   
                <div className="flex w-full flex-wrap lg:flex-nowrap md:justify-between justify-end">
                <div className="flex w-full lg:w-3/4 flex-wrap pt-2 filterSection mb-3 ">
                    <div className="searchSec w-full lg:w-1/4 sm:w-1/3 sm:pr-4 mb-2 lg:mb-0">
                      <input placeholder="Search By Name"
                       className="formControl w-full"
                       id="empSearch"
                       onChange={(e) => filterEmployees(e.target.value, toggleState)} 
                       defaultValue={empName}
                      />
                    </div>
                    
                    <div className="searchSec  w-full lg:w-1/4 sm:w-1/3 mb-2 sm:pr-4 lg:mb-0">
                      <select className="formControl" id="selectDepartment"  name="department_id" defaultValue={departmentId} onChange={(e) => filterDepartment(e)}>
                      <FormSelect 
                            optionValue={"0"}
                            optionName={"Select Department"} />;
                          {allDepartmentListData && allDepartmentListData.length > 0 && allDepartmentListData.map((dept, index) => {
                            return<FormSelect  key={index}
                                  optionValue={dept?.id} 
                                  optionName={dept?.department_name} />;
                       })}
                      </select>
                    </div>

                    <div className="searchSec  w-full lg:w-1/4 sm:w-1/3 mb-2 sm:pr-4 lg:mb-0">
                    <select
                    id="selectRole"
                    className="formControl"
                    name="role_id"
                    value={selectedRole} 
                    onChange={(e) => filterEmployeesByRole(e)}
                    >
                    <option value="0">Select Role</option>
                    <option value="2">Company Admin/HR</option>
                    <option value="3">Manager</option>
                    <option value="4">Employee</option>
                    {/* Add more options for other roles as needed */}
                    </select>
                    </div>

                    {toggleState === "ActiveEmployee" && (
                      <div className=" searchSec w-full lg:w-1/4 sm:w-1/3 mb-2 lg:mb-0 xl:pr-4">
                        <select className="formControl" id="selectRegistration" name="registerFilter" value={filter?.register ? filter?.register : 'Select' } onChange={(e) => filterRegister(e)}>
                          <option value="Select">Select Registration</option>
                          <option value="register">Registered</option>
                          <option value="unregister">Unregistered</option>
                        </select>
                      </div>
                    )}

                  </div> 
                  <div className="sm:w-[280px] pb-6 w-full py-2 ml-auto">
                 
                  {/* <button onClick={(e) => handlerUnRegister(e)}> Unregistered Employees</button> */}
                  {toggleState === "ActiveEmployee" && (
                     <Popup
                     open={open}
                     onClose={() => closeModal()}
                     closeOnDocumentClick={false}
                     trigger={
                       <div
                         onClick={() => {
                           setIsEdit(true);
                           // setEditData(employee);
                           // setOpen((o) => !o);
                           // alert("Please select");
                         }}
                         className="actionBtn h-[45px] w-full leading-8 items-baseline relative"
                       >
                         Invite all unregistered employees
                         <span className="absolute -right-2 leading-0 flex items-center justify-center -top-2 w-6 h-6 text-center text-white rounded-full bg-secondry">
                           {countReinvite}
                         </span>
                       </div>
                     }
                     modal
                     nested
                   >
                     {(close) => (
                       <div className="modal">
                         <button
                         id="inviteClose"
                           className="close"
                           onClick={() => {
                             closeModal();
                             close();
                           }}
                         >
                           &times;
                         </button>
                         {countReinvite !== 0 ? (
                           <>
                             <div className="header">
                               <h3>Unregistered Employees</h3>
                             </div>
                             <div className="content">
                               <div className="w-full ">
                                 <div className="form-group">
                                   <label className="formBlock" htmlFor="customized_subject">Subject:</label>
                                   <input
                                      type="text"
                                      id="customized_subject"
                                      name="customized_subject"
                                      className="w-full border p-2"
                                      placeholder="Enter subject"
                                      value={customized_subject}
                                      onChange={(e) => handleChangeSubject(e)}
                                      disabled={predefinedChecked}
                                   />
                                 </div>
                                 <div className="form-group mt-4">
                                   <label  className="formBlock"  htmlFor="customized_message">Message:</label>
                                   <textarea
                                     id="customized_message"
                                     name="customized_message"
                                     className="w-full border p-2"
                                     placeholder="Enter message"
                                     rows="4"
                                     value={customized_message}
                                     onChange={(e) => handleChangeMessage(e)}
                                     disabled={predefinedChecked}
                                   ></textarea>
                                 </div>
                                 <div className="form-group mt-3">
                                  <label className="formBlock" >
                                    <input
                                      type="checkbox"
                                      id="preDefined"
                                      checked={predefinedChecked}
                                      onChange={handleCheckboxChange}
                                    />{' '}
                                    Pre-Defined
                                  </label>
                                </div>
                                 <h5 className="py-4 text-center text-xl font-bold">Are you sure you want to re-invite?</h5>
                                 <div className="flex justify-center form-group  mt-4">
                                  <button
                                    className="btn save mr-2 "
                                    id="unregisteredYes"
                                    onClick={(e) => handlerUnRegister(e, close)}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => close()}
                                    className="btn cancel"
                                    id="unregisteredNo"
                                  >
                                    No
                                  </button>
                                </div>
                               </div>
                             </div>
                           </>
                         ) : (
                           <p className="text-base py-24 font-medium"><center>No Employee Count</center></p>
                         )}
                       </div>
                     )}
                   </Popup>
                    )}
                  </div>
                </div>            
                  <div className="tabCard ">
                    {/* <div
                      className={
                        toggleState === "ActiveEmployee"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    > */}
                      
               <div className="tableArea">
               <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th className="w-40">Name</th>
                          <th>Department</th>
                          <th>Role</th>
                          <th>Start Date</th>
                          {   toggleState === "Leavers" ? 
                          <th>
                          Leaving Date
                        </th>
                       : <th>Leave Allowance <br />
                             Remaining
                     </th>  }
                     {   toggleState === "Leavers" ? 
                        <th>
                          Leave Allowance <br />
                          Remaining
                        </th> : " "}
                          <th>
                            Authorised <br />
                            Approver
                          </th>
                          <th> { toggleState === "Leavers" ? "" :"Last login"}</th>
                         
                        
                          <th >Action</th>
                        </tr>
                      </thead>
                      <tbody> 
                      {employeeAllData? 
                       <>
                          {employeeAllData && employeeAllData?.map((employee, index) => {
                            return<>
                            <tr key={index}>
                             <td>
                             <Link to={`/employees/detail/${employee?.id}`} className="">
                               {employee?.first_name} {" "} {employee?.last_name} 
                             </Link>
                           </td>
                           <td>{employee?.departmentInfo?.department_name}</td>
                           <td>{employee?.rolesInfo[0]?.roleType?.role_name}</td>
                           <td>{moments(employee?.employment_start_date).format(formattedDate) ? moments(employee?.employment_start_date).format(formattedDate) : " "}</td>
                           {toggleState === "Leavers" ?
                            <td>{moments(employee?.leftInfo?.leaving_date).format(formattedDate)} </td> :
                            <td>
                            {employee?.remaining_leave_this_year !== null
                              ? Math?.abs(employee?.remaining_leave_this_year)?.toFixed(2)
                              : ""}
                          </td>
                           }
                           {toggleState === "Leavers" ?
                           <td>{employee?.leftInfo?.remaining_leave_allowance !== null ?
                            Math?.abs(employee?.leftInfo?.remaining_leave_allowance)?.toFixed(2)
                            : ""}</td> : " "}
                           <td>{employee?. employee_can_approve_leave === "1" ? "Yes" : "No"}</td>
                            <td>{toggleState === "Leavers" ? null :
                            employee?.loginInfo? moments(employee?.loginInfo?.login_time).format(formattedDate) : ''
                            
                            }</td>                
                         <td > {
                          employee?.loginInfo === null && toggleState === 'ActiveEmployee' ?
                           <>
                           <Popup
                               open={open}
                               onClose={() => closeModal()}
                               closeOnDocumentClick={false}
                            
                               trigger={
                                 <div
                                   onClick={() => {  setIsEdit(true);
                                    setEditData(employee);
                                    // setOpen((o) => !o);
                                    // alert("Please select");
                                  }}
                                   className="reInvite text-white bg-secondry inline-block text-sm font-medium mr-1 cursor-pointer px-2 py-2 rounded" style={{lineHeight:"17px"}}
                                 >
                               Re-Invite
                                 </div>
                               }
                               modal
                               nested
                             >
                               {(close) => (
                                 <div className="modal">
                                   <button
                                     className="close"
                                     id="reInviteClose"
                                     onClick={() => {
                                       closeModal();
                                       close();
                                     }}
                                   >
                                     &times;
                                   </button>
                                   <div className="header">                                   
                                     <h3>Re-Invite</h3>
                                   </div>
                                   <div className="content">
                                     <p>
                                       Are you sure you want to Re-Invite?
                                     </p>
                                     <div className="w-full mt-8">
                                       <div className="form-group pt-4 pb-3 flex justify-center ">
                                         <button className="btn save mr-2"
                                          id="reInviteYes"
                                          onClick={(e) => handlerInviteClick(e, employee, close)}>
                                           Yes
                                         </button>
                                         <button
                                           type="button"
                                           id="reInviteNo"
                                           onClick={() => close()}
                                           className="btn cancel "
                                         >
                                           No
                                         </button>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               )}
                             </Popup>
                             </> :<>
                             <Popup
                               open={open}
                               onClose={() => closeModal()}
                               closeOnDocumentClick={false}
                            
                               trigger={
                                 <div
                                   onClick={() => {  setIsEdit(true);
                                    setEditData(employee);
                                    // setOpen((o) => !o);
                                    // alert("Please select");
                                  }}
                                   className="reInvite text-white bg-green-500 inline-block text-sm font-medium mr-1 cursor-pointer px-4 py-2 rounded" style={{lineHeight:"17px"}}
                                 >
                                  Joined
                                 </div>
                               }
                               modal
                               nested
                             >
                               {(close) => (
                                 <div className="modal">
                                   <button
                                   id="joinedClose"
                                     className="close"
                                     onClick={() => {
                                       closeModal();
                                       close();
                                     }}
                                   >
                                     &times;
                                   </button>
                                   <div className="header">                                   
                                     <h3>Joined</h3>
                                   </div>
                                   <div className="content">
                                     <p className="ml-[34%] mt-10">
                                        Employee has already logged in
                                     </p>
                                   </div>
                                 </div>
                               )}
                             </Popup>
                             </>  }
                             {toggleState === 'ActiveEmployee' ?
                             <>
                             {activeEmployeeUpdatePermissions[0]?.status === true &&
                             <Popup
                               open={open}
                               className="addEmployeee"
                               onClose={() => closeModal()}
                               closeOnDocumentClick={false}
                               trigger={
                                 <div
                                   className="actionBtn" onClick={() => { 
                                    setIsEdit(true); setEditData(employee);
                                    }}
                                    title="Edit"
                                 >
                                   <i onClick={() => { 
                                    setIsEdit(true); 
                                    setEditData(employee);
                                    }}
                                     className="fa fa-pencil" />
                                 </div>
                               }
                               modal
                               nested
                             >
                               {(close) => (
                                 <div className="modal">
                                   <button
                                     className="close"
                                     id="editEmpClose"
                                     onClick={() => {
                                       closeModal();
                                       close();
                                     }}
                                   >
                                     &times;
                                   </button>
                                   <div className="header">
                                     <h3>Edit Employee</h3>
                                   </div>
                                   <EditEmployee
                                    allDepartmentListData={allDepartmentListData}
                                    setOpen={setIsOpen}
                                     isEdit={isEdit} 
                                     editData={employee} 
                                    closeModal={close} getEmployee={getEmployee}
                                    toggleState ={toggleState}
                                    currentPage={employeeListData?.currentPage}
                                    roles= {roles}
                                  />
                                 </div>
                               )}
                             </Popup> }
                             
                             <Popup
                               open={open}
                               onClose={() => closeModal()}
                               closeOnDocumentClick={false}
                               trigger={
                                 <div
                                   onClick={() => {  setIsEdit(true);
                                    setEditData(employee);
                                    // setOpen((o) => !o);
                                    // alert("Please select");
                                  }}
                                  title="Reset Password"
                                   className="actionBtn cancelBtn"
                                 >
                                   <i className="fa fa-lock" />
                                 </div>
                               }
                               modal
                               nested
                             >
                               {(close) => (
                                 <div className="modal">
                                   <button
                                     className="close"
                                     id="resetClose"
                                     onClick={() => {
                                       closeModal();
                                       close();
                                     }}
                                   >
                                     &times;
                                   </button>
                                   <div className="header">                                   
                                     <h3>Reset Password</h3>
                                   </div>
                                   <div className="content">
                                     <p>
                                       Are you sure you want to reset password ?
                                     </p>
                                     <div className="w-full mt-8">
                                       <div className="form-group pt-4 pb-3 flex justify-center ">
                                         <button id="resetPswYes" className="btn save mr-2" onClick={(e) => handlerResetPassword(e, employee, close)}>
                                           Yes
                                         </button>
                                         <button
                                           type="button"
                                           id="resetPswNo"
                                           onClick={() => close()}
                                           className="btn cancel "
                                         >
                                           No
                                         </button>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               )}
                             </Popup>
                             </> :
                             <>
                             {LeaversEmployeeDeletePermissions[0]?.status === true ?
                               <Popup
                               open={open}
                               onClose={() => closeModal()}
                               closeOnDocumentClick={false}
                               trigger={
                                 <div
                                   onClick={() => {  setIsEdit(true);
                                    setEditData(employee);
                                    // setOpen((o) => !o);
                                    alert("Please select");
                                  }}
                                   className="actionBtn cancelBtn "
                                 >
                                   <i className="fa fa-trash" />
                                 </div>
                               }
                               modal
                               nested
                             >
                               {(close) => (
                                 <div className="modal">
                                   <button
                                     className="close"
                                     id="deleteModel"
                                     onClick={() => {
                                       closeModal();
                                       close();
                                     }}
                                   >
                                     &times;
                                   </button>
                                   <div className="header">
                                     {" "}
                                     <h3>Deleted</h3>
                                   </div>
                                   <div className="content">
                                     <p>
                                       Are you sure you want to delete this ?
                                     </p>
                                     <div className="w-full mt-8">
                                       <div className="form-group pt-4 pb-3 flex justify-center ">
                                         <button id="empDeleteYes" onClick={() => handelDeleteEmployee(employee?.id)}
                                          className="btn save mr-2">
                                           Yes
                                         </button>
                                         <button
                                           type="button"
                                           id="empDeleteNo"
                                           onClick={() => close()}
                                           className="btn cancel "
                                         >
                                           No
                                         </button>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               )}
                             </Popup> :  <i className="fa fa-trash" /> } 
                             </>}
                           </td> 
                           {/* <td>
                           <span className="blue-bedge">Active</span>
                          <span className="red-bedge">Not enrolled</span>
                           <span className="yellow-bedge">Leaver</span> 
                           </td> */}
                          
                           </tr> 
                           </>;
                          })} 
                          </> :
                          <center className="mt-4">{<NoDataFound/>}</center>}
                      </tbody>
                    </table>
               
                </div>
                      <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
               </div>
                    </div>
                    </div>
            


                {/* </div> */}
              </div>}

            </div>
          </div>
        </div>
    
      </div>
    </>
  );
};

export default Employees;
