import { useState, useRef } from "react";
import { FormSelect, FormTextarea } from "../../components/formInput";
import DatePicker from "react-datepicker";
import PrimaryButton from "../../components/primaryButton";
import { useCreateMyOvertimeMutation } from "../../services/myOvertimeApi";
import { useEffect } from "react";
import moment, * as moments from "moment";
import { toast } from "react-toastify";
import { setMinutes } from "date-fns";
import { useSelector } from "react-redux";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB"; // Import the locale

registerLocale("en-GB", enGB);

const AddMyOvertime = ({ close, myOvertimeList, allMasterOverTimeTypeResponse, isSuccessAllMasterOvertime }) => {

  //selector for carrying out data from redux
  const employeeIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);

  //create overtime api calling
  const [ createOverTime, { isSuccess: isSuccessCreate, isLoading: isLoadingCreate, isError: isErrorCreate, error: apiError } ] = useCreateMyOvertimeMutation();
  
  //States and hooks
  const [allOverTimeData, setAllOverTimeData] = useState();
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [duration, setDuration] = useState();
  const toastGeneralId = useRef(null);
  const [endDays, setEndDays] = useState();
  const [hours, setHours] = useState();
  const [result, setResult] = useState("");
  const [breakTime, setBreakTime] = useState();
  const [multiplyOvertimeBy, setMultiplyOvertimeBy] = useState(1);
  const [formData, setFormData] = useState({
    start_date: new Date(),
    start_time: "",
    end_time: "",
    duration: duration,
    break_type: "20",
    break_time: breakTime,
    time_in_lieu: "0",
    is_paid: "0",
    company_id: "32",
    m_overtime_types_id: "",
    employee_id: employeeIdData,
    notes: "",
    status: "0"
  });
  const [error, setError] = useState({
    overtimeTypesError: "",
    startTimeError: "",
    endTimeError: "",
    notesError: ""
  });

  useEffect(() => {
      if (isSuccessAllMasterOvertime) {
        setAllOverTimeData(allMasterOverTimeTypeResponse.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessAllMasterOvertime]
  );

  const getTimeDifferenceCalculator = (startTime, endTime) => {
    let timeDiff = endTime.getTime() - startTime.getTime();
    let hourhsDifference = Math.round(timeDiff / 60000);

    return hourhsDifference;
  };

  const diffInMinutes = Math.abs((startTime - endTime) / (1000 * 60));
  const handlerChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((formValue) => {
      return {
        ...formValue,
        [name]: e.target.value
      };
    });
    if (name === "m_overtime_types_id") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            overtimeTypesError: "Please select overTime "
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            overtimeTypesError: ""
          };
        });
      }
    }
    if (name === "break_time") {
      setBreakTime(e.target.value);
      // Convert break time to minutes
      const breakTimeMinutes = parseInt(value?.split(":")[0]) * 60 + parseInt(value?.split(":")[1]);
      const newTotalMinutes = (diffInMinutes) - breakTimeMinutes/multiplyOvertimeBy;

      setDuration(newTotalMinutes);
      // Convert the new total minutes back to hours:minutes format
      const newHours = Math.floor(newTotalMinutes / 60);
      const newMinutes = newTotalMinutes % 60;

      setHours(
        `${newHours < 10 ? `0${newHours}` : newHours}:${
          newMinutes < 10 ? `0${newMinutes}` : newMinutes
        }`
      );
    }
    
    const selectedOvertimeId = e.target.value;
    const selectedOvertime = allOverTimeData?.find(
      (overtime) => overtime.id === parseInt(selectedOvertimeId)
    );

    if (selectedOvertime) {
      setMultiplyOvertimeBy(selectedOvertime?.multiplyOvertimeBy);
    }
  };

  const handlerDateChange = (e, name) => {
    if (name === "start_date") {
      setFormData((formValue) => {
        return {
          ...formValue,
          start_date: moment(e).format("YYYY-MM-DD")
        };
      });
      setStartDate(e);
    }
    if (name === "start_time") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            startTimeError: "Please select start time "
          };
        });
      } else {
        setFormData((formValue) => {
          return {
            ...formValue,
            ["start_time"]: moment(e).format("HH:mm")
          };
        });
        setStartTime(e);
        let endDays = new Date(
          moments(e).format("YYYY-MM-DD") + " " + "23:59:59"
        );
        setEndDays(endDays);

        let diff = getTimeDifferenceCalculator(e, endDays);
        localStorage.removeItem("maxTime");
        localStorage.setItem("maxTime", diff);
        if (endTime) {
          let difference = getTimeDifferenceCalculator(e, endTime);
          let hours = parseInt(difference / 60);
          let min = difference % 60;
          setHours(
            `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`
          );
        }
      }
    }
    if (name === "end_time") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            endTimeErrorTimeError: "Please select end time "
          };
        });
      } else {
        var a = startTime
          ? new Date(startTime).getTime()
          : new Date().getTime();
        var b = new Date(e).getTime();
        if (a < b) {
          setError((errorValue) => {
            return {
              ...errorValue,
              endTimeError: ""
            };
          });

          setFormData((formValue) => {
            return {
              ...formValue,
              ["end_time"]: moment(e).format("HH:mm")
            };
          });
          setEndTime(e);
          if (startTime) {
            let difference = getTimeDifferenceCalculator(startTime, e);
            let hours = parseInt(difference / 60);
            let min = difference % 60;
            setHours(
              `${hours < 10 ? `0${hours}` : hours}:${
                min < 10 ? `0${min}` : min
              }`
            );
          }
        } else {
          setError((errorValue) => {
            return {
              ...errorValue,
              endTimeError: "please select greater time "
            };
          });
        }
      }
    }
  };

  const handlerChangCheck = (e, name) => {
    setFormData({ ...formData, [name]: e.target.checked ? "1" : "0" });
  };

  useEffect(() => {
    if (hours && multiplyOvertimeBy > 0) {
      const [hh, mm] = hours.split(":").map(Number);
      const totalMinutes = hh * 60 + mm;
      const multipliedMinutes = totalMinutes * multiplyOvertimeBy;
      const resultHours = Math.floor(multipliedMinutes / 60).toString().padStart(2, '0');
      const resultMinutes = (multipliedMinutes % 60).toString().padStart(2, '0');
      setResult(`${resultHours}:${resultMinutes}`);
    } else {
      setResult("00:00");
    }
  }, [hours, multiplyOvertimeBy]);

  useEffect(() => {
      if (isLoadingCreate) {
        toastGeneralId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessCreate) {
        setTimeout(() => {
          toast.dismiss(toastGeneralId.current);
        }, 10000);

        toast.update(
          toastGeneralId.current,
          ("Your Overpaid claim has been submitted",
          {
            render: "Your Overpaid Claim has been submitted",
            type: "success",
            autoClose: 5000,
            isLoading: false
          })
        );
        let payload = `/overtimes/?page=${1}&employee_id=${employeeIdData}`;
        myOvertimeList(payload);
        close();
      }
      if (isErrorCreate) {
        setTimeout(() => {
          toast.dismiss(toastGeneralId.current);
        }, 10000);
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.update(
          toastGeneralId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessCreate, isErrorCreate, isLoadingCreate]
  );

  const breakTimeData = [
    { value: "", label: "Select Break Time" },
    { value: "00:00", label: "No Break" },
    { value: "00:10", label: "10min" },
    { value: "00:15", label: "15min" },
    { value: "00:20", label: "20min" },
    { value: "00:25", label: "25min" },
    { value: "00:30", label: "30min" },
    { value: "00:35", label: "35min" },
    { value: "00:40", label: "40min" },
    { value: "00:45", label: "45min" }
  ];

  const validForm = () => {
    const { start_time, end_time, m_overtime_types_id } = formData;
    let isFormValid = true;
    if (m_overtime_types_id === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          overtimeTypesError: "Please select overtime "
        };
      });
      isFormValid = false;
    }

    if (start_time === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          startTimeError: "Please select start time "
        };
      });
      isFormValid = false;
    }
    if (end_time === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          endTimeError: "Please select end time "
        };
      });
      isFormValid = false;
    }
    return isFormValid;
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    if (validForm()) {

      //start time and end time in minutes
      const startMoment = moment(formData.start_time, "HH:mm");
      const endMoment = moment(formData.end_time, "HH:mm");

      //break in minutes
      const breakMinutes = moment.duration(formData?.break_time).asMinutes();

      //difference between start time and end time in minutes
      const durationWithoutBreak = endMoment.diff(startMoment, "minutes");

      //(duration * multiplyOvertimeBy)
      const multipliedDurationWithoutBreak = durationWithoutBreak * multiplyOvertimeBy;

      //(duration * multiplyOvertimeBy) - break 
      const multipliedDuration = (durationWithoutBreak * multiplyOvertimeBy) - breakMinutes;

      const updatedFormData = {
        ...formData,
        duration:
          formData?.is_paid === "1"
            ? multipliedDurationWithoutBreak.toString()
            : multipliedDuration.toString()
      };
      createOverTime(updatedFormData);
    }
  };

  return (
    <>
      <div className="header">
        <h3>Claim Overtime </h3>
      </div>
      <div className="content">
        <form>
          <div className="flex flex-wrap ">
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Overtime type
                  <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                </label>
                <select
                  className="formControl"
                  id="overtimeTypes"
                  name={"m_overtime_types_id"}
                  onChange={(e) => handlerChange(e)}
                >
                  <FormSelect optionValue={""} optionName={"Select Overtime"} />
                  ;
                  {allOverTimeData?.length > 0
                    ? allOverTimeData?.map((overtime, key) => {
                        return (
                          <FormSelect
                            key={key}
                            optionValue={overtime?.id}
                            optionName={overtime?.overtime_name}
                          />
                        );
                      })
                    : null}
                </select>
                <div className="help-block text-red-700 mt-1">
                  {error?.overtimeTypesError ? error?.overtimeTypesError : ""}
                </div>
              </div>
            </div>
            <div className="md:w-1/2 md:pr-0 w-full mb-4 md:mb-0">
              <label className="formBlock	">Date </label>
              <DatePicker
                // showIcon
                dateFormat="dd/MM/yyyy"
                id="date"
                className="formControl"
                name="start_date"
                selected={
                  formData?.start_date
                    ? new Date(formData?.start_date)
                    : new Date()
                }
                onChange={(e) => handlerDateChange(e, "start_date")}
                maxDate={new Date()}
                startDate={startDate}
                selectsStart
                locale="en-GB"
              />
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Time
                  <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                </label>
                <div className="flex">
                  <div className="w-full pr-4">
                    <DatePicker
                      className="formControl"
                      id="time"
                      onChange={(e) => handlerDateChange(e, "start_time")}
                      selected={startTime ? startTime : new Date()}
                      popperPlacement="top-start"
                      showTimeSelectOnly
                      showTimeSelect
                      timeIntervals={15}
                      timeFormat="HH:mm"
                      dateFormat="hh:mm aa"
                      minDate={new Date()}
                    />
                    <div className="help-block  text-red-700">
                      {error?.startTimeError ? error?.startTimeError : ""}
                    </div>
                  </div>
                  <div className="w-full">
                    <DatePicker
                      className="formControl"
                      id="endTime"
                      selected={endTime ? endTime : new Date()}
                      onChange={(e) => handlerDateChange(e, "end_time")}
                      popperPlacement="top-start"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeFormat="HH:mm"
                      dateFormat="hh:mm aa"
                      minTime={
                        startTime ? setMinutes(startTime, 30) : startDate
                      }
                      maxTime={endDays ? endDays : startDate}
                      minDate={endDays ? endDays : startDate}
                    />
                    <div className="help-block  text-red-700">
                      {error?.endTimeError ? error?.endTimeError : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2  w-full">
              <div className="form-group mb-2 ">
                <label className="formBlock	">Break</label>
                <select
                  className="formControl"
                  name="break_time"
                  id="break_time"
                  onChange={(e) => handlerChange(e)}
                >
                  {breakTimeData?.map((breakTime, index) => {
                    return (
                      <FormSelect
                        key={index}
                        optionValue={breakTime?.value}
                        optionName={breakTime?.label}
                      />
                    );
                  })}
                  ;
                </select>
                <div className="help-block" />
              </div>
              <div className="form-group mb-6  flex">
                <label className="formBlock flex	">
                  <input
                    type="checkbox"
                    name="is_paid"
                    id="paid"
                    className="mr-2 w-5 h-5"
                    onChange={(e) => handlerChangCheck(e, "is_paid")}
                  />
                  Paid
                </label>
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-3 ">
                <label className="formBlock	">Duration (Hours)</label>
                <input
                  type="search"
                  id="duration"
                  className="formControl"
                  value={result}
                  readOnly
                />
                <div className="help-block" />
              </div>
              <div className="form-group mb-6 ">
                <label className="formBlock flex	">
                  <input
                    type="checkbox"
                    className="mr-2 w-5 h-5"
                    name="time_in_lieu"
                    id="timeInLieu"
                    onChange={(e) => handlerChangCheck(e, "time_in_lieu")}
                  />
                  Book as time in Lieu
                </label>
                <div className="help-block" />
              </div>
            </div>

            <div className="w-full ">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Notes
                </label>
                <FormTextarea
                  textName="notes"
                  handlerChange={handlerChange}
                  inputID="notes"
                />
              </div>
            </div>
            <div className="lg:w w-full  ">
              <div className="w-full mt-4">
                <div className="form-group pt-4 pb-3 flex justify-center ">
                  <PrimaryButton
                    btnText=" Send for Approval"
                    Btnclass="btn save mr-2"
                    btnype="button"
                    buttonID="sendApproval"
                    onClick={(e) => handlerSubmit(e)}
                  />
                  <PrimaryButton
                    btnText="Cancel"
                    onClick={() => close()}
                    buttonID="sendCancel"
                    Btnclass="btn cancel"
                    btntype="button"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddMyOvertime;