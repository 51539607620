/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger */
import React, {useState, useRef, useEffect} from 'react';
import {
  FormInput,
  FormSelect,
  FormTextarea
} from "../../components/formInput";
import DatePicker from "react-datepicker";
import PrimaryButton from "../../components/primaryButton";
import { useCreateMainExpensesMutation, useDeleteByFileMutation} from '../../services/expenseApi';
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { useSelector } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const EditMyExpense = ({expense, allExpenseType, close, getAllMainExpenses, getAllMainExpensesCSVRecord}) => {
  const [ createMainExpense, {isSuccess: isSuccessCreate, isLoading: isLoadingCreate, isError: isErrorCreate, error:apiError } ] =  useCreateMainExpensesMutation();
   const [ deleteByFile, {isSuccess: isSuccessDelete, isLoading: isLoadingDelete, isError: isErrorDelete} ] = useDeleteByFileMutation();
  const [ startDate, setStartDate ] = useState();
    const [ fileList, setFileList ] = useState([]);
    const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
    const [ open, setOpen ] = useState(false);
    const validImageTypes = [ 'gif', 'jpeg', 'png', 'jpg' ];
    const closeModal = () => {
      setOpen(false);
    }; 
   
    const [ formData, setFormData ] = useState({
        "department_id": employeeIdData?.department_id,
        "company_id": "",
        employee_id: '',
        start_date: new Date(),
        // expenses_type:'',
        "end_date": "2023-06-28",
        reason: '',
        "is_mileage": "0",
        "type": "",
        amount: "",
        "amount_of_miles": "no description",
        "status": "0"
        
    });
  
  
    useEffect(() => {
      setFormData({
        ...formData,
        type: expense?.type
      });
      if (Array.isArray(expense?.expenseFiles)) {
        setFileList([ ...expense.expenseFiles ]);
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ expense ]);
    const  toastGeneralId = useRef(null);
    const handlerChange = (e) => {
        e.preventDefault();
        const {name} = e.target;
         setFormData({...formData, [name] : e.target.value});
    };

    const handlerDateChange = (e, name) => {
        if(name === 'start_date') {  
            setFormData((formValue) => {
            return {
             ...formValue, start_date: e
            };
           }); 
            setStartDate(e);
        }
    };

    const handleUpload = (e) => {
        // setFileList([ ...e.target.files ]);
        setFileList((files) => [ ...files,  ...e.target.files ]);
      };
      
    useEffect(() => {
        if (isLoadingCreate) {
          toastGeneralId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
        }
        if (isSuccessCreate) {
          toast.dismiss(toastGeneralId.current);
          toast.update(
            toastGeneralId.current,
            (" Expenses is Updated!",
            {
              render: "Expenses is Updated!",
              type: "success",
              autoClose: 2000,
              isLoading: false
            })
          );
          close();
         let payload = `/expenses/filter/search?page=${1}&employee_id=${employeeIdData?.employee_id}`;
          getAllMainExpenses(payload);
          let payload1=`/expenses/filter/search?page=${1}&employee_id=${employeeIdData?.employee_id}&limit=100`;
          getAllMainExpensesCSVRecord(payload1);
        }
        if (isErrorCreate) {
         
          const toastMessage = apiError.data.message
            ? apiError.data.message
            : "Something went wrong";
          toast.update(
            toastGeneralId.current,
            (toastMessage,
            {
              render: toastMessage,
              type: "error",
              autoClose: 5000,
              isLoading: false, 
              delay:10
            })
          );
        }
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessCreate, isErrorCreate, isLoadingCreate ]);
      
      useEffect(() => {
        if (isLoadingDelete) {
          toastGeneralId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
        }
        if (isSuccessDelete) {
          toast.dismiss(toastGeneralId.current);
          toast.update(
            toastGeneralId.current,
            (" Expenses file deleted!",
            {
              render: "Expenses file deleted!",
              type: "success",
              autoClose: 2000,
              isLoading: false
            })
          );
         
        //  let payload = `/expenses/filter/search?page=${1}&employee_id=${employee?.employee_id}`;
        //   getAllMainExpenses(payload);
        //   let payload1=`/expenses/filter/search?page=${1}&employee_id=${employee?.employee_id}&limit=100`;
        //   getAllMainExpensesCSVRecord(payload1);
        }
        if (isErrorDelete) {
         
          const toastMessage = apiError?.data?.message
            ? apiError.data.message
            : "Something went wrong";
          toast.update(
            toastGeneralId.current,
            (toastMessage,
            {
              render: toastMessage,
              type: "error",
              autoClose: 5000,
              isLoading: false, 
              delay:10
            })
          );
        }
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessDelete, isErrorDelete, isLoadingDelete ]);

      const  handlerDelete = (e, f, close, fileId) => {
  
        e.preventDefault();
        if(fileId !== undefined) {
        deleteByFile(fileId);
        const removeFile = fileList?.filter((f) => f.name !== f?.name);
          setFileList(removeFile);
        } else {
          const removeFile = fileList?.filter((f) => f.name !== f?.name);
          setFileList(removeFile);
        }
        close();
      };


   
    const handlerSubmit = (e, expense) => {
     e.preventDefault();

     var formdata = new FormData();
          formdata.append("department_id", employeeIdData?.department_id);
          formdata.append("employee_id", expense?.employee_id? expense?.employee_id : employeeIdData?.employee_id);
          formdata.append("start_date", formData?.start_date? formData?.start_date : expense.start_date);
          // expenses_type:'',
          formdata.append("end_date", formData?.end_date);
          formdata.append("reason", formData.reason ? formData.reason : expense.reason);
          formdata.append("is_mileage", formData?.is_mileage);
          formdata.append("type", formData?.type);
          formdata.append("amount", formData.amount ? formData.amount : expense.amount);
          // formdata.append("amount_of_miles", );
          formdata.append("status", "0");
          formdata.append("expense_id", expense.id);
          fileList.forEach((file) => {
          formdata.append("evidence_file", file);
          });
     createMainExpense(formdata);
    };
    return(
        <>
        <div className="header">
        <h3>Edit Expense</h3>
        </div>
        <div className="content">
        <form>
            <div className="flex flex-wrap ">
            <div className="md:w-1/2 md:pr-6 w-full">
                <div className="form-group mb-6 ">
                <label className="formBlock	">Date</label>
                <DatePicker
                    // showIcon
                    dateFormat="dd/MM/yyyy"
                    className="formControl"
                    id="editExpenseStartDate"
                    name = "start_date"
                    selected = {startDate ? startDate : new Date(expense.start_date)}
                    onChange={(e) =>  handlerDateChange(e, "start_date")}
                    maxDate={new Date()}
                    selectsStart
                    startDate={startDate}
                    locale="en-GB"
                />
                <div className="help-block" />
                </div>
            </div>
            <div className="md:w-1/2  w-full">
                <div className="form-group mb-6 ">
                <label className="formBlock	">
                    Expense Type
                    <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                </label>
                <select className="formControl" id="editExpenseType" name="type" value={formData.type} onChange={(e) => handlerChange(e)}>
                <FormSelect 
                optionValue={"0"}
                optionName={"Select"}
                />
                {allExpenseType?.length > 0 ? allExpenseType?.map((exp, key) => { 
                    return   <FormSelect key={key} optionValue={exp?.id} optionName={exp?.expense_name} />;
                }) : null}
                </select>

                <div className="help-block" />
                </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
                <div className="form-group mb-6 ">
                <label className="formBlock	">Amount <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> </label>
                <FormInput
                    inputType="text"
                    inputName="amount"
                    inputID="Amount"
                    value= {formData.amount ? formData.amount : expense.amount}
                    handlerChange={handlerChange}
                />
                <div className="help-block" />
                </div>
            </div>
            <div className="w-full ">
                <div className="form-group mb-6 ">
                <label className="formBlock	">
                    Description{" "}
                    {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                </label>
                <FormTextarea textName="reason" id="editExpenseDescription" value={formData.reason ? formData.reason : expense.reason} handlerChange = {handlerChange} />
                <div className="help-block" />
                </div>
            </div>
            <div className="w-full">
                <div className="form-group mb-6 fileUploadInPopup">
                <label className="formBlock	">
                    Upload Evidence{" "}
                    <span className="text-[11px]">
                    (Supported formates: JPEG, PNG, PDF,
                    Word, PPT)
                    </span>
                </label>
                <div className='file_Upload'>
                  <input 
                    type="file"
                    name="file" 
                    className= "upload-button"
                    id="exampleFile" 
                    onChange={handleUpload} multiple
                    />
                    <p>Drop files here or click here to upload file.</p>
                </div>
               
              <span>Maximum upload file size:25 MB.</span> 
            
              <div className="help-block" />
              </div>
              <>
              
              <div className="form-group mb-6 ">
                 <ul className='flex flex-row overflow-scroll'>
                  <>
                  { [ ...fileList ]?.map((f, index) => {
                   let  FileExtension = f.name? f.name.split('.').pop():'';
                    //  FileExtension = f.file_name.split('.').pop();
                    
                    return(
                      <li key={index} className='m-2'>
                       <div className='border-2 h-[90px] w-[90px] overflow-hidden mb-1'>
                       <img
                          src={f?.file_name}
                          alt={f?.name}
                          style={{ maxWidth: '100px', maxHeight: '100px'}} 
                        />
                       </div>
                  
                    <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-trash"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="MyExpenseClose"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Delete</h3>
                      </div>
                      <div className="content">
                        <p>Are you sure you want to delete file ?</p>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" id="myExpenseYes" onClick={(e) => handlerDelete(e, f, close, f.id)}>Yes</button>
                            <button
                              type="button"
                              id="myExpenseNo" 
                              onClick={() => close()}
                              className="btn cancel  "
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                      </Popup>
                      {validImageTypes.includes(FileExtension)? 
                        <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                        <i className="fa fa-download " />
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="expenseDetailClose"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                     
                     <img src= {f.file_name} height="200" width="300" />
                     <a href={f.file_name}>Dowload</a>
                    </div>
                  )}
                </Popup> :
                <a href={f.file_name} className='actionBtn'> <i className="fa fa-download" /></a>
                }
                      </li>
                    );
                  })}

                
                </>
                </ul> 
                </div>
                </>
            </div>
            {/* <div className="pr-0 w-full">
            <div className="form-group mb-6 ">
                <button className="btn-md btn-add"><span className="fa fa-plus mr-2"></span>Add Another Expense </button>
                </div>
            </div>                          */}

            <div className="lg:w w-full  ">
                <div className="w-full mt-4">
                <div className="form-group pt-4 pb-3 flex justify-center ">
                    <PrimaryButton
                    btnText="Save"
                    Btnclass="btn save mr-2"
                    btnype="button"
                    buttonID="editExpenseSave"
                    onClick={(e) => handlerSubmit(e, expense)}
                    />
                    <PrimaryButton
                    btnText="Cancel"
                    onClick={() => close()}
                    Btnclass="btn cancel"
                    btntype="button"
                    buttonID="editExpenseCancel"
                    />
                </div>
                </div>
            </div>
            </div>
        </form>
        </div>
        </>
    );
};

export default EditMyExpense;