/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PrimaryButton from "../../components/primaryButton";
import SwitchBtn from "../../components/switchBtn";
import { useEffect, useState, useRef } from "react";
import { useCreateCompanyMutation } from "../../services/companyApi";
import { toast } from "react-toastify";
const PrivacyContent = ({companyData, privacyUpdatePermissions}) => {
  const [ createCompany, { isSuccess : isSuccessPrivacy, isLoading, isError, error:apiError} ] = useCreateCompanyMutation();
  const [ isLeave, setIsLeave ] = useState(false);
  const [ isCalendar, setIsCalendar ] = useState(false);
  const [ isDepartment, setIsDepartment ] = useState(false);

  const handlerSave = (e) => {
    e.preventDefault();
     let value = {
    "leave":isLeave ? "1" : "0",
    "calendar":isCalendar ? "1" :"0",
    "department":isDepartment ? "1" :"0"
  };
   createCompany(value);
  };
  const toastId = useRef(null);
  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccessPrivacy) {
      toast.update(
        toastId.current,
        ("Company Privacy Data Updated Successfully!",
        {
          
          render: "Company privacy data updated successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
    }
    if (isError) {
     
      const toastMessage = apiError.data.message
        ? apiError.data.message
        : "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessPrivacy, isError, isLoading ]);
  
  useEffect(() => {
    if(companyData?.companyData !== undefined) {
     
      if(companyData.companyData.leave === "1"){
        setIsLeave(true);
      } 
      if(companyData.companyData.calendar === "1"){
        setIsCalendar(true);
      }
      if(companyData.companyData.department === "1"){
        setIsDepartment(true);
      }
     
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ companyData ]);
  
 
  return (
    <>
     {/* <ToastContainer    containerId={'privacy1'} /> */}
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <h3 className=" font-semibold text-lg">Privacy </h3>
      </div>
      <div className="p-4">
        <div className=" border-b  pb-8 ">
          <div className="flex items-center mb-4">
            <h4 className="w-48">Leave types</h4>
            <SwitchBtn 
            value={isLeave}
            onChange={(e) => setIsLeave(e.target.checked)}
            name="isLeave"
             />
          </div>
          <p>
            Leave Types are the reasons people are away from work. Hiding means
            colleagues cannot see why someone is off work - other than their
            manager/ approver or admin users.
          </p>
        </div>
        <div className=" border-b  py-8">
          <div className="flex items-center mb-4">
            <h4 className="w-48">Absence</h4>
            <SwitchBtn 
              value={isCalendar}
              onChange={(e) => setIsCalendar(e.target.checked)}
              name="isCalendar"
            />
          </div>
          <p>
            Absence is the reasons employees take unplanned time way from work.
            Hiding means colleagues cannot see the reason why someone is absent-
            other than their manager/ approver or admin users.
          </p>
        </div>
        <div className="py-8">
          <div className="flex items-center mb-4">
            <h4 className="w-48">Other departments</h4>
            <SwitchBtn
             value={isDepartment}
             onChange={(e) => setIsDepartment(e.target.checked)}
             name="isDepartment"
              />
          </div>
          <p>
            If departmental calendars are visible, users can access and view
            schedules from any department. However, when departments are hidden,
            users are limited to viewing only their own department's calendar.
            It is important to note that this restriction does not apply to
            admin users, who have unrestricted access to all calendars.
          </p>
        </div>
        {privacyUpdatePermissions[0]?.status === true && 
             <div className="w-full mt-8">
             <div className="form-group pt-4 pb-3 flex  ">
               <PrimaryButton Btnclass="btn save mr-2" id="privacySave" onClick={(e) => handlerSave(e)}  btnText="Save" />
               {/* <PrimaryButton Btnclass="btn cancel mr-2" btnText="Cancel" /> */}
             </div>
           </div>}
   
      </div>

    </>
  );
};

export default PrivacyContent;
