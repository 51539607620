/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable */

import { useState, useRef, useEffect } from 'react';
import { FormInput } from "../../components/formInput";
import { FormSelect } from "../../components/formInput";
import PrimaryButton from '../../components/primaryButton';
import { useUpdateEmployeeAllowancesMutation, useUpdateEmployeeByIdMutation } from '../../services/employeeApi';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useGetFilterHolidayMutation } from "../../services/holidayApi";
import moment, * as moments from 'moment';
import { formatDate } from "../../helper/LeaveHelper";
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';

const EmployeeBankHoliday = ({ employeeInfo, setEmployeeInfo, activeEmployeeUpdatePermissions, myprofileReadOnly = false }) => {

  const navigate = useNavigate();
  const [updateEmployeeById, { isSuccess, isLoading, isError, error: apiError }] = useUpdateEmployeeByIdMutation();
  const [getFilterHoliday, { data: filterHolidayRespo, isSuccess: isSuccessFilter }] = useGetFilterHolidayMutation();
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const [holidayData, setHolidayData] = useState("");
  const [year, setYear] = useState();
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const toastId = useRef(null);
  const countryOptions = [
    { value: "", name: "None" },
    { value: 1, name: "England + Wales" },
    { value: 2, name: "Scotland" },
    { value: 3, name: "Northern Ireland" }
  ];

  useEffect(() => { // rather in onclick handlerChange(e ), used useffect  on page load required data will  be shown 
    if (employeeInfo.location_id) {
      getFilterHoliday({ location_id: employeeInfo.location_id, year: moments(year).format('yyyy') });
    } else {
      setHolidayData(null)
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[employeeInfo.location_id, year])

  useEffect(() => {
    if (filterHolidayRespo) {
      if (filterHolidayRespo.data) {
        setHolidayData(filterHolidayRespo.data);
      }
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isSuccessFilter]);

  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
        position:"top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccess) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.update(
        toastId.current,
        ("Bank holiday updated successfully.",
        {

          render: "Bank holiday updated successfully.",
          type: "success",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
    if (isError) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      const toastMessage = apiError?.data?.message
        ? apiError?.data?.message
        : "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isSuccess, isError, isLoading]);

  const handlerChange = (e) => {
    const { name, value } = e.target;
    setEmployeeInfo({ ...employeeInfo, [name]: e.target.value });
  };

  const handlerSubmit = (e) => {

    e.preventDefault();
    const id = employeeInfo.id;
    const formData = {
      location_id: employeeInfo.location_id
    };
    updateEmployeeById({id:id, formData: formData});
  };

  const handleCancle = (e) => { 
    close();
  };

  const handlerYearChange = (e, year) => {
    setYear(e);
    getFilterHoliday({ location_id: employeeInfo?.location_id, year: moments(e).format('yyyy') });
  };

  return (

    <>
      <div className="lg:p-7 p-4">
        <div className="content">
          <form >
            <div className=" w-full pr-0 mt-2">
              <div className="form-group mb-6 flex justify-between ">
                <div className="w-1/2 ">
                  {/* <label className="formBlock	">Country</label> */}
                  <label className="formBlock	">Bank Holidays</label>
                  <select
                    className="formControl"
                    name="location_id"
                    id="bankHoliday"
                    value={employeeInfo?.location_id}
                    onChange={handlerChange}
                  // selected={employeeInfo?.data[""]?.location_id}
                  >
                    {countryOptions?.map((country, index) => {
                      return <FormSelect key={index} optionValue={country?.value} optionName={country?.name} />;
                    })}
                  </select>
                  {/* <div className="help-block text-red-700">{error?.selectCountry}</div> */}
                </div>
                <div className="w-1/2 mt-7 ml-2 pl-2">
                  <DatePicker
                  id="date"
                    selected={year ? year : new Date()}
                    className="formControl"
                    showYearPicker
                    dateFormat="yyyy"
                    onChange={(e) => handlerYearChange(e, 'year')}
                  />
                </div>
              </div>
              <div className="form-group mb-6">
                <div className="overflow-x-auto w-full whitespace-nowrap">
                  <table className="table-auto table w-full">
                    <thead className="thead-dark ">
                      <tr>
                        <th className="md:w-80">Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                      </tr>
                    </thead>
                    <tbody>{holidayData?.length > 0 ?
                      <>
                        {holidayData?.map((holi, index) => {
                          let startDate = holi.start_date.split('T')[0];
                          let endDate = holi.end_date.split('T')[0];

                          return (
                            <tr key={index}>
                              <td>{holi?.name}</td>
                              <td>{moments(startDate).format(formattedDate)}</td>
                              <td>{moments(endDate).format(formattedDate)}</td>

                            </tr>
                          );
                        })}
                      </>
                      : <center className='text-red-700 mt-2 ml-[170%]'>No holiday data</center>}
                    </tbody>
                  </table>
                </div>
              </div>

              {(activeEmployeeUpdatePermissions[0]?.status === true || !myprofileReadOnly) &&
                <div className="lg:w w-full  ">
                  {roleIdData === 4 ? null :
                  <div className="w-full mt-8">
                    <div className="form-group pt-8 pb-3 flex border-t  ">
                      <PrimaryButton
                        btnText="Save"
                        buttonID="Save"
                        Btnclass="btn save mr-2"
                        onClick={handlerSubmit}
                      />
                     {/*  <PrimaryButton
                        btnText="Cancel"
                        buttonID="Cancel"
                        Btnclass="btn cancel "
                        onClick={handleCancle }
                      /> */}
                    </div>
                  </div>}
                </div>}
            </div>
          </form>
        </div >
      </div >
    </>
  );
};
export default EmployeeBankHoliday;