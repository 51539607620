/* eslint-disable camelcase */
import { useState, useEffect, useRef} from "react";
import {
  FormSelect
} from "../../components/formInput";
import {  toast } from "react-toastify";

import PrimaryButton from '../../components/primaryButton';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";




const ApproverEmployee = ({employeeInfo, handelChange, isApproveSuccess, approverList, errorValidations,
   setEmployeeInfo, updateEmployeeById, isProfileUpdate,
  isLeave, setIsLeave, isExpenses, setIsExpense, isOverTime, isSetOverTime,
  setIsSame, isSame, isProfileError,
  isprofileLoading, 
   profileError, activeEmployeeUpdatePermissions

}) => {
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const navigate = useNavigate();
  // const [ isSame, setIsSame ] = useState(false);
  const [ approverListData, setApproverListData ] = useState();
  const [ leaveListData, setLeaveListData ] = useState();
  // const [ getEmployeeDetail, {data: employeeData} ] =
  //   useGetEmployeeDetailMutation();
  const [ expenseListData, setExpenseListData ] = useState();
  const [ errorValidation, setErrorValidations ] = useState();
  const toastId = useRef(null);
  useEffect(() => {
    if(isSame){
      setEmployeeInfo({...employeeInfo, overtime_lieu_approver: employeeInfo?.leave_approver,
        expense_approver: employeeInfo?.leave_approver});
        // setSameApproverChecked(true);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSame ]);
  const handleCheckApprovers = (e) => {
    // let {name} = e.target;
    // let fieldName = name === 'isApproveLeave' ? employeeInfo.employee_can_approve_leave :
    //  name === 'isApproveExpense' ? employeeInfo.employee_can_approve_expense: 
    //  name === "isApproveOvertime" ? employeeInfo.employee_can_approve_ovetime_lieu :"";
    //  setEmployeeInfo({...employeeInfo, [name]: !fieldName});
    
    let {name} = e.target;
        if(name === 'isApproveLeave') {
          setIsLeave(e.target.checked);
          setEmployeeInfo({...employeeInfo, ['employee_can_approve_leave']: e.target.checked? "1" : "0"});
           
        } else if(name === 'isApproveExpense') {
          setIsExpense(e.target.checked);
          setEmployeeInfo({...employeeInfo, ['employee_can_approve_expense']: e.target.checked ? "1" : "0"});
        } else if(name === "isApproveOvertime") {
          isSetOverTime(e.target.checked);
          setEmployeeInfo({...employeeInfo, ['employee_can_approve_ovetime_lieu']: e.target.checked ? "1" : "0"});
        }
        
  };
  const handelApproveCancle = () => {
    navigate('/employees');
  };

  const handelApproveSave = () => {
      let formdata = new FormData();
    formdata.append("overtime_lieu_approver", employeeInfo?.overtime_lieu_approver === '0' || employeeInfo?.overtime_lieu_approver === null  ? '' : employeeInfo?.overtime_lieu_approver);
    formdata.append("expense_approver", employeeInfo?.expense_approver === '0' || employeeInfo?.expense_approver === null? '': employeeInfo?.expense_approver );    
    formdata.append("leave_approver", employeeInfo?.leave_approver === '0' || employeeInfo?.leave_approver === null ?'' : employeeInfo?.leave_approver); 
    formdata.append("employee_can_approve_leave", employeeInfo.employee_can_approve_leave); 
    formdata.append("employee_can_approve_expense", employeeInfo?.employee_can_approve_expense);
    formdata.append("employee_can_approve_ovetime_lieu", employeeInfo?.employee_can_approve_ovetime_lieu);
    // 
    updateEmployeeById({id:employeeInfo?.id, formData: formdata});
    // }
  };
  
  useEffect(() => {
    if (isprofileLoading) {
      toastId.current = toast.loading("Please wait...", {
      // position: "top-center",
      position:"top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if(isProfileUpdate){
      toast.dismiss(toastId.current);
      toast.success("Employee Update Successfully",
      {
        render: "Employee Update Successfully",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "success"
      }
    );
    }
    if(isProfileError){
      toast.dismiss(toastId.current);
      const message =profileError?.data?.message ? profileError?.data?.message: "Something went wrong";
      toast.update(
        toastId.current,
        (message,
        {
          render: message,
          type: "error",
          autoClose: 8000,
          isLoading: false
        })
      );
   
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isprofileLoading, isProfileUpdate, isProfileError ]);
  useEffect(() => {
    if(approverList?.data?.leaveApprover.length > 0) {
      setLeaveListData(approverList.data.leaveApprover);
    }
    if(approverList?.data?.expenseApprover.length > 0) {
      setExpenseListData(approverList.data.expenseApprover);
    }
    if(approverList?.data?.ovetime_lieuApprover.length > 0) {
      setApproverListData(approverList.data.ovetime_lieuApprover);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isApproveSuccess ]);

const handelMultiApprove = () => {

  if(approverList?.data?.leaveApprover.length > 0 &&
  approverList?.data?.expenseApprover.length > 0 && 
  approverList?.data?.ovetime_lieuApprover.length > 0){
    if ((employeeInfo?.expense_approver !== null || employeeInfo?.expense_approver !== 0 )&& (employeeInfo?.leave_approver !== null || employeeInfo?.leave_approver !== 0)  )  {
    setIsSame(!isSame);
    }
    
  } 
  
  else  {
    setErrorValidations('No data in list so unable to check');
    setIsSame(false);
  } 
};

  return (
    <div className="p-7">
    <div className="flex justify-between w-full flex-wrap md:flex-nowrap mt-4">
      <div className="md:w-1/2 w-full">
        <h4 className="mb-3 bg-primary text-white p-3 rounded ">
          Approver settings:
        </h4>
        <p>
          This is used to allocate which employee will approve
          this employee’s requests
        </p>
        <div className="w-full mt-4">
  <div className="form-group mb-2 ">
    <label className="formBlock	">Leave Approver</label>
    <select name="leave_approver"
    id="leaveApprove"
    disabled={isSame} 
    value={employeeInfo?.leave_approver}
    onChange={handelChange} className="formControl">
    <FormSelect 
       optionValue={"0"}
      optionName={"Select"} />;
    {leaveListData ? leaveListData?.map((approver, index) => { 
      return  <FormSelect key={index} optionValue={approver?.id} optionName={`${approver?.first_name} ${approver?.last_name}`} />;
    }) : null}
    </select>
    <div className="help-block text-red-700">{errorValidations?.leaveApprover}</div>
  </div>
</div>
<div className="w-full">
  <div className="form-group mb-3 ">
    <label className="formBlock flex	">
      <input type="checkbox" onChange={handelMultiApprove} checked={isSame} id="approvalCategories" className="mr-2 w-5 h-5" />
      Apply the same approver across all approval categories
    </label>
    <div className="help-block text-red-700">{errorValidation}</div>
  </div>
</div>
<div className="w-full">
  <div className="form-group mb-3 ">
    <label className="formBlock	">Overtime Approver</label>
    <select name="overtime_lieu_approver"
    id="overtimeApprovar"
    disabled={isSame} 
    value={employeeInfo?.overtime_lieu_approver}
     onChange={handelChange} className="formControl">
    <FormSelect 
       optionValue={"0"}
      optionName={"Select"}
     />;
    {approverListData?.map((approver, index) => { 
      return  <FormSelect key={index} optionValue={approver?.id} optionName={`${approver?.first_name} ${approver?.last_name}`} />;
    })}
    </select>
    <div className="help-block text-red-700">{errorValidations?.overtimeApprover}</div>
  </div>
</div>
<div className="w-full">
  <div className="form-group mb-6 ">
    <label className="formBlock	">Expenses Approver</label>
    <select name="expense_approver" 
    id="expenseApprover"
    disabled={isSame} 
    value={employeeInfo?.expense_approver}
    onChange={handelChange} className="formControl">
    <FormSelect 
      optionValue={"0"}
      optionName={"Select"} />;
    {expenseListData?.map((approver, index) => { 
      return  <FormSelect key={index} optionValue={approver?.id} optionName={`${approver?.first_name} ${approver?.last_name}`} />;
    })}
    </select>
    <div className="help-block text-red-700">{errorValidations?.expenseApprover}</div>
  </div>
</div>
      </div>
      <div className="md:w-4 md:px-8 w-full mb-5 md:mb-0">
        <div className="md:w-1 md:h-full bg-gray-100 w-full h-1" />
      </div>
      <div className="md:w-1/2 w-full lg:pr-0">
                  <h4 className="mb-3 bg-secondry text-white p-3 rounded text-base">
                    Make employee an approver:
                  </h4>
                  <p>
                    This is used to give an employee ‘approver status’ so they
                    can approve requests from their allocated employees.
                  </p>
                  <div className="form-group mb-10 mt-4">                    
                    <label className="formBlock flex	">                      
                      <input type="checkbox"
                      id="ApproveLeave"
                       name="isApproveLeave" 
                      onChange={(e) =>  handleCheckApprovers(e)
                        
                      } 
                    
                      checked={isLeave} className="mr-2 w-5 h-5" />{" "}
                      Employee can approve leave requests
                    </label>
                    <div className="help-block text-red-700" />
                  </div>
                  <div className="form-group mb-10 ">
                    <label className="formBlock flex	">
                      <input type="checkbox" name="isApproveExpense" id="ApproveExpense"
                       onChange={(e) =>  handleCheckApprovers(e)
                       
                      } 
                       checked={isExpenses}
                        className="mr-2 w-5 h-5" />{" "}
                      Employee can approve expenses claims
                    </label>
                    <div className="help-block text-red-700" />
                  </div>
                  <div className="form-group mb-6 ">
                    <label className="formBlock flex	">
                      <input type="checkbox" name="isApproveOvertime"  id="ApproveOvertime"
                      onChange={(e) => handleCheckApprovers(e)
                         
                      } 
                      checked={isOverTime} className="mr-2 w-5 h-5" />
                      Employee can approve overtime/time in lieu requests
                    </label>
                    <div className="help-block text-red-700" />
                  </div>
                </div>
    </div>
    {employeeInfo.status === "1" && activeEmployeeUpdatePermissions[0]?.status === true ?
    <div className="lg:w w-full  ">
      <div className="w-full mt-8">
        {roleIdData === 4 ? null :
        <div className="form-group pt-8 pb-3 flex border-t  ">
          <PrimaryButton
            btnText="Save"
            buttonID="saveEmployee"
            Btnclass="btn save mr-2"
            onClick={handelApproveSave}
          />
          <PrimaryButton
            btnText="Cancel"
            buttonID="cancelEmployee"
            Btnclass="btn cancel "
            onClick={handelApproveCancle}
          />
        </div>}
      </div>
    </div> : null }
  </div>
  );
};

export default ApproverEmployee;