import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  overTime: []
};

export const overTimeSlice = createSlice({
  name: "overTimeSlice",
  initialState,
  reducers: {
    addOverTime: (state, action) => {
       state.overTime.movertimetype.rows.push(action.payload.movertime);
     
    },
     overTimeInfo: (state, action) => {
     
     state.overTime = action.payload;
    },
    updatedOverTime: (state, action) => {
      //  const {id, name, color, status} = action.payload;
      //  const existingOverTime = state.overTime.data.find((over) => over.id === id);
      //  if(existingOverTime) {
      //   existingOverTime.name = name;
      //   existingOverTime.color = color;
      //   existingOverTime.status = status;
      // }
      const {id} = action.payload;
      const index = state.overTime.movertimetype.rows.findIndex((obj) => obj.id === id);
      state.holiday.data[index] = action.payload.updatedHoliday;
  },
  deletedOverTime: (state, action) => {
    const data = state.overTime.movertimetype.rows.filter((overtime) => {
     return overtime.id !== action.payload;
    });
      state.overTime.movertimetype.rows = data;
  }
}
});

export const {addOverTime, overTimeInfo,  updatedOverTime,  deletedOverTime} = overTimeSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default overTimeSlice.reducer;