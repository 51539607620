import { useEffect, useRef, useState } from "react";
import { FormInput } from "../../components/formInput";
import PrimaryButton from "../../components/primaryButton";
import { useAddAuditorMutation, useEditAuditorMutation, useGetCompanyListsQuery } from "../../services/auditorsApi";
import { toast } from "react-toastify";
import Select from "react-select";

const AddAuditors = ({ listOfAuditors, auditors, close }) => {
  
//States and hooks
const toastId = useRef();
const [ combinedOptions, setCombinedOptions ] = useState([]);
const [ selected, setSelected ] = useState();
const [ formData, setFormData ] = useState({
    name: "",
    email: "",
    mobile_no: "",
    password: ""
});
// eslint-disable-next-line no-unused-vars
const [selectedOptions, setSelectedOptions] = useState([]);

//add auditor api  
const [ createdAuditor, {isSuccess: createdAuditorSuccess, isLoading: createdAuditorLoading, isError: createdAuditorError, error: apiError} ] = useAddAuditorMutation();
const [ editAuditor, {isSuccess: editAuditorSuccess, isLoading: editAuditorLoading, isError: editAuditorError, error: apiErrorEditAuditor} ] = useEditAuditorMutation();
const { data: companyList } = useGetCompanyListsQuery();

useEffect(() => {
    if (createdAuditorLoading) {
      toastId.current = toast.loading("Please wait...", {
        toastId: "overtime11",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (createdAuditorSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Auditor created successfully.", {
        render: "Auditor created successfully.",
        type: "success",
        autoClose: 10000,
        theme: "colored",
        isLoading: false
      });
      listOfAuditors();
      close();
    }
    if (createdAuditorError) {
      toast.dismiss(toastId.current);
      const toastMessage = apiError.data.message
        ? apiError.data.message
        : "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 10000,
        duplicate: false,
        isLoading: false
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [createdAuditorLoading, createdAuditorSuccess, createdAuditorError]);

useEffect(() => {
    if (editAuditorLoading) {
      toastId.current = toast.loading("Please wait...", {
        toastId: "overtime11",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (editAuditorSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Auditor updated successfully.", {
        render: "Auditor updated successfully.",
        type: "success",
        autoClose: 10000,
        theme: "colored",
        isLoading: false
      });
      listOfAuditors();
      close();
    }
    if (editAuditorError) {
      toast.dismiss(toastId.current);
      const toastMessage = apiErrorEditAuditor.data.message
        ? apiErrorEditAuditor.data.message
        : "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 10000,
        duplicate: false,
        isLoading: false
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [editAuditorLoading, editAuditorSuccess, editAuditorError]);

  useEffect(() => {
      let mappedOptions = [];
      {
        companyList?.data?.length > 0 &&
            companyList?.data?.map((companyList) => {
            mappedOptions.push({
              value: companyList?.id,
              label: (
                <>
                  <div className="w-full">
                    <div className="flex items-center w-full">
                      <div className="flex justify-between w-11/12">
                        <div className="pr-1">
                          <h3 className="text-[13px]">
                            {companyList?.name}
                          </h3>
                          <span className="text-[11px]">{companyList?.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            });
          });
      }
      setCombinedOptions(() => [...mappedOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyList]);

    useEffect(() => {
        if (selected && selected?.length > 0) {
            const values = selected?.map((option) => option?.value);
            setSelectedOptions(values);
        }
    }, [selected]);

    // Set default selected companies from auditors prop
    useEffect(() => {
        if (auditors?.companies?.length > 0) {
            const defaultSelected = auditors?.companies?.map((company) => ({
                value: company?.id,
                label: (
                    <>
                        <div className="w-full">
                            <div className="flex items-center w-full">
                                <div className="flex justify-between w-11/12">
                                    <div className="pr-4">
                                        <h3 className="text-[14px]">
                                            {company?.name}
                                        </h3>
                                        <span>{company?.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }));
            setSelected(defaultSelected);
        }
    }, [auditors]);

    const handleSelectedCompany = (selectedOption) => {
        setSelected(selectedOption);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      if(auditors?.id){
        let val = {
          name: formData?.name ? formData?.name : auditors?.name,
          email: formData?.email ? formData?.email : auditors?.email,
          mobile_no: formData?.mobile_no ? formData?.mobile_no : auditors?.mobile_no.toString(),
          company_ids: selectedOptions,
          password: formData?.password ? formData?.password : auditors?.password
        };
        editAuditor({id: auditors?.id, payload: val});
      }else{
        let val = {
          name: formData?.name,
          email: formData?.email,
          mobile_no: formData?.mobile_no,
          company_ids: selectedOptions,
          password: formData?.password
        };
        createdAuditor(val);
      }
    };

    return (
        <>
        <div className="header">
            <h3>{auditors?.id ? "Edit External User" : "Add External User"}</h3>
        </div>
        <div className="content">
            <form>
            <div className="flex flex-wrap ">
                <div className="md:w-1/2 md:pr-6 w-full">
                  <div className="form-group mb-6 ">
                      <label className="formBlock	">
                      Name
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <FormInput
                      inputType="text"
                      inputName="name"
                      inputID="name"
                      handlerChange={handleInputChange}
                      value={auditors?.name}
                      />
                  </div>
                </div>
                <div className="md:w-1/2 w-full">
                  <div className="form-group mb-6 ">
                      <label className="formBlock	">
                          Email
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <FormInput
                      inputType="text"
                      inputName="email"
                      inputID="email"
                      handlerChange={handleInputChange}
                      value={auditors?.email}
                      />
                  </div>
                </div>
                <div className="md:w-1/2 md:pr-6 w-full">
                  <div className="form-group mb-6 ">
                      <label className="formBlock	">
                          Mobile No.
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <FormInput
                      inputType="text"
                      inputName="mobile_no"
                      inputID="mobile_no"
                      handlerChange={handleInputChange}
                      value={auditors?.mobile_no}
                      />
                  </div>
                </div>
                <div className="md:w-1/2  w-full">
                  <div className="form-group mb-6 ">
                      <label className="formBlock	">
                          Password
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <FormInput
                      inputType="text"
                      inputName="password"
                      inputID="password"
                      handlerChange={handleInputChange}
                      value={auditors?.password}
                      />
                  </div>
                </div>
                <div className=" w-full mb-10">
                  <div className="form-group mb-6 ">
                    <label className="formBlock	">
                      Company
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                    </label>
                    <Select
                      isMulti
                      name="selectedcompany"
                      className="basic-multi-select w-[100%] "
                      classNamePrefix="Select"
                      options={combinedOptions}
                      value={selected}
                      onChange={(selectedOption) => handleSelectedCompany(selectedOption)}
                    />
                  </div>
                </div>             
                <div className="md:w w-full  ">
                  <div className="w-full mt-4">
                    <div className="form-group pt-4 pb-3 flex justify-center ">
                      <PrimaryButton
                        Btnclass="btn save mr-2"
                        btnText="Save"
                        buttonID="overtimeTypesSave"
                        onClick={handleSubmit}
                      />
                      <PrimaryButton
                        Btnclass="btn cancel "
                        btnText="Cancel"
                        buttonID="overtimeTypesCancel"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
        </div>
        </>
    );
    };

    export default AddAuditors;
