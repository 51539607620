import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    expenseReportData: []
};

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    expenseReportInfoAction: (state, action) => {
    state.expenseReportData = action.payload;
    }

  }

});

export const { expenseReportInfoAction } = reportSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default reportSlice.reducer;