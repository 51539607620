/* eslint-disable camelcase */
/* eslint-disable no-debugger */
import { useState } from "react";
import Popup from "reactjs-popup";
import PrimaryButton from "../../components/primaryButton";
import { useDeleteEmployeeFileMutation, useGetEmployeeFilesMutation, usePostEmployeeFileMutation } from "../../services/employeeDocumentAPI";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import SharedFileData from "./shareFileData";


const DocumentFiles  = ({ selectedFolder, toggleState}) => {
    const [ selectedFile, setSelectedFile ] = useState();
    const [ postEmployeeFile, { isSuccess: postEmployeeFileSuccess, isError: postEmployeeFileError, error: errorFromApi, isLoading: postEmployeeFileLoading } ] = usePostEmployeeFileMutation();
    const [ getEmployeeFiles, { data: employeeFilesData} ] = useGetEmployeeFilesMutation();
    const [ deleteEmployeeFile, { isSuccess: deleteEmployeeFileSuccess, isError: deleteEmployeeFileError, isLoading: deleteEmployeeFileLoading } ] = useDeleteEmployeeFileMutation();
    const [ openFilePopupShare, setOpenShareFilePopup ] = useState(false);
    const [ fileSelected, setFileSelected ] = useState();
    const [ formData, setFormData ] = useState({
        "folder_id": null,
        "name": ""
    });
    const [ error, setError ] = useState();
    const [ open, setOpen ] = useState(false);
    const closeModal = () => {
      setOpen(false);
      setOpenShareFilePopup(false);
    };

    useEffect(() => {
        if(selectedFolder){
            getEmployeeFiles(selectedFolder?.id); 
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ selectedFolder ]);

    // const handlerChange = (e) => {
    //     setFormData({
    //       ...formData,
    //       name: e.target.value
    //     });
    // };

    // const handleFileChange = (event) => {

    //     const file = event.target.files[0];
    //     setSelectedFile(file);
        
    //     if(selectedFile){
    //       setError(" ");
    //     }
    // };

    const handleDelete = (id) => {
        //debugger;
        deleteEmployeeFile(id);
    };

    const saveFolder = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("folder_id", selectedFolder?.id);
        formdata.append("name", formData?.name);
        if (selectedFile) {
          selectedFile.forEach((file) => {
            formdata.append("document_file", file);
            });
            // formdata.append("document_file", selectedFile);
            setFiles( [] );
            setSelectedFile( [] );
        }
        if (selectedFile) {
            postEmployeeFile(formdata);
            document?.getElementById("closePopup").click();
        }
        else{
            setError("Please upload file");
        }
        setFormData();
    };

    // const handleTextUpdate = (e,item) => {
    //   e.preventDefault();
    //   var formdata = new FormData();
    //   formdata.append("id", item?.id);
    // }
    // const handleUpdate = (e, item) => {
    //   e.preventDefault();
    //   const value = {
    //     id: item?.id,
    //     folder_id: formData?.folder_id,
    //     name: formData?.name
    //   };
    //   postEmployeeFile(value);
    //   document?.getElementById("closePopup").click();
    // };
  //   const handleUpdate = (e, item) => {
  //     e.preventDefault();
  //     var formdata = new FormData();
  //     formdata.append("id", item?.id);
  //     formdata.append("folder_id", selectedFolder?.id);
  //     formdata.append("name", formData?.name ? formData?.name : employeeFilesData?.document?.name);
  //     if (selectedFile) {
  //         formdata.append("document_file", selectedFile);
  //     }
  //     if (selectedFile) {
  //       postEmployeeFile(formdata);
  //       document?.getElementById("closePopup").click();
  //   }
      
  //     else{
  //         setError("Please upload file");
  //     }
  //     setFormData();
  // };
    const toastId = useRef(null);
    useEffect(() => {
        if (postEmployeeFileLoading) {
          toastId.current = toast.loading("Please wait...", {
            // position: "top-center",
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            type: "info"
          });
        }
        if (postEmployeeFileSuccess) {
          toast.dismiss(toastId.current);
          toast.success("File uploaded successfully.",
            {
              render: "File uploaded successfully.",
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "colored",
              type: "success"
            }
          );
          // document?.getElementById("closePopup").click();
          getEmployeeFiles(selectedFolder?.id); 
          
        }
        if (postEmployeeFileError && errorFromApi) {
          toast.dismiss(toastId.current);
          const apiErrorMessage = errorFromApi.data?.message || "Something went wrong.";
      
          toast.error(apiErrorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
      
          setError(apiErrorMessage);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps        
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ postEmployeeFileLoading, postEmployeeFileSuccess, postEmployeeFileError ]);

      useEffect(() => {
        if (deleteEmployeeFileLoading) {
          toastId.current = toast.loading("Please wait...", {
            // position: "top-center",
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            type: "info"
          });
        }
        if (deleteEmployeeFileSuccess) {
          toast.dismiss(toastId.current);
          toast.success("File deleted successfully.",
            {
              render: "File deleted successfully.",
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "colored",
              type: "success"
            }
          );
          document?.getElementById("deleteClose")?.click();
          getEmployeeFiles(selectedFolder?.id); 
        }
        if (deleteEmployeeFileError) {
          const message = "Something went wrong.";
          toast.update(
            toastId.current,
            (message,
            {
              render: message,
              type: "error",
              autoClose: 5000,
              isLoading: false
            })
          );
        }
// eslint-disable-next-line react-hooks/exhaustive-deps        
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ deleteEmployeeFileLoading, deleteEmployeeFileSuccess, deleteEmployeeFileError ]);

      const handleFileClickShare = (file) => {
        setFileSelected(file);
        setOpenShareFilePopup(true);
      };

      // text file 
     
        const [ fileName, setFileName ] = useState('');
        const [ fileContent, setFileContent ] = useState('');
      
        const handleFileNameChange = (e) => {
          setFileName(e.target.value);
        };
      
        const handleFileContentChange = (e) => {
          setFileContent(e.target.value);
        };
      
        const handleCreateFile = () => {
          const blob = new Blob([ fileContent ], { type: 'text/plain'  });
          setSelectedFile( blob );

          if (!fileContent) {
          setError("Please enter file content");
          return;
  }
          var formdata = new FormData();
          formdata.append("folder_id", selectedFolder?.id);
          formdata.append("name", `${fileName}.txt` || 'untitled');
          formdata.append("document_file", new Blob([ fileContent ], { type: 'text/plain' }), `${fileName}.txt` || 'untitled.txt');
        if (selectedFile) {
            postEmployeeFile(formdata);
            setFileName( [] );
            setFileContent( [] );
            document.getElementById("addtextFolderClose").click() ;
        }
        else{
          setError("Please upload file");
      }
        setFormData();
          
        };
        // [ update multiple files code ]
        const [ files, setFiles ] = useState( [] );
        const handleFileChanges = (e) => {
          const selectedFiles = Array.from([ ...e.target.files ]);
      
          const updatedFiles = selectedFiles.map((file) => ({
            file,
            preview: URL.createObjectURL(file)
          }));
      
          // setFiles((prevFiles) => [ ...prevFiles, ...updatedFiles ]);
          setFiles(() => [ ...updatedFiles ]);

          setSelectedFile([ ...e.target.files ]);
          // setFileList([ ...e.target.files ]);
        };
      
        const removeFile = (index) => {
          const newFiles = [ ...files ];
          newFiles.splice(index, 1);
          setFiles(newFiles);

          var fileValues = [];

          for (var i = 0; i < newFiles.length; i++) {
            var fileValue = newFiles[i].file;
            fileValues.push(fileValue);
          }

          setSelectedFile(fileValues);
        };
        const [ show, setShow ] = useState(false);

        // icons code
        // const [ extension, setExtension ] = useState('pdf');

        const iconMap = {
          jpg: '/images/doc_icons/gallery.png',
          png: '/images/doc_icons/gallery.png',
          pdf: '/images/doc_icons/pdf.png',
          txt: '/images/doc_icons/file.png',
          docx: '/images/doc_icons/word.png',
          xlsx: '/images/doc_icons/sheets.png',
          csv: '/images/doc_icons/sheets.png'
          // zip: '/images/doc_icons/zip.png'
          // Add more file extensions and corresponding icons as needed
        };

         
  return (
    <>
        <div className="header flex justify-between items-center flex-wrap  ">
            <h3>{selectedFolder?.folder_name}</h3> 
            <div className="headerButton ">      
              { /* <Popup
                      open={open}
                      onClose={() => closeModal()}
                      closeOnDocumentClick={false}
                      trigger={
                        <button
                          id="closePopup"
                          className="btn-md btn-add "
                          aria-describedby="popup-8"
                          onClick={() => setOpen((o) => !o)}
                        >
                          <span className="fa fa-upload " /> Upload File
                        </button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal headerButton  justify-end mb-4 p-6">
                  <form>
                              <div className="flex flex-wrap ">
                                
                                <div className="w-full">
                                    <div className="form-group mb-6 fileUploadInPopup">                                   
                                    <label className="formBlock	">
                                    File Upload
                                    </label>
                                    <div className='file_Upload'>
                                        <input type="file" multiple onChange={handleFileChange} />
                                        <p>File Uplaod</p>
                                        </div>
                                        {selectedFile && (
                                            <p>Selected File: {selectedFile?.name}</p>
                                        )}                                   
                                    <div className="help-block" />
                                    </div>
                                </div>
                                <div className="text-red-700">{error}</div>

                                <div className="lg:w w-full  ">
                                  <div className="w-full mt-4">
                                    <div className="form-group pt-4 pb-3 flex  flex-wrap justify-center ">

                                      <button aria-describedby="popup-8"
                                        className="btn save mr-2"
                                        type="submit"
                                        id="folderSubmit"
                                        onClick={(e) => saveFolder(e)}
                                      >Upload</button>
                                      <PrimaryButton
                                        btnText="Cancel"                                      
                                        Btnclass="btn cancel mr-2"
                                        btntype="button"
                                        id="closePopup"
                                        onClick={() => close()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                        </div> )
                        }
              </Popup> */ }
              <Popup
                      open={open}
                      id = 'closePopup'
                      onClose={() => {

                        closeModal();
                      }}
                      closeOnDocumentClick={false}
                      
                      trigger={
                        <button
                          id="closePopup"
                          className="btn-md btn-add mr-1"
                          aria-describedby="popup-8"
                          onClick={() => setOpen((o) => !o)
                      } > 
                      <span className="fa fa-upload " /> Upload File
                        </button>}
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            onClick={() => {
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                          <div className="header">
                            <h3>Upload File</h3>
                          </div>
                          <div className="content">
                            <div >
                              <div className="flex flex-wrap ">
                              <div className="w-full relative ">
                                    <div className="form-group mb-6 ">                                   
                                    <label className="formBlock	">
                                    File Upload
                                    </label>
                                    <div>

        <div className='file_Upload'>
      <input type="file" multiple onChange={handleFileChanges} />
      <p>Drop files here or click here to upload file. </p>
      
      </div>
      <span>Maximum upload file size:25 MB.</span> 
      <div className='flex gap-2'>
        {files.map((file, index) => (
          <div key={index} className="file-preview">
            {file.file.type.startsWith('image/') ? (
              <img src={file.preview} alt={`Preview ${index}`} />
            ) : (
              <div className='filePreview'><p>{file.file.name}</p></div>
            )}
            <button className="removeIconImg"onClick={() => removeFile(index)}><span className='fa fa-trash text-red-600'/></button>
          </div>
        ))}
      </div>
    </div>             
                                    <div className="help-block" />
                                    </div>
                                    
                                </div>
                                <div className="text-red-700">{error}</div>

                                <div className="lg:w w-full   ">
                                  <div className="w-full mt-4">
                                    <div className="form-group pt-4 pb-3 flex  flex-wrap justify-center ">

                                    <button aria-describedby="popup-8"
                                        className="btn save mr-2"
                                        type="submit"
                                        id="folderSubmit"
                                        onClick={(e) => saveFolder(e)}
                                      >Upload</button>
                                      <PrimaryButton
                                        btnText="Cancel"
                                        onClick={() => close()}
                                        Btnclass="btn cancel mr-2"
                                        btntype="button"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}      
              </Popup>

                 {/* text file */}
                 <Popup
                      open={open}
                      onClose={() => closeModal()}
                      closeOnDocumentClick={false}
                      trigger={
                        <button
                          id="closePopup"
                          className="btn-md btn-add "
                          aria-describedby="popup-8"
                          onClick={() => setOpen((o) => !o)}
                        >
                          <span className="fa fa-folder-plus mr-2" />
                          Add Text File
                        </button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            id="addtextFolderClose"
                            onClick={() => {
                              setShow(false);
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                          <div className="header">
                            <h3>Create Text File</h3>
                          </div>
                          <div className="content w-full ">

                          { /* text file modal  */ }
                          {show && <div className="form-group mb-6 ">
                          <><button className="px-2 fa fa-arrow-left text-xl" onClick={() => setShow(false)} /> <span className="text-sm">Back</span></>
                          <br />
                            <div className="p-2 ">
                            <label className="formBlock	">
                                      File Name
                            </label>
                            <input
                              type="text"
                              placeholder="File Name"
                              className="formControl"
                              value={fileName}
                              onChange={handleFileNameChange}
                            />
                            </div>
                            <div className="form-group pt-4 pb-3 flex  flex-wrap justify-center ">
                            <button onClick={handleCreateFile} className="btn save mr-2">Create</button>
                            <button onClick={() => close()} className="btn cancel "> Cancel </button>
                            </div>
                            </div>
                            
                             }

                            {!show && <div className="form-group mb-6 ">
                            
                            <textarea
                              placeholder="Enter your text here..."
                              value={fileContent}
                              onChange={handleFileContentChange}
                              className="formControl h-80"
                            />
                            <div className="form-group pt-4 pb-3 flex  flex-wrap justify-center ">
                            <button onClick={() => setShow(true)} className="btn save mr-2">Save</button>
                            <button onClick={() => close()} className="btn cancel "> Cancel </button>
                            </div>
                            </div> }
                            
                            
                          </div>
                        </div>
                      )}
                  </Popup>      
            {/* <h3>Create File</h3>             */}
            </div>      
        </div>  

        <div className="md:p-6 p-2">
         <div className="grid lg:grid-cols-10 gap-2 max-[420px]:grid-cols-1 grid-cols-2 sm:grid-cols-4 md:grid-cols-6">
                  {  employeeFilesData?.documents?.length > 0 ? (
                                employeeFilesData?.documents?.map((item, index) => {
                                // const truncatedFolderName = item.folder_name && item.folder_name.length > 10
                                // ? item.folder_name.substring(0, 10) + "..."
                                // : item.folder_name;
                                const extension = (item?.name.split('.').pop());
                                
                                return (                                
                                <div key={index} className=" text-center mb-4 relative">
                                   <div className="border  p-2 pt-5 mb-2 w-full rounded h-[100px] flex flex-wrap items-center justify-center text-xl">
                                      {/* <span className="fa fa-file opacity-60"/>  */}
                                      { (extension && iconMap[extension.toLowerCase()]) ?
                                  <img src={process.env.PUBLIC_URL + iconMap[extension.toLowerCase()]} alt="File Icon" width='44px' height='44px'/>
                                      :
                                    // If extension not found, you can render a default icon or text
                                    <span>File</span> }
                                    </div>
                                    <div className="absolute right-0 top-0 p-2 px-3 hoverIcon cursor-pointer">
                                    <span className="fa fa-ellipsis-v text-3xl  "  />
                                    <div className="border rounded-md w-32 documentPopupList "  >
                                      <ul>
                                        { /* share file */ }
                                        {toggleState==="SystemFolder" && <li  onClick={() => handleFileClickShare(item)}>
                                        <span className="fa fa-share"/>
                                                Share File
                                        </li>}

                                        { /* download file */ }
                                        <li>
                                        <a
                                            href={item?.file_name} 
                                            download={item?.name}                                  
                                          > 
                                          <span className="fa fa-download "/>
                                          Download
                                          </a>
                                          </li>  

                                        { /* delete */ }
                                          <Popup
                                            open={open}
                                            onClose={() => closeModal()}
                                            closeOnDocumentClick={false}
                                            trigger={
                                              <li onClick={() => setOpen((o) => !o)}>
                                                <span  className="fa fa-trash " />
                                                Delete
                                              </li>
                                            }
                                            modal
                                            nested
                                          >
                                            {(close) => (
                                              <div className="modal">
                                                <button
                                                  className="close"
                                                  id="deleteClose"
                                                  onClick={() => {
                                                    closeModal();
                                                    close();
                                                  }}
                                                >
                                                  &times;
                                                </button>
                                                <div className="header">
                                                  {" "}
                                                  <h3>Delete</h3>
                                                </div>
                                                <div className="content">
                                                  <p>Are you sure you want to delete this {item?.name} directory ?</p>
                                                  <div className="w-full mt-8">
                                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                                      <button className="btn save mr-2" 
                                                      id="deleteYes"
                                                      onClick={(e) => { e.preventDefault(); handleDelete(item?.id); }}
                                                      >Yes</button>
                                                      <button
                                                        type="button"
                                                        id="deleteNo"
                                                        onClick={() => close()}
                                                        className="btn cancel "
                                                      >
                                                        No
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Popup> 
                                      </ul>   
                                    </div>
                                  </div>
                                    {/* { item?.name && setExtension(item?.name.split('.').pop())} */}
                                    
                                    <p>{item?.name ?? ''}</p>
                                </div>   
                                );
                              })
                            )
                    :
                    'Empty '
                  }     

                  { /* shared file popoup */ }
                    <Popup
                            open={openFilePopupShare}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal ">
                                <button
                                  className="close"
                                  id="folderClose"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <div className="header ">
                                  <h3>Shared file</h3>
                                </div>
                                  <SharedFileData
                                  fileSelected={fileSelected}
                                  />
                              </div>
                            )}
                    </Popup> 
        </div>
      </div>       
    </>
  );
};

export default DocumentFiles;


/*

/* edit file */ 
{ /* <Popup
open={open}
onClose={() => closeModal()}
closeOnDocumentClick={false}
trigger={
<li onClick={() => setOpen((o) => !o)}>
    { * <span className="fa fa-pencil text-[13px] mr-1 text-white p-1 rounded bg-primary  w-6" /> */ }
//     <span className="fa fa-edit"/>
//   Edit
// </li>
// }
// modal
// nested
// >
// {(close) => (
//   <div className="modal">
//     <button                                          
//       className="close"
//       id="editClose"
//       onClick={() => {
//         closeModal();
//         close();
//       }}
//     >
//       &times;
//     </button>
//     <div className="header">
//       <h3>Edit File</h3>
//     </div>
//     <div className="content">
//       <form 
      
//       onSubmit={(e) => saveFolder(e, item)}
//       >
//         <div className="flex flex-wrap ">
//           <div className=" w-full">
//             <div className="form-group mb-6 ">
//               <label className="formBlock	">
//                 File Name
//               </label>
//               <input
//                 type="text"
//                 name="name"
//                 id="editName"
//                 className="formControl"
//                 defaultValue={
//                   item?.name
//                 }
//                 onChange={(e) =>
//                   handlerChange(e, true)
//                 }
//               />
//               <div className="help-block" />
//             </div>
//           </div>
        
//           <div className="lg:w w-full  ">
//             <div className="w-full mt-4">
//               <div className="form-group pt-4 pb-3 flex  flex-wrap justify-center ">
//                 <PrimaryButton
//                   btnText="Update"
//                   Btnclass="btn save mr-2"
//                   btnype="button"
//                   buttonID="editUpdate"
//                   onClick={(e) => handleUpdate(e, item)}
//                 />
//                 <PrimaryButton
//                   btnText="Cancel"
//                   onClick={() => close()}
//                   Btnclass="btn cancel mr-2"
//                   btntype="button"
//                   buttonID="editCancel"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </form>
//     </div>
//   </div>
// )}
// </Popup>  