/* eslint-disable no-debugger */
/* eslint-disable */
import { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { useCreateNotesMutation, useGetNotesMutation } from "../../services/notesApi";
import { toast } from "react-toastify";
import moment from "moment";
import LoadingScreen from "../../helper/LoadingScreen";


import EditNotesEmployee from "./EditNotesEmployee";
import { useSelector } from "react-redux";


const EmployeeNotes = ({ employeeInfo, activeEmployeeUpdatePermissions, myprofileReadOnly = false }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [notesText, setNotesText] = useState();

  const [createNotes, { isSuccess: isNotesSuccess }] = useCreateNotesMutation();

  const [getNotes, { data: isNoteData, isSuccess: isNoteGetSuccess, isLoading: isNoteDataLoading }] = useGetNotesMutation();
  const closeModalAdd = () => {
    setIsAdd(false);
  };
  const addHandelNote = () => {
    createNotes({
      id: employeeInfo?.id,
      note: notesText
    });
  };
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);

  useEffect(() => {
    // if(isNoteData){
    //   setNotesData(isNoteData?.data);
    // }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isNoteGetSuccess]);

  useEffect(() => {
    if (employeeInfo) {
      getNotes(employeeInfo?.id);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[employeeInfo]);

  useEffect(() => {
    if (isNotesSuccess) {
      document?.getElementById("closeAdd")?.click();
      getNotes(employeeInfo?.id);
      toast.success("Notes Added Successfully",
        {
          render: "Notes Added Successfully",
          // position: "top-center",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        }
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isNotesSuccess]);


  return (
    <>
      <div className="heading_title px-7 justify-end flex  items-center py-4 ">
        {((activeEmployeeUpdatePermissions[0]?.status === true) || myprofileReadOnly) &&
          <div className="headerButton">
            <Popup
              open={isAdd}
              onClose={() => closeModalAdd()}
              closeOnDocumentClick={false}
              trigger={
                <button
                  className="btn-md btn-add"
                  id="addNote"
                  onClick={() => setIsAdd((o) => !o)}
                >
                  <span className="fa fa-plus mr-2" />
                  Add Note
                </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button
                    className="close"
                    id={"closeAdd"}
                    onClick={() => {
                      closeModalAdd();
                      close();
                    }}
                  >
                    &times;
                  </button>
                  <div className="header">
                    <h3>Add Note</h3>
                  </div>
                  <div className="content">
                    <>
                      <div className="flex flex-wrap ">
                        <div className="  w-full">
                          <div className="form-group mb-6 ">
                            <label className="formBlock	"> Notes</label>
                            <textarea
                              className="formControl h-24"
                              name="note"
                              id="note"
                              onChange={(e) => setNotesText(e?.target?.value)}
                              value={notesText}
                            />
                            <div className="help-block" />
                          </div>
                        </div>
                        {((activeEmployeeUpdatePermissions[0]?.status === true) || myprofileReadOnly) &&
                          <div className="lg:w w-full  ">
                            <div className="w-full mt-4">
                              <div className="form-group pt-4 pb-3 flex justify-center ">
                                <button
                                  className="btn save mr-2"
                                  onClick={addHandelNote}
                                  id="addNoteBtn"
                                >
                                  Add Note
                                </button>
                                <button
                                  type="button"
                                  id="addNoteCancel"
                                  onClick={() => close()}
                                  className="btn cancel  "
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>}
                      </div>
                    </>
                  </div>
                </div>
              )}
            </Popup>
          </div>}
      </div>
      <div className="p-7">
        <div className="overflow-x-auto w-full whitespace-nowrap md:whitespace-normal">
          <table className="md:table-fixed table-auto table  w-full">
            <thead className="thead-dark ">
              {isNoteData?.data?.length > 0 ? (
                <tr>
                  <th className="w-96">Note</th>
                  <th>Added by</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              ) : (
                <td className="mt-4  text-center" colSpan="12">No Data found</td>
              )}

            </thead>
            {isNoteDataLoading ? <LoadingScreen /> :
              <tbody>
                {isNoteDataLoading ? <LoadingScreen /> :
                  <>
                    {isNoteData?.data?.map((notes, index) => {
                      return (
                        <tr key={index}>
                          <td className="max-w-t">{notes?.note}</td>
                          <td>
                            {notes?.addedByInfo?.first_name +
                              " " +
                              notes?.addedByInfo?.last_name}
                          </td>
                          <td> {moment(notes?.updatedAt)?.format(formattedDate)}</td>
                          <td>

                            <EditNotesEmployee
                              notesNote={notes?.note}
                              employeeInfo={employeeInfo} notesId={notes?.id}
                              activeEmployeeUpdatePermissions={activeEmployeeUpdatePermissions}
                              getNotes={getNotes}
                              myprofileReadOnly={myprofileReadOnly}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                }
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

export default EmployeeNotes;
