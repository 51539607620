/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger */
import {useState, useEffect, useRef} from "react";
import {FormTextarea} from "../../components/formInput";
  import PrimaryButton from "../../components/primaryButton";
  import {useGetLeaveTypeListMutation} from "../../services/leaveTypesApi";
  import moment from 'moment/moment';
  import DatePicker from "react-datepicker";
  import {useCreateLeaveAbsenceMutation, useLeaveByDateRangeMutation, useRestrictedDayInfoAbsenceMutation, useUpdateLeaveAbsenceMutation} from "../../services/leaveAbsenceApi";
  import { toast } from "react-toastify";
  import { useDispatch, useSelector } from "react-redux";
  import {useGetEmployeeAllDaysScheduleMutation} from "../../services/employeeApi";
  import { useGetWorkScheduleMutation } from "../../services/employeeScheduleApi";
  import { getDay}  from 'date-fns';
  import Popup from "reactjs-popup";
import { employeeScheduleInfoAction } from "../../redux/EmployeeSlice/employeeSlice";
import { allLeaveDataAction } from "../../redux/LeaveTypeSlice/leaveSlice";

  const ListLeaveEdit = ({absence, allDepartmentListData, startDateShow, endDateShow, allEmployeeData, getAllLeaveAbsence, page, close, setAllEmployeeData}) => {
    
    const ignore = useRef(false);

    const getEmployeeAllDayScheduleRespo = useSelector((state) => state?.persistedReducer?.employeeSlice?.employeeScheduleInfo);
    const allLeaveApiResponse = useSelector((state) => state?.persistedReducer?.leaveTypeSlice?.allLeaveData);
    const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
    const closeModal = () => {
    setOpen(false);
    };
    const [ selectedLeave, setSelectedLeave ] = useState(1);
    const [ open, setOpen ] = useState(false);
    const [ hourOrDay, setHourOrDay ] = useState(null);
    const [ newAllowance, setNewAllowance ] = useState();
    const [ leaveTotalDuration, setLeaveTotalDuration ] = useState(0);
    const [ updateLeaveAbsence, {isSuccess, isLoading, isError, error:apiError} ] = useUpdateLeaveAbsenceMutation();
    // const [ workingHours, setWorkingHours ] = useState(0);
    const [ startNewTime, setNewStartTime ] = useState();
    const [ endNewTime, setNewEndTiming ] = useState();
    const [ getLeaveTypeList, {data:allLeaveApiResponseList, isSuccess: isSuccessLeave} ] = useGetLeaveTypeListMutation();
    const [ leaveByDateRange, { data: leaveByDateRangeData, isSuccess: leaveByDateRangeDataSuccess, isError:leaveByDateRangeDataError, error: leaveError } ] = useLeaveByDateRangeMutation();
    //const [ createLeaveAbsence, {isSuccess, isError, isLoading, error: apiError} ] = useCreateLeaveAbsenceMutation();
    const [ restrictedDayInfoAbsence, {data: restrictedDayInfoAbsenceData} ] = useRestrictedDayInfoAbsenceMutation();
    const [ getWorkSchedule ] = useGetWorkScheduleMutation();
    const defaultStartDate = new Date(absence?.start_date);
    const defaultEndDate = new Date(absence?.end_date);
    const [ dateRange, setDateRange ] = useState([ defaultStartDate, defaultEndDate ]);
    const [ startTime, setStartTime ] = useState("");
    const [ endTime, setEndTime ] = useState("");
    const [ selectedTimeData, setSelectedTimeData ] = useState([
      {
        employee_id: `${absence?.employee_id}`,
        date: "",
        end_date: "",
        leave_days: "",
        start_time:""
      }
    ]);

    const [ selectedTimeType, setSelectedTimeType ] = useState([]);
    const [ startTimingSchedule, setStartTimingSchedule ] = useState();
    const [ endTimingSchedule, setEndTimingSchedule ] = useState();
    const [ leaveDateRange, setLeaveDateRange ] = useState();
    const [ employeeSlots, setEmployeeSlots ] = useState([]);
    const [ employeeSlotsend, setEmployeeSlotsEnd ] = useState([]);
    const [ endTimeSlots, setEndTimeSlots ] = useState([]);
    const [ totalDurations, setTotalDuration ] = useState();
    const [ startDate, endDate ] = dateRange;
    const [ allLeaveType, setAllLeaveType ] = useState();
    
    const [ employeeSchedule, setEmployeeSchedule ] = useState();
    const [ selectedTimes, setSelectedTimes ] = useState({});
    // const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);     
    const [ restrictedDay, setRestrictedDay ] = useState(false);
    const [ formData, setFormData ] = useState({
      department_id: "",
      employee_id: "",
      leave_type: "",
      start_date: null,
      start_time: "",
      end_time: "",
      end_date: null,
      reason: "",
      type: "3",
      status: "1",
      time_in_lieu_deductions:"",
      is_private:"0"
     });
    const dispatch = useDispatch();
    const [ getEmployeeAllDaysSchedule, {data: EmployeeAllDayScheduleRespo, isSuccess: isSuccessSchedule} ] = useGetEmployeeAllDaysScheduleMutation();
    useEffect(() => {
      if (leaveByDateRangeDataSuccess) {
        setLeaveDateRange(leaveByDateRangeData?.data);
        setHourOrDay(leaveByDateRangeData?.hoursOrDay === 'inHour' ? 'Hours' : 'Days');
        setLeaveTotalDuration(leaveByDateRangeData?.changeAllowance);
        // setWorkingHours(leaveByDateRangeData?.hoursInDays);
        setNewAllowance(leaveByDateRangeData?.currentAllowance?.toFixed(2));
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveByDateRangeDataSuccess ]);
    

    useEffect(() => {
       setFormData({
        ...formData,
        start_date:moment(startDateShow).format('YYYY-MM-DD'),
        end_date:moment(endDateShow).format('YYYY-MM-DD')
       });
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ startDateShow, endDateShow ]);
    const scheduleData = [];

    useEffect(() => {
      if (leaveByDateRangeDataError) {
        toast.dismiss(toastId.current);
        const toastMessage = leaveError?.data?.message
          ? leaveError?.data?.message
          : "Something went wrong";
            toast.error(toastMessage, {
              render:toastMessage,
                   type: "error",
                   autoClose: 8000,
                   duplicate: false,
                   isLoading: false
            });
      }
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveByDateRangeDataError ]);
     
    useEffect(() => {
      if (!ignore.current){
      if (absence?.employee_id) {
        getWorkSchedule(absence?.employee_id);
      }
      getLeaveTypeList();
      getEmployeeAllDaysSchedule(absence?.employee_id);
    }
    return () => { ignore.current = true; };
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
    const [ defaultStartTiming, setDefaultStartTiming ] = useState();
    const [ defaultEndTiming, setDefaultEndTiming ] = useState();
    const [ durationInMinutess, setDurationInMinutes ] = useState();

    useEffect(() => {
      if (defaultStartTiming !== 'Select') {
        const startTime = moment(defaultStartTiming, 'HH:mm');
        const endTime = moment(defaultEndTiming, 'HH:mm');
  
        const durationInMinutes = endTime.diff(startTime, 'minutes');
        setDurationInMinutes(durationInMinutes);
        
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ defaultStartTiming, defaultEndTiming ]);

    useEffect(() => {
      const scheduleData = [];
      if(getEmployeeAllDayScheduleRespo){
        getEmployeeAllDayScheduleRespo?.data?.length > 0 && getEmployeeAllDayScheduleRespo?.data?.forEach((schedule) => {
          let Data = {
            day:schedule?.day,
            is_weekend: schedule?.is_weekend,
            start_time: schedule?.startTimeWithDummyDate,
            end_time: schedule?.endTimeWithDummyDate,
            daysHours: getEmployeeAllDayScheduleRespo?.dayHours  
          };
          scheduleData.push(Data);
        });
        setEmployeeSchedule(scheduleData);
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ getEmployeeAllDayScheduleRespo ]);
useEffect(() => {
  if(isSuccessSchedule){
    dispatch(employeeScheduleInfoAction(EmployeeAllDayScheduleRespo));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessSchedule ]);

    useEffect(() => {
      let slots =[];
      slots.push('Select');
      slots.push('Morning');
      slots.push('Afternoon');
      slots.push('.......');
      if(employeeSchedule){
      let dateDaySlots = employeeSchedule?.find((f) => parseInt(f.day)  === getDay(new Date()));
      let start= moment(dateDaySlots?.start_time).format('HH:mm');
      
      let end = moment(dateDaySlots?.end_time).format('HH:mm');
      setEndTimingSchedule(end);
      setStartTimingSchedule(start);
     
      let endTime = [];
     
      let  slotInterval =15;
      while (start <= end) {
        slots.push(start);
        endTime.push(start);
        start = moment(start, 'HH:mm').add(slotInterval, 'minutes').format('HH:mm');
      }
      setEmployeeSlots(slots);
      setEndTimeSlots(endTime);
      
    }
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ employeeSchedule ]);

     useEffect(() => {
      let slots =[];
      slots.push('Select');
      if(employeeSchedule){
      let dateDaySlots = employeeSchedule?.find((f) => parseInt(f.day)  === getDay(new Date()));
      let start= moment(dateDaySlots?.start_time).format('HH:mm');
      
      let end = moment(dateDaySlots?.end_time).format('HH:mm');
     
      let endTime = [];
     
      let  slotInterval =15;
      while (start <= end) {
        slots.push(start);
        endTime.push(start);
        start = moment(start, 'HH:mm').add(slotInterval, 'minutes').format('HH:mm');
      }
      setEmployeeSlotsEnd(slots);
      setEndTimeSlots(endTime);
    }
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ employeeSchedule ]);

     useEffect(() => {
      let totalDuration = 0;
  
      leaveByDateRangeData?.data?.forEach((entry) => {
        const startTime = new Date(`2000-01-01 ${entry?.start_time}`);
        const endTime = new Date(`2000-01-01 ${entry?.end_time}`);
  
        if (entry.is_weekend) {
          return; // skip weekends
        }
  
        const workDuration = endTime - startTime; 
        totalDuration += workDuration;
      });
  
      const totalDurationInMinutes = totalDuration / (60 * 1000); // convert total duration to minutes
      setTotalDuration(totalDurationInMinutes);
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveByDateRangeData ]);

    const [ error, setError ] = useState({
        errorDepartmentId : '',
        errorEmployeeId: "",
        errorLeaveType: "",
        errorStartTime:'',
        errorEndTime:"",
        errorReason: "",
        errorEndDate:''
       });
     
      let endTimeData = [
        "Select",
        "Lunch",
        "End of Day"
      ];
      let endNewTimeData = [
        "Select",
        "End of Day"
      ];
     
  

  useEffect(() => {
    if(getEmployeeAllDayScheduleRespo) {
      getEmployeeAllDayScheduleRespo?.data?.length > 0 && getEmployeeAllDayScheduleRespo?.data?.forEach((schedule) => {
        let Data = {
          day:schedule?.day,
          is_weekend: schedule?.is_weekend,
          start_time: schedule?.startTimeWithDummyDate,
          end_time: schedule?.endTimeWithDummyDate,
          break:schedule?.break,
          daysHours: getEmployeeAllDayScheduleRespo?.dayHours
        };
        scheduleData.push(Data);
      });
      setEmployeeSchedule(scheduleData);
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ getEmployeeAllDayScheduleRespo ]);

useEffect(() => {
  if(isSuccessLeave){
    dispatch(allLeaveDataAction(allLeaveApiResponseList));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessLeave ]);

      useEffect(() => {
        if(allLeaveApiResponse) {
          let deduct = [];
          deduct?.push({id : '',
        // leave_name:'Deduct',
        leave_name:'Deductible',
        isDisabled: true,
        icon: 'Nondeductible'}
        );
        allLeaveApiResponse?.leaveType?.rows?.forEach((leave) => {
          if(leave?.is_deductible === '1' && leave?.status === '1') {
           let value = {
              id : leave?.id,
              leave_name:leave?.leave_name,
              icon: leave?.icon,
              is_deductible:1
            };
            deduct?.push(value);
          }
         
        });
        // deduct.push('non deduct');
        deduct.push({id : '',
        leave_name:'Non-Deductible',
        isDisabled: true,
        icon: 'Nondeductible'}
        );
        allLeaveApiResponse?.leaveType?.rows.forEach((leave) => {
          if(leave?.is_deductible === '0' && leave?.status === '1') {
          let value = {
            id : leave?.id,
            leave_name:leave?.leave_name,
            icon: leave?.icon,
            is_deductible:0
          };
          deduct?.push(value);
        }
        });
          setAllLeaveType(deduct);
         
        }     
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ allLeaveApiResponse ]);
     const toastId = useRef();
     useEffect(() => {
      if (isLoading) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccess) {
        toast.success("My leave updated successfully!", {
          render: "My leave updated successfully!",
          type: "success",
          autoClose: 2000,
          isLoading: false,
          theme: "colored"
        });
        let payload=`/leaves?type=${1}&page=${page}&group=true`;
        getAllLeaveAbsence(payload);
        close();
        toast.dismiss(toastId.current); 
      }
      if (isError) {
          toast.dismiss(toastId.current);
        const toastMessage = apiError?.data?.message
          ? apiError?.data?.message
          : "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 2000,
          duplicate: false,
          isLoading: false
        });
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isLoading, isSuccess, isError ]);
    
   
    const handleCheckboxChange = (event) => {
      const isChecked = event.target.checked;
      const updatedFormData = {
        ...formData,
        is_private: isChecked ? "1" : "0"
      };
      setFormData(updatedFormData);
    };

  const handleStartTimeChange = (date, event, leave, index) => {

    
    const selectedStartTime = event.target.value;
    setNewStartTime(selectedStartTime);
    const newDuration = calculateDuration(selectedStartTime, endNewTime ? endNewTime : leave?.end_time, index); 

    const updatedSchedule = [ ...leaveDateRange ];
    
    updatedSchedule[index] = {
      break: updatedSchedule[index].break,
      break_paid: updatedSchedule[index].break_paid,
      date: updatedSchedule[index].date,
      day: updatedSchedule[index].day,
      end_time: updatedSchedule[index].end_time,
      hours_in_day: updatedSchedule[index]?.hours_in_day,
      has_leave_on_date: updatedSchedule[index].has_leave_on_date,
      holiday_name: updatedSchedule[index].holiday_name,
      is_weekend: updatedSchedule[index].is_weekend,
      location_holiday: updatedSchedule[index].location_holiday,
      start_time: updatedSchedule[index].start_time,
      ["duration"]: newDuration,
      ["leave_day"] : (newDuration / (updatedSchedule[index]?.hours_in_day * 60))?.toFixed(2)
    };
    // updatedSchedule[index]?.duration = newDuration;
    setLeaveDateRange(updatedSchedule);

    setSelectedTimeType((prevSelectedTimeType) => {
      const updatedTimeType = [ ...prevSelectedTimeType ];
      updatedTimeType[index] = { start_time: event.target.value };
      return updatedTimeType;
    });
    setSelectedTimeData((prevSelectedTimeData) => {
      const updatedTimeData = [ ...prevSelectedTimeData ];
      updatedTimeData[index] = {
        ["end_time"]:selectedTimeData[index]?.end_time,
        ["start_time"]:
          selectedStartTime === "Morning"
            ? startTimingSchedule
            : selectedStartTime === "Afternoon"
            ? moment(startTimingSchedule, "HH:mm").add(5, "hours").format("HH:mm")
            : selectedStartTime,
        ["date"]: date
      };
  
      return updatedTimeData;
    });
    if (selectedStartTime !== 'Morning' && selectedStartTime !== 'Afternoon') {
      setEndTime('Select');
    } else {
      let filteredEndTimes = [ 'Select' ];

      if (selectedStartTime === 'Morning') {
        filteredEndTimes.push('Lunch', 'EOD');
      } else if (selectedStartTime === 'Afternoon') {
        filteredEndTimes.push('EOD');
      }

      setEndTime('Select');
      setEndTimeSlots(filteredEndTimes);
    }
    const newSelectedTimes = { ...selectedTimes, [date]: { ...selectedTimes[date], start_time: event.target.value } };
    setSelectedTimes(newSelectedTimes);

    setLeaveDateRange((prevSchedule) => {
      return prevSchedule.map((item) => {
        if (item?.day === leave?.day) {
          return { ...item, start_time: startTime === "Morning" ? startTimingSchedule : startTime === "Afternoon" ? moment(startTimingSchedule).add(5, 'h').format('HH:mm') : startTime};
        }
        return item;
      });
    });
    setStartTime(selectedStartTime);
  };

  const handleEndTimeChange = (date, event, leave, index) => {
    const endTiming = event?.target?.value;
    setNewEndTiming(endTiming);
    const newDuration = calculateDuration(startNewTime ? startNewTime : leave?.start_time, endTiming, index); 

    const updatedSchedule = [ ...leaveDateRange ];
    
    updatedSchedule[index] = {
      break: updatedSchedule[index].break,
      break_paid: updatedSchedule[index].break_paid,
      date: updatedSchedule[index].date,
      day: updatedSchedule[index].day,
      end_time: updatedSchedule[index].end_time,
      hours_in_day: updatedSchedule[index]?.hours_in_day,
      has_leave_on_date: updatedSchedule[index].has_leave_on_date,
      holiday_name: updatedSchedule[index].holiday_name,
      is_weekend: updatedSchedule[index].is_weekend,
      location_holiday: updatedSchedule[index].location_holiday,
      start_time: updatedSchedule[index].start_time,
      ["duration"]: newDuration,
      ["leave_day"] : (newDuration / (updatedSchedule[index]?.hours_in_day * 60))?.toFixed(2)
    };
    // updatedSchedule[index]?.duration = newDuration;
    setLeaveDateRange(updatedSchedule);
    
    const newSelectedTimes = { ...selectedTimes, [date]: { ...selectedTimes[date], end_time: event.target.value } };
    // setSelectedTimeData([
    //   ...selectedTimeData,
    //  { end_time:
    //   event.target.value === "Lunch"
    //       ? moment(event.target.value).add(4, "h").format("HH:mm")
    //       : endTime === "EOD"
    //       ? endTimingSchedule
    //       :  event.target.value,
    //   start_date: moment(
    //     `${formData?.date} ${event.target.value}`,
    //     "YYYY-MM-DD"
    //   ).format("YYYY-MM-DD HH:mm")}
    //   ]);
      setSelectedTimeData((prevSelectedTimeData) => {
        const updatedTimeData = [ ...prevSelectedTimeData ];
        updatedTimeData[index] = {
          ["start_time"]:selectedTimeData[index]?.start_time,
          ["end_time"]:
             event.target.value === "Lunch"
          ? moment(startTimingSchedule, "HH:mm").add(4, "h").format("HH:mm")
          : event.target.value === "End of Day"
          ? endTimingSchedule
          :  event.target.value,
          ["date"]: date
        };
    
        return updatedTimeData;
      });
    setSelectedTimes(newSelectedTimes);
    setLeaveDateRange((prevSchedule) => {
      return prevSchedule.map((item) => {
        if (item?.day === leave?.day) {
          return { ...item, end_time: endTime === "Lunch" ? moment(endTimingSchedule, "HH:mm").add(4, 'h').format('HH:mm') : endTime === "EOD" ? endTimingSchedule : endTime };
        }
        return item;
      });
    });

  };
//--------------------------------------------------------------------------------------//
    const handlerChange = (e) => {
      const {name} = e.target;
      setFormData((formValue) => {
       return {
        ...formValue, [name]: e.target.value
       };
      }); 
     };

    // const handlerLeaveType = (e, name) => {
    //   setSelectedLeave(e?.is_deductible);
    //     if( name === 'leave_type') {
    //       if ( name === 0 || name === '') {
    //         setError({...error, ['errorLeaveType']: 'Please select leave type'});
    //       } else {
    //         setError({...error, ['errorLeaveType']: ''});
    //       }
    //     }
    //     setFormData((formValue) => {
    //         return {
    //          ...formValue, ['leave_type']: `${e.id}`
    //         };
    //        }); 

    // };
    const handlerSave = (e) => {
      e.preventDefault();

      let departmentIdValue = (employeeIdData?.department_id !== undefined && employeeIdData?.department_id !== null && !isNaN(employeeIdData.department_id)) ? employeeIdData.department_id : '';

        let value = {
          department_id: departmentIdValue?.toString(),
          employee_id:`${absence?.employee_id}`,
          leave_type: absence.leaveTypeInfo?.leave_type,
          end_time: formData?.end_time ? formData?.end_time : '',
          start_date: absence?.start_date ? absence?.start_date : formData?.start_date,
          start_time: formData?.start_time ? formData?.start_time : '',
          end_date: absence?.end_date ? absence?.end_date : formData?.end_date,
          reason: formData?.reason,
          type: "1",
          status: "0",
          time_in_lieu_deductions: (formData?.leave_type === process.env.REACT_APP_TIME_IN_LIEU_LEAVE_TYPE_ID) ? "1" : "",
          is_private: formData?.is_private
        };
        leaveByDateRange(value);
        restrictedDayInfoAbsence(value);
        setOpen(true);
        setDateRange([ null, null ]);
    };

    const handlerSubmit = (e) => {

      const mergedData = leaveDateRange.map((unchangedItem) => {
        const changedItem = selectedTimeData.find((item) => moment(item?.date).format("YYYY-MM-DD") === unchangedItem.date);
        if (changedItem) {
          // Merge changed data with unchanged data
          return {
            ...unchangedItem,
            ...changedItem
          };
        } else {
          // Keep unchanged data if no matching changed data
          return unchangedItem;
        }
      });

      const updatedEvents = mergedData?.map((event) => {
        const format = "HH:mm:ss";
        const startMoment = moment(event?.start_time, format);
        const endMoment = moment(event?.end_time, format);
        const duration222= moment.duration(endMoment?.diff(startMoment));
        const duration = duration222.asMinutes();
        const start_date = event?.date;
        const end_date = event?.date;
        const total_duration = event?.duration;
        const leave_day = event?.leave_day;
        return {
          ...event,
          duration,
          start_date,
          end_date,
          total_duration,
          leave_day
        };
      });
  
      const updatedStartTimes = updatedEvents.map((event) => event.start_time);
      const updatedEndTimes = updatedEvents.map((event) => event.end_time);
      const updatedTimeDuration = updatedEvents.map((event) => event.duration);
  
      // Find the earliest start_time and latest end_time
      const earliestStartTime = moment.min(updatedStartTimes.map((time) => moment(time, "HH:mm:ss"))).format("HH:mm:ss");
      const latestEndTime = moment.max(updatedEndTimes.map((time) => moment(time, "HH:mm:ss"))).format("HH:mm:ss");
      const timeDuration = moment.max(updatedTimeDuration.map((time) => time));
      
      e.preventDefault();
        let departmentIdValue = (employeeIdData?.department_id !== undefined && employeeIdData?.department_id !== null && !isNaN(employeeIdData.department_id)) ? employeeIdData.department_id : '';
        let value = {
          department_id: departmentIdValue?.toString(),
          employee_id: `${absence?.employee_id}`,
          leave_type: absence?.leaveTypeInfo?.leave_type,
          end_time: latestEndTime,
          start_date: `${moment(absence?.start_date).format("YYYY-MM-DD")}`,
          start_time: earliestStartTime,
          end_date: `${moment(absence?.end_date).format("YYYY-MM-DD")}`,
          reason: formData?.reason,
          type: "1",
          status: "0",
          time_in_lieu_deductions: (formData?.leave_type==process.env.REACT_APP_TIME_IN_LIEU_LEAVE_TYPE_ID) ? "1" : "",
          is_private: formData?.is_private,
          total_duration: timeDuration,
          leave_entries: updatedEvents,
          id:absence?.id
        };
        updateLeaveAbsence(value);   
    };


    const isWeekday = (date) => {
      const availableDays = [];
      const day = getDay(date);
      employeeSchedule && employeeSchedule.forEach((days) => {
        if(days.day === '7') {
          days.day= 0;
          if(days.is_weekend == 0) { 
            availableDays.push(parseInt(days.day));
          }
         } else {
          if(days.is_weekend === 0) { 
            availableDays.push(parseInt(days.day));
          }
        }
      });
      return availableDays.includes(day);
    
    };
    const onDateChange = (update) => {
    setFormData({
      ...formData,
      start_date: moment(update[0])?.format("YYYY-MM-DD"),
      end_date: moment(update[1])?.format("YYYY-MM-DD")
    });
  };

  const calculateDuration = (startTime, endTime) => {
    const format = "HH:mm:ss";
    const startMoment = moment(startTime === "Morning" ? startTimingSchedule : startTime === "Afternoon" ? moment(startTimingSchedule, "HH:mm").add(5, "h").format("HH:mm") : startTime, format);
    const endMoment = moment(endTime === "Lunch" ? moment(startTimingSchedule, "HH:mm").add(4, "h").format("HH:mm") : endTime === "End of Day" ? endTimingSchedule : endTime, format);
    const duration = moment.duration(endMoment.diff(startMoment));
    return duration.asMinutes();
  };

  const totalDurationInMinutes = leaveDateRange?.reduce((total, entry) => total + entry?.duration, 0);
  const hours = Math.floor(totalDurationInMinutes / 60);
  const minutes = totalDurationInMinutes % 60;

  // const days = (totalDurationInMinutes / (workingHours * 60))?.toFixed(2);

  const newAllowanceSummary = newAllowance;
  // const newDaysAfterChange = newAllowanceSummary - days;

  const newAllowanceSummaryHours = newAllowance;
  const newHoursAfterChange = newAllowanceSummaryHours - `${hours}.${minutes}`;

  const totalLeaveDays = leaveDateRange?.reduce((acc, current) => {
    return acc + parseFloat(current?.leave_day);
  }, 0);
  const newDaysAfterChange = newAllowanceSummary - totalLeaveDays;

  useEffect(() => {  
    if (restrictedDayInfoAbsenceData?.data) {
      setRestrictedDay(true);
    }else{
      setRestrictedDay(false);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ restrictedDayInfoAbsenceData ]);

    return (
        <>
          <div className="header">
              <span>
              <h3>Request Leave</h3>
              </span>
            </div>
            
        <div className="stepIndicator">
          <ul>
            <li className="active"><span>1</span>Request</li>
            <li><span>2</span>Details</li>
          </ul>
        </div>
        <div className="content">
          <form>
            <div className="flex flex-wrap ">
              <div className=" w-full ">
              <div className="form-group mb-6 ">
              <label className="formBlock	">
                Type{" "}
                <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
              </label>
             <input
                className="formControl"
                value={absence?.leaveTypeInfo?.leave_name}
             />
              {/* <div className="help-block text-red-700 mt-1">{error.errorLeaveType ? error.errorLeaveType :'' }</div> */}
              </div>
            </div>

            <div className=" w-full ">
            <div className="mb-2">
              <div className="flex">
                <input
                  type="checkbox"
                  defaultChecked={formData?.is_private === "1" ? formData?.is_private === "1" : absence?.is_private === "1"}
                  onChange={handleCheckboxChange}
                  className="mr-2 w-5 h-5"
                  id="private"
                />
                <label className="formBlock">Private</label>
              </div>  
            </div>
            <div className="w-full ">
              <div className="form-group mb-4 ">
                <label className="formBlock ">Reason (optional) </label>
                <FormTextarea textName="reason" value={formData?.reason? formData?.reason: absence?.reason} handlerChange={handlerChange} inputID="reason" />
                <div className="help-block" />
              </div>
            </div>
            <div className="form-group mb-4 ">
              <div className="flex">
              <div className='w-full'>
                <label className="formBlock">
                  Select Date
                  <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                </label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  id="startDate"
                  dateFormat="dd/MM/yyyy"
                  className="formControl"
                  placeholderText="Select Date Range"
                  disabled
                  onChange={(update) => {
                    setDateRange(update);
                    onDateChange(update);
                  }}
                 // isClearable={true}
                  filterDate={(date) => date >= new Date() && isWeekday(date)} 
                />

              </div>
            </div>
          </div>        
        </div>
        <div className="lg:w w-full  ">
          <div className="w-full">
            <div className="form-group pt-4 pb-3 flex justify-center ">
              <PrimaryButton
                btnText="Next"
                Btnclass="btn save mr-2"
                btnype="button"
                buttonID="next"
                onClick={(e) => handlerSave(e)}
              />
              <PrimaryButton
                btnText="Cancel"
                onClick={() => close()}
                Btnclass="btn cancel"
                buttonID="cancel"
                btntype="button"
              />
            </div>
          </div>
        </div>
        <Popup
        open={open}
        onClose={() => closeModal()}
        closeOnDocumentClick={false}
        className="leavePopup"
        modal
        nested
      >
        {(close) => (
          <div className="modal" >
            <button
              className="close"
              id="requestLeaveClose"
              onClick={() => {
                closeModal();
                close();
              }}
            >
              &times;
            </button>
            <div className= { restrictedDay? "header restrictedDay" : "header" }>
              <span>
                <h3>Request Leave</h3>      
                <span className='restricteSpan table rounded-lg font-medium text-red-700 bg-red-100'>
                  {restrictedDay ? '(Your request falls on a restricted day)' : ''}
                </span>
                {restrictedDay && (
                  <span className='restricteSpan table rounded-lg text-sm text-red-700 bg-red-100'>
                    (This request may be declined)
                  </span>
                )}  
                </span>   
              </div>
            {/* <h1 className="p-2 ml-2">Leave Details</h1> */}
            <div style={{ maxHeight: "70vh", overflowY: "auto", paddingBottom:"24px" }}>
            <div className="stepIndicator">
              <ul>
                <li><span>1</span>Request</li>
                <li  className="active"><span>2</span>Details</li>
              </ul>
            </div>
            <div className="p-6" >
            <h3 className="text-lg primary-text mb-4">Leave Details</h3>       
            {leaveDateRange &&
              leaveDateRange?.map((leave, index) => {
                const date = moment(leave?.date)?.format("YYYY-MM-DD");
                const startTime = selectedTimes[date]?.start_time || "Select";
                const endTime = selectedTimes[date]?.end_time || "Select";
                //const durationInMinutes = selectedTimes[date]?.start_time ? calculateDuration(selectedTimes[date].start_time, selectedTimes[date].end_time) : durationInMinutess;
                const defaultStartTime = leave?.start_time ? moment( leave?.start_time, "HH:mm:ss").format("HH:mm") : "Select";
                setDefaultStartTiming(defaultStartTime);
                const defaultEndTime = moment(leave?.end_time, "HH:mm:ss").format('HH:mm');
                setDefaultEndTiming(defaultEndTime);

               
                return (
                  <div
            className="w-full flex justify-between pb-4  md:flex-nowrap flex-wrap "
            key={index}
          >
            <div className="w-1/3">
              <h3 className="text-base text-left pt-1"> {date}</h3>
            </div>
            {leave?.is_weekend === 1 ? (
              <div>
              <p>Non working day</p>
            </div>
            )
             :  (
            <div className=" md:w-2/3 w-full pt-2 md:pt-0 md:pl-4">
              <>
              <div className="flex justify-between items-center w-full">
                <select
                  className="formControl"
                  name={`start_time_${date}`}
                  value={
                    startTime === "Select"
                      ? defaultStartTime
                      : startTime
                  }
                  // defaultValue={leave?.start_time ? moment(leave?.start_time).format('HH:mm') : startTime}
                  onChange={(event) =>
                    handleStartTimeChange(date, event, leave, index) 
                  }
                >
                  {employeeSlots?.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                <div className="mr-1 ml-1 text-sm text-black-900">to</div>

                <select
                  className="formControl"
                  name={`end_time_${date}`}
                  value={
                    endTime === "Select" ? defaultEndTime : endTime
                  }
                  defaultValue={
                    leave?.end_time
                      ? moment(leave?.end_time).format("HH:mm")
                      : endTime
                  }
                  onChange={(event) =>
                    handleEndTimeChange(date, event, leave, index)
                  }
                >
                  {selectedTimeType?.length > 0 ? (
                    <>
                      {selectedTimeType[index]?.start_time === "Morning"
                        ? endTimeData?.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))
                        : 
                        selectedTimeType[index]?.start_time === "Afternoon"
                        ? endNewTimeData?.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))
                        :
                        selectedTimeType[index]?.start_time !==
                            "Morning" &&
                          selectedTimeType[index]?.start_time !==
                            "Afternoon"
                        ? employeeSlotsend?.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))
                        : endTimeSlots?.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                    </>
                  ) : (
                    endTimeSlots?.map((time, index) => (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    ))
                  )}
                </select>
              </div>
              <div className="w-full mt-2">
                <div className="timeDiv">
                  <span className="fa fa-clock secondry-text" />
                    <span>
                      <span className="secondry-text">
                      {Math.floor(leave?.duration / 60) ? Math.floor(leave?.duration / 60) : Math.floor(absence?.total_duration / 60)}
                      </span>{" "}
                      Hours{" "}
                      <span className="secondry-text pl-2 pr-1">
                      {leave?.duration % 60 ? leave?.duration % 60 : absence?.total_duration % 60}
                      </span>{" "}
                      Minutes
                    </span>
                </div>
              </div>
              </>
        </div>            
      )}
          </div>
        );
              })}
           
            </div>
            <div className="px-6 text-right pb-4">
              <h1 className="text-lg">
              {hourOrDay === 'Hours' ? (
                  <h1>Total: {hours}{minutes !== 0 ? `:${minutes}` : ''} hours</h1>
                ) : (
                  <h1>Total: {totalLeaveDays?.toFixed(2)} days</h1>
                )}
              </h1>
              <p className="ml-10">Annual Leave</p>
            </div>
            {selectedLeave === 0 ? (
            <div className="w-full text-center py-2">
            <p className="flex justify-center items-center w-full">No deduction from allowance</p>
            </div>
            ) : (
            <div>
           <h1 className="p-2 pt-4 ml-2 text-lg border-t">Allowance Summary</h1>
            <div className="flex justify-between mt-4 px-5 flex-col md:flex-row">
              <div className="square-box rounded bgBlueLight flex  gap-1 mb-4 md:mb-0 flex-col items-center justify-center shadowblue md:w-[150px] h-[130px] w-full " >
                <span className="text-xl primary-text font-semibold"> Current</span>
                <span className="text-sm  primary-text">{leaveByDateRangeData?.currentAllowance?.toFixed(2)} {hourOrDay}</span>
                
              </div>
              <div className="square-box rounded-lg bg-yellow-light gap-1 mb-4 md:mb-0 shadowsecondry flex-col secondry-text flex items-center justify-center md:w-[150px] h-[130px] w-full" >
                <span className="text-xl secondry-text font-semibold"> New</span>
                <span className="text-sm  secondry-text">{hourOrDay === 'Hours' ? (
                  <p>{newHoursAfterChange?.toFixed(2)} {hourOrDay}</p>
                ) : (
                  //days calculation
                  <p>{(newDaysAfterChange)?.toFixed(2)}{hourOrDay}</p>
                )}</span>
              </div>
              <div className="square-box rounded bgBlueLight flex mb-4 md:mb-0 items-center justify-center flex-col shadowblue md:w-[150px] h-[130px] w-full" >
                <span className="text-xl primary-text font-semibold"> Change <span className="fa fa-arrow-down changeDown"/> </span>
                 <span className="text-sm primary-text">{hourOrDay === 'Hours' ? (
                  <p>{hours}{minutes !== 0 ? `:${minutes}` : ''} {hourOrDay}</p>
                ) : (
                  <p>{totalLeaveDays?.toFixed(2)}{hourOrDay}</p>
                )}</span>
              </div>
            </div>
            </div>
            )}
           
            </div>
            <div className="lg:w w-full  ">
              <div className="w-full mb-4">
                <div className="form-group pt-4 pb-3 flex justify-center ">
                  <PrimaryButton
                    btnText="Submit"
                    Btnclass="btn save mr-2"
                    btnype="button"
                    buttonID="leaveSave"
                    onClick={(e) => handlerSubmit(e)}
                  />
                  <PrimaryButton
                    btnText="Back"
                    onClick={() => close()}
                    Btnclass="btn cancel"
                    buttonID="leaveCancel"
                    btntype="button"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>

      </div>
    </form>
  </div>
</>
);
};



  export default ListLeaveEdit;