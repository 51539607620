/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger */
import React, {useState, useEffect, useRef}  from 'react';
import {
    DateFiled,
   
    FormInput,
    FormSelect,
    FormTextarea
  } from "../../components/formInput";
  import PrimaryButton from "../../components/primaryButton";
  import FileUploadBtn from "../../components/fileUploadBtn";
  import { ToastContainer, toast } from "react-toastify";
 import DatePicker from "react-datepicker";
 import moment from 'moment/moment';
// import FileUploadBtn from "../../components/fileUploadBtn";
import { useCreateMainExpensesMutation, useDeleteByFileMutation} from '../../services/expenseApi';
// import { ToastContainer, toast } from "react-toastify";
import Popup from "reactjs-popup";
import { useSelector } from 'react-redux';

const EditMyMileage = ({expense, allExpenseType, getAllMainExpenses, close, getAllMainExpensesCSVRecord}) => {
  const [ createMainExpense, {isSuccess: isSuccessCreate, isLoading: isLoadingCreate, isError: isErrorCreate, error:apiError } ] =  useCreateMainExpensesMutation();
  const [ deleteByFile, {isSuccess: isSuccessDelete, isLoading: isLoadingDelete, isError: isErrorDelete} ] = useDeleteByFileMutation();  
  const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
    const  toastGeneralId = useRef(null);
    const [ amountOfMile, setAmountOfMile ] = useState();
    const [ fileList, setFileList ] = useState([]);
    const [ perMile, setPerMile ] = useState();
    const [ disable, setDisable ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const validImageTypes = [ 'gif', 'jpeg', 'png', 'jpg' ];
    const closeModal = () => {
      setOpen(false);
    };    const [ formData, setFormData ] = useState({
       
        "company_id": "",
        "employee_id": employeeIdData.employee_id,
        "start_date": new Date(),
        "start_time": "",
        "end_time": "00",
        "end_date": new Date(),
        "reason": "",
        "is_mileage": "1",
        "type": "",
        "amount": "",
        "amount_of_miles":"",
        "notes": "notes",
        "bill": "bill",
      
        "status": "0"
        
    });
    
    const [ error, setError ] = useState({
      amountError :'',
      typeError: '',
      reasonError: '',
      errorEndDate: ''

    });
    useEffect(() => {
      setFormData({
         ...formData,
         type: expense.type
      });
      setStartDate(new Date(expense.start_date));
      setAmountOfMile(expense.amount_of_miles);
      if (Array.isArray(expense?.expenseFiles)) {
        setFileList([ ...expense.expenseFiles ]);
      }
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ expense ]);
    const handlerChange = (e) => {
    
        e.preventDefault();
        const {name} = e.target;
        if (name === 'type') {
            let per_Mile = allExpenseType?.find((f) => f.id == e.target.value );
            let amount =formData?.amount ? formData?.amount : expense.amount;
            let amountMiles = ((amount* per_Mile?.pence_per_mile)/100).toFixed(2);

            setAmountOfMile(amountMiles);
            setPerMile(per_Mile?.pence_per_mile);
          
        } else if (name === 'amount') {
             let amountMiles = ((e.target.value * (perMile ? perMile : expense?.expenseTypeInfo?.pence_per_mile)/100)).toFixed(2);
             setAmountOfMile(amountMiles);
        }
         setFormData({...formData, [name] : e.target.value});
    };

   
    const handlerDateChange = (e, name) => {
        if(name === 'start_date') {  
            setFormData((formValue) => {
            return {
             ...formValue, start_date: e
            };
           }); 
            setStartDate(e);
            setFormData((formValue) => {
             return {
              ...formValue, end_date: e
             };
            }); 
            setEndDate(e);
         } else {
          let checkEndDate = moment(moment(startDate).format('YYYY-MM-DD')).isSameOrBefore(moment(e).format('YYYY-MM-DD'));
          if(checkEndDate)  {
            setError((errorValue) => {
              return {
                ...errorValue,
                errorEndDate : ''
              };
          });
          setFormData((formValue) => {
             return {
              ...formValue, end_date: e
             };
            }); 
            setEndDate(e);
           } else {
            setError((errorValue) => {
              return {
                ...errorValue,
                errorEndDate : 'Please Select greater date than start date  '
              };
          });
           }
           }
          
    };

    const handlerSingleDate = (e) => {
      if(e.target.checked) {
       
        setDisable(true);
      } else {
        setDisable(false);
      }
     };
    const handleUpload = (e) => {
      setFileList((files) => [ ...files,  ...e.target.files ]);
    };

    useEffect(() => {
      if (isLoadingDelete) {
        toastGeneralId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
      }
      if (isSuccessDelete) {
        toast.dismiss(toastGeneralId.current);
        toast.update(
          toastGeneralId.current,
          (" Expenses file deleted!",
          {
            render: "Expenses file deleted!",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
      }
      if (isErrorDelete) {
       
        const toastMessage = apiError?.data?.message
          ? apiError?.data?.message
          : "Something went wrong";
        toast.update(
          toastGeneralId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false, 
            delay:10
          })
        );
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessDelete, isErrorDelete, isLoadingDelete ]);

    const  handlerDelete = (e, f, close, fileId) => {
      e.preventDefault();
    
      if(fileId !== undefined) {
      deleteByFile(fileId);
      const removeFile = fileList?.filter((f) => f.name !== f?.name);
        setFileList(removeFile);
      } else {
        const removeFile = fileList?.filter((f) => f.name !== f?.name);
        setFileList(removeFile);
      }
      close();
    };
  
    useEffect(() => {
      if (isLoadingCreate) {
        toastGeneralId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
      }
      if (isSuccessCreate) {
        toast.dismiss(toastGeneralId.current);
        toast.update(
          toastGeneralId.current,
          (" Mileage is Updated!",
          {
            render: "Mileage is Updated!",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
        close();
        let payload = `/expenses/filter/search?page=${1}&employee_id=${employeeIdData?.employee_id}`;
          getAllMainExpenses(payload);
          let payload1=`/expenses/filter/search?page=${1}&employee_id=${employeeIdData?.employee_id}&limit=100`;
          getAllMainExpensesCSVRecord(payload1);
      }
      if (isErrorCreate) {
        toast.dismiss(toastGeneralId.current);
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.update(
          toastGeneralId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessCreate, isErrorCreate, isLoadingCreate ]);
  
    const handlerSubmit = (e, expense) => {
        e.preventDefault();
        var formdata = new FormData();
     formdata.append("department_id", employeeIdData?.department_id);
          formdata.append("employee_id", expense?.employee_id? expense?.employee_id : employeeIdData?.employee_id);
          formdata.append("start_date", formData?.start_date? formData?.start_date : expense.start_date);
          // expenses_type:'',
          formdata.append("end_date", formData?.end_date);
          formdata.append("reason", formData.reason ? formData.reason : expense.reason);
          formdata.append("is_mileage", formData?.is_mileage);
          formdata.append("type", formData?.type);
          formdata.append("amount", formData.amount ? formData.amount : expense.amount);
          formdata.append("amount_of_miles", amountOfMile);
          formdata.append("status", formData?.status);
          formdata.append("expense_id", expense.id);
          fileList.forEach((file) => {
          formdata.append("evidence_file", file);
          });
          createMainExpense(formdata);
    };
    return(
        <>
         <div className="header">
                            <h3>Claim Mileage </h3>
                          </div>
                          <div className="content">
                            <form>
                              <div className="flex flex-wrap ">
                                <div className=" w-full">
                                  <div className="form-group mb-2 ">
                                    <div className="flex">
                                      <div className="w-full pr-4">
                                        <label className="formBlock	">
                                          Date {!disable ? 'from' : ''}
                                        </label>
                                        <DatePicker
                                            // showIcon
                                            id="editMileageStartDate"
                                            dateFormat="dd/MM/yyyy"
                                            className="formControl"
                                            name = "start_date"
                                            selected = {startDate? startDate : new Date(expense?.start_date)}
                                            onChange={(e) =>  handlerDateChange(e, "start_date")}
                                            maxDate={new Date()}
                                      />
                                      </div>
                                     {!disable &&(<div className="w-full ">
                                        <label className="formBlock	">
                                          Date to
                                        </label>
                                        <DatePicker
                                            // showIcon
                                            dateFormat="dd/MM/yyyy"
                                            id="editMileageEndDate"
                                            className="formControl"
                                            name = "end_date"
                                            selected = {endDate ? endDate : new Date(expense?.end_date)}
                                            onChange={(e) =>  handlerDateChange(e, "end_date")}
                                            maxDate={disable ? startDate : new Date()}
                                            minDate = {disable ? startDate : moment().subtract(500, "years")}
                                        />
                                      </div>)}
                                    </div>

                                    <div className="help-block text-red-700 mt-1">{error.errorEndDate? error.errorEndDate : ''}</div>
                                  </div>
                                  <div className="form-group mb-6  flex">
                                    <label className="formBlock flex mr-2	">
                                      <input
                                        type="checkbox"
                                        name="  break"
                                        id="editMileageApplyDate"
                                        className="mr-2 w-5 h-5"
                                        onChange={(e) => handlerSingleDate(e)}
                                      />
                                      Apply single date?
                                    </label>
                                  </div>
                                </div>
                                <div className="md:w-1/3 md:pr-6 w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Mileage Type
                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                    </label>
                                    <select className="formControl" name='type' id="editMileageType"   value={formData.type} onChange={(e) => handlerChange(e)} >
                                    <FormSelect 
                                    optionValue={"0"}
                                    optionName={"Select Mileage"}
                                    />
                                    {allExpenseType?.length > 0 ? allExpenseType?.map((exp, key) => { 
                                        return   <FormSelect key={key} optionValue={exp?.id} optionName={exp?.expense_name} />;
                                    }) : null}
                                    </select>

                                    <div className="help-block"> 
                                    <div className="help-block text-red-700 mt-1"> {error.typeError ? error.typeError :''} </div> 
                                    </div>
                                  </div>
                                </div>
                                <div className="md:w-1/3 md:pr-6 w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Amount of Miles
                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                    </label>
                                    <FormInput
                                     inputType="number"
                                     inputName="amount"
                                     inputID="Amount"
                                     value= {formData.amount ? formData.amount : expense.amount}
                                     handlerChange={handlerChange}
                                    />

                                    <div className="help-block text-red-700 mt-1"> {error.amountError ? error.amountError :''} </div>
                                  </div>
                                </div>
                                <div className="md:w-1/3 md:pr-0 w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">£</label>
                                    <FormInput
                                      inputType="number"
                                      inputName="amount_of_miles"
                                      inputID="AmountMiles"
                                      value= {amountOfMile}
                                      handlerChange={handlerChange}
                                    />

                                    <div className="help-block" />
                                  </div>
                                </div>

                                <div className="w-full ">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Description
                                      {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                                    </label>
                                    <FormTextarea inputID="editMileageDescription"   textName="reason" value={formData.reason ? formData.reason : expense.reason} handlerChange = {handlerChange} />
                                   
                                  </div>
                                </div>
                                <div className="w-full">
                                  <div className="form-group mb-6 fileUploadInPopup ">
                                    <label className="formBlock	">
                                      Upload Evidence{" "}
                                      <span className="text-[11px]">
                                        (Supported formats: JPEG, PNG, PDF,
                                        Word, PPT, CSV)
                                      </span>
                                    </label>
                                    <div className='file_Upload'>
                                    <input 
                                      type="file"
                                      name="file" 
                                      className= "upload-button"
                                      id="exampleFile" 
                                      onChange={handleUpload} multiple
                                      />
                                      <p>Drop files here or click here to upload file.</p>
                                  </div>
                                    <span>Maximum upload file size:25 MB.</span>
                                    <div className="help-block" />
                                  </div>
                                  <>
              <div className="form-group mb-6 ">
              <ul className='flex flex-row flex-wrap'>
                  <>
                  { [ ...fileList ]?.map((f, index) => {
                    
                   let  FileExtension = f.name? f.name.split('.').pop():'';

                    return(
                      <li key={index}>
                      <div className='border-2 h-[90px] w-[90px] overflow-hidden mb-1'>
                       <img
                          src={f?.file_name}
                          alt={f?.name}
                          style={{ maxWidth: '100px', maxHeight: '100px'}} 
                        />
                       </div>
                  
                    <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-trash"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="editMileageDeleteClose"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Delete</h3>
                      </div>
                      <div className="content">
                        <p>Are you sure you want to delete file ?</p>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" id="editMileageDeleteYes" onClick={(e) => handlerDelete(e, f, close, f.id)}>Yes</button>
                            <button
                              type="button"
                              id="editMileageDeleteNo"
                              onClick={() => close()}
                              className="btn cancel  "
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                      </Popup>
                      {validImageTypes.includes(FileExtension)? 
                        <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                        <i className="fa fa-download" />
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="mileageDetailClose"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                     
                     <img src= {f.file_name} height="200" width="300" />
                     <a href={f.file_name}>Dowload</a>
                    </div>
                  )}
                </Popup> :
                <a href={f.file_name} className='actionBtn'> <i className="fa fa-download " /></a>
                }
                      </li>
                    );
                  })}

                
                </>
                </ul> 
                </div>
                                  </>
                                </div>
                                <div className="lg:w w-full  ">
                                  <div className="w-full mt-4">
                                    <div className="form-group pt-4 pb-3 flex justify-center ">                                     
                                       <button
                                        className="btn save mr-2"
                                        id="editMileageSave"
                                        onClick={(e) => handlerSubmit(e, expense)}
                                      >Save</button>
                                      <PrimaryButton
                                        btnText="Cancel"
                                        onClick={() => close()}
                                        Btnclass="btn cancel"
                                        btntype="button"
                                        buttonID="editMileageCancel"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
        </>
    );
};

export default EditMyMileage;