import {useState} from "react";
import {Autocomplete} from "@react-google-maps/api";
const SearchLocation = ({setEmployeeInfo, employeeInfo}) => {
  const [addressObject, setAddressObject] = useState(null);

  const [autocomplete, setAutocomplete] = useState(null);

  const handlePlaceChanged = async () => {
    if (autocomplete) {
      const place = autocomplete?.getPlace();
      const address_components = place.address_components;
      for (let i = 0; i < address_components.length; i++) {
        const component = address_components[i];
        const types = component.types;

        if (types.includes("locality")) {
          if (component.long_name !== "") {
            employeeInfo.city = component.long_name.toString();
          } else {
            employeeInfo.city = "";
          }
        } else if (types.includes("administrative_area_level_1")) {
          employeeInfo.state = component.long_name;
        } else if (types.includes("postal_code")) {
          if (component.long_name !== "") {
            setEmployeeInfo({
              ...employeeInfo,
              ["postal_code"]: component.long_name
            });
            employeeInfo.postal_code = component.long_name;
          } else {
            setEmployeeInfo({...employeeInfo, ["postal_code"]: ""});
          }
        } else if (types.includes("country")) {
          employeeInfo.country = component.long_name;
          setEmployeeInfo({...employeeInfo, ["country"]: component.long_name});
        }
      }
      setEmployeeInfo({...employeeInfo, ["address"]: place?.formatted_address});
    }
  };

  return (
    <>
      <Autocomplete
        onLoad={(auto) => setAutocomplete(auto)}
        onPlaceChanged={handlePlaceChanged}
        googleMapsApiKey="AIzaSyC555n_gq8L_4IPtZFqFGHK6ClByoCXXs8"
      >
        <>
          <input
            type="text"
            name="address"
            className="formControl"
            placeholder={"Enter Address"}
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "16px",
              marginTop: "2px",
              border: "solid 1px #e3e3e3"
            }}
            onChange={(e) => {
              setAddressObject({
                ...addressObject,
                address: e.target.value
              });
            }}
            defaultValue={
              addressObject?.address
                ? addressObject?.address
                : employeeInfo?.address
            }
          />
        </>
      </Autocomplete>
    </>
  );
};

export default SearchLocation;
