/* eslint-disable camelcase */
import { useState, useCallback, useRef} from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import {SearchField} from "../../components/filters";
import moments  from 'moment';
import {FormSelect} from "../../components/formInput";
import AddAbsence from './AddAbsence';
import {useGetDepartmentListMutation} from "../../services/approverListApi";
import {useGetEmployeeMutation, useGetAllEmployeeListMutation} from "../../services/employeeApi";
import { useEffect } from "react";
import {useGetAllLeaveAbsenceMutation, useDeleteLeaveAbsenceMutation, useGetLeaveAbsenceAllRecordMutation} from "../../services/leaveAbsenceApi";
import {Link, useParams, useSearchParams} from 'react-router-dom';
import EditAbsence from './EditAbsence';
import Pagination from "../../components/inc/pagination";
import DatePicker from "react-datepicker";
import moment from 'moment/moment';
import NoDataFound from "../../helper/noDataFound";
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from "react-redux";
import { useGetEmployeeAllDaysScheduleMutation } from "../../services/employeeApi";
import { REACT_APP_COMPANY_ADMIN_ROLE_ID, REACT_APP_MANAGER_ROLE_ID, REACT_APP_EMPLOYEE_ROLE_ID, REACT_APP_AUDITOR_ROLE_ID } from "../../helper/constant";
import ViewPopup from "../../components/ViewPopup";
import ViewAbsence from "./viewAbsence";
import { allEmployeeListDataAction, employeeInfo, employeeScheduleInfoAction } from "../../redux/EmployeeSlice/employeeSlice";
import { absenceAllRecordDataAction, absenceDataAction } from "../../redux/AbsenceSlice/AbsenceSlice";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const Absense = () => {
  const ignore = useRef(false);
  const  filterEmployeeResponse = useSelector((state) => state?.persistedReducer?.employeeSlice?.allEmployeeListData);
  const getEmployeeAllDayScheduleRespo = useSelector((state) => state?.persistedReducer?.employeeSlice?.employeeScheduleInfo);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const employeeListData = useSelector((state) => state?.persistedReducer?.employeeSlice?.employee);
  const dataLeaveApiResponse = useSelector((state) => state?.persistedReducer?.absenceSlice?.absenceData);
  const dataLeaveApiAllRecordResponse = useSelector((state) => state?.persistedReducer?.absenceSlice?.absenceAllRecordData);
  const [ getDepartmentList, {data:departmentApiResponse, isSuccess: isSuccessDepartmentApi} ] = useGetDepartmentListMutation();
  const [ getEmployee, {data:employeeListAllData, isSuccess:isEmployeeAPISuccess} ] = useGetEmployeeMutation();
  const [ getAllLeaveAbsence, {data: leaveAPIData, isSuccess:isSuccessResponse} ] = useGetAllLeaveAbsenceMutation();
  const [ getLeaveAbsenceAllRecord, {data:LeaveApiAllDataRecordResponse, isSuccess:isSuccessAllRecordResponse} ] = useGetLeaveAbsenceAllRecordMutation();  
  const [ deleteLeaveAbsence, { isSuccess: isSuccessDelete, isLoading: isLoadingDelete, isError: isErrorDelete} ] = useDeleteLeaveAbsenceMutation();
  const [ getAllEmployeeList, {data: filterEmployeeResponseData,  isSuccess: isSuccessAllEmployee} ] = useGetAllEmployeeListMutation();
  const [ open, setOpen ] = useState(false);
  const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);
  const [ getEmployeeAllDaysSchedule, {data: EmployeeAllDayScheduleRespo, isSuccess:isSuccessSchedule} ] = useGetEmployeeAllDaysScheduleMutation();
  const [ allDepartmentListData, setAllDepartmentListData ] = useState();
  const [ allEmployeeData, setAllEmployeeData ] = useState();
  const [ absenceAllLeaveData, setAbsenceAllLeaveData ] = useState();
  const [ searchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(1);
  const toastId = useRef(null);
  const {id} =useParams();
  const [ excelData, setExcelData ] = useState([]);
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const dispatch = useDispatch();
  const loggedInUserData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const [ filter, setFilter ] = useState({
    name:'',
    department:''
  });
  const filterEmployees = useCallback((searchTerm) => {
    setFilter({...filter, ['name'] : searchTerm});
    let payload=`/leaves?type=${2}&page=${page}&first_name=${searchTerm}`;
      getAllLeaveAbsence(payload);
// eslint-disable-next-line react-hooks/exhaustive-deps      
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);
   
  useEffect(() => {
    if (!ignore.current){
    if(id){
      getEmployeeAllDaysSchedule(id);
    } else {
      getEmployeeAllDaysSchedule(employeeIdData);
    }
    getDepartmentList();
    getAllEmployeeList();
    let payload=`/leaves?type=${2}&page=${1}&limit=100`;
    getLeaveAbsenceAllRecord(payload);
  }
  return () => { ignore.current = true; };
// eslint-disable-next-line react-hooks/exhaustive-deps       
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
  []);
useEffect(() => {
  if(isSuccessSchedule){
    dispatch(employeeScheduleInfoAction(EmployeeAllDayScheduleRespo));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessSchedule ]);


  const closeModal = () => {
    setOpen(false);
  };
  
    useEffect(
      () => {
        if (filter?.name) {
          let payload = `/leaves?type=${2}&page=${page}&first_name=${
            filter?.name
          }`;
          getAllLeaveAbsence(payload);
        } else if (filter?.department) {
          let payload = `/leaves?type=${2}&page=${page}&department_id=${
            filter.department
          }`;
          getAllLeaveAbsence(payload);
        } else {
          let payload = `/leaves?type=${2}&page=${page}`;
          getAllLeaveAbsence(payload);
        }
        getEmployee(`/employee?page=${page}`);
      },
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [page]
    );


useEffect(() => {
  if(isSuccessAllEmployee){
     dispatch(allEmployeeListDataAction(filterEmployeeResponseData));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessAllEmployee ]);

useEffect(() => {
      if(filterEmployeeResponse){
          setAllEmployeeData(filterEmployeeResponse?.data);  
      }     
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ filterEmployeeResponse ]);
useEffect(() => {
  if(isSuccessDepartmentApi){
    dispatch(departmentListAction(departmentApiResponse));
  }
 },
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessDepartmentApi ]);
    
 useEffect(() => {
        let arr = [];
        if (departmentApiData) {
          departmentApiData?.data?.length > 0 && departmentApiData?.data?.forEach((dept) => {
            if (loggedInUserData?.roleId == REACT_APP_MANAGER_ROLE_ID && loggedInUserData?.department_id && dept?.id == loggedInUserData?.department_id) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr.push(deptOption);
            }
            if (loggedInUserData?.roleId == REACT_APP_COMPANY_ADMIN_ROLE_ID) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr.push(deptOption);
            }
            if (loggedInUserData?.roleId == REACT_APP_EMPLOYEE_ROLE_ID && loggedInUserData.department_id) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr?.push(deptOption);
            }
          });
          setAllDepartmentListData(arr);
        }
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
 [ loggedInUserData, departmentApiData ]);

useEffect(() => {
  if(isEmployeeAPISuccess) {
   dispatch(employeeInfo(employeeListAllData));
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isEmployeeAPISuccess ]);

    useEffect(() => {
     if(employeeListData) {
      
      setAllEmployeeData(employeeListData?.data);
     }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ employeeListData ]);

useEffect(() => {
  if(dataLeaveApiResponse){
     if(dataLeaveApiResponse?.data?.length < 1 && dataLeaveApiResponse?.currentPage > 1 ) {
       getAllLeaveAbsence(dataLeaveApiResponse?.currentPage - 1);
     
     }
    setPages(dataLeaveApiResponse?.totalPages );
    setAbsenceAllLeaveData(dataLeaveApiResponse?.data);
   } 
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ dataLeaveApiResponse ]);

    useEffect(() => {
      if(isSuccessResponse){
        dispatch(absenceDataAction(leaveAPIData));
       } 
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessResponse ]);
useEffect(() => {
  if(dataLeaveApiAllRecordResponse?.data?.length > 0) {
    let data =  exportData(dataLeaveApiAllRecordResponse?.data ? dataLeaveApiAllRecordResponse?.data :"");
    setExcelData(data);
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ dataLeaveApiAllRecordResponse ]);

    useEffect(() => {
      if(isSuccessAllRecordResponse) {
       dispatch(absenceAllRecordDataAction(LeaveApiAllDataRecordResponse));
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessAllRecordResponse ]);

  let  headers = [
    { label: 'Employee Name', key: 'Employee' },
    { label: 'Department', key: 'Department' },
    { label: 'Company Name', key: 'companyInfo.name' },
    { label: 'AppliedBy', key : 'applied_by'},
    { label: 'AddeddBy', key : 'addedByInfo'},
    { label: 'Start Date', key : 'start_date'},
    { label: 'End Date', key : 'end_date'},
    { label: 'Start Time', key : 'start_time'},
    { label: 'End Time', key : 'end_time'},
    { label: 'Duration(Days/Hours)', key : 'total_duration'},
    { label: 'Status', key : 'status'},
    { label: 'Type', key : 'type'},
    { label: 'Approved By', key : 'approved_by'},
    { label: 'Approved Date', key : 'approved_date'},
    { label: 'Reason', key : 'reason'},
    { label: 'Notes', key : 'notes'},
    { label: 'Cancel Reason', key : 'cancel_reason'},
    { label: 'CreatedAt', key : 'createdAt'},
    { label: 'UpdatedAt', key : 'updatedAt'},
    { label: 'DeletedAt', key : 'deletedAt'}
    ];
    
  var  dataAbence = [];
    
    const exportData = (data) => {
      if (data) {
        data?.forEach((dExcel) => {
          let hour = parseInt(dExcel?.total_duration) / 60 ?  parseInt(dExcel?.total_duration) / 60  : 'null';
          let days = hour / 8 ? hour / 8 : 'null';
          let val = {
            Employee: dExcel?.employeeInfo?.first_name ?  dExcel?.employeeInfo?.first_name :"",
            Department: dExcel?.departmentInfo?.department_name ?dExcel?.departmentInfo?.department_name :"",
            companyInfo: {name: `${dExcel?.companyInfo?.name ? dExcel?.companyInfo?.name : "" }`},
            applied_by: dExcel?.applied_by?.first_name ?  dExcel?.applied_by?.first_name :"",
            addedByInfo: dExcel?.addedByInfo?.first_name ? dExcel?.addedByInfo?.first_name :"",
            start_date: dExcel?.start_date ? moment(dExcel?.start_date).format("DD-MM-YYYY") : '',
            end_date: dExcel?.end_date ?  moment(dExcel?.end_date).format("DD-MM-YYYY") : '',
            start_time: dExcel?.start_time ?  dExcel?.start_time : '',
            end_time: dExcel?.end_time ? dExcel?.end_time : '',
            total_duration: `${days ? days : 0} days`,
            status: dExcel?.status === "1" ? "Active" : "Inactive",
            type: dExcel.type ? "Absence" : "Leave",
            approved_by: dExcel?.approvedByInfo?.first_name ? dExcel?.approvedByInfo?.first_name : '',
            approved_date: dExcel?.approved_date ? moment(dExcel?.approved_date).format("DD-MM-YYYY") : "",
            notes: dExcel?.notes ? dExcel?.notes : "",
            reason: dExcel.reason ? dExcel?.reason : "",
            cancel_reason: dExcel?.cancel_reason ? dExcel?.cancel_reason : "",
            createdAt:dExcel?.createdAt ? moment(dExcel?.createdAt).format("DD-MM-YYYY") : "",
            updatedAt:dExcel?.updatedAt ?  moment(dExcel?.updatedAt).format("DD-MM-YYYY") : '',
            deletedAt: dExcel?.deletedAt
              ? moment(dExcel?.deletedAt).format("DD-MM-YYYY")
              : "null"
          };
          dataAbence?.push(val);
        });
        return dataAbence;
      }
    };

    const decrementPage = () => {
      getAllLeaveAbsence(dataLeaveApiResponse?.currentPage - 1 );
      setPage(dataLeaveApiResponse?.currentPage - 1);
    };

  const handlerDepartmentChange = (e) => {
    e.preventDefault();
    if (e.target.value === '0') {
      let payload=`/leaves?type=${2}&page=${page}`;
      getAllLeaveAbsence(payload);
    } else {
      let payload=`/leaves?type=${2}&page=${1}&department_id=${e.target.value}`;
      getAllLeaveAbsence(payload); 
    }
    setFilter({...filter, ['department']: e.target.value});
  };

  const onDateChange = (e) => {
    if(e[0] !== null && e[1] !== null){
      let payload=`/leaves?type=${2}&page=${page}&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}`;
        getAllLeaveAbsence(payload); 
 
    } else {
      let payload=`/leaves?type=${2}&page=${page}`;
        getAllLeaveAbsence(payload); 
    }
  };

  
 
  useEffect(() => {
    if(isLoadingDelete) {
      toastId.current = toast.loading("Please wait...", {
        // position: "top-center",
        position:"top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
     }
    if(isSuccessDelete) {
      // getDeleteDataHoliday();
      toast.update(
        toastId.current,
        ("Absence deleted successfully.",
        {
          render: "Absence deleted successfully.",
          type: "success",
          autoClose: 8000,
          isLoading: false
        })
      );
      let payload=`/leaves?type=${2}&page=${page}`;
      getAllLeaveAbsence(payload);
    }
    if (isErrorDelete) {
      const toastMessage = 
      // apiError.data.message
      //   ? apiError.data.message
      //   :
         "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          isLoading: false
        })
      );
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isErrorDelete, isSuccessDelete, isLoadingDelete ]);
  
 const handlerDelete = (e, id, close) => {
  e.preventDefault();
  deleteLeaveAbsence(id);
  close();
 };

 const hidingAbsenceReason = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.companies?.calendar);
 const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
 const shouldShowReason = roleIdData === 4 && hidingAbsenceReason === "1";

 const createCsvFileName = () => `Absence_report_${moment()?.format()}.csv`;
  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Absence</title>
          </Helmet>
        </HelmetProvider>
        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Absence</h2>
              <div className="card bg-white  mb-3">
                <div className="heading_title px-4 flex justify-end items-center py-4">
                  <div className="headerButton">
                    {excelData?.length > 0  &&
                    <CSVLink
                      data={excelData}
                      headers={headers}
                      filename={createCsvFileName()}
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        outline: "none",
                        height: "5vh"
                      }}
                    >
                      <button className="btn-md btn-add ml-2" id="expoertCsv">
                        <span className="fa fa-file-export mr-2" /> Export to
                        CSV
                      </button>
                    </CSVLink> }
                    {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                    <Popup
                      open={open}
                      onClose={() => closeModal()}
                      closeOnDocumentClick={false}
                      trigger={
                        <button
                          className="btn-md btn-add ml-2 "
                          aria-describedby="popup-8"
                          id="addAbsence"
                          onClick={() => setOpen((o) => !o)}
                        >
                          <span className="fa fa-plus mr-2" />
                          Add Absence
                        </button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            id="absenceClose"
                            onClick={() => {
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                          <AddAbsence
                            allDepartmentListData={allDepartmentListData}
                            allEmployeeData={allEmployeeData}
                            close={close}
                            getAllLeaveAbsence={getAllLeaveAbsence}
                            page={page}
                          />
                        </div>
                      )}
                    </Popup>)}
                  </div>
                </div>
                <div className="p-4">
                  <div className="flex pt-2 mb-6 filterSection flex-wrap lg:flex-nowrap w-full justify-between ">
                    <div className=" flex flex-wrap w-full">
                      <div className="searchSec md:w-1/3 md:pr-4 w-full mb-2 lg:mb-0 ">
                        <SearchField
                          placeholderText="Search By Name"
                          searchId="search"
                          onChange={(e) => filterEmployees(e.target.value)}
                        />
                      </div>
                      <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 ">
                        <DatePicker
                          id="date"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          className="formControl"
                          placeholderText="Select Date Range"
                          onChange={(update) => {
                            setDateRange(update);
                            onDateChange(update);
                          }}
                          isClearable={true}
                          locale="en-GB"
                        />
                      </div>
                      <div className=" md:w-1/3 w-full md:mb-0 mb-4 lg:pr-4 searchSec">
                        <select
                          className="formControl mr-2"
                          name="department_id"
                          id="department_id"
                          onChange={(e) => handlerDepartmentChange(e)}
                        >
                          <FormSelect
                            optionValue={"0"}
                            optionName={"Select department"}
                          />
                          ;
                          {allDepartmentListData?.length > 0
                            ? allDepartmentListData?.map((departments, key) => {
                                return (
                                  <FormSelect
                                    key={key}
                                    optionValue={departments?.value}
                                    optionName={departments?.label}
                                  />
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th className="w-40">Employee Name</th>
                          <th>Department</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Absence Duration</th>
                          {shouldShowReason ? null : <th>Reason</th>}
                          <th>Added by</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {absenceAllLeaveData?.length > 0 ? (
                          <>
                            {absenceAllLeaveData &&
                              absenceAllLeaveData.map((absence, index) => {
                                // CHANGES FOR TICKET 0005929
                                const hoursOrdays =
                                  absence?.employeeInfo?.hours_or_day; // to compare allowance is in hours or days
                                const totalMinutes = Number(
                                  absence?.total_duration
                                ); // total duration of employee absence

                                // code to format hours
                                const formatHoursAndMinutes = (
                                  totalMinutes
                                ) => {
                                  let hours = Math.floor(totalMinutes / 60);
                                  let minutes = totalMinutes % 60;
                                  // add leading zeros if needed
                                  hours = hours < 10 ? "0" + hours : hours;
                                  minutes =
                                    minutes < 10 ? "0" + minutes : minutes;
                                  if (minutes == 0) return `${hours} hours`;
                                  else
                                    return `${hours} hours ${minutes} minutes`;
                                };
                                const formattedhours =
                                  formatHoursAndMinutes(totalMinutes);
                                const totalLeaveDays = absence?.leave_day;
                                const formattedTotalLeaveDays = totalLeaveDays ? parseFloat(totalLeaveDays).toFixed(2) : "00.00";

                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={`/employees/detail/${absence?.employee_id}`}
                                        className="listImage flex items-center "
                                      >
                                        {absence?.employeeInfo?.first_name +
                                          " " +
                                          absence?.employeeInfo?.last_name}
                                      </Link>
                                    </td>
                                    <td>
                                      {absence?.departmentInfo?.department_name}
                                    </td>
                                    <td>
                                      {moments(absence?.start_date).format(
                                        formattedDate
                                      )}
                                    </td>
                                    <td>
                                      {moments(absence?.end_date).format(
                                        formattedDate
                                      )}
                                    </td>
                                    <td>
                                      {hoursOrdays == "1"
                                        ? `${formattedhours}`
                                        : `${formattedTotalLeaveDays} days`}
                                    </td>
                                    {shouldShowReason ? null : (
                                      <td>
                                        <ViewPopup
                                          text={"Reason"}
                                          data={
                                            absence?.reason ? (
                                              absence?.reason
                                            ) : (
                                              <p className="text-red-700">
                                                Reason not provided by employee
                                              </p>
                                            )
                                          }
                                        />
                                      </td>
                                    )}

                                    <td>{absence?.addedByInfo?.first_name}</td>                                    
                                    <td>
                                    {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                                      <Popup
                                        open={open}
                                        onClose={() => closeModal()}
                                        closeOnDocumentClick={false}
                                        trigger={
                                          <div
                                            onClick={() => setOpen((o) => !o)}
                                            className="actionBtn"
                                          >
                                            <i className="fa fa-pencil" />
                                          </div>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="modal">
                                            <button
                                              className="close"
                                              id="editAbsenseClose"
                                              onClick={() => {
                                                closeModal();
                                                close();
                                              }}
                                            >
                                              &times;
                                            </button>
                                            <EditAbsence
                                              absence={absence}
                                              allDepartmentListData={
                                                allDepartmentListData
                                              }
                                              allEmployeeData={allEmployeeData}
                                              getAllLeaveAbsence={
                                                getAllLeaveAbsence
                                              }
                                              setAllEmployeeData={
                                                setAllEmployeeData
                                              }
                                              page={page}
                                              close={close}
                                              shouldShowReason={
                                                shouldShowReason
                                              }
                                            />
                                          </div>
                                        )}
                                      </Popup>)}
                                      <Popup
                                        open={open}
                                        onClose={() => closeModal()}
                                        closeOnDocumentClick={false}
                                        trigger={
                                          <div
                                            onClick={() => setOpen((o) => !o)}
                                            className="actionBtn"
                                          >
                                            <i className="fa fa-eye" />
                                          </div>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="modal">
                                            <button
                                              className="close"
                                              id="editAbsenseClose"
                                              onClick={() => {
                                                closeModal();
                                                close();
                                              }}
                                            >
                                              &times;
                                            </button>
                                            <ViewAbsence
                                              absence={absence}
                                              allDepartmentListData={
                                                allDepartmentListData
                                              }
                                              allEmployeeData={allEmployeeData}
                                              getEmployeeAllDayScheduleRespo={
                                                getEmployeeAllDayScheduleRespo?.dayHours
                                              }
                                              shouldShowReason={
                                                shouldShowReason
                                              }
                                              // getAllLeaveAbsence={getAllLeaveAbsence}
                                              // setAllEmployeeData={setAllEmployeeData}
                                              // page = {page}
                                              // close = {close}
                                            />
                                          </div>
                                        )}
                                      </Popup>
                                      {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                                      <Popup
                                        open={open}
                                        onClose={() => closeModal()}
                                        closeOnDocumentClick={false}
                                        trigger={
                                          <div
                                            onClick={() => setOpen((o) => !o)}
                                            className="actionBtn cancelBtn"
                                          >
                                            <i className="fa fa-trash" />
                                          </div>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="modal">
                                            <button
                                              className="close"
                                              id="deleteClose"
                                              onClick={() => {
                                                closeModal();
                                                close();
                                              }}
                                            >
                                              &times;
                                            </button>
                                            <div className="header">
                                              {" "}
                                              <h3>Deleted</h3>
                                            </div>
                                            <div className="content">
                                              <p>
                                                Are you sure you want to delete
                                                this ?
                                              </p>
                                              <div className="w-full mt-8">
                                                <div className="form-group pt-4 pb-3 flex justify-center ">
                                                  <button
                                                    className="btn save mr-2"
                                                    id="deleteYes"
                                                    onClick={(e) =>
                                                      handlerDelete(
                                                        e,
                                                        absence.id,
                                                        close
                                                      )
                                                    }
                                                  >
                                                    Yes
                                                  </button>
                                                  <button
                                                    type="button"
                                                    id="deleteNo"
                                                    onClick={() => close()}
                                                    className="btn cancel "
                                                  >
                                                    No
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>)}
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <center className="mt-4">{<NoDataFound />}</center>
                        )}
                      </tbody>
                    </table>
                    <Pagination
                      page={page}
                      pages={pages}
                      changePage={setPage}
                      decrementPage={decrementPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Absense;

