import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';

export const overTimeApi = createApi({
    reducerPath: "overTimeAPI",
    baseQuery: fetchBaseQuery({
      baseUrl: ApiUrl
}),
endpoints: (builder) => ({
 
 
    getAllOverTime: builder.mutation({
      query: (payload) => {
        return {
          url: payload,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          }
        };
      }
    }),
    getAllMaterOverTimeList:builder.mutation({
      query: () => ({
        url: `/masterovertime?page1&status=1&limit=200`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
createOverTime: builder.mutation({
      query: (payload) => ({
        url: '/masterovertime',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
  }),
  updateOverTime: builder.mutation({
    query: (payload) => {
       const { id, ...body } = payload;
      return {
        url: `/masterovertime/${id}`,
        method: 'PUT',
        body,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      };
    },
    invalidatesTags: [ 'Post' ]
  }),
  deleteOverTime: builder.mutation({
    query: (id) => ({
      url: `/masterovertime/${id}`,
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
  }) 

})
});

export const { useGetAllOverTimeMutation, useCreateOverTimeMutation, useUpdateOverTimeMutation, useDeleteOverTimeMutation, useGetAllMaterOverTimeListMutation} = overTimeApi;