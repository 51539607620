import { BrowserRouter as Router, Routes } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import React, { Suspense } from "react";
import AdminRoutes from "./adminRoutes";
import { LoadingIndicator } from "../components/loading-indicator";

const RouterFile = () => (
  <Router>
    <div>
      <Suspense fallback={<LoadingIndicator />}>
        <Routes>{AdminRoutes}</Routes>
      </Suspense>
    </div>
  </Router>
);

export default RouterFile;
