import { useState, useRef } from "react";
import { FormInput } from "../../../components/formInput";
import PrimaryButton from "../../../components/primaryButton";
import { useUpdateOverTimeMutation } from "../../../services/overTimeApi";
import { useEffect } from "react";
import { toast } from "react-toastify";

const EditOverTimeSetting = ({ getAllOverTime, page, close, over }) => {

  const [ updateOverTime, { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate, isError: isErrorUpdate, error: apiOvertimeError } ] = useUpdateOverTimeMutation();
 
  const closeModal = () => {
    close();
  };
  const toastId = useRef(null);

  /* States are defined here */
  const [ formData, setFormData ] = useState({
    overtime_name: "",
    color: "",
    time_in_lieu: "0",
    available_to: "",
    status: "",
    available_to_all: null,
    multiplyOvertimeBy: 0
  });

  const [ error, setError ] = useState({
    errorOvertimeName: "",
    errorColor: "",
    errorAvailableTo: "",
    errorStatus: ""
  });

  useEffect(
    () => {
      if (isLoadingUpdate) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessUpdate) {
        toast.update(
          toastId.current,
          ("Overtime created successfully.",
          {
            render: "Overtime created successfully.",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
        const url = `/masterovertime?page=${page}&limit=50`;
        getAllOverTime(url);
        close();
      }
      if (isErrorUpdate) {
        const toastMessage = apiOvertimeError.data.message
          ? apiOvertimeError.data.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessUpdate, isErrorUpdate, isLoadingUpdate ]
  );

  const handlerChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    if (name === "overtime_name") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorOvertimeName: "Please select overTime name"
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorOvertimeName: ""
          };
        });
      }
    }
    if (name === "color") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorColor: "Please select color"
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorColor: ""
          };
        });
      }
    }
    if (name === "available_to") {
      if (name === "0") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorAvailableTo: "Please select available "
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorAvailableTo: ""
          };
        });
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        available_to_all: e.target.value === "1" ? "1" : ""
      }));
    }
    if (name === "status") {
      if (name === "0") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorStatus: "Please select status "
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorStatus: ""
          };
        });
      }
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: e.target.value
    }));
  };

  const handlerChangCheck = (e, name) => {
    setFormData({ ...formData, [name]: e.target.checked ? "1" : "0" });
  };

  const overTimeSubmit = (e, edit = false, close, over = false) => {
    e.preventDefault();
    if (edit) {
      const value = {
        overtime_name: formData.overtime_name
          ? formData.overtime_name
          : over.overtime_name,
        color: formData.color ? formData.color : over.color,
        time_in_lieu: formData.time_in_lieu
          ? formData.time_in_lieu
          : over.time_in_lieu,
        available_to: formData.available_to
          ? formData.available_to
          : `${over.available_to}`,
        status: formData.status ? formData.status : over.status,
        id: over.id,
        available_to_all: formData?.available_to_all
          ? formData?.available_to_all
          : over?.available_to_all,
        multiplyOvertimeBy: parseFloat(formData?.multiplyOvertimeBy) ? parseFloat(formData?.multiplyOvertimeBy) : over?.multiplyOvertimeBy
      };
      updateOverTime(value);
    }
  };

  return (
    <>
      <div className="header">
        <h3>Edit Overtime Types</h3>
      </div>
      <div className="content">
        <form onSubmit={(e) => overTimeSubmit(e, true, close, over)}>
          <div className="flex flex-wrap ">
            {over?.company_id === null ? null : (
              <div className="md:w-1/2 md:pr-6 w-full">
                <div className="form-group mb-6 ">
                  <label className="formBlock	">Name</label>
                  <FormInput
                    inputType="text"
                    inputName="overtime_name"
                    inputID="Name"
                    value={over?.overtime_name}
                    handlerChange={handlerChange}
                  />
                  <div className="help-block" />
                </div>
              </div>
            )}
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Status </label>
                <select
                  className="formControl"
                  id="editOvertimeTypesStatus"
                  name="status"
                  defaultValue={over?.status}
                  selected={over.status}
                  onChange={(e) => handlerChange(e)}
                >
                  <option
                    value="0"
                    selected={over.status === 0 ? "selected" : ""}
                    onChange={(e) => handlerChange(e)}
                  >
                    Inactive
                  </option>
                  <option
                    value="1"
                    selected={over.status === 1 ? "selected" : ""}
                    onChange={(e) => handlerChange(e)}
                  >
                    Active
                  </option>
                </select>
                <div className="help-block" />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Multiply Overtime By</label>
                <FormInput
                  inputType="number"
                  inputName="multiplyOvertimeBy"
                  inputID="multiplyOvertimeBy"
                  value={over?.multiplyOvertimeBy}
                  handlerChange={handlerChange}
                />
              </div>
            </div>
            {over?.company_id === null ? null : (
              <div className="md:w-1/2 md:pr-6 w-full">
                <div className="form-group mb-6 ">
                  <label className="formBlock	">
                    Available to
                    <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                  </label>
                  <select
                    className="formControl"
                    id="overtimeTypesAvailable"
                    name="available_to"
                    defaultValue={over.available_to}
                    onChange={(e) => handlerChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="1">All</option>
                    <option value="2">Company Admin/HR</option>
                    <option value="3">Manager</option>
                    <option value="4">Employee</option>
                  </select>
                  <div className="help-block text-red-700">
                    {error.errorAvailableTo ? error.errorAvailableTo : ""}{" "}
                  </div>
                </div>
              </div>
            )}
            {over?.company_id === null ? null : (
              <div className="md:w-1/2 pr-0">
                <div className="form-group mb-6 ">
                  <label className="formBlock flex	">
                    <input
                      type="checkbox"
                      className="mr-2 w-5 h-5"
                      id="editOvertimeTypesAllow"
                      name="time_in_lieu"
                      value={over.time_in_lieu}
                      defaultChecked={over.time_in_lieu === "1" ? true : false}
                      onChange={(e) => handlerChangCheck(e, "time_in_lieu")}
                    />
                    Allow time in lieu on request
                  </label>

                  <div className="help-block" />
                </div>
              </div>
            )}
            <div className="md:w w-full  ">
              <div className="w-full mt-4">
                <div className="form-group pt-4 pb-3 flex justify-center ">
                  <button
                    className="btn save mr-2"
                    id="editOvertimeTypesUpdate"
                    onClick={(e) => overTimeSubmit(e, true, close, over)}
                  >
                    Update
                  </button>
                  <PrimaryButton
                    id="editOvertimeTypesCancel"
                    Btnclass="btn cancel "
                    btnText="Cancel"
                    onClick={() => closeModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditOverTimeSetting;
