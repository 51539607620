import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../constant";

export const auditorsApi = createApi({
  reducerPath: "auditorsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),
  endpoints: (builder) => ({
    addAuditor: builder.mutation({
      query: (payload) => ({
        url: "/auditor/register",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    editAuditor: builder.mutation({
      query: ({id, payload}) => ({
        url: `/auditor/update/${id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    loginAuditor: builder.mutation({
      query: (payload) => ({
        url: "/auditor/login",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    auditorsLists: builder.mutation({
        query: () => ({
          url: "/auditor/lists",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
    }),
    deleteAuditors: builder.mutation({
      query: (id) => ({
        url: `/auditor/delete/${id}`,
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
  }),
    getCompanyLists: builder.query({
        query: () => ({
          url:  `/admin/list/company?limit=${1000}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
    })
  }),
  onError: (
    error
    //  { dispatch, getState }
  ) => {
    if (error.status === 401) {
      alert("Invalid Token Error");
      // Handle 401 Unauthorized error here
      // For example, you can dispatch an action to update the auth state:
      // dispatch(authActions.setAuthenticated(false));
      // Or show a notification/alert to the user:
      // showNotification("You are not authorized to perform this action.");
    }
  }
});

export const {
  useAddAuditorMutation,
  useAuditorsListsMutation,
  useGetCompanyListsQuery,
  useDeleteAuditorsMutation,
  useLoginAuditorMutation,
  useEditAuditorMutation
} = auditorsApi;
