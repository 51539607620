import { useEffect, useState, useRef} from "react";
import PrimaryButton from "../../components/primaryButton";
import LoadingScreen from "../../helper/LoadingScreen";
import {  toast } from "react-toastify";
import {
    useGetPlanMutation,
    useUpdateTrialPlanDayMutation
} from "../../services/authApi";

const PlanSetting = () => {
  const ignore = useRef(false);
  const [ updateTrialPlanDay, { isSuccess:isSuccessTrial, isError:isErrorTrial } ] = useUpdateTrialPlanDayMutation();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ formData, setFormData ] = useState({
    days: ""
  });
  const [ getplan, {data: planData, isSuccess: isSuccess} ] = useGetPlanMutation();

  useEffect(() => {
    if (!ignore.current){
    getplan();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
  const handlerSave = (e) => {
    e.preventDefault();
    let payload = {
      "days": `${formData.days}`
    };
    updateTrialPlanDay(payload);
    getplan();
  };
  
  const handlerChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if(planData !== undefined && isSuccess) {
        setIsLoading(false);
        setFormData({
            days: planData?.subscription_plans[0]?.total_period
        });
    }
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
   
    }
    if (isSuccessTrial) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.update(
        toastId.current,
        ("Updated Successfully!",
        {
          render: "Updated Successfully",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
    }
    if (isErrorTrial) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      const toastMessage = "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isLoading, isSuccessTrial, isErrorTrial, isSuccess ]);
  const toastId = useRef(null);
  
  return (
    <>
    {
      isLoading ? ( <LoadingScreen /> ) :(
        <>
         {/* <ToastContainer /> */}
        <div className="heading_title px-4 flex justify-between items-center py-4">
          <div>
            <h3 className=" font-semibold text-lg">Plan Setting</h3>
            <p>
                What duration of complimentary access will be provided to trial users?
            </p>
          </div>
        </div>
        <div className="p-4">          
          <div className="flex mb-4 items-center">
            <div className="md:w-1/3 w-1/2">
              <h5>Free trial period (days)</h5>
            </div>
            <div className="w-1/3 ">
              <input
                type="number"
                id="freeDays"
                name="days"
                onChange={handlerChange}
                value={formData.days}
                className="p-2 bg-gray-100 w-20 rounded-sm border"
              />
            </div>
          </div>
          
          
          <div className="w-full mt-8">
            <div className="form-group pt-4 pb-3 flex  ">
            <PrimaryButton Btnclass="btn save mr-2" id="planSave" onClick={(e) => handlerSave(e)}  btnText="Save" />
            </div>
          </div>
          
        </div>
        </>
      )

    }
   </>
  );
};

export default PlanSetting;
