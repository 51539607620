import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  overTimeData: [],
  overtimeDataList: [],
  timeBackData: []
};

export const overTimeListSlice = createSlice({
  name: "overTimeListSlice",
  initialState,
  reducers: {
    overTimeDataAction: (state, action) => {
    state.overTimeData = action.payload;
    },
    overTimeTypeDataAction: (state, action) => {
      state.overtimeDataList = action.payload;
      },
      timeBackDataAction: (state, action) => {
        state.timeBackData = action.payload;
        }
}
});

export const { overTimeDataAction, overTimeTypeDataAction, timeBackDataAction } = overTimeListSlice.actions;
export default overTimeListSlice.reducer;