import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ModifiedToast } from './toaster';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FormInput } from "./formInput";
import PrimaryButton from "./primaryButton";
import { useLoginUserMutation } from "../services/authApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { emailValidator, passwordValidator } from "../pages/settings/validation";
import { openJoyce } from '../redux/JoyiceSlice/joyiceSlice';
import { userInfo, allUserPermissions, showApprovalsTab } from '../redux/AuthSlice/authSlice';
import { getTokens } from '../firebase';
import backgroundImage from '../loginBg.webp';


const PartnerLogin = () => {
  const [ loginUser, { data, isLoading, isError, isSuccess, error: apiError } ] = useLoginUserMutation();
  const [ isSubmitDisabled, setIsSubmitDisabled ] = useState(false);
  const dispatch = useDispatch();
  const [ passwordShown, setPasswordShown ] = useState(false);
  const [ fcmToken, setFcmToken ] = useState(false);
  const [ formData, setFormData ] = useState({
    email: '',
    password: '',
    remember_me: 0,
    fcm_token:''
  });
  const [ error, setError ] = useState({
    emailError: '',
    passwordError: ''
  });
  // const [  setLoading ] = useState(false);
  getTokens(setFcmToken);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const navigate = useNavigate();
  // const userLoginSuccess = () => {
  //   // 

  // };
  const toastId = useRef(null);

    const getCreatePermissionStatus = () => {
      const billingCreatePermission = data?.permissions?.find(
        (module) => module?.module_name === 'Billing' && module?.module_permission_name === 'Create'
      );
  
      return billingCreatePermission?.status;
    };
  
    const createPermissionStatus = getCreatePermissionStatus();

    useEffect(() => {
      if(data?.current_active_plan === null){
        toast.dismiss(toastId.current);
        toast.success("Plan Expired", {
          render: "Plan Expired",
          type: "error",
          autoClose: 10000,
          isLoading: false,
          theme: "colored"
        });
      }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ]);


  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
        // position: "top-center",
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        autoClose: 2000,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccess) {
      setIsSubmitDisabled(false);
      toast.dismiss(toastId.current);
      //  const id= toastId.current;
      setTimeout(() => {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(userInfo(data?.data));
        dispatch(showApprovalsTab(data?.showApprovalsTab));
        if (data?.permissions) {
          dispatch(allUserPermissions(data?.permissions));
        }
        localStorage.setItem("employeeData", JSON.stringify(data?.data));
        if(data?.tour_completed === "0" && data?.data?.roleId === 2){
          dispatch(openJoyce({ isJoyce: true, isSignup: true, data: data }));
        }
        else{
          dispatch(openJoyce({ isJoyce: false, isSignup: false, data: data }));
        }
        
        if(data?.current_active_plan === null && data?.user_role_id === 2){
          navigate('setting/billing');
        }
        else if(data?.current_active_plan === null  && data?.user_role_id === 4 && createPermissionStatus === true){
          navigate('setting/billing');
        }
        else if(data?.current_active_plan === null  && data?.user_role_id === 4 && createPermissionStatus === false){
          navigate('/billing');
        }
        else if(data?.current_active_plan === null  && data?.user_role_id === 3 && createPermissionStatus === true){
          navigate('setting/billing');
        }
        else if(data?.current_active_plan === null  && data?.user_role_id === 3 && createPermissionStatus === false){
          navigate('/billing');
        }
        else if(data?.user_role_id === 1){
          navigate('/dashboard');
        }
        else{
          if(data?.forcePasswordChange === true){
            navigate('/reset-password-at-first-login');
          }else{
          navigate('/dashboard');
          }
        }
        
        toast.success("User logged in successfully.", {
          render: "User logged in successfully.",
          type: "success",
          autoClose: 8000,
          isLoading: false
        });
      }, 210);
    }
    if (isError) {
      setIsSubmitDisabled(false);
      toast.dismiss(toastId.current);
      const toastMessage = apiError?.data?.message
        ? apiError?.data?.message
        : "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 8000,
        isLoading: false,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: true
      });
      //    toast.update(
      //   toastId.current,
      //   (toastMessage,
      //   {
      //     render: toastMessage,
      //     type: "error",
      //     autoClose: 2000,
      //     isLoading: false
      //   })
      // );

    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);


  const handlerChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    if (name === 'email') {
      let email = emailValidator(e.target.value);
      setError({ ...error, emailError: email });
    } else if (name === 'password') {
      let password = passwordValidator(e.target.value);
      setError({ ...error, passwordError: password });
    }
    setFormData({ ...formData, [name]: fieldValue });
  };

  const validaForm = () => {
    const { email, password } = formData;
    let isFormValidated = true;

    if (email === '') {

      setError((errorValue) => {
        return {
          ...errorValue,
          emailError: 'Please enter Email'
        };
      });
      isFormValidated = false;
    }
    if (password === '') {
      setError((errorValue) => {
        return {
          ...errorValue,
          passwordError: 'Please enter password'
        };
      });
      isFormValidated = false;

    }
    if (password.length < 8) {
      setError((errorValue) => {
        return {
          ...errorValue,
          passwordError: 'Password must be at least 8 characters long'
        };
      });
      isFormValidated = false;

    }
    return isFormValidated;
  };

  const handleFormSubmit = async (e) => {
    setIsSubmitDisabled(true);
   let values ={
    formData : formData,
     fcmToken: fcmToken
   };
    e.preventDefault();
    if (validaForm()) {
      loginUser(values);
     
    }
  };

  return (
    <div className="flex loginGradient w-full" style={{background: `url(${backgroundImage})`}}>
      <HelmetProvider>
        <Helmet>
          <title>Schedulize | Login </title>
        </Helmet>
      </HelmetProvider>
      {/* {loading ? <LoadingIndicator/> : ""} */}
      {/* <ModifiedToast /> */}
      {/* <ToastContainer /> */}
      <div className=" flex align-center w-full">
        <div className=" w-full  ">
          <div className="md:flex align-center justify-center h-full items-center flex-wrap w-full 	">
            <div className=" lg:p-8 p-4 lg:w-1/2 w-full relative">
              <div className=" w-full">
                <div className="w-full max-w-lg m-auto bg-white lg:p-8 p-4 border shadow-lg rounded-lg ">
                  <div className="text-center  w-full">
                    <img
                      src="../images/logo-full.webp"
                      width="220"
                      alt='logo'
                      className="mx-auto"
                    />
                  </div>
                  <h1 className="text-xl font-semibold my-6 mb-8 text-center">
                    Log in to your account
                  </h1>
                  <form
                    className="text-left"
                    id="loginForm"
                  >
                    <div className="parentGroup mb-5">
                      <label htmlFor="email" className="formBlock">
                        {" "}
                        Email
                      </label>
                      <FormInput
                        inputType="text"
                        inputName="email"
                        inputID="email"
                        value={formData.email}
                        handlerChange={handlerChange}

                      />
                      <div className="help-block text-red-700">{error.emailError ? error.emailError : ''}</div>
                    </div>
                    <div className="parentGroup mb-2 eyeIcon">
                      <label htmlFor="password" className="formBlock">
                        Password
                      </label>
                      <FormInput
                        inputType={passwordShown ? "text" : "password"}
                        inputName="password"
                        inputID="password"
                        value={formData.password}
                        handlerChange={handlerChange}
                      />

                      <i className={
                        passwordShown
                          ? "fa fa-eye cursor-pointer"
                          : "fa fa-eye-slash cursor-pointer"
                      } onClick={togglePasswordVisiblity}
                      />

                    </div>
                    <div className="help-block text-red-700">{error.passwordError ? error.passwordError : ''}</div>
                    <p className="primary-text text-right mb-4">
                      <Link to="/forgot-password" className="font-medium">
                        Forgot password?
                      </Link>
                    </p>
                    <div className="form-group mb-4 ">
                      <label className="formBlock flex	">
                        <input type="checkbox" name="remember_me" onClick={handlerChange} className="mr-2 w-5 h-5" />
                        Remember me
                      </label>
                    </div>

                    <div className=" mt-8 text-center">
                      {/* <Link to="/dashboard"> */}
                      <PrimaryButton
                        btnText="Login"
                        buttonID="login"
                        Btnclass={`btn save w-full mb-6 ${isSubmitDisabled ? 'disabled' : ''}`}
                        onClick={(e) => handleFormSubmit(e)}
                        disabled={isSubmitDisabled} // Disable the button based on state
                      />
                      {/* />{" "} */}
                      {/* </Link> */}
                      <p className="text-gray-600 font-medium">
                        Login as auditor:{" "}
                        <Link
                          to="/auditor"
                          className="primary-text font-semibold"
                        >
                          External User Login
                        </Link>
                      </p>
                      <p className="text-gray-600 font-medium">
                        Not a Member yet?{" "}
                        <Link
                          to="/signup"
                          className="primary-text font-semibold"
                        >
                          Signup
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
           {/*  <div className=" md:w-1/2 md:h-full flex-wrap  md:flex hidden bg-white justify-center relative items-center  ">
              <img src="./images/login.webp" className="w-full loginImg" alt='user-image'/>
            </div>*/}
          </div>
        </div>
      </div>
      <ModifiedToast />
    </div>
  );
};

export default PartnerLogin;
