

const NoDataFoundLeave = () => {
  return (
    <div>
      <span className="ml-[210%] text-red-700">Data Not found</span>
    </div>
  );
};

export default NoDataFoundLeave;
