/* eslint-disable camelcase */
import {useState, useRef, useEffect} from "react";
import {toast} from "react-toastify";
import {Link, useSearchParams, useParams} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Popup from "reactjs-popup";
import {
  useGetNextPrevMonthMutation,
  useGetNextPrevMonthAllListMutation
} from "../../services/leaveApi";
import {useGetDepartmentListMutation} from "../../services/approverListApi";
import {
  useGetEmployeeAllowanceQuery,
  useGetEmployeeMutation
} from "../../services/employeeApi";
import {useGetLeaveTypeListMutation} from "../../services/leaveTypesApi";
import {useFilterEmployeeDepartmentMutation} from "../../services/employeeApi";
import AddLeave from "./AddLeave";
import Pagination from "../../components/inc/pagination";
import {FormTextarea} from "../../components/formInput";
import {
  getTodayYear,
  daysInMonth,
  getCurrentMonthLeaveDate,
  getWeekDay
} from "../../helper/LeaveHelper";
import moments from "moment";
import {CSVLink} from "react-csv";
import {useChangesStatusApporveMutation} from "../../services/approverListApi";
import {Tooltip} from "react-tooltip";
import {LoadingIndicator} from "../../components/loading-indicator";
import {useGetEmployeeAllDaysScheduleMutation} from "../../services/employeeApi";
import Dropdown from "./dropdown";
import {useDispatch, useSelector} from "react-redux";
import {
  REACT_APP_COMPANY_ADMIN_ROLE_ID,
  REACT_APP_MANAGER_ROLE_ID,
  REACT_APP_EMPLOYEE_ROLE_ID,
  REACT_APP_AUDITOR_ROLE_ID
} from "../../helper/constant";
import {useGetLocationBankHolidayMutation} from "../../services/holidayApi";
import PrimaryButton from "../../components/primaryButton";
import moment from "moment";
import {
  employeeInfo,
  employeeScheduleInfoAction,
  filterCalenderEmployeeDataAction
} from "../../redux/EmployeeSlice/employeeSlice";
import {departmentListAction} from "../../redux/DepartmentSlice/departmentSlice";
import {
  allLeaveDataAction,
  monthLeaveAllListAction,
  monthLeaveDataAction
} from "../../redux/LeaveTypeSlice/leaveSlice";
import {getWeekdayOccurrencesInMonth} from "../../helper/helper";

const Leave = () => {
  //Getting Data Form Redux --
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const employeeListData = useSelector(
    (state) => state?.persistedReducer?.employeeSlice?.employee
  );
  const getEmployeeAllDayScheduleRespo = useSelector(
    (state) => state?.persistedReducer?.employeeSlice?.employeeScheduleInfo
  );
  const departmentApiData = useSelector(
    (state) => state?.persistedReducer?.departmentSlice?.allDepartmentList
  );
  const employeeIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id
  );
  const loggedInUserData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user
  );
  const userId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id
  );
  const hidingDepartment = useSelector(
    (state) =>
      state?.persistedReducer?.userPermissionsStates?.companies?.department
  );
  const roleIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  const employeeDepartmentId = useSelector(
    (state) =>
      state?.persistedReducer?.userPermissionsStates?.user?.department_id
  );
  const hidingLeaveType = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.companies?.leave
  );
  const allLeaveApiResponse = useSelector(
    (state) => state?.persistedReducer?.leaveTypeSlice?.allLeaveData
  );
  const getNextPrevResp = useSelector(
    (state) => state?.persistedReducer?.leaveTypeSlice?.monthLeaveData
  );
  const getNextPrevAllListResp = useSelector(
    (state) => state?.persistedReducer?.leaveTypeSlice?.monthAllLeaveData
  );
  const filterEmployee = useSelector(
    (state) =>
      state?.persistedReducer?.employeeSlice?.filterCalenderEmployeeData
  );
  //
  const dispatch = useDispatch();
  const {id} = useParams();
  const ignore = useRef(false);
  // Define States for Component
  const [searchParams] = useSearchParams();
  const pageTerm = searchParams.get("page") || "";
  const [allEmployeeData, setAllEmployeeData] = useState();
  const [allLeaveType, setAllLeaveType] = useState();
  const [open, setOpen] = useState(false);
  const [departmentId, setDepartmentId] = useState("0");
  const [leaveDeduct, setLeaveDeduct] = useState();
  const [leaveNonDeduct, setLeaveNonDeduct] = useState();
  const [allDepartmentListData, setAllDepartmentListData] = useState();
  const [hoveredDate, setHoveredDate] = useState(null);
  const [openLeave, setOpenLeave] = useState(false);
  const [page, setPage] = useState(pageTerm ? pageTerm : 1);
  const [pages, setPages] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [selected, setSelected] = useState("Select Department");
  const [monthData, setMonthData] = useState(moments(new Date()).format("MM"));
  const [yearData, setYearData] = useState(moments(new Date()).format("YYYY"));
  const [skip, setSkip] = useState(true);
  const [employeeId, setEmployeeId] = useState(true);

  // Calling Apis and Mutations
  // eslint-disable-next-line
  const {data} = useGetEmployeeAllowanceQuery(employeeId, {
    skip: skip
  });
  const [
    getEmployeeAllDaysSchedule,
    {data: EmployeeAllDayScheduleRespo, isSuccess: isSuccessSchedule}
  ] = useGetEmployeeAllDaysScheduleMutation();
  const [
    getDepartmentList,
    {data: departmentApiResponse, isSuccess: isSuccessDepartmentApi}
  ] = useGetDepartmentListMutation();
  const [
    changesStatusApporve,
    {isLoading: approveLoading, isSuccess, isError, error: apiError}
  ] = useChangesStatusApporveMutation();
  const [{data: locationBankHoliday}] = useGetLocationBankHolidayMutation();
  const [
    getEmployee,
    {data: employeeListAllData, isSuccess: isEmployeeAPISuccess}
  ] = useGetEmployeeMutation();
  // const [ getEmployeeAllowance ] = useGetEmployeeAllowanceMutation();
  const [
    getLeaveTypeList,
    {data: allLeaveApiResponseList, isSuccess: isSuccessLeave}
  ] = useGetLeaveTypeListMutation();
  const [filterEmployeeDepartment] = useFilterEmployeeDepartmentMutation();
  const [
    getNextPrevMonth,
    {data: getNextPrevMonthData, isSuccess: isSuccessNextPrev}
  ] = useGetNextPrevMonthMutation();
  const [
    getNextPrevMonthAllList,
    {data: getNextPrevAllListData, isSuccess: isSuccessNextPrevAllList}
  ] = useGetNextPrevMonthAllListMutation();

  const createCsvFileName = () => `Leave_report_${moments().format()}.csv`;
  const shouldShowDepartment = roleIdData === 4 && hidingDepartment === "1";
  const shouldShowLeaveType = roleIdData === 4 && hidingLeaveType === "1";

  const closeModal = () => {
    setOpen(false);
    setOpenLeave(false);
  };
  useEffect(
    () => {
      if (isSuccessSchedule) {
        dispatch(employeeScheduleInfoAction(EmployeeAllDayScheduleRespo));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessSchedule]
  );

  useEffect(
    () => {
      if (!ignore.current) {
        getDepartmentList();
        getLeaveTypeList();
        if (employeeIdData) {
          setEmployeeId(employeeIdData);
          setSkip(false);
        }
        // getEmployeeAllowance(employeeIdData);
        if (id) {
          getEmployeeAllDaysSchedule(id);
        } else {
          if (auditorRoleId === 5) {
            return;
          }
          getEmployeeAllDaysSchedule(employeeIdData);
        }
      }
      return () => {
        ignore.current = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (shouldShowDepartment === true) {
        let payload = `/employee?page=${page}&status=${1}&department_id=${employeeDepartmentId}`;
        getEmployee(payload);
      } else {
        let payload = `/employee?page=${page}&status=${1}`;
        getEmployee(payload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useEffect(
    () => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessDepartmentApi]
  );

  useEffect(
    () => {
      let arr = [];
      if (departmentApiData) {
        departmentApiData?.data?.length > 0 &&
          departmentApiData?.data?.forEach((dept) => {
            if (
              loggedInUserData?.roleId == REACT_APP_MANAGER_ROLE_ID &&
              loggedInUserData?.department_id &&
              dept?.id == loggedInUserData?.department_id
            ) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr?.push(deptOption);
            }
            if (loggedInUserData?.roleId == REACT_APP_COMPANY_ADMIN_ROLE_ID) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr?.push(deptOption);
            }
            if (
              loggedInUserData?.roleId == REACT_APP_EMPLOYEE_ROLE_ID &&
              loggedInUserData?.department_id
            ) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr?.push(deptOption);
            }
          });
        setAllDepartmentListData(arr);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedInUserData, departmentApiData]
  );

  useEffect(
    () => {
      let arr = [];
      if (employeeListData) {
        if (
          employeeListData?.data?.length < 1 &&
          employeeListData?.currentPage > 1
        ) {
          filterEmployeeDepartment(employeeListData?.currentPage - 1);
        }
        employeeListData?.data?.length > 0 &&
          employeeListData?.data?.forEach((emp) => {
            let empOption = {
              value: emp?.id,
              label: emp?.first_name
            };
            arr?.push(empOption);
          });
        setAllEmployeeData(arr);
        if (employeeListData?.data) {
          dispatch(filterCalenderEmployeeDataAction(employeeListData?.data));
        }
        if (
          employeeListData?.data?.length < 1 &&
          employeeListData?.currentPage > 1
        ) {
          getEmployee(employeeListData?.currentPage - 1);
        }
        setPages(
          employeeListData?.totalPages ? employeeListData?.totalPages : 1
        );
        if (employeeListData?.data) {
          let csvData = exportTOCSV(employeeListData?.data);
          setExcelData(csvData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employeeListData]
  );
  useEffect(
    () => {
      if (isEmployeeAPISuccess) {
        dispatch(employeeInfo(employeeListAllData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEmployeeAPISuccess]
  );

  const decrementPage = () => {
    getEmployee(employeeListData?.currentPage - 1);
    setPage(employeeListData?.currentPage - 1);
  };

  let headers = [
    {label: "Name", key: "Name"},
    {label: "DepartmentName", key: "dept_name"},
    {label: "Start Date", key: "start_date"},
    {label: "Start Time", key: "start_time"},
    {label: "End Date", key: "end_date"},
    {label: "End Time", key: "end_time"},
    {label: "Duration(Days/Hours)", key: "total_duration"},
    {label: "Status", key: "status"},
    {label: "Type", key: "type"},
    {label: "AppliedBy", key: "applied_by"},
    {label: "Approved By", key: "approved_by"},
    {label: "Approved Date", key: "approved_date"},
    {label: "Reason", key: "reason"},
    {label: "Notes", key: "notes"},
    {label: "Cancel Reason", key: "cancel_reason"},
    {label: "CreatedAt", key: "createdAt"},
    {label: "UpdatedAt", key: "updatedAt"},
    {label: "DeletedAt", key: "deletedAt"}
  ];

  const exportTOCSV = (employee) => {
    let dataAbence = [];
    if (employee) {
      employee?.forEach((emp) => {
        emp.leaveInfo?.forEach((dExcel) => {
          let hour = parseInt(dExcel.total_duration) / 60;
          let days = hour / 8;
          let val = {
            Name: emp?.first_name + " " + emp.last_name,
            dept_name: emp?.departmentInfo?.department_name,
            start_date: moments(dExcel?.start_date)?.format("DD-MM-YYYY"),
            start_time: dExcel?.start_time,
            end_date: moments(dExcel?.end_date)?.format("DD-MM-YYYY"),
            end_time: dExcel?.end_time,
            total_duration: days,
            status:
              dExcel?.status === "1"
                ? "Apporved"
                : dExcel?.status === "0"
                ? "Applied"
                : "Cancel",
            type:
              dExcel.type === "3"
                ? "My leave"
                : dExcel.type === "1"
                ? "Leave"
                : "",
            applied_by: dExcel?.addedByInfo?.first_name,
            approved_by: dExcel?.approvedByInfo?.first_name,
            approved_date:
              dExcel?.approved_date !== null || dExcel?.approved_date === ""
                ? moments(dExcel?.approved_date).format("DD-MM-YYYY")
                : "",
            notes: dExcel?.notes,
            reason: dExcel.reason,
            cancel_reason: dExcel?.cancel_reason,
            createdAt: moments(dExcel?.createdAt).format("DD-MM-YYYY"),
            updatedAt: moments(dExcel?.updatedAt).format("DD-MM-YYYY"),
            deletedAt: dExcel?.deletedAt
              ? moments(dExcel?.deletedAt).format("DD-MM-YYYY")
              : "null"
          };
          dataAbence.push(val);
        });
      });
      return dataAbence;
    }
  };

  let deduct = [];
  useEffect(
    () => {
      if (isSuccessLeave) {
        dispatch(allLeaveDataAction(allLeaveApiResponseList));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessLeave]
  );
  /*Create Deduct and nondeduct Data*/
  useEffect(
    () => {
      if (allLeaveApiResponse) {
        let nonDeduct = [];
        let allLeaveData = [];
        deduct?.push({
          id: "",
          leave_name: "Deductible",
          icon: "Nondeductible"
        });
        allLeaveApiResponse?.leaveType?.rows?.forEach((leave) => {
          if (leave?.is_deductible === "1" && leave?.status === "1") {
            let value = {
              id: leave.id,
              leave_name: leave?.leave_name,
              icon: leave.icon
            };
            deduct?.push(value);
          }
        });
        deduct?.push({
          id: "",
          leave_name: "Non-Deductible ",
          icon: "Nondeductible"
        });
        allLeaveApiResponse?.leaveType?.rows?.forEach((leave) => {
          if (leave?.is_deductible === "0" && leave?.status === "1") {
            let value = {
              id: leave?.id,
              leave_name: leave?.leave_name,
              icon: leave?.icon,
              is_deductible: leave?.is_deductible == 0 ? 0 : 1
            };
            deduct?.push(value);
          }
        });
        setLeaveDeduct(deduct);
        setLeaveNonDeduct(nonDeduct);
        setAllLeaveType(allLeaveData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allLeaveApiResponse]
  );
  useEffect(
    () => {
      if (isSuccessNextPrevAllList) {
        dispatch(monthLeaveAllListAction(getNextPrevAllListData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessNextPrevAllList]
  );

  useEffect(
    () => {
      if (getNextPrevAllListResp) {
        if (getNextPrevAllListResp?.data?.length > 0) {
          dispatch(
            filterCalenderEmployeeDataAction(getNextPrevAllListResp?.data)
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNextPrevAllListResp]
  );
  useEffect(
    () => {
      if (isSuccessNextPrev) {
        dispatch(monthLeaveDataAction(getNextPrevMonthData));
      }
    },
    // eslint-disable-next-line
    [isSuccessNextPrev]
  );
  useEffect(
    () => {
      if (getNextPrevResp) {
        if (getNextPrevResp?.data?.length > 0) {
          dispatch(filterCalenderEmployeeDataAction(getNextPrevResp?.data));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNextPrevResp]
  );

  var days = ["S", "M", "T", "W", "T", "F", "S"];
  const monthNames = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December"
  };

  let year = getTodayYear();
  let data1 = "";
  const handlerPrev = (e) => {
    let currentYear;
    let currentMonth1 = "";
    e.preventDefault();
    if (monthData > 1) {
      currentMonth1 = moments(monthData).subtract({months: 1}).format("MM");
      setMonthData(currentMonth1);
      currentYear = moments(yearData).format("YYYY");
    } else {
      currentMonth1 = moments().month("December").format("MM");
      setMonthData(currentMonth1);

      currentYear = moments(yearData).subtract({year: 1}).format("YYYY");
      setYearData(currentYear);
    }
    if (departmentId === "0") {
      getNextPrevMonthAllList({
        start_date: `${currentYear}-${currentMonth1}`,
        page,
        departmentId
      });
    } else {
      getNextPrevMonth({
        start_date: `${currentYear}-${currentMonth1}`,
        departmentId,
        page
      });
    }
  };

  const handlerNext = (e) => {
    e.preventDefault();
    // let payload= `/employee?page=${1}&status=${'1'}&limit=100` ;
    // getEmployee(payload);

    let currentMonth1 = "";
    let currentYear = "";
    if (monthData < 12) {
      currentMonth1 = moments(monthData).add({months: 1}).format("MM");
      currentYear = moments(yearData).format("YYYY");
      setMonthData(currentMonth1);
    } else {
      currentMonth1 = moments(monthData).add({months: 1}).format("MM");
      setMonthData(currentMonth1);
      currentYear = moments(yearData).add({year: 1}).format("YYYY");
      setYearData(currentYear);
    }
    if (departmentId === "0") {
      getNextPrevMonthAllList({
        start_date: `${currentYear}-${currentMonth1}`,
        page,
        departmentId
      });
    } else {
      getNextPrevMonth({
        start_date: `${currentYear}-${currentMonth1}`,
        departmentId,
        page
      });
    }
    // let payload= `/employee?page=${1}&status=${'1'}&department_id=${selectedId ? selectedId : departmentId}&limit=100` ;
    // getEmployee(payload);
  };

  var totalDays = daysInMonth(monthData, year);
  let array = [];
  let weekend = [];

  for (let i = 1; i <= totalDays; i++) {
    array.push(i);
    let newDate = new Date(yearData, monthData - 1, i);
    if (newDate.getDay() == 0 || newDate.getDay() == 6) {
      weekend.push(`${i}`);
    }
  }

  const toastId = useRef(null);
  useEffect(
    () => {
      if (approveLoading) {
        toast.dismiss(toastId.current);
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info",
          autoClose: 5000
        });
      }
      if (isSuccess) {
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);
        toast.update(
          toastId.current,
          ("Leave Cancelled Successfully.",
          {
            render: "Leave Cancelled Successfully.",
            type: "success",
            autoClose: 10000,
            isLoading: false
          })
        );
        // close();
        let payload = `/employee?page=${page}&status=${1}`;
        getEmployee(payload);
      }
      if (isError) {
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 2000,
            isLoading: false,
            duplicate: false
            //  position: toastId.POSITION.TOP_CENTER
            //  zIndex: 99999
            //  <div style={z-index:"9999"}/>
          })
        );
        setOpenLeave(false);
        //close();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, isError, approveLoading]
  );

  const cancelEvent = (e, formData, close, setError, error, employeeId) => {
    e.preventDefault();
    if (formData.notes !== "") {
      setError({...error, ["notesError"]: ""});
      let val = {
        status: "2",
        cancel_reason: formData.notes,
        employee_id: `${employeeId}`,
        id: formData?.id
      };
      changesStatusApporve(val);

      setOpenLeave(false);
      setOpen(close);
      //;
    } else {
      setError({...error, ["notesError"]: "please enter the notes"});
    }
  };

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Leave Calendar</title>
          </Helmet>
        </HelmetProvider>
        {employeeListData?.data?.length > 0 ? (
          <div>
            <div className="py-6">
              <div className="container mx-auto leaveCalendar">
                <h2 className="text-xl mb-3 font-semibold  ">Leave Calendar</h2>
                <div className="card bg-white  mb-3 ">
                  <div className="heading_title px-4 flex justify-end items-center py-4">
                    <div className="headerButton">
                      <CSVLink
                        className=""
                        data={excelData}
                        headers={headers}
                        filename={createCsvFileName()}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          outline: "none",
                          height: "5vh"
                        }}
                      >
                        <button className="btn-md btn-add mr-2" id="exportCsv">
                          <span className="fa fa-file-export mr-2" /> Export to
                          CSV
                        </button>
                      </CSVLink>
                      {roleIdData !== REACT_APP_EMPLOYEE_ROLE_ID && roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          className="leaveCalendarAdd"
                          closeOnDocumentClick={false}
                          trigger={
                            <button
                              className="btn-md btn-add "
                              id="requestLeaveOpen"
                              aria-describedby="popup-8"
                              onClick={() => setOpen((o) => !o)}
                            >
                              <span className="fa fa-plus mr-2" />
                              Add Leave
                            </button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                id="requestLeaveClose"
                                className="close"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <AddLeave
                                allDepartmentListData={allDepartmentListData}
                                allEmployeeData={allEmployeeData}
                                allLeaveType={allLeaveType}
                                close={close}
                                leaveNonDeduct={leaveNonDeduct}
                                leaveDeduct={leaveDeduct}
                                getEmployee={getEmployee}
                              />
                            </div>
                          )}
                        </Popup>
                      )}
                    </div>
                  </div>
                  <div className="md:p-5 p-0">
                    <div className="flex mb-2">
                      <div className="w-1/5" />
                      <div className="w-4/5" />
                    </div>
                  </div>

                  <div className="flex mb-6 flex-wrap md:flex-nowrap">
                    <div className="md:w-1/3 lg:w-1/5 pl-4 w-full">
                      <div className="calendarLeft md:mt-12 md:pr-10 pr-4">
                        <div className="mb-4">
                          <label className="formBlock mt-2 ml-1">
                            {shouldShowDepartment ? null : "Select Department"}
                          </label>
                          <Dropdown
                            selected={selected}
                            page={page}
                            setSelected={setSelected}
                            getEmployee={getEmployee}
                            departmentId={departmentId}
                            setDepartmentId={setDepartmentId}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:w-2/3 lg:w-4/5 pr-3 md:pl-0 pl-4 w-full">
                      <div className="flex items-center mb-8">
                        <div className="mr-6">
                          <button
                            onClick={(e) => handlerPrev(e)}
                            id="prevMonth"
                          >
                            <span className="fa fa-arrow-left text-xl mr-4 primary-text" />
                          </button>
                        </div>
                        <button onClick={(e) => handlerNext(e)} id="nextMonth">
                          <span className="fa fa-arrow-right text-xl mr-4 primary-text" />
                        </button>
                        <span className="text-xl mr-4 ">
                          {monthNames[monthData]} {yearData}
                        </span>
                      </div>
                      <div className="md:pl-0 pl-14">
                        <div className="overflow-x-auto w-full whitespace-nowrap calendarTable ">
                          <table className="table-auto   w-full">
                            <thead className="thead-dark ">
                              <tr>
                                {[...Array(totalDays)]?.map((m, index) => {
                                  return (
                                    <th key={index}>
                                      {
                                        days[
                                          getWeekDay(
                                            `${monthData}-${
                                              index + 1
                                            }-${yearData}`
                                          )
                                        ]
                                      }
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {filterEmployee?.length > 0 ? (
                    filterEmployee &&
                    filterEmployee?.map((employee, index) => {
                      // Array to store all weekend dates
                      let allWeekendDates = [];
                      employee?.workScheduleInfo?.forEach((day) => {
                        // Check if weekend is 1
                        if (day?.is_weekend === 1) {
                          // Get all occurrences of that day in the current month
                          const dates = getWeekdayOccurrencesInMonth(
                            parseInt(day?.day),
                            monthData,
                            yearData
                          ); // Adjust day index
                          allWeekendDates = allWeekendDates.concat(dates);
                          // Output the array of dates
                        }
                        allWeekendDates.sort(
                          (a, b) => parseInt(a) - parseInt(b)
                        );
                      });
                      const [
                        leaveArray,
                        leaveColor,
                        leaveIcon,
                        bookingDate,
                        addedByinfo,
                        leaveId,
                        reason,
                        duration,
                        startTime,
                        endTime,
                        leaveTypeName,
                        leaveAngle,
                        leaveHalfDay
                      ] = getCurrentMonthLeaveDate(
                        monthData,
                        employee.leaveInfo,
                        employee?.id,
                        roleIdData,
                        employeeIdData,
                        yearData,
                        employee?.dob,
                        employee?.employment_start_date,
                        employee?.locationInfo?.holidayInfo,
                        weekend
                      );
                      return (
                        <>
                          <div className="flex pb-10" key={index}>
                            <div className="md:w-1/3 w-1/5 lg:w-1/5 pl-4">
                              <div className="calendarLeft">
                                {roleIdData ===
                                  REACT_APP_COMPANY_ADMIN_ROLE_ID ||
                                userId === employee?.leave_approver ? (
                                  <Link to={`/my-leave/${employee?.id}`}>
                                    <div className="flex ">
                                      <div className="listImage flex items-center  mb-2">
                                        <img
                                          src={
                                            employee.profile_image
                                              ? employee.profile_image
                                              : "/images/no-image1.jpg"
                                          }
                                          className="ml-2 mt-2"
                                        />
                                        <span className="bellCount">
                                          {employee?.remaining_leave_this_year?.toFixed(
                                            1
                                          )}
                                        </span>
                                      </div>
                                      <div>
                                        <p className=" font-semibold">
                                          {employee?.first_name}
                                          <span> </span>
                                          {employee?.last_name}
                                        </p>
                                        <p>
                                          {employee?.rolesInfo[0]?.roleType
                                            ?.id === 2
                                            ? "Company Admin/HR"
                                            : "Employee"}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                ) : (
                                  <div className="flex ">
                                    <div className="listImage flex items-center  mb-2">
                                      <img
                                        src={
                                          employee.profile_image
                                            ? employee.profile_image
                                            : "/images/no-image1.jpg"
                                        }
                                        className="ml-2 mt-2"
                                      />
                                      <span className="bellCount">
                                        {employee?.remaining_leave_this_year?.toFixed(
                                          1
                                        )}
                                      </span>
                                    </div>
                                    <div>
                                      <p className=" font-semibold">
                                        {employee?.first_name}
                                        <span> </span>
                                        {employee?.last_name}
                                      </p>
                                      <p>
                                        {employee?.rolesInfo[0]?.roleType
                                          ?.id === 2
                                          ? "Company Admin/HR"
                                          : "Employee"}{" "}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="md:w-2/3 w-4/5 lg:w-4/5  pr-3">
                              <div className="overflow-x-auto w-full whitespace-nowrap calendarTable">
                                <table className="table-auto   w-full">
                                  <tbody>
                                    <tr>
                                      {array.map((m, index) => {
                                        m = parseInt(m) <= 9 ? `0${m}` : m;
                                        const currentDate = `${yearData}-${monthData}-${m}`;
                                        const currentDateEmployement = `${yearData}-${monthData}-${m}`;
                                        const filtered_date =
                                          locationBankHoliday?.data?.filter(
                                            (date) =>
                                              moment(date?.start_date).format(
                                                "MM"
                                              ) === monthData
                                          );
                                        const isEmploymentStartDate =
                                          currentDateEmployement ===
                                          moment(
                                            employee?.employment_start_date
                                          ).format("YYYY-MM-DD");
                                        return (
                                          <td key={index}>
                                            {/* {isEmploymentStartDate && (
                                                <span className="text-white bhIcon fa-regular fa-calendar-days"/>
                                              )} */}
                                            {leaveArray.indexOf(m.toString()) >
                                              -1 ||
                                            leaveColor.indexOf(m.toString()) >
                                              -1 ||
                                            leaveIcon.indexOf(m.toString()) >
                                              -1 ||
                                            bookingDate.indexOf(m.toString()) >
                                              -1 ? (
                                              <>
                                                <Popup
                                                  open={openLeave}
                                                  onClose={() => closeModal()}
                                                  closeOnDocumentClick={false}
                                                  trigger={
                                                    <span
                                                      className={`cursor-pointer py-2 text-white block ${
                                                        hoveredDate ===
                                                        currentDate
                                                          ? m
                                                          : leaveIcon[m]
                                                      }`}
                                                      title={
                                                        shouldShowLeaveType
                                                          ? ""
                                                          : hoveredDate ===
                                                            currentDate
                                                          ? `${
                                                              leaveTypeName
                                                                ? leaveTypeName[
                                                                    m
                                                                  ]
                                                                : ""
                                                            }`
                                                          : ""
                                                      }
                                                      style={{
                                                        background:
                                                          hoveredDate ===
                                                          currentDate
                                                            ? "#e4e3e4"
                                                            : leaveHalfDay[m]
                                                            ? `linear-gradient(-45deg, ${
                                                                leaveColor[m]
                                                              } ${
                                                                leaveAngle[m] < 30 ? 30 : leaveAngle[m]
                                                              }%, ${
                                                                leaveColor[m]
                                                              } ${
                                                                leaveAngle[m] < 30 ? 30 : leaveAngle[m]
                                                              }%, ${"#ffffff"} ${
                                                               leaveAngle[m] < 30 ? 30 : leaveAngle[m]
                                                              }%, #ffffff ${
                                                                100 - leaveAngle[m] > 30  ? 70 : 100 - leaveAngle[m]
                                                              }%)`
                                                            : leaveColor[m],
                                                        color: leaveHalfDay[m]
                                                          ? 
                                                              leaveAngle[m] <
                                                            40
                                                            ? "black"
                                                            : "white"
                                                          : "#fff"
                                                      }}
                                                      onMouseEnter={() =>
                                                        setHoveredDate(
                                                          currentDate
                                                        )
                                                      }
                                                      onMouseLeave={() =>
                                                        setHoveredDate(null)
                                                      }
                                                    >
                                                      {hoveredDate ===
                                                      currentDate
                                                        ? m
                                                        : leaveIcon[m] ===
                                                          "bhIcon"
                                                        ? "BH"
                                                        : null}
                                                    </span>
                                                  }
                                                  modal
                                                  nested
                                                >
                                                  {(close) => (
                                                    <div className="modal">
                                                      <button
                                                        className="close"
                                                        id="leaveIconClose"
                                                        onClick={() => {
                                                          closeModal();
                                                          close();
                                                        }}
                                                      >
                                                        &times;
                                                      </button>

                                                      <Tooltip id="my-tooltip">
                                                        {m}
                                                      </Tooltip>

                                                      <LeaveInfo
                                                        leaveRange={leaveArray}
                                                        selectedDate={m}
                                                        employeeName={
                                                          employee?.first_name
                                                        }
                                                        bookingDate={
                                                          bookingDate[m]
                                                        }
                                                        month={monthData}
                                                        year={yearData}
                                                        addedByinfo={
                                                          addedByinfo[m]
                                                        }
                                                        data1={data1}
                                                        leaveInfo={
                                                          employee?.leaveInfo
                                                        }
                                                        locationId={
                                                          employee?.location_id
                                                        }
                                                        employeeId={employee.id}
                                                        setOpen={setOpen}
                                                        cancelEvent={
                                                          cancelEvent
                                                        }
                                                        leaveId={leaveId[m]}
                                                        reason={reason[m]}
                                                        duration={duration[m]}
                                                        startTime={startTime[m]}
                                                        endTime={endTime[m]}
                                                        setOpenLeave={
                                                          setOpenLeave
                                                        }
                                                        getEmployeeAllDayScheduleRespo={
                                                          getEmployeeAllDayScheduleRespo
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                </Popup>
                                              </>
                                            ) : (
                                              <>
                                                {allWeekendDates?.indexOf(
                                                  m.toString()
                                                ) > -1 ? (
                                                  <>
                                                    <span
                                                      className={`cursor-pointer py-2  text-white block`}
                                                      style={{
                                                        backgroundColor:
                                                          "#e4e3e4"
                                                      }}
                                                    >
                                                      {m}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    {filtered_date?.map(
                                                      (startDate) => {
                                                        const dayInteger =
                                                          parseInt(
                                                            moment(
                                                              startDate?.start_date
                                                            ).format("DD")
                                                          );

                                                        return (
                                                          <>
                                                            {dayInteger ===
                                                              m && (
                                                              <span className="text-white bhIcon">
                                                                BH
                                                              </span>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                    <div
                                                    // onMouseEnter={() =>
                                                    //   setIsHovered(true)
                                                    // }
                                                    // onMouseLeave={() =>
                                                    //   setIsHovered(false)
                                                    // }
                                                    >
                                                      {/* {isEmploymentStartDate && (
                                                        <>{isHovered ?  m && (<span className="font-medium text-white bg-[#ba4706] " title="Joining Date">
            {isHovered ? <span className="bg-[#ffa500] w-full" >{m}</span> : ''}
          </span>) : <span className="text-[#ffffff] bg-[#ffa500] fa-regular fa-smile"/>}</>
                                                      )} */}

                                                      {!isEmploymentStartDate && (
                                                        <span>{m}</span>
                                                      )}
                                                    </div>

                                                    {/* <span>{!isEmploymentStartDate && m}</span> */}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <LoadingIndicator />
                  )}
                  <Pagination
                    page={page}
                    pages={pages}
                    changePage={setPage}
                    decrementPage={decrementPage}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </>
  );
};

export default Leave;

const LeaveInfo = ({
  selectedDate,
  month,
  year,
  leaveInfo,
  employeeId,
  cancelEvent,
  leaveId
}) => {
  //Getting Data Form Redux --
  const formattedDate = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.dateFormats
  );
  const hidingLeaveType = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.companies?.leave
  );
  const hidingAbsenceReason = useSelector(
    (state) =>
      state?.persistedReducer?.userPermissionsStates?.companies?.calendar
  );
  const roleIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );

  // Define States for Component

  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [error, setError] = useState({
    notesError: ""
  });
  const [formData, setFormData] = useState({
    notes: "",
    id: leaveId
  });
  // let durationInHours = duration / 60;
  // let hourOrHours = "Hours";
  // if (durationInHours <= 1) {
  //   hourOrHours = "Hour";
  // }
  const handlerSave = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  //let leaveDuration = Math.floor(duration/(getEmployeeAllDayScheduleRespo?.dayHours)*60) === duration/(getEmployeeAllDayScheduleRespo?.dayHours)*60 ? duration/(getEmployeeAllDayScheduleRespo?.dayHours)*60 : (duration/(getEmployeeAllDayScheduleRespo?.dayHours)*60).toFixed(1);

  const closeModal = () => {
    setOpen(false);
  };

  const handlerChange = (e) => {
    e.preventDefault();
    if (e.target.Name === "notes") {
      if (e.target === "") {
        setError({...error, ["notesError"]: "Please enter the notes"});
      } else {
        setError({...error, ["notesError"]: ""});
      }
    }
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const closeModalcancel = () => {
    setOpenCancel(false);
  };
  let start_date = `${selectedDate}-${month}-${year}`;
  const leaveData = leaveInfo?.find(
    (f) => moments(f?.start_date).format("DD-MM-YYYY") === start_date
  );

  const shouldShowReason =
    roleIdData === 4 && hidingAbsenceReason === "1" && leaveData?.type === "2";
  const shouldShowLeaveType = roleIdData === 4 && hidingLeaveType === "1";

  return (
    <>
      <div className="header">
        <span>
          {leaveData?.leaveTypeInfo?.leave_name === undefined ? (
            <h3>Absence Details</h3>
          ) : (
            <h3>Leave Details</h3>
          )}
        </span>
      </div>

      <div className="stepIndicator">
        <ul>
          <li className="active">
            <span>1</span>Request
          </li>
          <li>
            <span>2</span>Details
          </li>
        </ul>
      </div>
      <div className="content">
        <form>
          <div className="flex flex-wrap ">
            {leaveData?.leaveTypeInfo?.leave_name === undefined ? null : (
              <>
                {shouldShowLeaveType ? null : (
                  <div className=" w-full ">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">
                        Type{" "}
                        <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <input
                        className="formControl"
                        value={leaveData?.leaveTypeInfo?.leave_name}
                        disabled
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            <div className=" w-full ">
              {leaveData?.type === "2" ? null : (
                <div className="mb-2">
                  <div className="flex">
                    <input
                      type="checkbox"
                      checked={leaveData?.is_private === "1"}
                      className="mr-2 w-5 h-5"
                      id="private"
                    />
                    <label className="formBlock">Private</label>
                  </div>
                </div>
              )}
              {shouldShowLeaveType ? null : (
                <>
                  {shouldShowReason ? null : (
                    <div className="w-full ">
                      <div className="form-group mb-4 ">
                        <label className="formBlock ">Reason (optional) </label>
                        <input
                          value={leaveData?.reason}
                          className="formControl"
                        />
                        <div className="help-block" />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="form-group mb-4 ">
                <div className="flex">
                  <div className="w-full">
                    <label className="formBlock">
                      Select Date
                      <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                    </label>
                    <input
                      className="formControl"
                      value={`${moment(leaveData?.start_date).format(
                        formattedDate
                      )}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w w-full  ">
              <div className="w-full">
                <div className="form-group pt-4 pb-3 flex justify-center ">
                  <PrimaryButton
                    btnText="Next"
                    Btnclass="btn save mr-2"
                    btnype="button"
                    buttonID="next"
                    onClick={(e) => handlerSave(e)}
                  />
                  {/* <PrimaryButton
            btnText="Back"
            onClick={() => close()}
            Btnclass="btn cancel"
            buttonID="leaveCancel"
            btntype="button"
          /> */}
                </div>
              </div>
            </div>
            <Popup
              open={open}
              onClose={() => closeModal()}
              closeOnDocumentClick={false}
              className="leavePopup"
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button
                    className="close"
                    id="requestLeaveClose"
                    onClick={() => {
                      closeModal();
                      close();
                    }}
                  >
                    &times;
                  </button>
                  <div
                    style={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      paddingBottom: "24px"
                    }}
                  >
                    <div className="stepIndicator">
                      <ul>
                        <li>
                          <span>1</span>Request
                        </li>
                        <li className="active">
                          <span>2</span>Details
                        </li>
                      </ul>
                    </div>
                    <div className="p-6">
                      <h3 className="text-lg primary-text mb-4">
                        {leaveData?.leaveTypeInfo?.leave_name === undefined
                          ? "Absence Details"
                          : "Leave Details"}
                      </h3>
                      <div>
                        <div>
                          <div className="w-full flex justify-between pb-4  md:flex-nowrap flex-wrap ">
                            <div className="w-1/3">
                              <h3 className="text-base text-left pt-1">
                                {moment(leaveData?.start_date).format(
                                  formattedDate
                                )}
                              </h3>
                            </div>
                            <div className=" md:w-2/3 w-full pt-2 md:pt-0 md:pl-4  ">
                              <div className="flex justify-between items-center w-full">
                                <input
                                  className="formControl"
                                  value={moment(
                                    leaveData?.start_time,
                                    "hh:mm:ss"
                                  ).format("HH:mm")}
                                />
                                <div>to</div>

                                <input
                                  className="formControl"
                                  value={moment(
                                    leaveData?.end_time,
                                    "hh:mm:ss"
                                  ).format("HH:mm")}
                                />
                              </div>
                              <div className="w-full mt-2">
                                <div className="timeDiv">
                                  <span className="fa fa-clock secondry-text" />
                                  <span>
                                    <span className="secondry-text">
                                      {Math.floor(
                                        leaveData?.total_duration / 60
                                      )}
                                    </span>{" "}
                                    Hours{" "}
                                    <span className="secondry-text pl-2 pr-1">
                                      {leaveData?.total_duration % 60}
                                    </span>{" "}
                                    Minutes
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-6 text-right pb-4">
                      {leaveData?.leaveTypeInfo?.leave_name === undefined ? (
                        <p className="text-lg">
                          Total Absence:{" "}
                          {Math.floor(leaveData?.total_duration / 60)} hours{" "}
                          {leaveData?.total_duration % 60} minutes
                        </p>
                      ) : (
                        <p className="text-lg">
                          Total: {Math.floor(leaveData?.total_duration / 60)}{" "}
                          hours {leaveData?.total_duration % 60} minutes
                        </p>
                      )}
                      {leaveData?.leaveTypeInfo?.leave_name ===
                      undefined ? null : (
                        <p className="ml-10">Annual Leave</p>
                      )}
                    </div>
                  </div>
                  <div className="lg:w w-full  ">
                    <div className="w-full mb-4">
                      <div className="form-group pt-4 pb-3 flex justify-center ">
                        <div className="w-full text-right mt-2 mr-[200px]">
                          <Popup
                            open={openCancel}
                            onClose={() => closeModalcancel()}
                            closeOnDocumentClick={false}
                            trigger={
                              <div onClick={() => setOpenCancel((o) => !o)}>
                                <button className="btn cancel ">
                                  Cancel Leave
                                </button>
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="cancelLeaveClose"
                                  onClick={() => {
                                    closeModalcancel();
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <div className="header">
                                  {" "}
                                  <h3>Cancel Leave</h3>
                                </div>
                                <div className="content">
                                  <form>
                                    <label className="formBlock	">
                                      Reason
                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                    </label>
                                    <FormTextarea
                                      textName="notes"
                                      id="reason"
                                      handlerChange={handlerChange}
                                    />
                                    <div className="help-block text-red-700 mt-1">
                                      {error.notesError ? error.notesError : ""}
                                    </div>
                                    <div className="w-full mt-8">
                                      <div className="form-group pt-4 pb-3 flex justify-center ">
                                        <button
                                          id="submit"
                                          className="btn save mr-2"
                                          onClick={(e) =>
                                            cancelEvent(
                                              e,
                                              formData,
                                              close,
                                              setError,
                                              error,
                                              employeeId
                                            )
                                          }
                                        >
                                          Submit
                                        </button>
                                        <button
                                          className="btn cancel mr-2"
                                          id="cancel"
                                          onClick={() => {
                                            close();
                                            setOpenCancel(false);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </Popup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </form>
      </div>
    </>
  );
};
