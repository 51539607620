/* eslint-disable */
import { useEffect, useState, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import { BarChart } from "../barChart";
import { useGetSuperAdminDashboardChartDataMutation, useGetSuperAdminDashboardReportsMutation } from "../../../../services/superAdminApi";
import { useDispatch, useSelector } from "react-redux";
import { superDashboardChartDataAction, superDashboardReportDataAction } from "../../../../redux/DashboardSlice/DashboardSlice";



const  SuperDashboard = () => {
  const dispatch = useDispatch();
  const ignore = useRef(false);
  // useSelector
  const superAdminChartData = useSelector((state) => state?.persistedReducer?.DashboardSlice?.superDashboardChartData);
  const superAdminReportData = useSelector((state) => state?.persistedReducer?.DashboardSlice?.superDashboardReportData);
  // Mutation
  const [ getSuperAdminDashboardChartData, { data:superAdminChartDataList, isSuccess } ] = useGetSuperAdminDashboardChartDataMutation();
  const [ getSuperAdminDashboardReports, { data:superAdminReportDataList, isSuccess:isReportSuccess  } ] = useGetSuperAdminDashboardReportsMutation();
  const [ toggleState4, setToggleState4 ] = useState(1);
  const selectGetCompanyData = (state) => {
    const mutations = state?.companyAPI?.mutations;
    const getCompanyMutation = Object.values(mutations).find(
      (mutation) => mutation.endpointName === 'getCompany'
    );
    return getCompanyMutation?.data?.companyData?.id;
  };
  const company_id = useSelector(selectGetCompanyData);
  const cuurentYear = new Date().getFullYear();
  const [ billing, setBilling ] = useState();
  const [ usersPerMonths, setUserPerMonth ] = useState();
  const [ RevenuePerMonth, setRevenuePerMonth ] = useState();

//   useEffect(() => {
//     if (!ignore.current){
//     getSuperAdminDashboardReports(company_id);
//   }
//   return () => { ignore.current = true; };
//   }, 
// // eslint-disable-next-line react-hooks/exhaustive-deps
// [ company_id ]);
useEffect(() => {
  if (isReportSuccess) {
    dispatch(superDashboardReportDataAction(superAdminReportDataList));
 
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isReportSuccess ]);


  useEffect(() => {
    if (!ignore.current){
      let payload = `/admin/get-chart-data?company_id=${company_id}&type=1&year=${cuurentYear}`;
      getSuperAdminDashboardChartData(payload);
      getSuperAdminDashboardReports(company_id);
    }
    return () => { ignore.current = true; }; 
   
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ company_id ]);
useEffect(() => {
  if (isSuccess) {
    dispatch(superDashboardChartDataAction(superAdminChartDataList));
 
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ]);

  useEffect(() => {
    if (superAdminChartData) {
      let chartData = superAdminChartData?.data;
      if (chartData?.type === "1") {
        setBilling(chartData);
      } else if (chartData?.type === "2") {
        setUserPerMonth(chartData);
      } else if (chartData?.type === "3") {
        setRevenuePerMonth(chartData);
      } 
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ superAdminChartData ]);

  const toggleTab4 = (index) => {
    setToggleState4(index);
    let payload = `/admin/get-chart-data?company_id=${company_id}&type=${index}&year=${cuurentYear}`;
    getSuperAdminDashboardChartData(payload);
  };
  return (
    <div className="">
      <HelmetProvider>
        <Helmet>
          <title>Schedulize | Dashboard</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className="py-6 dashboardPage empDashboard ">
          <div className="container mx-auto ">
            <div className="flex justify-between flex-wrap  items-center">
              <h2 className="text-xl mb-3 font-semibold  ">Dashboard</h2>            
            </div>
            <div className="bg-white p-6 pb-0 rounded-md shadow-2xl">           
              <div className="flex flex-wrap "> 
              <div className="flex flex-wrap w-full">
                  <div className="w-full md:w-1/3 md:pr-3 lg:pr-6 md:mb-0 mb-4 flex">
                    <div className="w-full relative flex flex-col overflow-hidden rounded-lg h-auto md:h-36  bg-gradient-to-br from-blue-400 to-blue-900 p-2.5 py-8 shadow-xl">
                      <p className="text-xs uppercase text-sky-100">
                      How many businesses are subscribed
                      </p>
                      <div className="flex items-end justify-between space-x-2">
                        <p className="mt-4 text-4xl font-medium text-white">
                        {superAdminReportData?.TotalCompanies}
                        </p>
                        <Link
                          //to="//super/company" state={{ status: "1" }}
                          to="/super/company" state={{ status: "1" }}
                          className="border-b border-dotted border-current pb-0.5 text-xs font-medium text-pink-100 outline-none transition-colors duration-300 line-clamp-1 hover:text-white focus:text-white"
                        >
                          View All
                        </Link>
                      </div>
                      <div className="mask is-reuleaux-triangle absolute top-0 right-0 -m-3 h-16  rounded-md w-16 bg-white/20" />
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 md:pr-3 lg:pr-6 md:mb-0 mb-4 flex">
                    <div className="w-full relative flex flex-col overflow-hidden h-auto md:h-36 lg:h-auto rounded-lg bg-gradient-to-br from-pink-500 to-rose-500 p-2.5 py-8 shadow-xl">
                      <p className="text-xs uppercase text-pink-100">
                     Pending Invoices
                      </p>
                      <div className="flex items-end justify-between space-x-2">
                        <p className="mt-4 text-4xl font-medium text-white">
                        {superAdminReportData?.PendingInvoices}
                        </p>
                        <Link
                          //to="/employees" state={{ filter: "unregister" }}
                          className="border-b border-dotted border-current pb-0.5 text-xs font-medium text-pink-100 outline-none transition-colors duration-300 line-clamp-1 hover:text-white focus:text-white"
                        >
                          View All
                        </Link>
                      
                      </div>
                      <div className="mask is-hexagon-2 absolute top-0 right-0 -m-3 h-16 rounded-md w-16 bg-white/20" />
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 md:mb-0 mb-4 flex">
                    <div className="relative flex flex-col w-full overflow-hidden h-auto md:h-36 lg:h-auto rounded-lg bg-gradient-to-br from-amber-600 to-orange-700 p-2.5 py-8 shadow-xl">
                      <p className="text-xs uppercase text-pink-100">
                    Total Users
                      </p>
                      <div className="flex items-end justify-between space-x-2">
                        <p className="mt-4 text-4xl font-medium text-white">
                        {superAdminReportData?.TotalUsers}
                        </p>
                      
                        <Link
                          //to="/employees" state={{ filter: "unregister" }}
                          className="border-b border-dotted border-current pb-0.5 text-xs font-medium text-pink-100 outline-none transition-colors duration-300 line-clamp-1 hover:text-white focus:text-white"
                        >
                          View All
                        </Link>
                      
                      </div>
                      <div className="mask is-hexagon-2 absolute top-0 right-0 -m-3 h-16 rounded-md w-16 bg-white/20" />
                    </div>
                  </div>
                </div>            
                <div className=" w-full mt-6  ">
                    <div className="card w-full infoList ChartDiv superAdminTabs mb-10">
                      <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                        <div>
                          <h3 className=" font-semibold text-lg">Trends</h3>
                        </div>
                      </div>
                      <div className="p-2">
                        <div className="cardtabBtn  flex-wrap">
                          <button
                          id="billing"
                            onClick={() => toggleTab4(1)}
                            className={
                              toggleState4 === 1
                                ? "btn-sm  mr-2 active mb-1 sm:mb-0"
                                : "btn-sm  mr-2 mb-1 sm:mb-0"
                            }
                          >
                            Billing
                          </button>

                          <button
                            onClick={() => toggleTab4(2)}
                            id="usersMonth"
                            className={
                              toggleState4 === 2
                                ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                : "btn-sm mr-2 mb-1 sm:mb-0  "
                            }
                          >
                            Users per month
                          </button>
                          {/* <button
                            onClick={() => toggleTab4(3)}
                            className={
                              toggleState4 === 3
                                ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                : "btn-sm mr-2 mb-1 sm:mb-0 "
                            }
                          >
                            Tickets raised per month
                          </button> */}
                          <button
                            onClick={() => toggleTab4(3)}
                            id="revenueMonth"
                            className={
                              toggleState4 === 3
                                ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                : "btn-sm mr-2 mb-1 sm:mb-0 "
                            }
                          >
                             Revenue per month
                          </button>
                       
                        </div>
                        <div className="tabCard">
                          <div
                            className={
                              toggleState4 === 1
                                ? "tabContent tabContentActive"
                                : "tabContent"
                            }
                          >
                            <div className="barChartArea">
                            <BarChart chart={billing}/>
                            </div>
                          </div>
                          <div
                            className={
                              toggleState4 === 2
                                ? "tabContent tabContentActive"
                                : "tabContent"
                            }
                          >
                            <div className="barChartArea">
                            <BarChart chart={usersPerMonths}/>
                            </div>
                          </div>
                          <div
                            className={
                              toggleState4 === 3
                                ? "tabContent tabContentActive"
                                : "tabContent"
                            }
                          >
                            <div className="barChartArea">
                            <BarChart chart={RevenuePerMonth}/>
                            </div>
                          </div>
                        
                          {/* <div
                            className={
                              toggleState4 === 5
                                ? "tabContent tabContentActive"
                                : "tabContent"
                            }
                          >
                            <div className="barChartArea">
                              <BarChart/>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperDashboard;
