// notificationHandler.js

export const NotificationNavigation = (
  notification,
  employeeIdData,
  navigate
) => {
  if (
    notification?.title === "Subscription Canceled" ||
    notification?.title === "Trial period Notification"
  ) {
    navigate("/setting/billing");
  } else if (notification?.title === "Overtime/Time in Lieu Request") {
    navigate("/approvals", {
      state: { activeTab: "Overtime" }
    });
  } else if (notification?.title === "Leave Request") {
    navigate("/approvals", {
      state: { activeTab: "Leave" }
    });
  } else if (notification?.title === "Overtime/Time in Lieu Declined") {
    navigate("/overtime");
  } else if (notification?.title === "Mileage Expense Request") {
    navigate("/approvals", {
      state: { activeTab: "Expenses" }
    });
  } else if (notification?.title === "Absence Marked") {
    navigate("/absense");
  } else if (notification?.title === "Folder Shared") {
    navigate(`/my-profile/${employeeIdData}`, {
      state: {
        activeTab: "EmployeeDocument",
        active: "Shared Folder"
      }
    });
  } else if (
    notification?.title === "Expense Paid" ||
    notification?.title === "Expense Approved"
  ) {
    navigate("/expense");
  } else if (
    notification?.title === "Leave Request Declined" ||
    notification?.title === "Leave Approved"
  ) {
    navigate("/my-leave", {
      state: { activeTab: "leaveRequest" }
    });
  } else if (notification?.title === "Expense Request") {
    navigate("/approvals", {
      state: { activeTab: "Expenses" }
    });
  } else {
    navigate("/dashboard");
  }
};
