import { useState, useEffect, useRef } from "react";
import  "./addPayment.css";
import {  toast } from "react-toastify";
import { useElements, useStripe } from "@stripe/react-stripe-js";

// import { Country, State, City } from "country-state-city";
import { CardElement } from "@stripe/react-stripe-js";
import { useAddCardMutation } from "../../services/authApi";

export default function AddPayMethod({setIsAddCard}) {
  const stripe = useStripe();
  const elements = useElements();
  const card = useRef();
 
  const toastId = useRef(null);
  const [ cardInfo, setCardInfo ] = useState({
    name: "",
    expiry: "",
    number: "",
    address: {
      line: "",
      postalCode: ""
    }
  });
  const [ addCard, {isSuccess:intentSuccess, isError:isIntentError, isLoading, error:intentError} ] = useAddCardMutation();
  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
      // position: "top-center",
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (intentSuccess) {
        setIsAddCard(false);
        toast.dismiss(toastId.current);
        toast.success("Card Added Successfully.", {
          render:"Card Added Successfully.",
               type: "error",
               autoClose: 2000,
               duplicate: false,
               isLoading: false
        });
    }
    if (isIntentError) {
      const toastMessage = intentError?.data?.message
        ? intentError?.data?.message
        : "Something went wrong";
        toast.dismiss(toastId.current);
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ intentSuccess, isIntentError, isLoading ]);
 
  function handleChangeAddressLine(e) {
    const { value } = e.target;
    setCardInfo((prev) => {
      return { ...prev, address: { ...prev.address, line: value } };
    });
  }

  function handleChangeName(e) {
    const { value } = e.target;
    setCardInfo((prev) => {
      return { ...prev, name: value };
    });
  }


  async function handleSubmit() {
    const address = cardInfo.address;
    const billingDetails = {
      name: cardInfo.name,
      address: {
        country: address.country,
        state: address.state,
        city: address.city,
        line1: address.line
      }
    };

    try {
      stripe
        .createPaymentMethod({
          type: "card",
          "billing_details": billingDetails,
          card: elements.getElement(CardElement)
        })
        .then((resp) => {
       
         if(resp){
            addCard({ paymentMethod: resp?.paymentMethod?.id });
         }
        });
    } catch (err) {
      err.preventdefault();
    }
  }


  return (
    <div className="wrapper">
     <div className="title">Add Payment Details</div>
      <div className="innerWrapper">       
        <div className="row">
          <label>Cardholder Name</label>
          <input
            onChange={handleChangeName}
            type="text"
            className="p-2 inputField"
            id="cardHolderName"
            name="name"
            placeholder="Enter card holder name"
          />
        </div>
        <div className="rowPaymentInput">
          <CardElement ref={card} />
        </div>
        <div className="addressWrapper">
          <div className="row">
            <label>Address</label>
            <input
              onChange={handleChangeAddressLine}
              type="text"
              id="cardHolderAddress"
              className="p-2 inputField"
              name="address"
              placeholder="Enter Full Address"
            />
          </div>
          <div className="btnContainer">
            <button id="cardSubmit" className=" py-4 mb-10 bg-primary w-full text-white rounded text-base"  onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}