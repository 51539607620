import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';
export const leaveApi = createApi({
    reducerPath: "leaveAPI",
    baseQuery: fetchBaseQuery({ 
      baseUrl: ApiUrl
    }),
    endpoints: (builder) => ({
      getAllLeave:builder.mutation({
          query: () => ({
            url: '/appliedleave/user/export/csv/',
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization:  localStorage.getItem('accessToken')
            }
          })
        }),
        getNextPrevMonth:builder.mutation({
          query: (payload) => ({
            
            url: `/employee?start_date=${payload.start_date}&department_id=${payload.departmentId}&page=${payload?.page}&status=${1}`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization:  localStorage.getItem('accessToken')
            }
          })
        }),
        getNextPrevMonthAllList: builder.mutation({
          query: (payload) => {
            const departmentIdParam = payload.departmentId === "0" ? '' : `&department_id=${payload.departmentId}`;
        
            return {
              url: `/employee?start_date=${payload.start_date}${departmentIdParam}&page=${payload.page}&status=${1}`,
              method: 'GET',
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: localStorage.getItem('accessToken')
              }
            };
          }
        })
        
    })
  });

  export const { useGetNextPrevMonthMutation, useGetAllLeaveMutation, useGetNextPrevMonthAllListMutation } = leaveApi;
  
  
  
  
