/* eslint-disable camelcase */
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { SearchField } from "../../components/filters";
import { FormSelect } from "../../components/formInput";
import "react-datepicker/dist/react-datepicker.css";
import moments from "moment";
import Pagination from "../../components/inc/pagination";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import NoDataFound from "../../helper/noDataFound";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import moment from "moment";
import { LoadingIndicator } from "../../components/loading-indicator";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { useMyOvertimeListMutation } from "../../services/myOvertimeApi";
import { overTimeDataAction } from "../../redux/OverTimeSlice/overtimeListSlice";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const Timeinlieu = () => {

  const dispatch = useDispatch();
  const ignore = useRef(false);
  //Api calling 
  const [ myOvertimeList, { data: getAllOverTimeApiResponse, isSuccess: isSuccessEmployeeInfo, isLoading } ] = useMyOvertimeListMutation();
  const [ getDepartmentList, { data: departmentApiResponse, isSuccess: isSuccessDepartmentApi } ] = useGetDepartmentListMutation();

  // states are defined here
  const [ employeeInfo, setEmployeeInfo ] = useState();
  const [ allDepartmentListData, setAllDepartmentListData ] = useState();
  const [ excelData, setExcelData ] = useState([ ]);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(0);
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const pageSize = 10;
  const createCsvFileName = () => `Time_in_lieu_report_${moments().format()}.csv`;

  //carrying out data using redux
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const company_id = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);
  const allEmployeeInfoReponse = useSelector((state) => state?.persistedReducer?.overtimeListSlice?.overTimeData);
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);

  useEffect(
    () => {
      if (!ignore.current){
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : company_id}&time_in_lieu=${1}`;
      myOvertimeList(payload);
      getDepartmentList();
    }
    return () => { ignore.current = true; };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ ]
  );

  useEffect(
    () => {
      if (allEmployeeInfoReponse) {
        setEmployeeInfo(allEmployeeInfoReponse?.data);
        if (allEmployeeInfoReponse?.data?.rows) {
          let csvData = exportTOCSV(allEmployeeInfoReponse?.data?.rows);
          setExcelData(csvData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allEmployeeInfoReponse ]
  );

  useEffect(
    () => {
      if (isSuccessEmployeeInfo) {
        dispatch(overTimeDataAction(getAllOverTimeApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessEmployeeInfo ]
  );

  useEffect(
    () => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessDepartmentApi ]
  );
  useEffect(
    () => {
      if (departmentApiData) {
        setAllDepartmentListData(departmentApiData?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ departmentApiData ]
  );

  const handlerChangeByDeptName = (e) => {
    e.preventDefault();
    if (e.target.value === "0") {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : company_id}}&time_in_lieu=${1}`;
      myOvertimeList(payload);
    } else {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : company_id}&time_in_lieu=${1}&department_id=${
        e.target.value
      }`;
      myOvertimeList(payload);
    }
  };

  useEffect(
    () => {
      if (allEmployeeInfoReponse?.data?.rows) {
        let totalPages = Math.ceil(
          allEmployeeInfoReponse?.data?.rows?.length / pageSize
        );
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allEmployeeInfoReponse?.data?.rows?.slice(
          firstPageIndex,
          lastPageIndex
        );
        setEmployeeInfo(data);
        setPages(totalPages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allEmployeeInfoReponse?.data?.rows ]
  );

  // eslint-disable-next-line no-unused-vars
  const currentTableData = useMemo(
    () => {
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allEmployeeInfoReponse?.data?.rows?.slice(
        firstPageIndex,
        lastPageIndex
      );
      setEmployeeInfo(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ page ]
  );

  // eslint-disable-next-line no-unused-vars
  const decrementPage = () => {
    const lastPageIndex = (page - 1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allEmployeeInfoReponse?.data?.rows?.slice(
      lastPageIndex,
      firstPageIndex
    );
    setEmployeeInfo(data);
  };

  const filterEmployees = useCallback(
    (searchTerm) => {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : company_id}&time_in_lieu=${1}&first_name=${searchTerm}`;
      myOvertimeList(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  let headers = [
    { label: "Employee Name", key: "employeeName" },
    { label: "Department", key: "department" },
    { label: "Type", key: "type" },
    { label: "Approver", key: "approver" },
    { label: "Date", key: "date" }
  ];
  const exportTOCSV = (report) => {
    let dataAbence = [];
    if (report) {
      report?.forEach((dExcel) => {
        let val = {
          employeeName: dExcel?.employeeInfo?.first_name,
          department: dExcel?.employeeInfo?.department_info?.department_name,
          type: dExcel?.mOverTimeInfo?.overtime_namee,
          approver: dExcel?.approvedByInfo?.first_name+" "+dExcel?.approvedByInfo?.last_name,
          date: moment(dExcel?.start_date).format(formattedDate)
        };
        dataAbence.push(val);
      });
      return dataAbence;
    }
  };

  const onDateChange = (e) => {
    if (e[0] !== null && e[1] !== null) {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : company_id}&time_in_lieu=${1}&start_date=${moment(e[0]).format("YYYY-MM-DD")}&end_date=${moment(e[1]).format("YYYY-MM-DD")}`;
      myOvertimeList(payload);
    } else {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : company_id}&time_in_lieu=${1}`;
      myOvertimeList(payload);
    }
  };

  const statusData = [ 
    { value: "0", label: "Awaiting approval" },
    { value: "1", label: "Approved" },
    { value: "2", label: "Rejected" }
  ];
  
  const handlerStatusFilter = (e) => {
    if (e.target.value === "0") {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : company_id}&time_in_lieu=${1}`;
      myOvertimeList(payload);
    } else {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : company_id}&time_in_lieu=${1}&status=${e.target.value}`;
      myOvertimeList(payload);
    }
  };

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">
                Time in Lieu Report{" "}
              </h2>
              <div className="card ">
                <div className="p-4 ">
                  <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                    <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 ">
                      <SearchField
                        placeholderText="Search By Name"
                        searchId="reportTimeSearch"
                        onChange={(e) => filterEmployees(e.target.value)}
                      />
                    </div>
                    <div className="searchSec md:w-1/4 lg:w-1/5 md:pr-4 w-full md:mb-0 mb-2 ">
                      <DatePicker
                        selectsRange={true}
                        id="reportTimeStartDate"
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        className="formControl"
                        placeholderText="Select Date Range"
                        onChange={(update) => {
                          setDateRange(update);
                          onDateChange(update);
                        }}
                        isClearable={true}
                        locale="en-GB"
                      />
                    </div>
                    <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 md:pr-4">
                      <select
                        className="formControl"
                        id="reportTimeDepartment"
                        name="department_name"
                        onChange={(e) => handlerChangeByDeptName(e)}
                      >
                        <FormSelect
                          optionValue={"0"}
                          optionName={"Select Department"}
                        />
                        ;
                        {allDepartmentListData?.length > 0
                          ? allDepartmentListData?.map((departments, key) => {
                              return (
                                <FormSelect
                                  key={key}
                                  optionValue={departments?.id}
                                  optionName={departments?.department_name}
                                />
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-4 lg:pr-0">
                      <select
                        className="formControl"
                        id="reportTimeStatus"
                        onChange={(e) => handlerStatusFilter(e)}
                      >
                        <FormSelect
                          optionValue={"0"}
                          optionName={"Select Status"}
                        />
                        {statusData?.map((status, key) => {
                          return (
                            <FormSelect
                              key={key}
                              optionValue={status?.value}
                              optionName={status?.label}
                            />
                          );
                        })}
                      </select>
                    </div>
                    <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full ">
                      <CSVLink
                        className=""
                        data={excelData}
                        headers={headers}
                        filename={createCsvFileName()}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          outline: "none",
                          height: "5vh"
                        }}
                      >
                        <button className="btn-md btn-add " id="reportTimeCsv">
                          <span className="fa fa-file-export mr-2" /> Export to
                          CSV
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                  <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th className="w-40"> Name</th>
                          <th>Department </th>
                          <th>Type</th>
                          <th>Approver </th>
                          <th>Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Duration</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {isLoading ? (
                        <LoadingIndicator />
                      ) : (
                        <tbody>
                          {employeeInfo?.length > 0 ? (
                            <>
                              {" "}
                              {employeeInfo?.length > 0 &&
                                employeeInfo?.map((employee, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          <Link
                                            to={`/employees/detail/${employee.id}`}
                                            class="listImage flex items-center "
                                          >
                                            {employee?.employeeInfo?.first_name +" " +employee?.employeeInfo?.last_name}
                                          </Link>
                                        </td>
                                        <td>{employee?.department_info?.department_name}</td>
                                        <td>{employee?.mOverTimeInfo?.overtime_name}</td>
                                        <td>{employee?.approvedByInfo?.first_name+" "+employee?.approvedByInfo?.last_name}</td>
                                        <td>{moment(employee?.start_date).format(formattedDate)}</td>
                                        <td>{employee?.start_time}</td>
                                        <td>{employee?.end_time}</td>                                    
                                        <td>{(employee?.duration / 60).toFixed(2)} hours</td>
                                        <td>
                                          <span className={
                                        employee?.status === '0' ? 'yellow-bedge' :
                                        employee?.status === '1' ? 'greenBedge' :
                                        employee?.status === '3' ? 'amberBedge' :
                                        'red-bedge'
                                        }>{employee?.status === "1" ? "Approved" : employee?.status === "2" ? "Rejected" : employee?.status === "3" ? "Redeemed" : employee?.status === "0" ? "Awaiting approvals" : " "}</span>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <center className="mt-4">{<NoDataFound />}</center>
                          )}
                        </tbody>
                      )}
                    </table>
                    <Pagination
                      page={page}
                      pages={pages}
                      changePage={setPage}
                      decrementPage={setPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeinlieu;
