import { ModifiedToast } from "./toaster";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PrimaryButton from "./primaryButton";
import backgroundImage from "../loginBg.webp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { auditorSelectedCompanyAction } from "../redux/AuditorsSlice/AuditorsSlice";
import { useNavigate } from "react-router-dom";
import { useAuditorCompanyLoginMutation } from "../services/companyApi";
import { toast } from "react-toastify";

const AuditorCompanySelect = () => {

    const companyList = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.companies);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedCompany, setSelectedCompany] = useState("");
    const [error, setError] = useState();
    const toastId = useRef(null);

    const [ auditorCompanyLogin, { data, isLoading, isSuccess, isError, error: ApiError} ] = useAuditorCompanyLoginMutation();
    console.log(data?.accessToken);

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);
        setError("");
    };

    useEffect(() => {
        if(selectedCompany){
            dispatch(auditorSelectedCompanyAction(selectedCompany));
        }
    }, [selectedCompany]);

  useEffect(() => {
      if (isLoading) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccess) {
        localStorage.setItem('accessToken', data.accessToken);
        toast.update(
          toastId.current,
          ("Company Selected Successfully",
          {
            render: "Company Selected Successfully",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
        navigate("/dashboard");
      }
      if (isError) {
        const toastMessage = ApiError?.data?.message
          ? ApiError?.data?.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isSuccess, isError]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(selectedCompany){
            let val = {
              company_id: parseInt(selectedCompany)            
            };
            auditorCompanyLogin(val);
        }
        else{
            setError("Please select a company");
        }
    };

  return (
    <div
      className="flex loginGradient w-full"
      style={{ background: `url(${backgroundImage})` }}
    >
      <HelmetProvider>
        <Helmet>
          <title>Schedulize | Login </title>
        </Helmet>
      </HelmetProvider>
      <div className=" flex align-center w-full">
        <div className=" w-full  ">
          <div className="md:flex align-center justify-center h-full items-center flex-wrap w-full 	">
            <div className=" lg:p-8 p-4 lg:w-1/2 w-full relative">
              <div className=" w-full">
                <div className="w-full max-w-lg m-auto bg-white lg:p-8 p-4 border shadow-lg rounded-lg ">
                  <div className="text-center  w-full">
                    <img
                      src="../images/logo-full.webp"
                      width="220"
                      alt="logo"
                      className="mx-auto"
                    />
                  </div>
                  <h1 className="text-xl font-semibold my-6 mb-8 text-center">
                    External user account login
                  </h1>
                  <form className="text-left" id="loginForm">
                    <div className="parentGroup mb-5">
                      <label htmlFor="email" className="formBlock">
                        {" "}
                        Company<span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <select
                        id="company"
                        name="company"
                        value={selectedCompany}
                        onChange={handleCompanyChange}
                        className="formControl"
                      >
                        <option value="">
                          Select a company
                        </option>
                        {companyList?.map((company) => (
                          <option key={company?.id} value={company?.id}>
                            {company?.name}
                          </option>
                        ))}
                      </select>
                      <div>
                        <div className="flex justify-between items-center text-red-700 mt-2">{error}</div>
                      </div>
                    </div>
                    <div className=" mt-8 text-center">
                      {/* <Link to="/dashboard"> */}
                      <PrimaryButton
                        btnText="Continue"
                        buttonID="Continue"
                        Btnclass="btn save w-full mb-6"               
                        onClick={(e) => handleSubmit(e)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModifiedToast />
    </div>
  );
};

export default AuditorCompanySelect;
