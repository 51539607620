// eslint-disable-next-line no-unused-vars
/* eslint-disable no-debugger*/
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {ModifiedToast} from './toaster';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {FormInput} from './formInput';
import PrimaryButton from './primaryButton';
import { useResetPasswordMutation } from '../services/authApi';
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => { 

    const navigate = useNavigate();

    const [ resetPassword ] = useResetPasswordMutation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [ formData, setFormData ] = useState({ email: '', token: '' });  

    const [ passwords, setPasswords ] = useState({
        newPassword:"",
        confirmPassword : ""
    });

    useEffect(() => {
        const emailFromURL = searchParams.get('email');
        const tokenFromURL = searchParams.get('token');

        if (emailFromURL && tokenFromURL) {
        setFormData({ email: emailFromURL, token: tokenFromURL });
        }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ searchParams ]);

    const handlerChangeSubmit = (e) => {  
        e.preventDefault();
        const { name, value } = e.target;
        setPasswords((prevPasswords) => ({
          ...prevPasswords,
          [name]: value
        }));
    };
    const handleFormSubmit = (e) => {    
   
        e.preventDefault();
        if (passwords.newPassword !== passwords.confirmPassword) {
            toast.error('New password and confirm password are not same');
            return;
        }         
        else if (passwords.newPassword.length < 8) {
            toast.error('Password must be at least 8 characters long.');
            return;
        }   
        resetPassword({ formData:formData, passwords:passwords.confirmPassword });   
        navigate('/login');
        toast.success('Password reset successfully.');
    };

    return (
        <div className=" flex loginGradient w-full">
            <HelmetProvider>
                <Helmet>
                    <title>Schedulize | Login </title>
                </Helmet>
            </HelmetProvider>           
            <ModifiedToast/>
            <div className=" flex align-center w-full">
                <div className=" w-full   ">
                    <div
                        className="md:flex align-center justify-between h-full items-center flex-wrap md:flex-nowrap 	">
                        <div className=" md:p-8 p-2 md:w-1/2 w-full relative mx-auto">
                            <div className=" pt-0">
                                <div
                                    className="w-full max-w-lg m-auto bg-white p-8 border shadow-lg rounded-lg ">
                                     <div className="text-center  w-full 	">
                                        <img
                                            src="../images/logo-full.webp"
                                            width="220"
                                            className="mx-auto"
                                        />
                                    </div>
                                    <h1 className="text-xl font-semibold my-6 mb-8 text-center">
                                    Reset password?

                                    </h1>
                                    <div
                                        
                                        className="text-left"
                                        id="loginForm"
                                    >
                                        <div className="parentGroup mb-5">
                                            <label
                                                htmlFor="password"
                                                className="formBlock"
                                            >
                                            {""} 
                                            New Password
                                            </label>
                                          <FormInput inputType="password" inputName="newPassword" inputID="password" handlerChange={(e) => handlerChangeSubmit(e)}
                                          />
                                            <div className="help-block" />
                                        </div>
                                        {/* <div className="help-block text-red-700">{error && <p>Please enter a new password</p>}</div> */}

                                        <div className="parentGroup mb-5">
                                            <label
                                                htmlFor="password"
                                                className="formBlock"
                                            >
                                            {""} 
                                            Confirm Password
                                            </label>
                                          <FormInput inputType="password" inputName="confirmPassword" inputID="password" handlerChange={(e) => handlerChangeSubmit(e)}
                                          />
                                            <div className="help-block" />
                                        </div>
                                        {/* <div className="help-block text-red-700">{error && <p>Please enter a Confirm password</p>}</div> */}
                                        
                                        <div className=" mt-8 text-center">
                                        <PrimaryButton btnText="Submit " buttonID="resetPasswordSubmit" onClick={handleFormSubmit} Btnclass="btn save w-full  mb-6"/>                                         
                                        <p className='text-gray-600 font-medium'><Link to="/login" className='primary-text font-semibold'>Login</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                     
                    </div>
                </div>
            </div>
            <ModifiedToast/>
        </div>
    );
};

export default ResetPassword;