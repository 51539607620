import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';

export const leaveTypesApi = createApi({
    reducerPath: "leaveTypesAPI",
    baseQuery: fetchBaseQuery({
      baseUrl: ApiUrl
    }),
    endpoints: (builder) => ({
      getAllLeaveTypes:builder.mutation({
        query: (payload) => ({
          url: payload,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization:  localStorage.getItem('accessToken')
          }
        })
      }),
      getAllLeaveTypesNonDeductible:builder.mutation({
        query: (payload) => ({
          url: payload,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization:  localStorage.getItem('accessToken')
          }
        })
      }),
      getAllLeaveTypesDeductible:builder.mutation({
        query: (payload) => ({
          url: payload,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization:  localStorage.getItem('accessToken')
          }
        })
      }),
      getLeaveTypeList:builder.mutation({
        query: () => ({
          url: `/leavetype?page=1&status=1&limit=200`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          }
        })
      }),
        createLeaveTypes: builder.mutation({
            query: (payload) => ({
              url: '/leavetype',
              method: 'POST',
              body: payload,
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization:  localStorage.getItem('accessToken')
              }
            }),
            invalidatesTags: [ 'Post' ]
        }),
        updateLeaveTypes: builder.mutation({
            query: (payload) => {
              const {id, ...body } = payload;
              return {
                url: `/leavetype/${id}`,
                method: 'PUT',
                body,
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  Authorization:  localStorage.getItem('accessToken')
                }
              };
            },
            invalidatesTags: [ 'Post' ]
          }),
          deleteLeaveTypes: builder.mutation({
            query: (id) => ({
              url: `/leavetype/${id}`,
              method: 'DELETE',
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization:  localStorage.getItem('accessToken')
              }
            }),
            invalidatesTags: [ 'Post' ]
          }) 
    })
  });
  
  
  
  export const { useGetAllLeaveTypesDeductibleMutation, useGetAllLeaveTypesNonDeductibleMutation, useGetAllLeaveTypesMutation, useGetLeaveTypeListMutation, useCreateLeaveTypesMutation, useUpdateLeaveTypesMutation, useDeleteLeaveTypesMutation} = leaveTypesApi;