import {useState} from "react";
import Popup from "reactjs-popup";
import {Link, useNavigate} from "react-router-dom";
import {FormInput, FormSelect, FormTextarea} from "./formInput";
import PrimaryButton from "./primaryButton";
import { useSelector } from "react-redux";

const OnBoard = () => {
  const UserFirstName = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.first_name);
  const [ open, setOpen ] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const [ openboard, setOpenBoard ] = useState(false);
  const closeModal2 = () => {
    setOpenBoard(false);
  };

  const [ toggleWelState, setToggleWelState ] = useState("Welcome");
  const toggleWelTab = (index) => {
    setToggleWelState(index);
  };
  const formSubmit = (event) => {
    event.preventDefault();
  };

  const [ isActive, setActive ] = useState(false);

  const toggleTab = () => {
    setActive(!isActive);
  };

  const handleNextButtonClick = () => {
    if (toggleWelState === 'Welcome') {
      navigate("/dashboard");
    }
  };

  return (
    <>
      <Popup
        open={openboard}
        className="onboardPopup"
        onClose={() => closeModal2()}
        closeOnDocumentClick={false}
        trigger={
          <button className="btn save" id="continue" onClick={() => setOpenBoard((o) => !o)}>
            Continue
          </button>
        }
        modal
        nested
      >
        <div className="modal">
          <div className="content" style={{padding: "0"}}>
            <div className="flex flex-row shadow-xl flex-wrap lg:flex-nowrap">
              <div className="lg:w-1/4 w-full">
                <div className="multiNav shadow-lg">
                  <img src="./images/logo-full.webp" width="200" />
                  <div className=" onboardingTab">
                    <div className=" w-full  ">
                      <div className=" onboardingLink ">
                        <ul>
                          <li>
                            <span
                              onClick={() => toggleWelTab("Welcome")}
                              className={
                                toggleWelState === "Welcome" ? " active" : "  "
                              }
                            >
                              Welcome/Customer Support
                            </span>
                          </li>
                          {/* <li>
                            <span
                              onClick={() => toggleWelTab("CustomerSupport")}
                              className={
                                toggleWelState === "CustomerSupport"
                                  ? "  active"
                                  : " "
                              }
                            >
                              Customer Support
                            </span>
                          </li> */}
                          <li>
                            <Link to="/dashboard">
                              <span> Menus Overview</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/4">
                <div className="multiContent">
                  <div className="tabCard ">
                    <div
                      className={
                        toggleWelState === "Welcome"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <div className="flex flex-col	justify-between	h-full onboardContent">
                        <div className="py-0">
                          <h2 className="text-2xl mb-3 font-semibold  ">
                            Welcome, {UserFirstName}
                          </h2>

                          <p>Welcome to Schedulize!</p>
                          <p>Effortless Employee Management starts here.</p> 
                          {/* <p>Hello {UserFirstName}, </p>  */}

                         <p> You now have a 7 day free trial which allows you full access to the Schedulize Platform.</p>

                         <p> To help you get started: </p>

                            <p>Read our <a href="https://schedulize.co.uk/knowledge-base/" target="_blank" rel="noopener noreferrer"  >Step by step </a>guide to set up Schedulize.</p>

                            <p>Download our app via the Play Store or App Store</p>

                            <p>Approver and Request, Leave, Expenses, Mileage, Overtime and Time Back at the click of a button.</p>


                          {/* <p>effortless employee management starts here.</p>
                          <p>
                          {" "}
                            Hello {UserFirstName}, COMPANY ADMIN has invited you to join Schedulize. <br />
                            You now have a 7 day free trial which allows you full access to the Schedulize Platform. <br />
                            To help you get started: <br />
                          </p>
                          <p>
                            
                            Follow our Getting Started link in the help section of the platform 
                            step by step guide to set up Schedulize 
                            Download our app via the Play Store or App Store 
                            Request, Leave, Expenses, Mileage, Overtime and Time Back at the click of a button. 
                            Support
                          </p> */}
                          {/* <p>
                            <b>Please add requirements settings:</b>{" "}
                          </p>
                          <div className="form-group mb-4 mt-6">
                            <label className="formBlock flex	">
                              <input type="checkbox" className="mr-2 w-5 h-5" id="companyInfo" />
                              Company Information
                            </label>
                          </div>
                          <div className="form-group mb-4 ">
                            <label className="formBlock flex	">
                              <input type="checkbox" className="mr-2 w-5 h-5" id="expenseTypes" />
                              Expense types
                            </label>
                          </div>
                          <div className="form-group mb-4 ">
                            <label className="formBlock flex	">
                              <input type="checkbox" className="mr-2 w-5 h-5" id="overtimeTypes" />
                              Overtime types
                            </label>
                          </div>

                          <div className="form-group mb-4 ">
                            <label className="formBlock flex	">
                              <input type="checkbox" className="mr-2 w-5 h-5" id="Ddepartments" />
                              Add Departments{" "}
                              <Link to="" className="pl-1">
                                here
                              </Link>
                            </label>
                          </div> */}
                        </div>
                        <div className="onboardContent mt-4">
                        <h2 className="text-2xl mb-3 font-semibold  ">
                          Customer Support
                        </h2>
                        <p>
                          If at any time you require any further assistance, our team are ready to support you via the below options:<br /><br />
                          Helpdesk on 01480 756408 <br /><br />
                          Our email - support@schedulize.co.uk <br /><br />
                          <a href="https://schedulize.co.uk/book-a-1-1-session/" target="_blank" rel="noopener noreferrer"  >Our Support Sessions</a> <br /><br />
                          <a href="https://www.facebook.com/groups/schedulize" target="_blank" rel="noopener noreferrer"  >Our Private Facebook.</a> <br /><br />
                          <a href="https://schedulize.co.uk/knowledge-base/" target="_blank" rel="noopener noreferrer"  >Knowledge Base.</a> <br /><br />

                          The Schedulize App is available on iOS and Android 
                          Wherever your business takes you, Schedulize is with you. Simply download our app and manage employee admin on the go. 
                          We look forward to seeing you in Schedulize.
                        </p>

                        <ul>
                          <li>
                            <div className="flex items-center ">
                              <span className="fa fa-phone mr-4 " />
                              <div>
                                <h5>Contact Number</h5>
                                <p>01480 756408</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="flex items-center ">
                              <span className="fa fa-envelope mr-4 " />
                              <div>
                                <h5>Email</h5>
                                <p>support@schedulize.co.uk</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="flex items-center ">
                              <span className="fa fa-comments mr-4 " />
                              <div>
                                <h5>Live Chat</h5>
                                <Link to="https://schedulize.co.uk/book-a-1-1-session/">Click here</Link>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                        {/* <div className="w-full ">
                    <div className="form-group  flex justify-end	 ">
                      <button className="btn save mr-2" onClick={() => toggleTab("Expenses")}>Continue</button>
                 
                    </div>
                  </div> */}
                  
                      </div>
                      <button className="ml-[40%] btn save mt-4" id="welcomeNext" onClick={handleNextButtonClick}>Next</button>
                    </div>

                    {/* <div
                      className={
                        toggleWelState === "CustomerSupport"
                          ? "tabContent tabContentActive expenses-tab"
                          : "tabContent"
                      }
                    >
                      <button className="ml-[40%] mt-4 btn save" id="supportNext" onClick={handleNextButtonClick}>Next</button>
                    </div> */}
                    
                    <div
                      className={
                        toggleWelState === "Overview"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <div className="onboardContent">
                        <h2 className="text-2xl mb-3 font-semibold  ">
                          Menus Overview
                        </h2>
                        <div className="mb-3">
                          <h3 className="mb-1">Dashboard:</h3>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old. Richard McClintock, a Latin
                            professor at Hampden-Sydney College in Virginia
                          </p>
                        </div>

                        <div className="mb-3">
                          <h3 className="mb-1">Approvals:</h3>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. making it over 2000 years old.
                            Richard McClintock, a Latin professor at
                            Hampden-Sydney College in Virginia
                          </p>
                        </div>

                        <div className="mb-3">
                          <h3 className="mb-1">Absence:</h3>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old.
                          </p>
                        </div>

                        <div className="mb-3">
                          <h3 className="mb-1">My Leave:</h3>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old. Richard McClintock, a Latin
                            professor at Hampden-Sydney College in Virginia
                          </p>
                        </div>
                        <div className="mb-3">
                          <h3 className="mb-1">Employees:</h3>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old. Richard McClintock, a Latin
                            professor at Hampden-Sydney College in Virginia
                          </p>
                        </div>
                        <div className="mb-3">
                          <h3 className="mb-1">Settings:</h3>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old. Richard McClintock, a Latin
                            professor at Hampden-Sydney College in Virginia
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        toggleWelState === "Settings"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <div className="onboardContent">
                        <div className="heading_title mb-6 pb-2">
                          <h2 className="text-2xl mb-3 font-semibold  ">
                            Company Information
                          </h2>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old.
                          </p>
                        </div>
                        <form onSubmit={formSubmit}>
                          <div className="flex flex-wrap ">
                            <div className=" lg:w-1/2 lg:pr-6 w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	">
                                  Company Name
                                </label>
                                <input
                                  id="company"
                                  name="company"
                                  type="text"
                                  className="formControl"
                                />
                                <div className="help-block" />
                              </div>
                            </div>
                            <div className="lg:w-1/2 pr-0 w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	">Email</label>
                                <input
                                  id="email"
                                  name="email"
                                  type="text"
                                  className="formControl"
                                />
                                <div className="help-block" />
                              </div>
                            </div>
                            <div className="lg:w-1/2 lg:pr-6  w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	">
                                  Mobile Number
                                </label>
                                <input
                                  id="mobileNumber"
                                  name="mobileNumber"
                                  type="text"
                                  className="formControl"
                                />
                                <div className="help-block" />
                              </div>
                            </div>
                            <div className="lg:w-1/2 pr-0 w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	">Website</label>
                                <input
                                  id="website"
                                  name="website"
                                  type="text"
                                  className="formControl"
                                />
                                <div className="help-block" />
                              </div>
                            </div>

                            <div className="lg:w-1/2 lg:pr-6 w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	"> Address</label>
                                <input
                                  id="address"
                                  name="address"
                                  type="text"
                                  className="formControl"
                                />
                                <div className="help-block" />
                              </div>
                            </div>
                            
                            <div className="lg:w-1/2 lg:pr-6 w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	">City/Town</label>
                                <input
                                  id="city"
                                  name="city"
                                  type="text"
                                  className="formControl"
                                />
                                <div className="help-block" />
                              </div>
                            </div>
                            <div className="lg:w-1/2 pr-0 w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	">Post Code</label>
                                <input
                                  id="postCode"
                                  name="postCode"
                                  type="text"
                                  className="formControl"
                                />
                                <div className="help-block" />
                              </div>
                            </div>
                            <div className="lg:w-1/2 lg:pr-6 w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	">Country</label>
                                <select className="formControl" id="country">
                                  <option>Select Country</option>
                                </select>
                                <div className="help-block" />
                              </div>
                            </div>
                            <div className="lg:w-1/2 pr-0 w-full">
                              <div className="form-group mb-6 ">
                                <label className="formBlock	">
                                  Company Logo
                                </label>
                                <div className="imageUpload mt-5">
                                  <img src="/images/logo.webp" />
                                  <span className="fa fa-image" />
                                </div>
                                <div className="help-block" />
                              </div>
                            </div>
                            <div className="w-full mt-4">
                              <div className="form-group pt-4 pb-3 flex justify-center ">
                                <button id="expenseNext"
                                  className="btn save mr-2 text-center"
                                  onClick={() => toggleTab("Expense")}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div
                      className={
                        toggleWelState === "Expense"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <div className="onboardContent">
                        <div className="heading_title mb-6 pb-2">
                          <h2 className="text-2xl mb-3 font-semibold  ">
                            Expense Types
                          </h2>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old.
                          </p>
                        </div>
                        <div className="card p-4">
                          <div className="flex w-full justify-end">
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <button
                                 id="addExpense"
                                  className="btn-md btn-add  pull-right mb-4"
                                  aria-describedby="popup-8"
                                  onClick={() => setOpen((o) => !o)}
                                >
                                  <span className="fa fa-plus mr-2" />
                                  Add Expense Types
                                </button>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="closeExpensePopup"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    <h3>Add Expense Types</h3>
                                  </div>
                                  <div className="content">
                                    <form>
                                      <div className="flex flex-wrap ">
                                        <div className=" w-full">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              {" "}
                                              Expense name
                                            </label>
                                            <FormInput
                                              inputType="text"
                                              inputName="Name"
                                              inputID="expenseName"
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className=" w-full ">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Description
                                            </label>
                                            <FormTextarea  inputID="expenseDescription"/>
                                            <div className="help-block" />
                                          </div>
                                        </div>

                                        <div className="lg:w-1/2 pr-0 w-full">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Status
                                            </label>
                                            <select className="formControl ">
                                              <FormSelect optionValue="Active" selectID="expenseStatus" />
                                            </select>
                                            <div className="help-block" />
                                          </div>
                                        </div>

                                        <div className="lg:w w-full  ">
                                          <div className="w-full mt-4">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <PrimaryButton
                                                btnText="Save"
                                                Btnclass="btn save mr-2"
                                                btnype="button"
                                                buttonID="expenseSave"
                                              />
                                              <PrimaryButton
                                                btnText="Cancel"
                                                Btnclass="btn cancel"
                                                btntype="button"
                                                buttonID="expenseCancel"
                                                onClick={() => close()}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                          <div className="overflow-x-auto w-full whitespace-nowrap">
                            <table className="table-fixed table  w-full">
                              <thead className="thead-dark ">
                                <tr>
                                  <th className="w-50"> Name</th>
                                  <th>Type</th>
                                  <th>Status</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Parking</td>
                                  <td>Default</td>
                                  <td>
                                    <span className="blue-bedge">Active</span>
                                  </td>
                                  <td>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-eye" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="expenseDetailclosePopup"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Expense Types Details</h3>
                                          </div>
                                          <div className="content">
                                            <div className="flex flex-wrap mb-4 ">
                                              <div className="w-1/4 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Expense Name:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Parking</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Description:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>
                                                  Contrary to popular belief,
                                                  Lorem Ipsum is not simply
                                                  random text. It has roots in a
                                                  piece of classical Latin
                                                  literature from 45 BC, making
                                                  it over 2000 years old.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Type:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Default</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Status:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Active</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Petrol</td>
                                  <td>Custom</td>
                                  <td>
                                    <span className="blue-bedge">Active</span>
                                  </td>
                                  <td>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-eye" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="expenseTypeclosePopup"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Expense Types Details</h3>
                                          </div>
                                          <div className="content">
                                            <div className="flex flex-wrap mb-4 ">
                                              <div className="w-1/4 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Expense Name:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Petrol</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Description:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>
                                                  Contrary to popular belief,
                                                  Lorem Ipsum is not simply
                                                  random text. It has roots in a
                                                  piece of classical Latin
                                                  literature from 45 BC, making
                                                  it over 2000 years old.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Type:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Custom</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Status:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Active</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-pencil" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="editExpenseclosePopup"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Edit Expense Types</h3>
                                          </div>
                                          <div className="content">
                                            <form>
                                              <div className="flex flex-wrap ">
                                                <div className=" w-full">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      {" "}
                                                      Expense name
                                                    </label>
                                                    <FormInput
                                                      inputType="text"
                                                      inputName="Name"
                                                      inputID="expenseName"
                                                    />
                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                                <div className=" w-full ">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Description
                                                    </label>
                                                    <FormTextarea  inputID="editExpenseDescription"/>
                                                    <div className="help-block" />
                                                  </div>
                                                </div>

                                                <div className="lg:w-1/2 pr-0 w-full">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Status
                                                    </label>
                                                    <select className="formControl ">
                                                      <FormSelect optionValue="Active" selectID="editExpenseStatus"  />
                                                    </select>
                                                    <div className="help-block" />
                                                  </div>
                                                </div>

                                                <div className="lg:w w-full  ">
                                                  <div className="w-full mt-4">
                                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                                      <PrimaryButton
                                                        btnText="Save"
                                                        Btnclass="btn save mr-2"
                                                        btnype="button"
                                                        buttonID="editExpenseSave"
                                                      />
                                                      <PrimaryButton
                                                        btnText="Cancel"
                                                        Btnclass="btn cancel"
                                                        btntype="button"
                                                        buttonID="editExpenseCancel"
                                                        onClick={() => close()}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-trash" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="deleteClosePopup"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            {" "}
                                            <h3>Deleted</h3>
                                          </div>
                                          <div className="content">
                                            <p>
                                              Are you sure you want to delete
                                              this ?
                                            </p>
                                            <div className="w-full mt-8">
                                              <div className="form-group pt-4 pb-3 flex justify-center ">
                                                <button className="btn save mr-2" id="deleteYes">
                                                  Yes
                                                </button>
                                                <button
                                                  type="button"
                                                  id="deleteNo"
                                                  onClick={() => close()}
                                                  className="btn cancel "
                                                >
                                                  No
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="border-t my-6 py-4">
                            <div className="flex w-full justify-between items-center py-4">
                              <h3>Mileage Type</h3>
                              <Popup
                                open={open}
                                onClose={() => closeModal()}
                                closeOnDocumentClick={false}
                                trigger={
                                  <button
                                    className="btn-md btn-add  pull-right "
                                    aria-describedby="popup-8"
                                    id="addMileageType"
                                    onClick={() => setOpen((o) => !o)}
                                  >
                                    <span className="fa fa-plus mr-2" />
                                    Add Mileage Type
                                  </button>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div className="modal">
                                    <button
                                      className="close"
                                      id="mileageClosePopup"
                                      onClick={() => {
                                        closeModal();
                                        close();
                                      }}
                                    >
                                      &times;
                                    </button>
                                    <div className="header">
                                      <h3>Add Mileage Type</h3>
                                    </div>
                                    <div className="content">
                                      <form>
                                        <div className="flex flex-wrap ">
                                          <div className=" w-full md:w-1/2 md:pr-6">
                                            <div className="form-group mb-6 ">
                                              <label className="formBlock	">
                                                Mileage Expense name
                                              </label>
                                              <FormInput
                                                inputType="text"
                                                inputName="Name"
                                                inputID="mileageName"

                                              />
                                              <div className="help-block" />
                                            </div>
                                          </div>
                                          <div className=" w-full md:w-1/2 ">
                                            <div className="form-group mb-6 ">
                                              <label className="formBlock	">
                                                Pence per mile
                                              </label>
                                              <FormInput
                                                inputType="text"
                                                inputName="Name"
                                                inputID="pencePerMile"
                                              />
                                              <div className="help-block" />
                                            </div>
                                          </div>
                                          <div className=" w-full ">
                                            <div className="form-group mb-6 ">
                                              <label className="formBlock	">
                                                Description
                                              </label>
                                              <FormTextarea inputID="addMileageDescription"/>
                                              <div className="help-block" />
                                            </div>
                                          </div>

                                          <div className="lg:w-1/2 pr-0 w-full">
                                            <div className="form-group mb-6 ">
                                              <label className="formBlock	">
                                                Status
                                              </label>
                                              <select className="formControl " id="addMileageStatus">
                                                <FormSelect optionValue="Active" />
                                              </select>
                                              <div className="help-block" />
                                            </div>
                                          </div>

                                          <div className="lg:w w-full  ">
                                            <div className="w-full mt-4">
                                              <div className="form-group pt-4 pb-3 flex justify-center ">
                                                <PrimaryButton
                                                  btnText="Save"
                                                  Btnclass="btn save mr-2"
                                                  btnype="button"
                                                  id="addMileageSave"
                                                />
                                                <PrimaryButton
                                                  btnText="Cancel"
                                                  Btnclass="btn cancel"
                                                  btntype="button"
                                                  id="addMileageCancelSave"
                                                  onClick={() => close()}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </div>
                            <div className="overflow-x-auto w-full whitespace-nowrap">
                              <table className="table-fixed table  w-full">
                                <thead className="thead-dark ">
                                  <tr>
                                    <th className="w-50"> Name</th>
                                    <th>Type</th>
                                    <th>Status</th>

                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Parking</td>
                                    <td>Default</td>
                                    <td>
                                      <span className="blue-bedge">Active</span>
                                    </td>
                                    <td>
                                      <Popup
                                        open={open}
                                        onClose={() => closeModal()}
                                        closeOnDocumentClick={false}
                                        trigger={
                                          <div
                                            onClick={() => setOpen((o) => !o)}
                                            className="actionBtn"
                                          >
                                            <i className="fa fa-eye" />
                                          </div>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="modal">
                                            <button
                                              className="close"
                                              id="mileageDetailClose"
                                              onClick={() => {
                                                closeModal();
                                                close();
                                              }}
                                            >
                                              &times;
                                            </button>
                                            <div className="header">
                                              <h3>Mileage Type Details</h3>
                                            </div>
                                            <div className="content">
                                              <div className="flex flex-wrap mb-4 ">
                                                <div className="w-1/4 pr-2">
                                                  <h4 className="text-[ 14px ]">
                                                    Mileage Expense Name:
                                                  </h4>
                                                </div>
                                                <div className="w-3/4">
                                                  <p>Parking</p>
                                                </div>
                                              </div>
                                              <div className="flex flex-wrap mb-4">
                                                <div className="w-1/4 pr-2">
                                                  <h4 className="text-[ 14px ]">
                                                    Description:
                                                  </h4>
                                                </div>
                                                <div className="w-3/4">
                                                  <p>
                                                    Contrary to popular belief,
                                                    Lorem Ipsum is not simply
                                                    random text. It has roots in
                                                    a piece of classical Latin
                                                    literature from 45 BC,
                                                    making it over 2000 years
                                                    old.
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="flex flex-wrap mb-4">
                                                <div className="w-1/4  pr-2">
                                                  <h4 className="text-[ 14px ]">
                                                    Type:
                                                  </h4>
                                                </div>
                                                <div className="w-3/4">
                                                  <p>Default</p>
                                                </div>
                                              </div>
                                              <div className="flex flex-wrap mb-4">
                                                <div className="w-1/4  pr-2">
                                                  <h4 className="text-[ 14px ]">
                                                    Status:
                                                  </h4>
                                                </div>
                                                <div className="w-3/4">
                                                  <p>Active</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Petrol</td>
                                    <td>Custom</td>
                                    <td>
                                      <span className="blue-bedge">Active</span>
                                    </td>
                                    <td>
                                      <Popup
                                        open={open}
                                        onClose={() => closeModal()}
                                        closeOnDocumentClick={false}
                                        trigger={
                                          <div
                                            onClick={() => setOpen((o) => !o)}
                                            className="actionBtn"
                                          >
                                            <i className="fa fa-eye" />
                                          </div>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="modal">
                                            <button
                                              className="close"
                                              id="mileageTypeClose"
                                              onClick={() => {
                                                closeModal();
                                                close();
                                              }}
                                            >
                                              &times;
                                            </button>
                                            <div className="header">
                                              <h3>Mileage Types Details</h3>
                                            </div>
                                            <div className="content">
                                              <div className="flex flex-wrap mb-4 ">
                                                <div className="w-1/4 pr-2">
                                                  <h4 className="text-[ 14px ]">
                                                    Mileage Types Name:
                                                  </h4>
                                                </div>
                                                <div className="w-3/4">
                                                  <p>Petrol</p>
                                                </div>
                                              </div>
                                              <div className="flex flex-wrap mb-4">
                                                <div className="w-1/4 pr-2">
                                                  <h4 className="text-[ 14px ]">
                                                    Description:
                                                  </h4>
                                                </div>
                                                <div className="w-3/4">
                                                  <p>
                                                    Contrary to popular belief,
                                                    Lorem Ipsum is not simply
                                                    random text. It has roots in
                                                    a piece of classical Latin
                                                    literature from 45 BC,
                                                    making it over 2000 years
                                                    old.
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="flex flex-wrap mb-4">
                                                <div className="w-1/4  pr-2">
                                                  <h4 className="text-[ 14px ]">
                                                    Type:
                                                  </h4>
                                                </div>
                                                <div className="w-3/4">
                                                  <p>Custom</p>
                                                </div>
                                              </div>
                                              <div className="flex flex-wrap mb-4">
                                                <div className="w-1/4  pr-2">
                                                  <h4 className="text-[ 14px ]">
                                                    Status:
                                                  </h4>
                                                </div>
                                                <div className="w-3/4">
                                                  <p>Active</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                      <Popup
                                        open={open}
                                        onClose={() => closeModal()}
                                        closeOnDocumentClick={false}
                                        trigger={
                                          <div
                                            onClick={() => setOpen((o) => !o)}
                                            className="actionBtn"
                                          >
                                            <i className="fa fa-pencil" />
                                          </div>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="modal">
                                            <button
                                              className="close"
                                              id="editExpenseClosePopup"
                                              onClick={() => {
                                                closeModal();
                                                close();
                                              }}
                                            >
                                              &times;
                                            </button>
                                            <div className="header">
                                              <h3>Edit Expense Types</h3>
                                            </div>
                                            <div className="content">
                                              <form>
                                                <div className="flex flex-wrap ">
                                                  <div className=" w-full">
                                                    <div className="form-group mb-6 ">
                                                      <label className="formBlock	">
                                                        {" "}
                                                        Expense name
                                                      </label>
                                                      <FormInput
                                                        inputType="text"
                                                        inputName="Name"
                                                        inputID="nameEditExpense"
                                                        
                                                      />
                                                      <div className="help-block" />
                                                    </div>
                                                  </div>
                                                  <div className=" w-full ">
                                                    <div className="form-group mb-6 ">
                                                      <label className="formBlock	">
                                                        Description
                                                      </label>
                                                      <FormTextarea  inputID="descriptionEditExpense"/>
                                                      <div className="help-block" />
                                                    </div>
                                                  </div>

                                                  <div className="lg:w-1/2 pr-0 w-full">
                                                    <div className="form-group mb-6 ">
                                                      <label className="formBlock	">
                                                        Status
                                                      </label>
                                                      <select className="formControl " id="statusEditExpense">
                                                        <FormSelect optionValue="Active" />
                                                      </select>
                                                      <div className="help-block" />
                                                    </div>
                                                  </div>

                                                  <div className="lg:w w-full  ">
                                                    <div className="w-full mt-4">
                                                      <div className="form-group pt-4 pb-3 flex justify-center ">
                                                        <PrimaryButton
                                                          btnText="Save"
                                                          Btnclass="btn save mr-2"
                                                          btnype="button"
                                                          buttonID="saveEditExpense"
                                                        />
                                                        <PrimaryButton
                                                          btnText="Cancel"
                                                          Btnclass="btn cancel"
                                                          btntype="button"
                                                          buttonID="cancelEditExpense"
                                                          onClick={() =>
                                                            close()
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                      <Popup
                                        open={open}
                                        onClose={() => closeModal()}
                                        closeOnDocumentClick={false}
                                        trigger={
                                          <div
                                            onClick={() => setOpen((o) => !o)}
                                            className="actionBtn"
                                          >
                                            <i className="fa fa-trash" />
                                          </div>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="modal">
                                            <button
                                              className="close"
                                              id="closeDeleteEdit"
                                              onClick={() => {
                                                closeModal();
                                                close();
                                              }}
                                            >
                                              &times;
                                            </button>
                                            <div className="header">
                                              {" "}
                                              <h3>Deleted</h3>
                                            </div>
                                            <div className="content">
                                              <p>
                                                Are you sure you want to delete
                                                this ?
                                              </p>
                                              <div className="w-full mt-8">
                                                <div className="form-group pt-4 pb-3 flex justify-center ">
                                                  <button className="btn save mr-2"  id="yesEditExpense">
                                                    Yes
                                                  </button>
                                                  <button
                                                    type="button"
                                                    id="noEditExpense"
                                                    onClick={() => close()}
                                                    className="btn cancel "
                                                  >
                                                    No
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="w-full mt-20">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button 
                            id="back"
                              className="btn cancel mr-2 text-center"
                              onClick={() => toggleTab("Settings")}
                            >
                              Back
                            </button>
                            <button
                            id="skip"
                              className="btn save mr-2 text-center"
                              onClick={() => toggleTab("Overtime")}
                            >
                              Skip
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        toggleWelState === "Overtime"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <div className="onboardContent">
                        <div className="heading_title mb-6 pb-2">
                          <h2 className="text-2xl mb-3 font-semibold  ">
                            Overtime Types
                          </h2>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old.
                          </p>
                        </div>
                        <div className="card p-4">
                          <div className="flex w-full justify-end">
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <button
                                  className="btn-md btn-add  pull-right mb-4"
                                  aria-describedby="popup-8"
                                  id="AddovertimeTypes"
                                  onClick={() => setOpen((o) => !o)}
                                >
                                  <span className="fa fa-plus mr-2" />
                                  Add Overtime Types
                                </button>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="overtimeTypesClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    <h3>Add Overtime Types</h3>
                                  </div>
                                  <div className="content">
                                    <form>
                                      <div className="flex flex-wrap ">
                                        <div className=" w-full lg:w-1/2 lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Name
                                            </label>
                                            <FormInput
                                              inputType="text"
                                              inputName="Name"
                                              inputID="overtimesName"
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className=" w-full lg:w-1/2 ">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Color
                                            </label>
                                            <FormInput
                                              inputType="color"
                                              inputName="Name"
                                              inputID="overtimeColor"
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>

                                        <div className="lg:w-1/2 lg:pr-6 w-full">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Status
                                            </label>
                                            <select className="formControl " id="overtimeStatus">
                                              <FormSelect optionValue="Active" />
                                            </select>
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w-1/2 pr-0 w-full">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Available to
                                            </label>
                                            <select className="formControl "  id="overtimeAvailable">
                                              <FormSelect optionValue="Employee" />
                                            </select>
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="md:w-1/2 pr-0">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock flex	">
                                              <input
                                                type="checkbox"
                                                id="allowTimeRequest"
                                                className="mr-2 w-5 h-5"
                                              />
                                              Allow time in lieu on request
                                            </label>

                                            <div className="help-block" />
                                          </div>
                                        </div>

                                        <div className="lg:w w-full  ">
                                          <div className="w-full mt-4">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <PrimaryButton
                                                btnText="Save"
                                                Btnclass="btn save mr-2"
                                                btnype="button"
                                                buttonID="overtimeSave"
                                              />
                                              <PrimaryButton
                                                btnText="Cancel"
                                                Btnclass="btn cancel"
                                                btntype="button"
                                                buttonID="overtimeCancel"
                                                onClick={() => close()}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                          <div className="overflow-x-auto w-full whitespace-nowrap">
                            <table className="table-fixed table  w-full">
                              <thead className="thead-dark ">
                                <tr>
                                  <th className="w-50"> Name</th>
                                  <th>Color</th>
                                  <th>Type</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>John</td>
                                  <td>#000</td>
                                  <td>Default</td>
                                  <td>
                                    <span className="blue-bedge">Active</span>
                                  </td>
                                  <td>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-eye" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="overtimeDetailClose"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Overtime Types Details</h3>
                                          </div>
                                          <div className="content">
                                            <div className="flex flex-wrap mb-4 ">
                                              <div className="w-1/4 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Name:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>John</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Color:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>#000</p>
                                              </div>
                                            </div>

                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Status:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Active</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Available to:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Manager</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Lee</td>
                                  <td>#333</td>
                                  <td>Custom</td>
                                  <td>
                                    <span className="blue-bedge">Active</span>
                                  </td>
                                  <td>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-eye" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="overtimeTypeClosePopup"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Overtime Types Details</h3>
                                          </div>
                                          <div className="content">
                                            <div className="flex flex-wrap mb-4 ">
                                              <div className="w-1/4 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Name:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Lee</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Color:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>#333</p>
                                              </div>
                                            </div>

                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Status:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Active</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                              <div className="w-1/4  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Available to:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Manager</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-pencil" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="editOvertimeClose"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Edit Overtime Types</h3>
                                          </div>
                                          <div className="content">
                                            <form>
                                              <div className="flex flex-wrap ">
                                                <div className=" w-full lg:w-1/2 lg:pr-6">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Name
                                                    </label>
                                                    <FormInput
                                                      inputType="text"
                                                      inputName="Name"
                                                      inputID="NameEditOvertime"
                                                    />
                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                                <div className=" w-full lg:w-1/2 ">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Color
                                                    </label>
                                                    <FormInput
                                                      inputType="color"
                                                      inputName="Name"
                                                      inputID="colorEditOvertime"
                                                    />
                                                    <div className="help-block" />
                                                  </div>
                                                </div>

                                                <div className="lg:w-1/2 lg:pr-6 w-full">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Status
                                                    </label>
                                                    <select className="formControl " id="editOverTimeStatus">
                                                      <FormSelect optionValue="Active" />
                                                    </select>
                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                                <div className="lg:w-1/2 pr-0 w-full">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Available to
                                                    </label>
                                                    <select className="formControl " id="availableEditOvertime">
                                                      <FormSelect optionValue="Employee" />
                                                    </select>
                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                                <div className="md:w-1/2 pr-0">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock flex	">
                                                      <input
                                                        type="checkbox"
                                                        id="allowRequestOvertime"
                                                        className="mr-2 w-5 h-5"
                                                      />
                                                      Allow time in lieu on
                                                      request
                                                    </label>

                                                    <div className="help-block" />
                                                  </div>
                                                </div>

                                                <div className="lg:w w-full  ">
                                                  <div className="w-full mt-4">
                                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                                      <PrimaryButton
                                                        btnText="Save"
                                                        Btnclass="btn save mr-2"
                                                        btnype="button"
                                                        buttonID="saveEditOvertimeType"
                                                      />
                                                      <PrimaryButton
                                                        btnText="Cancel"
                                                        Btnclass="btn cancel"
                                                        btntype="button"
                                                        buttonID="cancelEditOvertimeType"
                                                        onClick={() => close()}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-trash" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            {" "}
                                            <h3>Deleted</h3>
                                          </div>
                                          <div className="content">
                                            <p>
                                              Are you sure you want to delete
                                              this ?
                                            </p>
                                            <div className="w-full mt-8">
                                              <div className="form-group pt-4 pb-3 flex justify-center ">
                                                <button className="btn save mr-2" id="yesEditOvertimeDelete">
                                                  Yes
                                                </button>
                                                <button
                                                  type="button"
                                                  id="noEditOvertimeDelete"
                                                  onClick={() => close()}
                                                  className="btn cancel "
                                                >
                                                  No
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="w-full mt-20">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button
                              className="btn cancel mr-2 text-center" 
                              id="settingBack"
                              onClick={() => toggleTab("Settings")}
                            >
                              Back
                            </button>
                            <button
                              className="btn save mr-2 text-center"
                              id="overtimeSkip"
                              onClick={() => toggleTab("Overtime")}
                            >
                              Skip
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        toggleWelState === "Departments"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <div className="onboardContent">
                        <div className="heading_title mb-6 pb-2">
                          <h2 className="text-2xl mb-3 font-semibold  ">
                            Departments
                          </h2>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin
                          </p>
                        </div>
                        <div className="card p-4">
                          <div className="flex w-full justify-end">
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <button
                                  className="btn-md btn-add  pull-right mb-4"
                                  aria-describedby="popup-8"
                                  id="addDepartmentPopup"
                                  onClick={() => setOpen((o) => !o)}
                                >
                                  <span className="fa fa-plus mr-2" />
                                  Add Departments
                                </button>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="closeAddDepartment"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    <h3>Add Departments</h3>
                                  </div>
                                  <div className="content">
                                    <form onSubmit={formSubmit}>
                                      <div className="flex flex-wrap ">
                                        <div className="w-full md:w-1/2 md:pr-6">
                                          <div className="form-group mb-6">
                                            <label className="formBlock	">
                                              Department Name{" "}
                                              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                                            </label>
                                            <input
                                              id="departmentName"
                                              name="price"
                                              type="text"
                                              className="formControl"
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="w-full md:w-1/2 pr-0">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Department Manager(Optional)
                                            </label>
                                            <select className="formControl" id="departmentManager">
                                              <option>Sahion</option>
                                            </select>

                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="w-full md:w-1/2 md:pr-6">
                                          <div className="form-group mb-6">
                                            <label className="formBlock	">
                                              Maximum employees off at once{" "}
                                              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                                            </label>
                                            <input
                                              id="maximumEmployees"
                                              name="price"
                                              type="text"
                                              className="formControl"
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="w-full md:w-1/2 pr-0">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Color{" "}
                                              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                                            </label>
                                            <input
                                              id="departmentColor"
                                              name="color"
                                              type="color"
                                              className="formControl"
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="w-full md:w-1/2 md:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Status{" "}
                                              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                                            </label>
                                            <select className="formControl" id="departmentStatus">
                                              <option>Active</option>
                                            </select>

                                            <div className="help-block" />
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                          <div className="overflow-x-auto w-full whitespace-nowrap">
                            <table className="table-auto table  w-full">
                              <thead className="thead-dark ">
                                <tr>
                                  <th className="w-50"> Department</th>
                                  <th>Color</th>
                                  <th>Type</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Accountant</td>
                                  <td>#000</td>
                                  <td>Default</td>
                                  <td>
                                    <span className="blue-bedge">Active</span>
                                  </td>
                                  <td>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-eye" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="departmentDetailClose"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Departments Details</h3>
                                          </div>
                                          <div className="content">
                                            <div className="flex flex-wrap md:flex-nowrap mb-4 ">
                                              <div className="w-1/3  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Department Name:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Accountant</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap md:flex-nowrap mb-4 ">
                                              <div className="w-1/3 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Short Name:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Acc</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap md:flex-nowrap mb-4 ">
                                              <div className="w-1/3 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Department Manager:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Lee</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap md:flex-nowrap mb-4">
                                              <div className="w-1/3 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Color:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>#333</p>
                                              </div>
                                            </div>

                                            <div className="flex flex-wrap md:flex-nowrap mb-4">
                                              <div className="w-1/3  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Status:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Active</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap md:flex-nowrap mb-4">
                                              <div className="w-1/3  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Maximum employees off at once:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>04</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Human Resource</td>
                                  <td>#333</td>
                                  <td>Custom</td>
                                  <td>
                                    <span className="blue-bedge">Active</span>
                                  </td>
                                  <td>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-eye" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="departmentDetailClose2"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Departments Details</h3>
                                          </div>
                                          <div className="content">
                                            <div className="flex flex-wrap md:flex-nowrap mb-4 ">
                                              <div className="w-1/3  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Department Name:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Human Resource </p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap md:flex-nowrap mb-4 ">
                                              <div className="w-1/3 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Short Name:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Hr</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap md:flex-nowrap mb-4 ">
                                              <div className="w-1/3 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Department Manager:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Lee</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap md:flex-nowrap mb-4">
                                              <div className="w-1/3 pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Color:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>#333</p>
                                              </div>
                                            </div>

                                            <div className="flex flex-wrap md:flex-nowrap mb-4">
                                              <div className="w-1/3  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Status:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>Active</p>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap md:flex-nowrap mb-4">
                                              <div className="w-1/3  pr-2">
                                                <h4 className="text-[ 14px ]">
                                                  Maximum employees off at once:
                                                </h4>
                                              </div>
                                              <div className="w-3/4">
                                                <p>04</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                          
                                        >
                                          <i className="fa fa-pencil" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="editDepartmentClose"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3>Edit Departments</h3>
                                          </div>
                                          <div className="content">
                                            <form onSubmit={formSubmit}>
                                              <div className="flex flex-wrap ">
                                                <div className="w-full md:w-1/2 md:pr-6">
                                                  <div className="form-group mb-6">
                                                    <label className="formBlock	">
                                                      Department Name{" "}
                                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                                                    </label>
                                                    <input
                                                      id="departmentNameEdit"
                                                      name="price"
                                                      type="text"
                                                      className="formControl"
                                                    />
                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                                <div className="w-full md:w-1/2 pr-0">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Department
                                                      Manager(Optional)
                                                    </label>
                                                    <select className="formControl" id="departmentManagerEdit">
                                                      <option>Sahion</option>
                                                    </select>

                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                                <div className="w-full md:w-1/2 md:pr-6">
                                                  <div className="form-group mb-6">
                                                    <label className="formBlock	">
                                                      Maximum employees off at
                                                      once{" "}
                                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                                                    </label>
                                                    <input                                                     
                                                      name="price"
                                                      id="EditDepartmentEmployees"
                                                      type="text"
                                                      className="formControl"
                                                    />
                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                                <div className="w-full md:w-1/2 pr-0">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Color{" "}
                                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                                                    </label>
                                                    <input
                                                      id="departmentEditcolor"

                                                      name="color"
                                                      type="color"
                                                      className="formControl"
                                                    />
                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                                <div className="w-full md:w-1/2 md:pr-6">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Status{" "}
                                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                                                    </label>
                                                    <select className="formControl" id="editDepartmentStatus">
                                                      <option>Active</option>
                                                    </select>

                                                    <div className="help-block" />
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <div
                                          onClick={() => setOpen((o) => !o)}
                                          className="actionBtn"
                                        >
                                          <i className="fa fa-trash" />
                                        </div>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="closeEditDepartment"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            {" "}
                                            <h3>Deleted</h3>
                                          </div>
                                          <div className="content">
                                            <p>
                                              Are you sure you want to delete
                                              this ?
                                            </p>
                                            <div className="w-full mt-8">
                                              <div className="form-group pt-4 pb-3 flex justify-center ">
                                                <button className="btn save mr-2" id="yesEditDepartment">
                                                  Yes
                                                </button>
                                                <button
                                                  type="button"
                                                  onClick={() => close()}
                                                  id="noEditDepartment"
                                                  className="btn cancel "
                                                >
                                                  No
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="w-full mt-20">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button
                              className="btn cancel mr-2 text-center"
                              onClick={() => toggleTab("Overtime")}
                              id="buttonBack"
                            >
                              Back
                            </button>
                            <Link to="/dashboard">
                              {" "}
                              <button className="btn save mr-2 text-center" id="getStarted">
                                Get Started
                              </button>
                            </Link>
                            {/* setOpenBoard(true) */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default OnBoard;
