import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';

export const restrictedHolidayApi = createApi({
    reducerPath: "restrictedHolidayAPI",
    baseQuery: fetchBaseQuery({
      baseUrl: ApiUrl
}),
endpoints: (builder) => ({
getAllRestrictedHoliday:builder.mutation({
      query: (page) => ({
        url: `/holiday/?is_restricted=1&page=${page}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
createRestrictedHoliday: builder.mutation({
      query: (payload) => ({
        url: '/holiday',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
  }),
  updateRestrictedHoliday: builder.mutation({
    query: (payload) => {
       const { id, ...body } = payload;
      return {
        url: `/holiday/${id}`,
        method: 'PUT',
        body,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      };
    },
    invalidatesTags: [ 'Post' ]
  }),
  deleteRestrictedHoliday: builder.mutation({
    query: (id) => ({
      url: `/holiday/${id}`,
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
  }) 
})
});

export const { useGetAllRestrictedHolidayMutation, useCreateRestrictedHolidayMutation, useUpdateRestricedHolidayMutation, useDeleteRestricedHolidayMutation} = restrictedHolidayApi;