/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState, useEffect, useRef, useMemo } from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import NoDataFound from "../../../helper/noDataFound";
import { FormTextarea } from "../../../components/formInput";
import PrimaryButton from "../../../components/primaryButton";
import { toast } from "react-toastify";
import { useBulkExpenseApporveMutation, useChangesStatusExpenseApporveMutation } from "../../../services/approverListApi";
import moments from "moment";
import Pagination from "../../../components/inc/pagination";
import ExpenseDetails from './ExpenseDetails';
import ExpenseCancel from "./ExpenseCancel";
import { LoadingIndicator } from "../../../components/loading-indicator";
import { useSelector } from "react-redux";

const ExpenseToApprove = ({ allExpenseToApprove, employeeExpenseApprover, employeeId, expenseLoading}) => {

    const [ changesStatusExpenseApporve, { isSuccess: isSuccessStatus, isError: isErrorStatus, isLoading: isLoadingStatus } ] = useChangesStatusExpenseApporveMutation();
    const [ bulkExpenseApporve, { isSuccess, isError, isLoading } ] = useBulkExpenseApporveMutation();
    const [ open, setOpen ] = useState(false);
    const toastId = useRef(null);
    const [ page, setPage ] = useState(1);
    const [ pages, setPages ] = useState(0);
    const [selectedExpenses, setSelectedExpenses] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [showActionButtons, setShowActionButtons] = useState(false);
    const [ approvedList, setApprovedList ] = useState();
    const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
    const pageSize = 10 ;
    const [ formData, setFormData ] = useState({
      notes:""
    });
    const closeModal = () => {
    setOpen(false);
    };
    
    useEffect(() => {
      if(allExpenseToApprove) {
        let totalPages = Math.ceil(allExpenseToApprove.length /pageSize);
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allExpenseToApprove?.slice(firstPageIndex, lastPageIndex);
        setApprovedList(data);
        setPages(totalPages);
      }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allExpenseToApprove ]);
        const currentTableData = useMemo(() => {
          const firstPageIndex = (page - 1) * pageSize;
          const lastPageIndex = firstPageIndex + pageSize;
          const data = allExpenseToApprove?.slice(firstPageIndex, lastPageIndex);
          setApprovedList(data);
        
        }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ page ]);
  
    const decrementPage = () => {
      const lastPageIndex = (page-1) * pageSize;
      const firstPageIndex = lastPageIndex - pageSize;
      const data = allExpenseToApprove?.slice( lastPageIndex, firstPageIndex);
      setApprovedList(data);
    };
    const handlerChange = (e) => {
      e.preventDefault();
      const {name} = e.target;
      setFormData({...formData, [name]: e.target.value});
    };
  
    useEffect(() => {
      if(isLoadingStatus){
        toastId.current = toast.loading("Please wait...", {
          // position: "top-center",
          position:"top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if(isSuccessStatus) {
        toast.dismiss(toastId.current);
        toast.update(
          toastId.current,
          ("Expense approved successfully!",
          {
            render: "Expense approved successfully!",
            type: "success",
            autoClose: 8000,
            isLoading: false
          })
        );
        let payload = `/expenses/filter/search/?expense_approver=${employeeId}&page=1&limit=100`;
        employeeExpenseApprover(payload);
      }
      if (isErrorStatus) {
        toast.dismiss(toastId.current);
        const toastMessage = 
          "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 8000,
            isLoading: false
          })
        );
      }
    }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccessStatus, isErrorStatus, isLoadingStatus ]);

  useEffect(() => {
    if(isLoading){
      toastId.current = toast.loading("Please wait...", {
        // position: "top-center",
        position:"top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if(isSuccess) {
      toast.dismiss(toastId.current);
      toast.update(
        toastId.current,
        ("Expenses approved/rejected successfully!",
        {
          render: "Expenses approved/rejected successfully!",
          type: "success",
          autoClose: 8000,
          isLoading: false
        })
      );
      let payload = `/expenses/filter/search/?expense_approver=${employeeId}&page=1&limit=100`;
      employeeExpenseApprover(payload);
      setSelectedExpenses([]);
      setSelectAll(false);
      setShowActionButtons(false);
    }
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage = 
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = approvedList.map((expense) => expense.id);
      setSelectedExpenses(allIds);
      setShowActionButtons(true);
    } else {
      setSelectedExpenses([]);
      setShowActionButtons(false);
    }
    setSelectAll(isChecked);
  };
  
  const handleSelectExpense = (e, id) => {
    if (e.target.checked) {
      setSelectedExpenses((prev) => [...prev, id]);
      setShowActionButtons(true);
    } else {
      const updatedExpenses = selectedExpenses.filter((expenseId) => expenseId !== id);
      setSelectedExpenses(updatedExpenses);
      if (updatedExpenses.length === 0) {
        setShowActionButtons(false);
      }
    }
  };  
  
  const onHandlerApporve = (e, expense) => {
      e.preventDefault();
      const value = {
        status: '1',
        employee_id: `${expense?.employee_id}`,
        notes:formData?.notes,
        id: expense?.id
    };
    changesStatusExpenseApporve(value);
    setFormData("");
    };

    const onHandlerBulkApporve = (e) => {
      e.preventDefault();
      const val = {
        notes: "Approved",
        status: "1",
        expenseIds: selectedExpenses
      };  
      bulkExpenseApporve(val);
      setFormData("");
    };

    const onHandlerBulkReject = (e) => {
      e.preventDefault();
      const val = {
        notes: "Rejected",
        status: "3",
        expenseIds: selectedExpenses
      };  
      bulkExpenseApporve(val);
      setFormData("");
    };
    
  return (
    <div>
      {showActionButtons && (
        <div className="action-buttons space-x-2 mb-4">
          <button
            className="btn save"
            onClick={onHandlerBulkApporve}
            disabled={selectedExpenses.length === 0}
          >
            Approve
          </button>
          <button
            className="btn save"
            onClick={onHandlerBulkReject}
            disabled={selectedExpenses.length === 0}
          >
            Reject
          </button>
          <button
            className="btn cancel"
            onClick={() => {
              setSelectedExpenses([]);
              setSelectAll(false);
              setShowActionButtons(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
      <div className="overflow-x-auto w-full whitespace-nowrap">
                <table className="table-auto table  w-full">
                  <thead className="thead-dark ">
                    <tr>
                    {allExpenseToApprove?.length > 0 ? ( 
                      <>
                      <th>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                      />
                    </th>
                    </> ) : ("")}
                      <th className="w-40"> Employee</th>
                      <th>Expenses Type</th>
                      <th>Date Applied</th>
                      <th>Date of Spend</th>
                      <th>Amount</th>
                      <th>Attachments</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {expenseLoading ?<LoadingIndicator/> :
                  <tbody>
                  {allExpenseToApprove?.length > 0 ?                      
                  <>
                  {approvedList && approvedList.map((expense, index) => {
                    const fileName = expense?.expenseFiles[0]?.file_name;
                    const handleImageClick = () => {
                      // Check if fileName exists
                      if (fileName) {
                        // Create a link element
                        const downloadLink = document.createElement('a');
                        // Set the href attribute to the file path
                        downloadLink.href = fileName;
                        // Set the download attribute with the desired file name
                        downloadLink.download = 'your_desired_file_name.extension';
                        // Append the link to the document
                        document.body.appendChild(downloadLink);
                        // Trigger a click on the link element
                        downloadLink.click();
                        // Remove the link from the document
                        document.body.removeChild(downloadLink);
                      }
                    };
                    return (
                    <tr key={index}>
                       <td>
                        <input
                          type="checkbox"
                          checked={selectedExpenses.includes(expense.id)}
                          onChange={(e) => handleSelectExpense(e, expense.id)}
                        />
                      </td>
                      <td>
                        <Link
                          to={`/employees/detail/${expense?.employee_id}`}
                          className="listImage flex items-center "
                        >
                          {`${expense?.employeeInfo?.first_name} ${expense?.employeeInfo?.last_name}`}
                        </Link>
                      </td>
                      <td>{expense?.expenseTypeInfo?.expense_name}</td>
                      <td>{moments(expense?.createdAt).format(formattedDate)}</td>
                      <td>{moments(expense?.start_date).format(formattedDate)}</td>
                      <td>£{expense?.amount}</td>
                      <td>
                        {fileName ? (
                          <div>
                            <img
                              src={fileName}
                              alt="Expense Image"
                              onClick={handleImageClick}
                              style={{ cursor: 'pointer', width: '80px', height: '44px', border: '1px solid grey', objectFit:'contain' }}
                            />
                            <p className="font-semibold">Download</p>
                          </div>
                        ) : (
                          "No File"
                        )}
                      </td>
                      <td>
                      <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa fa-eye" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="expenseApproveClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <ExpenseDetails expense={expense}/>
                            </div>
                          )}
                        </Popup>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa-solid fa-check" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="expenseEditApproveClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <div className="header">
                                {" "}
                                <h3>Approve</h3>
                              </div>
                              <div className="content">
                                <label className="formBlock">Notes 
                                  {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                                </label>
                                <FormTextarea textName="notes" handlerChange = {handlerChange} inputID="notesApprove" />
                                <div className="w-full mt-8">
                                  <div className="form-group pt-4 pb-3 flex justify-center ">
                                    <button
                                      type="submit"
                                      className="btn save mr-2"
                                      id="SubmitApprove"
                                      onClick={(e) => onHandlerApporve(e, expense, close)}
                                    >
                                      Approve
                                    </button>
                                    <PrimaryButton
                                      btnText="Cancel"
                                      Btnclass="btn cancel"
                                      btntype="button"
                                      buttonID="CancelApprove"
                                      onClick={() => close()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>

                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn cancelBtn"
                            >
                              <i className="fa-solid fa-xmark" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="approveCancelClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <ExpenseCancel expense={expense}
                                employeeExpenseApprover={ employeeExpenseApprover}
                                employeeId={employeeId}
                                close={close}
                              />
                            </div>
                          )}
                        </Popup>
                      </td>
                    </tr>
                    );
                    })}
                    </> : 
                    <center className="mt-4">{<NoDataFound/>}</center>}
                  </tbody>}
                </table>
                <Pagination page={page} pages={pages} changePage={setPage} decrementPage={setPage}/>
              </div>
    </div>
  );
};

export default ExpenseToApprove;
