import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ModifiedToast } from "./toaster";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  useGetPlanMutation,
  useSendOtpMutation,
  useSignupUserMutation,
  useVerifyUserMutation
} from "../services/authApi";
import Popup from "reactjs-popup";
import { FormInput } from "./formInput";
import OtpInput from "react-otp-input";
import OnBoard from "./onBoard";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { emailValidator, passwordValidator, nameValidator } from "../pages/settings/validation";
import { allUserPermissions, userInfo } from "../redux/AuthSlice/authSlice";
import { openJoyce } from "../redux/JoyiceSlice/joyiceSlice";
import backgroundImage from '../loginBg.webp';

const SignUp = () => {
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    password: "",
    mobileNo: "",
    countryCode: "",
    otp: "",
    otpId: "",
    confirmPassword: "",
    checkbox: false,
    hearAboutUs: ""
  });

  const [error, setError] = useState({
    firstNameError: "",
    lastNameError: "",
    companyNameError: "",
    emailError: "",
    passwordError: "",
    mobileNoError: "",
    confirmPasswordError: "",
    checkboxError: ""
  });

  const [ timer, setTimer ] = useState(90);
  const [ isRunning, setIsRunning ] = useState(false);
  const [ showResend, setShowResend ] = useState(false);
  const [ passwordValid, setPasswordValid ] = useState(false);

  useEffect(() => {
    let interval;

    if (isRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setIsRunning(false);
      setShowResend(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isRunning, timer]);

  const [signupUser, { data, isLoading, isError, isSuccess, error: apiError }] = useSignupUserMutation();
  const [sendOtp, { data: otpData, isSuccess: isOtpSuccess }] = useSendOtpMutation();
  const [verifyUser, { isSuccess: isUserVerified, isError: isVerifiedError, error: verifiedError }] = useVerifyUserMutation();
  const toastId = useRef(null);
  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccess) {
      toast.update(
        toastId.current,
        ("Thanks for signing up.",
        {
          render: "Thanks for signing up.",
          type: "success",
          autoClose: 8000,
          isLoading: false
        })
      );
      localStorage.setItem("accessToken", data.accessToken);
      let employeeData = JSON.stringify(data);
      localStorage.setItem('employeeData', JSON.stringify(employeeData));
      dispatch(openJoyce({ isJoyce: true, isSignup: true, data: data }));
      dispatch(userInfo(data));
      if (data?.permissions) {
        dispatch(allUserPermissions(data?.permissions));
      }
      closeModal();
      setOpen((o) => !o);
      getplan();
      close();
    }
    if (isError) {
      const toastMessage = apiError?.data?.message
        ? apiError?.data?.message
        : "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isSuccess, isError, isLoading]);
  useEffect(() => {
    if (isUserVerified) {
      let value = {
        "mobile_no": `${formData.mobileNo}`,
        "country_code": `${formData.countryCode}`,
        email: `${formData.email}`
      };
      sendOtp(value);
      document?.getElementById("openOtpModel").click();
    }
    if (isVerifiedError) {
      const toastMessage = verifiedError?.data?.message
        ? verifiedError?.data?.message
        : "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 4000,
        isLoading: false
      });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isUserVerified, isVerifiedError]);
  useEffect(() => {
    if (isOtpSuccess) {
      setFormData({ ...formData, otpId: otpData?.data?.id });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isOtpSuccess]);

  useEffect(() => {
    setFormData({ ...formData, otp: otp });
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[otp]);
  const [getplan, { data: planData }] = useGetPlanMutation();
  const handlerChange = (e) => {
    const { name } = e.target;
    if (name === "firstName") {
      let firstName = nameValidator(name, e.target.value);
      setError({ ...setError, firstNameError: firstName });
    } else if (name === "lastName") {
      let lastName = nameValidator(name, e.target.value);
      setError({ ...setError, lastNameError: lastName });
    } else if (name === "companyName") {
      let companyName = nameValidator(name, e.target.value);
      setError({ ...setError, companyNameError: companyName });
    } else if (name === "email") {
      let email = emailValidator(e.target.value);
      setError({ ...setError, emailError: email });
    } else if (name === "password") {
      let password = passwordValidator(e.target.value);
      setError({ ...setError, passwordError: password });
    } else if (name === "confirmPassword") {
      if (e.target.value !== formData.password) {
        setError({
          ...setError,
          confirmPasswordError: "Confirm Password must be same as the password"
        });
      } else {
        setError({ ...setError, confirmPasswordError: "" });
      }
    }
    setFormData({ ...formData, [name]: e.target.value });
  };

  const closeModal = () => {
    setOpen(false);
  };
  const handelRegisterSubmit = () => {
    signupUser(formData);
    dispatch(openJoyce({ isJoyce: false, isSignup: false }));
  };
  const onChangeMobile = (e) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (phoneRegex.test(e.target.value)) {
      setFormData({ ...formData, mobileNo: e.target.value });
      setError({ ...setError, mobileNoError: "" });
    } else {
      setError({
        ...setError,
        mobileNoError: "Please Enter a valid mobile number"
      });
    }
  };

  const handelCheckbox = () => {
    setFormData({ ...formData, checkbox: !formData.checkbox });
  };

  const handelCountryCode = (e) => {
    const codeRegex = /^\+[0-9]{1,3}$/;
    let isCode = codeRegex.test(e.target.value);
    if (isCode) {
      setFormData({ ...formData, countryCode: e.target.value });
      setError({ ...setError, mobileNoError: "" });
    } else {
      setError({
        ...setError,
        mobileNoError: "Please Enter a valid Country Code"
      });
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglePasswordVisiblity2 = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const validaForm = () => {
    const {
      firstName,
      lastName,
      companyName,
      mobileNo,
      email,
      password,
      confirmPassword,
      countryCode,
      checkbox
    } = formData;
    let formIsValid = true;
    if (firstName === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          firstNameError: "Please enter the first name "
        };
      });
      formIsValid = false;
    }
    if (lastName === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          lastNameError: "Please Enter the last name "
        };
      });
      formIsValid = false;
    }
    if (companyName === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          companyNameError: "Please enter company name "
        };
      });
      formIsValid = false;
    }
    if (email === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          emailError: "Please enter Email"
        };
      });
      formIsValid = false;
    }
    if (password === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          passwordError: "Please enter password"
        };
      });
      formIsValid = false;
    }
    if (confirmPassword === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          confirmPasswordError: "Please enter your password"
        };
      });
      formIsValid = false;
    }
    if (countryCode === "" || countryCode === "+9" || countryCode === "+8" || countryCode === "+7" || countryCode === "+6" ||
      countryCode === "+5" || countryCode === "+4" || countryCode === "+3" || countryCode === "+2" || countryCode === "+1") {
      setError((errorValue) => {
        return {
          ...errorValue,
          mobileNoError: "Please enter Country Code"
        };
      });
      formIsValid = false;
    }
    if (mobileNo === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          mobileNoError: "Please enter Mobile Number"
        };
      });
      formIsValid = false;
    }
    if (!checkbox) {
      setError((errorValue) => {
        return {
          ...errorValue,
          checkboxError: "You must agree to the terms and conditions"
        };
      });
      formIsValid = false;
    }
    for (let errorKey in error) {
      if (error[errorKey]) {
        formIsValid = false;
        break;
      }
    }
    return formIsValid;
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  
    if (validaForm()) {
      verifyUser({
        email: formData?.email,
        mobileNo: formData?.mobileNo,
        companyName: formData?.companyName
      });
  
      setTimer(90);
      setIsRunning(true);
      setShowResend(false);
    }
  };
  const resendOtp = () => {
    let value = {
      "mobile_no": `${formData.mobileNo}`,
      "country_code": `${formData.countryCode}`,
      email: `${formData.email}`
    };
    sendOtp(value);
    setTimer(90);
    setIsRunning(true);
    setShowResend(false);

  };

  const hearAboutUsOptions = [
    { value: "", label: "Select an option", disabled: true },
    { value: "search_engine", label: "search engine (Google, bing etc)" },
    { value: "Recommendation ", label: "Recommendation " },
    { value: "Social_media", label: "Social media" },
    { value: "other", label: "Other" }
  ];

  return (
    <div className=" flex loginGradient w-full" style={{background: `url(${backgroundImage})`}}>
      <HelmetProvider>
        <Helmet>
          <title>Schedulize | Signup </title>
        </Helmet>
      </HelmetProvider>
      <ToastContainer />
      <ModifiedToast />
      <div className=" flex align-center w-full h-full signupPage">
        <div className=" w-full h-full ">
          <div className="md:flex align-center justify-center items-center flex-wrap h-full    ">
            <div className=" lg:p-8 p-4 lg:w-1/2 w-full relative ">
              <div className=" pt-0 w-full">
                <div className="w-full max-w-lg m-auto bg-white lg:p-8 py-5 p-4 border shadow-lg rounded-lg ">
                <div className="text-center  w-full">
                    <img
                      src="../images/logo-full.webp"
                      width="220"
                      alt='logo'
                      className="mx-auto"
                    />
                  </div>
                  <h1 className="text-xl font-semibold my-6 mb-8 text-center">
                    Sign up to your account
                  </h1>
                  <div
                    className="text-left"
                    id="signupForm"
                  >
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-1/2 lg:pr-4">
                        <div className="parentGroup mb-2">
                          <label htmlFor="email" className="formBlock">
                            First Name
                          </label>
                          <FormInput
                            inputType="text"
                            inputName="firstName"
                            inputID="FirstName"
                            value={formData.firstName}
                            handlerChange={handlerChange}
                          />
                          <div className="help-block text-red-700">
                            {error.firstNameError ? error.firstNameError : ""}
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2 pr-0">
                        <div className="parentGroup mb-2">
                          <label htmlFor="email" className="formBlock">
                            Last Name
                          </label>
                          <FormInput
                            inputType="text"
                            inputName="lastName"
                            inputID="LastName"
                            value={formData.lastName}
                            handlerChange={handlerChange}
                          />
                          <div className="help-block text-red-700">
                            {error.lastNameError ? error.lastNameError : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="w-full  mb-2">
                        <label htmlFor="email" className="formBlock">
                          Email
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="email"
                          inputID="email"
                          value={formData.email}
                          handlerChange={handlerChange}
                        />
                        <div className="help-block text-red-700">
                          {error.emailError ? error.emailError : ""}
                        </div>
                      </div>
                      <div className="w-full mb-2">
                        <label htmlFor="email" className="formBlock">
                          Mobile Number
                        </label>
                        <div className="flex items-center">
                          <input
                            type="text"
                            name="countryCode"
                            id="countryCode"
                            onChange={(e) => handelCountryCode(e)}
                            className="appearance-none w-20 bg-gray-100 border rounded text-gray-700 py-2 px-3 focus:outline-none focus:border-gray-500"
                            placeholder="+44"
                          />
                          <input
                            type="text"
                            name="mobileNo"
                            id="mobileNo"
                            onChange={(e) => onChangeMobile(e)}
                            className="formControl flex-1 ml-2"
                            placeholder="Phone Number"
                          />
                        </div>
                        <div className="help-block text-red-700">
                          {error.mobileNoError ? error.mobileNoError : ""}
                        </div>
                      </div>
                    </div>
                    <div className="w-full mb-2">
                      <label htmlFor="email" className="formBlock">
                        Company Name
                      </label>
                      <FormInput
                        inputType="text"
                        inputName="companyName"
                        inputID="CompanyName"
                        value={formData.companyName}
                        handlerChange={handlerChange}
                      />
                      <div className="help-block text-red-700">
                        {error.companyNameError ? error.companyNameError : ""}
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <div className="w-full lg:w-1/2 lg:pr-4 ">
                        <div className="parentGroup mb-2 eyeIcon">
                          <label htmlFor="email" className="formBlock">
                            Password
                          </label>
                          <FormInput
                            inputType={passwordShown ? "text" : "password"}
                            inputName="password"
                            inputID="password"
                            value={formData.password}
                            handlerChange={(e) => {
                              const password = e.target.value;
                              const isValid = password.length >= 8 && password.length <= 50;
                              setPasswordValid(isValid);
                              handlerChange(e); 
                            }}
                          />
                          <i
                            className={
                              passwordShown
                                ? "fa fa-eye cursor-pointer"
                                : "fa fa-eye-slash cursor-pointer"
                            }
                            onClick={togglePasswordVisiblity}
                          />
                        </div>
                        <div className="help-block text-red-700">
                          {error.passwordError ? error.passwordError : ""}
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2 lg:pr-4 ">
                        <div className="parentGroup mb-2 eyeIcon">
                          <label htmlFor="email" className="formBlock">
                            Confirm Password
                          </label>
                          <FormInput
                            inputType={confirmPasswordShown ? "text" : "password"}
                            inputName="confirmPassword"
                            inputID="confirmPassword"
                            value={formData?.confirmPassword}
                            handlerChange={handlerChange}
                          />
                          <i
                            className={
                              confirmPasswordShown
                                ? "fa fa-eye cursor-pointer"
                                : "fa fa-eye-slash cursor-pointer"
                            }
                            onClick={togglePasswordVisiblity2}
                          />
                        </div>
                        <div className="help-block text-red-700">
                          {error.confirmPasswordError ? error.confirmPasswordError : ""}
                        </div>
                      </div>
                      <div className="w-full mb-2">
                        <label htmlFor="hearAboutUs" className="formBlock">
                          How did you hear about us?
                        </label>
                        <select
                          name="hearAboutUs"
                          id="hearAboutUs"
                          value={formData?.hearAboutUs}
                          onChange={handlerChange}
                          className="formControl"
                        >
                          {hearAboutUsOptions?.map((option, index) => (
                            <option key={index} value={option?.value}>
                              {option?.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="formBlock flex  ">
                        <input
                          type="checkbox"
                          id="agreeTerm"
                          checked={formData?.checkbox}
                          className="mr-2 w-5 h-5"
                          onChange={() => handelCheckbox()}
                        />
                        Agree with{" "}
                        <Link to="https://schedulize.co.uk/terms-conditions/" target="_blank" className="px-1">
                          Term and Conditions
                        </Link>{" "}
                        
                      </label>
                    </div>
                    <div className="help-block text-red-700">
                      {error.checkboxError ? error.checkboxError : ""}
                    </div>
                    <div className=" mt-6 text-center">
                      <button
                      id="signUp"
                        className={` mb-4 btn save w-full focus:outline-none focus:border-purple `}
                        aria-describedby="popup-8"
                        onClick={(e) => handleFormSubmit(e)}
                        disabled={!passwordValid} 
                      >
                        Sign up
                      </button>
                      <Popup
                        onClose={() => closeModal()}
                        closeOnDocumentClick={false}
                        className="smallModal"
                        trigger={
                          <button id="openOtpModel" style={{ display: "none" }}>
                            {" "}
                            OpenOtp{" "}
                          </button>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button
                              className="close"
                              onClick={() => {
                                setOtp(" ");
                                closeModal();
                                close();
                              }}
                            >
                              &times;
                            </button>
                            <div className="content text-center ">
                              <div className="">
                                <h2 className="text-2xl ">OTP Verification</h2>
                                <p className=" mt-3 mb-6">
                                Please confirm the verification code we have just sent to your email address.
                                </p>
                                <h3 className="text-base">
                                  {formData?.email}
                                </h3>
                              </div>
                              <div className=" mx-auto  mt-3 otpSection">
                                <OtpInput
                                  className="w-full"
                                  value={otp}
                                  onChange={setOtp}
                                  numInputs={4}
                                  renderInput={(props) => (
                                    <input {...props} className="otpInput" />
                                  )}
                                />
                              </div>

                              {isRunning && (
                                <p className="resend  px-4 py-2 rounded my-4 font-bold secondry-text ">{timer} s</p>
                              )}
                              {!isRunning && showResend && (
                                <button
                                  className="resend  px-4 py-2 rounded my-4 font-bold secondry-text "
                                  onClick={(e) => resendOtp(e)}>Resend</button>
                              )}

                              <div className="lg:w w-full  ">
                                <div className="w-full ">
                                  <div className="form-group  flex justify-center ">
                                    <button
                                      className="btn save w-full"
                                      aria-describedby="popup-8"
                                      onClick={handelRegisterSubmit}
                                    >
                                      Continue
                                    </button>
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      modal
                                      nested
                                    >
                                      {() => (
                                        <div className="modal">
                                          <div className="header">
                                            <h3>Welcome</h3>
                                          </div>
                                          <div className="content">
                                            <div className="flex justify-between flex-wrap ">
                                              <h6 className="mb-4">
                                                {" "}
                                                Upon registration, you will automatically receive a 7-day free trial. To continue enjoying our premium features beyond the trial period, kindly upgrade to our Paid plan through your account settings, accessible in the billing section.
                                              </h6>

                                              {planData?.subscription_plans
                                                .length > 0 ? (
                                                planData?.subscription_plans?.map(
                                                  (plans, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={index}
                                                          className="lg:w-1/2 w-full lg:pr-6"
                                                        >
                                                          <div className="selectPlan">
                                                            <label>
                                                              <div
                                                                className={
                                                                  index === 0
                                                                    ? "p-4 freePlan  divcurrentPlan border-2 border-gray-200 rounded-md w-full relative h-44 lg:mb-0 mb-4"
                                                                    : "p-4 freePlan  border-2 border-gray-200 rounded-md w-full relative h-44 lg:mb-0 mb-4"
                                                                }
                                                              >
                                                                <div className="text-sm mb-4">
                                                                  <span className="text-white font-light bg-primary p-1 rounded-sm  ">
                                                                    {plans?.monthly_amount ===
                                                                      "0"
                                                                      ? "Free"
                                                                      : "Paid"}
                                                                  </span>
                                                                  <h2 className=" mt-3 mb-3">
                                                                    <span className="text-4xl">
                                                                      {
                                                                        plans?.monthly_amount
                                                                      }
                                                                    </span>
                                                                    <span>
                                                                      {
                                                                        plans?.currency
                                                                      }
                                                                      / employee{" "}
                                                                    </span>
                                                                  </h2>
                                                                  <p className="absolute top-5 right-5 font-bold opacity-100 ">
                                                                    {
                                                                      plans?.name
                                                                    }
                                                                  </p>
                                                                </div>
                                                                <p>
                                                                  {
                                                                    plans?.description
                                                                  }
                                                                </p>
                                                              </div>
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )
                                              ) : isError ? (
                                                <>
                                                  <div className="flex justify-between flex-wrap">
                                                    No Data Found
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}

                                              <div className="lg:w w-full  ">
                                                <div className="w-full mt-4">
                                                  <div className="form-group pt-8 pb-3 flex justify-center ">
                                                    <OnBoard />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popup>
                      <p className="text-gray-600 font-medium">
                        Already have an Account?{" "}
                        <Link to="/" className="primary-text font-semibold">
                          Log in
                        </Link>
                      </p>
                    </div>
                    <ModifiedToast />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
