/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';

export const myOverTimeApi = createApi({
  reducerPath: "myOverTimeAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),
  endpoints: (builder) => ({
    myOvertimeList: builder.mutation({
      query: (payload) => ({
        url: payload,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    allOvertimeList: builder.mutation({
      query: (payload) => ({
        url: payload,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    timeBackOvertimeList: builder.mutation({
      query: (payload) => ({
        url: payload,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    createMyOvertime: builder.mutation({
      query: (payload) => ({
        url: "/overtimes",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      }),
      invalidatesTags: ["Post"]
    }),
    updateMyOvertime: builder.mutation({
      query: (payload) => {
        return {
          url: `/overtimes`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: ["Post"]
    }),
    deleteMyOvertime: builder.mutation({
      query: (id) => ({
        url: `/overtimes/${id}`,
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    myOvertimeFilterName: builder.mutation({
      query: (first_name) => ({
        url: `/overtimes?first_name=${first_name}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    myOvertimeByOvertimeName: builder.mutation({
      query: (id) => ({
        url: `/overtimes?m_overtime_types_id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    myOvertimeByDate: builder.mutation({
      query: (start_date) => ({
        url: `/overtimes?start_date=${start_date}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    })
  })
});

export const { useMyOvertimeListMutation, 
            useCreateMyOvertimeMutation,
             useUpdateMyOvertimeMutation,
              useDeleteMyOvertimeMutation,
              useMyOvertimeFilterNameMutation,
              useMyOvertimeByOvertimeNameMutation,
              useMyOvertimeByDateMutation,
              useAllOvertimeListMutation,
              useTimeBackOvertimeListMutation

            } = myOverTimeApi;