
import { FormSelect } from '../../../components/formInput';
import { FormTextarea } from '../../../components/formInput';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
const OvertimeDetails = ({overtimeDetails}) => {
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
    const startTimeArray = overtimeDetails?.start_time?.split(':');
    const formattedStartTime = startTimeArray?.slice(0, 2).join(':');
    const endTimeArray = overtimeDetails?.end_time?.split(':');
    const formattedEndTime = endTimeArray?.slice(0, 2).join(':');

    const [ formattedDuration, setFormattedDuration ] = useState('');

    useEffect(() => {
      if (overtimeDetails?.duration !== undefined) {
        setFormattedDuration(formatDuration(overtimeDetails?.duration));
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ overtimeDetails ]);

    // Function to format duration in minutes to "hh:mm" format
    const formatDuration = (durationInMinutes) => {
      if (durationInMinutes === null || durationInMinutes === undefined) {
        return ""; // Handle null or undefined cases
      }

      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;

      // Use string padding to ensure two digits for hours and minutes
      const formattedHours = String(hours)?.padStart(2, '0');
      const formattedMinutes = String(minutes)?.padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}`;
    };

    
    return (
     

<>
<div className="header">
          <h3 className="flex secondry-text">          
           Overtime Details
          </h3>
        </div>
<div className="content">
                            <form >
                              <div className="flex flex-wrap ">
                                <div className="md:w-1/2 md:pr-6 w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Overtime type
                                    </label>
                                    <select id="editOvertimeType" className="formControl" 
                                    //value={over?.m_overtime_types_id}
                                    name='m_overtime_types_id'
                                    >
                                    <FormSelect 

                                      optionName={overtimeDetails?.mOverTimeInfo?.overtime_name} />;
                                    </select>
                                    <div className="help-block" />
                                  </div>
                                </div>
                                <div className="md:w-1/2 md:pr-0 w-full mb-4 md:mb-0">
                                  <label className="formBlock	">Date </label>
                                  <input
                                      id="editDate"
                                      className="formControl"
                                      value={moment(overtimeDetails?.start_date).format(formattedDate)}                                 
                                    />
                                </div>
                                <div className="md:w-1/2 md:pr-6 w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">Time</label>
                                    <div className="flex">
                                      <div className="w-full pr-4">                                       
                                      <input
                                      className="formControl"
                                      value={formattedStartTime}
                                    />
                                      
                                      </div>
                                      <div className="w-full">
                                      <input
                                       className="formControl"
                                        value={formattedEndTime}
                                      />                                   
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="md:w-1/2  w-full">
                                  <div className="form-group mb-2 ">
                                    <label className="formBlock	">Break</label>
                                    <input className="formControl"
                                      value={overtimeDetails?.break_time}
                                      disabled
                                    />
                                    <div className="help-block" />
                                  </div>
                                  <div className="form-group mb-6  flex">
                                    <label className="formBlock flex	">
                                      <input
                                        type="checkbox"
                                        className="mr-2 w-5 h-5"
                                        checked={overtimeDetails?.is_paid === "0" ? false : true}
                                      />
                                      Paid
                                    </label>
                                  </div>
                                </div>
                                <div className="md:w-1/2 md:pr-6 w-full">
                                  <div className="form-group mb-3 ">
                                    <label className="formBlock	">
                                      Duration (Hours)
                                    </label>
                                    <input
                                      className="formControl"
                                      value={formattedDuration}
                                    />
                                    <div className="help-block" />
                                  </div>
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock flex	">
                                      <input
                                        type="checkbox"
                                        className="mr-2 w-5 h-5"
                                        checked={overtimeDetails?.time_in_lieu === "0" ? false : true}
                                      />
                                      Book as time in Lieu
                                    </label>
                                    <div className="help-block" />
                                  </div>
                                </div>

                                <div className="w-full ">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	" >Notes </label>
                                    <FormTextarea textName="notes" inputID="editNotes" disabled
                                    value={overtimeDetails?.notes}
                                     />
                                    <div className="help-block" />
                                  </div>
                                </div>
                                
                              </div>
                            </form>
                          </div>
      {/* <div className="header">
        <h3 className="flex secondry-text">         
         Overtime Details
        </h3>
      </div> */}
      {/* <div className="content">
      
        <div className="flex mb-7">
          <div className="w-1/2 ">
            <p className="mr-4">
              <span className="font-medium">
                Employee Name:
              </span>{" "} {overtimeDetails?.employeeInfo?.first_name}
           
              
            </p>
          </div>
          <div className="w-1/2 ">
            <p>
              <span className="font-medium">
                Type:
              </span>{" "} {overtimeDetails?.mOverTimeInfo?.overtime_name}	
             
            </p>
          </div>
        </div>
       <div className="flex mb-7">
          
          <div className="w-1/2 ">
            <p className="mr-4">
              <span className="font-medium">
                Start Time:
              </span>{" "}  {overtimeDetails?.start_time}{" "}
           
              
            </p>
          </div>
          <div className="w-1/2 ">
            <p>
              <span className="font-medium">
                End Time:
              </span>{" "}  {overtimeDetails?.end_time}{" "}
             
            </p>
          </div>
          
        </div>
        <div className="flex mb-7">
       
        <div className="w-1/2 ">
            <p>
              <span className="font-medium">
                Duration:
              </span>{" "}
              {parseInt(overtimeDetails?.duration/60)}:{overtimeDetails?.duration%60}
              
            </p>
          </div>
          <div className="w-1/2 ">
            <p>
              <span className="font-medium">
                OverTime Date:
              </span>{" "}
              {moments(overtimeDetails?.updatedAt).format('DD-MM-YYYY')}
            
            </p>
          </div>
         
        
         
        </div>
        

       <div className="flex mb-7">
          <div className="w-1/2 ">
            <p>
              <span className="font-medium">
              Time in Lieu:
              </span>{" "}
              {overtimeDetails?.time_in_lieu === '1' ? 'Yes' : 'No'}
              
            </p>
          </div>
        </div>
      
        
      </div> */}
                                  
    </>

                            
                            
    );
};

export default OvertimeDetails;