import moment from "moment";

export function authToken() {
    return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null;
}

export const convertBreakDurationToHHMM = (breakDurationInMinutes) => {
    var duration = moment.duration(breakDurationInMinutes, 'minutes');
    var hours = Math.floor(duration.asHours());
    var minutes = Math.floor(duration.asMinutes()) - hours * 60;
    return moment({ hours: hours, minutes: minutes }).format('HH:mm');
};

export const convertBreakTimeToMinutes = (breakTime) => {
    return moment.duration(breakTime).asMinutes();
};


export const calculateTimeDifferenceInMinutes = (startTime, endTime) => {
    // Parse the time strings into Date objects
    var start = new Date("2000-01-01 " + startTime);
    var end = new Date("2000-01-01 " + endTime);
  
    // Calculate the difference in milliseconds
    var difference = end - start;
  
    // Convert milliseconds to minutes
    var minutesDifference = Math.floor(difference / (1000 * 60));
  
    return minutesDifference;
  };


  export const  getWeekdayOccurrencesInMonth = (dayOfWeek, currentMonth, currentYear) => {
    const dates = [];
  const firstDayOfMonth = moment(`${currentYear}-${currentMonth + 1}-01`, 'YYYY-MM-DD');
  const lastDayOfMonth = moment(firstDayOfMonth).endOf('month');

  let currentDay = firstDayOfMonth.clone().day(dayOfWeek);
  while (currentDay.isSameOrBefore(lastDayOfMonth)) {
    dates.push(currentDay.format('DD')); // Store the day of the month without leading zeros
    currentDay.add(7, 'days');
  }

  return dates;
};

export const formatYearHelper = (inputYear, offset) => {
  const dateObject = new Date(inputYear);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const currentMonth = dateObject.getMonth();
  const currentYear = dateObject.getFullYear();

  const newDate = new Date(currentYear, currentMonth - 11);

  const formattedCurrentYear = `${monthNames[currentMonth]} ${currentYear + offset}`;
  const formattedNewDateYear = `${monthNames[newDate.getMonth()]} ${newDate.getFullYear() + offset}`;

  return [formattedCurrentYear, formattedNewDateYear];
};

// Function to get the number of days in the current year
export const getDaysInCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  const startDate = new Date(currentYear, 0, 1);
  const endDate = new Date(currentYear + 1, 0, 1);

  // Calculate the number of days between the two dates
  const daysInYear = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000));

  return daysInYear;
};

// Function to calculate pro-rata leave
export const calculateProRataLeave = (annualAllowance, joinDate, nextcarryForward, edit) => {
  const currentYearLastDate = moment().endOf("year"); // Get the last date of the current year

  // If nextCarryForward is an empty string, set it to the last date of the current year
  const nextcarryForwardDate = nextcarryForward
    ? moment(nextcarryForward, "DD-MMM-YYYY")
    : currentYearLastDate;
    console.log("moment(nextcarryForward, DD-MMM-YYYY1234", nextcarryForwardDate);
    
  const joiningDate = moment(joinDate, "DD-MMM-YYYY");

  // If joining date is the start of the year, return the full annual allowance
  if (joiningDate.isSame(moment().startOf("year"), 'day')) {
    return annualAllowance;
  }

  // Calculate the difference in days
  const daysDifference = nextcarryForwardDate.diff(joiningDate, "days");
  const daysDifferenceEditProRata = nextcarryForwardDate.diff(joiningDate, "days") + 1;
  const daysToUse = edit === 'editProrata' ? daysDifferenceEditProRata : daysDifference;

  const daysInCurrentYear = getDaysInCurrentYear();

  let proRataLeave = (annualAllowance / daysInCurrentYear) * daysToUse;

  // Ensure proRataLeave is always positive
  proRataLeave = Math.max(0, proRataLeave);

  // Cap proRataLeave to annualAllowance if it exceeds
  if (proRataLeave > annualAllowance) {
    proRataLeave = annualAllowance;
  }
    proRataLeave = parseFloat(proRataLeave);
    proRataLeave = parseFloat(proRataLeave?.toFixed(2));
   // Ensure the result is a float with 2 decimal points

  return proRataLeave;
};