/* eslint-disable camelcase */
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ApiUrl} from "../constant";

export const overTimeApi = createApi({
  reducerPath: "overTimeAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),
  endpoints: (builder) => ({
    getNotes: builder.mutation({
      query: (id) => ({
        url: `/note/?employee_id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    createNotes: builder.mutation({
      query: ({id, note}) => ({
        url: `/note`,
        method: "POST",
        body: {
          note: note,
          employee_id: id
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      }),
      invalidatesTags: [ "Post" ]
    }),
    updateNotes: builder.mutation({
      query: ({id, note, noteId}) => {
        return {
          url: `/note/${noteId}`,
          method: "PUT",
          body: {
            note: note,
            employee_id: id
          },
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: [ "Post" ]
    }),
    deleteNotes: builder.mutation({
      query: ({id, noteId}) => ({
        url: `/note/${noteId}`,
        method: "DELETE",
        body: {
            employee_id: id
          },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      }),
      invalidatesTags: [ "Post" ]
    }),
    getAllNotification : builder.mutation({
      query: (payload) => ({
        url: payload,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
   
    deleteNotification : builder.mutation({
      query: (payload) => ({
        url: payload,
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
      
    }),
    getAllNotificationCount : builder.mutation({

      query: (payload) => ({
        url: payload,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
        
      }),
      onError() {
        alert("error401");
        // Handle errors globally here, such as logging or displaying notifications
      }
    }),
    updateEmployeeNotification: builder.mutation({
      query: (id) => {
        return {
          url: `/EmployeeNotification/${id}`,
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: [ "Post" ]
    }),
    onError() {
      alert("error401");
      // Handle errors globally here, such as logging or displaying notifications
    }

  }),
  onError() {
    alert("error401");
    // Handle errors globally here, such as logging or displaying notifications
  }
});

export const {
  useGetNotesMutation,
  useCreateNotesMutation,
  useUpdateNotesMutation,
  useDeleteNotesMutation,
  useGetAllNotificationMutation,
  useDeleteNotificationMutation,
  useGetAllNotificationCountMutation,
  useUpdateEmployeeNotificationMutation
} = overTimeApi;
