import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    notificationData: [],
    notificationCounts: []
};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    notificationDataAction: (state, action) => {
    state.notificationData = action.payload;
    },
    notificationCountAction: (state, action) => {
        state.notificationCounts = action.payload;
        }
  }

});

export const { notificationDataAction, notificationCountAction } = notificationSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default notificationSlice.reducer;