
import DatePicker from "react-datepicker";
import { useState, useEffect, useCallback } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SearchField } from "../../components/filters";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import { FormSelect } from "../../components/formInput";
import Pagination from "../../components/inc/pagination";
import moment from "moment";
import { useGetCompanyHistoryQuery } from "../../services/employeeApi";
import Popupfeature from "../../components/Popupfeature";
registerLocale("en-GB", enGB);

const CompanyReport = () => {

  //carrying out data from redux store 
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const id = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.id);

  //States and Hooks
  const [ filter, setFilter ] = useState({
    name: "",
    category: "",
    start_date: "",
    end_date: "",
    employee_name:''
  });
  const [allHistoryInfo, setAllHistoryInfo] = useState();
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [pages, setPages] = useState(0);
  const [excelData, setExcelData] = useState([]);

  //Api calling
  const { data: employeeHistory, isSuccess } = useGetCompanyHistoryQuery({page: page, category: filter?.category, start_date: filter?.start_date, end_date: filter?.end_date, name: filter?.name, employee_name:filter?.employee_name });
  const totalPages = employeeHistory?.totalPages;

  //Csv file default name 
  const createCsvFileName = () => `Company_history_report_${moment().format()}.csv`;

  useEffect(() => {
      if (isSuccess) {
        setAllHistoryInfo(employeeHistory?.data);
        if (employeeHistory?.data) {
          let csvData = exportTOCSV(employeeHistory?.data);
          setExcelData(csvData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [isSuccess]);

  useEffect(() => {
      if (employeeHistory) {
        const firstPageIndex = 0;
        const lastPageIndex = 10;
        const data = employeeHistory?.data?.slice(
          firstPageIndex,
          lastPageIndex
        );
        setAllHistoryInfo(data);
        setPages(totalPages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [employeeHistory]);

  //Date change filter
  const onDateChange = (e) => {
    if (id && e[0] !== null && e[1] !== null) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        start_date: moment(e[0]).format("YYYY-MM-DD"),
        end_date: moment(e[1]).format("YYYY-MM-DD")
      }));
    } else {
      setFilter((prevFilter) => ({
        ...prevFilter,
        start_date: "",
        end_date: ""
      }));
    }
  };

  // Search by name filter
  const filterEmployee = useCallback((searchTerm) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      name: searchTerm
    }));
  }, []);
   // Search by employeeName filter
   const filterEmployees = useCallback((searchTerm) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      employee_name: searchTerm
    }));
  }, []);
  

  //Filter based on category
  const handlerCategoryFilter = (e) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      category: encodeURIComponent(e.target.value)
    }));
  };

  const categoryType = [
    { value: "", label: "Select Category Type" },
    { value: "Work Schedule", label: "Work Schedule" },
    { value: "Company Work Schedule", label: "Company Work Schedule" },
    { value: "Employee Profile", label: "Employee Profile" },
    { value: "Leave Section", label: "Leave Section" },
    { value: "Signed Up", label: "Signed Up" },
    { value: "Logged In", label: "Logged In" },
    { value: "Logged Out", label: "Logged Out" }
  ];

  //CSV Header
  let headers = [
    { label: "Name", key: "name" },
    { label: "Updated at", key: "updatedAt" },
    { label: "Old Value", key: "oldValue" },
    { label: "New Value", key: "newValue" },
    { label: "Description", key: "description" },
    { label: "Category", key: "category" }
  ];

  //export to CSV function
  const exportTOCSV = (report) => {
    let dataAbence = [];
    if (report) {
      report?.forEach((dExcel) => {
        let val = {
          name: dExcel?.name,
          updatedAt: dExcel?.updatedAt,
          oldValue: dExcel?.old_value,
          newValue: dExcel?.new_value,
          description: dExcel?.description,
          category: dExcel?.category
        };
        dataAbence.push(val);
      });
      return dataAbence;
    }
  };

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>
        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">
                Company Report{" "}
              </h2>
              <div className="card ">
                <div className="p-4 ">
                  <div className="headingContent  pt-0 w-full">
                    <div className="flex pt-2 pb-6 filterSection flex-wrap md:flex-nowrap">
                      <div className="flex  filterSection flex-wrap md:flex-nowrap w-full">
                      <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 mt-4">
                          <SearchField
                            placeholderText="Search By Employee/Administrator"
                            searchId="historySearch"
                            onChange={(e) => filterEmployees(e.target.value)}
                          />
                        </div>
                        <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 mt-4">
                          <SearchField
                            placeholderText="Search By Name"
                            searchId="historySearch"
                            onChange={(e) => filterEmployee(e.target.value)}
                          />
                        </div>
                        <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 p-4">
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            id="datePicker"
                            endDate={endDate}
                            dateFormat="dd/MM/yyyy"
                            className="formControl"
                            placeholderText="Select Date Range"
                            onChange={(update) => {
                              setDateRange(update);
                              onDateChange(update);
                            }}
                            isClearable={true}
                          />
                        </div>
                        <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 p-4">
                          <select
                            className="formControl"
                            onChange={(e) => handlerCategoryFilter(e)}
                          >
                            {categoryType?.map((category, key) => {
                              return (
                                <FormSelect
                                  key={key}
                                  optionValue={category?.value}
                                  optionName={category?.label}
                                />
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="headerButton flex items-center exportFile md:w-96  w-full justify-end ">
                        <CSVLink
                          className=""
                          data={excelData}
                          headers={headers}
                          filename={createCsvFileName()}
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            outline: "none",
                            height: "5vh"
                          }}
                        >
                          <button
                            className="btn-md btn-add "
                            id="companyMessageCsv"
                          >
                            <span className="fa fa-file-export mr-2" /> Export
                            to CSV
                          </button>
                        </CSVLink>
                      </div>
                    </div>
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th className="w-40"> Name</th>
                          <th>Old Value</th>
                          <th>New Value</th>
                          <th>Description</th>
                          <th>Category</th>
                          <th>Created By</th>
                          <th>Created For</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allHistoryInfo?.length > 0 ? (
                          <>
                            {allHistoryInfo &&
                            allHistoryInfo?.map((history, index) => {
                              return (
                                <tr key={index}>
                                  <td>{history?.name}</td>
                                  <td>
                                    {history?.old_value ? (
                                      history?.category === "Company Work Schedule" || history?.category === "Work Schedule" ? (
                                        <Popupfeature
                                          text={"old value"}
                                          data={history?.old_value}
                                        />
                                      ) : (
                                        history?.old_value
                                      )
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </td>
                                  <td>
                                    {history?.new_value ? (
                                      history?.category === "Company Work Schedule" || history?.category === "Work Schedule" ? (
                                        <Popupfeature
                                          text={"new value"}
                                          data={history?.new_value}
                                        />
                                      ) : (
                                        history?.new_value
                                      )
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </td>
                                  <td>{history?.description ? history?.description : "Unavailable"} </td>
                                  <td>{history?.category ? history?.category : "Unavailable"}</td>
                                  <td>
                                    {history?.addedByForHistory?.first_name} {history?.addedByForHistory?.last_name}
                                  </td>
                                  <td>
                                    {history?.employeeInfo?.first_name} {history?.employeeInfo?.last_name}
                                  </td>
                                  <td>{moment(history?.updatedAt)?.format(formattedDate)}{" "}
                                    <span className="text-gray-700">
                                      {moment(history?.updatedAt)?.format("hh:mm")}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })} 
                          </>
                        ) : 
                        (
                          <center className="mt-4 ml-[350%] text-red-700">No Data Found</center>
                        )
                      }
                      </tbody>
                    </table>
                    <Pagination page={page} pages={pages} changePage={setPage} decrementPage={setPage}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyReport;
