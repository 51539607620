import {initializeApp} from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getMessaging, getToken, onMessage} from "firebase/messaging";




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBY9u2zRA_lxLvyu2var7khfuaUJAbs_JA",
  authDomain: "schedulize-ltd.firebaseapp.com",
  projectId: "schedulize-ltd",
  storageBucket: "schedulize-ltd.appspot.com",
  messagingSenderId: "544330811647",
  appId: "1:544330811647:web:963edc368dd9bc260da4ca",
  measurementId: "G-WG69P53KDP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const getTokens = (setFcmToken) => {
  return getToken(messaging, {vapidKey: "BGezGGTjfqjN8NT6WgNpzuVn5dD4SHr1M2ErE6kWA_6-J0AOWDkH3oR3GAypPHagY81wPirpLJjssokrTLIbFt0"})
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        setFcmToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setFcmToken("");
        // setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
