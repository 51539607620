/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from "react";

const PrimaryButton = ({ btnText, Btnclass, type, onClick, buttonID, disabled }) => {
  return (
    <>
      <button className={Btnclass} disabled={disabled} onClick={onClick} type={type}  id={buttonID}>{btnText}</button>
    </>
  );
};

export default PrimaryButton;
