/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  restrictedHoliday: []
};

export const restrictedHolidaySlice = createSlice({
  name: "restrictedHolidaySlice",
  initialState,
  reducers: {
    addRestrictedHoliday: (state, action) => {
       state.restrictedHoliday.data.push(action.payload);
    },
    restrictedHolidayInfo: (state, action) => {
      state.restrictedHoliday = action.payload;
    },
    updatedRestrictedHoliday: (state, action) => {
      const {id, name, start_date, end_date} = action.payload;
      const existingHoliday = state.restrictedHoliday.data.find((holiday) => holiday.id === id);
      if(existingHoliday) {
        existingHoliday.name= name;
        existingHoliday.start_date = start_date;
        existingHoliday.end_date = end_date;
      }
    },
    deleteRestrictedHoliday: (state, action) => {
      const {id} = action.payload;
      const existingHoliday = state.restrictedHoliday.data.find((holiday) => holiday.id === id);
      if(existingHoliday) {
        return  state.holiday.data.filter((holiday) => holiday.id !== id);
     }
    }
    
  }
  
});

export const {addRestrictedHoliday, restrictedHolidayInfo, updatedRestrictedHoliday, deleteRestrictedHoliday } = restrictedHolidaySlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default restrictedHolidaySlice.reducer;