/* eslint-disable no-debugger*/
/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { Link, useNavigate } from "react-router-dom";
import { openJoyce } from "../redux/JoyiceSlice/joyiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllDashboradMutation } from "../services/dashboardAp";
import { toast } from "react-toastify";
import { useCreateCompanyMutation } from "../services/companyApi";
import { FormSelect } from "./formInput";
import { useUpdateEmployeeMutation } from "../services/employeeApi";
import { checkValidations } from "../pages/Employees/empolyeeValidation";
import OnboardingCompanyWorkSchedule from "./onboardingCompanyWorkSchedule";

const GeneralSignup = ({ isSignup, joyceData }) => {
  const [updateEmployee, { isSuccess, isError, error: apiError, isLoading }] =
    useUpdateEmployeeMutation();
  const [openBoard, setOpenBoard] = useState(false);
  const [newPop, setNewPop] = useState(false);
  const [openAllowancePopup, setOpenAllowancePopup] = useState(false);
  const [openNewPopup, setOpenNewPopup] = useState(false); // New state for the new popup
  const employeeIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id
  );
  const [employeeData, setEmployeeData] = useState({
    annualAllowance: "",
    hoursOrDay: "0",
    maximumLeaveCarry: "",
    id: employeeIdData,
  });

  const [errorValidations, setErrorValidations] = useState({
    annualAllowance: "",
    hoursOrDay: "",
    maximumLeaveCarry: "",
  });
  const navigate = useNavigate();
  const [updateCompany] = useCreateCompanyMutation();
  const companyId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id
  );
  const [getAllDashboard, { isSuccess: isSuccessDashBoard }] =
    useGetAllDashboradMutation();
  
  const dispatch = useDispatch();
  useEffect(
    () => {
      if (isSignup) {
        // setOpenBoard(true);
        dispatch(openJoyce({ isJoyce: false, isSignup: false }));
        getAllDashboard(companyId);

        handleTourCompleted();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSignup, joyceData]
  );
  const toastId = useRef(null);
  useEffect(
    () => {
      if (isLoading) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info",
        });
      }
      if (isSuccess) {
        toast.dismiss(toastId.current);
        toast.success("Employee profile updated successfully.", {
          render: "Employee profile updated successfully.",
          type: "success",
          autoClose: 8000,
          isLoading: false,
          theme: "colored",
        });
        // let payload = `/employee?page=${currentPage}&status=${toggleState === 'Leavers' ? '0' : '1'}`;
        //   getEmployee(payload);
        closeModal();
      }
      if (isError) {
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.dismiss(toastId.current);
        toast.success(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          duplicate: false,
          isLoading: false,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, isError, isLoading]
  );

  const closeModal2 = () => {
    setOpenBoard(false);
  };
  const closeModal = () => {
    setOpenBoard(false);
  };

  const handleTourCompleted = () => {
    let value = {
      tour_completed: "1",
    };
    updateCompany(value);

    setOpenAllowancePopup(true);
  };

  useEffect(
    () => {
      if (isSuccessDashBoard) {
        toast.success("Welcome to Schedulize", {
          render: "Welcome to Schedulize",
          // position: "top-center",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessDashBoard]
  );

  const hourData = [
    { value: 0, name: "Select" },
    { value: 1, name: "Hours" },
    { value: 2, name: "Days" },
  ];

  const validForm = () => {
    const { annualAllowance, hoursOrDay, maximumLeaveCarry } = employeeData;
    let isFormValidated = true;
    if (maximumLeaveCarry === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          maximumLeaveCarry: "Please enter maximum allowance to carry forward",
        };
      });
      isFormValidated = false;
    }
    if (annualAllowance === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          annualAllowance: "Please enter annual allowances",
        };
      });
      isFormValidated = false;
    }
    if (hoursOrDay === "" || hoursOrDay === "0") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          hoursOrDay: "Please select hours or days",
        };
      });
      isFormValidated = false;
    }

    return isFormValidated;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let message = checkValidations(name, value);
    setErrorValidations({ ...errorValidations, [name]: message });

    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handelUpdatEmployee = () => {
    if (validForm()) {
      updateEmployee(employeeData);
      // Set the state to open the new popup
      setOpenNewPopup(true);
      //navigate("/setting/info");
      setTimeout(() => {
        alert(
          "You are nearly set! Please go through each setting option to fully customise Schedulize to suit your business."
        );
      }, 5000);
    }
  };

  return (
    <>
      <Popup
        open={openAllowancePopup}
        onClose={() => closeModal()}
        closeOnDocumentClick={false}
        className="MultiFrom"
        trigger={
          <button
            className="p-1 px-3 rounded text-md text-left primary-text"
            aria-describedby="popup-8"
            id="resetPassword"
            onClick={() => setOpen((o) => !o)}
          ></button>
        }
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header">
              <h3>Your Annual Holiday Allowance</h3>
            </div>
            <div className="content">
              <div className=" flex flex-wrap ">
                <div className="w-full md:w-1/2 md:pr-6">
                  <div className="form-group mb-6">
                    <label className="formBlock	">
                      Annual holiday allowance
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                    </label>
                    <input
                      id="annualAllowance"
                      name="annualAllowance"
                      // value={employeeData?.annualAllowance}
                      type="number"
                      className="formControl"
                      onChange={(e) => handleChange(e)}
                    />
                    <div className="help-block text-red-700">
                      {errorValidations?.annualAllowance
                        ? errorValidations?.annualAllowance
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/2 ">
                  <div className="form-group mb-6 ">
                    <label className="formBlock	">
                      Hours or Days
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                    </label>
                    <select
                      name="hoursOrDay"
                      id="hoursOrDay"
                      value={employeeData?.hoursOrDay}
                      onChange={(e) => handleChange(e)}
                      className="formControl"
                    >
                      {hourData?.map((hour, index) => {
                        return (
                          <FormSelect
                            key={index}
                            optionValue={hour?.value}
                            optionName={hour?.name}
                          />
                        );
                      })}
                    </select>

                    <div className="help-block text-red-700">
                      {errorValidations?.hoursOrDay}
                    </div>
                  </div>
                </div>
                <div className="w-full ">
                  <div className="form-group mb-2 ">
                    <label className="formBlock	">
                      Maximum allowance you can carry forward{" "}
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[ 8px ]" />
                    </label>
                    <input
                      id="maximumLeaveCarry"
                      name="maximumLeaveCarry"
                      type="number"
                      className="formControl"
                      onChange={(e) => handleChange(e)}
                    />
                    <div className="help-block text-red-700">
                      {errorValidations?.maximumLeaveCarry
                        ? errorValidations?.maximumLeaveCarry
                        : ""}
                    </div>
                  </div>
                </div>
                <div>
                  <Link
                    className=" p-1 px-3 rounded text-md text-left "
                    to={`${process.env.REACT_APP_BASE_URL}/holiday-calculator`}
                    target="_blank"
                  >
                    <h5 className="formBlock">
                      <i className="fa-solid fa-calculator mr-2 primary-text" />
                      Use our handy calculator
                    </h5>
                  </Link>
                </div>
                <div className="w-full flex justify-center">
                  <button
                    className="btn save "
                    onClick={() => {
                      handelUpdatEmployee();
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>

      <Popup
        open={openNewPopup} // Use the new state variable
        onClose={() => setOpenNewPopup(false)} // Add a function to close the new popup
        closeOnDocumentClick={false}
        className="MultiFrom workScheduleOnBoardPopup "
        modal
        nested
      >
        {(close) => (
          <div className="modal ">
            <OnboardingCompanyWorkSchedule />
          </div>
        )}
      </Popup>
    </>
  );
};

export default GeneralSignup;
