/* eslint-disable camelcase */
import { useState, useEffect } from "react";
import moment from "moment";
import { useGetEmployeeDashboradMutation } from "../../../services/dashboardAp";

export function UpcomingLeaveData({ UpcomingLeaveData, tableNames }) {
  const [ employeeInfo, setEmployeeInfo ] = useState({});
  const token = localStorage?.getItem('accessToken');
  const [ getEmployeeDashborad, { data: dashboardAllData, isSuccess } ] = useGetEmployeeDashboradMutation();

  useEffect(() => {
    getEmployeeDashborad({ headers: { Authorization: `Bearer ${token}` } });
  }, [ getEmployeeDashborad, token ]);

  useEffect(() => {
    if (isSuccess) {
      setEmployeeInfo({
        employeeData: dashboardAllData?.data?.employeeData
      });
    }
  }, [ isSuccess ]);

  // Function to format hours and minutes from total_duration(min)
  const formatHoursAndMinutes = (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    // Add leading zeros if needed
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    if (minutes === 0) return `${hours} hours`;
    else return `${hours} hours ${minutes} minutes`;
  };

  // Preprocess UpcomingLeaveData to combine leaves with the same leave_group_identifier
  let processedLeaveData = [];
  if (Array.isArray(UpcomingLeaveData)) {
    processedLeaveData = UpcomingLeaveData.reduce((acc, leave) => {
      const existingLeave = acc.find((item) => item?.leave_group_identifier === leave?.leave_group_identifier);
      if (existingLeave) {
        existingLeave.total_duration += leave.total_duration;
        existingLeave.days += leave.days;
      } else {
        acc.push({ ...leave });
      }
      return acc;
    }, []);
  } 

  return (
    <div className="overflow-x-auto w-full whitespace-nowrap">
      <table className="table-auto table  w-full ">
        <thead className="thead-dark ">
          <tr>
            {tableNames?.map((name, index) => {
              return (<th key={index} className="w-50">{name}</th>);
            })}
          </tr>
        </thead>
        <tbody>
          {processedLeaveData?.length > 0 ?
            processedLeaveData.map((thisWeek, index) => {

              const days = Number(thisWeek?.days);
              const formattedDays = ` ${Number.isInteger(days) ? days : days.toFixed(2)} days`;

              const totalMinutes = Number(thisWeek?.total_duration);
              const formattedhours = formatHoursAndMinutes(totalMinutes);
              const hoursOrDay = employeeInfo?.employeeData?.hours_or_day;
              return (
                <tr key={index}>
                  <td>{moment(thisWeek?.leave_start_date).format('DD-MM-YYYY')}{" to "}{moment(thisWeek?.leave_end_date).format('DD-MM-YYYY')}</td>
                  <td>{hoursOrDay === '1' ? (formattedhours) : (formattedDays)}</td>
                  <td>{thisWeek?.status === "0" ? "Applied" : thisWeek?.status === "1" ? "Approved" : thisWeek?.status === "2" ? "Rejected" : " "}</td>
                </tr>
              );
            }) :
            <tr>
              <td colSpan="12" className="text-center mt-3 text-red-700 ">
                <div className=" flex justify-center items-center  ">
                  <p className="mt-5 pt-10">
                    No upcoming leave this week.
                  </p>
                </div>

              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
}
