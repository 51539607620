/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState, useRef, useCallback } from "react";
import { useGetEmployeeMutation } from "../../services/employeeApi";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/inc/pagination";
import Popup from "reactjs-popup";
import { usePostEmployeeShareFolderMutation } from "../../services/employeeDocumentAPI";
import { toast } from "react-toastify";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { employeeInfo } from "../../redux/EmployeeSlice/employeeSlice";

const SharedEmployee  = ({ selectedFolder, getDocumentFolderShare }) => {
    const employeeListData = useSelector((state) => state?.persistedReducer?.employeeSlice?.employee);
    const [ getEmployee, {data:employeeListAllData, isSuccess: isEmployeeAPISuccess} ] = useGetEmployeeMutation();
    const [ postEmployeeSharefolder, { isSuccess: postEmployeeSharefolderSuccess, isLoading: postEmployeeSharefolderLoading, isError: postEmployeeSharefolderError, error: postEmployeeError} ] = usePostEmployeeShareFolderMutation();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const pageTerm = searchParams.get("page") || "";
    const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
    const [ pages, setPages ] = useState(0);
    const [ open, setOpen ] = useState(false);
    const [ empName, setEmpName ] = useState();
    const [ openShare, setOpenShare ] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
      setOpen(false);
    };

    useEffect(() => {
        let payload=`/employee?page=${page}&status=${1}&limit=${100}`;
        getEmployee(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);
useEffect(() => {
  if(employeeListData){
    setPages(employeeListData?.totalPages);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ employeeListData ]);

    useEffect(() => {
      if(isEmployeeAPISuccess){
        dispatch(employeeInfo(employeeListAllData));
      }
    //     if(employeeListAllData){
    //       dispatch(employeeInfo(employeeListAllData))
    //       setPages(employeeListData?.totalPages);
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isEmployeeAPISuccess ]);

    const decrementPage = () => {
        if(employeeListData?.currentPage - 1 >= 0){
          getEmployee(employeeListData?.currentPage - 1);
           setPage(employeeListData?.currentPage - 1);
        }
    };

    const handleShareFolder = (id) => {
        // e.preventDefault(); 
        const value = {
            "folder_id": selectedFolder?.id,
            "employee_id": id
        };
        postEmployeeSharefolder(value);
    };

    const toastId = useRef(null);
    useEffect(() => {
        if (postEmployeeSharefolderLoading) {
            toastId.current = toast.loading("Please wait...", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            type: "info"
          });
        }
        if (postEmployeeSharefolderSuccess) {
          toast.dismiss(toastId.current);
          toast.success("Folder shared Successfully",
            {
              render: "Folder shared successfully",
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "colored",
              type: "success"
            }
          );
          getDocumentFolderShare(selectedFolder?.id);
        document?.getElementById("closeSharefolderPopup")?.click();
        }
        if (postEmployeeSharefolderError) {
          const message =  postEmployeeError?.data?.error ? (postEmployeeError?.data?.error) : "Something went wrong";
          
          toast.update(
            toastId.current,
            (message,
            {
              render: message,
              type: "error",
              autoClose: 5000,
              isLoading: false
            })
          );
        }
      // eslint-disable-next-line 
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ postEmployeeSharefolderLoading, postEmployeeSharefolderSuccess, postEmployeeSharefolderError ]);

      const [ filter, setFilter ] = useState({
        name: '',
        department:'',
        email:''
      });
      
      const [ show, setShow ] = useState(false);
      const filterEmployees = useCallback((searchTerm) => {
        
        setFilter({...filter, ['name'] : searchTerm});
        let payload=`/employee?page=${page}&status=${1}&email=${searchTerm}&limit=${100}`;
       getEmployee(payload); 
       setEmpName(searchTerm);
       (searchTerm ? setShow(true) : setShow(false));
// eslint-disable-next-line react-hooks/exhaustive-deps       
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
       [ page ]);

      const [ combinedOptions, setCombinedOptions ] = useState([]);

      useEffect(() => { 
        let  mappedOptions = [];
        {
          employeeListData?.data?.length > 0 &&
            employeeListData?.data?.map((employeeList) => {
              
              mappedOptions.push({
                value: employeeList?.id,
                label: (
                  <>
                    <div className="w-full">
                      <div className="flex items-center w-full">
                        <div className="w-8 h-8 mr-3 rounded-full bg-gray-700 flex items-center justify-center text-md uppercase text-white">
                          {employeeList?.first_name[0]}
                          {employeeList?.last_name[0]}
                        </div>
                        <div className="flex justify-between w-11/12">
                          <div className="pr-4">
                            <h3 className="text-[14px]">
                              {employeeList?.first_name}{" "}
                              {employeeList?.last_name}
                            </h3>
                            <span>{employeeList?.email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              });
            });
        }
        setCombinedOptions(() => [ ...mappedOptions ]);         
       }, 
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [ employeeListData ]);
       const [ selected, setSelected ] = useState();

       const handleSubmit = () => {
          selected?.forEach((option) => {
          handleShareFolder(option.value);
        });
       };

       const handleShareDocs = (selectedOption) => {
        setSelected(selectedOption);

       };
      
      // Combine the existing options with the mapped employeeList
  return (
    <>
      <div className=" w-full flex">
        {/* <input placeholder="Search Employee By Email"
                       className="formControl "
                       id="empSearch"
                       onChange={(e) => filterEmployees(e.target.value)} 
                       defaultValue={empName}
                      /> */}

        <Select
          isMulti
          name="authorized_user_list"
          className="basic-multi-select w-[80%] "
          classNamePrefix="Select"
          options={combinedOptions}
          onChange={(selectedOption) => handleShareDocs(selectedOption)}
        />
        <span>
          <button
            className="formControl ml-2  mr-2 "
            onClick={() => handleSubmit()}
          >
            Share access
          </button>
        </span>
      </div>

      {show && (
        <div className=" overflow-x-auto h-[20vh] whitespace-nowrap w-full mt-2">
          {employeeListData?.data?.length > 0 ? (
            <>
              {employeeListData?.data?.length > 0 &&
                employeeListData?.data?.map((employeeList, index) => {
                 
                  return (
                    <div key={index} className=" w-full ">
                      <div className="form-group mb-2 ">
                        <div className="w-full">
                          <div className="flex items-center w-full">
                            <div className="w-8 h-8 mr-3 rounded-full bg-gray-700 flex items-center justify-center text-md uppercase text-white">
                              {employeeList?.first_name[0]}
                              {employeeList?.last_name[0]}
                            </div>
                            <div className="flex justify-between w-11/12">
                              <div className="pr-4">
                                <h3 className="text-[14px]">
                                  {employeeList?.first_name}{" "}
                                  {employeeList?.last_name}
                                </h3>
                                <span>{employeeList?.email}</span>
                              </div>
                              <Popup
                                open={openShare}
                                onClose={() => closeModal()}
                                closeOnDocumentClick={false}
                                trigger={
                                  <span
                                    onClick={() => setOpen((o) => !o)}
                                    className="fa fa-share  text-white bg-green-500 w-7 flex items-center justify-center h-7 rounded-full cursor-pointer"
                                  />
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div className="modal">
                                    <button
                                      className="close"
                                      id="closeSharefolderPopup"
                                      onClick={() => {
                                        closeModal();
                                        close();
                                      }}
                                    >
                                      &times;
                                    </button>
                                    <div className="content">
                                      <p>
                                        Are you sure you want to share this
                                        folder to {employeeList?.first_name} ?
                                      </p>
                                      <div className="w-full mt-8">
                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                          <button
                                            className="btn save mr-2"
                                            id="deleteYes"
                                            onClick={(e) =>
                                              handleShareFolder(
                                                e,
                                                employeeList?.id
                                              )
                                            }
                                          >
                                            Yes
                                          </button>
                                          <button
                                            type="button"
                                            id="deleteNo"
                                            onClick={() => close()}
                                            className="btn cancel "
                                          >
                                            No
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          ) : (
            <td className="mt-4  text-center" colSpan="12">
              No Data found
            </td>
          )}

          <Pagination
            page={page}
            pages={pages}
            changePage={setPage}
            decrementPage={decrementPage}
          />
        </div>
      )}
    </>
  );
};

export default SharedEmployee;
