// eslint-disable-next-line no-unused-vars
/* eslint-disable */
import React from "react";
import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Leave from "./Leave/leave";
import Expense from "./expense";
import OverTime from "./OvertimeApproval/overTimeApproveStatus";
import { approvalsCount } from "../../redux/AuthSlice/authSlice";
import { useDispatch } from "react-redux";

const Approvals = () => {
  const location = useLocation();
  const initialTab = location.state?.activeTab || "Leave";
  const [ toggleState, setToggleState ] = useState(initialTab);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [ leaveCount, setLeaveCount ] = useState();
  const [ overTimeCount, setOverTimeCount ] = useState();
  const [ expenseCount, setExpenseCount ] = useState();
  useEffect(() => {
    const total = (leaveCount || 0) + (overTimeCount || 0) + (expenseCount || 0);
    dispatch(approvalsCount(total));
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveCount, overTimeCount, expenseCount]);



  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Approvals</title>
          </Helmet>
        </HelmetProvider>
        <div>
          <div className="py-6">
            <div className="container mx-auto approvalPage ">
              <h2 className="text-xl mb-3 font-semibold  ">Approvals</h2>
              <div className="w-full">
                <div className="card ">
                  <div className=" px-4 border-b  ">
                    <div className="approvalTab employeeTab ">
                      <ul>
                        <li>
                          <span
                            onClick={() => toggleTab("Leave")}
                            className={
                              toggleState === "Leave" ? " active" : "  "
                            }
                          >
                            {" "}
                            <span className="fa fa-calendar" />
                            Leave<pre>({leaveCount ? leaveCount : "0"})</pre>
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => toggleTab("Expenses")}
                            className={
                              toggleState === "Expenses" ? "  active" : " "
                            }
                          >
                            {" "}
                            <span className="fa fa-credit-card" />
                            Expenses<pre>({expenseCount ? expenseCount : "0"})</pre>
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => toggleTab("Overtime")}
                            className={
                              toggleState === "Overtime" ? "  active" : " "
                            }
                          >
                            {" "}
                            <span className="fa fa-clock" />
                            Overtime/Time in Lieu<pre>({overTimeCount ? overTimeCount : "0"})</pre>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tabCard ">
                    <div
                      className={
                        toggleState === "Leave"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <Leave 
                        setLeaveCount={setLeaveCount}
                        leaveToggleState={state}
                      />
                    </div>

                    <div
                      className={
                        toggleState === "Expenses"
                          ? "tabContent tabContentActive expenses-tab"
                          : "tabContent"
                      }
                    >
                      <Expense setExpenseCount={setExpenseCount}/>
                    </div>
                    <div
                      className={
                        toggleState === "Overtime"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <OverTime 
                        setOverTimeCount={setOverTimeCount}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Approvals;
