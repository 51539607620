import { useState } from "react";
import Popup from "reactjs-popup";  


const Popupfeature = ({ text, data }) => {
  const [ open, setOpen ] = useState(false);
  const closeModal = () => {
    setOpen(false);
};

/* making json data look pretty */

  function prettyJson(data) {
    let oldDataObject = JSON.parse(data);
    const formattedData = Object?.entries(oldDataObject)?.map(([ key, value ]) => (
      <div key={key}>
        <strong>{key}:</strong> {value}
      </div>
    ));
    return formattedData;
  }
//---------------------------------

  return (
    <>
      <Popup
        open={open}
        onClose={() => closeModal()}
        closeOnDocumentClick={false}
        trigger={
          <div onClick={() => setOpen((o) => !o)}>
          {/* eye icon */}
          <i className="not-italic cursor-pointer actionBtn">View</i>
          </div>
        }
        modal
        nested
      >
        {(close) => (
          <div className="modal">
          {/* close btn */}
            <button
              className="close"
              id="messageDetailClose"
              onClick={() => {
                closeModal();
                close();
              }}
            >
              &times;
            </button>

          {/* popup header */}
            <div className="header">
              <h3>{ text }</h3>
            </div>
          
            <div className="OpenMeassage p-4">
              <div className="form-group mt-3">
                <div className="   ">
                  <p>
                    {/* making json look preetier */}
                    {prettyJson(data)}
                    {/* ---------------------- */}
                  </p>
                </div>
              </div>
            </div>

          </div>
        )}
      </Popup>
    </>
  );
};

export default Popupfeature;


