import { useState, useRef } from "react";
import { MultiStepForm, Step } from "react-multi-form";
import { FormInput, FormSelect, FormTextarea} from "../../components/formInput";
import { useEffect } from "react";
import { useAddEmployeeMutation } from "../../services/employeeApi";
import { useGetApproverListAPIMutation } from "../../services/approverListApi";
import { toast } from "react-toastify";
import { checkValidations } from "./empolyeeValidation";
import DatePicker from "react-datepicker";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";
import { calculateProRataLeave } from "../../helper/helper";

const AddEmployee = ({allDepartmentListData, closeModal, getEmployee, toggleState, roles, totalReinviteEmployeeCount}) => {

  //Selector for carrying out data from redux store
  const nextcarryForward = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.nextcarryForward);

  //States and hooks
  const [active, setActive] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [isSame, setIsSame] = useState(false);
  const [approverListData, setApproverListData] = useState();
  const [leaveListData, setLeaveListData] = useState();
  const [expenseListData, setExpenseListData] = useState();
  const [proRateStatus, setProRateStatus] = useState();
  const [isPopupChecked, setPopupChecked] = useState(false);
  const [errorValidations, setErrorValidations] = useState({
    firstName: "",
    email: "",
    gender: "",
    mobileNumber: "",
    department: "",
    designation: "",
    dob: "",
    employeeStartDate: ""
  });

  //Api Calling
  const [addEmployee, { isSuccess, isError, error: apiError, isLoading }] = useAddEmployeeMutation();
  const [getApproverListAPI, { data: approverList, isSuccess: isApproveSuccess }] = useGetApproverListAPIMutation();

  const [employeeData, setEmployeeData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    email: "",
    mobileNumber: "",
    department: "",
    roleId: "",
    employeeStartDate: "",
    annualAllowance: "",
    hoursOrDay: "1",
    proRate: "",
    maximumLeaveCarry: "",
    note: "",
    leaveApprover: "0",
    expenseApprover: "0",
    overtimeApprover: "0",
    isApproveLeave: false,
    isApproveExpense: false,
    isApproveOvertime: false,
    status: "",
    countryCode: "",
    rolesId: "4",
    designation: ""
  });

  const handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let message = checkValidations(name, value);
    setErrorValidations({ ...errorValidations, [name]: message });
    setEmployeeData({ ...employeeData, [name]: value });
    if (name === "annualAllowance") {
      let data = calculateProRataLeave(value, employeeData?.employeeStartDate, nextcarryForward);
      setProRateStatus(data);
    }
  };

  const handleDate = (date, name) => {
    setEmployeeData({ ...employeeData, [name]: date });
    if (name === "dob") {
      if (name === "") {
        setErrorValidations((errorValue) => {
          return {
            ...errorValue,
            dob: "Please select date of birth "
          };
        });
      } else {
        setErrorValidations((errorValue) => {
          return {
            ...errorValue,
            dob: ""
          };
        });
      }
    }
    if (name === "employeeStartDate") {
      if (name === "") {
        setErrorValidations((errorValue) => {
          return {
            ...errorValue,
            employeeStartDate: "Please select employeeStartDate "
          };
        });
      } else {
        setErrorValidations((errorValue) => {
          return {
            ...errorValue,
            employeeStartDate: ""
          };
        });
      }
    }

    if (name === "employeeStartDate" && employeeData.annualAllowance !== "") {
      let data = calculateProRataLeave(employeeData.annualAllowance, date, nextcarryForward);
      setProRateStatus(data);
    }
  };

  const toastId = useRef(null);

  useEffect(() => {
      if (isLoading) {
        toastId.current = toast.loading("Please wait...", {
          // position: "top-center",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccess) {
        let payload = `/employee?page=${1}&status=${
          toggleState === "Leavers" ? "0" : "1"
        }`;
        getEmployee(payload);
        toast.dismiss(toastId.current);
        toast.success("Employee added successfully.", {
          render: "Employee added successfully.",
          type: "success",
          autoClose: 8000,
          isLoading: false
        });
        closeModal();
        let payload1 = `/employee?page=${1}&status=${
          toggleState === "Leavers" ? "0" : "1"
        }&limit=100`;
        totalReinviteEmployeeCount(payload1);
      }
      if (isError) {
        toast.dismiss(toastId.current);
        const toastMessage = apiError?.data?.message
          ? apiError?.data?.message
          : "Something went wrong!";
        toast.success(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          duplicate: false,
          isLoading: false
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, isError, isLoading]
  );

  useEffect(() => {
      if (approverList?.data?.leaveApprover.length > 0) {
        setLeaveListData(approverList.data.leaveApprover);
      }
      if (approverList?.data?.expenseApprover.length > 0) {
        setExpenseListData(approverList.data.expenseApprover);
      }
      if (approverList?.data?.ovetime_lieuApprover.length > 0) {
        setApproverListData(approverList.data.ovetime_lieuApprover);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isApproveSuccess]
  );

  const handelMultiApprove = () => {
    if (employeeData.leaveApprover == 0) {
      setIsSame(false);
    } else {
      setIsSame(!isSame);
    }
  };

  useEffect(() => {
      if (isSame) {
        setEmployeeData({
          ...employeeData,
          overtimeApprover: employeeData.leaveApprover,
          expenseApprover: employeeData.leaveApprover
        });
      } else {
        setEmployeeData({...employeeData,
          overtimeApprover: "0",
          expenseApprover: "0"
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSame]
  );

  const handleCheckApprovers = (e) => {
    let { name } = e.target;
    let fieldName =
      name === "isApproveLeave"
        ? employeeData.isApproveLeave
        : name === "isApproveExpense"
        ? employeeData.isApproveExpense
        : name === "isApproveOvertime"
        ? employeeData.isApproveOvertime
        : "";
    setEmployeeData({ ...employeeData, [name]: !fieldName });
  };

  useEffect(() => {
      if (isChecked) {
        setEmployeeData({ ...employeeData, proRate: 1 });
      } else {
        setEmployeeData({ ...employeeData, proRate: 0 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked]
  );
  const handelCountryCode = (e) => {
    const codeRegex = /^\+[0-9]{1,3}$/;
    const plusRegex = /\+/;
    let isCode = codeRegex.test(e.target.value);
    setEmployeeData({ ...employeeData, countryCode: e.target.value });
    if (!plusRegex.test(e.target.value)) {
      setErrorValidations({
        ...errorValidations,
        countryCode: "Country code must Contains + "
      });
    } else if (isCode) {
      setErrorValidations({ ...errorValidations, countryCode: "" });
    } else {
      setErrorValidations({
        ...errorValidations,
        countryCode: "Please enter a valid country code"
      });
    }
  };
  const genderData = [
    { value: 0, name: "Select Gender" },
    { value: 1, name: "Male" },
    { value: 2, name: "Female" },
    { value: 3, name: "Other" }
  ];

  const validForm = () => {
    const { firstName, email, gender, employeeStartDate, department, mobileNumber, annualAllowance, hoursOrDay, lastName, maximumLeaveCarry, designation, dob, countryCode } = employeeData;
    let isFormValidated = true;
    if (firstName === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          firstName: "Please enter first name"
        };
      });
      isFormValidated = false;
    }
    if (employeeStartDate === "" || employeeStartDate === null) {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          employeeStartDate: "Please enter start date"
        };
      });
      isFormValidated = false;
    }
    isFormValidated = true;
    if (lastName === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          lastName: "Please enter last name"
        };
      });
      isFormValidated = false;
    }
    if (email === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          email: "Please enter the email"
        };
      });
      isFormValidated = false;
    }
    if (gender === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          gender: "Please select gender"
        };
      });
      isFormValidated = false;
    }
    if (department === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          department: "Please select department"
        };
      });
      isFormValidated = false;
    }
    if (mobileNumber === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          mobileNumber: "Please enter the mobile number"
        };
      });
      isFormValidated = false;
    }

    if (annualAllowance === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          annualAllowance: "Please enter the annual allowance"
        };
      });
      isFormValidated = false;
    }
    if (hoursOrDay === "0") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          hoursOrDay: "Please select Hours Or Day"
        };
      });
      isFormValidated = false;
    }

    if (maximumLeaveCarry === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          maximumLeaveCarry: "Please enter data"
        };
      });
      isFormValidated = false;
    }
    if (designation === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          designation: "Please enter designation"
        };
      });
      isFormValidated = false;
    }
    if (dob === "" || dob === null) {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          dob: "Please enter date of birth"
        };
      });
      isFormValidated = false;
    }

    if (countryCode === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          countryCode: "Please enter country code"
        };
      });
      isFormValidated = false;
    }
    return isFormValidated;
  };

  const handelNext = (e) => {
    e.preventDefault();
    if (validForm()) {
      setPopupChecked(true);
    }
  };

  const handleNext2 = (e) => {
    e.preventDefault();
    if (validForm()) {
      if (isPopupChecked) {
        setEmployeeData((prevData) => ({
          ...prevData,
          annualAllowance: proRateStatus.toString()
        }));
      }
      if (employeeData.department === "") {
        alert("Please Fill all the Fields");
      } else {
        let payload = `employee/approver/list/?department_id=${employeeData?.department}`;
        getApproverListAPI(payload);

        setActive(active + 1);
      }
      setIsChecked(true);
      setPopupChecked(false);
    }
  };

  const handleNoClick = (e) => {
    e.preventDefault();
    if (validForm()) {
      if (employeeData.department === "") {
        alert("Please Fill all the Fields");
      } else {
        let payload = `employee/approver/list/?department_id=${employeeData?.department}`;
        getApproverListAPI(payload);

        setActive(active + 1);
      }
      setIsChecked(false);
      setPopupChecked(false);
    }
  };

  const handelAddEmployee = () => {
    addEmployee(employeeData);
  };

  return (
    <>
      <div className="header">
        <h3>Add Employee</h3>
      </div>
      <div className="content">
        <div className="MultiFrom">
          <MultiStepForm activeStep={active} accentColor="#023a51">
            <Step label=" General Information" className="MultiFrom">
              <div className="card border p-3">
                <form>
                  <h3 className="sm:hidden block mb-3 text-base">
                    General Information
                  </h3>
                  <div className="flex flex-wrap ">
                    <div className="md:w-1/2 md:pr-6 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          First Name
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="firstName"
                          inputID="FirstName"
                          value={employeeData.firstName}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.firstName}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full md:pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Last Name
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="lastName"
                          inputID="LastName"
                          value={employeeData.lastName}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.lastName}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 md:pr-6 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Date of Birth
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <DatePicker
                          name="dob"
                          dateFormat="dd/MM/yyyy"
                          selected={employeeData?.dob}
                          id="dob"
                          onChange={(date) => handleDate(date, "dob")}
                          placeholderText={"DD/MM/YYYY"}
                          className="formControl"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.dob ? errorValidations?.dob : ""}
                        </div>
                      </div>
                    </div>

                    <div className="md:w-1/2 w-full lg:pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock">
                          Gender
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>

                        <select
                          name="gender"
                          value={employeeData.gender}
                          id="gender"
                          defaultValue={employeeData.gender}
                          onChange={handleChange}
                          className="formControl"
                        >
                          {genderData?.map((gender, index) => {
                            return (
                              <FormSelect
                                key={index}
                                optionValue={gender.value}
                                optionName={gender.name}
                              />
                            );
                          })}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.gender}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full md:pr-6">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Email
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="email"
                          inputID="Email"
                          value={employeeData.email}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.email}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Mobile Number
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <div className="flex">
                          <input
                            type="text"
                            name="countryCode"
                            id="mobile"
                            value={employeeData.countryCode}
                            onChange={(e) => handelCountryCode(e)}
                            className="appearance-none w-20 bg-gray-100 border mr-2 rounded text-gray-700 py-2 px-3 focus:outline-none focus:border-gray-500"
                            placeholder="+44"
                          />
                          <FormInput
                            inputType="text"
                            inputName="mobileNumber"
                            inputID="MobileNumber"
                            value={employeeData.mobileNumber}
                            handlerChange={handleChange}
                          />
                        </div>
                        <div className="help-block text-red-700">
                          {errorValidations?.countryCode
                            ? errorValidations?.countryCode
                            : errorValidations?.mobileNumber
                            ? errorValidations?.mobileNumber
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="md:w-1/2 w-full md:pr-6">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Department
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <select
                          name="department"
                          id="department"
                          value={employeeData?.department}
                          onChange={handleChange}
                          className="formControl"
                        >
                          <FormSelect
                            optionValue={"0"}
                            optionName={"Select Department"}
                          />
                          ;
                          {allDepartmentListData?.length > 0
                            ? allDepartmentListData?.map((departments, key) => {
                                return (
                                  <FormSelect
                                    key={key}
                                    optionValue={departments?.id}
                                    optionName={departments?.department_name}
                                  />
                                );
                              })
                            : null}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.department}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full pr-0">
                      <div className="form-group mb-6">
                        <label className="formBlock">Role</label>
                        <select
                          className="formControl"
                          name="rolesId"
                          id="role"
                          onChange={handleChange}
                        >
                          <option value="">Select Role</option>
                          {roles?.roles?.map((role, index) => (
                            <option
                              key={index + 1}
                              value={role?.id}
                              selected={role?.id === 4}
                            >
                              {role?.role_name}
                            </option>
                          ))}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.rolesId}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 md:pr-6 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock">
                          Start date
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <DatePicker
                          id="startDate"
                          name="employeeStartDate"
                          dateFormat="dd/MM/yyyy"
                          selected={employeeData.employeeStartDate}
                          onChange={(date) =>
                            handleDate(date, "employeeStartDate")
                          }
                          placeholderText={"DD/MM/YYYY"}
                          className="formControl"
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.employeeStartDate
                            ? errorValidations?.employeeStartDate
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 md:pr-0 w-full ">
                      <div className="flex flex-wrap ">
                        <div className="form-group mb-2 md:pr-2 w-full md:w-1/2 ">
                          <label className="formBlock	">
                            Annual Allowance
                            <span
                              className="fa fa-question w-4 ml-1 text-center h-4 inline-flex text-sm items-center rounded-full text-white bg-gray-800 p-1"
                              title="Please enter the employees standard annual leave allowance."
                            />
                          </label>
                          <FormInput
                            inputType="text"
                            inputName="annualAllowance"
                            inputID="AnnualAllowance"
                            value={employeeData?.annualAllowance}
                            handlerChange={handleChange}
                          />
                          <div className="help-block text-red-700">
                            {errorValidations?.annualAllowance}
                          </div>
                        </div>

                        <div className="form-group mb-2 w-full md:w-1/2">
                          <label className="formBlock">
                            Hours or Days
                            <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]"/>
                          </label>
                          <select
                            name="hoursOrDay"
                            id="hourDays"
                            value={employeeData.hoursOrDay}
                            onChange={handleChange}
                            className="formControl"
                          >
                            <option value="1">Hours</option>
                            <option value="2">Days</option>
                          </select>
                          <div className="help-block text-red-700">
                            {errorValidations?.hoursOrDay}
                          </div>
                        </div>
                        <div className="form-group w-full md:flex md:mb-0 mb-6 "/>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full md:pr-6 ">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Maximum leave to carry over
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="maximumLeaveCarry"
                          inputID="carryOver"
                          value={employeeData.maximumLeaveCarry}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.maximumLeaveCarry
                            ? errorValidations?.maximumLeaveCarry
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full md:pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Job Title
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="designation"
                          inputID="designation"
                          value={employeeData.designation}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.designation
                            ? errorValidations?.designation
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="w-full pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Notes</label>
                        <FormTextarea
                          textName="note"
                          inputID="note"
                          value={employeeData.note}
                          handlerChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Step>
            <Step label=" Authorization Settings">
              <div className="card border p-3">
                <h3 className="sm:hidden block mb-3 text-base">
                  Authorization Settings
                </h3>
                <div className="flex justify-between w-full flex-wrap">
                  <div className="md:w-1/2 md:pr-6 w-full">
                    <h4 className="mb-3 bg-primary text-white p-3 rounded text-base  ">
                      Approver settings:
                    </h4>
                    <p>
                      This is used to allocate which employee will approve this
                      employee’s requests
                    </p>
                    <div className="w-full mt-4">
                      <div className="form-group mb-2 ">
                        <label className="formBlock	">Leave Approver</label>
                        <select
                          name="leaveApprover"
                          id="leaveApprove"
                          value={employeeData.leaveApprover}
                          disabled={isSame}
                          onChange={handleChange}
                          className="formControl"
                        >
                          <FormSelect optionValue={"0"} optionName={"Select"} />
                          ;
                          {leaveListData?.map((approver, index) => {
                            return (
                              <FormSelect
                                key={index}
                                optionValue={approver.id}
                                optionName={`${approver?.first_name} ${approver?.last_name}`}
                              />
                            );
                          })}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.leaveApprover}
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="form-group mb-3 ">
                        <label className="formBlock flex	">
                          <input
                            type="checkbox"
                            onChange={handelMultiApprove}
                            id="approvalCategories"
                            checked={
                              employeeData.leaveApprover == 0 ? false : isSame
                            }
                            disabled={
                              employeeData.leaveApprover == 0 ? true : false
                            }
                            className="mr-2 w-5 h-5"
                          />
                          Apply the same approver across all approval categories
                        </label>
                        <div className="help-block text-red-700" />
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="form-group mb-3 ">
                        <label className="formBlock	">Overtime Approver</label>
                        <select
                          name="overtimeApprover"
                          id="overtimeApproverSelect"
                          value={employeeData.overtimeApprover}
                          disabled={isSame}
                          onChange={handleChange}
                          className="formControl"
                        >
                          <FormSelect optionValue={"0"} optionName={"Select"} />
                          ;
                          {approverListData?.map((approver, index) => {
                            return (
                              <FormSelect
                                key={index}
                                optionValue={approver.id}
                                optionName={`${approver?.first_name} ${approver?.last_name}`}
                              />
                            );
                          })}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.overtimeApprover}
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Expenses Approver</label>
                        <select
                          name="expenseApprover"
                          id="expensesApprover"
                          disabled={isSame}
                          value={employeeData.expenseApprover}
                          onChange={handleChange}
                          className="formControl"
                        >
                          <FormSelect optionValue={"0"} optionName={"Select"} />
                          ;
                          {expenseListData?.map((approver, index) => {
                            return (
                              <FormSelect
                                key={index}
                                optionValue={approver.id}
                                optionName={`${approver?.first_name} ${approver?.last_name}`}
                              />
                            );
                          })}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.expenseApprover}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:w-1/2 w-full lg:pr-0">
                    <h4 className="mb-3 bg-secondry text-white p-3 rounded text-base">
                      Make employee an approver:
                    </h4>
                    <p>
                      This is used to give an employee ‘approver status’ so they
                      can approve requests from their allocated employees.
                    </p>
                    <div className="form-group mb-10 mt-4">
                      <label className="formBlock flex	">
                        <input
                          type="checkbox"
                          name="isApproveLeave"
                          onChange={handleCheckApprovers}
                          checked={employeeData.isApproveLeave}
                          className="mr-2 w-5 h-5"
                        />{" "}
                        Employee can approve leave requests
                      </label>
                      <div className="help-block text-red-700" />
                    </div>
                    <div className="form-group mb-10 ">
                      <label className="formBlock flex	">
                        <input
                          type="checkbox"
                          name="isApproveExpense"
                          onChange={handleCheckApprovers}
                          checked={employeeData.isApproveExpense}
                          className="mr-2 w-5 h-5"
                        />{" "}
                        Employee can approve expenses claims
                      </label>
                      <div className="help-block text-red-700" />
                    </div>
                    <div className="form-group mb-6 ">
                      <label className="formBlock flex	">
                        <input
                          type="checkbox"
                          name="isApproveOvertime"
                          onChange={handleCheckApprovers}
                          checked={employeeData.isApproveOvertime}
                          className="mr-2 w-5 h-5"
                        />
                        Employee can approve overtime/time in lieu requests
                      </label>
                      <div className="help-block text-red-700" />
                    </div>
                  </div>
                </div>
              </div>
            </Step>
          </MultiStepForm>
          <div className="form-group pt-4 pb-3 flex justify-center">
            {active !== 1 && (
              <>
                <button
                  onClick={() => setActive(active - 1)}
                  id="back"
                  className="btn cancel mr-2"
                >
                  Back
                </button>
                <button
                  onClick={handelAddEmployee}
                  className="btn save"
                  id="save"
                >
                  Save
                </button>
              </>
            )}
            {active !== 2 && (
              <button
                className="btn save"
                onClick={handelNext}
                style={{ float: "right" }}
                id="next"
              >
                Next
              </button>
            )}
            <Popup
              open={isPopupChecked}
              position="right center"
              className="proRata"
              modal
            >
              <div className="modal">
                <button
                  className="close"
                  id="proRataClose"
                  onClick={() => {
                    closeModal();
                    close();
                  }}
                />
                <h1 className="p-4 text-xl">Pro Rata Allowance?</h1>
                <div className="w-full px-4  ">
                  <p>
                    {employeeData?.firstName
                      ? employeeData?.firstName
                      : "{first name}"}{" "}
                    is starting part way through a leave year. You might want to
                    pro rata their allowance.
                  </p>
                  <p className=" flex items-center mt-4 ">
                    Set there allowance to{" "}
                    <input
                      type="text"
                      value={proRateStatus ? proRateStatus : 0}
                      style={{ borderBottom: "1px solid #000" }}
                      className="leaveDayAdd"
                      id="leaveDayAdd"
                      disabled
                    />
                  </p>
                </div>
                <div className="form-group pt-10 pb-8 flex justify-center gap-6 ">
                  <button
                    onClick={handleNext2}
                    className="btn save"
                    id="employeeYes"
                  >
                    Yes
                  </button>
                  <button
                    onClick={handleNoClick}
                    className="btn cancel mr-2"
                    id="employeeNo"
                  >
                    No
                  </button>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
