export const apiStatusHandler = (status) => {

    switch (status) {
        case 401:
            localStorage?.clear();
            window.location.reload();
            break;
        case 404:
            break;
        case 500:
            window.location.reload();
            break;
        // Add more cases as needed
        default:
            break;
    }
};

