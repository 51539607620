/* eslint-disable camelcase */

import { useState, useEffect, useCallback, useRef } from "react";
import ApprovedLeave from "./ApprovedLeave";
import { SearchField } from "../../../components/filters";
import { FormSelect } from "../../../components/formInput";
import LeaveApprove from "./LeaveApprove";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useGetLeaveTypeListMutation } from "../../../services/leaveTypesApi";
import { useParams } from "react-router-dom";
import moments from "moment";
import { CSVLink } from "react-csv";
import { useGetWorkScheduleMutation } from "../../../services/employeeScheduleApi";
import { useGetEmployeeDetailMutation } from "../../../services/employeeApi";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllLeaveAbsenceMutation } from "../../../services/leaveAbsenceApi";
import { absenceDataAction } from "../../../redux/AbsenceSlice/AbsenceSlice";
import { allLeaveDataAction } from "../../../redux/LeaveTypeSlice/leaveSlice";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const Leave = ({ setLeaveCount, leaveToggleState }) => {
   const { id } = useParams();
   const dispatch = useDispatch();
   const ignore = useRef(false);

  //  Selectors
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
   const allLeaveApiResponse = useSelector((state) => state?.persistedReducer?.leaveTypeSlice?.allLeaveData);
   const leaveListData = useSelector((state) => state?.persistedReducer?.absenceSlice?.absenceData);
   const employeeIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id );
  
   // Mutations
   const [ getWorkSchedule, { data: workscheduleData } ] = useGetWorkScheduleMutation();
   const [  getAllLeaveAbsence, { data: allLeaveListData, isSuccess: isSuccessLeaveApprove } ] = useGetAllLeaveAbsenceMutation();
   const [ getLeaveTypeList, {data: allLeaveApiResponseList, isSuccess: isSuccessLeave} ] = useGetLeaveTypeListMutation();
   const [ getEmployeeDetail ] = useGetEmployeeDetailMutation();

  // States
  const [ allLeaveToApporve, setAllLeaveToApprove ] = useState();
  const [ allApproveToLeave, setAllApproveToLeave ] = useState();
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ searchName, setSearchName ] = useState("");
  const [ startDate, endDate ] = dateRange;
  const [ allLeaveType, setAllLeaveType ] = useState();
  const [ excelData, setExcelData ] = useState([]);

  const createCsvFileName = () =>
    `Leave_Approval_myreport_${moments().format()}.csv`;

  const [ toggleState2, setToggleState2 ] = useState("LeaveApprove");
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };
  useEffect(() => {
    if (!ignore.current){
    getLeaveTypeList();
      if (auditorRoleId === 5) {
        return;
      }  
    let url = `/leaves/?leave_approver=${employeeIdData}&type=1&group=true&exclude_self=true` ;
    getAllLeaveAbsence(url);
    getWorkSchedule(employeeIdData);
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
  []);


useEffect(() => {
  if (leaveToggleState === "outstandingLeaves") {
      setToggleState2("LeaveApprove");
  } 
}, [ leaveToggleState ]);


  useEffect(() => {
    setLeaveCount(allLeaveToApporve?.length);
  });

  useEffect(
    () => {
      if (id) {
        getEmployeeDetail(id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ id ]
  );
  useEffect(() => {
    if(isSuccessLeave){
      dispatch(allLeaveDataAction(allLeaveApiResponseList));
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessLeave ]);

  useEffect(
    () => {
      if (allLeaveApiResponse) {
        setAllLeaveType(allLeaveApiResponse?.leaveType?.rows);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allLeaveApiResponse ]
  );

useEffect(() => {
  if(isSuccessLeaveApprove){
    dispatch(absenceDataAction(allLeaveListData));
  }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[  isSuccessLeaveApprove ]);

  useEffect(() => {
    if(leaveListData){
    let leaveApprove = [];
    let approvedLeave = [];
    
    leaveListData?.data?.forEach((leave) => {
        if(leave?.status === '0') {        
           const leaveApproveData ={
            employeeName : `${leave?.employeeInfo?.first_name} ${leave?.employeeInfo?.last_name}`,
            departmentName: leave?.department_id,
            date: leave?.createdAt,
            hoursInDays: leave?.hoursInDays,
            days: leave?.leave_total_duration,
            hours_or_day: leave?.employeeInfo?.hours_or_day,
            startDate: leave?.leave_start_date,
            dayHours: leave?.dayHours,
            endDate: leave?.leave_end_date,
            leaveType: leave?.leaveTypeInfo?.leave_name,
            type: leave?.type,
            status: leave?.status,
            id :leave?.id,
            total_leave_days: leave?.total_leave_days,
            employee_id: leave?.employee_id,
            leaveTypeId: leave?.leave_type,
            reason: leave?.reason,
            startTime: leave?.start_time,
            endTime: leave?.end_time,
            isPrivate: leave?.is_private,
            totalDuration: leave?.total_duration,
            notes:leave?.notes,
            leaveRestrictedDay: leave?.is_restricted_day,
            appliedBy : `${leave?.addedByInfo?.first_name || ''} ${leave?.addedByInfo?.last_name || ''}`,
            approvedBy: `${leave?.approvedByInfo?.first_name || ''} ${leave?.approvedByInfo?.last_name || ''}`,
            department_leave_limit_exceeded: leave?.department_leave_limit_exceeded,
            leave_group_identifier: leave?.leave_group_identifier
           };
           leaveApprove.push(leaveApproveData);
        } else {

          const leaveApproveData ={
            employeeName : `${leave?.employeeInfo?.first_name} ${leave?.employeeInfo?.last_name}`,
            departmentName: leave?.department_id,
            date: leave?.createdAt,
            days: leave?.leave_total_duration,
            startDate: leave?.leave_start_date,
            hoursInDays: leave?.hoursInDays,
            endDate: leave?.leave_end_date,
            dayHours: leave?.dayHours,
            hours_or_day: leave?.employeeInfo?.hours_or_day,
            leaveType: leave?.leaveTypeInfo?.leave_name,
            type: leave?.type,
            status: leave?.status,
            reason: leave?.reason,
            total_leave_days: leave?.total_leave_days,
            notes:leave?.notes,
            totalDuration: leave?.total_duration,
            id :leave?.id,
            startTime: leave?.start_time,
            endTime: leave?.end_time,
            employee_id: leave?.employee_id,
            isPrivate: leave?.is_private,
            leaveTypeId: leave?.leaveTypeInfo?.id,
            leaveRestrictedDay: leave?.is_restricted_day,
            appliedBy : `${leave?.addedByInfo?.first_name || ''} ${leave?.addedByInfo?.last_name || ''}`,
            approvedBy: `${leave?.approvedByInfo?.first_name || ''} ${leave?.approvedByInfo?.last_name || ''}`,
            department_leave_limit_exceeded: leave?.department_leave_limit_exceeded,
            leave_group_identifier: leave?.leave_group_identifier
           };
           approvedLeave.push(leaveApproveData);
        }
     });
     setAllLeaveToApprove(leaveApprove);
     setAllApproveToLeave(approvedLeave);
     if(leaveListData?.data){
      let csvData = exportTOCSV(leaveListData?.data);
      setExcelData(csvData);
     }
    }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[  leaveListData ]);

  let headers = [
    { label: "Name", key: "Name" },
    { label: "DepartmentName", key: "dept_name" },
    { label: "Start Date", key: "start_date" },
    { label: "Start Time", key: "start_time" },
    { label: "End Date", key: "end_date" },
    { label: "End Time", key: "end_time" },
    { label: "Duration(Days/Hours)", key: "total_duration" },
    { label: "Status", key: "status" },
    { label: "Type", key: "type" },
    { label: "AppliedBy", key: "applied_by" },
    { label: "Approved By", key: "approved_by" },
    { label: "Approved Date", key: "approved_date" },
    { label: "Reason", key: "reason" },
    { label: "Notes", key: "notes" },
    { label: "Cancel Reason", key: "cancel_reason" },
    { label: "CreatedAt", key: "createdAt" },
    { label: "UpdatedAt", key: "updatedAt" },
    { label: "DeletedAt", key: "deletedAt" }
  ];

  const exportTOCSV = (employee) => {
    let dataAbence = [];
    if(employee){
      employee.forEach((dExcel) => {
        let hour = parseInt(dExcel.total_duration) / 60;
        let days = hour / workscheduleData?.dayHours;
        let val = {
          Name:
            dExcel?.employeeInfo?.first_name +
            " " +
            dExcel?.employeeInfo?.last_name,
          dept_name: dExcel?.departmentInfo?.department_name,
          start_date: moments(dExcel?.start_date).format("DD-MM-YYYY"),
          start_time: dExcel?.start_time,
          end_date: moments(dExcel?.end_date).format("DD-MM-YYYY"),
          end_time: dExcel?.end_time,
          total_duration: days,
          status:
            dExcel?.status === "1"
              ? "Apporved"
              : dExcel?.status === "0"
              ? "Applied"
              : "Cancel",
          type:
            dExcel.type === "3" ? "My leave" : dExcel.type === "1" ? "Leave" : "",
          applied_by: dExcel?.addedByInfo?.first_name,
          approved_by: dExcel?.approvedByInfo?.first_name,
          approved_date:
            dExcel?.approved_date !== null || dExcel?.approved_date === ""
              ? moments(dExcel?.approved_date).format("DD-MM-YYYY")
              : "",
          notes: dExcel?.notes,
          reason: dExcel.reason,
          cancel_reason: dExcel?.cancel_reason,
          createdAt: moments(dExcel?.createdAt).format("DD-MM-YYYY"),
          updatedAt: moments(dExcel?.updatedAt).format("DD-MM-YYYY"),
          deletedAt: dExcel?.deletedAt
            ? moments(dExcel?.deletedAt).format("DD-MM-YYYY")
            : "null"
        };
        dataAbence.push(val);
      });
      return dataAbence;
    }
    
  };

    const onDateChange = (e) => {
      if(e[0] !== null && e[1] !== null){
        let url = `/leaves/?leave_approver=${employeeIdData}&type=1&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}&exclude_self=true` ;
        getAllLeaveAbsence(url);
   
      } else {
        let url = `/leaves/?leave_approver=${employeeIdData}&type=1&exclude_self=true` ;
        getAllLeaveAbsence(url);
      }
    };

  const handlerLeavechange = (e) => {
    e.preventDefault();
    let leaveApprove = [];
    let approvedLeave = [];
    leaveListData?.data?.forEach((leave) => {
      if (
        leave?.status === "0" &&
        leave?.leaveTypeInfo?.id === parseInt(e.target.value)
      ) {
        const leaveApproveData = {
          employeeName: leave?.employeeInfo?.first_name,
          departmentName: leave?.department_id,
          date: leave?.createdAt,
          days: leave?.leave_total_duration,
          hoursInDays: leave?.hoursInDays,
          startDate: leave?.leave_start_date,
          endDate: leave?.leave_end_date,
          dayHours: leave?.dayHours,
          total_leave_days: leave?.total_leave_days,
          hours_or_day: leave?.employeeInfo?.hours_or_day,
          leaveType: leave?.leaveTypeInfo?.leave_name,
          type: leave?.type,
          status: leave?.status,
          id: leave?.id,
          employee_id: leave?.employee_id,
          leaveTypeId: leave?.leave_type
        };
        leaveApprove.push(leaveApproveData);
      } else {
        if (leave.leaveTypeInfo?.id === parseInt(e.target.value)) {
          const leaveApproveData = {
            employeeName: leave?.employeeInfo?.first_name,
            departmentName: leave?.department_id,
            date: leave?.createdAt,
            days: leave?.leave_total_duration,
            hoursInDays: leave?.hoursInDays,
            startDate: leave?.leave_start_date,
            endDate: leave?.leave_end_date,
            total_leave_days: leave?.total_leave_days,
            dayHours: leave?.dayHours,
            hours_or_day: leave?.employeeInfo?.hours_or_day,
            leaveType: leave?.leaveTypeInfo?.leave_name,
            type: leave?.type,
            status: leave?.status,
            id: leave?.id,
            employee_id: leave?.employee_id,
            leaveTypeId: leave?.leaveTypeInfo?.id
          };
          approvedLeave.push(leaveApproveData);
        }
      }
    });
    setAllLeaveToApprove(leaveApprove);
    setAllApproveToLeave(approvedLeave);

    if (e.target.value === "0") {
      leaveListData.data.forEach((leave) => {
        if (leave?.status === "0") {
          const leaveApproveData = {
            employeeName: leave?.employeeInfo?.first_name,
            departmentName: leave?.department_id,
            date: leave?.createdAt,
            days: leave?.leave_total_duration,
            hoursInDays: leave?.hoursInDays,
            startDate: leave?.leave_start_date,
            endDate: leave?.leave_end_date,
            dayHours: leave?.dayHours,
            total_leave_days: leave?.total_leave_days,
            hours_or_day: leave?.employeeInfo?.hours_or_day,
            leaveType: leave?.leaveTypeInfo?.leave_name,
            type: leave?.type,
            status: leave?.status,
            id: leave?.id,
            employee_id: leave?.employee_id,
            leaveTypeId: leave?.leave_type
          };
          leaveApprove.push(leaveApproveData);
        } else {
          const leaveApproveData = {
            employeeName: leave?.employeeInfo?.first_name,
            departmentName: leave?.department_id,
            date: leave?.createdAt,
            days: leave?.leave_total_duration,
            hoursInDays: leave?.hoursInDays,
            startDate: leave?.leave_start_date,
            endDate: leave?.leave_end_date,
            total_leave_days: leave?.total_leave_days,
            dayHours: leave?.dayHours,
            hours_or_day: leave?.employeeInfo?.hours_or_day,
            leaveType: leave?.leaveTypeInfo?.leave_name,
            type: leave?.type,
            status: leave?.status,
            id: leave?.id,
            employee_id: leave?.employee_id,
            leaveTypeId: leave?.leaveTypeInfo?.id
          };
          approvedLeave.push(leaveApproveData);
        }
      });
      setAllLeaveToApprove(leaveApprove);
      setAllApproveToLeave(approvedLeave);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 300);
    };
  };

    const handleChange = (value) => {
      setSearchName(value);
      let url = `/leaves/?first_name=${value}&leave_approver=${employeeIdData}&type=1&exclude_self=true` ;
      getAllLeaveAbsence(url);
    };

  const optimizedFn = useCallback(debounce(handleChange), []);

  return (
    <>
      <div className=" mb-3">
        <div className="p-4">
          <div className="flex pb-6 pt-2 filterSection flex-wrap lg:flex-nowrap w-full justify-between ">
            <div className="w-full flex flex-wrap">
              <div className="searchSec md:w-1/3 md:pr-4 w-full mb-2 lg:mb-0 ">
                <SearchField
                  placeholderText="Search By Name"
                  onChange={(e) => optimizedFn(e.target.value)}
                  value={searchName}
                  id="searchLeave"
                />
              </div>
              <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 ">
                <DatePicker
                  selectsRange={true}
                  id="leaveDatePicker"
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  className="formControl"
                  placeholderText="Select Date Range"
                  onChange={(update) => {
                    setDateRange(update);
                    onDateChange(update);
                  }}
                  isClearable={true}
                  locale="en-GB"
                />
              </div>
              <div className=" searchSec md:w-1/3 w-full md:mb-0 mb-4 lg:pr-4">             
                <select
                  className="formControl"
                  onChange={(e) => handlerLeavechange(e)}
                  id="leaveFilter"
                >
                  <FormSelect
                    optionValue={"0"}
                    optionName={"Select Leave Type"}
                  />
                  {allLeaveType?.length > 0
                    ? allLeaveType?.map((leave, key) => {
                        return (
                          <FormSelect
                            key={key}
                            optionValue={leave?.id}
                            optionName={leave?.leave_name}
                          />
                        );
                      })
                    : null}
                </select>
              </div>
            </div>

            <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full">
              <CSVLink
                className=""
                data={excelData}
                headers={headers}
                filename={createCsvFileName()}
                target="_blank"
                style={{
                  textDecoration: "none",
                  outline: "none",
                  height: "5vh"
                }}
              >
                <button className="btn-md btn-add " id="exportCsv">
                  <span className="fa fa-file-export mr-2" /> Export to CSV
                </button>
              </CSVLink>
            </div>
          </div>
          <ul className="flex approvalSubTab mb-8 ">
            <li>
              <span
                onClick={() => toggleTab2("LeaveApprove")}
                className={toggleState2 === "LeaveApprove" ? " active" : "  "}
              >
                Leave to Approve
              </span>
            </li>
            <li>
              <span
                onClick={() => toggleTab2("ApprovedLeave")}
                className={toggleState2 === "ApprovedLeave" ? " active" : "  "}
              >
                Approved Leave
              </span>
            </li>
          </ul>
          <div className="tabCard ">
            <div
              className={
                toggleState2 === "LeaveApprove"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
            <LeaveApprove 
            allLeaveToApporve={allLeaveToApporve}
              getLeaveApproveList={getAllLeaveAbsence}
              employeeId ={employeeIdData}
              workscheduleData={workscheduleData}
              getLeaveApproveListRespo={leaveListData}
            />
            </div>
            <div
              className={
                toggleState2 === "ApprovedLeave"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <ApprovedLeave 
              allApproveToLeave={allApproveToLeave}
              getLeaveApproveList={getAllLeaveAbsence}
              employeeId ={employeeIdData}
              workscheduleData={workscheduleData}
              getLeaveApproveListRespo={leaveListData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leave;
