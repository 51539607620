import { useState, useEffect, useRef, useMemo } from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import moments from "moment";
import { useBulkOvertimeApporveMutation, useChangesStatusOverTimeApporveMutation } from "../../../services/approverListApi";
import { FormTextarea } from "../../../components/formInput";
import PrimaryButton from "../../../components/primaryButton";
import NoDataFound from "../../../helper/noDataFound";
import Pagination from "../../../components/inc/pagination";
import { toast } from "react-toastify";
import OvertimeDetails from "./OvertimeDetails";
import ApproveCancel from "./ApproveCancel";
import { LoadingIndicator } from "../../../components/loading-indicator";
import { useSelector } from "react-redux";

const OverTimeApprove = ({ isLoading, allOverTimeToApporve, employeeOverTimeApporve, employeeId }) => {

  //Selector for carrying out data from redux
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);

  //States and hooks
  const [formData, setFormData] = useState();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [approvedList, setApprovedList] = useState();
  const pageSize = 10;
  const toastId = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedOvertime, setSelectedOvertime] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  //Api calling
  const [changesStatusOverTimeApporve, {isSuccess: isSuccessStatus, isError: isErrorStatus, isLoading: isLoadingStatus }] = useChangesStatusOverTimeApporveMutation();
  const [ bulkOvertimeApporve, { isSuccess, isError, isLoading: bulkOvertimeLoading } ] = useBulkOvertimeApporveMutation();

  useEffect(() => {
      if (allOverTimeToApporve) {
        let totalPages = Math.ceil(allOverTimeToApporve.length / pageSize);
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allOverTimeToApporve?.slice(firstPageIndex, lastPageIndex);
        setApprovedList(data);
        setPages(totalPages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allOverTimeToApporve]
  );

  // eslint-disable-next-line no-unused-vars
  const currentTableData = useMemo(() => {
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allOverTimeToApporve?.slice(firstPageIndex, lastPageIndex);
      setApprovedList(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  // eslint-disable-next-line no-unused-vars
  const decrementPage = () => {
    const lastPageIndex = (page - 1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allOverTimeToApporve?.slice(lastPageIndex, firstPageIndex);
    setApprovedList(data);
  };

  const handlerChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    setFormData({ ...formData, [name]: e.target.value });
  };

  useEffect(() => {
      if (isLoadingStatus) {
        toastId.current = toast.loading("Please wait...", {
          // position: "top-center",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessStatus) {
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 10000);
        toast.update(
          toastId.current,
          ("Overtime approved successfully!",
          {
            render: "Overtime approved successfully!",
            type: "success",
            autoClose: 8000,
            isLoading: false
          })
        );
        let payload = `overtimes?overtime_lieu_approver=${employeeId}&page=1&limit=100`;
        employeeOverTimeApporve(payload);
      }
      if (isErrorStatus) {
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 10000);
        const toastMessage = "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 8000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessStatus, isErrorStatus, isLoadingStatus]
  );

  useEffect(() => {
    if (bulkOvertimeLoading) {
      toastId.current = toast.loading("Please wait...", {
        // position: "top-center",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccess) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.update(
        toastId.current,
        ("Overtime approved/rejected successfully!",
        {
          render: "Overtime approved/rejected successfully!",
          type: "success",
          autoClose: 8000,
          isLoading: false
        })
      );
      let payload = `overtimes?overtime_lieu_approver=${employeeId}&page=1&limit=100`;
      employeeOverTimeApporve(payload);
      setSelectedOvertime([]);
      setSelectAll(false);
      setShowActionButtons(false);
    }
    if (isError) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      const toastMessage = "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          isLoading: false
        })
      );
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [isSuccess, isError, bulkOvertimeLoading]
);

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = approvedList.map((expense) => expense.id);
      setSelectedOvertime(allIds);
      setShowActionButtons(true);
    } else {
      setSelectedOvertime([]);
      setShowActionButtons(false);
    }
    setSelectAll(isChecked);
  };
  
  const handleSelectOvertime = (e, id) => {
    if (e.target.checked) {
      setSelectedOvertime((prev) => [...prev, id]);
      setShowActionButtons(true);
    } else {
      const updatedExpenses = selectedOvertime.filter((expenseId) => expenseId !== id);
      setSelectedOvertime(updatedExpenses);
      if (updatedExpenses.length === 0) {
        setShowActionButtons(false);
      }
    }
  };  

  const onHandlerApporve = (e, over, close) => {
    e.preventDefault();
    const value = {
      status: "1",
      notes: formData?.notes,
      id: over?.id
    };
    changesStatusOverTimeApporve(value);
    close();
  };

  const onHandlerBulkApporve = (e) => {
    e.preventDefault();
    const val = {
      notes: "Approved",
      status: "1",
      overtimeIds: selectedOvertime
    };  
    bulkOvertimeApporve(val);
    setFormData("");
  };

  const onHandlerBulkReject = (e) => {
    e.preventDefault();
    const val = {
      notes: "Reject",
      status: "2",
      overtimeIds: selectedOvertime
    };  
    bulkOvertimeApporve(val);
    setFormData("");
  };

  return (
    <div>

      
        {showActionButtons && (
        <div className="action-buttons space-x-2 mb-4">
          <button
            className="btn save"
            onClick={onHandlerBulkApporve}
            disabled={selectedOvertime.length === 0}
          >
            Approve
          </button>
          <button
            className="btn save"
            onClick={onHandlerBulkReject}
            disabled={selectedOvertime.length === 0}
          >
            Reject
          </button>
          <button
            className="btn cancel"
            onClick={() => {
              setSelectedOvertime([]);
              setSelectAll(false);
              setShowActionButtons(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
      <div className="overflow-x-auto w-full whitespace-nowrap">
        <table className="table-auto table  w-full">
          <thead className="thead-dark ">
            <tr>
              {allOverTimeToApporve?.length > 0 ? ( 
                <>
                <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                />
              </th>
              </> ) : ("")}
              <th className="w-40"> Employee</th>
              <th>Overtime Type</th>
              <th>Date Type</th>
              <th>Time Period</th>
              <th>Duration</th>
              <th>Time in Lieu</th>
              <th>Action</th>
            </tr>
          </thead>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <tbody>
              {allOverTimeToApporve?.length > 0 ? (
                <>
                  {approvedList &&
                    approvedList.map((over, index) => {
                      //over?.duration format to hours and min
                      const hours = Math.floor(over?.duration / 60);
                      const minutes = over?.duration % 60;
                      let formattedDuration = "";
                      //if duration is less than 1 hour then do not show hour
                      if (hours > 0) {
                        formattedDuration = `${hours} hour${
                          hours !== 1 ? "s" : ""
                        }`;
                      }
                      //if min = 0 then do not show min
                      if (minutes > 0) {
                        formattedDuration += `${
                          formattedDuration ? " " : ""
                        }${minutes} min${minutes !== 1 ? "s" : ""}`;
                      }

                      // removing seconds in time period code
                      const startTimeArray = over?.start_time?.split(":");
                      const endTimeArray = over?.end_time?.split(":");
                      const formattedStartTime = startTimeArray
                        ?.slice(0, 2)
                        .join(":");
                      const formattedEndTime = endTimeArray
                        ?.slice(0, 2)
                        .join(":");

                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedOvertime.includes(over.id)}
                              onChange={(e) => handleSelectOvertime(e, over.id)}
                            />
                          </td>
                          <td>
                            <Link
                              to={`/employees/detail/${over?.employees_id}`}
                              className="listImage flex items-center "
                            >
                              {`${over?.employeeInfo?.first_name} ${over?.employeeInfo?.last_name}`}
                            </Link>
                          </td>
                          <td>{over?.mOverTimeInfo?.overtime_name}</td>
                          <td>
                            {moments(over?.start_date).format(formattedDate)}
                          </td>
                          <td>
                            {formattedStartTime}-{formattedEndTime}{" "}
                          </td>
                          <td>{formattedDuration}</td>
                          <td>{over?.time_in_lieu === "1" ? "Yes" : "No"}</td>
                          <td>
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn"
                                >
                                  <i className="fa fa-eye" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    id="overtimeApprovedClose"
                                    className="close"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <OvertimeDetails overtimeDetails={over} />
                                </div>
                              )}
                            </Popup>
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn"
                                >
                                  <i className="fa-solid fa-check" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="overtimeApproveClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    {" "}
                                    <h3>Approve</h3>
                                  </div>
                                  <div className="content">
                                    <form>
                                      <label className="formBlock	">Notes</label>
                                      <FormTextarea
                                        textName="notes"
                                        handlerChange={handlerChange}
                                        id="notesApprove"
                                      />

                                      <div className="w-full mt-8">
                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                          <button
                                            className="btn save mr-2"
                                            id="saveOvertime"
                                            onClick={(e) =>
                                              onHandlerApporve(e, over, close)
                                            }
                                          >
                                            Approve
                                          </button>
                                          <PrimaryButton
                                            btnText="Cancel"
                                            Btnclass="btn cancel"
                                            btntype="button"
                                            buttonID="overTimeCancel"
                                            onClick={() => close()}
                                          />
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </Popup>

                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn cancelBtn"
                                >
                                  <i className="fa-solid fa-xmark" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="declineOvertimeClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <ApproveCancel
                                    over={over}
                                    close={close}
                                    employeeOverTimeApporve={
                                      employeeOverTimeApporve
                                    }
                                    employeeId={employeeId}
                                  />
                                </div>
                              )}
                            </Popup>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <center className="mt-4">{<NoDataFound />}</center>
              )}
            </tbody>
          )}
        </table>
        <Pagination
          page={page}
          pages={pages}
          changePage={setPage}
          decrementPage={setPage}
        />
      </div>
    </div>
  );
};
export default OverTimeApprove;
