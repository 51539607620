/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, {useState, useRef, useEffect} from 'react';
import Popup from "reactjs-popup";
import {
  FormInput,
  FormSelect,
  FormTextarea
} from "../../components/formInput";
import DatePicker from "react-datepicker";
import PrimaryButton from "../../components/primaryButton";
import { useCreateMainExpensesMutation} from '../../services/expenseApi';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);


const AddExpense = ({allExpenseType, close, getAllMainExpenses, getAllMainExpensesCSVRecord}) => {
    const [ createMainExpense, {isSuccess: isSuccessCreate, isLoading: isLoadingCreate, isError: isErrorCreate, error:apiError } ] =  useCreateMainExpensesMutation();
    const [ startDate, setStartDate ] = useState();
    const [ fileList, setFileList ] = useState([]);
    const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
    const [ isButtonDisabled, setIsButtonDisabled ] = useState(false);
    const [ formData, setFormData ] = useState({
        employee_id: employeeIdData?.employee_id,
        start_date: new Date(),
        "end_date": "2023-06-28",
        reason: '',
        "is_mileage": "0",
        "type": "",
        amount: "",
        "amount_of_miles": "",
        "status": "0"
    });
     const [ open, setOpen ] = useState(false);
    const [ errors, setErrors ] = useState({
        typeError: '',
        amountError: '',
        reasonError: ''
      });
      const closeModal = () => {
        setOpen(false);
      };

    const toastId = useRef(null);
    const handlerChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
     
         if(name === 'type') {
            if(name === '') {
              setErrors((errorValue) => { 
                return {
                  ...errorValue,
                  typeError: 'Please select type'
                };
              });

            } else {
              setErrors((errorValue) => { 
                return {
                  ...errorValue,
                  typeError: ''
                };
              });
            }
            setFormData((formValue) => {
              return {
               ...formValue, [name]: e.target.value
              };
             }); 
        }
        else if(name === 'amount') {
            if(name === '') {
              setErrors((errorValue) => { 
                return {
                  ...errorValue,
                  amountError: 'Please enter amount '
                };
              });
            } 
            else if (name === 'amount' && parseFloat(value) < 0) {
              setErrors((prevErrors) => ({
                  ...prevErrors,
                  amountError: 'Amount must be zero or greater than zero'
              }));
            }
            else if(Number.isNaN(e.target.value)){

              setFormData((formValue) => {
                  return {
                  ...formValue, [name]: e.target.value
                  };
                });
                }else {
                setErrors((errorValue) => { 
                  return {
                    ...errorValue,
                    amountError: ''
                  };
                });
                setFormData((formValue) => {
                  return {
                  ...formValue, [name]: e.target.value
                  };
                }); 
              }
            
            }
          // else if(name === 'reason') {
          //   if(name === '') {
          //     setErrors((errorValue) => { 
          //       return {
          //         ...errorValue,
          //         reasonError: 'Please enter reason '
          //       };
          //     });
          //   } else {
          //     setErrors((errorValue) => { 
          //       return {
          //         ...errorValue,
          //         reasonError: ''
          //       };
          //     });
          //     setFormData((formValue) => {
          //       return {
          //        ...formValue, [name]: e.target.value
          //       };
          //      }); 
          //   }
          // }
    };

    const handlerDateChange = (e, name) => {
        if(name === 'start_date') {  
            setFormData((formValue) => {
            return {
             ...formValue, start_date: e
            };
           }); 
            setStartDate(e);
        }
        if(name === 'start_date') {  
            if(name === '') {
              setErrors((errorValue) => { 
                return {
                  ...errorValue,
                  start_dateError: 'Please select start date'
                }; 
              });
            } else {
              setErrors((errorValue) => { 
                return {
                  ...errorValue,
                  start_dateError: ''
                };
              });
            }
        }
    };

    const handleUpload = (e) => {
      setFileList([ ...e.target.files ]);
    };
   
    useEffect(() => {
      if (isLoadingCreate) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: 2000,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
    
      if (isSuccessCreate) {
        toast.dismiss(toastId.current);
        toast.success("Expense added successfully.", {
          render: "Expense added successfully.",
          type: "success",
          autoClose: 5000,
          isLoading: false,
          theme: "colored"
        });
        close();
        let payload = `/expenses/filter/search?page=${1}&employee_id=${employeeIdData?.employee_id}`;
        getAllMainExpenses(payload);
        let payload1 = `/expenses/filter/search?page=${1}&employee_id=${employeeIdData?.employee_id}&limit=100`;
        getAllMainExpensesCSVRecord(payload1);
      }
    
      if (isErrorCreate) {
        toast.dismiss(toastId.current);
        const toastMessage = apiError?.data?.message || "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          duplicate: false,
          isLoading: false
        });
    
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessCreate, isErrorCreate, isLoadingCreate ]);
    

      const validForm = () => {
        const {amount, type } = formData;
        let isFormValid = true ;
        if(amount === '') {
            setErrors((errorValue) => { 
            return {
              ...errorValue,
              amountError: 'Please enter amount ( zero or greater than zero ) '
            };
          });
          isFormValid = false;
        } 
        if(type === '' || type === '0') {
            setErrors((errorValue) => { 
            return {
              ...errorValue,
              typeError: 'Please select type '
            };
          });
          isFormValid = false;
        } 
        // if(reason.trim() === '') {
        //     setErrors((errorValue) => { 
        //     return {
        //       ...errorValue,
        //       reasonError: 'Please enter description '
        //     };
        //   });
        //   isFormValid = false;
        // } 
        return isFormValid;
      };

    const handlerSubmit = (e) => {
        e.preventDefault();
        if(validForm()) {
          // Disable the button
          setIsButtonDisabled(true);
          var formdata = new FormData();
         
          formdata.append("employee_id", employeeIdData?.employee_id);
          formdata.append("start_date", formData?.start_date);
          if(employeeIdData?.department_id !== null){
          formdata.append("department_id", employeeIdData?.department_id);
          }
          // expenses_type:'',
          formdata.append("end_date", formData?.end_date);
          formdata.append("reason", formData?.reason);
          formdata.append("is_mileage", formData?.is_mileage);
          formdata.append("type", formData?.type);
          formdata.append("amount", formData?.amount);
          formdata.append("amount_of_miles", formData?.amount_of_miles);
          formdata.append("status", formData?.status);
          fileList.forEach((file) => {
          formdata.append("evidence_file", file);
          });
          createMainExpense(formdata);
          // After 7 seconds, enable the button again
          setTimeout(() => {
          setIsButtonDisabled(false);
          }, 7000);
        }
    
    };

   const  handlerDelete = (e, fileName, close) => {
    e.preventDefault();
    const removeFile = fileList?.filter((f) => f.name !== fileName?.name);
    setFileList(removeFile);
    close();
  };

    return (
      <>
        <div className="header">
          <h3>Claim Expense</h3>
        </div>
        <div className="content">
          <form>
            <div className="flex flex-wrap ">
              <div className="md:w-1/2 md:pr-6 w-full">
                <div className="form-group mb-6 ">
                  <label className="formBlock	">Date of Expense</label>
                  <DatePicker
                    id="expenseDate"
                    dateFormat="dd/MM/yyyy"
                    className="formControl"
                    name="start_date"
                    selected={startDate ? startDate : new Date()}
                    onChange={(e) => handlerDateChange(e, "start_date")}
                    maxDate={new Date()}
                    selectsStart
                    startDate={startDate}
                    locale="en-GB"
                  />
                  <div className="help-block" />
                </div>
              </div>
              <div className="help-block text-red-700 mt-1">
                {errors?.start_dateError ? errors?.start_dateError : ""}
              </div>
              <div className="md:w-1/2  w-full">
                <div className="form-group mb-6 ">
                  <label className="formBlock	">
                    Expense Type
                    <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                  </label>
                  <select
                    className="formControl"
                    id="expenseType"
                    name="type"
                    onChange={(e) => handlerChange(e)}
                  >
                    <FormSelect
                      optionValue={"0"}
                      optionName={"Select Expense"}
                    />
                    {allExpenseType?.length > 0
                      ? allExpenseType?.map((exp, key) => {
                          return (
                            <FormSelect
                              key={key}
                              optionValue={exp?.id}
                              optionName={exp?.expense_name}
                            />
                          );
                        })
                      : null}
                  </select>

                  <div className="help-block" />
                  <div className="help-block text-red-700 mt-1">
                    {errors?.typeError ? errors?.typeError : ""}
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 md:pr-6 w-full">
                <div className="form-group mb-6 ">
                  <label className="formBlock	">
                    Amount
                    <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                  </label>
                  <FormInput
                    inputType="number"
                    inputName="amount"
                    inputID="Amount"
                    value={
                      Number.isNaN(formData?.amount) ? "Nan" : formData?.amount
                    }
                    handlerChange={handlerChange}
                  />
                  <div className="help-block" />
                  <div className="help-block text-red-700 mt-1">
                    {errors?.amountError ? errors?.amountError : ""}
                  </div>
                </div>
              </div>
              <div className="w-full ">
                <div className="form-group mb-6 ">
                  <label className="formBlock	">
                    Description{" "}
                    {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                  </label>
                  <FormTextarea
                    textName="reason"
                    inputID="expenseDescription"
                    handlerChange={handlerChange}
                  />
                  <div className="help-block" />
                  {/* <div className="help-block text-red-700 mt-1">{errors?.reasonError ? errors?.reasonError :''}</div> */}
                </div>
              </div>
              <div className="w-full">
                <div className="form-group mb-6 fileUploadInPopup">
                  <label className="formBlock	">
                    Upload Evidence{" "}
                    <span className="text-[11px]">
                      (Supported formats: JPEG, PNG, PDF, Word, PPT, CSV)
                    </span>
                  </label>
                  {/* <input type="file" multiple id='fileUpload'
              onChange={(e) => handlerFileEvent(e)} */}
                  {/* <FileUploadBtn  handleUpload={handleUpload}/>   */}
                  <div className="file_Upload">
                    <input
                      type="file"
                      name="file"
                      className="upload-button"
                      id="exampleFile"
                      onChange={handleUpload}
                      multiple
                    />
                    <p>Drop files here or click here to upload file.</p>
                  </div>

                  <span>Maximum upload file size:25 MB.</span>

                  <div className="help-block" />
                </div>
              </div>
              <div className="w-full ">
                <div className="form-group mb-6 ExpenseAfterUplaod">
                  <ul>
                    <>
                      {[...fileList]?.map((f, index) => {
                        return (
                          <li key={index}>
                            {f.name}
                            <span>
                              <Popup
                                open={open}
                                onClose={() => closeModal()}
                                closeOnDocumentClick={false}
                                trigger={
                                  <div
                                    onClick={() => setOpen((o) => !o)}
                                    className="actionBtn"
                                  >
                                    <i className="fa fa-trash" />
                                  </div>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div className="modal">
                                    <button
                                      className="close"
                                      id="expenseDeleteClose"
                                      onClick={() => {
                                        closeModal();
                                        close();
                                      }}
                                    >
                                      &times;
                                    </button>
                                    <div className="header">
                                      {" "}
                                      <h3>Delete</h3>
                                    </div>
                                    <div className="content">
                                      <p>
                                        Are you sure you want to delete file ?
                                      </p>
                                      <div className="w-full mt-8">
                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                          <button
                                            className="btn save mr-2"
                                            id="expenseDeleteYes"
                                            onClick={(e) =>
                                              handlerDelete(e, f, close)
                                            }
                                          >
                                            Yes
                                          </button>
                                          <button
                                            type="button"
                                            id="expenseDeleteNo"
                                            onClick={() => close()}
                                            className="btn cancel  "
                                          >
                                            No
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </span>
                          </li>
                        );
                      })}
                    </>
                  </ul>
                </div>
              </div>

              <div className="lg:w w-full  ">
                <div className="w-full mt-4">
                  <div className="form-group pt-4 pb-3 flex justify-center ">
                    <button
                      className="btn save mr-2"
                      id="addExpenseSave"
                      disabled={isButtonDisabled}
                      onClick={(e) => handlerSubmit(e)}
                    >
                      Save
                    </button>
                    <PrimaryButton
                      btnText="Cancel"
                      onClick={() => close()}
                      Btnclass="btn cancel"
                      buttonID="addExpenseCancel"
                      btntype="button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
};

export default AddExpense;