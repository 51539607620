import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import moment from "moment";
import NoDataFound from "../../../helper/noDataFound";
import CancelLeavesApprover from "./CancelLeavesApprover";
import Pagination from "../../../components/inc/pagination";
import LeaveDetails from "./LeaveDetails";
import { useSelector } from "react-redux";
import { REACT_APP_AUDITOR_ROLE_ID } from "../../../helper/constant";

const ApprovedLeave = ({allApproveToLeave, getLeaveApproveList, employeeId}) => {

  // States are defined 
  const [ open, setOpen ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(0);
  const [ approvedList, setApprovedList ] = useState();
  const pageSize = 10;
  const closeModal = () => {
    setOpen(false);
  };

  // Redux 
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);

  useEffect(() => {
      if (allApproveToLeave) {
        allApproveToLeave = allApproveToLeave.filter(
          (leave) => leave.status === "1"
        );
        let totalPages = Math.ceil(allApproveToLeave.length / pageSize);
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allApproveToLeave?.slice(firstPageIndex, lastPageIndex);
        setApprovedList(data);
        setPages(totalPages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allApproveToLeave ]
  );

  // eslint-disable-next-line no-unused-vars
  const currentTableData = useMemo(() => {
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allApproveToLeave?.slice(firstPageIndex, lastPageIndex);
      setApprovedList(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ page ]
  );

  // eslint-disable-next-line no-unused-vars
  const decrementPage = () => {
    const lastPageIndex = (page - 1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allApproveToLeave?.slice(lastPageIndex, firstPageIndex);
    setApprovedList(data);
  };

  return (
    <div className="overflow-x-auto w-full whitespace-nowrap">
      <table className="table-auto table  w-full">
        <thead className="thead-dark ">
          <tr>
            <th className="w-40"> Employee</th>
            <th>Leave Type</th>
            <th>Date </th>
            <th>Required</th>
            <th>Date From</th>
            <th>Date To</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {approvedList?.length > 0 ? (
            <>
              {approvedList &&
                approvedList.map((leave, index) => {
                  let hour = Math.round(parseInt(leave?.days / 60));
                  // const day = hour / leave?.hoursInDays;
                  return (
                    <tr key={index}>
                      <td>
                        <Link
                          to={`/employees/detail/${leave?.employee_id}`}
                          className="listImage flex items-center "
                        >
                          {leave?.employeeName}
                        </Link>
                      </td>
                      <td>{leave?.leaveType}</td>
                      <td>{moment(leave?.date).format(formattedDate)}</td>
                      <td>{leave?.hours_or_day === "1" ? `${hour} ${hour <= 1 ? "Hour" : "Hours"}` :  `${parseFloat(leave?.total_leave_days).toFixed(2)} ${"days"}`}</td>
                      <td>{moment(leave?.startDate).format(formattedDate)}</td>
                      <td>{moment(leave?.endDate).format(formattedDate)}</td>

                      <td>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa fa-eye" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="leaveDetailClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <LeaveDetails leaveDetails={leave} />
                            </div>
                          )}
                        </Popup>
                        {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn cancelBtn"
                            >
                              <i className="fa-solid fa-xmark" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="leaveCancelClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <CancelLeavesApprover
                                leave={leave}
                                getLeaveApproveList={getLeaveApproveList}
                                employeeId={employeeId}
                                close={close}
                              />
                            </div>
                          )}
                        </Popup>)}
                      </td>
                    </tr>
                  );
                })}
            </>
          ) : (
            <center className="mt-4">{<NoDataFound />}</center>
          )}
        </tbody>
      </table>
      <Pagination
        page={page}
        pages={pages}
        changePage={setPage}
        decrementPage={setPage}
      />
    </div>
  );
};

export default ApprovedLeave;
