/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {useGetAllInformationMutation} from "../../services/reportApi";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import {SearchField} from "../../components/filters";
import {FormSelect} from "../../components/formInput";
import Pagination from "../../components/inc/pagination";
import "react-datepicker/dist/react-datepicker.css";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import moments from "moment";
import moment from "moment";
import DatePicker from "react-datepicker";
import NoDataFound from "../../helper/noDataFound";
import { LoadingIndicator } from "../../components/loading-indicator";
import { useDispatch, useSelector } from "react-redux";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const ApproverReport  = () => {
  const dispatch = useDispatch();
  const ignore = useRef(false);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);
  const [ getAllInformation, {data: allApproversReponse, isSuccess: isSuccessApprovers, isLoading } ] = useGetAllInformationMutation();
  const [ getDepartmentList, {data:departmentApiResponse, isSuccess:isSuccessDepartmentApi} ] = useGetDepartmentListMutation();
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(0);
  const pageSize = 10 ;
  const [ employeeInfo, setEmployeeInfo ] = useState();
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const [ allDepartmentListData, setAllDepartmentListData ] =useState({});
  const createCsvFileName = () => `Approvers_report_${moments().format()}.csv`;
  const [ excelData, setExcelData ] = useState([]);
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);
  useEffect(() => {
    if (!ignore.current){
    let payload = `/reports/?type=approvers&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
    getAllInformation(payload);
    getDepartmentList();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[] );

  useEffect(() => {
    if(isSuccessApprovers) {
      setEmployeeInfo(allApproversReponse?.data);
      if(allApproversReponse?.data){
        let csvData = exportTOCSV(allApproversReponse?.data);
        setExcelData(csvData);
      }
      
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessApprovers ]);

  useEffect(() => {
    if(allApproversReponse?.data){
      let totalPages = Math.ceil(allApproversReponse?.data?.length /pageSize);
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allApproversReponse?.data?.slice(firstPageIndex, lastPageIndex);
        setEmployeeInfo(data);
        setPages(totalPages);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ allApproversReponse?.data ]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    const data = allApproversReponse?.data?.slice(firstPageIndex, lastPageIndex);
    setEmployeeInfo(data);
  
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

  const decrementPage = () => {
    const lastPageIndex = (page-1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allApproversReponse?.data?.slice( lastPageIndex, firstPageIndex);
    setEmployeeInfo(data);
  };

  const handlerChangeByDeptName = (e) => {
    e.preventDefault();
     if (e.target.value === '0') {
     let payload =  `/reports?type=approvers&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
      getAllInformation(payload);    
     } else {
      let payload = `/reports?type=approvers&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&department_id=${e.target.value}`;
      getAllInformation(payload); 
     } 
     
  };
  useEffect(() => {
    if(isSuccessDepartmentApi){
      dispatch(departmentListAction(departmentApiResponse));
    }
   },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccessDepartmentApi ]);

  useEffect(() => {
    if(departmentApiData){
      setAllDepartmentListData(departmentApiData?.data);
    }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ departmentApiData ]);

const onDateChange = (e) => {
  if(e[0] !== null && e[1] !== null){
    let payload=`/reports?type=approvers&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}`;
    getAllInformation(payload); 

  } else {
    let payload=`/reports?type=approvers&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
    getAllInformation(payload); 
  }
};

const filterEmployees = useCallback((searchTerm) => {
  let payload = `/reports?type=approvers&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&first_name=${searchTerm}`;
  getAllInformation(payload);
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

let  headers = [
  { label: 'Employee Name', key : 'employeeName'},
  { label: 'Department', key : 'department'},
  { label: 'Expenses approver', key : 'expenses_approver'},
  { label: 'Leave approver', key : 'leave_approver'},
  { label: 'Overtime/time in lieu approver', key : 'overtime_lieu_approver'}
  ];
  const exportTOCSV = (report) => {
    let  dataAbence =[];
   if(report){
    report?.forEach((dExcel) => {
      // let hour = parseInt(dExcel.duration) /60;
      let val ={
        employeeName: dExcel?.employeeInfo?.first_name,
        department: dExcel?.employeeInfo?.department_info?.department_name,
        expenses_approver: dExcel?.expense_approver_info?.first_name,
        leave_approver: dExcel?.leave_approver_info?.first_name,
        overtime_lieu_approver: dExcel?.overtime_approver_info?.first_name
     };
      dataAbence?.push(val);
    });
    return dataAbence;
   }
 };

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Approver Report </h2>             
              <div className="card ">            
                <div className="p-4 ">
               
                <div className="headingContent  pt-0 w-full">
                      <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                        <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 ">
                          <SearchField placeholderText="Search By Name" searchId="reportApproverSearch" onChange={(e) => filterEmployees(e.target.value)} />
                        </div>
                        <div className="searchSec md:w-1/4 lg:w-1/5 md:pr-4 w-full md:mb-0 mb-2 ">
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          id="reportApproverStartDate"
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          className="formControl"
                          placeholderText="Select Date Range"
                          onChange={(update) => {
                            setDateRange(update);
                            onDateChange(update);
                          }}
                          isClearable={true}
                          locale="en-GB"
                          />
                        </div>
                        
                        <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 md:pr-4">
                          <select className="formControl"  id="reportApproverDepartment"  name="department_name" onChange={(e) => handlerChangeByDeptName(e)}>
                        <FormSelect 
                              optionValue={"0"}
                              optionName={"Select Department"} />;
                        {allDepartmentListData && allDepartmentListData.length > 0 && allDepartmentListData.map((dept, index) => {
                            return<FormSelect  key={index}
                                  optionValue={dept?.id} 
                                  optionName={dept?.department_name} />;
                        })}
                        </select>
                          </div>
                          <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 ">
                          <select className="formControl" id="reportApproverByDepartment">
                            <FormSelect optionValue=" Select by Department" />
                          </select>
                        </div>
                        <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full ">
                        <CSVLink
                          className=""
                          data={excelData}
                          headers={headers}
                            filename={createCsvFileName()}
                            target="_blank"
                          style={{ textDecoration: 'none', outline: 'none', height: '5vh' }}
                          >
                          <button  className="btn-md btn-add " id="reportApproverCsv">
                          <span className="fa fa-file-export mr-2" /> Export to CSV
                          </button>
                          </CSVLink>
                        </div>
                      </div>
                      <div className="overflow-x-auto w-full whitespace-nowrap">
                        <table className="table-auto table  w-full">
                          <thead className="thead-dark ">
                            <tr>
                              <th className="w-40"> Name</th>
                              <th>Department</th>
                              <th>Expenses approver</th>
                              <th>Leave approver</th>
                              <th>Overtime/time in lieu approver</th>
                              {/* <th>Status</th> */}
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>
                          {isLoading ?<LoadingIndicator/> :
                          <tbody>
                          
                          { employeeInfo?.length > 0 ?
                            <>
                          { employeeInfo?.length > 0 &&  employeeInfo?.map((employee, index) => { 
                            return<>
                            <tr key = {index}>
                              <td>
                                <Link
                                  to={`/employees/detail/${employee.id}`}
                                  class="listImage flex items-center "
                                >
                                  {employee?.first_name+' '+employee?.last_name}
                                </Link>
                              </td>
                              <td>{employee?.department_info?.department_name}</td>
                              <td>{employee?.expense_approver_info?.first_name}</td>
                              <td>{employee?.leave_approver_info?.first_name}</td>
                              <td>{employee?.overtime_approver_info?.first_name}</td>
                              {/* <td>{employee?.mobileno}</td>
                              <td>{employee?departmentInfo.</td> */}
                              {/* <td>
                                <span className="blue-bedge">Approved</span>
                              </td> */}
                            </tr>
                            </>;
                           
                            })}</> : 
                              <center className="mt-4">{<NoDataFound/>}</center>}
                          </tbody>}
                        </table>
                        <Pagination page={page} pages={pages} changePage={setPage} decrementPage={setPage}/>
                      </div>
                    </div>
             
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproverReport;
