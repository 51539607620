import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const addressAutofillApi = createApi({
    reducerPath: "addressAutofillAPI",
    baseQuery: fetchBaseQuery({
       baseUrl: "https://maps.googleapis.com/maps/api/geocode/json"
    }),
    endpoints: (builder) => ({
      addressDetails:builder.mutation({
          query: (data) => {         
            return {
            url: `?address=${data}&key=AIzaSyC555n_gq8L_4IPtZFqFGHK6ClByoCXXs8`,
            method: 'GET'
            };
          }
        })
    }),
    onError: (error
      //  { dispatch, getState }
       ) => {
      if (error.status === 401) {
        alert("Invalid Token Error");
        // Handle 401 Unauthorized error here
        // For example, you can dispatch an action to update the auth state:
        // dispatch(authActions.setAuthenticated(false));
        // Or show a notification/alert to the user:
        // showNotification("You are not authorized to perform this action.");
      }
    }
});

export const { useAddressDetailsMutation} = addressAutofillApi;