import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useCreateCompanyMutation, useGetCompanyMutation } from "../../services/companyApi";
import PrimaryButton from "../../components/primaryButton";
import { FormInput } from "../../components/formInput";
import { useSelector, useDispatch } from "react-redux";
import { nextcarryForward, startMonthYear, dateFormats } from "../../redux/AuthSlice/authSlice";

const GeneralSetting = ({ companyData, isCompanySuccess, generalSettingsUpdatePermissions }) => {

  //carrying out data from redux store
  const userInformation = useSelector((state) => state?.authState?.companies?.payment_processor_email);
  // const companyDatas = useSelector((state) => state?.authState?.companies);
  const startMonth = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.companies?.start_month);
  //  console.log("companyData", companyDatas);
  //states and hooks
  const [formData, setFormData] = useState({
    day_hours: "8",
    date_format: "1",
    startMonth: "1",
    min_leave_hours : "",
    min_leave_days: "",
    payment_processor_email: ""
  });
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const ignore = useRef(false);
  const toastGeneralId = useRef(null);

  //Api calling
  const [getCompany] = useGetCompanyMutation();
  const [createCompany, { isSuccess: isGeneralSuccess, isLoading: isGeneralLoading, isError: isGeneralError, error: apiError }] = useCreateCompanyMutation();

  useEffect(() => {
    if (!ignore.current) {
      getCompany();
    }
    return () => { ignore.current = true; };
  }, [getCompany]);

  const dateFormatsData = [
    { name: "DD/MM/YY", value: "1" },
    { name: "DD/MM/YYYY", value: "2" },
    { name: "YYYY/MM/DD", value: "3" },
    { name: "MM/DD/YYYY", value: "4" },
    { name: "M/D/YY", value: "5" },
    { name: "D/M/YY", value: "6" },
    { name: "D Month, Yr", value: "7" },
    { name: "Yr, Month D", value: "8" },
    { name: "Mon-DD-YYYY", value: "9" },
    { name: "YYYYY-Mon-DD", value: "10" },
    { name: "YYYY, Mon DD", value: "11" },
    { name: "DD Mon, YYYY", value: "12" },
    { name: "DD-Mon-YYYY", value: "13" },
    { name: "dddd Do MMMM YYYY", value: "14" }
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    let formatDate = dateFormatsData.find((f) => f.value === value);
    setFormData({ ...formData, [name]: value });
    dispatch(dateFormats(formatDate?.name));
  };

  const handleChanges = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'payment_processor_email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);

      setError({
        ...error,
        [name]: isValidEmail ? '' : 'Invalid email format'
      });
    }
    if(name === 'min_leave_days'){
      setError({
        ...error,
        [name]: value > 0 ? '' : 'Minimum leave days must be greater than zero'
      });
    }
    if(name === 'min_leave_hours'){
      setError({
        ...error,
        [name]: value > 0 ? '' : 'Minimum leave Hours must be greater than zero'
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    let value = {
      day_hours: formData.day_hours.toString(),
      date_format: formData.date_format,
      payment_processor_email: formData?.payment_processor_email,
      start_month: formData?.startMonth,
      min_leave_days: formData?.min_leave_days,
      min_leave_hours: formData?.min_leave_hours
    };
    createCompany(value);
  };

  useEffect(() => {
    if (isGeneralLoading) {
      toastGeneralId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isGeneralSuccess) {
      setTimeout(() => {
        toast.dismiss(toastGeneralId.current);
      }, 10000);
      toast.update(
        toastGeneralId.current,
        ("General setting updated successfully.",
          {
            toastId: 'privacyId',
            render: "General setting updated successfully.",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
      );
    }
    if (isGeneralError) {
      setTimeout(() => {
        toast.dismiss(toastGeneralId.current);
      }, 10000);
      const toastMessage = apiError.data.message
        ? apiError.data.message
        : "Something went wrong";
      toast.update(
        toastGeneralId.current,
        (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
      );
    }
  }, [isGeneralSuccess, isGeneralError, isGeneralLoading, apiError]);

  useEffect(() => {
   
    if (companyData?.companyData !== undefined) {
      setFormData({
        day_hours: companyData?.companyData?.day_hours ? companyData?.companyData?.day_hours : '8',
        date_format: companyData?.companyData?.date_format,
        startMonth: companyData?.companyData?.start_month,
        min_leave_days: companyData?.companyData?.min_leave_days,
        min_leave_hours: companyData?.companyData?.min_leave_hours
      });
    }
  }, [companyData, isCompanySuccess]);

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };

  const getEndDate = (startMonth, year) => {
    const leapYear = isLeapYear(year);

    switch (parseInt(startMonth, 10)) {
      case 1:
        return `31-Dec-${year}`;
      case 2:
        return `31-Jan-${year + 1}`;
      case 3:
        return leapYear ? `29-Feb-${year + 1}` : `28-Feb-${year + 1}`;
      case 4:
        return `31-Mar-${year + 1}`;
      case 5:
        return `30-Apr-${year + 1}`;
      case 6:
        return `31-May-${year + 1}`;
      case 7:
        return `30-Jun-${year + 1}`;
      case 8:
        return `31-Jul-${year + 1}`;
      case 9:
        return `31-Aug-${year + 1}`;
      case 10:
        return `30-Sep-${year + 1}`;
      case 11:
        return `31-Oct-${year + 1}`;
      case 12:
        return `30-Nov-${year + 1}`;
      default:
        return "";
    }
  };

  const currentYear = new Date().getFullYear();
  const endDate = getEndDate(formData?.startMonth, currentYear);

  useEffect(() => {
    if (formData?.startMonth !== null) {
      dispatch(nextcarryForward(endDate));
      dispatch(startMonthYear(formData?.startMonth));
    }
  }, [formData?.startMonth, dispatch, endDate]);

  return (
    <>
      <div className="general-settings">
        <div className="heading_title px-4 flex justify-between items-center py-4">
          <div>
            <h3 className="font-semibold text-lg">General</h3>
          </div>
        </div>
        <div className="p-4">
          <div className="block">
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8">
                <div className="w-1/2">
                  <h3>Standard Day</h3>
                </div>
                <div className="lg:w-1/3 w-2/3 md:w-1/3 flex items-center">
                  <input
                    id="dayHours"
                    name="day_hours"
                    type="text"
                    value={formData?.day_hours}
                    onChange={handleChange}
                    className="formControl"
                  />
                  <span className="pl-2">Hours</span>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8 flex-wrap md:flex-nowrap">
                <div className="md:w-1/2 w-full">
                  <h3>Date Format</h3>
                </div>
                <div className="md:w-2/5 flex items-center w-full md:mt-0 mt-2">
                  <select
                    value={
                      formData?.date_format ? formData?.date_format : "DD/MM/YY"
                    }
                    name="date_format"
                    id="dateFormat"
                    onChange={handleChange}
                    className="formControl"
                  >
                    {dateFormatsData.map((dateFormat, index) => (
                      <option
                        key={index}
                        value={dateFormat.value}
                        label={dateFormat.name}
                      />
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8 flex-wrap md:flex-nowrap">
                <div className="md:w-1/2 w-full mb-2 md:mb-0">
                  <h5>Holiday Year</h5>
                </div>
                <div className="md:w-1/3 w-full">
                  <select
                    name="startMonth"
                    id="startMonth"
                    defaultValue={startMonth}
                    className="p-2 bg-gray-100 md:w-[200px] w-full rounded-sm border"
                    onChange={handleChange}
                  >
                    {[
                      "January-December",
                      "February-January",
                      "March-February",
                      "April-March",
                      "May-April",
                      "June-May",
                      "July-June",
                      "August-July",
                      "September-August",
                      "October-September",
                      "November-October",
                      "December-November"
                    ].map((month, index) => (
                      <option key={index + 1} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8 flex-wrap md:flex-nowrap">
                <div className="md:w-1/2 w-full mb-2">
                  <h3>
                    Purchase ledger email address{" "}
                    <span className="relative -top-1 secondry-text text-[17px]">
                      *
                    </span>
                  </h3>
                </div>
                <div className="md:mb-0 md:w-1/2 w-full">
                  <FormInput
                    inputType="text"
                    inputName="payment_processor_email"
                    inputID="payment_processor_email"
                    value={userInformation}
                    handlerChange={handleChanges}
                  />
                </div>
                <div className="help-block text-red-700 mt-2">
                  {error.payment_processor_email}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8 flex-wrap md:flex-nowrap">
                <div className="md:w-1/2 w-full mb-2">
                  <h3>
                    Minimum Leave Hours{" "}
                    <span className="relative -top-1 secondry-text text-[17px]">
                      *
                    </span>
                  </h3>
                </div>
                <div className="md:mb-0 md:w-1/2 w-full">
                  <FormInput
                    inputType="text"
                    inputName="min_leave_hours"
                    inputID="min_leave_hours"
                    value={formData?.min_leave_hours}
                    handlerChange={handleChanges}
                  />
                </div>
                <div className="help-block text-red-700 mt-2">
                  {error?.min_leave_hours}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8 flex-wrap md:flex-nowrap">
                <div className="md:w-1/2 w-full mb-2">
                  <h3>
                  Minimum Leave Days{" "}
                    <span className="relative -top-1 secondry-text text-[17px]">
                      *
                    </span>
                  </h3>
                </div>
                <div className="md:mb-0 md:w-1/2 w-full">
                  <FormInput
                    inputType="text"
                    inputName="min_leave_days"
                    inputID="min_leave_days"
                    value={formData?.min_leave_days}
                    handlerChange={handleChanges}
                  />
                </div>
                <div className="help-block text-red-700 mt-2">
                  {error?.min_leave_days}
                </div>
              </div>
            </div>
          </div>
          {generalSettingsUpdatePermissions[0]?.status === true && (
            <div className="w-full mt-8">
              <div className="form-group pt-4 pb-3 flex">
                <PrimaryButton
                  Btnclass="btn save mr-2"
                  buttonID="save"
                  onClick={(e) => {
                    if (!error?.payment_processor_email) {
                      handleSave(e);
                    }
                  }}
                  btnText="Save"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GeneralSetting;
