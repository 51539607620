/* eslint-disable react/no-unescaped-entities */
 /* eslint-disable no-unused-vars */
/* eslint-disable no-debugger*/
import {useEffect, useState, useRef} from "react";
import {
  // useCreateSubscriptionMutation,
  useGetCardMutation,
  useGetInvoiceMutation,
  useGetPlanMutation,
  useSendPaymentMutation
} from "../../services/authApi";
import {toast} from "react-toastify";
// import PaymentForm from "../../services/paymentForm";
import {useDispatch, useSelector} from "react-redux";
// import {LoadingIndicator} from "../../components/loading-indicator";
import AddPayMethod from "./addPaymentMethod";
import Popup from "reactjs-popup";
import SavedCardsList from "./savedCards";
import { ApiUrl } from "../../constant";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { useCancelSubscriptionMutation } from "../../services/authApi";
import { useSubscriptionEmployeeMutation } from "../../services/employeeApi";
import { allUserPermissions } from "../../redux/AuthSlice/authSlice";

const Billing = ({billingUpdatePermissions, billingDeletePermissions}) => {
  const dispatch = useDispatch();
  const ignore = useRef(false);
  const [ getplan, {data: planData, isSuccess, isError} ] = useGetPlanMutation();
  const [ cancelSubscription, { isLoading:cancelSubscriptionLoading, isError:cancelSubscriptionError, isSuccess:cancelSubscriptionSuccess, error:errorSubscription } ] = useCancelSubscriptionMutation();
  const [ sendPayment, { isLoading:isPaymentLoading, isSuccess:isSavedSuccess, isError:isSavedError, error:paymentError} ] = useSendPaymentMutation();
  const [ getInvoice, {data:invoiceData} ] = useGetInvoiceMutation();
  const [ subscriptionEmployee, { data: subscriptionEmployeeData, isSuccess: subscriptionEmployeeSuccess } ] = useSubscriptionEmployeeMutation();
  const [ toggleState, setToggleState ] = useState(1);
  const [ selectedPlan, setSelectedPlan ] = useState();
 const [ sessionId, setSessionId ] = useState(false);
  const [ isAddCard, setIsAddCard ] = useState(false);
  const [ isDisable, setIsDisable ] = useState(false);
  const [ getCard, {data: savedCards} ] = useGetCardMutation();
  const [ error, setError ] = useState({
    errorMessage: "",
    isError: false
  });
  const selectGetCompanyData = (state) => {
    const mutations = state?.companyAPI?.mutations;
    const getCompanyMutation = Object.values(mutations).find(
      (mutation) => mutation.endpointName === 'getCompany'
    );
    
    return getCompanyMutation?.data?.companyData;
  };
  const [ open, setOpen ] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
 const userInformation = useSelector(selectGetCompanyData);
 const navigate = useNavigate();
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const statusFromURL = searchParams.get('status');
 const sessionFromURL = searchParams.get('session_id');

 useEffect(() => {
  if(subscriptionEmployeeSuccess){
    dispatch(allUserPermissions(subscriptionEmployeeData?.permissions));
  }
 }, [ subscriptionEmployeeSuccess ]);

 useEffect(() => {
  if (statusFromURL === "success") {
    setSessionId(sessionFromURL);
    subscriptionEmployee();
  }else if(statusFromURL === "error"){
    const toastMessage =  "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 2000,
          duplicate: false,
          isLoading: false
        });
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ statusFromURL ]);
  useEffect(() => {
    if (planData) {
      const filter = planData?.subscription_plans?.filter(
        (plans) => plans?.is_active === true
      );
      if (filter) {
        setToggleState(filter[0]?.id);
        filter[0]?.id === 2 ? setIsDisable(true) : setIsDisable(false);
      }
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ]);

  useEffect(() => {
    if (isPaymentLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSavedSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Subscription created successfully.", {
        render: "Subscription created successfully..",
             type: "success",
             autoClose: 2000,
           isLoading: false,
           theme: "colored"
      });
    }
    if (isSavedError) {
      toast.dismiss(toastId.current);
      const toastMessage = paymentError?.data?.message
        ? paymentError?.data?.message
        : "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSavedSuccess, isSavedError, isPaymentLoading ]);

  const toastId = useRef(null);
  useEffect(() => {
    if (cancelSubscriptionLoading) {
      toastId.current = toast.loading("Please wait...", {
      // position: "top-center",
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (cancelSubscriptionSuccess) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.success("Subscription cancel successfully.", {
        render: "Subscription cancel successfully..",
             type: "success",
             autoClose: 2000,
           isLoading: false,
           theme: "colored"
      });
      getplan();
    }
    if (cancelSubscriptionError) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      const toastMessage = paymentError?.data?.message
        ? paymentError?.data?.message
        : "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ cancelSubscriptionLoading, cancelSubscriptionError, cancelSubscriptionSuccess ]);

  useEffect(() => {
    if (!ignore.current){
    getplan();
    getCard();
    getInvoice();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const toggleTab = (index, plan) => {
    setToggleState(index);
    setSelectedPlan(plan);
  };
  const handelPayment = () => {
    if (selectedPlan === undefined || selectedPlan?.id === 1) {
      setError({
        ...error,
        isError: true,
        errorMessage: "Please Select a Paid Plan to Upgrade."
      });
    } else {
      setError({
        ...error,
        isError: false,
        errorMessage: ""
      });
      // setIsPayment(true);
      document.getElementById("paymentButton").click();
    }
  };
  const cancelSubscriptionHandler = () => {
    const value = {
      subscriptionId: planData?.current_active_plan?.stripe_payment_id
    };
    cancelSubscription(value);
  };
  return (
    <>
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <div>
          <h3 className=" font-semibold text-lg">Your plan</h3>
          <h3>
            {planData?.remaining_days ? planData?.remaining_days : 0} days are remaining until your plan expires.
          </h3>
        </div>
        <div>
        {planData?.current_active_plan?.stripe_payment_id && (
            <Popup
              open={open}
              onClose={() => closeModal()}
              closeOnDocumentClick={false}
              trigger={
                <div className="actionBtn cancelBtn">
                  Cancel Subscription
                </div>
              }
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button
                    className="close"
                    id="cancelSubscriptionClose"
                    onClick={() => {
                      closeModal();
                      close();
                    }}
                  >
                    &times;
                  </button>
                  <div className="header">
                    <h3>Cancel Subscription</h3>
                  </div>
                  <div className="content">
                    <p>
                      Are you sure you want to cancel subscription?
                    </p>
                    <div className="w-full mt-8">
                      <div className="form-group pt-4 pb-3 flex justify-center ">
                        <button className="btn save mr-2" id="cancelSubscriptionYes" onClick={(e) => cancelSubscriptionHandler()}>
                          Yes
                        </button>
                        <button
                          type="button"
                          onClick={() => close()}
                          id="cancelSubscriptionNo"
                          className="btn cancel "
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          )}
        </div>
      </div>
      <div className="lg:p-4 p-5 ">
        <div className="flex justify-between flex-wrap -mx-3">
          {isSuccess ? (
            planData?.subscription_plans?.map((plans, index) => {
              return (
                <>
                  <div className="lg:w-1/2 lg:px-3 w-full">
                    <div className="selectPlan">
                      <label>
                        <div
                          onClick={() =>
                            isDisable ? null : toggleTab(index + 1, plans)
                          }
                          className={
                            toggleState === index + 1
                              ? "p-4 freePlan  divcurrentPlan border-2 border-gray-200 rounded-md w-full relative h-44 lg:mb-0 mb-4"
                              : "p-4 freePlan  border-2 border-gray-200 rounded-md w-full relative h-44 lg:mb-0 mb-4"
                          }
                        >
                          <div className="text-sm mb-4">
                            <span className="text-white font-light bg-primary p-1 rounded-sm  ">
                              {plans?.monthly_amount === "0" ? "Free" : "Paid"}
                            </span>
                            <h2 className=" mt-3 mb-3">
                              <span className="text-4xl">
                                {plans?.monthly_amount}
                              </span>
                              <span>{plans?.currency}/ employee </span>
                            </h2>
                            <p className="absolute top-5 right-5 font-bold opacity-100 ">
                              {plans?.name}
                            </p>
                          </div>
                          <p>
                            {plans?.description}
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                </>
              );
            })
          ) : isError ? (
            <>
              <div className="flex justify-between flex-wrap">
                No Data Found
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <>
          {error.isError ? (
            <div className="help-block text-red-700">{error?.errorMessage}</div>
          ) : null}
        </>
        {billingUpdatePermissions[0]?.status === true &&    <button
          onClick={() => handelPayment()}
          disabled={isDisable}
          className={
            isDisable
              ? "btn  my-4 mb-10 bg-gray-400  rounded"
              : "btn btn-md my-4 mb-10 bg-primary"
          }
        >
          Upgrade Your Plan
        </button> }
           <form id="paymentForm" action={`${ApiUrl}/subscription/create-checkout-session`} method="POST">
              <input type="hidden" id="priceId" name="priceId" value={selectedPlan?.pricing_id} />
              <input type="hidden" id="plan_id" name="plan_id" value={selectedPlan?.id} />
              <input type="hidden" id="company_id" name="company_id" value={userInformation?.id} />
              <input name="first_name" id="first_name"  type="hidden" value={userInformation?.name} />
              <input name="email" id="email"  type="hidden" value={userInformation?.email} />
              <button type="hidden"  id="paymentButton"/>
            </form>
        <div className="BillingDeatils mt-10 card2 p-5">
          <h3 className="mb-3">Billing details</h3>
          <p>
            Here you'll find all your plan details, billing info and payment
            methods. Pay with a Debit, Credit Card or BACS Direct Debit
          </p>
          <SavedCardsList getCard={getCard} billingDeletePermissions={billingDeletePermissions} savedCards={savedCards?.data} />
          {isAddCard ? (
            <>
              <Popup
                open={isAddCard}
                onClose={() => setIsAddCard(false)}
                className="creditCard"
                closeOnDocumentClick={false}
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      id="addCardClose"
                      onClick={() => {
                        setIsAddCard(false);
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <AddPayMethod  setIsAddCard={setIsAddCard} />
                  </div>
                )}
              </Popup>
            </>
          ) : null}

          <h3 className="mb-3">Invoice Details</h3>
          
          <div className="overflow-x-auto w-full whitespace-nowrap">
            <table className="table-auto table  w-full">
              <thead className="thead-dark ">
                <tr>
                  <th className="w-56">Invoice Id</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              {
                invoiceData?.invoices?.length > 0 ?
                <>
              <tbody>
                {invoiceData?.invoices?.map((invoice, index) => {
                    return (<tr key={index}>
                      <td>{invoice?.id}</td>
                      <td>{moment?.unix(invoice?.created)?.format("DD/MM/yyyy")}</td>
                      <td>
                      <a className="actionBtn" href={invoice?.invoice_pdf}>
                        <i className="fa fa-download" />
                      </a>
                    </td>
                     </tr>);                  
                  })}              
              </tbody>
              </> : 
                <center className="mt-4 text-red-700 ml-[350px]">No Data Found</center>}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
