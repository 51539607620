import { ReactComponent as VisaIcon } from "../../helper/images/visa.svg"; 
import { ReactComponent as MasterCardIcon } from "../../helper/images/mastercard.svg"; 
import { useRemoveCardMutation } from "../../services/authApi";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useEffect } from "react";



const SavedCardsList = ({ savedCards, billingDeletePermissions, getCard }) => {
 const [ removeCard, { isLoading, isSuccess, isError, error:apiError } ] = useRemoveCardMutation();


  const toastId = useRef(null);
  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      autoClose: 2000,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccess) {
      toast.dismiss(toastId.current);
    //  const id= toastId.current;
       getCard();
        toast.success("Card has been removed in successfully.", {
          render: "Card has been removed in successfully.",
               type: "success",
               autoClose: 2000,
             isLoading: false
        });
    }
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage = apiError?.data?.message
        ? apiError?.data?.message
        : "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
               type: "error",
               autoClose: 3000,
             isLoading: false,
             theme: "colored",
             closeOnClick: true,
             pauseOnHover: true
        });

    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);
    // const options = {
    //   margin: 5,
    //   responsiveClass: true,
    //   nav: true,
   
    //   dots: false,
    //   autoplay: false,
    //   navText: [
    //     "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
    //     "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    //   ],
  
    //   smartSpeed: 2000,
      
    //   responsive: {
    //     0: {
    //       items: 1
         
    //     },
    //     400: {
    //       items: 1
          
    //     },
    //     600: {
    //       items: 1
    //     },    
  
    //     700: {
    //       items: 1
    //     },    
  
    //     1000: {
    //       items: 1
    //     },
    //     1200: {
    //       items: 3
    //     }
    //   }
    // };
    const handelCardRemove = (id) => {
      const payload = {"cardId": id };
      removeCard(payload);
    };
  return (
    <>
      {savedCards?.map((card, index) => (
      <div className='item  flex items-center flex-wrap' key={index}>     
       <div className='selectedCard md:w-1/2 w-full'>
       <input type='radio' name='card' id="cardInput"/>
      <div className={card?.card?.brand !== 'visa' ? "bg-gradient-to-r from-gray-600 to-blue-400 my-4  text-white rounded-lg p-6 shadow-md hover:shadow-lg transition duration-300 ease-in-out transform " : 
      "bg-gradient-to-r from-gray-600 to-green-400 my-4  text-white rounded-lg p-6 shadow-md hover:shadow-lg transition duration-300 ease-in-out transform "}>
     <span className="fa fa-check w-5 h-5 rounded-full checkIcon"/>
      <div className="flex justify-between items-center mb-4">
         {card?.card?.brand === 'visa' ?  <VisaIcon/>
       :  <MasterCardIcon/> }
       
      </div>
      <div className="text-2xl font-semibold mb-2">
        **** **** **** {card?.card?.last4}
      </div>
      <div className="flex justify-between">
        <div className=" text-sm">
          {card?.billing_details?.name}
        </div>
        <div className="text-2xl font-semibold">
          {card?.card?.brand === 'visa' ? 'VISA' : 'MasterCard'}
        </div>
        <div className="text-sm">
          {card?.card?.exp_month}/{card?.card?.exp_year}
        </div>
      </div>
    </div>
    </div>   
    <div className=" md:ml-4 flex flex-col md:w-auto w-full">
    {/* <button className="btn save md:mr-2 mb-2">Buy</button> */}
    {billingDeletePermissions[0]?.status === true &&
    <button onClick={() => handelCardRemove(card?.id)} 
     className="btn cancel md:mr-2 mb-4 md:mb-0" id="removeCard">Remove Card</button>
    }
    
    </div> 
        </div>
        ))}
  </>
      
//       </OwlCarousel>
  );
};

export default SavedCardsList;