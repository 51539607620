/* eslint-disable camelcase */
/* eslint-disable*/
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SearchField } from "../../components/filters";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { CSVLink } from "react-csv";
import { LoadingIndicator } from "../../components/loading-indicator";
import { useDispatch, useSelector } from "react-redux";
import moments from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale
import { useEmployeeLeaveAllowanceMutation } from "../../services/employeeApi";
import { FormSelect } from "../../components/formInput";
import Pagination from "../../components/inc/pagination";

registerLocale('en-GB', enGB);


const AllowanceOverview = () => {
  const ignore = useRef(false);
  //carrying out data from redux
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);

  //api calling
  const [ employeeLeaveAllowance, { data: leaveAllowanceData, isSuccess: isSuccessEmployeeLeaveAllowance, isLoading } ] = useEmployeeLeaveAllowanceMutation();
  const [ getDepartmentList, { data: departmentApiResponse, isSuccess: isSuccessDepartmentApi } ]  = useGetDepartmentListMutation();

  //States are defined here
  const dispatch = useDispatch();
  const [ allDepartmentListData, setAllDepartmentListData ]  = useState();
  const [ employeeInfo, setEmployeeInfo ] = useState();
  const [ excelData, setExcelData ] = useState([ ]);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get("page") || "";
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);
  const createCsvFileName = () => `Leave_Allowances_Report_${moments().format()}.csv`;

  useEffect(() => {
    if (!ignore.current){
        employeeLeaveAllowance(`/employee/leave-allowances`);
        getDepartmentList();
    }
    return () => { ignore.current = true; };
    },  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if (isSuccessDepartmentApi) {
      dispatch(departmentListAction(departmentApiResponse));
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccessDepartmentApi ] 
);

useEffect(() => {
    if(page){
        employeeLeaveAllowance(`/employee/leave-allowances?page=${page}`);
    }
}, [page]);

useEffect(() => {
    if (departmentApiData) {
      setAllDepartmentListData(departmentApiData?.data);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ departmentApiData ] 
);

  useEffect(() => {
      if (isSuccessEmployeeLeaveAllowance) {
        setEmployeeInfo(leaveAllowanceData?.employees);
        if (leaveAllowanceData) {
          let csvData = exportTOCSV(leaveAllowanceData?.employees);
          setExcelData(csvData);
        }
        setPages(leaveAllowanceData?.totalPages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccessEmployeeLeaveAllowance ]);

  //Filters
  const filterEmployees = useCallback(
    (searchTerm) => {
      let payload = `/employee/leave-allowances?first_name=${searchTerm}`;
      employeeLeaveAllowance(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [ ]);

  const handlerChangeByDeptName = (e) => {
    e.preventDefault();
    if (e.target.value === "0") {
      let payload = `/employee/leave-allowances`;
      employeeLeaveAllowance(payload);
    } else {
      let payload = `/employee/leave-allowances?department_id=${e.target.value}`;
      employeeLeaveAllowance(payload);
    }
  };

  const decrementPage = () => {
    if(leaveAllowanceData?.currentPage - 1 >= 0){
        employeeLeaveAllowance(leaveAllowanceData?.currentPage - 1);
        setPage(leaveAllowanceData?.currentPage - 1);
    }
};
  
  //Export To CSV Func.
  let headers = [
    { label: "Employee Name", key: "employeeName" },
    { label: "Total Allowance", key: "totalAllowances" },
    { label: "Total Leave Taken", key: "totalLeaveTaken" },
    { label: "Remaining Leave", key: "totalLeaveRemaining" }
  ];

  const exportTOCSV = (report) => {
    let dataAbence = [];
    if (report) {
      report?.forEach((dExcel) => {
        let val = {
            employeeName: dExcel?.employeeName,
            totalAllowances: dExcel?.totalAllowance,
            totalLeaveTaken: dExcel?.totalLeaveTaken,
            totalLeaveRemaining: dExcel?.remainingLeave
        };
        dataAbence?.push(val);
      });
      return dataAbence;
    }
  };


  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>
        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">
                Allowance Overview{" "}
              </h2>
              <div className="card ">
                <div className="p-4 ">
                  <div className="headingContent  pt-0 w-full">
                    <div className="flex pt-2 pb-6 filterSection flex-wrap md:flex-nowrap">
                      <div className="flex  filterSection flex-wrap md:flex-nowrap w-full">
                        <div className="searchSec lg:w-1/5 md:w-1/3 md:pr-4 w-full mb-2 lg:mb-0 ">
                          <SearchField
                            placeholderText="Search By Name"
                            searchId="companyMessageSearch"
                            onChange={(e) => filterEmployees(e.target.value)}
                          />
                        </div>
                        <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 md:pr-4">
                        <select
                          className="formControl"
                          id="leaveReportDepartment"
                          name="department_name"
                          onChange={(e) => handlerChangeByDeptName(e)}
                        >
                          <FormSelect
                            optionValue={"0"}
                            optionName={"Select Department"}
                          />
                          ;
                          {allDepartmentListData?.length > 0
                            ? allDepartmentListData?.map((departments, key) => {
                                return (
                                  <FormSelect
                                    key={key}
                                    optionValue={departments?.id}
                                    optionName={departments?.department_name}
                                  />
                                );
                              })
                            : null}
                        </select>
                      </div>
                      </div>
                      
                      <div className="headerButton flex items-center exportFile md:w-96  w-full justify-end ">
                        <CSVLink
                          className=""
                          data={excelData}
                          headers={headers}
                          filename={createCsvFileName()}
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            outline: "none",
                            height: "5vh"
                          }}
                        >
                          <button
                            className="btn-md btn-add "
                            id="companyMessageCsv"
                          >
                            <span className="fa fa-file-export mr-2" /> Export
                            to CSV
                          </button>
                        </CSVLink>
                      </div>
                    </div>
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                      <table className="table-auto table  w-full">
                        <thead className="thead-dark ">
                          <tr>
                            <th>Employee Name</th>
                            <th>Total Allowance</th>
                            <th>Total Leave Taken</th>
                            <th>Remaining Leave</th>
                            <th>Hours/Days</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <LoadingIndicator />
                        ) : (
                          <tbody>
                            {employeeInfo?.length > 0 ? (
                              <>
                                {employeeInfo?.length > 0 &&
                                  employeeInfo?.map((employee, index) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>
                                            <Link
                                              to={`/employees/detail/${employee?.employeeId}`}
                                              class="listImage flex items-center "
                                            >
                                              {employee?.employeeName}
                                            </Link>
                                          </td>
                                          <td>
                                            {employee?.totalAllowance !==
                                              undefined &&
                                            employee?.totalAllowance !== null
                                              ? employee.totalAllowance.toFixed(
                                                  2
                                                )
                                              : "0.00"}
                                          </td>
                                          <td>
                                            {employee?.totalLeaveTaken !==
                                              undefined &&
                                            employee?.totalLeaveTaken !== null
                                              ? employee.totalLeaveTaken.toFixed(
                                                  2
                                                )
                                              : "0.00"}
                                          </td>
                                          <td>
                                            {employee?.remainingLeave !==
                                              undefined &&
                                            employee?.remainingLeave !== null
                                              ? employee.remainingLeave.toFixed(
                                                  2
                                                )
                                              : "0.00"}
                                          </td>
                                          <td>{employee?.inHours === true ? "Hours" : "Days"}</td>
                                        </tr>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <center className="mt-4 ml-[120%] text-red-700">
                                No Data Found
                              </center>
                            )}
                          </tbody>
                        )}
                      </table>
                      <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllowanceOverview;
