import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true
      // text: 'Chart.js Bar Chart'
    }
  },
  scales: {
    x: {
      type: 'category',
      labels: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
      title: {
        display: true,
        text: 'All values are in £'
      }
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'All values are in £'
      }
    }
  }
};

export function BarChartExpense({ chart }) {
  const currentYear = new Date().getFullYear();
  const data1 = {
    labels: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
    datasets: [
      {
        label: currentYear.toString(),
        data: chart?.ChartDataSets?.data,
        backgroundColor: '#023A51'
      }
    ]
  };
  return <Bar options={options} data={data1} responsive={true} />;
}
