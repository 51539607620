import { Link } from "react-router-dom";

const BillingPage = () => {
   return (
      <>
 
         <header className="bg-primary shadow-sm 	">
            <div className="container mx-auto">
               <div className="flex items-center ">
                  <div className="headerLeft flex items-center justify-between  w-full ">
                     <div className="logo   ">
                        <Link to="/dashboard">
                           <img
                              src="/images/logo-leave.webp"
                              alt={"Logo"}
                              className="max-w-full"
                           />
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </header>
         <div className="py-6">
            <div className="container  mx-auto  w-2/3">
               <h2 className="text-xl mb-3 font-semibold ">Plan Expired</h2>
               <div>Plan Expired Please Contact Company Admin</div>     
            </div>
         </div>

      </>
   );
};

export default BillingPage;