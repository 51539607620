import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ApiUrl} from "../constant";

export const reportApi = createApi({
  reducerPath: "reportAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),
  endpoints: (builder) => ({
    getAllInformation: builder.mutation({
      query: (payload) => ({
        url: payload,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getRoles: builder.mutation({
      query: () => ({
        url: `/permissions/roles`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getUserPermissions: builder.mutation({
      query: () => ({
        url: `/permissions/get-user-permissions`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getRolePermissions: builder.mutation({
      query: (roleId) => ({
        url: `/permissions/${roleId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    updateRolePermissions: builder.mutation({
      query: ({roleId, payload}) => ({
        url: `/permissions/${roleId}`,
        method: "POST",
        body: {permissions:payload},
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    createOrUpdateRole: builder.mutation({
      query: (payload) => ({
        url: `/permissions/create-or-update-role`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    })
    // deleteRole: builder.mutation({
    //   query: (id) => ({
    //     url: `/permissions/role/${id}`,
    //     method: 'DELETE',
    //     headers: {
    //       'Content-type': 'application/json; charset=UTF-8',
    //       Authorization:  localStorage.getItem('accessToken')
    //     }
    //   }),
    // }) 
  })
});
export const {
  useGetAllInformationMutation,
  useGetRolesMutation,
  useGetRolePermissionsMutation,
  useUpdateRolePermissionsMutation,
  useGetUserPermissionsMutation,
  useCreateOrUpdateRoleMutation
  // useDeleteRoleMutation
} = reportApi;
