// eslint-disable-next-line no-unused-vars
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState, useCallback, useRef } from "react";
import {
  DateRangeFiled,
  SearchField
} from "../../components/filters";
import { FormSelect } from "../../components/formInput";
import ApprovedExpensesToPay from "./Expense/approvedExpensesToPay";
import PaidExpense from "./Expense/paidExpense";
import ExpenseToApprove from "./Expense/expenseApprove";
import { useDispatch, useSelector } from "react-redux";
import { useEmployeeExpenseApproverMutation } from "../../services/approverListApi";
// import { useGetAllExpensesListMutation } from "../../services/masterExpensesApi";
import { useEffect } from "react";
import { useGetAllExpensesListMutation } from "../../services/masterExpensesApi";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { allExpenseDataAction } from "../../redux/ExpenseSlice/expenseSlice";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const Expense = ({ setExpenseCount }) => {
  const  expenseListData = useSelector((state) => state?.persistedReducer?.expenseSlice?.allExpenseDataList);
  const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);
  const ignore = useRef(false);
  const [ employeeExpenseApprover, { data:expenseApproverData, isSuccess: expenseApproverSuccess, isLoading:expenseLoading } ] = useEmployeeExpenseApproverMutation();
  const [ getAllExpensesList, { data:expensesTypeResponseData, isSuccess:expenseListSuccess } ] = useGetAllExpensesListMutation();
  const [ allExpenseToApprove, setAllExpenseToApprove ] = useState();
  const [ allApproveToExpense, setAllApproveToExpense ] = useState();
  const [ allExpensePaid, setAllExpensepaid ] = useState();
  const [ expenseType, setExpenseType ] = useState();
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState();
  const [ excelData, setExcelData ] = useState([]);
  const [ toggleState2, setToggleState2 ] = useState("ExpenseApprove");
  const createCsvFileName = () => `MyExpense_Approval_report_${moment().format()}.csv`;
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };
  const dispatch = useDispatch();

  const decrementPage = () => {
    employeeExpenseApprover(expenseApproverData?.currentPage - 1 );
    setPage(expenseApproverData?.currentPage - 1);
  };
  useEffect(() => {
    if (!ignore.current) getAllExpensesList();
    return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
useEffect(() => {
  if(expenseListSuccess){
     dispatch(allExpenseDataAction(expensesTypeResponseData));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ expenseListSuccess ]);

  useEffect(() => {
    if(expenseListData) {
    setExpenseType(expenseListData?.data);
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ expenseListData ]);

  useEffect(() => {
    setExpenseCount(allExpenseToApprove?.length);
  });
  useEffect(() => {
    let payload = `/expenses/filter/search/?expense_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
    if (!ignore.current) employeeExpenseApprover(payload);
    return () => { ignore.current = true; };
    
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
  useEffect(() => {
    if(expenseApproverSuccess) {
      let expenseApprove = [];
      let approvedExpense = [];
      let paidExpense = [];
      expenseApproverData?.data?.forEach((expense) => {
       if(expense.status === '0') {
        expenseApprove.push(expense);
       } else if(expense.status === '1'){
        approvedExpense.push(expense);
       } else if(expense.status === '2'){
         paidExpense.push(expense);
       }
      });
      setPages(expenseApproverData?.totalPages);
      setAllExpenseToApprove(expenseApprove);
      setAllApproveToExpense(approvedExpense);
      setAllExpensepaid(paidExpense);
      if(expenseApproverData?.data){
        let csvData = exportTOCSV(expenseApproverData?.data);
        setExcelData(csvData);
      }  
    }
    
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ expenseApproverSuccess ]);

   let  headers = [
    { label: 'Employee Name', key : 'employeeName'},
    // { label: 'Department Name', key : 'departmentName'},
    { label: 'Type', key : 'type'},
    { label: 'Start Date', key : 'start_date'},
    // { label: 'Start Time', key : 'start_time'},
    { label:  'End Date', key : 'end_date'},
    // { label: 'End Time', key : 'end_time'},
    // { label: 'Duration(Days/Hours)', key : 'total_duration'},
    { label: 'Status', key : 'status'},
    { label: "Amount", key: 'amount'},
    { label: 'Notes', key : 'notes'},
    { label: 'Reason', key : 'reason'},
    {label: 'IsMileage', key: 'is_mileage'},
    { label: 'CreatedAt', key : 'createdAt'},
    { label: 'UpdatedAt', key : 'updatedAt'}
   
    ];
    const exportTOCSV = (expensInfo) => {
      let  dataAbence =[];
      if(expensInfo){
        expensInfo?.forEach((dExcel) => {
          // let hour = parseInt(dExcel.duration) /60;
          let val ={
            employeeName: `${dExcel?.employeeInfo?.first_name} ${dExcel?.employeeInfo?.last_name}`,
            // departmentName:dExcel?.departmentInfo?.department_name,
             type: dExcel?.expenseTypeInfo?.expense_name,
             start_date: moment(dExcel?.start_date).format('DD-MM-YYYY'),
            //  start_time: dExcel?.start_time,
             end_date: moment(dExcel?.end_date).format('DD-MM-YYYY'),
            //  end_time: dExcel?.end_time,
           
             status: dExcel?.status === '1' ? 'Active' : 'Unactive',
             amount: dExcel?.amount,
             notes: dExcel?.notes,
             reason: dExcel?.reason,
            is_mileage: dExcel?.is_mileage === 0 ? 'No' : 'Yes',
             createdAt: moment(dExcel?.createdAt).format('DD-MM-YYYY'),
             updatedAt: moment(dExcel?.updatedAt).format('DD-MM-YYYY')
         };
          dataAbence.push(val);
        });
        return dataAbence;
      }
   
   };

   const handlerChangeByExpenseName = (e) => {
    e.preventDefault();
    if (e.target.value === '0') {
      let payload=`/expenses/filter/search/?expense_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
      employeeExpenseApprover(payload);
    } else {
    let payload=`/expenses/filter/search/?expense_type=${e.target.value}&expense_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
    employeeExpenseApprover(payload);
    }
   };

   const filterEmployees = useCallback((searchTerm) => {
    let payload = `/expenses/filter/search/?first_name=${searchTerm}&expense_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
    employeeExpenseApprover(payload);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

  const onDateChange = (e) => {
    if(e[0] !== null && e[1] !== null){
      let payload=`/expenses/filter/search/?expense_approver=${employeeIdData}&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}&exclude_self=true`;
      employeeExpenseApprover(payload); 
 
    } else {
      let payload=`/expenses/filter/search/?expense_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
      employeeExpenseApprover(payload);
    }
  };

  const claimType = [
    {value :"0", label: "Select Claim Type"},
    {value :"1", label: "Expense"},
    {value :"2", label: "Mileage"}
    
  ]; 

  const handlerClaimTypeFilter = (e) => {
    if(e.target.value === '0') {
      let payload =`/expenses/filter/search/?expense_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
      employeeExpenseApprover(payload);
    } else {
      let isMileage = e.target.value === '1' ? 0 : e.target.value === '2' ? 1 :0;
      let payload =`/expenses/filter/search/?expense_approver=${employeeIdData}&is_mileage=${isMileage}&page=1&limit=100&exclude_self=true`;
      employeeExpenseApprover(payload); 
    }
  };

  return (
    <>
      <div className="  mb-3">
        <div className="p-4">
          <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap w-full justify-between ">
            <div className="w-full flex flex-wrap">
              <div className="searchSec md:w-1/3 md:pr-4 w-full mb-2 lg:mb-0 ">
                <SearchField
                  placeholderText="Search By Name"
                  searchId="search"
                  onChange={(e) => filterEmployees(e.target.value)}
                />
              </div>
              <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full md:mb-0 mb-2 ">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  id="datePicker"
                  dateFormat="dd/MM/yyyy"
                  className="formControl"
                  placeholderText="Select Date Range"
                  onChange={(update) => {
                    setDateRange(update);
                    onDateChange(update);
                  }}
                  isClearable={true}
                  locale="en-GB"
                />
              </div>
              <div className="searchSec md:w-1/3 w-full md:mb-0 mb-4 lg:pr-4">
                <select
                  className="formControl"
                  onChange={(e) => handlerChangeByExpenseName(e)}
                  id="SelectFliter"
                >
                  <FormSelect
                    optionValue={""}
                    optionName={"Select Expense Name"}
                  />
                  ;
                  {expenseType?.map((expense, key) => {
                    return (
                      <FormSelect
                        key={key}
                        optionValue={expense?.id}
                        optionName={expense?.expense_name}
                      />
                    );
                  })}
                </select>
              </div>
              <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-4 md:pr-0 lg:pr-4">
                <select
                  className="formControl"
                  id="claimType"
                  onChange = {(e) => handlerClaimTypeFilter(e)}
                >
                  {claimType?.map((claim, key) => {
                    return (
                      <FormSelect
                        key={key}
                        optionValue={claim?.value}
                        optionName={claim.label}
                      />
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full">
              <CSVLink
                className=""
                data={excelData}
                headers={headers}
                filename={createCsvFileName()}
                target="_blank"
                style={{
                  textDecoration: "none",
                  outline: "none",
                  height: "5vh"
                }}
              >
                <button className="btn-md btn-add ">
                  <span className="fa fa-file-export mr-2" /> Export to CSV
                </button>
              </CSVLink>
            </div>
          </div>
          <ul className="flex approvalSubTab mb-8 ">
            <li>
              <span
                onClick={() => toggleTab2("ExpenseApprove")}
                className={toggleState2 === "ExpenseApprove" ? " active" : "  "}
              >
                Expenses to Approve
              </span>
            </li>
            <li>
              <span
                onClick={() => toggleTab2("ApprovedExpense")}
                className={
                  toggleState2 === "ApprovedExpense" ? " active" : "  "
                }
              >
                Expenses to Pay
              </span>
            </li>
            <li>
              <span
                onClick={() => toggleTab2("PaidExpense")}
                className={toggleState2 === "PaidExpense" ? " active" : "  "}
              >
                Paid Expense
              </span>
            </li>
          </ul>
          <div className="tabCard ">
            <div
              className={
                toggleState2 === "ExpenseApprove"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <ExpenseToApprove
                allExpenseToApprove={allExpenseToApprove}
                employeeExpenseApprover={employeeExpenseApprover}
                employeeId={employeeIdData}
                expenseLoading={expenseLoading}
              />
            </div>
            <div
              className={
                toggleState2 === "ApprovedExpense"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <ApprovedExpensesToPay
                allApproveToExpense={allApproveToExpense}
                employeeExpenseApprover={employeeExpenseApprover}
                employeeId={employeeIdData}
                expenseLoading={expenseLoading}
              />
            </div>
            <div
              className={
                toggleState2 === "PaidExpense"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <PaidExpense
                allExpensePaid={allExpensePaid}
                expenseLoading={expenseLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expense;
