import { useState, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Popup from "reactjs-popup";
import { FormSelect } from "../../components/formInput";
import AddMyOvertime from './AddMyOvertime';
import {useMyOvertimeListMutation, useDeleteMyOvertimeMutation, useMyOvertimeByDateMutation, useAllOvertimeListMutation, useTimeBackOvertimeListMutation} from "../../services/myOvertimeApi";
import { useEffect } from "react";
import {toast } from "react-toastify";
import {useSearchParams} from 'react-router-dom';
import {useGetAllOverTimeMutation} from "../../services/overTimeApi";
import DatePicker from "react-datepicker";
import { CSVLink } from 'react-csv';
import moment from 'moment/moment';
import Overtime from "./overtime";
import TimeInLieu from "./time_in_lieu";
import { parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { overTimeDataAction, overTimeTypeDataAction, timeBackDataAction } from "../../redux/OverTimeSlice/overtimeListSlice";
import Pagination from "../../components/inc/pagination";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale
import { REACT_APP_AUDITOR_ROLE_ID } from "../../helper/constant";

registerLocale('en-GB', enGB);

const MyOverTime = () => {
  const getAllOverTimeApiResponse = useSelector((state) => state?.persistedReducer?.overtimeListSlice?.overTimeData);
  const overtimeAllListData = useSelector((state) => state?.persistedReducer?.overtimeListSlice?.overtimeDataList);
  const timeBackListData = useSelector((state) => state?.persistedReducer?.overtimeListSlice?.timeBackData);
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const [ open, setOpen ] = useState(false);
  const ignore = useRef(false);
  const dispatch = useDispatch();
  const [ getAllMaterOverTimeList, {data: allMasterOverTimeTypeResponse, isSuccess: isSuccessAllMasterOvertime} ] = useGetAllOverTimeMutation();
  const [ myOvertimeList, {data : getAllOverTimeApiResponseData, isSuccess} ] = useMyOvertimeListMutation();
  const [allOvertimeList, {data: allOvertimeListData, isSuccess: isAllOvertimeListSuccess}] = useAllOvertimeListMutation();
  const [ timeBackOvertimeList, {data:  timeBackOvertimeListData, isSuccess: isTimeBackOvertimeListSuccess}] = useTimeBackOvertimeListMutation();
  const [ deleteMyOvertime, {isSuccess: isSuccessDelete, isLoading: isLoadingDelete, isError: isErrorDelete, error: apiError} ] = useDeleteMyOvertimeMutation();
  const [ overtimeTimeBackData, setOvertimeTimeBackData ] = useState();
  const [ overtimeData, setOvertimeData ] = useState();
  const [ {data: getDataOvertimeByDate, isSuccess : isSuccessOvertimeDate} ] = useMyOvertimeByDateMutation();
  const [ allMyOverTime, setAllMyOverTime ] = useState();
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const toastGeneralId = useRef(null);
  const [ searchParams ] = useSearchParams();
  const [ excelData, setExcelData ] = useState([]);
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState();
  const [ selectedStatus, setSelectedStatus ] = useState("");
  const [ overTimeType, setOverTimeType ] = useState();
  const createCsvFileName = () => `MyOverTime_report_${moment().format()}.csv`;
  const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);
  const [ toggleState2, setToggleState2 ] = useState("overtime");

  // Handle the Overtime Timeback
  const handleTimeBackData = () => {
    let timeBack = [];
    timeBackListData?.data?.forEach((overtimeData) => { 
      const startTimeArray = overtimeData?.start_time?.split(':');
      const endTimeArray = overtimeData?.end_time?.split(':');
      const formattedStartTime = startTimeArray?.slice(0, 2).join(':');
      const formattedEndTime = endTimeArray?.slice(0, 2).join(':');
      const timeBackData ={
        type : overtimeData?.mOverTimeInfo?.overtime_name,
        date : overtimeData?.start_date,
        start_time : formattedStartTime,
        end_time : formattedEndTime,
        duration : overtimeData?.duration,
        time_in_lieu : overtimeData?.time_in_lieu,
        details : overtimeData?.notes,
        status:overtimeData?.status,
        notes:overtimeData?.notes, 
        m_overtime_types_id:overtimeData?.m_overtime_types_id,
        cancelReason:overtimeData?.cancel_reason,
        is_Paid:overtimeData?.is_paid,
        break:overtimeData?.break_time
       };
       timeBack.push(timeBackData);
    });
    setOvertimeTimeBackData(timeBack);
  };
  const  handleOverTimeData = () => {
    let Overtime = [];
    overtimeAllListData?.data?.forEach((overtimeData) => {
      const startTimeArray = overtimeData?.start_time?.split(':');
      const endTimeArray = overtimeData?.end_time?.split(':');
      const formattedStartTime = startTimeArray?.slice(0, 2).join(':');
      const formattedEndTime = endTimeArray?.slice(0, 2).join(':');
      const overtimeData1 ={
        id: overtimeData?.id,
        type : overtimeData?.mOverTimeInfo?.overtime_name,
        date : overtimeData?.start_date ? parseISO(overtimeData?.start_date) : new Date(),
        start_time :formattedStartTime,
        end_time : formattedEndTime,
        duration : overtimeData?.duration,
        time_in_lieu : overtimeData?.time_in_lieu,
        details : overtimeData?.notes, 
        status:overtimeData?.status,
        notes:overtimeData?.notes,
        m_overtime_types_id:overtimeData?.m_overtime_types_id,
        cancelReason:overtimeData?.cancel_reason,
        break:overtimeData?.break_time,
        is_Paid:overtimeData?.is_paid
       };
       Overtime.push(overtimeData1);     
      });
     setOvertimeData(Overtime);
    };
  const handleOverTimeApi = (overtimeType) => {
    let url;
    if(overtimeType === 0){
       url = `overtimes/?page=${page}&&employee_id=${employeeIdData}&time_in_lieu=${overtimeType}`;
          allOvertimeList(url);
    }else{
      url = `overtimes/?page=${page}&&employee_id=${employeeIdData}&time_in_lieu=${overtimeType}`;
         timeBackOvertimeList(url);
    }
  };
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };
  useEffect(() => {
    if (toggleState2 === "overtime") {
      handleOverTimeApi(0);
      setPages(overtimeAllListData?.totalPages);
    } else {
      handleOverTimeApi(1);
      setPages(timeBackListData?.totalPages);
    }
  }, [toggleState2]);
  useEffect(() => {
    if (overtimeAllListData) {
      handleOverTimeData();
    }
    if (timeBackListData) {
      handleTimeBackData();
    }
  }, [overtimeAllListData, timeBackListData]);
  useEffect(() => {
    if (isAllOvertimeListSuccess) {
      dispatch(overTimeTypeDataAction(allOvertimeListData));
    }
    if(isTimeBackOvertimeListSuccess){
      dispatch(timeBackDataAction(timeBackOvertimeListData));
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isAllOvertimeListSuccess, isTimeBackOvertimeListSuccess ]);
  
  useEffect(() => {
    if (isSuccess) {
      dispatch(overTimeDataAction(getAllOverTimeApiResponseData));
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccess ]
);

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(toggleState2 === "overtime"){
      handleOverTimeApi(0);
      setPages(overtimeAllListData?.totalPages);

    }else{
      handleOverTimeApi(1);
      setPages(timeBackListData?.totalPages);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);
  useEffect(() => {
    if (!ignore.current){
    const url = `/masterovertime?page=${1}&userSpecific=${1}&limit=50`;
    getAllMaterOverTimeList(url);
    handleOverTimeApi(0);
    handleOverTimeApi(1);
    if (toggleState2 === "overtime") {
      setPages(overtimeAllListData?.totalPages);
    } else {
      setPages(timeBackListData?.totalPages);
    }
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ ]);

  useEffect(() => {
    if(isSuccess) {
      if(getAllOverTimeApiResponse?.data?.length < 1 && getAllOverTimeApiResponse?.currentPage >1 ) {
         myOvertimeList(getAllOverTimeApiResponse?.currentPage - 1);
      }
    
      setAllMyOverTime(getAllOverTimeApiResponse?.data);
      if(getAllOverTimeApiResponse?.data){
        let csvData = exportTOCSV( getAllOverTimeApiResponse?.data);
        setExcelData(csvData);
      }
     
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ]);
  const decrementPage = () => {
    myOvertimeList(getAllOverTimeApiResponse?.currentPage - 1 );
    setPage(getAllOverTimeApiResponse?.currentPage - 1);
  };

  let  headers = [
    { label: 'Type', key : 'type'},
    { label: 'Start Date', key : 'start_date'},
    { label: 'Start Time', key : 'start_time'},
    { label: 'End Time', key : 'end_time'},
    { label: 'Duration(Days/Hours)', key : 'total_duration'},
    { label: 'Status', key : 'status'},
    { label: "Time in Lieu", key: 'time_in_lieu'},
    { label: 'Notes', key : 'notes'},
    { label: 'CreatedAt', key : 'createdAt'},
    { label: 'UpdatedAt', key : 'updatedAt'}
   
    ];

    const exportTOCSV = (overTimeinFo) => {
      let  dataAbence =[];
     if(overTimeinFo){
      overTimeinFo?.forEach((dExcel) => {
        let hour = parseInt(dExcel.duration) /60;
        let status = dExcel?.status;
        let statusOvertime = (status === '0' ? 'Awaiting approval' : status === '1' ? 'Approved' : status === '2' ? 'Rejected' : '');
        let val ={
          type: dExcel?.mOverTimeInfo?.overtime_name,
          start_date: moment(dExcel?.start_date).format('DD-MM-YYYY'),
          start_time: dExcel?.start_time,
          end_time: dExcel?.end_time,
          total_duration: hour,
          status: statusOvertime,
          time_in_lieu:  dExcel?.time_in_lieu === '1' ? 'Yes' : 'No',
          notes: dExcel?.notes,
          createdAt: moment(dExcel?.createdAt).format('DD-MM-YYYY'),
          updatedAt: moment(dExcel?.updatedAt).format('DD-MM-YYYY')
       };
        dataAbence.push(val);
      });
      return dataAbence;
     }
   };

  useEffect(() => {
    if (isLoadingDelete) {
      toastGeneralId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccessDelete) {
      setTimeout(() => {
        toast.dismiss(toastGeneralId.current);
      }, 10000);
      toast.success("Overtime deleted successfully!", {
        render: "Overtime deleted successfully!",
             type: "success",
             autoClose: 2000,
           isLoading: false,
           theme: "colored"
      });
      let payload = `/overtimes/?page=${page}&employee_id=${employeeIdData}`;
      myOvertimeList(payload);
    }
    if (isErrorDelete) {
      setTimeout(() => {
        toast.dismiss(toastGeneralId.current);
      }, 10000);
      const toastMessage = apiError?.data?.message
        ? apiError?.data?.message
        : "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessDelete, isErrorDelete, isLoadingDelete ]);

  useEffect(() => {
   if(isSuccessOvertimeDate) {
     setAllMyOverTime(getDataOvertimeByDate?.data);
   }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessOvertimeDate ]);

  const onDateChange = (e) => {
    if(e[0] !== null && e[1] !== null){
      let payload=`/overtimes?start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}&employee_id=${employeeIdData}`;
      myOvertimeList(payload);
  
    } else {
      let payload = `/overtimes/?page=${page}&employee_id=${employeeIdData}`;
      myOvertimeList(payload);
    }
  };
 

  const handlerChangeByOvertimeName = (e) => {
    e.preventDefault();
    if (e.target.value === '0') {
    let payload = `/overtimes?page=${page}&employee_id=${employeeIdData}`;
    myOvertimeList(payload);
    setOverTimeType(e.target.value);
    } else {
      let payload = `/overtimes?page=${page}&m_overtime_type_id=${e.target.value}&employee_id=${employeeIdData}`;
    myOvertimeList(payload);
    setOverTimeType(e.target.value);
    }
  };
  const statusData = [
    {value :"0", label: "Awaiting approval"},
    {value :"1", label: "Approved"},
    {value :"2", label: "Rejected"}
  ];

  const handlerStatusFilter = (e) => {
    e.preventDefault();
    const selectedValue = e.target.value;

    setSelectedStatus(selectedValue);

    if (selectedValue === "") {
      let payload = `/overtimes?page=${page}&employee_id=${employeeIdData}`;
      myOvertimeList(payload);
    } else {
      let payload = `/overtimes?page=${page}&employee_id=${employeeIdData}&status=${selectedValue}`;
      myOvertimeList(payload);
    }
  };

   return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | My Overtime & Timeback</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">           
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">
                My Overtime & Timeback
              </h2>
              <div className="card bg-white  mb-3">
                <div className="heading_title px-4 flex justify-end items-center py-4">
                  <div className="headerButton">
                  <CSVLink
                      className=""
                     data={excelData}
                     headers={headers}
                       filename={createCsvFileName()}
                       target="_blank"
                      style={{ textDecoration: 'none', outline: 'none', height: '5vh' }}
                  >
                      <button  className="btn-md btn-add mr-2" id="csvExport">
                      <span className="fa fa-file-export mr-2" /> Export to CSV
                      </button>
                  </CSVLink>
                  {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                    <Popup
                      open={open}
                      onClose={() => closeModal()}
                      closeOnDocumentClick={false}
                      className="claimOvertimePopup"
                      trigger={
                        <button
                          className="btn-md btn-add "
                          aria-describedby="popup-8"
                          id="claimOvertime"
                          onClick={() => setOpen((o) => !o)}
                        >
                          <span className="fa fa-plus mr-2" />Claim
                          Overtime
                        </button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            id="closeClaimOvertime"
                            onClick={() => {
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                          <AddMyOvertime close={close}
                           myOvertimeList={myOvertimeList}
                           allMasterOverTimeTypeResponse={allMasterOverTimeTypeResponse}
                           isSuccessAllMasterOvertime={isSuccessAllMasterOvertime}
                           getAllMaterOverTimeList={getAllMaterOverTimeList}
                          />
                        </div>
                      )}
                    </Popup>)}
                  </div>
                </div>
                <div className="p-4">
                  <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                    <div className="searchSec lg:w-1/5 md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 ">
                    <DatePicker
                        selectsRange={true}
                        id="startDate"
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        className="formControl"
                        placeholderText="Select Date Range"
                        onChange={(update) => {
                          setDateRange(update);
                          onDateChange(update);
                        }}
                        isClearable={true}
                        locale="en-GB"
                        />
                    </div>
                    <div className=" lg:w-1/5 md:w-1/3 w-full md:mb-0 mb-2 md:pr-4">
                    <select className="formControl" id="overtimeStatus" value={selectedStatus} onChange={handlerStatusFilter}>
                      <option value="">Select Status</option>
                      {statusData.map((status) => (
                        <option key={status.value} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                    </select>
                    </div>
                    <div className=" lg:w-1/5 md:w-1/3 w-full md:mb-0 mb-4 md:pr-4 lg:pr-0">
                      <select className="formControl" id="overTimeType"  name ='m_overtime_type_id' value= {overTimeType} onChange={(e) => handlerChangeByOvertimeName(e)}>
                      <FormSelect 
                        optionValue={"0"}
                        optionName={"Select Overtime"} />;

                      { allMasterOverTimeTypeResponse?.data?.length > 0 ? allMasterOverTimeTypeResponse?.data?.map((overtime, key) => { 
                          return   <FormSelect key={key} optionValue={overtime?.id} optionName={overtime?.overtime_name} />;
                      }) : null}

                      </select>
                    </div>
                  </div>
                  <ul className="flex approvalSubTab mb-8 ">
            <li>
              <span
                onClick={() => toggleTab2("overtime")}
                className={toggleState2 === "overtime" ? " active" : "  "}
              >
                Overtime
              </span>
            </li>
            <li>
              <span
                onClick={() => toggleTab2("time_in_lieu")}
                className={toggleState2 === "time_in_lieu" ? " active" : "  "}
              >
                Time Back
              </span>
            </li>
          </ul>
          <div className="tabCard ">
            <div
              className={
                toggleState2 === "overtime"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
           <Overtime
           overtimeData={overtimeData}
           selectedStatus={selectedStatus}
           deleteMyOvertime={deleteMyOvertime}
           decrementPage={decrementPage}
           allMyOverTime={allMyOverTime}
           allOvertimeList={allOvertimeList}
           myOvertimeList={myOvertimeList}
           roleIdData={roleIdData}
           />
            </div>
            <div
              className={
                toggleState2 === "time_in_lieu"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <TimeInLieu
              overtimeTimeBackData={overtimeTimeBackData}
              decrementPage={decrementPage}
              />
            </div>
            </div>
                </div>
                <Pagination
                        page={page}
                        pages={pages}
                        changePage={setPage}
                        decrementPage={setPage}
                      />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOverTime;
