import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    leaveType : [],
    allLeaveData : [],
    monthLeaveData: [],
    monthAllLeaveData:[],
    deductibleLeaveType: [],
    nondeductibleLeaveType: [],
    employeeLeaveAndAbsence: []
};

export const leaveTypeSlice = createSlice({
  name: "leaveTypeSlice",
  initialState,
  reducers: {
    leaveTypeInfo: (state, action) => {
      state.leaveType = action.payload;
    },
      addLeaveType: {
        reducer: (state, action) => {
        state.leaveType.rows.push(action.payload);

    }
    },
     deletedLeave:(state, action) => {
      const data =   state.leaveType.rows.filter((leave) => {
       return leave.id !== action.payload;
      });
        state.leaveType.rows = data;
    },
    allLeaveDataAction: (state, action) => {
      state.allLeaveData = action.payload;
    },
    monthLeaveDataAction: (state, action) => {
      state.monthLeaveData = action.payload;
    },
    monthLeaveAllListAction: (state, action) => {
      state.monthAllLeaveData = action.payload;
    },
    deductibleLeaveTypeAction: (state, action) => {
      state.deductibleLeaveType = action.payload;
    },
    nondeductibleLeaveTypeAction: (state, action) => {
      state.nondeductibleLeaveType = action.payload;
    },
    employeeLeaveAndAbsenceAction: (state, action) => {
      state.employeeLeaveAndAbsence = action.payload;
    }
  }
  
});

export const { nondeductibleLeaveTypeAction, employeeLeaveAndAbsenceAction, deductibleLeaveTypeAction, leaveTypeInfo, addLeaveType, deletedLeave, allLeaveDataAction, monthLeaveDataAction, monthLeaveAllListAction} = leaveTypeSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default leaveTypeSlice.reducer;