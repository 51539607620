import { useEffect, useState, useRef } from "react";
import { useDeleteLeaveAbsenceMutation, useGetAllLeaveAbsenceMutation } from "../../services/leaveAbsenceApi";
import moments from "moment";
import Popup from "reactjs-popup";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/inc/pagination";
import { toast } from "react-toastify";
import { useGetAllEmployeeListMutation } from "../../services/employeeApi";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import { REACT_APP_AUDITOR_ROLE_ID, REACT_APP_COMPANY_ADMIN_ROLE_ID, REACT_APP_EMPLOYEE_ROLE_ID, REACT_APP_MANAGER_ROLE_ID } from "../../helper/constant";
import ListLeaveEdit from "../absense/listLeaveEdit";
import { useMemo } from "react";
import ViewPopup from "../../components/ViewPopup";
import { absenceDataAction } from "../../redux/AbsenceSlice/AbsenceSlice";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { allEmployeeListDataAction, employeeScheduleInfoAction } from "../../redux/EmployeeSlice/employeeSlice";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FormSelect } from "../../components/formInput";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const ListLeave = ({ startDate, endDate, isSuccessSchedule, EmployeeAllDayScheduleRespo, roleIdData }) => {
  const ignore = useRef(false);
  /*API Calling*/
  const [  getAllLeaveAbsence, { data: dataLeaveApiData, isSuccess }  ] = useGetAllLeaveAbsenceMutation();
  const [  getDepartmentList, { data: departmentApiResponse, isSuccess: isSuccessDepartmentApi }  ] = useGetDepartmentListMutation();
  const [  deleteLeaveAbsence, { isSuccess: isSuccessDelete, isLoading: isLoadingDelete, isError: isErrorDelete, error: apiError }  ] = useDeleteLeaveAbsenceMutation();
  const [  getAllEmployeeList, { data: filterEmployeeResponseData, isSuccess: isSuccessAllEmployee }  ] = useGetAllEmployeeListMutation();

  /** Carrying out data from redux */
  const dataLeaveApiResponse = useSelector((state) => state?.persistedReducer?.absenceSlice?.absenceData);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const employeeIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);
  const loggedInUserData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const  filterEmployeeResponse = useSelector((state) => state?.persistedReducer?.employeeSlice?.allEmployeeListData);
  const dispatch = useDispatch();
  const { id } = useParams();
 
  /**States are defined here */
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startingDate, endingDate ] = dateRange;
  const [ open, setOpen ] = useState(false);
  const [ allDepartmentListData, setAllDepartmentListData ] = useState();
  const [ allEmployeeData, setAllEmployeeData ] = useState();
  const [ allLeaveInfo, setAllLeaveInfo ] = useState();
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(0);
  const pageSize = 10;
  const statusData = [
    {value :"0", label: "Applied"},
    {value :"1", label: "Approve"},
    {value :"2", label: "Rejected"}
  ];
  const closeModal = () => {
    setOpen(false);
  };


  useEffect(() => {
    if (isSuccessSchedule) {
      dispatch(employeeScheduleInfoAction(EmployeeAllDayScheduleRespo));
    }
  }, [ isSuccessSchedule ]);

  useEffect(() => {
    if (!ignore.current){
      getDepartmentList();
      getAllEmployeeList();
      let payload = `/leaves?type=${1}&page=${page}&employee_id=${id ? id : employeeIdData}&limit=100`;
      getAllLeaveAbsence(payload);
    }
    return () => { ignore.current = true; };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [  ]
  );

  useEffect(() => {
    if(isSuccessAllEmployee){
       dispatch(allEmployeeListDataAction(filterEmployeeResponseData));
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessAllEmployee ]);

  useEffect(() => {
      if (filterEmployeeResponse) {
        setAllEmployeeData(filterEmployeeResponse?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ filterEmployeeResponse ]
  );

  const toastId = useRef(null);
  useEffect(() => {
      if (isLoadingDelete) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessDelete) {
        toast.update(
          toastId.current,
          ("Leave Deleted Successfully.",
          {
            render: "Leave Deleted Successfully.",
            type: "success",
            autoClose: 8000,
            isLoading: false
          })
        );
        let payload = `/leaves?type=${1}&page=${page}&employee_id=${id}&limit=100`;
        getAllLeaveAbsence(payload);
      }
      if (isErrorDelete) {
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 8000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isErrorDelete, isSuccessDelete, isLoadingDelete ]
  );

  useEffect(() => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessDepartmentApi ]
  );
  useEffect(() => {
      let arr = [  ];
      if (departmentApiData) {
        departmentApiData?.data?.length > 0 &&
          departmentApiData?.data?.forEach((dept) => {
            if (
              loggedInUserData?.roleId == REACT_APP_MANAGER_ROLE_ID &&
              loggedInUserData.department_id &&
              dept?.id == loggedInUserData.department_id
            ) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr.push(deptOption);
            }
            if (loggedInUserData.roleId == REACT_APP_COMPANY_ADMIN_ROLE_ID) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr.push(deptOption);
            }
            if (
              loggedInUserData.roleId == REACT_APP_EMPLOYEE_ROLE_ID &&
              loggedInUserData.department_id
            ) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr.push(deptOption);
            }
          });
        setAllDepartmentListData(arr);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ loggedInUserData, departmentApiData ]
  );

  const handlerDelete = (e, id, close) => {
    e.preventDefault();
    deleteLeaveAbsence(id);
    close();
  };

  useEffect(() => {
    dispatch(absenceDataAction(dataLeaveApiData));
  }, [ isSuccess ]);

  useEffect(() => {
      if (dataLeaveApiResponse?.data) {
        let totalPages = Math.ceil(
          dataLeaveApiResponse?.data?.length / pageSize
        );
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = dataLeaveApiResponse?.data?.slice(
          firstPageIndex,
          lastPageIndex
        );
        setAllLeaveInfo(data);
        setPages(totalPages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ dataLeaveApiResponse?.data ]
  );

  // eslint-disable-next-line no-unused-vars
  const currentTableData = useMemo(() => {
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = dataLeaveApiResponse?.data?.slice(
        firstPageIndex,
        lastPageIndex
      );
      setAllLeaveInfo(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ page ]
  );

  // eslint-disable-next-line no-unused-vars
  const decrementPage = () => {
    const lastPageIndex = (page - 1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = dataLeaveApiResponse?.data?.slice(
      lastPageIndex,
      firstPageIndex
    );
    setAllLeaveInfo(data);
  };

  const onDateChange = (e) => {
    if(e[0] !== null && e[1] !== null){
      let payload = `/leaves?type=${1}&page=${page}&employee_id=${id ? id : employeeIdData}&limit=100&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}` ;
      getAllLeaveAbsence(payload);
 
    } else {
      let payload = `/leaves?type=${1}&page=${page}&employee_id=${id ? id : employeeIdData}&limit=100` ;
      getAllLeaveAbsence(payload);
    }
  };

  const  handlerStatusFilter = (e) => {
    if (e.target.value === " ") {
      let payload = `/leaves?type=${1}&page=${page}&employee_id=${id ? id : employeeIdData}&limit=100`;
      getAllLeaveAbsence(payload);    
      } else {
      let payload = `/leaves?type=${1}&page=${page}&employee_id=${id ? id : employeeIdData}&&limit=100&status=${e.target.value}`;
      getAllLeaveAbsence(payload); 
      } 
  };

  return (
    <>
      <div className="overflow-x-auto w-full whitespace-nowrap">
        <div className="w-full flex flex-wrap">
          <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 ml-4">
            <DatePicker
              selectsRange={true}
              id="leaveDatePicker"
              startDate={startingDate}
              endDate={endingDate}
              dateFormat="dd/MM/yyyy"
              className="formControl"
              placeholderText="Select Date Range"
              locale="en-GB"
              onChange={(update) => {
                setDateRange(update);
                onDateChange(update);
              }}
              isClearable={true}
            />
          </div>
          <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-4 md:pr-0 lg:pr-0">
            <select
              className="formControl"
              id="reportExpenseStatus"
              onChange={(e) => handlerStatusFilter(e)}
            >
              <FormSelect optionValue={" "} optionName={"Select Status"} />
              {statusData?.map((status, key) => {
                return (
                  <FormSelect
                    key={key}
                    optionValue={status?.value}
                    optionName={status.label}
                  />
                );
              })}
            </select>
          </div>
        </div>
        <table className="table-auto table  w-full mt-2">
          <thead className="thead-dark ">
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Total Duration</th>
              <th>Reason</th>
              <th>Added by</th>
              <th>Approved by</th>
              <th>Approved date</th>
              <th>Cancel Reason</th>
              <th>Status</th>
              {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {allLeaveInfo?.length > 0 ? (
              <>
                {allLeaveInfo &&
                  allLeaveInfo?.map((absence, index) => {
                    const hoursOrdays = absence?.employeeInfo?.hours_or_day;
                    const totalMinutes = Number(absence?.total_duration); // total duration of employee absence

                    // code to format hours
                    const formatHoursAndMinutes = (totalMinutes) => {
                      let hours = Math.floor(totalMinutes / 60);
                      let minutes = totalMinutes % 60;

                      // add leading zeros if needed
                      hours = hours < 10 ? "0" + hours : hours;
                      minutes = minutes < 10 ? "0" + minutes : minutes;
                      if (minutes == 0) return `${hours} hours`;
                      else return `${hours} hours ${minutes} minutes`;
                    };
                    const formattedhours = formatHoursAndMinutes(totalMinutes);

                    const totalLeaveDays = absence?.total_leave_days;
                    // Format the total leave days to have two decimal places
                    const formattedTotalLeaveDays = totalLeaveDays ? parseFloat(totalLeaveDays).toFixed(2) : "00.00";
                    return (
                      <tr key={index}>
                        <td>
                          {moments(absence?.start_date).format(formattedDate)}
                        </td>
                        <td>
                          {moments(absence?.end_date).format(formattedDate)}
                        </td>
                        <td>
                          {" "}
                          {hoursOrdays == "1"
                            ? `${formattedhours}`
                            : `${formattedTotalLeaveDays} days`}
                        </td>
                        <td>
                          {absence?.reason ? (
                            absence.reason.length > 10 ? (
                              <span>
                                {absence.reason.substring(0, 10)}
                                <Popup
                                  open={open}
                                  onClose={() => closeModal()}
                                  closeOnDocumentClick={false}
                                  trigger={
                                    <span
                                      onClick={() => setOpen((o) => !o)}
                                      className="cursor-pointer"
                                    >
                                      .... read more
                                    </span>
                                  }
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div className="modal">
                                      <button
                                        className="close"
                                        id="readMoreClose"
                                        onClick={() => {
                                          closeModal();
                                          close();
                                        }}
                                      >
                                        &times;
                                      </button>
                                      <div className="header">
                                        <h3>Reason</h3>
                                      </div>
                                      <div className="OpenMeassage">
                                        <div className="flex justify-between">
                                          <p>{absence?.reason}</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </span>
                            ) : (
                              absence?.reason
                            )
                          ) : (
                            "Reason not provided"
                          )}
                        </td>

                        <td>{absence?.addedByInfo?.first_name}</td>
                        <td>
                          {absence?.approvedByInfo?.first_name
                            ? absence?.approvedByInfo?.first_name
                            : "No data available"}
                        </td>
                        <td>
                          {absence?.approved_date
                            ? moments(absence?.approved_date).format(
                                formattedDate
                              ) // Format the date using Moment.js
                            : "No data available"}
                        </td>
                        <td>
                          {absence?.cancel_reason ? (
                            <ViewPopup
                              text="Description"
                              data={absence?.cancel_reason}
                            />
                          ) : (
                            " "
                          )}
                        </td>
                        <td>
                          <span
                            className={
                              absence?.status === "0"
                                ? "yellow-bedge"
                                : absence?.status === "1"
                                ? "greenBedge"
                                : absence?.status === "2"
                                ? "redBedge"
                                : absence?.status === "3"
                                ? "amberBedge"
                                : "blue-bedge"
                            }
                          >
                            {(() => {
                              if (absence?.status == 0) {
                                return "Awaiting Approval";
                              } else if (absence?.status == 1) {
                                return "Approved";
                              } else if (absence?.status == 2) {
                                return "Rejected";
                              } else if (absence?.status == 3) {
                                return "Cancelled";
                              } else {
                                return "";
                              }
                            })()}
                          </span>
                        </td>
                        <td>
                        {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <div
                                onClick={() => setOpen((o) => !o)}
                                className="actionBtn"
                              >
                                <i className="fa fa-pencil" />
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="leaveEdit"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <ListLeaveEdit
                                  absence={absence}
                                  startDateShow={startDate}
                                  endDateShow={endDate}
                                  employeeIdData={employeeIdData}
                                  allDepartmentListData={allDepartmentListData}
                                  allEmployeeData={allEmployeeData}
                                  getAllLeaveAbsence={getAllLeaveAbsence}
                                  setAllEmployeeData={setAllEmployeeData}
                                  page={page}
                                  employee_id={id}
                                  close={close}
                                />
                              </div>
                            )}
                          </Popup>)}
                          {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <div
                                onClick={() => setOpen((o) => !o)}
                                className="actionBtn cancelBtn"
                              >
                                <i className="fa fa-trash" />
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="leaveDeleteClose"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <div className="header">
                                  {" "}
                                  <h3>Deleted</h3>
                                </div>
                                <div className="content">
                                  <p>Are you sure you want to delete this ?</p>
                                  <div className="w-full mt-8">
                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                      <button
                                        className="btn save mr-2"
                                        id="leaveDeleteYes"
                                        onClick={(e) =>
                                          handlerDelete(e, absence.id, close)
                                        }
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        id="leaveDeleteNo"
                                        onClick={() => close()}
                                        className="btn cancel "
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Popup>)}
                        </td>
                      </tr>
                    );
                  })}
              </>
            ) : (
              <center className="mt-4 text-red-700 ml-[ 400% ]">
                No Data Found
              </center>
            )}
          </tbody>
        </table>
        <Pagination
          page={page}
          pages={pages}
          changePage={setPage}
          decrementPage={setPage}
        />
      </div>
    </>
  );
};

export default ListLeave;

