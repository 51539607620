import { useState } from "react";
import Joyride from "react-joyride";
import { useDispatch } from "react-redux";
import { closeJoyce } from "../../redux/JoyiceSlice/joyiceSlice";
import GeneralSignup from "../../components/GeneralSignup";

function IntroJoyRide({ isJoyce, isSignup, joyceData}) {

  const dispatch = useDispatch();
  const [ isGeneralOpen, setIsGeneralOpen ] = useState();



  const handleJoyrideCallback = (data) => {
    const { action, index } = data;
    if (action === 'close') {
      dispatch(closeJoyce(false));
    }
    
    if (action === 'next' && index === steps.length) {
      dispatch(closeJoyce(false));
    }
    if (action === 'reset') {
      
      if(isSignup){
        setIsGeneralOpen(true);
      }
      dispatch(closeJoyce(false));
    }
  };
  const [ {  steps } ] = useState({
    run: isJoyce,
    disableBeacon: true,
    steps: [ 
      {
        disableBeacon: true,
        title: "Dashboard",       
        placement: "bottom",      
        target: ".Dashboard",  
        spotlightPadding: 3,       
        content: "The Dashboard is your central hub for a comprehensive overview of your workforce's activities. It provides real-time insights into leave balances, pending approvals, expenses, and overtime, helping you manage your team's resources efficiently and make informed decisions. "
      },
      {
        placement: "bottom",
        target: ".Approvals",
        spotlightPadding: 3,  
        title:"Approvals",
        content: "The Approvals module simplifies the process of managing leave requests, expenses, and overtime/time in leiu submissions. With streamlined workflows, it allows managers to review, approve, or reject requests with ease, ensuring that your organization's policies are consistently applied. "
      },
      {
        placement: "bottom",
        target: ".Absence",
        spotlightPadding: 3,  
        title:"Absence",
        content: "The Absence module is designed to help you maintain a clear picture of employee attendance and unplanned absences. It provides a detailed analysis of absenteeism trends, enabling you to proactively address workforce availability concerns."
      },
      {
        placement: "bottom",
        target: ".Leave",
        spotlightPadding: 3,  
        title:"Leave Calendar",
        content: "The Leave Calendar offers a visual representation of approved leaves, making it a powerful tool for planning and scheduling time off. With an organized view of employee availability, you can optimize staffing and ensure business continuity."
      },
      {
        placement: "bottom",
        target: ".Employees",
        spotlightPadding: 3,  
        title:"Employees",
        content: "Dive into the Employees section to access comprehensive profiles of all your team members. It includes details on leave balances, personal information, allowances, approver settings and history allowing you to manage your workforce effectively and facilitate seamless collaboration."
      },
      {
        placement: "bottom",
        target: ".Reports",
        spotlightPadding: 3,  
        title:"Reports",
   
        content: "Generate detailed reports on leave usage, expense claims, absences recorded, and overtime hours. These reports provide valuable insights that support data-driven decision-making, helping you enhance operational efficiency."
      },
      {
        placement: "bottom",
        target: ".Settings",
        title:"Settings",     
        spotlightPadding: 3,  
        content: "The Settings menu allows you to customize and configure the software to align with your organization's unique policies and requirements. Tailor the system to suit your specific needs and preferences effortlessly."
      },
      {
        placement: "bottom",
        spotlightPadding: 3,  
        target: ".Notifications",
        title:"Notifications",       
        content: "Stay informed with real-time Notifications for pending approvals, important updates, and reminders related to leave requests, expenses, and overtime. Never miss a critical deadline or piece of information."
      },
       {
        placement: "bottom",
        target: ".Help",
        spotlightPadding: 3,  
        title:"Help",       
        content: "Access a rich knowledge base and support centre in the Help section. It's your go-to resource for tutorials, FAQs, and guidance on how to make the most of the software, ensuring a smooth user experience."
      },     
      {
        placement: "bottom",
        target: ".MyProfile",
        spotlightPadding: 3,  
        title:"My Profile",       
        content: " Manage your personal profile settings and preferences in the My Profile section. Customize your experience, update your information, and ensure that the platform works seamlessly for you."
      },
      {
        placement: "bottom",
        target: ".MyLeave",
        title:"My Leave",       
        content: "In the My Leave area, you can view your leave balances, request time off, and track the status of your leave requests. It's your portal for managing your own time off efficiently and staying organized."
      },
      {
        placement: "bottom",
        target: ".MyExpenses",
        title:"My Expenses",       
        content: "Simplify expense management in the My Expenses section. Easily submit, track, and manage your expense claims, making the reimbursement process smoother for both employees and your purchase ledger team."
      },
      {
        placement: "bottom",
        target: ".MyOvertime",
        title:"My Overtime/Time in Lieu",       
        content: "Keep a close eye on your overtime hours, request overtime approvals/time in lieu, and monitor all overtime-related activities within the system through the My Overtime section. It helps you manage your work hours effectively and ensures accurate compensation."
      }
      
      
     ]
  });


  return (
    <div>
      <GeneralSignup isSignup={isGeneralOpen} joyceData={joyceData}/>
      <Joyride 
        disableBeacon={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        continuous
        callback={handleJoyrideCallback}
        locale={{ back: 'Back', close: 'Close', last: 'Next', next: 'Next', open: 'Open the dialog', skip: 'Skip' }}
        run={isJoyce}
        steps={steps}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        showProgress        
        styles={{
            buttonNext: {
                background: '#023A51'
            },
            options: {
                primaryColor: '#023A51'
            }
        }}
        
      />
      
    </div>
  );
}

export default IntroJoyRide;


