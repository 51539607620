/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable*/
import { useRef } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Popup from "reactjs-popup";
import PrimaryButton from "../../components/primaryButton";
import { useDeleteEmployeeDocumentMutation, useGetEmployeeDocumentMutation, useGetEmployeeDocumentShareMutation, usePostEmployeeDocumentMutation } from "../../services/employeeDocumentAPI";
import { useEffect } from "react";
import { toast } from "react-toastify";
import DocumentFiles from "./documentFiles";
import { useSelector } from "react-redux";
import SharedDataListing from "./sharedDataListing";
import Select from "react-select";
import FilesListingForSharedEmployee from "./fileListingforSharedEmployee";

const EmployeeDocument = () => {
  const { id } = useParams();
  const employeeIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const shouldShowAddFolderButton = parseInt(id) === employeeIdData;
  const [employeeDocument, { data: employeeDocumentData }] = useGetEmployeeDocumentMutation();
  const [deleteEmployeeDocument, { isSuccess: deleteFolderSuccess, isLoading: deleteFolderLoading, isError: deleteFolderError }] = useDeleteEmployeeDocumentMutation();
  // const [getEmployeeDocumentShare, { data: employeeDocumentSystemData }] = useGetEmployeeDocumentShareMutation();
  const [postEmployeeDocument, { isSuccess: postFolderSuccess, isLoading: postFolderLoading, isError: postFolderError }] = usePostEmployeeDocumentMutation();
  const [openFolderPopupShare, setOpenShareFolderPopup] = useState(false);

  const [selectedFolder, setSelectedFolder] = useState(null);
  const [formData, setFormData] = useState({
    "folder_name": "",
    "company_folder": "",
  })
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [filesOpen, setFilesOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    setOpenShareFolderPopup(false);
  };
  const handlerChange = (e) => {
    setFormData({
      ...formData,
      folder_name: e.target.value,
      company_folder: (toggleState == "CompanyFolder" ? "1" : "0")
    });
  };

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
    setFilesOpen(true);
  };

  const handleFolderClickSharedFolder = (id) => {
    setSelectedFolder(id);
    setFilesOpen(true);
  };

  const handleFolderClickShare = (folder) => {
    setSelectedFolder(folder);
    setOpenShareFolderPopup(true);
  };

  const saveFolder = (e) => {
    e.preventDefault();
    const value = {
      folder_name: formData?.folder_name,
      company_folder:formData?.company_folder
    };
    postEmployeeDocument(value);
    setFormData();
  };
  const [toggleState, setToggleState] = useState("SystemFolder");
  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    // debugger;
    if (id) {
      employeeDocument(id);
    }
    // getEmployeeDocumentShare(id);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[id]);
  const toastId = useRef(null);
  useEffect(() => {
    if (postFolderSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Folder created successfully.",
        {
          render: "Folder created successfully.",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        }
      );
      employeeDocument(id);
      document?.getElementById("closePopup").click();
    }
    if (postFolderError) {
      const message = "Something went wrong.";
      toast.update(
        toastId.current,
        (message,
        {
          render: message,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[postFolderLoading, postFolderSuccess, postFolderError]);

  const handleDelete = (id) => {
    deleteEmployeeDocument(id);
  };

  useEffect(() => {
    if (deleteFolderSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Folder deleted successfully.",
        {
          render: "Folder deleted successfully.",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        }
      );
      employeeDocument(id);
    }
    if (deleteFolderError) {
      const message = "Something went wrong.";
      toast.update(
        toastId.current,
        (message,
        {
          render: message,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[deleteFolderLoading, deleteFolderSuccess, deleteFolderError]);

  const handleUpdate = (e, item) => {
    e.preventDefault();
    const value = {
      id: item?.id,
      folder_name: formData?.folder_name
    };
    postEmployeeDocument(value);
    document?.getElementById("closePopup").click();
  };

//       const [showDropdown, setDropdown] = useState(false);
//   const showDropdownClick = () =>{
//     setDropdown(!showDropdown)
//   }
const options =  [
  {value: 'testing@yopmail.com', label: 'testing@yopmail.com'},
 
];
  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize</title>
          </Helmet>
        </HelmetProvider>

        <>
          <div className=" myExpensPage container mx-auto employeeDocument">
              { /* header myfolder | shared folder | add folder */ }
              {!filesOpen ? (<div className="heading_title px-4 flex justify-between items-center ">

                <div className="approvalTab employeeTab ">
                  <ul style={{ marginTop: "0" }}>
                    <li>
                      <span
                        onClick={() => toggleTab("SystemFolder")}
                        className={
                          toggleState === "SystemFolder" ? " active" : "  "
                        }
                      >
                        My Folder
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => toggleTab("CustomFolder")}
                        className={
                          toggleState === "CustomFolder" ? "  active" : " "
                        }
                      >
                        Shared Folder
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => toggleTab("CompanyFolder")}
                        className={
                          toggleState === "CompanyFolder" ? "  active" : " "
                        }
                      >
                        Company Folder
                      </span>
                    </li>
                  </ul>
                </div>
                {shouldShowAddFolderButton && (
                  <div className="headerButton pt-4 md:pt-0">
                  { /* add folder popup */}
                    <Popup
                      open={open}
                      onClose={() => closeModal()}
                      closeOnDocumentClick={false}
                      trigger={
                        (toggleState === "SystemFolder" || (toggleState === "CompanyFolder" && roleIdData=== 2)) &&
                         <button
                          id="closePopup"
                          className="btn-md btn-add "
                          aria-describedby="popup-8"
                          onClick={() => setOpen((o) => !o)}
                        >
                          <span className="fa fa-folder-plus mr-2" />
                          Add Folder
                        </button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            id="addFolderClose"
                            onClick={() => {
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                          <div className="header">
                            <h3>Add Folder</h3>
                          </div>
                          <div className="content">
                            <form onSubmit={saveFolder}>
                              <div className="flex flex-wrap ">
                                <div className=" w-full">
                                  <div className="form-group mb-6 ">
                                    <label className="formBlock	">
                                      Folder Name
                                    </label>
                                    <input
                                      type="text"
                                      name="name"
                                      id="name"
                                      className="formControl"
                                      onChange={(e) => handlerChange(e)}
                                    />
                                    {/* <span className="text-danger">{getErrorMessage('name')}</span> */}
                                    <div className="help-block" />
                                  </div>
                                </div>

                                <div className="lg:w w-full  ">
                                  <div className="w-full mt-4">
                                    <div className="form-group pt-4 pb-3 flex  flex-wrap justify-center ">

                                      <button aria-describedby="popup-8"
                                        className="btn save mr-2"
                                        type="submit"
                                        id="folderSubmit"
                                      >Create </button>
                                      <PrimaryButton
                                        btnText="Cancel"
                                        onClick={() => close()}
                                        Btnclass="btn cancel mr-2"
                                        btntype="button"
                                        buttonID="folderCancel"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </div>
                )}
              </div>) : (<><button className="p-4 fa fa-arrow-left text-xl" onClick={() => setFilesOpen(false)} /> <span className="text-lg">Back</span></>)}

              {/* document space */}
              <div className="tabCard">

                  { /* My folder code */ }
                  <div className={ toggleState === "SystemFolder"
                        ? "tabContent tabContentActive"
                        : "tabContent"}
                  >
                    {!filesOpen && <div className="p-4 grid lg:grid-cols-10 gap-2 max-[420px]:grid-cols-1 grid-cols-2 sm:grid-cols-4 md:grid-cols-6">
                        {
                          employeeDocumentData?.employeeFolders?.length > 0 ? (
                            employeeDocumentData?.employeeFolders?.map((item, index) => {
                              const words = item?.folder_name?.split(/[\s_]+/);
                              // Create an array to store JSX elements with line breaks after every three words
                              const folderNameElements = [];
                              for (let i = 0; i < words.length; i += 3) {
                                folderNameElements.push(
                                  <p key={i}>
                                    {words.slice(i, i + 3).join(' ')}
                                  </p>
                                );
                              }
                              return (
                                <div
                                //onSubmit={(e) => { e.preventDefault(); handleDelete(e, item.id); }}
                                >
                                  <div key={index} className="  text-center my-5 relative">

                                    <div className="border p-2 pt-5 mb-2 w-full rounded h-[80px] flex flex-wrap items-center cursor-pointer justify-center text-xl" onClick={() => handleFolderClick(item)}>
                                      <span className="fa fa-folder secondry-text text-4xl " />
                                    </div>
                                    
                                    { /* three dots */ }
                                    <div className="absolute right-0 top-0 px-3  hoverIcon  primary-text cursor-pointer p-2">
                                      <span className="fa fa-ellipsis-v text-3xl  " />
                                      <div className="border rounded-md w-36 documentPopupList "  >
                                        <ul>
                                          { /* open */ }
                                            <li onClick={() => handleFolderClick(item)}>
                                                <span className="fa fa-file" />
                                                Open
                                            </li>  

                                          { /* rename file */ }
                                            {shouldShowAddFolderButton && (
                                              <Popup
                                                open={open}
                                                onClose={() => closeModal()}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                  // <span className="fa fa-pencil text-[13px] mr-1 text-white p-1 rounded bg-primary  w-6" onClick={() => setOpen((o) => !o)}></span>
                                                  <li onClick={() => setOpen((o) => !o)}>
                                                    <span className="fa fa-edit" />
                                                    Rename
                                                  </li>
                                                }
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      id="editClose"
                                                      onClick={() => {
                                                        closeModal();
                                                        close();
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                    <div className="header">
                                                      <h3>Rename Folder</h3>
                                                    </div>
                                                    <div className="content">
                                                      <form

                                                        onSubmit={(e) => handleUpdate(e, item)}
                                                      >
                                                        <div className="flex flex-wrap ">
                                                          <div className=" w-full">
                                                            <div className="form-group mb-6 ">
                                                              <label className="formBlock	">
                                                                Folder Name
                                                              </label>
                                                              <input
                                                                type="text"
                                                                name="name"
                                                                id="editName"
                                                                className="formControl"
                                                                defaultValue={
                                                                  item.folder_name
                                                                }
                                                                onChange={(e) =>
                                                                  handlerChange(e, true)
                                                                }
                                                              />
                                                              <div className="help-block" />
                                                            </div>
                                                          </div>

                                                          <div className="lg:w w-full  ">
                                                            <div className="w-full mt-4">
                                                              <div className="form-group pt-4 pb-3 flex  flex-wrap justify-center ">
                                                                <PrimaryButton
                                                                  btnText="Update"
                                                                  Btnclass="btn save mr-2"
                                                                  btnype="button"
                                                                  buttonID="editUpdate"

                                                                />
                                                                <PrimaryButton
                                                                  btnText="Cancel"
                                                                  onClick={() => close()}
                                                                  Btnclass="btn cancel mr-2"
                                                                  btntype="button"
                                                                  buttonID="editCancel"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                )}
                                              </Popup>
                                            )}
                                                                                  
                                          {/* share */}
                                          <li onClick={() => handleFolderClickShare(item)}>
                                              <span className="fa fa-share" />
                                              Share  
                                            </li>
                                            {shouldShowAddFolderButton && (
                                              <Popup
                                                open={open}
                                                onClose={() => closeModal()}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                  // <span onClick={() => setOpen((o) => !o)} className="fa fa-trash text-[13px] text-white p-1 rounded bg-red-500 w-6" ></span>
                                                  <li onClick={() => setOpen((o) => !o)}>
                                                    <span className="fa fa-trash" />
                                                    Delete
                                                  </li>
                                                }
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      id="deleteClose"
                                                      onClick={() => {
                                                        closeModal();
                                                        close();
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                    <div className="header">
                                                      {" "}
                                                      <h3>Delete</h3>
                                                    </div>
                                                    <div className="content">
                                                      <p>Are you sure you want to delete this {item?.folder_name} directory ?</p>
                                                      <div className="w-full mt-8">
                                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                                          <button className="btn save mr-2"
                                                            id="deleteYes"
                                                            onClick={(e) => { e.preventDefault(); handleDelete(item?.id); }}
                                                          >Yes</button>
                                                          <button
                                                            type="button"
                                                            id="deleteNo"
                                                            onClick={() => close()}
                                                            className="btn cancel "
                                                          >
                                                            No
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </Popup>
                                            )}
                                        </ul>
                                    </div>
                                  </div>
                                                    
                                    <p>{folderNameElements ?? ''}</p>
                                  </div>
                                </div>
                              )
                            })
                          )
                            :
                            'Empty'
                        }
                        
                        { /* shared folder popup */ }
                        <Popup
                          open={openFolderPopupShare}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal ">
                              <button
                                className="close"
                                id="folderClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <div className="header">
                                <h3>Shared folder</h3>
                              </div>
                              <SharedDataListing selectedFolder={selectedFolder}
                              />
                             
                            </div>
                          )}
                        </Popup>

                    </div> }  

                    {/* files modal */}
                    {filesOpen && <div className="modal" >
                    
                    {selectedFolder ? (
                      <DocumentFiles selectedFolder={selectedFolder}/>
                    ) : ' ' } 
                    </div> }
                        
                  </div>
                  {/* shared folder code  */}
                  <div
                    className={
                      toggleState === "CustomFolder"
                        ? "tabContent tabContentActive"
                        : "tabContent"
                    }
                  >

                    <div>
                     { !filesOpen && <div className="p-4 grid lg:grid-cols-10 gap-2 max-[420px]:grid-cols-1 grid-cols-2 sm:grid-cols-4 md:grid-cols-6">
                        {
                          employeeDocumentData?.sharedFolders?.length > 0 ? (
                            employeeDocumentData?.sharedFolders?.map((shared, index) => {
                              return (
                                <div
                                >
                                  <div key={index} className=" text-center my-5 relative">
                                    <div className="border p-2 pt-5 mb-2 w-full rounded h-[80px] flex flex-wrap items-center cursor-pointer justify-center text-xl" onClick={() => handleFolderClickSharedFolder(shared?.folder_id)}>
                                      <span className="fa fa-folder secondry-text text-4xl" />
                                    </div>


                                    <div className="absolute right-0 top-0 px-3  hoverIcon  primary-text cursor-pointer p-2" >
                                      <span className="fa fa-ellipsis-v text-3xl  " />
                                      
                                      <div className="border rounded-md w-36 documentPopupList 
                                      " 
                                       >
                                        <ul>
                                          <li onClick={() => handleFolderClickSharedFolder(shared?.folder_id)}>
                                              <span className="fa fa-file" />
                                              Open
                                          </li>  
                                        </ul>
                                      </div>
                                    </div>

                                    <p>{shared?.DocumentFolder?.folder_name ?? ''}</p>
                                    <p className="text-sm">(Folder shared by {shared?.sharedBy?.first_name ?? ''} {shared?.sharedBy?.last_name ?? ''})</p>
                                  </div>
                                </div>
                              )
                            })
                          )
                            :
                            ' No shares found for this profile '
                        }
                        
                       
                      </div>}

                      { filesOpen && <div className="modal " >
                              <div className="header"></div>

                              <FilesListingForSharedEmployee
                                selectedFolder={selectedFolder}
                              />
                      </div> }
                    </div>

                  </div>
                  {/* company folder code */}
                  <div
                    className={
                      toggleState === "CompanyFolder"
                        ? "tabContent tabContentActive"
                        : "tabContent"
                    }
                  >
                  {roleIdData===2 ?
                  (<div>
                  {!filesOpen && <div className="p-4 grid lg:grid-cols-10 gap-2 max-[420px]:grid-cols-1 grid-cols-2 sm:grid-cols-4 md:grid-cols-6">
                        {
                          employeeDocumentData?.companySharedFolder?.length > 0 ? (
                            employeeDocumentData?.companySharedFolder?.map((item, index) => {

                              const words = item?.folder_name?.split(/[\s_]+/);
                              // Create an array to store JSX elements with line breaks after every three words
                              const folderNameElements = [];
                              for (let i = 0; i < words.length; i += 3) {
                                folderNameElements.push(
                                  <p key={i}>
                                    {words.slice(i, i + 3).join(' ')}
                                  </p>
                                );
                              }
                           
                              return (
                                <div>
                                  <div key={index} className=" text-center my-5 relative">
                                    
                                    <div className="border p-2 pt-5 mb-2 w-full rounded h-[80px] flex flex-wrap items-center cursor-pointer justify-center text-xl" onClick={() => handleFolderClick(item)}>
                                      <span className="fa fa-folder secondry-text text-4xl " />
                                    </div>
                                    
                                    { /* three dots */ }
                                    <div className="absolute right-0 top-0 px-3  hoverIcon  primary-text cursor-pointer p-2">
                                      <span className="fa fa-ellipsis-v text-3xl  " />
                                      <div className="border rounded-md w-36 documentPopupList "  >
                                        <ul>
                                          { /* open */ }
                                            <li onClick={() => handleFolderClick(item)}>
                                                <span className="fa fa-file" />
                                                Open
                                            </li>  
                                          
                                          
                                          { /* rename file */ }
                                            {shouldShowAddFolderButton && (
                                              <Popup
                                                open={open}
                                                onClose={() => closeModal()}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                  // <span className="fa fa-pencil text-[13px] mr-1 text-white p-1 rounded bg-primary  w-6" onClick={() => setOpen((o) => !o)}></span>
                                                  <li onClick={() => setOpen((o) => !o)}>
                                                    <span className="fa fa-edit" />
                                                    Rename
                                                  </li>
                                                }
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      id="editClose"
                                                      onClick={() => {
                                                        closeModal();
                                                        close();
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                    <div className="header">
                                                      <h3>Rename Folder</h3>
                                                    </div>
                                                    <div className="content">
                                                      <form

                                                        onSubmit={(e) => handleUpdate(e, item)}
                                                      >
                                                        <div className="flex flex-wrap ">
                                                          <div className=" w-full">
                                                            <div className="form-group mb-6 ">
                                                              <label className="formBlock	">
                                                                Folder Name
                                                              </label>
                                                              <input
                                                                type="text"
                                                                name="name"
                                                                id="editName"
                                                                className="formControl"
                                                                defaultValue={
                                                                  item.folder_name
                                                                }
                                                                onChange={(e) =>
                                                                  handlerChange(e, true)
                                                                }
                                                              />
                                                              <div className="help-block" />
                                                            </div>
                                                          </div>

                                                          <div className="lg:w w-full  ">
                                                            <div className="w-full mt-4">
                                                              <div className="form-group pt-4 pb-3 flex  flex-wrap justify-center ">
                                                                <PrimaryButton
                                                                  btnText="Update"
                                                                  Btnclass="btn save mr-2"
                                                                  btnype="button"
                                                                  buttonID="editUpdate"

                                                                />
                                                                <PrimaryButton
                                                                  btnText="Cancel"
                                                                  onClick={() => close()}
                                                                  Btnclass="btn cancel mr-2"
                                                                  btntype="button"
                                                                  buttonID="editCancel"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                )}
                                              </Popup>
                                            )}
                                                                                  
                                          {/* Delete */}
                                            {shouldShowAddFolderButton && (
                                              <Popup
                                                open={open}
                                                onClose={() => closeModal()}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                  // <span onClick={() => setOpen((o) => !o)} className="fa fa-trash text-[13px] text-white p-1 rounded bg-red-500 w-6" ></span>
                                                  <li onClick={() => setOpen((o) => !o)}>
                                                    <span className="fa fa-trash" />
                                                    Delete
                                                  </li>
                                                }
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      id="deleteClose"
                                                      onClick={() => {
                                                        closeModal();
                                                        close();
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                    <div className="header">
                                                      {" "}
                                                      <h3>Delete</h3>
                                                    </div>
                                                    <div className="content">
                                                      <p>Are you sure you want to delete this {item?.folder_name} directory ?</p>
                                                      <div className="w-full mt-8">
                                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                                          <button className="btn save mr-2"
                                                            id="deleteYes"
                                                            onClick={(e) => { e.preventDefault(); handleDelete(item?.id); }}
                                                          >Yes</button>
                                                          <button
                                                            type="button"
                                                            id="deleteNo"
                                                            onClick={() => close()}
                                                            className="btn cancel "
                                                          >
                                                            No
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </Popup>
                                            )} 
                                        </ul>
                                    </div>
                                  </div>                  
                                    <p>{folderNameElements ?? ''}</p>
                                  </div>
                                </div>
                              )
                            })
                          )
                            :
                            'Empty'
                        }

                    </div> }  

                    {/* files modal */}
                    {filesOpen && <div className="modal" >
                    
                    {selectedFolder ? (
                      <DocumentFiles selectedFolder={selectedFolder}  />
                    ) : ' ' } 
                    </div> }
                    </div>)
                    :
                  (<div>
                     { !filesOpen && <div className="p-4 grid lg:grid-cols-10 gap-2 max-[420px]:grid-cols-1 grid-cols-2 sm:grid-cols-4 md:grid-cols-6">
                        {
                          employeeDocumentData?.companySharedFolder?.length > 0 ? (
                            employeeDocumentData?.companySharedFolder?.map((item, index) => {
                              const words = item?.folder_name?.split(/[\s_]+/);
                              const folderNameElements = [];
                              for (let i = 0; i < words.length; i += 3) {
                                folderNameElements.push(
                                  <p key={i}>
                                    {words.slice(i, i + 3).join(' ')}
                                  </p>
                                );
                              }
                              return (
                                <div
                                >
                                  <div key={index} className="text-center my-5 relative">
                                    <div className="border p-2 pt-5 mb-2 w-full rounded h-[80px] flex flex-wrap items-center cursor-pointer justify-center text-xl" onClick={() => handleFolderClickSharedFolder(item?.id)}>
                                      <span className="fa fa-folder secondry-text text-4xl" />
                                    </div>


                                    <div className="absolute right-0 top-0 px-3  hoverIcon  primary-text cursor-pointer p-2" >
                                      <span className="fa fa-ellipsis-v text-3xl  " />
                                      
                                      <div className="border rounded-md w-36 documentPopupList 
                                      " 
                                       >
                                        <ul>
                                          <li onClick={() => handleFolderClickSharedFolder(item?.id)}>
                                              <span className="fa fa-file" />
                                              Open
                                          </li>  
                                        </ul>
                                      </div>
                                    </div>

                                    <p>{folderNameElements ?? ''}</p>
                                  </div>
                                </div>
                              )
                            })
                          )
                            :
                            ' No shares found for this profile '
                        }
                        
                       
                      </div>}

                      { filesOpen && <div className="modal " >
                              <div className="header"></div>

                              <FilesListingForSharedEmployee
                                selectedFolder={selectedFolder}
                              />
                      </div> }
                    </div>)
                    }


                  </div>

                  {/* ---------------------- */}
                </div>
              </div>
        </>
      </div>
      

    </>
  );
};

export default EmployeeDocument;
