//import { useState } from "react";
/* eslint-disable camelcase */
import { useEffect, useState, useRef} from "react";
import PrimaryButton from "../../components/primaryButton";
import LoadingScreen from "../../helper/LoadingScreen";
import {  toast } from "react-toastify";
import { useCreateCompanyMutation } from "../../services/companyApi";
import { useSelector } from "react-redux";


const CarryForward = ({carryForwardUpdatePermissions, companyData, isCompanySuccess, isCompanyLoading}) => {
  const nextcarryForwardDate = useSelector((state) => state?.authState?.nextcarryForward);
 
  const [ formData, setFormData ] = useState({
    days:"",
    day_hours: "",
    startMonth: ""
  });
  const [ createCompany, { isSuccess, isLoading, isError, error:apiError} ] = useCreateCompanyMutation();
  
  const handlerSave = (e) => {
    e.preventDefault();
    let value = {
      // "name":companyData?.companyData?.name,
      // "email":companyData?.companyData?.email,    
      // "tel_no":companyData?.companyData?.tel_no, 
      // "website":companyData?.companyData?.website?.toString(), 
      // "address":companyData?.companyData?.address,
      // "address2":companyData?.companyData?.address2,
      // "city":companyData?.companyData?.city,
      // "postal_code":companyData?.companyData?.postal_code,
      // "state":companyData?.companyData?.state,
      // "country":companyData?.companyData?.country,
      "max_carry_forward": `${formData.days}`,
      // "day_hours": `${formData.day_hours}`,
      "start_month":formData.startMonth
    };
    createCompany(value);
  };
  
  const handlerChange = (event) => {
    const { name, value } = event.target;
    // hours = value * formData.day_hours;
    setFormData({ ...formData, [name]: value });
   
    // formData.day_hours= value * companyData?.companyData?.day_hours;
  };

  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccess) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.update(
        toastId.current,
        ("Carry Forward data Updated Successfully!",
        {
          render: "Carry forward data updated successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
    }
    if (isError) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      const toastMessage = apiError.data.message
        ? apiError.data.message
        : "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );

    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);
  const toastId = useRef(null);
  useEffect(() => {
    if(companyData?.companyData !== undefined) {
      setFormData({
        days:companyData?.companyData?.max_carry_forward,
        day_hours:(companyData?.companyData?.day_hours * companyData?.companyData?.max_carry_forward),
        startMonth:companyData?.companyData?.start_month 
      });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ companyData, isCompanySuccess ]);

  

  return (
    <>
    {
      isCompanyLoading ? ( <LoadingScreen /> ) :(
        <>
         {/* <ToastContainer /> */}
        <div className="heading_title px-4 flex justify-between items-center py-4">
          <div>
            <h3 className=" font-semibold text-lg">Carry Forward</h3>
            <p>
              When you hit year end, how much allowance can be carried forward
              into the next year?
            </p>
          </div>
        </div>
        <div className="p-4">
          <div className="flex mb-6 flex-wrap">
            <div className="lg:w-1/3 w-full">
              <h5>Next carry forward</h5>
            </div>
            <div className="lg:w-1/3 w-full">
              <p>{nextcarryForwardDate}</p>
            </div>
          </div>
          <div className="flex mb-4 items-center">
            <div className="md:w-1/3 w-1/2">
              <h5>Days</h5>
            </div>
            <div className="w-1/3 ">
              <input
                type="number"
                name="days"
                id="days"
                onChange={handlerChange}
                value={formData.days}
                className="p-2 bg-gray-100 w-20 rounded-sm border"
              />
            </div>
          </div>
          <div className="flex items-center">
            <div className="md:w-1/3 w-1/2">
              <h5>Hours</h5>
            </div>
            <div className="w-1/3">
              <input
                type="text"
                name="day_hours"
                id="day_hours"
                
                defaultValue={formData.days ? formData.days * companyData?.companyData?.day_hours : formData.day_hours}
                className="p-2 bg-gray-100 w-20 rounded-sm border"
                readOnly
              />
            </div>
          </div>

          
          {
            carryForwardUpdatePermissions[0]?.status === true &&
            <div className="w-full mt-8">
            <div className="form-group pt-4 pb-3 flex  ">
              <PrimaryButton Btnclass="btn save mr-2" buttonID="saveCarryForward" onClick={(e) => handlerSave(e)}  btnText="Save" />
              {/* <PrimaryButton Btnclass="btn cancel mr-2" btnText="Cancel" /> */}
            </div>
          </div>
          }
          
        </div>
        </>
      )

    }
   </>
  );
};

export default CarryForward;
