/* eslint-disable no-unused-vars */
//import React from 'react';
import { useState } from "react";
import Pagination from "../../components/inc/pagination";
import NoDataFound from "../../helper/noDataFound";
import Popup from "reactjs-popup";
import { useSearchParams } from "react-router-dom";
import moments from "moment";
import EditMyOvertime from "./EditMyOvertime";
import { useSelector } from "react-redux";
import ViewPopup from "../../components/ViewPopup";
import { REACT_APP_AUDITOR_ROLE_ID } from "../../helper/constant";

const Overtime = ({overtimeData, selectedStatus, deleteMyOvertime, decrementPage, myOvertimeList, allMyOverTime, allOvertimeList, roleIdData}) => {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const pageTerm = searchParams.get('page') || '';
    const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
    const [ pages, setPages ] = useState();
    const [ open, setOpen ] = useState(false);
    const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
    const closeModal = () => {
        setOpen(false);
    };

  const handlerDelete = (e, id, close) => {
    e.preventDefault();
    deleteMyOvertime(id);
    close();
  };
  return (
    <div>
      <div className="overflow-x-auto w-full whitespace-nowrap">
        <table className="table-auto table  w-full">
          <thead className="thead-dark ">
            <tr>
              <th>Type </th>
              <th>Date </th>
              <th>Start-End(Time)</th>
              <th>Payment</th>
              <th>Break</th>
              <th>Duration(Hours)</th>
              <th>Lieu Time</th>
              <th className="tdMaxWidth">Notes</th>
              <th>Status</th>
              {selectedStatus === "2" || selectedStatus === "" ? (
                <th>Reason</th>
              ) : null}
              {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {overtimeData?.length > 0 ? (
              <>
                {overtimeData?.map((over, index) => {
                  const formatDuration = (duration) => {
                    const hours = Math.floor(duration / 60).toString().padStart(2, '0');
                    const minutes = (duration % 60).toString().padStart(2, '0');
                    return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} min${minutes !== 1 ? 's' : ''}`;
                  };
                  
                  const formattedDuration = formatDuration(over?.duration);
                  let breakTime = over?.break || "0:0";

                  // Split the time into hours and minutes
                  let [ hoursBreak, minutesBreak ] = breakTime.split(":");

                  // Convert the string values to integers
                  hoursBreak = parseInt(hoursBreak, 10);
                  minutesBreak = parseInt(minutesBreak, 10);

                  // Calculate the total duration in minutes
                  let totalMinutesBreak = hoursBreak * 60 + minutesBreak;

                  // Format the duration
                  let formattedDurationBreak = `${totalMinutesBreak} min`;
                  
                  return (
                    <tr key={index}>
                      <td>{over?.type}</td>
                      <td>{moments(over?.date).format(formattedDate)}</td>
                      <td>
                        {over?.start_time}-{over?.end_time}
                      </td>
                      <td>{over?.is_Paid === "1" ? "Paid" : "UnPaid"}</td>
                      <td>{formattedDurationBreak}</td>
                      <td>
                        {over?.is_Paid === "1"
                          ? formattedDuration
                          : formattedDuration}
                      </td>
                      <td>{over?.time_in_lieu === "1" ? "Yes" : "No"}</td>
                      <td className="tdMaxWidth">
                        <ViewPopup text={"Notes"} data={over?.notes} />
                      </td>
                      {/* <td>{over?.notes}</td> */}
                      <td>
                        <span
                          className={
                            over?.status === "0"
                              ? "yellow-bedge"
                              : over?.status === "1"
                              ? "greenBedge"
                              : over?.status === "2"
                              ? "redBedge"
                              : "blue-bedge"
                          }
                        >
                          {over?.status == "1"
                            ? "Approved"
                            : over?.status === "2"
                            ? "Rejected"
                            : "Awaiting approval"}
                        </span>
                      </td>
                      <td>
                        {over?.cancelReason ? (
                          <ViewPopup text={"Notes"} data={over?.cancelReason} />
                        ) : (
                          " "
                        )}
                      </td>
                      {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                      <td>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          className="claimOvertimePopup"
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa fa-pencil" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="editOvertimeClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <EditMyOvertime
                                over={over}
                                myOvertimeList={myOvertimeList}
                                allOvertimeList={allOvertimeList}
                                close={close}
                                allMyOverTime={allMyOverTime}
                              />
                            </div>
                          )}
                        </Popup>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn cancelBtn"
                            >
                              <i className="fa fa-trash" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="trashOvertimeClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <div className="header">
                                {" "}
                                <h3>Deleted</h3>
                              </div>
                              <div className="content">
                                <p>Are you sure you want to delete this ?</p>
                                <div className="w-full mt-8">
                                  <div className="form-group pt-4 pb-3 flex justify-center ">
                                    <button
                                      className="btn save mr-2"
                                      id="overtimeDeleteYes"
                                      onClick={(e) =>
                                        handlerDelete(e, over?.id, close)
                                      }
                                    >
                                      Yes
                                    </button>
                                    <button
                                      type="button"
                                      id="overtimeDeleteNo"
                                      onClick={() => close()}
                                      className="btn cancel "
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </td>)}
                    </tr>
                  );
                })}
              </>
            ) : (
              <center className="mt-4">{<NoDataFound />}</center>
            )}
          </tbody>
        </table>
        <Pagination
          page={page}
          pages={pages}
          changePage={setPage}
          decrementPage={decrementPage}
        />
      </div>
    </div>
  );
};

export default Overtime;
