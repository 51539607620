/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger*/
import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ModifiedToast } from "../../components/toaster";
import { useEffect } from "react";


import SettingTab from "./settingTab";
import { useParams } from "react-router-dom";

function Settings() {
const {tab} = useParams();

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Schedulize | Settings </title>
        </Helmet>
      </HelmetProvider>

      <ModifiedToast />
      <div className="py-6">
        <div className="container mx-auto ">
          <h2 className="text-xl mb-3 font-semibold ">Settings</h2>
          <div className="card  bg-white md:p-6 p-4 mb-3">
            <SettingTab tab={tab}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
