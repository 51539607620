/* eslint-disable react/no-unknown-property */
/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import {
  FormInput,
  FormTextarea,
  FormSelect
} from "../../../components/formInput";

import PrimaryButton from "../../../components/primaryButton";
import {useCreateExpensesMutation, useGetAllExpensesMutation, useUpdateExpensesMutation,  useDeleteExpensesMutation, useGetAllMileageExpensesMutation} from  "../../../services/masterExpensesApi";
import {useDispatch, useSelector } from "react-redux";
import { masterExpensesInfo, addMasterExpenses, masterExpensesUpdate, masterExpensesDelete } from "../../../redux/MasterExpenses/masterExpepesesSlice";
import { toast } from "react-toastify";
import Pagination from "../../../components/inc/pagination";
import {useSearchParams} from 'react-router-dom';
import ViewPopup from "../../../components/ViewPopup";

const ExpenseTypes = ({ expenseTypesUpdatePermissions, expenseTypesCreatePermissions, expenseTypesDeletePermissions}) => {
  const [ createExpenses, {data: createExpensesData, isSuccess: isCreateSuccess, isError, isLoading: isCreateLoading} ] = useCreateExpensesMutation();
  const [ getAllExpenses, {data: expensesData, isSuccess: isExpensesSuccess} ] = useGetAllExpensesMutation();
  const [ updateExpenses, {data: updateExpenseData, isSuccess: isSuccessUpdateExpenses, isError: isErrorUpdateExpenses, isLoading: isLoadingUpdateExpenses, error: apiUpdateError} ] = useUpdateExpensesMutation();
  const [ deleteExpenss, {data: deleteExpenseData, isSuccess: isSuccessDeleteExpenses, isError: isErrorDeleteExpenses, isLoading: isLoadingDeleteExpenses, error: apiError} ] =  useDeleteExpensesMutation();
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const [ open, setOpen ] = useState(false);
  const [ openAdd, setOpenAdd ] = useState(false);
  const [ openEdit, setOpenEdit ] = useState(false);
  const dispatch = useDispatch();
  const [ expenses, setExpenses ] = useState('0');
  const [ expenseId, setExpenseId ] = useState('');
  const [ expenseTypes, setExpensesType ] = useState([]);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [ mileageExpenseTypes, setMileageExpenseTypes ] = useState([]);
  const getAllExpensesData = useSelector((state) => state.masterExpensesState);
  const {currentPage, masterExpenses, totalPages} = getAllExpensesData;
  const toastId = useRef(null);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState();
  const getExpensesType = () => {
    if(masterExpenses && masterExpenses.data ){
        const expenseData =  masterExpenses.data.filter((exp) => exp.is_mileage === '0');
        setExpensesType(expenseData);
        const mileageExpense =  masterExpenses.data.filter((mExp) => mExp.is_mileage === '1');
        setMileageExpenseTypes(mileageExpense);
    }
   };

   const statusData = [
   
    {value :"1", label: "Active"},
    {value :"2", label: "Inactive"}
    
  ]; 
   const [ error, setError ] = useState({
    nameError:'',
    descriptionError: '',
    statusError:''
   });

   const [ formData, setFormData ] = useState({
    expense_name: "",    
    description: "",
    status :"",
    is_mileage: expenses,
    pence_per_mile: '',
    companies_id:"1",
    id:""
  });
   
   useEffect(() => {
    getExpensesType();
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ masterExpenses ]);

  const closeModal = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setOpenDelete(false);
     setFormData({
      expense_name: "",    
      description: "",
      status :"",
      is_mileage: expenses,
      pence_per_mile: '',
      companies_id:"1",
      id:""
    });
  };
  useEffect(() => {
    getAllExpenses(page);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

  useEffect(() => {
     //eslint-disable-next-line no-undef
    if (isExpensesSuccess) {
      if(expensesData?.data?.length < 1 && expensesData?.currentPage >1 ) {
        getAllExpenses(expensesData?.currentPage - 1);
      }
        setPages(expensesData?.totalPages);
    } 
    
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isExpensesSuccess ]);

const decrementPage = () => {
  getAllExpenses(expensesData?.currentPage - 1 );
  setPage(expensesData?.currentPage - 1);
};

  const getExpensesData = () => {
    dispatch(masterExpensesInfo(expensesData));
  };
  useEffect(() => {
    if(isExpensesSuccess) {
      getExpensesData();
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isExpensesSuccess ]);

  const getCreateExpenses = () => {
    dispatch(addMasterExpenses(createExpensesData));
  };
  
  useEffect(() => {
    if(isCreateLoading){
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if(isCreateSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Expense type created successfully.", {
        render: "Expense type created successfully.",
             type: "success",
             autoClose: 2000,
             theme: "colored",
           isLoading: false
      });
      getCreateExpenses();
      setOpenAdd(false);
      getAllExpenses(page);
    }
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage = 
      // apiError.data.message
      //   ? apiError.data.message
      //   :
         "Something went wrong";
         toast.dismiss(toastId.current);
         toast.error(toastMessage, {
           render: toastMessage,
                type: "error",
                autoClose: 2000,
              isLoading: false
         });
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isCreateSuccess, isError, isCreateLoading ]);

  const getupdatedExpenses = () => {
      dispatch(masterExpensesUpdate(updateExpenseData));
      setOpen(true);
      closeModal();
  };

   useEffect(() => {
      if(isLoadingUpdateExpenses) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
     }
    if(isSuccessUpdateExpenses) {
      toast.dismiss(toastId.current);
      // getupdatedExpenses();
      setOpenEdit(false);
      setOpenDelete(false);
      toast.update(
        toastId.current,
        ("Expense type updated successfully.",
        {
          render: "Expense type updated successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
    
     
      getAllExpenses(page);
    }
   
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage = 
      apiUpdateError.data.message
        ? apiUpdateError.data.message
        : 
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessUpdateExpenses, isErrorUpdateExpenses, isLoadingUpdateExpenses ]);

  
 
  const handlerChange = (e) => {
    e.preventDefault();
    const {name} = e.target;
    if(name === 'expense_name') {
      if(name === '') {
      setError({...error, nameError: "please enter the name " });
      } else {
        setError({...error, nameError: " " });
      }
    }
    if(name === 'description') {
      if(name === '') {
      setError({...error, descriptionError: "please enter the description " });
      } else {
        setError({...error, descriptionError: " " });
      }
    }
    
     setFormData({...formData, [name]:e.target.value});
  };
  
 
  const validation = () => {
    const {expense_name, description, status} = formData;
    let isFormValidated = true;
        if(expense_name === '') {
    
    setError((errorValue) => { 
      return {
        ...errorValue,
        nameError: 'Please enter expense name'
      };
    });
      isFormValidated = false;
  } 
   if(description === '') {
    setError((errorValue) => { 
      return {
        ...errorValue,
        descriptionError: 'Please enter description name'
      };
    });
      isFormValidated= false;
  }
  
  return isFormValidated;
  };
  

 
  const handlerFormSubmit = (e, edit=false, close, exp=false) => {
    e.preventDefault();
   
    if(edit) {
      let value = {
        "expense_name" : formData.expense_name ? formData.expense_name : exp.expense_name,    
        "description" : formData.description ? formData.description : exp.description,
        "status" : formData.status ? formData.status : exp.status,
        "is_mileage": '0',
        "pence_per_mile": formData.pence_per_mile ? formData.pence_per_mile : exp.pence_per_mile,
        "id": exp.id
        };
        updateExpenses(value);
        close();
     } else {
     
      if(validation()) {
      let value = {
        "expense_name" : formData.expense_name,    
        "description" : formData.description,
        "status" :formData.status ? formData.status === '2' ? '0': '1' : '1',
        "is_mileage": '0',
        "pence_per_mile": formData.pence_per_mile
        
        };
        createExpenses(value);
        close();
      }
     
    }
    
  // }
  };
  const deleteDataExpenseTypes = () => {
    if(isSuccessDeleteExpenses) {
      dispatch(masterExpensesDelete(expenseId));
      closeModal();
    }
  };

   useEffect(() => {
    if(isLoadingDeleteExpenses) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
     }
    if(isSuccessDeleteExpenses) {
      // deleteDataExpenseTypes();
      toast.dismiss(toastId.current);
      toast.success("Expense type deleted successfully.", {
        render: "Expense type deleted successfully.",
             type: "success",
             autoClose: 2000,
             theme: "colored",
           isLoading: false
      });
        setOpenDelete(false);
        closeModal();
    
        getAllExpenses(page);
     
    }
    if (isErrorDeleteExpenses) {
      toast.dismiss(toastId.current);
      const toastMessage = 
       apiError.data.message
         ? apiError.data.message
         : 
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isErrorDeleteExpenses, isSuccessDeleteExpenses, isLoadingDeleteExpenses ]);
  
  const handlerDelete = (e, id, close) => {
    e.preventDefault();
    deleteExpenss(id);
    close();
  };

  return (
    <>
    <div className="heading_title px-4 flex justify-between items-center py-4">
      <h3 className=" font-semibold text-lg">Expense Types</h3>
      {expenseTypesCreatePermissions[0]?.status === true && (
        <Popup
        open={openAdd}
        onClose={() => closeModal()}
        closeOnDocumentClick={false}
        trigger={
          <button
          id="addExpenseType"
            className="btn-md btn-add"
          >
            <span className="fa fa-plus mr-2"  onClick={() => setOpenAdd((o) => !o)}/>Add Expense Type
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <button
              className="close"
              id="addExpenseTypeClose"
              onClick={() => {
                closeModal();
                close();
              }}
            >
              &times;
            </button>
            <div className="header">
              <h3>Add Expense Types</h3>
            </div>
            <div className="content">
            <form onSubmit={(e) => handlerFormSubmit(e, '', close, '')}>
              <div className="flex flex-wrap ">
                <div className=" w-full">
                  <div className="form-group mb-6 ">
                    <label className="formBlock	"> Expense name
                    <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                    </label>
                    <FormInput
                      inputType="text"
                      inputName="expense_name"
                      inputID="Name"
                      
                      handlerChange={handlerChange}
                    />
                    <div className="help-block text-red-700">{ error.nameError ? error.nameError : ''}</div>
                  </div>
                </div>
                <div className=" w-full ">
                  <div className="form-group mb-6 ">
                    <label className="formBlock	">Description
                    <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                    </label>
                    <FormTextarea  inputID="addExpenseTypeDescription" textName="description" handlerChange = {handlerChange}/>
                    <div className="help-block text-red-700">{error.descriptionError ? error.descriptionError : ''}</div>
                  </div>
                </div>

                 <div className="lg:w-1/2 pr-0 w-full">
                  <div className="form-group mb-6 ">
                    <label className="formBlock	">Status
                    <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                    </label>
                    <select className="formControl" id="addExpenseTypeStatus" value={formData.status} name = "status" onChange={(e) => handlerChange( e)}>
                    {statusData?.map((status, index) => { 
                              return  <FormSelect key={index} 
                              optionValue={status.value} 
                                optionName={status.label} />;
                            })};
                    </select>
                    <div className="help-block text-red-700">{ error.statusError ? error.statusError : ''}</div>
                  </div>
                </div> 

                <div className="lg:w w-full  ">
                  <div className="w-full mt-4">
                    <div className="form-group pt-4 pb-3 flex justify-center ">
                      <PrimaryButton
                        btnText="Save"
                        Btnclass="btn save mr-2"
                        buttonID="addExpenseTypeSave"
                        btnype="button"
                        onClick={(e) => handlerFormSubmit(e, false, close, '')}
                      />
                      <PrimaryButton
                        btnText="Cancel"
                        Btnclass="btn cancel"
                        buttonID="addExpenseCancel"
                        btntype="button"
                        onClick={() => close()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            </div>
          </div>
        )}
      </Popup>
      )}
      
    </div>
    <div className="p-4">
      <div className="overflow-x-auto w-full whitespace-nowrap">
        <table className="table-auto table  w-full">
          <thead className="thead-dark ">
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Status</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {expenseTypes && expenseTypes.map((exp, index) => (
             
            <tr key={index}>
              <td>{exp.expense_name}</td>
              <td><ViewPopup text="Description" data={exp.description} /></td>
              <td>{exp.status === "1" ? "Active" : "InActive"}</td>
              <td>{exp?.company_id !== null ? 'Custom': 'Default'}</td>
              {roleIdData === 1 ? (<td>
                <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-eye" />
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                       id="detailExpenseType" 
                        className="close"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <AllExpenses expensesDetails={exp} />
            
                    </div>
                  )}
                        </Popup>
                    {expenseTypesUpdatePermissions[0]?.status === true ? (
                <Popup
                  open={openEdit}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-pencil"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="editExpenseType"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        <h3>Edit Expense Types</h3>
                      </div>
                      <div className="content">
                      <form onSubmit={(e) => handlerFormSubmit(e, true, close, exp)}>
                  <div className="flex flex-wrap ">
                    <div className=" w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	"> Expense name</label>
                        <FormInput
                          inputType="text"
                          inputName="expense_name"
                          inputID="ExpenseName"
                          value={exp.expense_name}
                          handlerChange={handlerChange}
                        />
                        <div className="help-block" />
                      </div>
                    </div>
                    <div className=" w-full ">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Description</label>
                        <FormTextarea  inputID="editExpenseTypeDescription" textName="description" value={exp.description} handlerChange = {handlerChange}/>
                        <div className="help-block" />
                      </div>
                    </div>

                    <div className="lg:w-1/2 pr-0 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Status</label>
                        <select className="formControl" id="editExpenseTypeStatus" defaultValue={exp.status} name = "status"  selected={exp.status} onChange={(e) => handlerChange( e)}>
                       
                           
                          
                           <option value="0" selected={exp.status === '0' ? "selected" : ""} onChange={(e) => handlerChange(e)}>InActive</option>
                           <option value="1" selected={exp.status === '1'  ? "selected" : ""} onChange={(e) => handlerChange(e)}>Active</option>
                       
                        </select>
                      {/* <div onChange = {(e) => handlerChange(e)}>
                      <label className="flex text-sm items-center pb-3">  <input type="radio" className="mr-2 w-5 h-5" value="0" name="status" defaultChecked  = {(exp.status == 0) ? 'checked' : ""} onChange={(e) => handlerChange(e)} /> InActive</label>
                      <label className="flex text-sm items-center"> <input type="radio" className="mr-2 w-5 h-5" value="1" name="status"  defaultChecked  = {(exp.status == 1) ? 'checked' : ""} onChange={(e) => handlerChange(e)}/> Active</label>
                     
                    </div> */}
                        <div className="help-block" />
                      </div>
                    </div>

                    <div className="lg:w w-full  ">
                      <div className="w-full mt-4">
                        <div className="form-group pt-4 pb-3 flex justify-center ">
                          <PrimaryButton
                            btnText="Update"
                            Btnclass="btn save mr-2"
                            btnype="button"
                            buttonID="editExpenseTypeUpdate"
                            onClick={(e) => handlerFormSubmit(e, true, close, exp)}
                          />
                          <PrimaryButton
                            btnText="Cancel"
                            Btnclass="btn cancel"
                            btntype="button"
                            buttonID="editExpenseTypeCancel"
                            onClick={() => close()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                      </div>
                    </div>
                  )}
                </Popup> ) : <button className="actionBtn disabled"><i className="fa fa-pencil" /></button> }
                {expenseTypesDeletePermissions[0]?.status === true ? (      
                <Popup
                  open={openDelete}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn cancelBtn" 
                    >
                      <i className="fa fa-trash"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="deletExpenseType"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Delete</h3>
                      </div>
                      <div className="content">
                        <p>Are you sure you want to delete this?</p>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" id="deletExpenseTypeYes" onClick={(e) => handlerDelete(e, exp.id, close)}>Yes</button>
                            <button
                              type="button"
                              id="deletExpenseTypeNo" 
                              onClick={() => close()}
                              className="btn cancel "
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup> ) : <button className="actionBtn disabled"  id="deletExpenseTypeTrash" ><i  className="fa fa-trash" /></button> }
              </td>) : exp?.company_id !== null ? (
                <td>
                    {expenseTypesUpdatePermissions[0]?.status === true ? (
                <Popup
                  open={openEdit}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-pencil"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="editExpenseType"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        <h3>Edit Expense Types</h3>
                      </div>
                      <div className="content">
                      <form onSubmit={(e) => handlerFormSubmit(e, true, close, exp)}>
                  <div className="flex flex-wrap ">
                    <div className=" w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	"> Expense name</label>
                        <FormInput
                          inputType="text"
                          inputName="expense_name"
                          inputID="ExpenseName"
                          value={exp.expense_name}
                          handlerChange={handlerChange}
                        />
                        <div className="help-block" />
                      </div>
                    </div>
                    <div className=" w-full ">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Description</label>
                        <FormTextarea  inputID="editExpenseTypeDescription" textName="description" value={exp.description} handlerChange = {handlerChange}/>
                        <div className="help-block" />
                      </div>
                    </div>

                    <div className="lg:w-1/2 pr-0 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Status</label>
                        <select className="formControl" id="editExpenseTypeStatus" defaultValue={exp.status} name = "status"  selected={exp.status} onChange={(e) => handlerChange( e)}>
                       
                           
                          
                           <option value="0" selected={exp.status === '0' ? "selected" : ""} onChange={(e) => handlerChange(e)}>InActive</option>
                           <option value="1" selected={exp.status === '1'  ? "selected" : ""} onChange={(e) => handlerChange(e)}>Active</option>
                       
                        </select>
                      {/* <div onChange = {(e) => handlerChange(e)}>
                      <label className="flex text-sm items-center pb-3">  <input type="radio" className="mr-2 w-5 h-5" value="0" name="status" defaultChecked  = {(exp.status == 0) ? 'checked' : ""} onChange={(e) => handlerChange(e)} /> InActive</label>
                      <label className="flex text-sm items-center"> <input type="radio" className="mr-2 w-5 h-5" value="1" name="status"  defaultChecked  = {(exp.status == 1) ? 'checked' : ""} onChange={(e) => handlerChange(e)}/> Active</label>
                     
                    </div> */}
                        <div className="help-block" />
                      </div>
                    </div>

                    <div className="lg:w w-full  ">
                      <div className="w-full mt-4">
                        <div className="form-group pt-4 pb-3 flex justify-center ">
                          <PrimaryButton
                            btnText="Update"
                            Btnclass="btn save mr-2"
                            btnype="button"
                            buttonID="editExpenseTypeUpdate"
                            onClick={(e) => handlerFormSubmit(e, true, close, exp)}
                          />
                          <PrimaryButton
                            btnText="Cancel"
                            Btnclass="btn cancel"
                            btntype="button"
                            buttonID="editExpenseTypeCancel"
                            onClick={() => close()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                      </div>
                    </div>
                  )}
                </Popup> ) : <button className="actionBtn disabled"><i className="fa fa-pencil" /></button> }
                {expenseTypesDeletePermissions[0]?.status === true ? (      
                <Popup
                  open={openDelete}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn cancelBtn" 
                    >
                      <i className="fa fa-trash"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="deletExpenseType"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Delete</h3>
                      </div>
                      <div className="content">
                        <p>Are you sure you want to delete this?</p>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" id="deletExpenseTypeYes" onClick={(e) => handlerDelete(e, exp.id, close)}>Yes</button>
                            <button
                              type="button"
                              id="deletExpenseTypeNo" 
                              onClick={() => close()}
                              className="btn cancel "
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup> ) : <button className="actionBtn disabled"  id="deletExpenseTypeTrash" ><i  className="fa fa-trash" /></button> }
              </td>
              ) : (
                <td>
                  <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-eye" />
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                       id="detailExpenseType" 
                        className="close"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <AllExpenses expensesDetails={exp} />
            
                    </div>
                  )}
                        </Popup>
                        {expenseTypesUpdatePermissions[0]?.status === true ? (
                <Popup
                  open={openEdit}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-pencil"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="editExpenseType"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        <h3>Edit Expense Types</h3>
                      </div>
                      <div className="content">
                      <form onSubmit={(e) => handlerFormSubmit(e, true, close, exp)}>
                  <div className="flex flex-wrap ">
                    <div className="lg:w-1/2 pr-0 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Status</label>
                        <select className="formControl" id="editExpenseTypeStatus" defaultValue={exp.status} name = "status"  selected={exp.status} onChange={(e) => handlerChange( e)}>
                           <option value="0" selected={exp.status === '0' ? "selected" : ""} onChange={(e) => handlerChange(e)}>InActive</option>
                           <option value="1" selected={exp.status === '1'  ? "selected" : ""} onChange={(e) => handlerChange(e)}>Active</option>
                       
                        </select>
                        <div className="help-block" />
                      </div>
                    </div>

                    <div className="lg:w w-full  ">
                      <div className="w-full mt-4">
                        <div className="form-group pt-4 pb-3 flex justify-center ">
                          <PrimaryButton
                            btnText="Update"
                            Btnclass="btn save mr-2"
                            btnype="button"
                            buttonID="editExpenseTypeUpdate"
                            onClick={(e) => handlerFormSubmit(e, true, close, exp)}
                          />
                          <PrimaryButton
                            btnText="Cancel"
                            Btnclass="btn cancel"
                            btntype="button"
                            buttonID="editExpenseTypeCancel"
                            onClick={() => close()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                      </div>
                    </div>
                  )}
                </Popup> ) : <button className="actionBtn disabled"><i className="fa fa-pencil" /></button> }
                        
                </td>
              )}
              
            </tr>
            
            ))}
          </tbody>
        </table>
        <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
      </div>
    </div>
   
    
    <MileageExpense/>
  </>
   
  
 
   );
};


export default ExpenseTypes;

const AllExpenses = ({expensesDetails}) => {
  return (
    <>
    <div className="header">
      {" "}
      <h3> Details</h3>
    </div>
        <div className="content">
        <div className="w-full">
        <div className="mb-3">
          <h3 className="text-[14px]">
          <span className="w-36 inline-block">  Name:</span>
                <span className=" text-black font-medium pl-2">
              {expensesDetails?.expense_name}
            </span>
          </h3>
      </div>
    
      <div className="mb-3">
          <h3 className="text-[14px]">
          <span className="w-36 inline-block"> Description:</span>
                <span className=" text-black font-medium pl-2">
              {expensesDetails.description}
            </span>
          </h3>
      </div>
        </div>
        </div>
                   
   </>
  );
};

const MileageExpense = () => {
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const [ getAllMileageExpenses, {data: mileageData, isSuccess: isSuccessMileage } ]  = useGetAllMileageExpensesMutation();
  const [ createExpenses, { isSuccess: isCreateSuccess, isError, isLoading: isCreateLoading} ] = useCreateExpensesMutation();
  const [ deleteExpenss, { isSuccess: isSuccessDeleteExpenses, isError: isErrorDeleteExpenses, isLoading: isLoadingDeleteExpenses, error: apiError} ] =  useDeleteExpensesMutation();
  const [ updateExpenses, { isSuccess: isSuccessUpdateExpenses, isError: isErrorUpdateExpenses, isLoading: isLoadingUpdateExpenses, error: apiUpdateError} ] = useUpdateExpensesMutation();
  const [ openAdd, setOpenAdd ] = useState(false);
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);
  const [ open, setOpen ] = useState(false);
  const [ formData, setFormData ] = useState({
    expense_name: "",    
    description: "",
    status :"1",
    is_mileage: "1",
    pence_per_mile: '',
    companies_id:"1",
    id:""
  });
  const [ error, setError ] = useState({
    nameError:'',
    descriptionError: '',
    pencePerMileError: '',
    statusError:""
   });
   
  useEffect(() => {
    //eslint-disable-next-line no-undef
    if (isSuccessMileage) {
      if(mileageData?.data?.length < 1 && mileageData?.currentPage > 1 ) {
        getAllMileageExpenses(mileageData?.currentPage - 1);
      }
      setPages(mileageData?.totalPages);
    } 
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessMileage ]);

  const decrementPage = () => {
    getAllMileageExpenses(mileageData?.currentPage - 1 );
    setPage(mileageData?.currentPage - 1);
  };

  useEffect(() => {
    getAllMileageExpenses(page);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);
 
  const toastId = useRef(null);

  const statusData = [
  
    {value :"1", label: "Active"},
    {value :"2", label: "Inactive"}
   
  ];
  
  const closeModal = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setOpenDelete(false);
   setFormData({
      expense_name: "",    
      description: "",
      status :"",
      is_mileage: "1",
      pence_per_mile: '',
      companies_id:"1",
      id:""
    });
  };
 
 
 
  const handlerChange = (e) => {
    e.preventDefault();
    const {name} = e.target;
    if(name === 'expense_name') {
      if(name === '') {
      setError({...error, nameError: "please enter the name " });
      } else {
        setError({...error, nameError: " " });
      }
    }
    if(name === 'description') {
      if(name === '') {
      setError({...error, descriptionError: "please enter the description " });
      } else {
        setError({...error, descriptionError: " " });
      }
    }
    if(name === 'pence_per_mile') {
      if(name === '') {
      setError({...error, pencePerMileError: "please enter the pence per mile " });
      } else {
        setError({...error, pencePerMileError: " " });
      }
    }
    // if(name === 'status') {
    //   if(name === '0') {
    //   setError({...error, statusError: "please select status " });
    //   } else {
    //     setError({...error, statusError: " " });
    //   }
    // }
     setFormData({...formData, [name]:e.target.value});
  };

  useEffect(() => {
    if(isLoadingUpdateExpenses) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
  }
  if(isSuccessUpdateExpenses) {
    // getupdatedExpenses();
    setOpenEdit(false);
    setOpenDelete(false);
    toast.update(
      toastId.current,
      ("Mileage type updated successfully!",
      {
        render: "Mileage type updated successfully!",
        type: "success",
        autoClose: 2000,
        isLoading: false
      })
    );
    getAllMileageExpenses();
  }
 
  if (isErrorUpdateExpenses) {
    const toastMessage = 
    apiUpdateError.data.message
      ? apiUpdateError.data.message
      : 
      "Something went wrong";
    toast.update(
      toastId.current,
      (toastMessage,
      {
        render: toastMessage,
        type: "error",
        autoClose: 5000,
        isLoading: false
      })
    );
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessUpdateExpenses, isErrorUpdateExpenses, isLoadingUpdateExpenses ]);

  useEffect(() => {
    if(isCreateLoading){
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    setOpenAdd(false);
    if(isCreateSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Mileage type created successfully!", {
        render: "Mileage type created successfully!",
             type: "success",
             autoClose: 2000,
             theme: "colored",
           isLoading: false
      });
      setOpenAdd(false);
      getAllMileageExpenses(page);
  }
  if (isError) {
    const toastMessage = 
       "Something went wrong";
    toast.update(
      toastId.current,
      (toastMessage,
      {
        render: toastMessage,
        type: "error",
        autoClose: 5000,
        isLoading: false
      })
    );
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[  isCreateLoading, isCreateSuccess, isError ]);

  const validation = () => {
    const {expense_name, description, pence_per_mile, status} = formData;
    let isFormValidated = true;
    if(expense_name === '') {
      setError((errorValue) => { 
        return {
          ...errorValue,
          nameError: 'Please enter mileage name'
        };
      });
        isFormValidated = false;
  } 
  if(description === '') {
    setError((errorValue) => { 
      return {
        ...errorValue,
        descriptionError: 'Please enter description name'
      };
    });
    isFormValidated= false;
  }
  if(pence_per_mile === '') {
    setError((errorValue) => { 
      return {
        ...errorValue,
        pencePerMileError: 'Please enter pence permile'
      };
    });
    isFormValidated= false;
  }
  
  return isFormValidated;
  };

  const handlerFormSubmit = (e, edit=false, close, mile=false) => {
    e.preventDefault();
    
      if(edit) {
        let value = {
          "expense_name" : formData.expense_name ? formData.expense_name : mile.expense_name,    
          "description" : formData.description ? formData.description : mile.description,
          "status" : formData.status ? formData.status : mile.status,
          "is_mileage": '1',
          "pence_per_mile": formData.pence_per_mile ? formData.pence_per_mile : mile.pence_per_mile,
          "companies_id":"1",
          "id":mile.id
          };
          updateExpenses(value);
          close();
      } else {
        if(validation()) {
        let value = {
          "expense_name" : formData.expense_name,    
          "description" : formData.description,
          "status" : formData.status ? formData.status === '2' ? '0': '1' : '1',
          "is_mileage": '1',
          "pence_per_mile": formData.pence_per_mile,
          "companies_id":"1"
          };
          createExpenses(value);
          close();
      }
    }
     
  
  };

  useEffect(() => {
    if(isLoadingDeleteExpenses) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
     }
    if(isSuccessDeleteExpenses) {
      toast.dismiss(toastId.current);
      toast.success("Mileage type deleted successfully!", {
        render: "Mileage type deleted successfully!",
             type: "success",
             autoClose: 2000,
             theme: "colored",
           isLoading: false
      });
        setOpenDelete(false);
        closeModal();
        closeModal();
      
      getAllMileageExpenses(page);
    }
    if (isErrorDeleteExpenses) {
      toast.dismiss(toastId.current);
      const toastMessage = 
       apiError.data.message
         ? apiError.data.message
         : 
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isErrorDeleteExpenses, isSuccessDeleteExpenses, isLoadingDeleteExpenses ]);
  

  const handlerDelete = (e, id, close) => {
    e.preventDefault();
    deleteExpenss(id);
    close();
  };
  
  const handlerClick = () => {
    setOpenAdd(false);
  };
 
  return(
    <>
    <div className="heading_title px-7 flex justify-between border-t items-center py-4">
    <h3 className=" font-semibold text-lg"> Mileage Type</h3>
    
    <Popup
      open={openAdd}
      onClose={() => closeModal()}
      closeOnDocumentClick={false}
      trigger={
        <button
          className="btn-md btn-add pull-right"
          // onClick={() => setOpenAdd((o) => !o)}
        >
          <span className="fa fa-plus mr-2"/>Add Mileage
        </button>
       
      }
      modal
      nested
    >
      {(close) => (
        <div className="modal">
          <button
            className="close"
            onClick={() => {
              closeModal();
              close();
            }}
          >
            &times;
          </button>
          <div className="header">
            <h3>Add Mileage Type</h3>
          </div>
          <div className="content">
          <form >
                <div className="flex flex-wrap ">
                  <div className="w-full lg:w-1/2 md:pr-6">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">
                        Mileage Name
                        <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <FormInput
                        inputType="text"
                        inputName="expense_name"
                        inputID="ExpenseName"
                        handlerChange={handlerChange}
                      />
                      <div className="help-block text-red-700">{error.nameError? error.nameError : ''} </div>
                    </div>
                  </div>
                  <div className=" w-full lg:w-1/2 lg:pr-0">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	"> Pence per mile
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <FormInput
                        inputType="number"
                        inputName="pence_per_mile"
                        inputID="mile"
                        handlerChange={handlerChange}
                      />
                      <div className="help-block text-red-700">{error.pencePerMileError? error.pencePerMileError : ''}</div>
                    </div>
                  </div>

                  <div className="  w-full">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">Description
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <FormTextarea  textName="description" handlerChange = {handlerChange} />
                      <div className="help-block text-red-700">{error.descriptionError? error.descriptionError : ''}</div>
                    </div>
                  </div>
                  <div className="lg:w-1/2 pr-0 w-full">
                     <div className="form-group mb-6 ">
                      <label className="formBlock">Status
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <select className="formControl" name="status" value={formData.status} onChange={(e) => handlerChange(e)}>
                      {statusData?.map((status, index) => { 
                              return  <FormSelect key={index} 
                              optionValue={status.value} 
                                optionName={status.label} />;
                            })};
                      </select>
                      <div className="help-block text-red-700"/>
                    </div> 
                   </div>
                  <div className="lg:w w-full  ">
                    <div className="w-full mt-4">
                      <div className="form-group pt-4 pb-3 flex justify-center ">
                        <button className="btn save mr-2"
                          onClick={(e) => handlerFormSubmit(e, false, close, '')}
                        >Save</button>
                        <button
                          type="button"
                          onClick={() => close()}
                          className="btn cancel "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form> 
          </div>
        </div> 
      )} 
     </Popup> 
   </div> 
  <div className="p-4">
    <div className="overflow-x-auto w-full whitespace-nowrap">
      <table className="table-auto table  w-full">
        <thead className="thead-dark ">
          <tr>
            <th>Name</th>
            <th>Pence<br />per mile</th>
            <th>Description</th>
            <th>Status</th>
            <th>Type</th>
            <th>Action</th>
          
          </tr>
        </thead>
        <tbody>
        {mileageData ?
        <>
        {mileageData && mileageData.data.map((mile, index) => (
        
         <tr key={index}>
            <td>{mile?.expense_name}</td>
            <td>{mile?.pence_per_mile}</td>
            <td><ViewPopup text="Description" data={mile?.description} /> </td>
            <td>
            {mile?.status === '1' ? "Active" : "InActive"}
            </td>
            <td> {mile?.company_id !== null? 'Custom' : 'Default' }</td>
            {roleIdData === 1 ? (<td>
              <Popup
                open={openEdit}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpenEdit((o) => !o)}
                    className="actionBtn"
                  >
                    <i className="fa fa-pencil" />
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <div className="header">
                      <h3>Edit Mileage Type</h3>
                    </div>
                    <div className="content">
                    <form onSubmit={(e) => handlerFormSubmit(e)}>
                <div className="flex flex-wrap ">
                  <div className="w-full lg:w-1/2 md:pr-6">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">
                        Mileage Name
                      </label>
                      <FormInput
                        inputType="text"
                        inputName="expense_name"
                        inputID="ExpenseName"
                        value={mile.expense_name}
                        handlerChange={handlerChange}
                      />
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className=" w-full lg:w-1/2 lg:pr-0">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	"> Pence per mile</label>
                      <FormInput
                        inputType="text"
                        inputName="pence_per_mile"
                        inputID="mile"
                        value={mile.pence_per_mile}
                        handlerChange={handlerChange}
                      />
                      <div className="help-block" />
                    </div>
                  </div>

                  <div className="  w-full">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">Description</label>
                      <FormTextarea  textName="description" value={mile.description}  handlerChange = {handlerChange}  />
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className="lg:w-1/2 pr-0 w-full">
                    <div className="form-group mb-6 ">
                    <label className="formBlock">Status</label>
                  <select className="formControl" defaultValue={mile.status} name = "status" selected={mile.status} onChange={(e) => handlerChange( e)}>
                  <option>Select</option>
                            <option value="0" selected={mile.status === 0 ? "selected" : ""} onChange={(e) => handlerChange(e)}>InActive</option>
                            <option value="1" selected={mile.status === 1  ? "selected" : ""} onChange={(e) => handlerChange(e)}>Active</option>
                    </select>
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className="lg:w w-full  ">
                    <div className="w-full mt-4">
                      <div className="form-group pt-4 pb-3 flex justify-center ">
                        <button className="btn save mr-2"
                          onClick={(e) => handlerFormSubmit(e, true, close, mile)}
                        >Update</button>
                        <button
                          type="button"
                          onClick={() => close()}
                          className="btn cancel "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
                    </div>
                  </div>
                )}
              </Popup>
              <Popup
                open={openDelete}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpenDelete((o) => !o)}
                    className="actionBtn cancelBtn"
                  >
                    <i className="fa fa-trash" />
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <div className="header">
                      {" "}
                      <h3>Delete</h3>
                    </div>
                    <div className="content">
                      <p>Are you sure you want to delete this?</p>
                      <div className="w-full mt-8">
                        <div className="form-group pt-4 pb-3 flex justify-center ">
                          <button className="btn save mr-2" onClick={(e) => handlerDelete(e, mile.id, close)}>Yes</button>
                          <button
                            type="button"
                             onClick={() => close()}
                            className="btn cancel "
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>

            </td>) : mile?.company_id !== null ? (
            <td>
              <Popup
                open={openEdit}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpenEdit((o) => !o)}
                    className="actionBtn"
                  >
                    <i className="fa fa-pencil" />
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <div className="header">
                      <h3>Edit Mileage Type</h3>
                    </div>
                    <div className="content">
                    <form onSubmit={(e) => handlerFormSubmit(e)}>
                <div className="flex flex-wrap ">
                  <div className="w-full lg:w-1/2 md:pr-6">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">
                        Mileage Name
                      </label>
                      <FormInput
                        inputType="text"
                        inputName="expense_name"
                        inputID="ExpenseName"
                        value={mile.expense_name}
                        handlerChange={handlerChange}
                      />
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className=" w-full lg:w-1/2 lg:pr-0">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	"> Pence per mile</label>
                      <FormInput
                        inputType="text"
                        inputName="pence_per_mile"
                        inputID="mile"
                        value={mile.pence_per_mile}
                        handlerChange={handlerChange}
                      />
                      <div className="help-block" />
                    </div>
                  </div>

                  <div className="  w-full">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">Description</label>
                      <FormTextarea  textName="description" value={mile.description}  handlerChange = {handlerChange}  />
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className="lg:w-1/2 pr-0 w-full">
                    <div className="form-group mb-6 ">
                    <label className="formBlock">Status</label>
                  <select className="formControl" defaultValue={mile.status} name = "status" selected={mile.status} onChange={(e) => handlerChange( e)}>
                  <option>Select</option>
                            <option value="0" selected={mile.status === 0 ? "selected" : ""} onChange={(e) => handlerChange(e)}>InActive</option>
                            <option value="1" selected={mile.status === 1  ? "selected" : ""} onChange={(e) => handlerChange(e)}>Active</option>
                    </select>
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className="lg:w w-full  ">
                    <div className="w-full mt-4">
                      <div className="form-group pt-4 pb-3 flex justify-center ">
                        <button className="btn save mr-2"
                          onClick={(e) => handlerFormSubmit(e, true, close, mile)}
                        >Update</button>
                        <button
                          type="button"
                          onClick={() => close()}
                          className="btn cancel "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
                    </div>
                  </div>
                )}
              </Popup>
              <Popup
                open={openDelete}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpenDelete((o) => !o)}
                    className="actionBtn cancelBtn"
                  >
                    <i className="fa fa-trash" />
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <div className="header">
                      {" "}
                      <h3>Delete</h3>
                    </div>
                    <div className="content">
                      <p>Are you sure you want to delete this?</p>
                      <div className="w-full mt-8">
                        <div className="form-group pt-4 pb-3 flex justify-center ">
                          <button className="btn save mr-2" onClick={(e) => handlerDelete(e, mile.id, close)}>Yes</button>
                          <button
                            type="button"
                             onClick={() => close()}
                            className="btn cancel "
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            </td> ) :(
              <td>
                   <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-eye" />
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                       id="detailExpenseType" 
                        className="close"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <AllExpenses expensesDetails={mile} />
            
                    </div>
                  )}
                        </Popup>
                 <Popup
                open={openEdit}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpenEdit((o) => !o)}
                    className="actionBtn"
                  >
                    <i className="fa fa-pencil" />
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <div className="header">
                      <h3>Edit Mileage Type</h3>
                    </div>
                    <div className="content">
                    <form onSubmit={(e) => handlerFormSubmit(e)}>
                <div className="flex flex-wrap ">
                  <div className="lg:w-1/2 pr-0 w-full">
                    <div className="form-group mb-6 ">
                    <label className="formBlock">Status</label>
                  <select className="formControl" defaultValue={mile.status} name = "status" selected={mile.status} onChange={(e) => handlerChange( e)}>
                  <option>Select</option>
                            <option value="0" selected={mile.status === 0 ? "selected" : ""} onChange={(e) => handlerChange(e)}>InActive</option>
                            <option value="1" selected={mile.status === 1  ? "selected" : ""} onChange={(e) => handlerChange(e)}>Active</option>
                    </select>
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className="lg:w w-full  ">
                    <div className="w-full mt-4">
                      <div className="form-group pt-4 pb-3 flex justify-center ">
                        <button className="btn save mr-2"
                          onClick={(e) => handlerFormSubmit(e, true, close, mile)}
                        >Update</button>
                        <button
                          type="button"
                          onClick={() => close()}
                          className="btn cancel "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
                    </div>
                  </div>
                )}
              </Popup>
              </td>
            )}
          </tr>
         
        ))}
        </> :
        <center className="text-red-700 ml-[235%] mt-4">No Data Found</center>}
        </tbody>
      </table>
      <Pagination page={page} pages={pages} changePage={setPage}  decrementPage={decrementPage}/>
    </div>  
  </div>   
  </>
  );
};
