/* eslint-disable camelcase */
/* eslint-disable no-debugger*/
import { useState, useEffect, useRef } from 'react';
import { useGetDepartmentListMutation } from '../../services/approverListApi';
import { useUpdateEmployeeMutation } from '../../services/employeeApi';
import { useDispatch, useSelector } from 'react-redux';
import { departmentListAction } from '../../redux/DepartmentSlice/departmentSlice';

const Dropdown = ({ selected, setSelected, getEmployee, setDepartmentId, page }) => {
  const [ isActive, setIsActive ] = useState(false);
  const dispatch = useDispatch();
  const ignore = useRef(false);
  const [ favoriteDepartment, setFavoriteDepartment ] = useState(''); 
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const [ getDepartmentList, { data: departmentApiResponse, isSuccess: isSuccessDepartmentApi } ] = useGetDepartmentListMutation();
  const [ allDepartmentListData, setAllDepartmentListData ] = useState();
  const [ updateEmployee ] = useUpdateEmployeeMutation();
  const hidingDepartment = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.companies?.department);
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const employeeDepartmentId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.department_id);
  const shouldShowDepartment = roleIdData === 4 && hidingDepartment === "1";
  let employeeData = localStorage.getItem('employeeData') ? JSON.parse(localStorage.getItem("employeeData")) : '';

  const handleStarClick = (value) => {
    setFavoriteDepartment(value);
    let payload = {
      id: employeeData?.employee_id,
      favorite_department_id: value
    };
    updateEmployee(payload);
  };

  useEffect(() => {
    if (!ignore.current){
    getDepartmentList();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
useEffect(() => {
  if(isSuccessDepartmentApi){
    dispatch(departmentListAction(departmentApiResponse));
  }
 },
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessDepartmentApi ]);

  useEffect(() => {
    let arr = [];
    arr.push({
      value: '0',
      label: 'All Departments'
    });
    if (departmentApiData) {
      departmentApiData?.data?.length > 0 && departmentApiData?.data?.forEach((dept) => {
        let deptOption = {
          value: dept?.id,
          label: dept?.department_name
        };
        arr.push(deptOption);
      });
      setAllDepartmentListData(arr);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ departmentApiData ]);

  const handleDropdownChange = (selectedValue) => {
    if(shouldShowDepartment === true){
      setDepartmentId(employeeDepartmentId);
    }else{
      if (selectedValue === '0') {
        let payload=`/employee?page=${page}&status=${1}`;
        getEmployee(payload); 
        setDepartmentId(selectedValue);
      } else {
        let payload = `/employee?page=${1}&status=${'1'}&department_id=${selectedValue}&limit=100`;
        getEmployee(payload);
        setDepartmentId(selectedValue);
      }
    }
    
  };

  return shouldShowDepartment ? null : (
    <>
      <div className='dropDown relative'>
        <div className='dropDownBtn border p-2 flex justify-between formControl items-center' onClick={() => setIsActive(!isActive)}>
          {selected} <span className='fa fa-angle-down' />
        </div>
        {isActive && (
          <div className='dropDownContent border p-3 mt-1 rounded w-full absolute bg-white z-20' style={{ maxHeight: '350px', overflowY: 'auto' }}>
          {allDepartmentListData?.map((department) => (
              <div className='dorpdownItem flex justify-between items-center py-2 cursor-pointer' key={department?.value} onClick={() => {
                setSelected(department?.label);
                setIsActive(false);
                handleDropdownChange(department?.value);
              }}>
                {department?.label}{' '}
                {favoriteDepartment === department?.value ? (
                  <span className='fa fa-star' />
                ) : (
                  <span className='fa fa-star-o' onClick={() => handleStarClick(department?.value)} />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown;
