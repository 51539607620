import {useState} from "react";
import PrimaryButton from "../../../components/primaryButton";
import Popup from "reactjs-popup";
import moment from "moment";
import {useSelector} from "react-redux";
import {useGetEmployeeGroupLeavesQuery} from "../../../services/employeeApi";
const LeaveDetails = ({leaveDetails}) => {
  const [open, setOpen] = useState(false);
  const formattedDate = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.dateFormats
  );
  const {data} = useGetEmployeeGroupLeavesQuery(
    {id: leaveDetails.leave_group_identifier},
    {skip: leaveDetails.leave_group_identifier ? false : true}
  );
  const closeModal = () => {
    setOpen(false);
  };

  const formatDates = (startDate, endDate) => {
    if (!startDate) return "";

    if (startDate === endDate) {
      return startDate;
    }

    return `${startDate} - ${endDate}`;
  };
  const calculateTotalDuration = (data) => {
    return data?.reduce((sum, item) => sum + item?.total_duration, 0);
  };

  const handlerSave = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  return (
    <>
      <div className="header">
        <span>
          <h3>Approved Leave Details</h3>
        </span>
      </div>

      <div className="stepIndicator">
        <ul>
          <li className="active">
            <span>1</span>Request
          </li>
          <li>
            <span>2</span>Details
          </li>
        </ul>
      </div>
      <div className="content">
        <form>
          <div className="flex flex-wrap ">
            <div className=" w-full ">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Type{" "}
                  <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                </label>
                <input
                  className="formControl"
                  value={leaveDetails?.leaveType}
                />
              </div>
            </div>

            <div className=" w-full ">
              <div className="mb-2">
                <div className="flex">
                  <input
                    type="checkbox"
                    checked={leaveDetails?.isPrivate === "1"}
                    className="mr-2 w-5 h-5"
                    id="private"
                  />
                  <label className="formBlock">Private</label>
                </div>
              </div>
              <div className="w-full ">
                <div className="form-group mb-4 ">
                  <label className="formBlock ">Reason (optional) </label>
                  <input value={leaveDetails?.reason} className="formControl" />
                  <div className="help-block" />
                </div>
              </div>
              <div className="form-group mb-4 ">
                <div className="flex">
                  <div className="w-full">
                    <label className="formBlock">
                      Select Date
                      <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                    </label>
                    <input
                      className="formControl"
                      value={formatDates(
                        `${moment(leaveDetails?.startDate).format(
                          formattedDate
                        )}`,
                        `${moment(leaveDetails?.endDate)?.format(
                          formattedDate
                        )}`
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w w-full  ">
              <div className="w-full">
                <div className="form-group pt-4 pb-3 flex justify-center ">
                  <PrimaryButton
                    btnText="Next"
                    Btnclass="btn save mr-2"
                    btnype="button"
                    buttonID="next"
                    onClick={(e) => handlerSave(e)}
                  />
                  {/* <PrimaryButton
                  btnText="Back"
                  onClick={() => close()}
                  Btnclass="btn cancel"
                  buttonID="leaveCancel"
                  btntype="button"
                /> */}
                </div>
              </div>
            </div>
            <Popup
              open={open}
              onClose={() => closeModal()}
              closeOnDocumentClick={false}
              className="leavePopup"
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button
                    className="close"
                    id="requestLeaveClose"
                    onClick={() => {
                      closeModal();
                      close();
                    }}
                  >
                    &times;
                  </button>
                  <div
                    style={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      paddingBottom: "24px"
                    }}
                  >
                    <div className="stepIndicator">
                      <ul>
                        <li>
                          <span>1</span>Request
                        </li>
                        <li className="active">
                          <span>2</span>Details
                        </li>
                      </ul>
                    </div>
                    <div className="header restrictedDay p-4 ml-3">
                      {leaveDetails?.leaveRestrictedDay !== null ? (
                        <p className="restricteSpan table rounded-lg font-medium text-red-700 bg-red-100 p-4">
                          (This request falls on a restricted day)
                        </p>
                      ) : null}
                      {/* Other components or code */}
                    </div>

                    <div className="p-6">
                      <h3 className="text-lg primary-text mb-4">
                        Approved Leave Details
                      </h3>
                      <div>
                        {data?.data?.length > 0 &&
                          data?.data?.map((leaveDetails, index) => {
                            return (
                              <div key={index}>
                                <div className="w-full flex justify-between pb-4  md:flex-nowrap flex-wrap ">
                                  <div className="w-1/3">
                                    <h3 className="text-base text-left pt-1">
                                      {moment(leaveDetails?.start_date).format(
                                        formattedDate
                                      )}
                                    </h3>
                                  </div>
                                  <div className=" md:w-2/3 w-full pt-2 md:pt-0 md:pl-4  ">
                                    <div className="flex justify-between items-center w-full">
                                      <input
                                        className="formControl"
                                        value={moment(
                                          leaveDetails?.start_time,
                                          "hh:mm:ss"
                                        ).format("HH:mm")}
                                      />
                                      <div>to</div>

                                      <input
                                        className="formControl"
                                        value={moment(
                                          leaveDetails?.end_time,
                                          "hh:mm:ss"
                                        ).format("HH:mm")}
                                      />
                                    </div>
                                    <div className="w-full mt-2">
                                      <div className="timeDiv">
                                        <span className="fa fa-clock secondry-text" />
                                        <span>
                                          <span className="secondry-text">
                                            {Math.floor(
                                              leaveDetails?.total_duration / 60
                                            )}
                                          </span>{" "}
                                          Hours{" "}
                                          <span className="secondry-text pl-2 pr-1">
                                            {leaveDetails?.total_duration % 60}
                                          </span>{" "}
                                          Minutes
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="px-6 text-right pb-4">
                      <h1 className="text-lg">
                        Total:{" "}
                        {Math.floor(calculateTotalDuration(data?.data) / 60)}{" "}
                        hours {calculateTotalDuration(data?.data) % 60} minutes
                      </h1>
                      <p className="ml-10">Annual Leave</p>
                    </div>
                  </div>
                  <div className="lg:w w-full  ">
                    <div className="w-full mb-4">
                      <div className="form-group pt-4 pb-3 flex justify-center ">
                        {/* <PrimaryButton
                  btnText="Submit"
                  Btnclass="btn save mr-2"
                  btnype="button"
                  buttonID="leaveSave"
                  //onClick={(e) => handlerSubmit(e)}
                /> */}
                        <PrimaryButton
                          btnText="Back"
                          onClick={() => close()}
                          Btnclass="btn cancel"
                          buttonID="leaveCancel"
                          btntype="button"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </form>
      </div>
    </>
  );
};

export default LeaveDetails;
