/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, {useEffect, useState, useRef, useMemo} from "react";
import {FormTextarea} from "../../../components/formInput";
import PrimaryButton from "../../../components/primaryButton";
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";
import {
  useChangesBulkStatusApporveMutation,
  useChangesStatusApporveMutation,
  useEmployeeLeaveCountOndateMutation
} from "../../../services/approverListApi";
import moments from "moment";
import {toast} from "react-toastify";
import NoDataFound from "../../../helper/noDataFound";
import Pagination from "../../../components/inc/pagination";
import CancelLeavesApprover from "./CancelLeavesApprover";
import LeaveDetails from "./LeaveDetails";
import {useSelector} from "react-redux";
import {REACT_APP_AUDITOR_ROLE_ID} from "../../../helper/constant";

const LeaveApprove = ({
  allLeaveToApporve,
  getLeaveApproveList,
  employeeId,
  getLeaveApproveListRespo
}) => {
  // States are defined here
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [approvedList, setApprovedList] = useState();
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [formData, setFormData] = useState({
    status: "1",
    employee_id: "100",
    notes: ""
  });
  const toastId = useRef(null);
  const pageSize = 10;
  const closeModal = () => {
    setOpen(false);
  };

  // Api calling
  const [employeeLeaveCountOndate, {data: leaveDataOnDate}] =
    useEmployeeLeaveCountOndateMutation();
  const [changesStatusApporve, {isSuccess, isError, isLoading}] =
    useChangesStatusApporveMutation();
  const [
    changesBulkStatusApporve,
    {
      isSuccess: changesBulkStatusApporveSuccess,
      isError: changesBulkStatusApporveError,
      isLoading: changesBulkStatusApporveLoading
    }
  ] = useChangesBulkStatusApporveMutation();

  // Carrying out data from redux
  const roleIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  const formattedDate = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.dateFormats
  );

  useEffect(
    () => {
      if (selected) {
        let payload = `/leaves/count/ondate?leave_id=${selected}`;
        employeeLeaveCountOndate(payload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  useEffect(
    () => {
      if (allLeaveToApporve) {
        let totalPages = Math.ceil(allLeaveToApporve.length / pageSize);
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allLeaveToApporve?.slice(firstPageIndex, lastPageIndex);
        setApprovedList(data);
        setPages(totalPages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allLeaveToApporve]
  );

  // eslint-disable-next-line no-unused-vars
  const currentTableData = useMemo(
    () => {
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allLeaveToApporve?.slice(firstPageIndex, lastPageIndex);
      setApprovedList(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  // eslint-disable-next-line no-unused-vars
  const decrementPage = () => {
    const lastPageIndex = (page - 1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allLeaveToApporve?.slice(lastPageIndex, firstPageIndex);
    setApprovedList(data);
  };

  useEffect(
    () => {
      if (changesBulkStatusApporveLoading) {
        toastId.current = toast.loading("Please wait...", {
          // position: "top-center",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (changesBulkStatusApporveSuccess) {
        toast.dismiss(toastId.current);
        toast.update(
          toastId.current,
          ("Leaves approved successfully!",
          {
            render: "leaves approved successfully!",
            type: "success",
            autoClose: 8000,
            isLoading: false
          })
        );
        let payload = `/leaves/?leave_approver=${employeeId}&type=1&group=true&exclude_self=true`;
        getLeaveApproveList(payload);
        setSelectedLeaveId([]);
        setSelectAll(false);
        setShowActionButtons(false);
      }
      if (changesBulkStatusApporveError) {
        toast.dismiss(toastId.current);
        const toastMessage = "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 8000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      changesBulkStatusApporveError,
      changesBulkStatusApporveLoading,
      changesBulkStatusApporveSuccess
    ]
  );

  useEffect(
    () => {
      if (isLoading) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccess) {
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 10000);
        toast.update(
          toastId.current,
          ("leave approved/reject Successfully!",
          {
            render: "leave approved/reject successfully!",
            type: "success",
            autoClose: 8000,
            isLoading: false
          })
        );
        let payload = `/leaves/?leave_approver=${employeeId}&type=1&group=true&exclude_self=true`;
        getLeaveApproveList(payload);
      }
      if (isError) {
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 10000);
        const toastMessage = "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 8000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, isError, isLoading]
  );

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = approvedList.map((expense) => expense.id);
      setSelectedLeaveId(allIds);
      setShowActionButtons(true);
    } else {
      setSelectedLeaveId([]);
      setShowActionButtons(false);
    }
    setSelectAll(isChecked);
  };

  const handleSelectExpense = (e, id) => {
    if (e.target.checked) {
      setSelectedLeaveId((prev) => [...prev, id]);
      setShowActionButtons(true);
    } else {
      const updatedExpenses = selectedLeaveId.filter(
        (expenseId) => expenseId !== id
      );
      setSelectedLeaveId(updatedExpenses);
      if (updatedExpenses.length === 0) {
        setShowActionButtons(false);
      }
    }
  };

  const handlerChange = (e) => {
    e.preventDefault();
    const {name} = e.target;
    setFormData({...formData, [name]: e.target.value});
  };

  const onHandlerApporve = (e, leave, close) => {
    e.preventDefault();
    const value = {
      status: "1",
      employee_id: `${leave?.employee_id}`,
      notes: formData?.notes,
      id: leave?.id
    };
    changesStatusApporve(value);
    close();
  };

  const onHandlerBulkApporve = (e) => {
    e.preventDefault();
    const val = {
      notes: "Approved",
      status: "1",
      leaveIds: selectedLeaveId
    };
    changesBulkStatusApporve(val);
    setFormData("");
  };

  const onHandlerBulkReject = (e) => {
    e.preventDefault();
    const val = {
      notes: "Reject",
      status: "2",
      leaveIds: selectedLeaveId
    };  
    changesBulkStatusApporve(val);
    setFormData("");
  };

  return (
    <div>
      {showActionButtons && (
        <div className="action-buttons space-x-2 mb-4">
          <button
            className="btn save"
            onClick={onHandlerBulkApporve}
            disabled={selectedLeaveId.length === 0}
          >
            Approve
          </button>
          <button
            className="btn save"
            onClick={onHandlerBulkReject}
            disabled={selectedLeaveId.length === 0}
          >
            Reject
          </button>
          <button
            className="btn cancel"
            onClick={() => {
              setSelectedLeaveId([]);
              setSelectAll(false);
              setShowActionButtons(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
      <div className="overflow-x-auto w-full whitespace-nowrap">
        <table className="table-auto table  w-full">
          <thead className="thead-dark ">
            <tr>
              {approvedList?.length > 0 ? (
                <>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                  </th>
                </>
              ) : (
                ""
              )}
              <th className="w-40"> Employee</th>
              <th>Leave Type</th>
              <th>Date </th>
              <th>Required</th>
              <th>Date From</th>
              <th>Date To</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {approvedList?.length > 0 ? (
              <>
                {approvedList &&
                  approvedList?.map((leave, index) => {
                    let hour = Math.round(parseInt(leave?.days / 60));
                    //const day = hour / leave?.hoursInDays;
                    return (
                      <tr style={{backgroundColor: leave?.department_leave_limit_exceeded ? "#ffe6e6" : ''}} key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedLeaveId.includes(leave.id)}
                            onChange={(e) => handleSelectExpense(e, leave.id)}
                          />
                        </td>
                        <td>
                          <Link
                            to={`/employees/detail/${leave?.employee_id}`}
                            className="listImage flex items-center "
                          >
                            {leave?.employeeName}
                          </Link>
                        </td>
                        <td>{leave?.leaveType}</td>
                        <td>{moments(leave?.date).format(formattedDate)}</td>
                        <td>
                          <td>
                            {leave?.hours_or_day === "1"
                              ? `${hour} ${hour <= 1 ? "Hour" : "Hours"}`
                              : `${parseFloat(leave?.total_leave_days).toFixed(
                                  2
                                )} ${"days"}`}
                          </td>
                        </td>
                        <td>
                          {moments(leave?.startDate).format(formattedDate)}
                        </td>
                        <td>{moments(leave?.endDate).format(formattedDate)}</td>
                        <td>
                          {leave?.leaveRestrictedDay && (
                            <div className="actionBtn">
                              <span
                                className="font-medium text-red-500"
                                title={leave?.leaveRestrictedDay}
                              >
                                {leave.leaveRestrictedDay ? (
                                  <i className="fa fa-bolt" />
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          )}

                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <div
                                onClick={() => setOpen((o) => !o)}
                                className="actionBtn"
                              >
                                <i className="fa fa-eye" />
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="closeLeaveDetails"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <LeaveDetails
                                  leaveDetails={leave}
                                  getLeaveApproveListRespo={
                                    getLeaveApproveListRespo
                                  }
                                  selectedLeaveItemId={leave?.id}
                                />
                              </div>
                            )}
                          </Popup>

                          {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                            <>
                              {/* <Popup
                            open={messageOpen}
                            onClose={() => setMessageOpen(false)}
                            closeOnDocumentClick={false}
                            modal
                            nested
                          >
                            {(close) => (
                             
                            )}
                          </Popup> */}
                              <Popup
                                open={open}
                                onClose={() => closeModal()}
                                closeOnDocumentClick={false}
                                trigger={
                                  <div
                                    onClick={() => {
                                      setOpen((o) => !o);
                                      setSelected(leave?.id);
                                    }}
                                    className="actionBtn"
                                  >
                                    <div
                                      className="fa-solid fa-check"
                                      onClick={() => {
                                        setSelected(leave?.id);
                                      }}
                                    />
                                  </div>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div className="modal">
                                    <button
                                      className="close"
                                      id="closeLeaveApprove"
                                      onClick={() => {
                                        closeModal();
                                        close();
                                      }}
                                    >
                                      &times;
                                    </button>
                                    <div className="header">
                                      {" "}
                                      <h3>Approve</h3>
                                      <div className="restrictedDay">
                                        {leave?.leaveRestrictedDay !== null ? (
                                          <p className="restricteSpan table rounded-lg font-medium text-red-700 bg-red-100">
                                            (This is a company restricted day -
                                            please confirm you approve?)
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <>
                                      <div className="content">
                                        <form>
                                          <div className="w-full mb-6">
                                            {leave?.department_leave_limit_exceeded && (
                                              <div className="formBlock flex items-center">
                                                <h1 style={{color: "red"}}>
                                                This leave request exceeds departmental rules
                                                </h1>
                                              </div>
                                            )}

                                            <label className="formBlock flex items-center	">
                                              Employees on leave at the same
                                              period requested:{" "}
                                              <span className="font-bold text-xl secondry-text ml-2">
                                                {leaveDataOnDate?.data}
                                              </span>
                                            </label>
                                          </div>
                                          <label className="formBlock	">
                                            Notes
                                          </label>
                                          <FormTextarea
                                            textName="notes"
                                            handlerChange={handlerChange}
                                            id="notesLeaveApprove"
                                          />

                                          <div className="w-full mt-8">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <button
                                                className="btn save mr-2"
                                                id="saveLeaveApprove"
                                                onClick={(e) =>
                                                  onHandlerApporve(
                                                    e,
                                                    leave,
                                                    close
                                                  )
                                                }
                                              >
                                                Approve
                                              </button>
                                              <PrimaryButton
                                                btnText="Cancel"
                                                Btnclass="btn cancel"
                                                btntype="button"
                                                buttonID="cancelLeaveApprove"
                                                onClick={() => close()}
                                              />
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </>
                                  </div>
                                )}
                              </Popup>
                            </>
                          )}
                          {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn cancelBtn"
                                >
                                  <i className="fa-solid fa-xmark" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="closeCancelLeaveApprove"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <CancelLeavesApprover
                                    leave={leave}
                                    getLeaveApproveList={getLeaveApproveList}
                                    employeeId={employeeId}
                                    close={close}
                                  />
                                </div>
                              )}
                            </Popup>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </>
            ) : (
              <center className="mt-4">{<NoDataFound />}</center>
            )}
          </tbody>
        </table>
        <Pagination
          page={page}
          pages={pages}
          changePage={setPage}
          decrementPage={setPage}
        />
      </div>
    </div>
  );
};

export default LeaveApprove;
