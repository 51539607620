// eslint-disable-next-line no-unused-vars
/* eslint-disable no-debugger*/
import { useState } from 'react';
import {Link} from 'react-router-dom';
import { ModifiedToast } from '../components/toaster';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {FormInput} from '../components/formInput';
import PrimaryButton from '../components/primaryButton';
import { useChangePasswordMutation } from '../services/authApi';
import { toast } from "react-toastify";
// import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const ResetPasswordAtFirstLogin = () => { 

    const navigate = useNavigate();

    const [ changePassword ] = useChangePasswordMutation();
    //const email = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.email);
    //const token = localStorage.getItem("accessToken");
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const [ formData, setFormData ] = useState({ email: '', token: '' });  

    const [ passwords, setPasswords ] = useState({
        oldPassword:"",
        newPassword : ""
    });
    // useEffect(() => {
    //     const tokenFromURL = searchParams.get('token');

    //     if (email) {
    //     setFormData({ email: email, token: tokenFromURL });
    //     }
    // }, [ email ]);

    const handlerChangeSubmit = (e) => {  
        e.preventDefault();
        const { name, value } = e.target;
        setPasswords((prevPasswords) => ({
          ...prevPasswords,
          [name]: value
        }));
    };
    const handleFormSubmit = (e) => {    
        e.preventDefault();
        let value = {
            oldPassword: passwords?.oldPassword,
            newPassword: passwords?.newPassword
        };
        changePassword(value);   
        navigate('/login');
        toast.success('Password reset successfully.');
    };

    return (
        <div className="h-screen flex bg-gray-bg1 w-full">
            <HelmetProvider>
                <Helmet>
                    <title>Schedulize | Login </title>
                </Helmet>
            </HelmetProvider>           
            <ModifiedToast/>
            <div className=" flex align-center h-screen w-full">
                <div className=" w-full bg-white  ">
                    <div
                        className="md:flex align-center justify-between h-full items-center flex-wrap md:flex-nowrap 	">
                        <div className=" md:p-8 p-2 md:w-1/2 w-full relative mx-auto">
                            <div className=" pt-0">
                                <div
                                    className="w-full max-w-lg m-auto bg-white p-8 border shadow-lg rounded-lg ">
                                     <div className="text-center  w-full 	">
                                        <img
                                            src="../images/logo-full.webp"
                                            width="220"
                                            className="mx-auto"
                                        />
                                    </div>
                                    <h1 className="text-xl font-semibold my-6 mb-8 text-center">
                                    Reset password?

                                    </h1>
                                    <div
                                        
                                        className="text-left"
                                        id="loginForm"
                                    >
                                        <div className="parentGroup mb-5">
                                            <label
                                                htmlFor="password"
                                                className="formBlock"
                                            >
                                            {""} 
                                            Old Password
                                            </label>
                                          <FormInput inputType="password" inputName="oldPassword" inputID="password" handlerChange={(e) => handlerChangeSubmit(e)}
                                          />
                                            <div className="help-block" />
                                        </div>
                                        {/* <div className="help-block text-red-700">{error && <p>Please enter a new password</p>}</div> */}

                                        <div className="parentGroup mb-5">
                                            <label
                                                htmlFor="password"
                                                className="formBlock"
                                            >
                                            {""} 
                                            New Password
                                            </label>
                                          <FormInput inputType="password" inputName="newPassword" inputID="password" handlerChange={(e) => handlerChangeSubmit(e)}
                                          />
                                            <div className="help-block" />
                                        </div>
                                        {/* <div className="help-block text-red-700">{error && <p>Please enter a Confirm password</p>}</div> */}
                                        
                                        <div className=" mt-8 text-center">
                                        <PrimaryButton btnText="Submit " buttonID="resetPasswordSubmit" onClick={handleFormSubmit} Btnclass="btn save w-full  mb-6"/>                                         
                                        <p className='text-gray-600 font-medium'><Link to="/login" className='primary-text font-semibold'>Login</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                     
                    </div>
                </div>
            </div>
            <ModifiedToast/>
        </div>
    );
};

export default ResetPasswordAtFirstLogin;