/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger */
/* eslint-disable */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import { useState,  useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Popup from "reactjs-popup";
import "react-datepicker/dist/react-datepicker.css";
import { FormInput } from "../../../components/formInput";
import { FormSelect } from "../../../components/formInput";
import { useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import Pagination from "../../../components/inc/pagination";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import NoDataFound from "../../../helper/noDataFound";
import PrimaryButton from "../../../components/primaryButton";
import {
  useGetSuperAdminCompanyListMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyDetailMutation,
  useGetSuperAdminEmployeeListMutation,
  useChangePasswordImmediatelyMutation,
} from "../../../services/superAdminApi";
import { useAddressDetailsMutation } from "../../../services/addressAutofillApi";
import { toast } from "react-toastify";
import { useCompanyBillingDetailsMutation, useExtendFreeTrialOfCompanyMutation } from "../../../services/companyApi";
import { useDispatch, useSelector } from "react-redux";
import { getSuperAdminCompanyListAction } from "../../../redux/CompanyInformation/companySlice";
import SearchLocation from "../../Employees/addressComponent";

const CompanyList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // useSelector
  const companyListData = useSelector((state) => state?.persistedReducer?.companySlice?.getSuperAdminCompanyListData); 
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
 // 
  const [ extendedtrialDaysOfCompany, { isSuccess:extendedtrialDaysOfCompanySuccess, isLoading:extendedtrialDaysOfCompanyLoading, isError:extendedtrialDaysOfCompanyError, error:extendedTrialError } ] = useExtendFreeTrialOfCompanyMutation();
 
  
  const { status } = location.state || "";
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageTerm = searchParams.get("page") || "";
  const [page, setPage] = useState(pageTerm ? pageTerm : 1);
  const [pages, setPages] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [startDate, endDate] = dateRange;
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState();
  const toastId = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    MobileNumber: "",
    Website: "",
    streetAddress: "",
    address: "",
    address2: "",
    city: "",
    postcode: "",
    state: "",
    country: "",
    company_logo: "",
    payment_processor_email: "",
  });
  const [loading, setLoading] = useState(true);
  const [mobileError, setMobileError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [postalError, setPostalError] = useState("");
  const imageLoaded = () => {
    setLoading(false);
  };

  const [addressDetails] = useAddressDetailsMutation();
  const [companyBillingDetails, { data: companyBillingDetailsData }] =
    useCompanyBillingDetailsMutation();
  const [getsuperAdminEmployeeList, { data: superAdminEmployeeList }] =
    useGetSuperAdminEmployeeListMutation();
  const getaddressDetails = useSelector((state) => state.addressAutofillState);
  const { addressAutofill } = getaddressDetails;
  const [getSuperAdminCompanyList, { data: companyListDataRes, isSuccess }] =
    useGetSuperAdminCompanyListMutation({
      page: page,
      name: searchInput,
      start_date: startDate,
      end_date: endDate,
      status: status ? status : "",
    });
  const [
    updateCompanyDetail,
    {
      isSuccess: companyUpdateSuccess,
      isError: isErrorUpdateCompany,
      isLoading: isLoadingUpdateCompany,
    },
  ] = useUpdateCompanyDetailMutation();
  const [
    deleteCompany,
    {
      isSuccess: companyDeleteSuccess,
      isError: isErrorDeleteCompany,
      isLoading: isLoadingDeleteCompany,
    },
  ] = useDeleteCompanyMutation();
  const [
    changePasswordImmediately,
    {
      isLoading: changePasswordImmediatelyLoading,
      isSuccess: changePasswordImmediatelySuccess,
      isError: changePasswordImmediatelyError,
    },
  ] = useChangePasswordImmediatelyMutation();
  const [selectedRole, setSelectedRole] = useState(2);
  const [companyId, setCompanyId] = useState();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("0");
  const [passwordMismatchError, setPasswordMismatchError] = useState("");
  useEffect(() => {
    getSuperAdminCompanyList({
      page: page,
      name: searchInput,
      start_date: startDate,
      end_date: endDate,
      status: status ? status : "",
    });
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[page]);
 useEffect(()  => {
  dispatch(getSuperAdminCompanyListAction(companyListDataRes))
 }, [ isSuccess ])
  useEffect(() => {
    if (companyListData) {
      if (
        companyListData?.rows?.length < 1 &&
        companyListData?.currentPage > 1
      ) {
        getSuperAdminCompanyList({
          page: page,
          name: searchInput,
          start_date: startDate,
          end_date: endDate,
          status: status ? status : "",
        });
      }
      setPages(companyListData?.totalPages);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ companyListData ]);

  const closeModal = () => {
    setOpen(false);
  };

  const onSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    getSuperAdminCompanyList({
      page: page,
      name: searchInput,
      start_date: startDate,
      end_date: endDate,
      status: status ? status : "",
    });
  };
  useEffect(() => {
    if(selectedRole && companyId){
    const value = `/admin/employees/by-role-and-company?page=1&limit=200&company_id=${companyId}&role_type_id=${selectedRole}`;
    getsuperAdminEmployeeList(value);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[selectedRole, companyId]);

  /** pagination functions */
  const decrementPage = () => {
    getSuperAdminCompanyList({
      page: page,
      name: searchInput,
      start_date: startDate,
      end_date: endDate,
      status: status ? status : "",
    });
    setPage(companyListData?.currentPage - 1);
  };

  useEffect(() => {
    if (companyListData) {
      if (
        companyListData?.data?.length < 1 &&
        companyListData?.currentPage > 1
      ) {
        getSuperAdminCompanyList({
          page: page,
          name: searchInput,
          start_date: startDate,
          end_date: endDate,
          status: status ? status : "",
        });
      }
      setPages(companyListData?.totalPages ? companyListData?.totalPages : 1);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[companyListData]);
  /** end of pagination function */

  const handlerChange = (event) => {
    const { name, value } = event.target;
    if (name === "MobileNumber") {
      if (value.length !== 10) {
        setMobileError("Mobile number must be 10 digits");
      } else {
        setMobileError("");
      }
    }
    if (name === "country") {
      if (/\d/.test(value)) {
        setCountryError("Country should not contain numbers");
      } else if (/[\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/.test(value)) {
        setCountryError(
          "Country should not contain numbers or special characters"
        );
      } else {
        setCountryError("");
      }
    }
    if (name === "city") {
      if (/[^a-zA-Z0-9\s]/.test(value)) {
        setCityError("City/Town should not contain special characters");
      } else {
        setCityError("");
      }
    }
    if (name === 'postcode') {
      if (
        !(/^\d{6}$/.test(value) || /^[A-Za-z]{1,2}\d{1,2} \d[A-Za-z]{2}$/.test(value))
      ) {
        setPostalError('Invalid postal code format');
      } else {
        setPostalError('');
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);
    if (confirmPassword && newPasswordValue !== confirmPassword) {
      setPasswordMismatchError("Passwords do not match");
    } else {
      setPasswordMismatchError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    if (newPassword !== confirmPasswordValue) {
      setPasswordMismatchError("Passwords do not match");
    } else {
      setPasswordMismatchError("");
    }
  };

  const handleChangeAddress = (e) => {
    e.preventDefault();
      setFormData({ ...formData, ["address"]: e.target.value });
  };

  const openCreditCardPopup = (e, company) => {
    e.preventDefault();
    companyBillingDetails(company?.id);
  };

  const handlerSave = (e, company) => {
    e.preventDefault();
    setTimeout(() => {
      let formdata = new FormData();
      formdata.append("id", company.id);
      formdata.append("name", formData.name ? formData.name : company.name);
      formdata.append("email", formData.email ? formData.email : company.email);
      formdata.append(
        "tel_no",
        formData.MobileNumber ? formData.MobileNumber : company.tel_no
      );
      formdata.append(
        "website",
        formData.Website ? formData.Website : company.website
      );
      formdata.append(
        "address",
        formData.address ? formData.address : company.address
      );
      formdata.append(
        "address2",
        formData.address2 ? formData.address2 : company.address2
      );
      formdata.append("city", formData.city ? formData.city : company.city);
      formData.postcode
        ? formdata.append("postal_code", formData.postcode)
        : "";
      formdata.append("state", formData.state ? formData.state : company.state);
      formdata.append(
        "country",
        formData.country ? formData.country : company.country
      );
      if (file) {
        formdata.append("company_logo", file);
      }
      const paymentProcessorEmailToAdd = formData.payment_processor_email
        ? formData.payment_processor_email
        : company.payment_processor_email !== null
        ? company.payment_processor_email
        : "";
      formdata.append("payment_processor_email", paymentProcessorEmailToAdd);
      updateCompanyDetail(formdata);
    }, 100);
  };
  const [websiteError, setwebsiteError] = useState({
    website: "",
  });
  const handlerWebsiteChange = (e) => {
    const { name, value } = e.target;
    const isValidWebsite = value.match(/^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/\S*)?$/);

    setFormData({
      ...formData,
      [name]: value,
    });

    setwebsiteError({
      ...websiteError,
      website: isValidWebsite
        ? ""
        : "Invalid website format.",
    });
  };

  const handledeleteCompany = (id) => {
    deleteCompany(id);
  };

  const handleUpload = async (event) => {
    if (event.target.files[0]) {
      var fileSize = Math.round(event.target.files[0].size / 1024);

      if (fileSize <= 5 * 1024) {
        setFile(event.target.files[0]);
        setPreview(URL.createObjectURL(event.target.files[0]));
      } else {
        alert("Please select a file size less than 5kb.");
      }
    }
  };

  useEffect(() => {
    if (changePasswordImmediatelyLoading) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info",
      });
    }
    if (changePasswordImmediatelySuccess) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.success("Password changed successfully.", {
        render: "Password changed successfully..",
        type: "success",
        autoClose: 2000,
        isLoading: false,
        theme: "colored",
      });
      document?.getElementById("openOtpModel").click();
    }
    if (changePasswordImmediatelyError) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      const toastMessage = "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 2000,
        duplicate: false,
        isLoading: false,
      });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[
    changePasswordImmediatelySuccess,
    changePasswordImmediatelyError,
    changePasswordImmediatelyLoading,
  ]);

  useEffect(() => {
    if (companyUpdateSuccess) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.success("Company updated successfully.", {
        render: "Company updated successfully.",
        type: "success",
        autoClose: 2000,
        isLoadingUpdateCompany: false,
        theme: "colored",
      });
      close();
    }

    if (isErrorUpdateCompany) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.success("Company could not be update.", {
        render: "Company could not be update.",
        type: "error",
        autoClose: 2000,
        isLoadingUpdateCompany: false,
        theme: "colored",
      });
      close();
    }

    if (companyDeleteSuccess) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.success("Company deleted successfully.", {
        render: "Company deleted successfully.",
        type: "success",
        autoClose: 2000,
        isLoadingDeleteCompany: false,
        theme: "colored",
      });
      close();
    }

    if (isErrorDeleteCompany) {
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
      toast.success("Company could not be delete.", {
        render: "Company could not be delete.",
        type: "error",
        autoClose: 2000,
        isLoadingDeleteCompany: false,
        theme: "colored",
      });
      close();
    }
    getSuperAdminCompanyList({
      page: page,
      name: searchInput,
      start_date: startDate,
      end_date: endDate,
      status: status ? status : "",
    });
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[
    companyUpdateSuccess,
    isErrorUpdateCompany,
    isErrorDeleteCompany,
    companyDeleteSuccess,
  ]);

  const handleSelectChange = (event, id) => {
    setSelectedRole(event.target.value);
    setCompanyId(id);
  };

  const submitChangePassword = (e, id) => {
    e.preventDefault();
    const payload = {
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      company_id: id,
      employee_id: selectedEmployeeId,
    };
    changePasswordImmediately(payload);
  };
  const [ extendedtrialDays, setExtendentrialDays ] = useState(null);
  const handleChangeDaysOfExtendedTrial = (e) => {
    setExtendentrialDays(e.target.value);
  };

  const handleExtendFreeTrialOfCompany = (e, id) => {
    e.preventDefault();
    const value = {
      days: parseInt(extendedtrialDays),
      company_id: id
    }
    extendedtrialDaysOfCompany(value);
  };

  useEffect(() => {
    if (extendedtrialDaysOfCompanyLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (extendedtrialDaysOfCompanySuccess) {
      toast.dismiss(toastId.current);
      toast.success("Free trial extended successfully.", {
        render: "Free trial extended successfully..",
             type: "success",
             autoClose: 2000,
           isLoading: false,
           theme: "colored"
      });
      getSuperAdminCompanyList({
        page: page,
        name: searchInput,
        start_date: startDate,
        end_date: endDate,
        status: status ? status : "",
      });
    }
    if (extendedtrialDaysOfCompanyError) {
      toast.dismiss(toastId.current);
      const toastMessage = extendedTrialError?.data?.message
        ? extendedTrialError?.data?.message
        : "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ extendedtrialDaysOfCompanySuccess, extendedtrialDaysOfCompanyLoading, extendedtrialDaysOfCompanyError ]);

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Company</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Company</h2>
              <div className="card bg-white  mb-3">
                <div className="heading_title px-4 flex justify-end items-center py-4"></div>
                <div className="p-4">
                  <div className="flex pt-2 mb-6 filterSection flex-wrap lg:flex-nowrap w-full justify-between ">
                    <div className=" flex flex-wrap w-full">
                      <div className="searchSec md:w-1/3 md:pr-4 w-full mb-2 lg:mb-0 ">
                        <input
                          type="text"
                          className="formControl w-full"
                          placeholder={"search by company name"}
                          onChange={onSearchInputChange}
                          value={searchInput}
                          id="companySearch"
                        />
                      </div>
                      <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 ">
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          id="companyDate"
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          className="formControl"
                          placeholderText="Select Date Range"
                          onChange={(update) => {
                            setDateRange(update);
                            setTimeout(() => {
                              const initialStartDate = new Date(startDate);
                              const formattedStartDate =
                                moment(initialStartDate).format("YYYY-MM-DD");
                              const initialEndDate = new Date(endDate);
                              const formattedEndDate =
                                moment(initialEndDate).format("YYYY-MM-DD");

                              if (formattedStartDate && formattedEndDate) {
                                getSuperAdminCompanyList({
                                  page: page,
                                  name: searchInput,
                                  start_date: formattedStartDate,
                                  end_date: formattedEndDate,
                                  status: status ? status : "",
                                });
                              }
                            }, 100);
                          }}
                          // isClearable={true}
                          isClearable={startDate && endDate ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th className="w-40">Company Name</th>
                          <th>Email</th>
                          <th>Mobile Number</th>
                          <th>
                            No. of
                            <br /> Employees
                          </th>
                          <th>Signup Date</th>
                          <th>
                            Remaning free <br /> trial days
                          </th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyListData?.data?.length > 0 ? (
                          <>
                            {companyListData?.data?.map((company, index) => (
                              <tr key={index}>
                                <td className="w-40">{company?.name}</td>
                                <td>{company?.email}</td>
                                <td>{company?.tel_no}</td>
                                <td>{company?.employeeCount}</td>
                                <td>
                                  {moment(company?.createdAt).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>
                                  {company?.trialDaysCount > 0
                                    ? company?.trialDaysCount
                                    : 0}{" "}
                                  Days
                                </td>
                                <td>
                                  {company?.status ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Popup
                                    open={open}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                      <div
                                        onClick={() => setOpen((o) => !o)}
                                        className="actionBtn"
                                      >
                                        <i className="fa-solid fa-eye"></i>
                                      </div>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          id="companyDetailClose"
                                          className="close"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                          <h3 className=" mr-2 secondry-text flex">
                                            <span className="text-lg">
                                              Company Details
                                            </span>
                                          </h3>
                                        </div>
                                        <div className="content">
                                          <div className="flex mb-7">
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Company Name:{" "}
                                                </span>
                                                {company?.name}
                                              </p>
                                            </div>
                                            <div className="w-1/2 ">
                                              <p>
                                                <span className="font-medium">
                                                  Email:{" "}
                                                </span>{" "}
                                                {company?.email}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="flex mb-7">
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Business Name:{" "}
                                                </span>
                                                Food
                                              </p>
                                            </div>
                                            <div className="w-1/2 ">
                                              <p>
                                                <span className="font-medium">
                                                  Mobile Number:{" "}
                                                </span>{" "}
                                                {company?.tel_no}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="flex mb-7">
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  No. of Employees:{" "}
                                                </span>
                                                {company.employeeCount}
                                              </p>
                                            </div>
                                            <div className="w-1/2 ">
                                              <p>
                                                <span className="font-medium">
                                                  Signup Date:{" "}
                                                </span>{" "}
                                                {moment(
                                                  company?.createdAt
                                                ).format("DD-MM-YYYY")}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="flex mb-7">
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Website:{" "}
                                                </span>
                                                {company?.website}
                                              </p>
                                            </div>
                                            <div className="w-1/2 ">
                                              <p>
                                                <span className="font-medium">
                                                  Status:{" "}
                                                </span>
                                                {company?.status
                                                  ? "Active"
                                                  : "Inactive"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="w-full ">
                                            <div className="w-full mb-7">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Address:{" "}
                                                </span>
                                                {company?.address}{" "}
                                                {company?.address2}
                                              </p>
                                            </div>
                                            <div className="w-full mb-7">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Company Logo:{" "}
                                                </span>
                                              </p>
                                              <div className="imageUpload mt-2">
                                                <img
                                                  src={company?.company_logo}
                                                  className="rounded-lg"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>

                                  <Popup
                                    open={open}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                      <div
                                        onClick={() => setOpen((o) => !o)}
                                        className="actionBtn"
                                      >
                                        <i className="fa-solid fa-edit"></i>
                                      </div>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          id="companyEditClose"
                                          className="close"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                          {" "}
                                          <h3>Edit Company Details</h3>
                                        </div>
                                        <div className="content">
                                          <div className="p-4">
                                            {/* <form> */}
                                            <div className="flex flex-wrap ">
                                              <div className=" lg:w-1/2 lg:pr-6 w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Company Name
                                                  </label>
                                                  <FormInput
                                                    inputType="text"
                                                    inputName="name"
                                                    inputID="companyName"
                                                    value={company?.name}
                                                    handlerChange={
                                                      handlerChange
                                                    }
                                                  />

                                                  {/* <div className="help-block">{error.name ? error.name: ''}</div> */}
                                                </div>
                                              </div>
                                              <div className="lg:w-1/2 pr-0 w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Email
                                                  </label>
                                                  <FormInput
                                                    inputType="text"
                                                    inputName="email"
                                                    inputID="email"
                                                    value={company?.email}
                                                    handlerChange={
                                                      handlerChange
                                                    }
                                                  />

                                                  {/* <div className="help-block">{error.email ? error.email: ''}</div> */}
                                                </div>
                                              </div>
                                              <div className="lg:w-1/2 lg:pr-6  w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Mobile Number
                                                  </label>
                                                  <FormInput
                                                    inputType="number"
                                                    inputName="MobileNumber"
                                                    inputID="MobileNumber"
                                                    value={company?.tel_no}
                                                    handlerChange={
                                                      handlerChange
                                                    }
                                                  />
                                                  <div className="help-block mt-2">
                                                    {mobileError && (
                                                      <span className="text-red-500">
                                                        {mobileError}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lg:w-1/2 pr-0 w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Website
                                                  </label>
                                                  <FormInput
                                                    inputType="text"
                                                    inputName="Website"
                                                    inputID="Website"
                                                    value={company?.website}
                                                    handlerChange={
                                                      handlerWebsiteChange
                                                    }
                                                  />
                                                  <div className="help-block mt-2 text-red-700">
                                                    {websiteError?.website}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lg:w-1/2 lg:pr-6 w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    {" "}
                                                    Address
                                                  </label>
                                                  <SearchLocation
                                                    handleChangeAddress={handleChangeAddress}
                                                    setEmployeeInfo={setFormData}
                                                    employeeInfo={formData}
                                                  />
                                                </div>
                                              </div>
                                              <div className="lg:w-1/2 lg:pr-6 w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    City/Town
                                                  </label>
                                                  <FormInput
                                                    inputType="text"
                                                    inputName="city"
                                                    inputID="city"
                                                    value={company?.city}
                                                    handlerChange={
                                                      handlerChange
                                                    }
                                                  />
                                                  <div className="help-block mt-2">
                                                    {cityError && (
                                                      <span className="text-red-500">
                                                        {cityError}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lg:w-1/2 pr-0 w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Post Code
                                                  </label>
                                                  <FormInput
                                                    inputType="text"
                                                    inputName="postcode"
                                                    inputID="postcode"
                                                    value={company?.postal_code}
                                                    handlerChange={
                                                      handlerChange
                                                    }
                                                  />
                                                  <div className="help-block mt-2">
                                                    {postalError && (
                                                      <span className="text-red-500">
                                                        {postalError}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lg:w-1/2 lg:pr-6 w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Country
                                                  </label>
                                                  <FormInput
                                                    inputType="text"
                                                    inputName="country"
                                                    inputID="country"
                                                    value={company?.country}
                                                    handlerChange={
                                                      handlerChange
                                                    }
                                                  />
                                                  <div className="help-block mt-2">
                                                    {countryError && (
                                                      <span className="text-red-500">
                                                        {countryError}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="w-full">
                                                <div className="relative">
                                                  <label className="formBlock	">
                                                    Company Logo
                                                  </label>
                                                  <div className="relative">
                                                    <div className="imageUpload">
                                                      {preview ? (
                                                        <img
                                                          style={{
                                                            height: 80,
                                                            width: 80,
                                                          }}
                                                          src={preview}
                                                          className="rounded-lg"
                                                          onLoad={imageLoaded}
                                                        />
                                                      ) : company?.company_logo ? (
                                                        <>
                                                          <img
                                                            style={{
                                                              height: 80,
                                                              width: 80,
                                                            }}
                                                            src={
                                                              company?.company_logo
                                                            }
                                                            className="rounded-lg"
                                                            onLoad={imageLoaded}
                                                          />
                                                        </>
                                                      ) : (
                                                        <img
                                                          src="/images/no-image1.jpg"
                                                          className="rounded-lg"
                                                        />
                                                      )}

                                                      <div className="file-upload">
                                                        <input
                                                          type="file"
                                                          id="fileInput"
                                                          onChange={
                                                            handleUpload
                                                          }
                                                        />{" "}
                                                        <i className="fa fa-file-image" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="help-block" />
                                                </div>
                                              </div>
                                              <div className="lg:w w-full  ">
                                                <div className="w-full mt-4">
                                                  <div className="form-group pt-6 pb-3 flex border-t  ">
                                                    <button
                                                      className="btn save mr-2"
                                                      onClick={(e) => {
                                                        if (
                                                          !mobileError &&
                                                          !countryError &&
                                                          !cityError &&
                                                          !postalError
                                                        ) {
                                                          handlerSave(
                                                            e,
                                                            company
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      Update
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* </form> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                  <Popup
                                    open={open}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                      <div
                                        onClick={() => setOpen((o) => !o)}
                                        className="actionBtn"
                                      >
                                        <button
                                          className="fa-solid fa-credit-card"
                                          onClick={(e) =>
                                            openCreditCardPopup(e, company)
                                          }
                                        />
                                      </div>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          id="companyBillingClose"
                                          className="close"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                          <h3 className=" mr-2 secondry-text flex">
                                            <span className="text-lg">
                                              Billing Details
                                            </span>
                                          </h3>
                                        </div>
                                        <div className="content">
                                          <div className="flex mb-7">
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Company Name:{" "}
                                                </span>
                                                {company?.name}
                                              </p>
                                            </div>
                                            <div className="w-1/2 ">
                                              <p>
                                                <span className="font-medium">
                                                  Email:{" "}
                                                </span>{" "}
                                                {company?.email}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="flex mb-7">
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Mobile Number:{" "}
                                                </span>
                                                {company?.tel_no}
                                              </p>
                                            </div>
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Amount:{" "}
                                                </span>
                                                {
                                                  companyBillingDetailsData?.amount
                                                }
                                              </p>
                                            </div>
                                          </div>

                                          <div className="flex mb-7">
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Last Payment Date:{" "}
                                                </span>
                                                {
                                                  companyBillingDetails?.paymentDate
                                                }
                                              </p>
                                            </div>
                                            <div className="w-1/2 ">
                                              <p>
                                                <span className="font-medium">
                                                  Last Payment Status:{" "}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="flex mb-7">
                                            <div className="w-1/2 ">
                                              <p className="mr-4">
                                                <span className="font-medium">
                                                  Next Payment Due:{" "}
                                                </span>
                                                {moment(
                                                  companyBillingDetailsData?.nextPaymentDue
                                                ).format(formattedDate)}
                                              </p>
                                            </div>
                                            <div className="w-1/2 ">
                                              <p>
                                                <span className="font-medium">
                                                  Status:{" "}
                                                </span>
                                                {
                                                  companyBillingDetailsData?.status
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="w-full mb-7">
                                            <p className="mr-4">
                                              <span className="font-medium">
                                                Billing Address:{" "}
                                              </span>
                                              {company?.address}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                  <Popup
                                    open={open}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                      <div
                                        onClick={() => setOpen((o) => !o)}
                                        className="actionBtn"
                                        id="openOtpModel"
                                      >
                                        <i className="fa-solid fa-lock"></i>
                                      </div>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          className="close"
                                          id="companyPasswordClose"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                          <h3>Change Password</h3>
                                        </div>
                                        <div className="content">
                                          <form>
                                            <div className="w-full">
                                              <div className="form-group mb-6 ">
                                                <label className="formBlock">
                                                  Select
                                                  <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                                </label>
                                                <select
                                                  className="formControl"
                                                  id="companyPasswordSelect"
                                                  value={selectedRole}
                                                  onChange={(e) =>
                                                    handleSelectChange(
                                                      e,
                                                      company?.id
                                                    )
                                                  }
                                                >
                                                  <option value={2}>
                                                    Company Admin
                                                  </option>
                                                  <option value={3}>
                                                    Manager
                                                  </option>
                                                  <option value={4}>
                                                    Employee
                                                  </option>
                                                </select>
                                              </div>
                                              <div className="w-full">
                                                <div className="form-group mb-6 ">
                                                  <select
                                                    className="formControl"
                                                    id="companyPasswordEmpList"
                                                    name="type"
                                                    onChange={(e) =>
                                                      setSelectedEmployeeId(
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    <FormSelect
                                                      optionValue={"0"}
                                                      optionName={"Select"}
                                                    />
                                                    {superAdminEmployeeList
                                                      ?.data?.length > 0
                                                      ? superAdminEmployeeList?.data?.map(
                                                          (list, key) => {
                                                            return (
                                                              <FormSelect
                                                                key={key}
                                                                optionValue={
                                                                  list?.id
                                                                }
                                                                optionName={
                                                                  list?.first_name
                                                                }
                                                              />
                                                            );
                                                          }
                                                        )
                                                      : null}
                                                  </select>

                                                  <div className="help-block" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex flex-wrap ">
                                              <div className="form-group mb-6">
                                                <label className="formBlock">
                                                  New Password
                                                  <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                                </label>
                                                <FormInput
                                                  inputType="password"
                                                  inputName="newPassword"
                                                  inputID="NewPassword"
                                                  value={newPassword}
                                                  handlerChange={
                                                    handleNewPasswordChange
                                                  }
                                                />
                                                {passwordMismatchError && (
                                                  <p className="error-message text-red-700 mt-2">
                                                    {passwordMismatchError}
                                                  </p>
                                                )}
                                              </div>
                                              <div className="form-group mb-6 ml-4">
                                                <label className="formBlock">
                                                  Confirm Password
                                                  <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                                </label>
                                                <FormInput
                                                  inputType="password"
                                                  inputName="confirmPassword"
                                                  inputID="ConfirmPassword"
                                                  value={confirmPassword}
                                                  handlerChange={
                                                    handleConfirmPasswordChange
                                                  }
                                                />
                                              </div>

                                              <div className="lg:w w-full  ">
                                                <div className="w-full mt-4">
                                                  <div className="form-group pt-4 pb-3 flex justify-center ">
                                                    <PrimaryButton
                                                      btnText="Save"
                                                      Btnclass="btn save mr-2"
                                                      btnype="button"
                                                      buttonID="companyPasswordSave"
                                                      onClick={(e) =>
                                                        submitChangePassword(
                                                          e,
                                                          company?.id
                                                        )
                                                      }
                                                    />
                                                    <PrimaryButton
                                                      btnText="Cancel"
                                                      onClick={() => close()}
                                                      buttonID="companyPasswordCancel"
                                                      Btnclass="btn cancel"
                                                      btntype="button"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                  {/* delete company pop-up  */}
                                  <Popup
                                    open={open}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                      <div
                                        onClick={() => setOpen((o) => !o)}
                                        className="actionBtn"
                                      >
                                        <i className="fa fa-trash" />
                                      </div>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          className="close"
                                          id="companyDeleteClose"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                          {" "}
                                          <h3>Delete</h3>
                                        </div>
                                        <div className="content">
                                          <p>
                                            Are you sure you want to delete this{" "}
                                            {company?.name} type ?
                                          </p>
                                          <div className="w-full mt-8">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <button
                                                className="btn save mr-2"
                                                id="companyDeleteYes"
                                                onClick={(e) => {
                                                  handledeleteCompany(
                                                    company.id
                                                  );
                                                  close();
                                                }}
                                              >
                                                Yes
                                              </button>
                                              <button
                                                type="button"
                                                id="companyDeleteNo"
                                                onClick={() => close()}
                                                className="btn cancel "
                                              >
                                                No
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                  <Popup
                                    open={open}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                      <div
                                        onClick={() => setOpen((o) => !o)}
                                        className="actionBtn"
                                      >
                                        <i className="fa fa-upload" />
                                      </div>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          className="close"
                                          id="extendFreeTrialPopupClose"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                          {" "}
                                          <h3>Extend Trial</h3>
                                        </div>
                                        <div className="content">
                                        <div className="form-group mb-6 ml-4">
                                                <label className="formBlock">
                                                  Days to extend in free trial
                                                  <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                                </label>
                                                <input
                                                  type="number"
                                                  name="extend_trial"
                                                  id="extend_trial"
                                                  className="formControl"
                                                  // value={confirmPassword}
                                                  onChange={handleChangeDaysOfExtendedTrial}
                                                  pattern="[0-9]*"
                                                />
                                              </div>
                                          
                                          <div className="w-full mt-8">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <button
                                                className="btn save mr-2"
                                                id="companyDeleteYes"
                                                onClick={(e) => {
                                                  handleExtendFreeTrialOfCompany(e, company?.id);
                                                  close();
                                                }}
                                              >
                                                Yes
                                              </button>
                                              <button
                                                type="button"
                                                id="companyDeleteNo"
                                                onClick={() => close()}
                                                className="btn cancel "
                                              >
                                                No
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <center className="mt-4">{<NoDataFound />}</center>
                        )}
                      </tbody>
                    </table>
                    <Pagination
                      page={page}
                      pages={pages}
                      changePage={setPage}
                      decrementPage={decrementPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyList;
