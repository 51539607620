/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */

import SharedEmployee from "./sharedEmployee";
import { useEffect, useState, useRef} from "react";
import Popup from "reactjs-popup";
import { useDeleteSharedEmployeeFolderMutation, useGetDocumentFolderShareMutation } from "../../services/employeeDocumentAPI";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SharedDataListing  = ({ selectedFolder }) => {
    const [ getDocumentFolderShare, { data: documentFolderShareData} ] = useGetDocumentFolderShareMutation();
    const [ DeleteSharedEmployeeSharedFile, { isSuccess: DeleteSharedEmployeeSharedFileSuccess, isLoading: DeleteSharedEmployeeSharedFileLoading, isError:DeleteSharedEmployeeSharedFileError} ] = useDeleteSharedEmployeeFolderMutation();
    const [ open, setOpen ] = useState(false);
    const closeModal = () => {
      setOpen(false);
    };

    useEffect(() => {
        if(selectedFolder?.id){
            getDocumentFolderShare(selectedFolder?.id);
        } 
// eslint-disable-next-line react-hooks/exhaustive-deps        
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ selectedFolder?.id ]);

    const deleteshareFolderEmployee = (e, id) => {
        e.preventDefault();
        DeleteSharedEmployeeSharedFile(id);
    };

    const toastId = useRef(null);
    useEffect(() => {
        if (DeleteSharedEmployeeSharedFileLoading) {
            toastId.current = toast.loading("Please wait...", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            type: "info"
          });
        }
        if (DeleteSharedEmployeeSharedFileSuccess) {
          toast.dismiss(toastId.current);
          toast.success("Folder removed successfully",
            {
              render: "Folder removed successfully",
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "colored",
              type: "success"
            }
          );
          getDocumentFolderShare(selectedFolder?.id);
        document?.getElementById("closeRemovefolderPopup")?.click();
        }
        if (DeleteSharedEmployeeSharedFileError) {
          const message = "Something went wrong";
          toast.update(
            toastId.current,
            (message,
            {
              render: message,
              type: "error",
              autoClose: 5000,
              isLoading: false
            })
          );
        }
// eslint-disable-next-line react-hooks/exhaustive-deps      
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ DeleteSharedEmployeeSharedFileLoading, DeleteSharedEmployeeSharedFileSuccess, DeleteSharedEmployeeSharedFileError ]);


   
  return (
    <>
   <div className="p-6">
   <div className=" mb-4">
          <div className="mb-4">
      <SharedEmployee 
        selectedFolder={selectedFolder}
        getDocumentFolderShare={getDocumentFolderShare}
        />
      </div>
     </div>                            
      <>
       <label className="formBlock">
        People with access
        </label>  
        
        <div className="overflow-x-auto h-[28vh] whitespace-nowrap ">   
                       
                                        {documentFolderShareData?.sharedFolderWithEmployees?.length > 0 ? 
                                        <>
                                        {documentFolderShareData?.sharedFolderWithEmployees?.length > 0 && documentFolderShareData?.sharedFolderWithEmployees?.map((employeeList, index) => {

                                            return (
                                      <div key={index}  className=" w-full ">
                                        <div className="form-group mb-2 ">
                                        
                                        <div className="w-full">
                                        <div className="flex items-center w-full">
                                        <div className="w-8 h-8 mr-3 rounded-full bg-gray-700 flex items-center justify-center text-md uppercase text-white">
                                        {employeeList?.first_name[0]}{employeeList?.last_name[0]}
                                        </div>
                                        <div className="flex justify-between w-11/12">



                                        <div className="pr-4">
                                        <h3 className="text-[14px]">
                                        <Link
                                                to={`/employees/detail/ ${employeeList?.employee_id}`}
                                                className="listImage flex items-center "
                                            >
                                                {employeeList?.first_name} {employeeList?.last_name} 
                                        </Link>
                                        </h3>
                                        <span>{employeeList?.email}</span>
                                        </div>
                                        <Popup
                                                                                                                open={open}
                                                                                                                onClose={() => closeModal()}
                                                                                                                closeOnDocumentClick={false}
                                                                                                                trigger={
                                                                                                                    <span onClick={() => setOpen((o) => !o)} className="fa fa-trash text-red-600 bg-red-100 w-7 flex items-center justify-center h-7 rounded-full cursor-pointer " />
                                                                                                                }
                                                                                                                modal
                                                                                                                nested
                                                                                                            >
                                                                                                                {(close) => (
                                                                                                                <div className="modal">
                                                                                                                    <button
                                                                                                                    className="close"
                                                                                                                    id="closeRemovefolderPopup"
                                                                                                                    onClick={() => {
                                                                                                                        closeModal();
                                                                                                                        close();
                                                                                                                    }}
                                                                                                                    >
                                                                                                                    &times;
                                                                                                                    </button>
                                                                                                                    <div className="content">
                                                                                                                    <p>Are you sure you want to delete share folder for {employeeList?.first_name} {employeeList?.last_name}  ?</p>
                                                                                                                    <div className="w-full mt-8">
                                                                                                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                                                                                                        <button className="btn save mr-2" 
                                                                                                                        id="deleteYes"
                                                                                                                        onClick={(e) => deleteshareFolderEmployee(e, employeeList?.id)}
                                                                                                                        >Yes</button>
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            id="deleteNo"
                                                                                                                            onClick={() => close()}
                                                                                                                            className="btn cancel "
                                                                                                                        >
                                                                                                                            No
                                                                                                                        </button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                )}
                   </Popup> 
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                            );
                                            })}
                                        </> :
                                      <td className="mt-4 pl-8 text-red-700" colSpan="12">0 people have access to this folder.</td>}
                                        
                                    
                                    {/* <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/> */}
        </div>
        </> 
   </div>
    </>
  );
};

export default SharedDataListing;
