
/* eslint-disable camelcase */
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  department: [],
  employeeDepartmentsData: [],
  allDepartmentList: []
};

export const departmentSlice = createSlice({
  name: "deparmentSlice",
  initialState,
  reducers: {
    departmentInfo: (state, action) => {
      state.department = action.payload;
    },
    addDepartment: {
      reducer: (state, action) => {
        state.department.data.push(action.payload);
      }
    },

    editDepartment: (state, action) => {
      const {
        id,
        department_name,
        status,
        department_color_code,
        department_manager,
        department_off_at_once
      } = action.payload;
      const existingDepartment = state.department.data.find(
        (dept) => dept.id === id
      );

      if (existingDepartment) {
        existingDepartment.department_name = department_name;
        existingDepartment.department_manager = department_manager;
        existingDepartment.status = status;
        existingDepartment.department_off_at_once = department_off_at_once;
        existingDepartment.department_color_code = department_color_code;
      }
    },
    deleteDepartment: (state, action) => {
      const data = state.department.data.filter((dept) => {
        return dept.id !== action.payload;
      });
      state.department.data = data;
    },
    employeeDepartments: (state, action) => {
      state.employeeDepartmentsData = action.payload;
    },
    departmentListAction: (state, action) => {
      state.allDepartmentList = action.payload;
    }
  }
});

export const {
  addDepartment,
  editDepartment,
  departmentInfo,
  deleteDepartment,
  employeeDepartments,
  departmentListAction
} = departmentSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default departmentSlice.reducer;
