
import { useState } from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import moments from "moment";
import ExpenseDetails from "./ExpenseDetails";
import NoDataFound from "../../../helper/noDataFound";
import { LoadingIndicator } from "../../../components/loading-indicator";
import { useSelector } from "react-redux";

const PaidExpense = ({expenseLoading, allExpensePaid}) => {

    const [ open, setOpen ] = useState(false);
    const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
    const closeModal = () => {
    setOpen(false);
    };

  return (
    <div>
      <div className="overflow-x-auto w-full whitespace-nowrap">
                <table className="table-auto table  w-full">
                  <thead className="thead-dark ">
                    <tr>
                      <th className="w-40"> Employee</th>
                      <th>Expenses Type</th>
                      <th>Date Applied</th>
                      <th>Date of Spend</th>
                      <th>Date Paid</th>
                      <th>Amount</th>
                      <th>Attachments</th>
                      <th>Approved By</th>
                      <th>Date of Approval</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {expenseLoading ?<LoadingIndicator/> :
                  <tbody>
                  {allExpensePaid?.length > 0 ?                      
                  <>
                  {allExpensePaid && allExpensePaid.map((expense, index) => {
                     const fileName = expense?.expenseFiles[0]?.file_name;
                     const handleImageClick = () => {
                      // Check if fileName exists
                      if (fileName) {
                        // Create a link element
                        const downloadLink = document.createElement('a');
                        // Set the href attribute to the file path
                        downloadLink.href = fileName;
                        // Set the download attribute with the desired file name
                        downloadLink.download = 'your_desired_file_name.extension';
                        // Append the link to the document
                        document.body.appendChild(downloadLink);
                        // Trigger a click on the link element
                        downloadLink.click();
                        // Remove the link from the document
                        document.body.removeChild(downloadLink);
                      }
                    };
                    return (
                    <tr key={index}>
                      <td>
                        <Link
                          to={`/employees/detail/${expense?.employee_id}`}
                          className="listImage flex items-center "
                        >
                          {`${expense?.employeeInfo?.first_name} ${expense?.employeeInfo?.last_name}`}
                        </Link>
                      </td>
                      <td>{expense?.expenseTypeInfo?.expense_name}</td>
                      <td>{moments(expense?.createdAt).format(formattedDate)}</td>
                      <td>{moments(expense?.start_date).format(formattedDate)}</td>
                      <td>{moments(expense?.payment_date).format(formattedDate)}</td>
                      <td>£{expense?.amount}</td>
                      <td>
                        {fileName ? (
                          <div>
                            <img
                              src={fileName}
                              alt="Expense Image"
                              onClick={handleImageClick}
                              style={{ cursor: 'pointer', width: '80px', height: '44px', border: '1px solid grey', objectFit:'contain'}}
                            />
                            <p className="font-semibold">Download</p>
                          </div>
                        ) : (
                          "No File"
                        )}
                      </td>
                      <td>{expense?.approvedByInfo?.first_name}</td>
                      <td>{moments(expense?.approved_at).format(formattedDate)}</td>
                      <td>
                      <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"

                            >
                              <i className="fa fa-eye" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="paidExpenseClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <ExpenseDetails expense={expense}/>
                            </div>
                          )}
                        </Popup>
                        {/* <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa-solid fa-check" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <div className="header">
                                {" "}
                                <h3>Approve</h3>
                              </div>
                              <div className="content">
                                <label>Notes</label>
                                <textarea
                                  className="form-control"
                                  id="comment"
                                  rows="5"
                                />

                                <div className="w-full mt-8">
                                  <div className="form-group pt-4 pb-3 flex justify-center ">
                                    <button
                                      type="submit"
                                      className="btn save mr-2"
                                    >
                                      Submit
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => close()}
                                      className="btn cancel "
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>

                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa-solid fa-xmark" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <div className="header">
                                {" "}
                                <h3>Decline</h3>
                              </div>
                              <div className="content">
                                <form>
                                  {" "}
                                  <label>Notes</label>
                                  <textarea
                                    className="form-control"
                                    id="comment"
                                    rows="5"
                                  />
                                </form>
                                <div className="w-full mt-8">
                                  <div className="form-group pt-4 pb-3 flex justify-center ">
                                    <button className="btn save mr-2">
                                      Submit
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => close()}
                                      className="btn cancel "
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup> */}
                      </td>
                    </tr> );
                    })}
                    </> : 
                    <center className="mt-4">{<NoDataFound/>}</center>}
                  </tbody>}
                </table>
              </div>
    </div>
  );
};

export default PaidExpense;
