import "./App.css";
import RouterFile from "./route/router";
import "reactjs-popup/dist/index.css";
import {Provider} from "react-redux";
import {store, persistor} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./Notification";


function App() {
  // const stripePromise = loadStripe(
  //   "pk_test_51NJDOoHikpv0RAVqNWI67hVCRfY9h2ZIkZnFTxlnPairFkj8nNrMIz3GZ70s48yGXIiR2R4YbkiRY2glGjzCyONP00f4bNUQhW"
  // );
  // const elementsOptions = {
  //   paymentMethodCreation: 'manual',
  // };

  // const options = {
  //    mode: 'subscription',
  //   amount: 1099,
  //   currency: 'usd',
  //   paymentMethodCreation: 'manual',
  //   // Fully customizable with appearance API.
  //   // appearance: {
  //   //   theme: 'flat'
  //   // },
  //   layout: {
  //     type: 'tabs',
  //     defaultCollapsed: false,
  //     maxColumns:3
  //   }
  // };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <Elements stripe={stripePromise} options={options}> */}
        <div className="App">
          <ToastContainer
            // position="top-right"
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            d
            draggable
            pauseOnHover
            theme="colored"
          />
          <RouterFile />
          <Notification />
        </div>
        {/* </Elements> */}
      </PersistGate>
    </Provider>
  );
}

export default App;
