/* eslint-disable camelcase */
/* eslint-disable no-debugger*/
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ApiUrl} from "../constant";
export const employeeDocumentAPI = createApi({
  reducerPath: "employeeDocumentAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),

  endpoints: (builder) => ({
  
    getEmployeeDocument: builder.mutation({
      query: (id) => ({
        url: `document/folder?employee_id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeFiles: builder.mutation({
      query: (id) => ({
        url: `document/file?folder_id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getSharedEmployeeFiles: builder.mutation({
      query: (id) => ({
        url: `document/file/share?document_id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getDocumentFolderShare: builder.mutation({
      query: (id) => ({
        url: `document/folder/share?folder_id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeDocumentShare: builder.mutation({
        query: (id) => ({
          url: `document/folder/share?folder_id=${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
      }),
      postEmployeeDocument: builder.mutation({
        query: (payload) => ({
          url: `document/folder`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
      }),
      postShareEmployeeFile: builder.mutation({
        query: (payload) => ({
          url: `document/file/share`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
      }),
      postEmployeeFile: builder.mutation({
        query: (payload) => ({
          url: `document/file`,
          method: "POST",
          body: payload,
          headers: {
            // "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
      }),
      postEmployeeShareFolder: builder.mutation({
        query: (payload) => ({
          url: `document/folder/share`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
      }),
      deleteEmployeeDocument: builder.mutation({
        query: (id) => {     
          
          return {
            url: `document/folder/${id}`,
            method: "DELETE",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: localStorage.getItem("accessToken")
            }
          };
        }
      }),
      deleteEmployeeFile: builder.mutation({
        query: (id) => {        
          return {
            url: `document/file/${id}`,
            method: "DELETE",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: localStorage.getItem("accessToken")
            }
          };
        }
      }),
      deleteSharedEmployeeFolder: builder.mutation({
        query: (id) => {        
          return {
            url: `document/folder/share/${id}`,
            method: "DELETE",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: localStorage.getItem("accessToken")
            }
          };
        }
      }),
      deleteSharedEmployeeFile: builder.mutation({
        query: (id) => {        
          return {
            url: `document/file/share/${id}`,
            method: "DELETE",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: localStorage.getItem("accessToken")
            }
          };
        }
      })
  }),
  
 
  onError: (error
    //  { dispatch, getState }
     ) => {
    if (error.status === 401) {
      alert("Invalid Token Error");
      // Handle 401 Unauthorized error here
      // For example, you can dispatch an action to update the auth state:
      // dispatch(authActions.setAuthenticated(false));
      // Or show a notification/alert to the user:
      // showNotification("You are not authorized to perform this action.");
    }
  }
});

export const { usePostShareEmployeeFileMutation, useDeleteSharedEmployeeFolderMutation, useGetDocumentFolderShareMutation, usePostEmployeeShareFolderMutation, useGetEmployeeDocumentMutation, useDeleteEmployeeDocumentMutation, useGetEmployeeDocumentShareMutation, usePostEmployeeDocumentMutation, usePostEmployeeFileMutation, useGetEmployeeFilesMutation, useDeleteEmployeeFileMutation, useGetSharedEmployeeFilesMutation, useDeleteSharedEmployeeFileMutation } = employeeDocumentAPI;
