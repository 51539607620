import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import Select from "react-select";
import { FormInput, FormSelect } from "../../components/formInput";
import PrimaryButton from "../../components/primaryButton";
import { useCreateLeaveTypesMutation, useGetAllLeaveTypesDeductibleMutation, useGetAllLeaveTypesNonDeductibleMutation} from "./../../services/leaveTypesApi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {useSearchParams} from 'react-router-dom';
import { DeductibleLeaveType } from "./deductibleLeaveType";
import { NonDeductibleLeaveType } from "./nonDeductibleLeaveType";
import { deductibleLeaveTypeAction, nondeductibleLeaveTypeAction } from "../../redux/LeaveTypeSlice/leaveSlice";

const LeaveType = ({LeaveTypesCreatePermissions, LeaveTypesUpdatePermissions, LeaveTypesDeletePermissions}) => {

  const dispatch = useDispatch();

  //Api calling
  const [ getAllLeaveTypesDeductible, {data: deductibleData, isSuccess: deductibleDataSuccess } ] = useGetAllLeaveTypesDeductibleMutation();
  const [ getAllLeaveTypesNonDeductible, {data: nonDeductibleData, isSuccess: nonDeductibleDataSuccess } ] = useGetAllLeaveTypesNonDeductibleMutation();
  const [ createLeaveTypes, { isSuccess: isSuccessCreate, isError:isErrorCreate, isLoading: isLoadingCreate} ] = useCreateLeaveTypesMutation();

  //States
  const [ selectedIcon, setSelectedIcon ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ searchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page ] = useState(pageTerm ? pageTerm : 1);
  const toastId = useRef(null);
  const [ checked, setChecked ] = useState(true);
  const [ error, setError ] = useState({
    nameError : '', 
    colorError: '',
    statusError: '',
    typeError:'',
    iconError:''
  });
  const [ formData, setFormData ] = useState({
    leave_name:"",
    companies_id:"",
    leave_type:"3",
    short_name:"assf",
    color:"",
    status:"",
    icon:"",
    type:"0",
    is_deductible:'1'
  });
  const statusData = [
    {value :"1", label: "Active"},
    {value :"2", label: "InActive"}
  ];
  const closeModal = () => {
    setOpen(false);
    setFormData({
      leave_name:"",
      companies_id:"2",
      leave_type:"3",
      short_name:"assf",
      color:"",
      status:"",
      icon:"",
      type:"1"
  });
  };

  const deductibleLeaveType = (page) => {
    getAllLeaveTypesDeductible(`/leavetype?page=${page}&is_deductible=1`);
  };

  const nonDeductibleLeaveType = (page) => {
    getAllLeaveTypesNonDeductible(`/leavetype?page=${page}&is_deductible=0`);
  };
  
  useEffect(() => {
    deductibleLeaveType(page);
    nonDeductibleLeaveType(page);
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

  useEffect(() => {
   if(deductibleDataSuccess) {
      dispatch(deductibleLeaveTypeAction(deductibleData));
   }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ deductibleDataSuccess ]);

useEffect(() => {
  if(nonDeductibleDataSuccess) {
    dispatch(nondeductibleLeaveTypeAction(nonDeductibleData));
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ nonDeductibleDataSuccess ]);

  const leaveTypes = [
    { value: "", label: "", icon: "fa fa-umbrella-beach" },
    { value: "", label: "", icon: "fa fa-graduation-cap" },
    { value: "", label: "", icon: "fa fa-book" },
    { value: "", label: "", icon: "fa fa-truck-medical" },
    { value: "", label: "", icon: "fa fa-baby-carriage" },
    { value: "", label: "", icon: "fa fa-sun" },
    { value: "", label: "", icon: "fa fa-clock" },
    { value: "", label: "", icon: "fa fa-business-time" },
    { value: "", label: "", icon: "fa fa-list-check" },
    { value: "", label: "", icon: "fa fa-plane-departure" },
    { value: "", label: "", icon: "fa fa-handshake" },
    { value: "", label: "", icon: "fa fa-clipboard" },
    { value: "", label: "", icon: "fa fa-car" },
    { value: "", label: "", icon: "fa fa-house-user" },
    { value: "", label: "", icon: "fa fa-paw" },
    { value: "", label: "", icon: "fa fa-bed" },
    { value: "", label: "", icon: "fa fa-snowflake" },
    { value: "", label: "", icon: "fa fa-person" },
    { value: "", label: "", icon: "fa fa-person-dress" },
    { value: "", label: "", icon: "fa fa-basket-shopping" },
    { value: "", label: "", icon: "fa fa-cake-candles" },
    { value: "", label: "", icon: "fa fa-circle" },
    { value: "", label: "", icon: "fa fa-square" },
    { value: "", label: "", icon: "fa fa-triangle-exclamation" },
    { value: "", label: "", icon: "fa fa-user-doctor" },
    { value: "", label: "", icon: "fa fa-hand" },
    { value: "", label: "", icon: "fa fa-rotate" },
    { value: "", label: "", icon: "fa fa-spa" },
    { value: "", label: "", icon: "fa fa-star" },
    { value: "", label: "", icon: "fa fa-calendar-days" }   
  ];
  const options = leaveTypes.map((leaveType) => ({
    value: leaveType.value,
    label: (
      <div>
        <i className={leaveType.icon} style={{ fontSize: '16px' }}/>
        {leaveType.label}
      </div>
    ),
    icon: leaveType.icon
  }));

  const handleChange = (selectedOption) => {
    setFormData({...formData, ['icon']: ( selectedOption?.icon)});
    setSelectedIcon(selectedOption?.icon);
    if(selectedOption?.icon){
      setError({...error, iconError: " " });
    }
  };

  const changeHandler = (e) => {
    if((e.target.value).length > 50){
      setError({...error, nameError: "please enter maximum 10 character " });
    } else {
      setError({...error, nameError: " " });
    }
  };

  const handlerChange = (e) => {
   e.preventDefault();
   const {name} = e.target;
   if(name === 'leave_name') {
    if(name === '') {    
      setError({...error, nameError: "please enter the name " });  
    }else {
      setError({...error, nameError: " " });
    }
  }
  if(name === 'color') {
    if(name === '') {
      setError({...error, colorError: "please choose Color " });
    } else {
      setError({...error, colorError: " " });
    }
  }
  if(name === 'status') {
    if(name === '') {
      setError({...error, statusError: "please choose status " });
    } else {
      setError({...error, statusError: " " });
    }
  }
  if(name === 'type') {
    if(name === '0') {
      setError({...error, typeError: "please choose type " });
    } else {
      setError({...error, typeError: " " });
    }
  }
  if(name === 'icon') {
    if(name === '') {
      setError({...error, iconError: "please choose icon " });
    } else {
      setError({...error, iconError: " " });
    }
  }
   setFormData({...formData, [name]: e.target.value});
  };

  useEffect(() => {
    if (isLoadingCreate) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccessCreate) {
       toast.dismiss(toastId.current);
      toast.success("Leave type created successfully.", {
        render: "Leave type created successfully.",
             type: "success",
             autoClose: 2000,
           isLoading: false,
           theme: "colored"
      });
      deductibleLeaveType(page);
      nonDeductibleLeaveType(page);
      document.getElementById("addLeaveTypeClose")?.click() ;
    }
    if (isErrorCreate) {
      toast.dismiss(toastId.current);
      const toastMessage = "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessCreate, isErrorCreate, isLoadingCreate ]);

  const validation = () => {
    const {leave_name, color, icon} = formData;
    let isFormValidated = true;
    if(leave_name === '') {
      setError((errorValue) => { 
        return {
          ...errorValue,
          nameError: 'Please enter leave name.'
        };
      });
        isFormValidated = false;
  }
  if(leave_name?.length > 50) {
    setError((errorValue) => { 
      return {
        ...errorValue,
        nameError: 'Please enter less than 10.'
      };
    });
      isFormValidated = false;
}
  if(color === '') {
    setError((errorValue) => { 
      return {
        ...errorValue,
        colorError: 'Please enter color name.'
      };
    });
      isFormValidated = false;
  }
  if(icon === '') {
    setError((errorValue) => { 
      return {
        ...errorValue,
        iconError: 'Please select icon.'
      };
    });
      isFormValidated = false;
  }
  return isFormValidated;
};

  const handlerFormSubmit = (e, leave) => {
    e.preventDefault();
      if(validation()) {
        const value = {
          leave_name: formData.leave_name ? formData.leave_name : leave.leave_name,  
          leave_type:"3",
          short_name:"assf",
          color:formData.color ? formData.color : leave.color,
          status:formData.status ? formData.status === '2' ? '0': '1' : '1',
          icon:selectedIcon ? selectedIcon : leave.icon,
          id : leave.id,
          is_deductible: formData.is_deductible
        };
        createLeaveTypes(value);
      }
  };

  const handlerChangCheck = (e, name) => {
    e.persist();
    setChecked(e.target.checked);
    setFormData({...formData, [name]: e.target.checked === true ? "1" : "0"});
  };


  return (
    <>
  
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <h3 className=" font-semibold text-lg">Leave Type</h3>
        {LeaveTypesCreatePermissions[0]?.status === true && 
        <Popup
          open={open}
          onClose={() => { closeModal();
            }}
          closeOnDocumentClick={false}
          trigger={
            <button
              className="btn-md btn-add"
              id="addLeaveType"
              onClick={() => setOpen((o) => !o)}
            >
              <span className="fa fa-plus mr-2" />Add Leave Type
            </button>
          }
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <button
                className="close"
                id="addLeaveTypeClose"
                onClick={() => {
                  closeModal();
                  close();
                  setSelectedIcon();
                }}
              >
                &times;
              </button>
              <div className="header">
                <h3>Add Leave Type</h3>
              </div>
              <div className="content">
                <form onSubmit={(e) => handlerFormSubmit(e, false, close, '')}>
                  <div className="flex flex-wrap ">
                    <div className="lg:w-1/2  w-full lg:pr-6">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Name
                        <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <input
                          type="text"
                          className="formControl"
                          name="leave_name"
                          id="LeaveType"
                          value = {formData?.leave_name}
                          onChange={handlerChange}
                          onKeyUp={changeHandler}
                        />

                        <div className="help-block text-red-700">{error.nameError ? error.nameError : ''}</div>
                      </div>
                    </div>
                    <div className="lg:w-1/2 w-full pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Color
                        <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <FormInput
                          inputType="color"
                          inputName="color"
                          inputID="Color"
                          value = {formData?.color}
                          handlerChange={handlerChange}
                        />
                        <div className="help-block text-red-700">{error.colorError ? error.colorError : ''}</div>
                      </div>
                    </div>
                    <div className="lg:w-1/2  w-full lg:pr-6">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                       {''}
                        </label>
                        <div>
                         
                      <label className="flex text-sm items-center pb-3">
                          <input type="checkbox" className="mr-2 w-5 h-5" id="allowance" value="1"  defaultChecked={checked}  onChange={(e) => handlerChangCheck(e, 'is_deductible') } /> Take from Leave Allowance</label>
                     
                    </div>

                        <div className="help-block text-red-700">{error.typeError ? error.typeError : ''}</div>
                      </div>
                    </div>
                    <div className="lg:w-1/2 w-full pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Icon
                        <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
                        <Select
                          options={options}
                          id="icon"
                          onChange={handleChange}
                          isSearchable={true}
                          classNamePrefix="iconselect"
                          placeholder="Select an icon"
                        
                        />
                        <div className="help-block  text-red-700" >{error.iconError ? error.iconError : ''}</div>
                      </div>
                    </div>
                    <div className="lg:w-1/2  w-full lg:pr-6">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Status
                        <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <select className="formControl" name="status" id="addLeaveTypeStatus" onChange= {(e) => handlerChange(e)}>
                           {statusData?.map((status, index) => { 
                              return  <FormSelect key={index} 
                              optionValue={status?.value} 
                                optionName={status?.label} />;
                            })};
                        </select>
                        <div className="help-block text-red-700">{error.statusError ? error.statusError : ''}</div>
                      </div>
                    </div>
                    <div className="lg:w w-full  ">
                      <div className="w-full mt-4">
                        <div className="form-group pt-4 pb-3 flex justify-center ">
                          <PrimaryButton
                            Btnclass="btn save mr-2"
                            btnText="Save"
                            id="addLeaveTypeSave"
                            onClick={(e) => handlerFormSubmit(e, false, '')}
                          />
                          <PrimaryButton
                            Btnclass="btn cancel "
                            onClick={() => close()}
                            id="addLeaveTypeCancel"
                            btnText="Cancel"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Popup> }
      </div>
      <><DeductibleLeaveType
        LeaveTypesCreatePermissions={LeaveTypesCreatePermissions}
        LeaveTypesUpdatePermissions={LeaveTypesUpdatePermissions}
        LeaveTypesDeletePermissions={LeaveTypesDeletePermissions}
        deductibleLeaveType={deductibleLeaveType}
        nonDeductibleLeaveType={nonDeductibleLeaveType}
      />
      </>
      <>
        <NonDeductibleLeaveType
        LeaveTypesCreatePermissions={LeaveTypesCreatePermissions}
        LeaveTypesUpdatePermissions={LeaveTypesUpdatePermissions}
        LeaveTypesDeletePermissions={LeaveTypesDeletePermissions}
        nonDeductibleLeaveType={nonDeductibleLeaveType}
        deductibleLeaveType={deductibleLeaveType}
        />
      </>
     
    </>
  );
};

export default LeaveType;