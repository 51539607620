/* eslint-disable no-unused-vars */
//import React from 'react';
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import moments from "moment";
import Pagination from "../../components/inc/pagination";
import NoDataFound from "../../helper/noDataFound";
import { useSelector } from "react-redux";
import ViewPopup from '../../components/ViewPopup';


const TimeInLieu = ({overtimeTimeBackData, decrementPage}) => {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const pageTerm = searchParams.get('page') || '';
    const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
    const [ pages, setPages ] = useState();
    const [ open, setOpen ] = useState(false);
    const closeModal = () => {
        setOpen(false);
    };
    const filteredDatastatus1 = overtimeTimeBackData?.filter((item) => item?.status === "1" && item?.time_in_lieu === "1");
    const filteredDatastatus2 = overtimeTimeBackData?.filter((item) => item?.status === "3" && item?.time_in_lieu === "1");
    const totalDuration1 = filteredDatastatus1?.reduce((total, item) => total + item?.duration, 0);
    const totalDuration2 = filteredDatastatus2?.reduce((total, item) => total + item?.duration, 0);
    const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);                        
    const remainingDuration = totalDuration1 - totalDuration2; 
    const hour = Math.floor(remainingDuration / 60);
    const minute = remainingDuration % 60;
  return (
    <div>
      <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th>Type </th>
                          <th>Date </th>
                          <th>Start Date - End Date</th>
                          <th>Is Paid</th>  
                          <th>Break</th>
                          <th>Duration(Hours)</th>
                          <th>Lieu Time</th>
                          <th>Details</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {overtimeTimeBackData?.length > 0 ?
                        <>
                        {overtimeTimeBackData?.map((over, index) => {
                        const formatDuration = (duration) => {
                          const hours = Math.floor(duration / 60).toString().padStart(2, '0');
                          const minutes = (duration % 60).toString().padStart(2, '0');
                          return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} min${minutes !== 1 ? 's' : ''}`;
                        };
                        
                        const formattedDuration = formatDuration(over?.duration);
                        let breakTime = over?.break || "0:0";
      
                        // Split the time into hours and minutes
                        let [ hoursBreak, minutesBreak ] = breakTime.split(":");
      
                        // Convert the string values to integers
                        hoursBreak = parseInt(hoursBreak, 10);
                        minutesBreak = parseInt(minutesBreak, 10);
      
                        // Calculate the total duration in minutes
                        let totalMinutesBreak = hoursBreak * 60 + minutesBreak;
      
                        // Format the duration
                        let formattedDurationBreak = `${totalMinutesBreak} min`;
                        
                          return (
                        <tr key={index}>
                         
                          <td>{over?.type}</td>
                          <td>{moments(over?.date).format(formattedDate)}</td>
                          <td>{over?.start_time}-{over?.end_time}</td>
                          <td>{over?.is_Paid === "1" ? "Paid" : "UnPaid"}</td>
                          <td>{formattedDurationBreak}</td>
                          <td>{over?.is_Paid === "1"
                            ? formattedDuration
                            : (formattedDuration)}
                          </td>  
                          <td>{over?.time_in_lieu === '1' ? 'Yes' : 'No' }</td>
                          <td className="tdMaxWidth"><ViewPopup text={'Details'} data={over?.notes} /></td>
                          <td>
                            <span className={
                           over?.status === '0' ? 'yellow-bedge' :
                           over?.status === '1' ? 'greenBedge' :
                           over?.status === '3' ? 'amberBedge' :
                           'red-bedge'
                           }>{over?.status === "1" ? "Approved" : over?.status === "2" ? "Rejected" : over?.status === "3" ? "Redeemed" : over?.status === "0" ? "Awaiting approvals" : " "}</span>
                          </td>
                        </tr>
                       
                        );
                        })}
                        </> :
                         <center className="mt-4">{<NoDataFound/>}</center>}
                      </tbody>
                      
                    </table>
                    <div className="md:w-1/5 md:mb-0 mb-3  w-full ">
                      <h7 className="ml-[330%]">Total Remaining Duration :- <span className="circle">{hour} hours {minute} minutes</span></h7>
                    </div>
                    <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
                  </div>
    </div>
  );
};

export default TimeInLieu;