import { useSelector } from "react-redux";
import PrimaryButton from "../../components/primaryButton";
import { useApplyCompanyScheduleMutation, useCompanyDefaultScheduleMutation, useCreateCompanyWorkScheduleMutation } from "../../services/companyApi";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { parseISO } from "date-fns";
import {toast} from "react-toastify";
import Popup from "reactjs-popup";

const CompanyWorkSchedule = ({ myprofileReadOnly = false }) => {

    //Selector
    const employeeInfo = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);

    //Api Calling
    const [getWorkSchedule, {data, isSuccess: isSuccessData}] = useCompanyDefaultScheduleMutation();
    const [createCompanyWorkSchedule, {isSuccess, isLoading, isError, error: apiError}] = useCreateCompanyWorkScheduleMutation();
    const [ applyCompanySchedule, {isSuccess: applyCompanyScheduleSuccess, isLoading:applyCompanyScheduleLoading, isError: applyCompanyScheduleError} ] = useApplyCompanyScheduleMutation();

    //states and hooks
    const [sumOfBreak, setSumOfBreak] = useState(0);
    const toastId = useRef();
    const [isPaid, setIsPaid] = useState(false);
    const [totalHours, setTotalHours] = useState(0);
    const [totalHoursWeek, setTotalHoursWeek] = useState({});
    const [applyToAll, setApplyToAll] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [workScheduleInfo, setWorkScheduleInfo] = useState([
        {
          day: "1",
          is_holiday: "0",
          is_weekend: "0",
          break_paid: "0",
          break: 60,
          start_time: "10:00",
          end_time: "19:00"
        },
        {
          day: "2",
          is_holiday: "0",
          is_weekend: "0",
          break_paid: "0",
          break: 60,
          start_time: "10:00",
          end_time: "19:00"
        },
        {
          day: "3",
          is_holiday: "0",
          is_weekend: "0",
          break_paid: "0",
          break: 60,
          start_time: "10:00",
          end_time: "19:00"
        },
        {
          day: "4",
          is_holiday: "0",
          is_weekend: "0",
          break_paid: "0",
          break: 60,
          start_time: "10:00",
          end_time: "19:00"
        },
        {
          day: "5",
          is_holiday: "0",
          is_weekend: "0",
          break_paid: "0",
          break: 60,
          start_time: "10:00",
          end_time: "19:00"
        },
        {
          day: "6",
          is_holiday: "0",
          is_weekend: "0",
          break_paid: "0",
          break: 60,
          start_time: "10:00",
          end_time: "19:00"
        },
        {
          day: "7",
          is_holiday: "0",
          is_weekend: "0",
          break_paid: "0",
          break: 60,
          start_time: "10:00",
          end_time: "19:00"
        }
    ]);

    const handlerPaid = (e) => {
        workScheduleInfo.forEach((work) => {
          work.break_paid = e === true ? "1" : "0";
        });
      };
    
    const clickHandler = () => {
      let value = {
        employee_id: employeeInfo?.id,
        workSchedules: workScheduleInfo
      };
      createCompanyWorkSchedule(value);
    };

    const handleConfirmationPopup = () => {
      setShowConfirmation(true);
    };

    const confirmApplyToAll = () => {
      applyCompanySchedule();
      clickHandler();
      setShowConfirmation(false);
    };
  
    const cancelApplyToAll = () => {
      setShowConfirmation(false);
    };

    // Splitting the totalHours string into hours and minutes
    const totalHourString = totalHours.toString();
    const [totalHoursStr, totalMinutesStr] = totalHourString.split(":");
    const totalHoursInt = parseInt(totalHoursStr);
    const totalMinutesInt = parseInt(totalMinutesStr);

    // Subtracting sumOfBreak from totalHours
    let remainingHoursInt = totalHoursInt;
    let remainingMinutesInt = totalMinutesInt;

    // Handling if remainingMinutesInt is negative
    if (remainingMinutesInt < 0) {
        remainingMinutesInt += 60;
        remainingHoursInt -= 1;
    }

    // Formatting the result back into the desired string format
    const remainingHoursStr = remainingHoursInt.toString().padStart(2, "0");
    const remainingMinutesStr = remainingMinutesInt.toString().padStart(2, "0");
    const remainingTime = `${remainingHoursStr}h ${remainingMinutesStr}m`;

    // Splitting the originalTotalHours string into hours and minutes
    const HourString = totalHours.toString();
    const [totalHoursStrs, totalMinutesStrs] = HourString.split(":");
    const totalHoursInts = parseInt(totalHoursStrs);
    const totalMinutesInts = parseInt(totalMinutesStrs);

    // Subtracting sumOfBreak from totalHours
    let remainingHoursInts = totalHoursInts;
    let remainingMinutesInts = totalMinutesInts;

    // Handling if remainingMinutesInt is negative
    if (remainingMinutesInts < 0) {
    remainingMinutesInts += 60;
    remainingHoursInts -= 1;
    }

    // Formatting the result back into the desired string format
    const remainingHoursStrs = remainingHoursInts.toString().padStart(2, "0");
    const remainingMinutesStrs = remainingMinutesInts.toString().padStart(2, "0");
    const newTime = `${remainingHoursStrs}h ${remainingMinutesStrs}m`;

    useEffect(
        () => {
            if (employeeInfo?.id) {
              getWorkSchedule(employeeInfo?.id);
            }
          },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
      );

      useEffect(
        () => {
          if (isSuccess) {
            toast.dismiss(toastId.current);
            toast.success(
              `The Working Schedule has been changed for ${employeeInfo.first_name} ${employeeInfo.last_name}, please ensure their leave allowance is revised if necessary.`,
              {
                render:
                  "Working Schedule has been changed, please ensure the employees leave allowance is revised if necessary.",
                type: "success",
                autoClose: 10000,
                isLoading: false,
                theme: "colored"
              }
            );
            getWorkSchedule(employeeInfo?.id);
          }
          if (isError) {
            const toastMessage = apiError?.data?.message
              ? apiError?.data?.message
              : "Something went wrong";
            toast.update(
              toastId.current,
              (toastMessage,
              {
                render: toastMessage,
                type: "error",
                autoClose: 10000,
                isLoading: false
              })
            );
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSuccess, isError, isLoading]
      );

      useEffect(
        () => {
          if (applyCompanyScheduleSuccess) {
            toast.dismiss(toastId.current);
            toast.success(
              `The Working Schedule has been applied to all employee`,
              {
                render:
                  "Working Schedule has been applied to all employee",
                type: "success",
                autoClose: 10000,
                isLoading: false,
                theme: "colored"
              }
            );
          }
          if (applyCompanyScheduleError) {
            const toastMessage = apiError?.data?.message
              ? apiError?.data?.message
              : "Something went wrong";
            toast.update(
              toastId.current,
              (toastMessage,
              {
                render: toastMessage,
                type: "error",
                autoClose: 10000,
                isLoading: false
              })
            );
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [applyCompanyScheduleSuccess, applyCompanyScheduleError, applyCompanyScheduleLoading]
      );

      useEffect(
        () => {
          const filteredData = data?.data.filter((item) => item.is_weekend === 0);
          const sumOfBreak = filteredData?.reduce(
            (sum, item) => sum + parseInt(item?.break, 10),
            0
          );
    
          const breakSum = sumOfBreak / 60;
          setSumOfBreak(breakSum);
          data?.data.forEach((d) => {
            setIsPaid(d.break_paid === 1 ? true : false);
          });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSuccessData]
      );

      const handleCheckboxChange = () => {
        setApplyToAll(!applyToAll);
      
        if (!applyToAll) {
          // Find the schedule for day "1"
          const dayOneSchedule = workScheduleInfo?.find(
            (schedule) => schedule.day === "1"
          );
          if (dayOneSchedule) {
            const {start_time, end_time, break: breakTime} = dayOneSchedule;
      
            // Set all days to have the same times as day "1"
            const updatedSchedule = workScheduleInfo?.map((schedule) => ({
              ...schedule,
              start_time,
              end_time,
              break: breakTime
            }));
            setWorkScheduleInfo(updatedSchedule);
            let value = {
              employee_id: employeeInfo?.id,
              workSchedules: updatedSchedule
            };
            createCompanyWorkSchedule(value);
          }
        }
      
      };
    
    return (
      <>
          <div className="heading_title px-4 flex justify-between items-center py-4">
            <div>
              <h3 className="font-semibold text-lg">Company Work Schedule</h3>
            </div>
          </div>
        <div className="p-4">
        <div className="workingSchduleForm">
          <div className="md:flex flex-wrap mb-6 hidden">
            <div className="w-1/6	">
              <h3>Day</h3>
            </div>
            <div className="w-1/3	">
              <h3>Shift</h3>
            </div>
            <div className="w-1/6 px-4">
              <h3>Non working days</h3>
            </div>
            <div className="w-1/6 pl-2	">
              <h3>Break (min)</h3>
            </div>
            <div className="w-1/6	pl-1 ">
              <h3>Total Working Hours</h3>
            </div>
          </div>
          {data ? (
            <>
              {data?.data?.map((schedule, index) => {
                return (
                  <>
                    <DayComponent
                      key={index}
                      dayName={
                        schedule?.day === "1"
                          ? "Monday"
                          : schedule?.day === "2"
                          ? "Tuesday"
                          : schedule?.day === "3"
                          ? "Wednesday"
                          : schedule?.day === "4"
                          ? "Thursday"
                          : schedule?.day === "5"
                          ? "Friday"
                          : schedule?.day === "6"
                          ? "Saturday"
                          : schedule?.day === "7"
                          ? "Sunday"
                          : ""
                      }
                      schdeuleDay={schedule?.day}
                      employeeInfo={employeeInfo}
                      workScheduleInfo={workScheduleInfo}
                      setWorkScheduleInfo={setWorkScheduleInfo}
                      scheduleData={schedule}
                      setTotalHours={setTotalHours}
                      totalHoursWeek={totalHoursWeek}
                      setTotalHoursWeek={setTotalHoursWeek}
                      myprofileReadOnly={myprofileReadOnly}
                      sumOfBreak={sumOfBreak}
                      isPaid={isPaid}
                      clickHandler={clickHandler}
                      applyToAll={applyToAll}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  </>
                );
              })}
            </>
          ) : null}

          <div className="flex flex-wrap mb-6 lg:items-center totalSection mt-10">
            <div className="w-1/5">
              <h2 className="text-xl">Total</h2>
            </div>
            <div className="w-1/5  pl-2">
              <input
                type="text"
                id="hours"
                className="p-3 px-2 border rounded sm:w-24 mr-2  w-full"
                value={isPaid === false ? remainingTime : newTime}
                disabled
              />
            </div>
            <div className="lg:w w-full">
              <div className="w-full mt-4">
                <label className=" flex md:mr-2 mb-2 lg:mb-0 text-sm	">
                  <input
                    type="checkbox"
                    id="breaksPaid"
                    name="break_paid"
                    className="mr-2 w-5 h-5"
                    onChange={(e) => {
                      setIsPaid(e.target.checked);
                      handlerPaid(e.target.checked);
                    }}
                    checked={isPaid}
                  />
                  Breaks are paid
                </label>
              </div>
            </div>
            <div className="lg:w w-full">
              <div className="w-full mt-4">
                <div className="lg:w w-full">
                  <PrimaryButton
                    Btnclass="btn save mr-2"
                    onClick={clickHandler}
                    btnText="Save"
                    buttonID="save"
                  />
                  <button
                    type="button"
                    onClick={handleConfirmationPopup}
                    id="cancel"
                    className="btn cancel"
                  >
                    Apply to all employees
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Popup
          open={showConfirmation}
          modal
          nested
        >
          {() => (
            <div className="modal">
              <div className="header">
                <h3>Apply to all employees</h3>
              </div>
              <div className="content">
                <p>Are you sure you want to apply this work schedule to all employees?</p>
                <div className="w-full mt-8">
                  <div className="form-group pt-4 pb-3 flex justify-center ">
                    <button
                      id="resetPswYes"
                      className="btn save mr-2"
                      onClick={() => confirmApplyToAll()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      id="resetPswNo"
                      onClick={() => cancelApplyToAll()}
                      className="btn cancel "
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>
      </div>
      </>
      
    );
};

const DayComponent = ({
    clickHandler,
    dayName,
    schdeuleDay,
    workScheduleInfo,
    setWorkScheduleInfo,
    totalHoursWeek,
    setTotalHoursWeek,
    scheduleData,
    setTotalHours,
    myprofileReadOnly = false,
    applyToAll,
    handleCheckboxChange
  }) => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [hours, setHours] = useState(0);
    const [isCheck, setIsCheck] = useState(scheduleData?.is_weekend === 1);

    const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  
    const getTimeDifferenceCalculator = (startTime, endTime, isCheck) => {
      var a = new Date(startTime).getHours();
      var b = new Date(endTime).getHours();
      if (a > 12 && a < 24 && b < 12) {
        let tomorrow = new Date(endTime);
        tomorrow.setDate(tomorrow.getDate() + 1);
        var diff = tomorrow.getTime() - startTime.getTime();
        diff /= 1000 * 60;
        let hours = parseInt(Math.abs(diff) / 60);
        let min = diff % 60;
        setHours(
          `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`
        );
        return diff;
      } else {
        // if(moment(startDate).format('HH:mm') >= 23 || moment(startDate).format('HH:mm') >= 23 )
        let timeDiff = endTime?.getTime() - startTime?.getTime();
        let hourhsDifference = isCheck ? 0 : Math.round(timeDiff / 60000);
        let hours = parseInt(Math.abs(hourhsDifference) / 60);
        let min = hourhsDifference % 60;
        setHours(
          `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`
        );
        return hourhsDifference;
      }
    };

    
  useEffect(() => {
    if (scheduleData) {
      setIsChecked(scheduleData?.is_weekend === 1 ? true : false);
      setDisabled(scheduleData?.is_weekend === 1 ? true : false);

      let start_date = parseISO(`${"2023-07-01"} ${scheduleData?.start_time}`);
      let end_date = parseISO(`${"2023-07-01"} ${scheduleData?.end_time}`);
      let hourhsDifference = getTimeDifferenceCalculator(
        start_date,
        end_date,
        isCheck
      );

      const defaultBreakMinutes = scheduleData.break || 0; // Default break duration

      let abc = totalHoursWeek;
      setStartDate(start_date ? start_date : new Date());
      setEndDate(end_date ? end_date : new Date());

      const index = workScheduleInfo?.findIndex(
        (object) => object.day === schdeuleDay
      );
      if (index !== -1) {
        // Deduct default break time only if the day is not a weekend
        if (scheduleData.is_weekend !== 1) {
          hourhsDifference -= scheduleData?.break_paid
            ? 0
            : defaultBreakMinutes;
        }
        abc[index] = hourhsDifference;
        setTotalHoursWeek(abc);

        let sum = Object.values(abc).reduce(
          (accumulator, value) => accumulator + value,
          0
        );
        let hours = parseInt(sum / 60);
        let min = sum % 60;
        setTotalHours(
          `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`
        );

        // Set the deducted hours with default break in the 'hours' state
        setHours(formatHours(hourhsDifference));

        workScheduleInfo[index].is_weekend = scheduleData?.is_weekend;
        workScheduleInfo[index].start_time = moment(start_date).format("HH:mm");
        workScheduleInfo[index].end_time = moment(end_date).format("HH:mm");
      }
    }
  }, [scheduleData]);
  
    const timeData = [
      {name: "No Break", value: "00"},
      {name: "15", value: "15"},
      {name: "30", value: "30"},
      {name: "45", value: "45"},
      {name: "60", value: "60"},
      {name: "75", value: "75"},
      {name: "90", value: "90"},
      {name: "105", value: "105"},
      {name: "120", value: "120"}
    ];
  
    const handelChange = (date, name) => {
      const index = workScheduleInfo?.findIndex((object) => {
        return object?.day === schdeuleDay;
      });
      if (index !== -1) {
        if (name === "startDate") {
          setStartDate(date);
          const newsStartDate = moment(date).format("HH:mm");
          workScheduleInfo[index].start_time = newsStartDate;
          if (endDate) {
            let hourhsDifference = getTimeDifferenceCalculator(date, endDate);
            let abc = totalHoursWeek;
            abc[index] = hourhsDifference;
            setTotalHoursWeek(abc);
            let sum = Object.values(abc).reduce((accumulator, value) => {
              return accumulator + value;
            }, 0);
            let hours = parseInt(sum / 60);
            let min = sum % 60;
            setTotalHours(
              `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`
            );
          }
        }
        if (name === "endDate") {
          setEndDate(date);
          const newEndDate = moment(date).format("HH:mm");
          workScheduleInfo[index].end_time = newEndDate;
          if (startDate) {
            let hourhsDifference = getTimeDifferenceCalculator(startDate, date);
            let abc = totalHoursWeek;
            abc[index] = hourhsDifference;
            setTotalHoursWeek(abc);
            let sum = Object.values(abc).reduce((accumulator, value) => {
              return accumulator + value;
            }, 0);
            let hours = parseInt(sum / 60);
            let min = sum % 60;
            setTotalHours(
              `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`
            );
          }
        }
      }
    };
    const handelChangeIsWeekend = (e, checked) => {
      setIsCheck(checked);
      const index = workScheduleInfo?.findIndex(
        (object) => object?.day === schdeuleDay
      );
      if (index !== -1) {
        setIsChecked(e);
        setDisabled(e);
        workScheduleInfo[index].is_weekend = e === true ? "1" : "0";
        if (e === true) {
          setStartDate(new Date());
          workScheduleInfo[index].start_time = "10:00";
          workScheduleInfo[index].end_time = "19:00";
          let hourhsDifference = getTimeDifferenceCalculator(
            new Date(),
            new Date()
          );
          const defaultBreakMinutes = workScheduleInfo[index]?.break || 0; // Default break duration from workScheduleInfo
          hourhsDifference -= defaultBreakMinutes; // Deduct break time
          let abc = totalHoursWeek;
          abc[index] = hourhsDifference;
          setTotalHoursWeek(abc);
          let sum = Object.values(abc).reduce((accumulator, value) => {
            return accumulator + value;
          }, 0);
          let hours = parseInt(sum / 60);
          let min = sum % 60;
          setTotalHours(
            `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`
          );
        } else {
            let start_date = parseISO(`${"2023-07-01"} ${scheduleData?.start_time}`);
            let end_date = parseISO(`${"2023-07-01"} ${scheduleData?.end_time}`);
        let hourhsDifference = getTimeDifferenceCalculator(
            start_date,
            end_date
          );
          let abc = totalHoursWeek;
          abc[index] = hourhsDifference;
          setTotalHoursWeek(abc);
          let sum = Object.values(abc).reduce((accumulator, value) => {
            return accumulator + value;
          }, 0);
          let hours = parseInt(sum / 60);
          let min = sum % 60;
          setTotalHours(
            `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`
          );
        }
        let start_date = parseISO(`${"2023-07-01"} ${scheduleData?.start_time}`);
        let end_date = parseISO(`${"2023-07-01"} ${scheduleData?.end_time}`);
        setStartDate(start_date ? start_date : new Date());
        setEndDate(end_date ? end_date : new Date());
      }
    };
  
    const changeSlotHandler = (e) => {
      setWorkScheduleInfo(workScheduleInfo);
      const index = workScheduleInfo.findIndex((object) => {
        return object.day === schdeuleDay;
      });
      if (index !== -1) {
        workScheduleInfo[index].break = e.target.value;
      }
      clickHandler();
    };
  
    const formatHours = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
      }`;
    };
  
    return (
      <>
        <div className="flex flex-wrap mb-6 lg:items-center p-3 md:p-0 workDays bg-gray-50 md:bg-transparent rounded">
          <div className="md:w-1/6 md:mb-0 mb-3  w-full ">
            <h3 className="md:hidden block mb-2">Day</h3>
            <p>{dayName}</p>
          </div>
          <div className="md:w-1/3 md:mb-0 mb-3  w-full md:pr-2 lg:pr-0">
            <h3 className="md:hidden block mb-2">Shift</h3>
            <div className="md:flex items-center flex">
              <DatePicker
                id="fromDate"
                className={`${
                  disabled ? "formControl is-disabled" : "formControl ownClass"
                }`}
                selected={
                  startDate
                    ? startDate
                    : parseISO(`${"2023-07-01"} ${scheduleData?.start_time}`)
                }
                onChange={(date) => handelChange(date, "startDate")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Start Time"
                name="startDate"
                dateFormat="h:mm aa"
                disabled={myprofileReadOnly ? myprofileReadOnly : applyToAll ? applyToAll : disabled}
              />
              <span className="px-3">to</span>
              <DatePicker
                className={`${
                  disabled ? "formControl is-disabled" : "formControl ownClass"
                }`}
                selected={
                  endDate ? endDate : parseISO(`${"2023-07-01"} ${scheduleData?.end_time}`)
                }
                onChange={(date) => handelChange(date, "endDate")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                id="toDate"
                timeCaption="End Time"
                name="endDate"
                dateFormat="h:mm aa"
                disabled={myprofileReadOnly ? myprofileReadOnly : applyToAll ? applyToAll : disabled}
              />
             
            </div>
            {roleIdData === 2 ? <div className="mt-2 applyAllDay">
            <label className="flex items-center">
            <input
              type="checkbox" 
              className="mr-2 w-5 h-5"
              checked={applyToAll}
              onChange={handleCheckboxChange}
            />
            Apply to all days
          </label>
            </div> : null}
          </div>
          <div className="md:w-1/6 md:mb-0 mb-3  w-1/2 md:px-5">
            <h3 className="md:hidden block mb-2">Weekend</h3>
            <div className="form-group lg:flex items-center block md:pl-0 md:px-5">
              <label className=" md:mr-2 flex md:px-5">
                <input
                  type="checkbox"
                  id="weekend"
                  className="mr-2 w-5 h-5"
                  checked={isChecked}
                  onChange={(e) =>
                    handelChangeIsWeekend(e.target.checked, "is_weekend")
                  }
                />
              </label>
            </div>
          </div>
          <div className="md:w-1/6 md:mb-0 mb-3 pl-1 w-1/2">
            <h3 className="md:hidden block mb-2">Break (minutes )</h3>
            <div className="pform-group lg:flex items-center block md:pl-0 ">
              <label className="  md:mr-2	 ">
                <select
                  id="breakMinutes"
                  onChange={(e) => changeSlotHandler(e)}
                  defaultValue={scheduleData?.break}
                  className="p-1 px-1 border rounded md:w-14 lg:20 w-20 md:mr-2  mb-2 lg:mb-0"
                  disabled={roleIdData === 4 }
                >
                  {timeData.map((slot, index) => (
                    <option key={index} value={slot.value}>
                      {slot.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
          <div className="md:w-1/6 md:mb-0 mb-3  w-full pl-1">
            <h3 className="md:hidden block mb-2">Total Work Hours</h3>
            <p>{hours}</p>
          </div>
        </div>
      </>
    );
  };

export default CompanyWorkSchedule;
