
import { NavLink } from "react-router-dom";
const NavAnchor = ({LinkUrl, navText, navIcon, className}) => {
  return (
    <>
 <NavLink className={className} 
   to={LinkUrl}>
    <span className={navIcon} />
     {navText}
   </NavLink>
    </>
  );
};

export default NavAnchor;