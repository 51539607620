import {Route} from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import React, {lazy} from "react";
import {Layout} from "../components/layout";


const MasterRoutes = (

    <Route path="/" element={<Layout/>} />

);


export default MasterRoutes;