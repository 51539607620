/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  masterExpenses: []
};

export const masterExpensesSlice = createSlice({
  name: "masterExpensesSlice",
  initialState,
  reducers: {
    masterExpensesInfo: (state, action) => {

      state.masterExpenses = action.payload;
     },
    
    addMasterExpenses: (state, action) => {
       state.masterExpenses.data.push(action.payload.expenseType );
    },
    
     masterExpensesUpdate: (state, action) => {
      const{id, expense_name, description, status, is_mileage, pence_per_mile, companies_id} = action.payload.updatedMasterExpense;
      const existingExpenses = state.masterExpenses.data.find((exp) => exp.id === id);
      if(existingExpenses){
        existingExpenses.expense_name = expense_name;
        existingExpenses.description = description;
        existingExpenses.status = status;
        existingExpenses.is_mileage = is_mileage;
        existingExpenses.pence_per_mile = pence_per_mile;
        existingExpenses.companies_id = companies_id; 
      }
    },
    masterExpensesDelete: (state, action) => {
      // const data = state.masterExpenses.data.filter((exp) => {
      //   return exp.id !== action.payload;
      //  }
      //    );
      //    state.masterExpenses.data= data;
    
     }
  }
});

export const {addMasterExpenses, masterExpensesInfo, masterExpensesUpdate, masterExpensesDelete} =  masterExpensesSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default masterExpensesSlice.reducer;