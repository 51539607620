/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable comma-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import SimpleFileUpload from "react-simple-file-upload";
import DatePicker from "react-datepicker";
import { ExportCsvFile, FormInput } from "../../components/formInput";
import PrimaryButton from "../../components/primaryButton";
import { nameValidator } from "./validation";
import { useCreateHolidayMutation, useGetAllHolidayMutation, useUpdateHolidayMutation, useDeleteHolidayMutation, useImportHolidayCsvMutation, useGetFilterHolidayMutation } from "../../services/holidayApi";
import { holidayInfo, addHoliday, updatedHoliday, deletedHoliday } from "../../redux/HolidaySlice/holidaySlice";
import { useDispatch, useSelector } from "react-redux";
import TableContent from "../../components/tableContent";
import { toast } from "react-toastify";
import moment, * as moments from 'moment';
import Pagination from "../../components/inc/pagination";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormSelect } from "../../components/formInput";
import { read, utils } from 'xlsx';
// import  ExcelData from './excelData';
import { CSVLink } from 'react-csv';
import { formatDate } from "../../helper/LeaveHelper";

const PublicHoliday = ({ bankHolidayCreatePermissions }) => {

  const [importHolidayCsv, { data: importHolidayCsvRespo, isSuccess: isSuccessImport, isError: isErrorImport, isLoading: isLoadingImport }] = useImportHolidayCsvMutation();
  const [toggleState2, setToggleState2] = useState("England + Wales");
  const yearOptions = [
    { value: 0, name: "Select" },
    { value: 1, name: "2023" },
    { value: 2, name: "2024" },
    { value: 3, name: "2025" }
  ];
  const countryOptions = [
    { value: 0, name: "Select" },
    { value: 1, name: "England + Wales" },
    { value: 2, name: "Scotland" },
    { value: 3, name: "Ireland" }
  ];
  const [createHoliday, { isSuccess: isCreateSuccess, isLoading, isError, error: apiError }] = useCreateHolidayMutation();
  const [getAllHoliday, { data: allHolidayData, isSuccess: isHolidaySuccess }] = useGetAllHolidayMutation();
  const [updateHoliday, { isSuccess: isSuccessUpdate, isError: isErrorUpdate, isLoading: isLoadingUpdate }] = useUpdateHolidayMutation();
  const [deleteHoliday, { data: deleteHolidayData, isSuccess: isSuccessDelete, isError: isErrorDelete, isLoading: isLoadingDelete }] = useDeleteHolidayMutation();
  const [getFilterHoliday, { data: filterHolidayRespo, isSuccess: isSuccessFilter }] = useGetFilterHolidayMutation();
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const pageTerm = searchParams.get('page') || '';
  const [page, setPage] = useState(pageTerm ? pageTerm : 1);
  const [pages, setPages] = useState(0);
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [tableData, setTableData] = useState();
  const [holidayData, setHolidayData] = useState();
  const [previewButton, setPreviewButton] = useState(false);
  const [file, setFile] = useState();
  const [showTable, setShowTable] = useState(false);
  const [year, setYear] = useState();
  const [selectedYear, setSelectedYear] = useState(2023);
  const [locationId, setLocationId] = useState(1);
  const [noData, setNoData] = useState();
  const [formData, setFormData] = useState({
    name: "",
    start_date: new Date(),
    end_date: new Date(),
    id: "",
    is_restricted: "0",
    selectCountry: "0",
    selectYear: "0",
    location_id: "0"
  });
  const tableData1 = [{
    name: 'abc',
    start_date: '07/22/2025',
    end_date: '07/22/2025',
    location_id: '1',
    description: '',
    day: ''
  }

  ];
  const headers = [{
    label: 'name', key: 'name'
  },
  { label: 'start_date', key: 'start_date' },
  { label: 'end_date', key: 'end_date' },
  { label: 'location_id', key: 'location_id' },
  { label: 'description', key: 'description' },
  { label: 'day', key: 'day' }

  ];
  const [rows, setRows] = useState();
  const [error, setError] = useState({
    nameError: "",
    selectCountry: "",
    selectYear: "",
    yearError: '',
    monthEror: ''
  });

  const toggleTab2 = (index) => {
    setToggleState2(index);
    index === "England + Wales" ? getAllHoliday({ location_id: 1, start_year: selectedYear, page: page }) : index === "Scotland" ? getAllHoliday({ location_id: 2, start_year: selectedYear, page: page }) : index === "Ireland" ? getAllHoliday({ location_id: 3, start_year: selectedYear, page: page }) : null;

    if (index === "England + Wales") {
      setLocationId(1);
    } else if (index === "Scotland") {
      setLocationId(2);
    } else if (index === "Ireland") {
      setLocationId(3);
    }
  };


  const closeModal = () => {
    setOpen(false);
    // setStartDate();
    // setEndDate();
    // setFormData({
    //   name: "",
    //   start_date: '',
    //   end_date: '',
    //    id: "",
    //    is_restricted:"0",
    //    location_id:'0'
    // });
  };

  // eslint-disable-next-line no-unused-vars
  const handleOnDrop = (data) => {
    <div />;
  };

  useEffect(() => {
    getAllHoliday({ location_id: 1, start_year: selectedYear, page: page });
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[page]);

  useEffect(() => {
    if (filterHolidayRespo) {
      if (filterHolidayRespo.data) {
        setPages(filterHolidayRespo?.totalPages);
        setHolidayData(filterHolidayRespo.data);
      } else {
        setNoData('No holiday data.');
      }


    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isSuccessFilter]);

  const handlerYearChange = (e, year) => {
    setYear(e);
    //let selectedYear = moments(e).format('yyyy');
    setSelectedYear(moments(e).format('yyyy'));
    getFilterHoliday({ location_id: locationId, year: moments(e).format('yyyy') });
  };

  useEffect(() => {
    if (isHolidaySuccess) {
      if (allHolidayData?.data) {
        if (allHolidayData?.data?.length < 1 && allHolidayData?.currentPage > 1) {
          getAllHoliday(allHolidayData?.currentPage - 1);
        }
        setPages(allHolidayData?.totalPages);
        setHolidayData(allHolidayData?.data);
      }
    }
    else {
      setNoData('No holiday Data');
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isHolidaySuccess]);

  const decrementPage = () => {
    getAllHoliday(allHolidayData?.currentPage - 1);
    setPage(allHolidayData?.currentPage - 1);
  };


  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isCreateSuccess) {
      // getCreateSuccessHoliday();
      toast.dismiss(toastId.current);
      toast.update(
        toastId.current,
        ("Holiday added Successfully!",
        {
          render: "Holiday added  successfully!",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
      getAllHoliday(page);
    }
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage =
        apiError?.data?.message
          ? apiError?.data?.message
          : "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isCreateSuccess, isError, isLoading]);
  // eslint-disable-next-line no-unused-vars
  useEffect(() => {
    if (isLoadingImport) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccessImport) {
      toast.dismiss(toastId.current);
      // getUpdateHoliday();
      toast.update(
        toastId.current,
        ("Holiday data added successfully!",
        {
          render: "Holiday data added successfully!",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
      getAllHoliday(page);
    }
    if (isErrorImport) {
      toast.dismiss(toastId.current);
      const toastMessage =
        // apiError.data.message
        //   ? apiError.data.message
        //   :
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isLoadingImport, isSuccessImport, isErrorImport]);
  function handleMultipleUpload(e) {
    let file = e.target.files[0];
    var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/;

    setOpenExcel(false);
    if (file) {
      setFile(file);
      setPreviewButton(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { raw: true });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 0,
            defval: ""
          });
          if (rows.length > 0) {
            let columns = Object.keys(rows[0]);
            let available_columns = columns.map((col) => {
              let val = col.replace('-', '_')
                .replace(/[^\w ]/g, '')
                .replace(/^\s+|\s+$/gm, '')
                .split(' ')
                .join('_')
                .replace('-', '_')
                .toLowerCase();
              return {
                'label': col,
                'value': val
              };
            });
            setTableData({ file_rows: rows, file_columns: columns, available_file_columns: available_columns });

            setRows(rows);
            setOpenExcel(true);
          }

        }
      };
      reader.readAsArrayBuffer(file);
    }
    // 
  }

  const handlerFileSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const blob = new Blob([file], { type: "text/csv" });
    formData.append("csvfile", blob);
    importHolidayCsv(formData);
  };

  const handlerTableShow = (e) => {
    e.preventDefault();
    setShowTable(true);
  };




  const handlerChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    setFormData({ ...formData, [name]: e.target.value });
  };

  const validForm = () => {
    const { name } = formData;
    let isFormValidated = true;
    if (name === '') {
      setError((errorValue) => {
        return {
          ...errorValue,
          ['nameError']: "Please enter holiday name"
        };
      });
      isFormValidated = false;

    }
    else if (formData?.location_id === '0') {
      setError((errorValue) => {
        return {
          ...errorValue,
          ['selectCountry']: "Please select the country"
        };
      });
      isFormValidated = false;

    }
    else {
      setError((errorValue) => {
        return {
          ...errorValue,
          ['nameError']: ''
        };
      });
      setError((errorValue) => {
        return {
          ...errorValue,
          ['selectCountry']: ''
        };
      });
    }
    let startYear = moment(startDate).format('YYYY');
    let endYear = moment(endDate).format('YYYY');
    if (startYear !== endYear) {
      setError((errorValue) => {
        return {
          ...errorValue,
          ['yearError']: "Please select same year"
        };
      });
      isFormValidated = false;

    }

    return isFormValidated;

  };

  const handlerDateChange = (e, name) => {
    if (name === 'start_date') {
      setFormData((formValue) => {
        return {
          ...formValue, start_date: e
        };
      });
      setStartDate(e);
      setFormData((formValue) => {
        return {
          ...formValue, end_date: e
        };
      });
      setEndDate(e);
    } else {
      setFormData((formValue) => {
        return {
          ...formValue, end_date: e
        };
      });
      setEndDate(e);
    }

  };


  useEffect(() => {
    if (isLoadingUpdate) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccessUpdate) {
      // getUpdateHoliday();
      toast.dismiss(toastId.current);
      toast.update(
        toastId.current,
        ("Holiday data updated successfully!",
        {
          render: "Holiday data updated successfully!",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
      getAllHoliday(page);
    }
    if (isErrorUpdate) {
      toast.dismiss(toastId.current);

      const toastMessage =
        // apiError.data.message
        //   ? apiError.data.message
        //   :
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isSuccessUpdate, isErrorUpdate]);


  const handlerSave = async (e, edit = false, close, holi) => {

    e.preventDefault();
    if (edit) {
      const value = {
        name: formData.name ? formData.name : holi.name,
        start_date: formData.start_date ? formData.start_date : holi.start_date,
        end_date: formData.end_date ? formData.end_date : holi.end_date,
        id: holi.id,
        location_id: formData.location_id ? formData.location_id : holi.location_id,
        days: formData.days ? formData.days : holi.days,
        is_restricted: "0"
      };

      updateHoliday(value);
      close();
    } else {
      if (validForm()) {

        const value = {
          name: formData.name,
          start_date: formData.start_date ? formData.start_date : new Date(),
          end_date: formData.end_date ? formData.end_date : new Date(),
          location_id: formData.location_id ? formData.location_id : holi.location_id,
          is_restricted: "0"
        };
        createHoliday(value);
        close();
      }
    }

  };

  const yesterday = moment().subtract(1, 'day');
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };



  useEffect(() => {
    if (isLoadingDelete) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccessDelete) {
      // getDeleteDataHoliday();
      toast.dismiss(toastId.current);
      toast.update(
        toastId.current,
        ("Holiday deleted successfully",
        {
          render: "Holiday deleted successfully",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
      getAllHoliday(page);
    }
    if (isErrorDelete) {
      toast.dismiss(toastId.current);
      const toastMessage =
        // apiError.data.message
        //   ? apiError.data.message
        //   :
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isErrorDelete, isSuccessDelete, isLoadingDelete]);



  const handlerDelete = (e, id, close) => {
    e.preventDefault();
    deleteHoliday(id);
    close();
  };

  const today = new Date();
  return (
    <>
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <h3 className=" font-semibold text-lg">Bank Holidays</h3>
        {/* <ToastContainer /> */}
        <div className="headerButton">
          { bankHolidayCreatePermissions[0]?.status === true &&
            <Popup
              open={open}
              onClose={() => closeModal()}
              closeOnDocumentClick={false}
              trigger={
                <></>
                // <button
                //   className="btn-md btn-add mr-2 mb-2 sm:mb-0"
                //   onClick={() => setOpen((o) => !o)}
                //   id="importDataPopup"
                // >
                //   <span className="fa fa-download mr-2 sm:mb-0" />Import Data
                // </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button
                    className="close"
                    id="importDataClose"
                    onClick={() => {
                      closeModal();
                      close();
                    }}
                  >
                    &times;
                  </button>
                  <div className="header">
                    <h3>Import Data</h3>
                  </div>
                  <div className="content">
                    <div className="file_Upload mb-4">


                      <input
                        type="file"
                        name="file"
                        className="upload-button"

                        id="exampleFile"
                        onClick={(event) => event.target.value = null}
                        onChange={(e) => handleMultipleUpload(e)} />
                      <p>Upload File</p>

                    </div>
                    {/* {previewButton &&<button className ='btn-md btn-add mr-2 mb-2 sm:mb-0' onClick={(e) => handlerFileSubmit(e)}> Save </button>} */}
                    {/* {previewButton && <button className ='btn-md btn-add mr-2 mb-2 sm:mb-0' onClick={(e) => handlerTableShow(e)}> preview </button>} */}


                    {/* <a
                  href="#"
                  className="text-blue-900 cursor-pointer text-base	mt-4"
                > */}

                    <CSVLink className="text-blue cursor-pointer text-base	mt-4"
                      data={tableData1 ? tableData1 : ""}
                      headers={headers}
                      filename={'bankholiday.csv'}
                    >
                      <span className="fa fa-download mr-2" />Download CSV template
                    </CSVLink>

                    {/* </a> */}
                  </div>
                </div>
              )}
            </Popup>}

          <Popup
            open={openExcel}
            onClose={() => closeModal()}
            closeOnDocumentClick={false}
            // trigger={}
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button
                  className="close"
                  id="openExcelClose"
                  onClick={() => {
                    closeModal();
                    close();
                  }}
                >
                  &times;
                </button>
                <ExcelData tableData={tableData} title={'Bank Holiday'}
                  file={file} close={close}
                  setOpenExcel={setOpenExcel}
                  getAllHoliday={getAllHoliday}

                  selectedYear={selectedYear}
                  page={page}
                  setOpen={setOpen}
                  closeModal={close}
                />
              </div>
            )}
          </Popup>
          {/* <Popup
          open={open}
          onClose={() => closeModal()}
          closeOnDocumentClick={false}
          trigger={
            <button
              className="btn-md btn-add"
              onClick={() => setOpen((o) => !o)}
            >
              <span className="fa fa-plus mr-2"/>Add Bank Holiday
            </button>
          }
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <button
                className="close"
                onClick={() => {
                  closeModal();
                  close();
                }}
              >
                &times;
              </button>
              <div className="header">
                {/* <h3>Company Holiday Year</h3> */}
          {/* <h3>Add Bank Holiday </h3>
              </div>
              <div className="content">
              <form onSubmit={(e) => handlerSave(e)}>
                     <div className="flex flex-wrap ">
                       <div className="w-full">
                        <div className="form-group mb-6 ">
                           <label className="formBlock">Holiday Name
                           <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                           </label>
                           <FormInput
                             inputType="text"
                             inputName="name"
                             inputID="HolidayName"
                             handlerChange={(e) => handlerChange(e)}
                           />

                           <div className="help-block text-red-700">{error.nameError ? error. nameError:''}</div>
                         </div>
                       </div>

                       <div className=" w-full pr-0">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">Country</label>
                      <select
                        className="formControl"
                        name="location_id"
                        value={formData.location_id}
                        onChange={handlerChange}
                      >
                       {countryOptions?.map((country, index) => { 
                        return  <FormSelect key={index} optionValue={country?.value} optionName={country?.name} />;
                      })}
                      </select>
                      <div className="help-block text-red-700">{error?.selectCountry}</div>
                    </div>
                  </div>

                       <div className="flex">
                       <div className="md:w-1/2 md:pr-6 w-full ">
                         <div className="form-group mb-6 ">
                           <label className="formBlock	"> Start Date</label>
                           <DatePicker
                             showIcon
                             isValidDate={disablePastDt}
                             dateFormat="dd/MM/yyyy"
                             className="formControl"
                             name= "start_date"
                             selected={ formData.start_date ? startDate: new Date()}
                             onChange={(e) => { handlerDateChange(e,"start_date");
                             //  setStartDate(e);
                             
                           }}
                           selectsStart
                           startDate={startDate}
                           minDate={today}
                           />
                           <div className="help-block">{error.startDate?error.startDate:''}</div>
                         </div>
                       </div>
                      <div className="md:w-1/2 w-full ">
                         <div className="form-group mb-6 ">
                           <label className="formBlock	"> End Date</label>
                           <DatePicker
                             showIcon
                             dateFormat="dd/MM/yyyy"
                             className="formControl"
                             name = "end_date"
                             selected = {formData.end_date ? endDate: new Date()}
                             onChange={(e) =>  handlerDateChange(e,"end_date")}
                             minDate={startDate ? startDate : today}
                           />
                           <div className="help-block">{error.endDate ? error.endDate:''}</div>
                         </div>
                       </div>
                       </div>
                       <div className="md:w w-full  ">
                         <div className="w-full mt-4">
                           <div className="form-group pt-4 pb-3 flex justify-center ">
                             <PrimaryButton
                               Btnclass="btn save mr-2"
                               btnText="Save"
                               onClick={(e) => handlerSave(e, false, close, '')}
                             />
                             <PrimaryButton
                              Btnclass="btn cancel "
                               btnText="Cancel"
                               onClick={() => close()}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                   </form>
              </div>
            </div>
          )}
        </Popup> */}
        </div>
      </div>




      <div className="p-4">
        <div>
          <ul className="flex approvalSubTab mb-6 ">
            <li className="mr-0">
              <span
                onClick={() => toggleTab2("England + Wales")}
                className={
                  toggleState2 === "England + Wales" ? " active" : "  "
                }
              >
                England + Wales
              </span>
            </li>
            <li className="mr-0">
              <span
                onClick={() => toggleTab2("Scotland")}
                className={
                  toggleState2 === "Scotland" ? " active" : "  "
                }
              >
                Scotland
              </span>
            </li>
            <li className="mr-0">
              <span
                onClick={() => toggleTab2("Ireland")}
                className={
                  toggleState2 === "Ireland" ? " active" : "  "
                }
              >
                Northern Ireland
              </span>
            </li>
          </ul>
        </div>
        <div className="flex flex-row item-center">
          <div className="w-[160px] ">
            <div className="form-group mb-6 ">
              <div className="flex items-baseline">
                <label className="formBlock	mr-4">Year</label>
                {/* <select
              className="formControl"
              name="selectYear"
              
              onChange={(e) => handlerYearChange(e)}
            >
            {yearOptions?.map((year, index) => { 
              return  <FormSelect key={index} optionValue={year?.value} optionName={year?.name} />;
            })}
            </select> */}
              </div>
              <DatePicker
                selected={year ? year : new Date()}
                className="formControl"
                id="publicHolidayDate"
                showYearPicker
                dateFormat="yyyy"
                onChange={(e) => handlerYearChange(e, 'year')}
              />
              <div className="help-block text-red-700">{error?.selectYear}</div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto w-full whitespace-nowrap">
          <table className="table-auto table w-full">
            <thead className="thead-dark ">
              <tr>
                <th className="w-80">Name</th>
                <th>Start Date</th>
                <th>End Date</th>

                {/* <th>Action</th> */}
              </tr>
            </thead>

            <tbody>{holidayData ?
              <>

                {holidayData?.map((holi, index) => {
                  let startDate = holi.start_date.split('T')[0];
                  let endDate = holi.end_date.split('T')[0];


                  return (

                    <tr key={index}>
                      <td>{holi?.name}</td>
                      <td>{moments(startDate).format('DD-MM-YYYY')}</td>
                      <td>{moments(endDate).format('DD-MM-YYYY')}</td>
                      {/* <td>
                {employee?.roleId === 1  ?
                <>
                 <Popup
                  open={openEdit}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-pencil"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        <h3>Edit Holiday</h3>
                      </div>
                      <div className="content">
                      <form onSubmit={(e) => handlerSave(e)}>
                     <div className="flex flex-wrap ">
                       <div className="w-full">
                        <div className="form-group mb-6 ">
                           <label className="formBlock	">Holiday Name</label>
                           <FormInput
                             inputType="text"
                             inputName="name"
                             inputID="HolidayName"
                             value = {holi.name}
                             handlerChange={handlerChange}
                           />

                           
                         </div>
                       </div>
                       <div className=" w-full pr-0">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">Country</label>
                      <select
                        className="formControl"
                        name="location_id"
                        defaultValue={formData?.location_id}
                        onChange={handlerChange}
                      >
                       {countryOptions?.map((country, index) => { 
                        return  <FormSelect key={index} optionValue={country?.value} optionName={country?.name} />;
                      })}
                      </select>
                      <div className="help-block text-red-700">{error?.selectCountry}</div>
                    </div>
                  </div>
                       <div className="md:w-1/2 md:pr-6 w-full ">
                         <div className="form-group mb-6 ">
                           <label className="formBlock	"> Start Date</label>
                           <DatePicker
                             showIcon
                             dateFormat="dd/MM/yyyy"
                             className="formControl"
                             name= "start_date"
                            selected={startDate ? startDate: new Date(holi.start_date) }
                             onChange={(e) => { handlerDateChange(e,"start_date");
                             setStartDate(e);
                             
                           }}
                           minDate={today}
                           />
                           <div className="help-block">{error.startDate?error.startDate:''}</div>
                         </div>
                       </div>
                      <div className="md:w-1/2 w-full ">
                         <div className="form-group mb-6 ">
                           <label className="formBlock	"> End Date</label>
                           <DatePicker
                             showIcon
                             dateFormat="dd/MM/yyyy"
                             className="formControl"
                             name = "end_date"
                             selected = {endDate ? endDate : new Date(holi.end_date)}
                             onChange={(e) =>  handlerDateChange(e,"end_date")}
                             minDate={startDate ? startDate : today}
                           />
                           <div className="help-block">{error.endDate ? error.endDate:''}</div>
                         </div>
                       </div>
                       <div className="md:w w-full  ">
                         <div className="w-full mt-4">
                           <div className="form-group pt-4 pb-3 flex justify-center ">
                             <PrimaryButton
                               Btnclass="btn save mr-2"
                               btnText="Update"
                               onClick={(e) => handlerSave(e, true, close, holi)}
                             />
                             <PrimaryButton
                              Btnclass="btn cancel "
                               btnText="Cancel"
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                   </form>
                      </div>
                    </div>
                  )}
                </Popup>
                <Popup
                  open={openDelete}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-trash"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Delete</h3>
                      </div>
                      <div className="content">
                        <p>Are you sure you want to delete this {holi.name}holiday ?</p>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" onClick={(e) => handlerDelete(e, holi.id, close)}>Yes</button>
                            <button
                              type="button"
                              onClick={() => close()}
                              className="btn cancel "
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup> 
                </>:
                
                <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-eye" />
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <HolidayDetail holidayDetails={holi} />
            
                    </div>
                  )}
                </Popup>
              }
              </td> */}
                    </tr>
                  );
                })}
              </>
              : <center>{noData}</center>}
            </tbody>
          </table>
          <Pagination page={page}
            pages={pages}
            changePage={setPage}
            decrementPage={decrementPage}

          />
        </div>
      </div>
    </>

  );
};

export default PublicHoliday;
const HolidayDetail = ({ holidayDetails }) => {
  return (
    <>
      <div className="header">
        {" "}
        <h3>Holiday Details</h3>
      </div>
      <div className="content">
        <div className="w-full">
          <div className="mb-3">
            <h3 className="text-[14px]">
              <span className="w-36 inline-block">  Holiday Name:</span>
              <span className=" text-gray font-medium pl-2">
                {holidayDetails?.name}
              </span>
            </h3>
          </div>

          <div className="mb-3">
            <h3 className="text-[14px]">
              <span className="w-36 inline-block">Date From:</span>
              <span className=" text-gray font-medium pl-2">
                {moments(holidayDetails?.start_date).format('DD-MM-YYYY')}
              </span>
            </h3>
          </div>
          <div className="mb-3">
            <h3 className="text-[14px]">
              <span className="w-36 inline-block"> Date To:</span>
              <span className=" text-gray font-medium pl-2">
                {moments(holidayDetails?.end_date).format('DD-MM-YYYY')}
              </span>
            </h3>
          </div>
        </div>
      </div>

    </>
  );
};

const ExcelData = ({ tableData, title, file, close, setOpenExcel, getAllHoliday,
  selectedYear,
  page, setOpen, closeModal }) => {
  const [importHolidayCsv, { data: importHolidayCsvRespo, isSuccess: isSuccessImport, isError: isErrorImport, error: apiError, isLoading: isLoadingImport }] = useImportHolidayCsvMutation();
  const toastId = useRef(null);
  const [statusModel, setStatusModel] = useState(false);
  let navigation = useNavigate();
  const handlerFileSubmit = (e) => {

    e.preventDefault();
    const formData = new FormData();
    const blob = new Blob([file], { type: "text/csv" });
    formData.append("csvfile", blob);
    importHolidayCsv(formData);
  };

  useEffect(() => {
    if (isLoadingImport) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccessImport) {
      // getUpdateHoliday();
      toast.update(

        toastId.current,
        ("Bank Holiday data added successfully!",
        {
          render: "Bank Holiday data added successfully!",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );

      getAllHoliday({ location_id: 1, start_year: selectedYear, page: page });
      close();
      setOpen(close);
    }
    if (isErrorImport) {

      const toastMessage =
        // apiError.data.message
        //   ? apiError.data.message
        //   :
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isLoadingImport, isSuccessImport, isErrorImport]);

  useEffect(() => {
    if (statusModel) {
      setOpen(close);

    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[statusModel]);

  return (
    <>

      <div className="header">
        {" "}
        <h3>{title}</h3>
      </div>
      <div className="content">
        <div className="w-full">
          <div className="view_tbl_bdr">
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive view_tbl_exp">
                  <table className="table file_preview_tbl">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        {
                          tableData?.file_columns?.length > 0
                            ?
                            tableData?.file_columns?.map((cols, index) => (
                              <th scope="col" key={index}>{cols}</th>
                            ))
                            :
                            <div className="flex justify-center items-center h-screen">
                              <span className="text-2xl text-gray-500">No data found</span>
                            </div>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tableData?.file_rows?.length > 0
                          ?
                          tableData?.file_rows?.map((file_row, index) => {
                            let colVals = Object.values(file_row);
                            return (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                {
                                  colVals.map((colVal, idx) => {
                                    return (<td key={idx}>{colVal}</td>);
                                  })
                                }
                              </tr>
                            );
                          })
                          :
                          ''
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w w-full  ">
          <div className="w-full mt-4">
            <div className="form-group pt-4 pb-3 flex justify-center ">
              <button className='btn-md btn-add mr-2 mb-2 sm:mb-0' id="pbHolidaySave" onClick={(e) => handlerFileSubmit(e)}> Save </button>
              <button className="btn cancel" id="pbHolidayCancel" onClick={() => {
                close();
                setOpenExcel();
                setOpen(close);
              }}> Cancel </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );

};

