/* eslint-disable */
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import UserPermissions from "../../settings/Permissions/userPermissions"

const PermissionTable = ({ setIsEdit, roles, getRolePermissions, isPermissionsSuccess, isPermissionsError, permissionError, setIsEditForm, setRoleName, setRoleId }) => {

  const permissionsSelector = useSelector((state) => state.persistedReducer.userPermissionsStates.userPermissions);

 const checkCreatePermissionModule = permissionsSelector?.find((obj) => obj?.module_name === "Permissions" && obj?.module_permission_name === 'Create');
 const companyCreateUserPermissions =  checkCreatePermissionModule?.status ? checkCreatePermissionModule.status: false; 
 const checkUpdatPermissionModule =  permissionsSelector?.find((obj) => obj?.module_name === "Permissions" && obj?.module_permission_name === 'Update') ;
 const companyUpdateUserPermissions =  checkUpdatPermissionModule?.status ? checkUpdatPermissionModule.status: false; 


  const handlePermissions = (id, name, roleName, roleId) => {
    if (name === "edit") {
      setIsEditForm(false);
      setRoleName(roleName);
      setRoleId(roleId);
    } else if (name === "view") {
      setIsEditForm(true);
      setRoleName(roleName);
      setRoleId(roleId);
    }
    getRolePermissions(id);

  };
  useEffect(() => {
    if (isPermissionsSuccess) {
      setIsEdit(true);
    }
    if (isPermissionsError) {
      const toastMessage = permissionError?.data?.message
        ? permissionError?.data?.message : "Something went wrong";
      toast.error({
        render: toastMessage,
        type: "error",
        autoClose: 2000,
        isLoading: false
      });
    }

  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isPermissionsSuccess, isPermissionsError]);
  return (
    <div className="overflow-x-auto w-full whitespace-nowrap">
      <table className="table-auto table  w-full">
        <thead className="thead-dark ">
          <tr>
            <th className=" p-2">S.No</th>
            <th className=" p-2">Role</th>
            <th className=" p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {roles?.map((role, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{role?.role_name}</td>
              <td>
                <button
                  onClick={() => handlePermissions(role?.id, "view", role?.role_name)}
                  className="actionBtn"
                >
                  <i key={0} className="fa fa-eye" />
                </button>
                {(companyCreateUserPermissions || companyUpdateUserPermissions) &&
                  <button id="PermissionsTableEdit"
                    onClick={() => handlePermissions(role?.id, "edit", role?.role_name, role?.id)}
                    className="actionBtn"
                  >
                    <i key={1} className="fa fa-pencil" />
                  </button>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PermissionTable;
