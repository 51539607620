
/* eslint-disable camelcase */
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    auditorsLists: [],
    auditorSelectedCompany: ""
};

export const auditorsSlice = createSlice({
  name: "auditorsSlice",
  initialState,
  reducers: {
    auditorsListsAction: (state, action) => {
      state.auditorsLists = action.payload;
    },
    auditorSelectedCompanyAction: (state, action) => {
      state.auditorSelectedCompany = action.payload;
    }
  }
});

export const { auditorsListsAction, auditorSelectedCompanyAction } = auditorsSlice.actions;
// ? Export the auditorsSlice reducer to be included in the store.
export default auditorsSlice.reducer;
