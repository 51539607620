import {useEffect, useRef, useState} from "react";
import {FormInput} from "../../components/formInput";
import {useSelector} from "react-redux";
import PrimaryButton from "../../components/primaryButton";
import {
  useCreateCompanyCustomizationMutation,
  useCustomizationDetailsQuery,
  useResetCompanyStylesMutation
} from "../../services/companyApi";
import {toast} from "react-toastify";
const CompanyStyles = () => {
  //carrying out data from redux store
  const companyData = useSelector(
    (state) => state?.persistedReducer?.companySlice?.companyData?.companyData
  );
  const companyStyleData = useSelector(
    (state) => state?.persistedReducer?.companySlice?.companyStyleData
  );

  const [isSkip, setIsSkip] = useState(true);
  const {data} = useCustomizationDetailsQuery(
    {id: companyData?.id},
    {skip: isSkip, refetchOnMountOrArgChange: true}
  );
  const [resetCompanyStyles, {isSuccess: isSuccessReset}] =
    useResetCompanyStylesMutation();
  const [preview, setPreview] = useState();
  const [file, setFile] = useState("");

  const [formData, setFormData] = useState({
    top_panel_bar_color: "",
    font_color: "",
    font_style: "Arial",
    company_logo: "",
    platform_default_logo: "1"
  });
  const [
    createCompanyCustomization,
    {isSuccess: isSuccess, isError, isLoading}
  ] = useCreateCompanyCustomizationMutation();

  const handleChanges = (event) => {
    const {name, value} = event.target;
    setFormData({...formData, [name]: value});

    if (name === "payment_processor_email") {
      //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      //   const isValidEmail = emailRegex.test(value);
      //   setError({
      //     ...error,
      //     [name]: isValidEmail ? '' : 'Invalid email format'
      //   });
      // }
      // if(name === 'min_leave_days'){
      //   setError({
      //     ...error,
      //     [name]: value > 0 ? '' : 'Minimum leave days must be greater than zero'
      //   });
      // }
      // if(name === 'min_leave_hours'){
      //   setError({
      //     ...error,
      //     [name]: value > 0 ? '' : 'Minimum leave Hours must be greater than zero'
      //   });
    }
  };
  const handleUpload = async (event) => {
    if (event.target.files[0]) {
      var fileSize = Math.round(event.target.files[0].size / 1024);

      if (fileSize <= 5 * 1024) {
        setFile(event.target.files[0]);
        setPreview(URL.createObjectURL(event.target.files[0]));
      } else {
        alert("Please select a file size less than 5kb.");
      }
    }
  };
  const handleReset = () => {
    resetCompanyStyles({id: companyData?.id});
  };

  const handleSave = (e) => {
    e.preventDefault();
    let payload = {
      top_panel_bar_color: formData?.top_panel_bar_color,
      company_logo: file,
      platform_default_logo: formData?.platform_default_logo,
      font_color: formData?.font_color,
      font_style: formData?.font_style,
      company_id: companyData?.id
    };
    createCompanyCustomization(payload);
  };
  const toastId = useRef();
  useEffect(() => {
    if (companyStyleData) {
      setFormData({
        ...formData,
        top_panel_bar_color: companyStyleData?.top_panel_bar_color,
        company_logo: companyStyleData.custom_logo_url,
        platform_default_logo: companyStyleData?.platform_default_logo,
        font_color: companyStyleData?.font_color,
        font_style: companyStyleData?.font_style
      });
    }
  }, [companyStyleData]);
  useEffect(() => {
    if (isSuccess || isSuccessReset) {
      setIsSkip(false);
      toast.dismiss(toastId.current);
      toast.success("Customization added successfully.", {
        render: data?.data?.message
          ? data.data.message
          : "Customization added successfully.",
        type: "success",
        autoClose: 8000,
        isLoading: false,
        theme: "colored"
      });
    }
  }, [isSuccess, isError, isLoading, isSuccessReset]);
  return (
    <>
      <div className="general-settings">
        <div className="heading_title px-4 flex justify-between items-center py-4">
          <div className="w-full lg:w-2/3 pr-0">
            <h3 className="font-semibold text-lg">Set Company Style</h3>
          </div>
          <PrimaryButton
            Btnclass="btn save mr-2"
            buttonID="save"
            onClick={() => {
              //   if (!error?.payment_processor_email) {
              handleReset();
              //   }
            }}
            btnText="Reset Default"
          />
        </div>
        <div className="p-4">
          <div className="block">
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8 flex-wrap md:flex-nowrap">
                <div className="md:w-1/2 w-full mb-2">
                  <h3>
                    Want Default Logo{" "}
                    <span className="relative -top-1 secondry-text text-[17px]">
                      *
                    </span>
                  </h3>
                </div>
                <div className="md:mb-0 md:w-1/2 w-full">
                  <FormInput
                    inputType="checkbox"
                    inputName="font_color"
                    inputID="font_color"
                    checked={
                      formData?.platform_default_logo === "1" ? true : false
                    }
                    handlerChange={() => {
                      setFormData((prev) => ({
                        ...prev,
                        platform_default_logo:
                          formData?.platform_default_logo === "1" ? "0" : "1"
                      }));
                    }}
                  />
                </div>
                <div className="help-block text-red-700 mt-2">
                  {/* {error?.min_leave_hours} */}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8 flex-wrap md:flex-nowrap">
                <div className="md:w-1/2 w-full mb-2">
                  <h3>
                    Top Menu Color Code{" "}
                    <span className="relative -top-1 secondry-text text-[17px]">
                      *
                    </span>
                  </h3>
                </div>
                <div className="md:mb-0 md:w-1/2 w-full">
                  <FormInput
                    inputType="text"
                    inputName="top_panel_bar_color"
                    inputID="top_panel_bar_color"
                    value={formData?.top_panel_bar_color}
                    handlerChange={handleChanges}
                  />
                </div>
                <div className="help-block text-red-700 mt-2">
                  {/* {error.payment_processor_email} */}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 pr-0">
              <div className="flex items-center mb-8 flex-wrap md:flex-nowrap">
                <div className="md:w-1/2 w-full mb-2">
                  <h3>
                    Font Color Code{" "}
                    <span className="relative -top-1 secondry-text text-[17px]">
                      *
                    </span>
                  </h3>
                </div>
                <div className="md:mb-0 md:w-1/2 w-full">
                  <FormInput
                    inputType="text"
                    inputName="font_color"
                    inputID="font_color"
                    value={formData?.font_color}
                    handlerChange={handleChanges}
                  />
                </div>
                <div className="help-block text-red-700 mt-2">
                  {/* {error?.min_leave_hours} */}
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="relative">
                <label className="formBlock	">
                  {" "}
                  Upload Logo to replace Schedulize Logo
                </label>
                <div className="relative">
                  <div className="imageUpload">
                    {preview ? (
                      <img
                        style={{height: 80, width: 80}}
                        src={preview}
                        className="rounded-lg"
                      />
                    ) : formData?.company_logo ? (
                      <>
                        <img
                          style={{height: 80, width: 80}}
                          src={formData?.company_logo}
                          className="rounded-lg"
                        />
                      </>
                    ) : (
                      <img src="/images/no-image1.jpg" className="rounded-lg" />
                    )}
                    {/* } */}
                    <div className="file-upload">
                      <input
                        type="file"
                        onChange={handleUpload}
                        id="fileUpload"
                        // disabled={(role_id == 4 && updatePermissionStatus)
                        //   ? false : (role_id !== 4 && updatePermissionStatus) ? false : true}
                      />{" "}
                      <i className="fa fa-file-image" />
                    </div>
                  </div>
                </div>
                <div className="help-block" />
              </div>
            </div>
          </div>
          {/* {generalSettingsUpdatePermissions[0]?.status === true && ( */}
          <div className="w-full mt-8">
            <div className="form-group pt-4 pb-3 flex">
              <PrimaryButton
                Btnclass="btn save mr-2"
                buttonID="save"
                onClick={(e) => {
                  //   if (!error?.payment_processor_email) {
                  handleSave(e);
                  //   }
                }}
                btnText="Save"
              />
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default CompanyStyles;
