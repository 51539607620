/* eslint-disable no-unused-vars */

import React from "react";
import { Link } from "react-router-dom";
import moment, * as moments from "moment";

export function EmployeeBirthdayThisWeek({ thisWeekBirthday, tableNames }) {
  return (
    <div className="overflow-x-auto w-full whitespace-nowrap">
      <table className="table-auto table  w-full ">
        <thead className="thead-dark ">
          <tr>
            {tableNames?.map((name, index) => {
              return (<th key={index} className="w-50">{name}</th>);
            })}
          </tr>
        </thead>
        <tbody>
          {thisWeekBirthday?.length>0 ?
            thisWeekBirthday.map((thisWeek, index) => {

              let fullname =  thisWeek?.first_name + " " + thisWeek?.last_name;
              return (
                <tr key={index}>
                  <td>
                    <Link to={`/employees/detail/${thisWeek?.id}`}  className="listImage flex items-center">
                      {fullname}
                    </Link>
                  </td>
                  <td>
                    {moment(thisWeek?.dob).isValid()
                      ? moment(thisWeek?.dob).format("DD MMM")
                      : 'N/A'}
                  </td>
                  {thisWeek?.days_or_hours && <td>{thisWeek?.days_or_hours}</td>}
                </tr>
              );
            })
          :
          <tr>
            <td colSpan="12" className="text-center mt-3 text-red-700 "> 
            <div className=" flex justify-center items-center  ">
            <p className="mt-5 pt-2">
            No employee birthdays this week.
            </p>
            </div>

            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  );
}
