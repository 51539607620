/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import DatePicker from "react-datepicker";
export function SearchField({ placeholderText, onChange, searchId}) {
  return (
    <>
      <input
        type="text"
        className="formControl w-full"
        placeholder={placeholderText}
        onChange={onChange}
        id={searchId}
      />
    </>
  );
}

export function SelectDepartment({ optionText }) {
    return (
      <>
       <select className="formControl">
         <option> {optionText}</option>
        </select>
      </>
    );
  }

  export function DateRangeFiled({ }) {
    const [ startDate, setStartDate ] = useState(new Date());

    const [ dateRange, setDateRange ] = useState([ null, null ]);
    const [ startDate2, endDate ] = dateRange;
    return (
      <>
       <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        id="dateRange"
        dateFormat="dd/MM/yyyy"
        className="formControl"
        placeholderText="Select Date Range"
        onChange={(update) => {
          setDateRange(update);
        }}
        isClearable={true}
/>
      </>
    );
  }


 