import { useDispatch, useSelector } from "react-redux";
import { useGetEmployeeAllowanceQuery } from "../../services/employeeApi";
import { useEffect } from "react";
import { timeInLieuDataAction } from "../../redux/EmployeeSlice/employeeSlice";

const TimeInLieu = ({ employeeInfo }) => {

    const { data: data, isSuccess } = useGetEmployeeAllowanceQuery(employeeInfo?.id);

    const previousYearTimeInLieuData = useSelector((state) => state.persistedReducer.employeeSlice?.timeInLieuData?.data?.previousYear?.timeInLieuData);
    const currentYearTimeInLieuData = useSelector((state) => state.persistedReducer.employeeSlice?.timeInLieuData?.data?.currentYear?.timeInLieuData);
    const nextYearTimeInLieuData = useSelector((state) => state.persistedReducer.employeeSlice?.timeInLieuData?.data?.nextYear?.timeInLieuData);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isSuccess) {
          dispatch(timeInLieuDataAction(data));
        }
      }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess]);

  return (
    <>
    <div className="lg:p-7 p-4 timeInLieuPage">
    <div className="w-full mb-8">
        <p className="font-semibold text-leading ">
        Previous year
      </p>
      <div className="currentYear ">
        <form>
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-1/3 w-full  lg:pr-6">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center lg:text-">
                <div className="text-center">
                  <p>Total Authorised In Hours</p>
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-base border p-2 text-center primary-text bg-white mx-auto">
                  <span style={{marginBottom:"0"}} >{previousYearTimeInLieuData?.totalDurationInHours.toFixed(2)}</span>
                  </div>                 
                </div>
                <span className="fa fa-plus lg:mt-8 mt-4 xl:mr-8 3xl:mr-16 md:mr-4" />
              </div>
            </div>
            <div className="sm:w-1/3 w-full  lg:pr-6">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                <div className="text-center">
                  <p>Time In Lieu Used In Hours</p>
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-base border p-2 text-center primary-text bg-white mx-auto">
                  <span>{previousYearTimeInLieuData?.timeInLieuUsedInHours.toFixed(2)}</span>
                  </div>
                </div>
                <span className=" lg:mt-8 mt-4 lg:mr-8 xl:mr-6 md:mr-4 text-3xl">
                  =
                </span>
              </div>
            </div>
            <div className="w-auto ml-auto mr-auto md:ml-0 md:mr-0">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                <div className="text-center">
                  <p>Remaining Time In Lieu</p>
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-white text-xl border p-2 text-center  bg-secondry rounded mx-auto">
                    <span>{previousYearTimeInLieuData?.remainingTimeInLieu.toFixed(2)}</span>
                    </div>
                </div>
                
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="w-full mb-8">
        <p className="font-semibold text-leading ">
        Current year
      </p>
      <div className="currentYear ">
        <form>
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-1/3 w-full  lg:pr-6">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center lg:text-">
                <div className="text-center">
                  <p>Total Authorised In Hours</p>
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-base border p-2 text-center primary-text bg-white mx-auto">
                  <span>{currentYearTimeInLieuData?.totalDurationInHours.toFixed(2)}</span>
                  </div>
                </div>
                <span className="fa fa-plus lg:mt-8 mt-4 xl:mr-8 3xl:mr-16 md:mr-4" />
              </div>
            </div>
            <div className="sm:w-1/3 w-full  lg:pr-6">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                <div className="text-center">
                  <p>Time In Lieu Used In Hours</p>                 
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-base border p-2 text-center primary-text bg-white mx-auto">
                  <span>{currentYearTimeInLieuData?.timeInLieuUsedInHours.toFixed(2)}</span>
                  </div>
                </div>
                <span className=" lg:mt-8 mt-4 lg:mr-8 xl:mr-6 md:mr-4 text-3xl">
                  =
                </span>
              </div>
            </div>
            <div className="w-auto ml-auto mr-auto md:ml-0 md:mr-0">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                <div className="text-center">
                  <p>Remaining Time In Lieu</p>
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-white text-xl border p-2 text-center  bg-secondry rounded mx-auto">
                    <span>{currentYearTimeInLieuData?.remainingTimeInLieu.toFixed(2)}</span>
                    </div>
                </div>                
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="w-full">
        <p className="font-semibold text-leading ">
        Next year
      </p>
      <div className="currentYear ">
        <form>
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-1/3 w-full  lg:pr-6">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center lg:text-">
                <div className="text-center">
                  <p>Total Authorised In Hours</p>
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-base border p-2 text-center primary-text bg-white mx-auto">
                  <span>{nextYearTimeInLieuData?.totalDurationInHours.toFixed(2)}</span>
                  </div>
                </div>
                <span className="fa fa-plus lg:mt-8 mt-4 xl:mr-8 3xl:mr-16 md:mr-4" />
              </div>
            </div>
            <div className="sm:w-1/3 w-full  lg:pr-6">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                <div className="text-center">
                  <p>Time In Lieu Used In Hours</p>
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-base border p-2 text-center primary-text bg-white mx-auto">
                  <span>{nextYearTimeInLieuData?.timeInLieuUsedInHours.toFixed(2)}</span>
                  </div>
                </div>
                <span className=" lg:mt-8 mt-4 lg:mr-8 xl:mr-6 md:mr-4 text-3xl">
                  =
                </span>
              </div>
            </div>
            <div className="w-auto ml-auto mr-auto md:ml-0 md:mr-0">
              <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                <div className="text-center">
                  <p>Remaining Time In Lieu</p>
                  <div className="mt-2 w-24 h-16 font-bold flex justify-center items-center text-white text-xl border p-2 text-center  bg-secondry rounded mx-auto">
                    <span>{nextYearTimeInLieuData?.remainingTimeInLieu.toFixed(2)}</span>
                    </div>
                </div>
               
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </div>
    </>
  );
};

export default TimeInLieu;
