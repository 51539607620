/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger*/
import { useRef } from "react";
import {useEffect, useState} from "react";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { allUserPermissions } from "../../../redux/AuthSlice/authSlice";
import { useDispatch } from "react-redux";

const EditPermissions = ({isEdit, setIsEdit, rolePermissions, isEditForm, roleName, updateRolePermissions, isRoleError, isRoleLoading, rolesUpdateError, isRoleSuccess, roleId, getUserPermissions, userPermissions, isSuccess, companyUpdateUserPermissions}) => {
  const [ modulePermission, setModulePermissions ] = useState(rolePermissions);
  const toastId = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if(userPermissions?.permissions.length > 0){
      dispatch(allUserPermissions(userPermissions?.permissions));
    }
    // 
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ]);
  useEffect(() => {
    setModulePermissions(rolePermissions);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ rolePermissions ]);
  useEffect(() => {
    if(isRoleLoading){
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if(isRoleSuccess) {
      toast.dismiss(toastId.current);
      getUserPermissions();
      toast.success("Permissions update Successfully!",
        {
          render: "Permissions update Successfully!",
          type: "success",
          autoClose: 8000,
          isLoading: false
        }
      );
     
    }
    if (isRoleError) {
      toast.dismiss(toastId.current);
      const toastMessage = 
      rolesUpdateError.data.message
        ? rolesUpdateError.data.message
        : "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isRoleError, isRoleLoading, isRoleSuccess ]);

  const handleCheckboxChange = (moduleName, name) => {
    const updatedData = modulePermission.map((module) => ({
      ...module,
      data: module.data.map((permission) => ({
        ...permission,
        status:
          permission.module_permission_info.module_permission_name === name &&
          module.title === moduleName
            ? !permission.status
            : permission.status
      }))
    }));
    setModulePermissions(updatedData);
  };

  const handleUpdatePermission = () => {
    updateRolePermissions({roleId:roleId,  payload: modulePermission});
    setIsEdit(false);
  };

  return (
    <Popup
      open={isEdit}
      onClose={() => setIsEdit(false)}
      closeOnDocumentClick={false}
      modal
      nested
    >
      {(close) => (
        <div className="modal">
          <button
            className="close"
            id="editPermissions"
            onClick={() => {
              setIsEdit(false);
              close();
            }}
          >
            &times;
          </button>
          <div className="header">
            <h3 className=" font-semibold text-lg">{roleName}</h3>
            </div>
            <div className="content">      
              {modulePermission?.filter((permission) => permission?.title !== 'Super Admin Dashboard')?.map((permission, moduleIndex) => (
                  <div key={moduleIndex} className="mb-5 pb-5 border-b ">
                    <h2 className="text-base font-semibold mb-3">
                      {permission?.title}
                    </h2>
                    <div className="flex flex-wrap">
                      {permission?.data?.map((action, index) => (
                        <label
                          key={index}
                          className="flex items-center pr-16 cursor-pointer text-[14px] mb-3"
                          
                        >
                          <input
                            type="checkbox"
                            id="editPermissionsCheck"
                            className={isEditForm ? "form-checkbox w-5 h-5 mr-2" : "form-checkbox w-5 h-5 mr-2 border border-gray-300 rounded pointer-events-none"}
                            disabled={isEditForm}
                            checked={action?.status}
                            onChange={() =>
                              handleCheckboxChange(
                                permission?.title,
                                action?.module_permission_info
                                  ?.module_permission_name
                              )
                            }
                          />
                          {
                            action?.module_permission_info
                              ?.module_permission_name
                          }
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
                {isEditForm === false ? (
                 <>
                  <div className="w-full flex justify-center">
                    {companyUpdateUserPermissions && 
                  <button
                  id="editPermissionsUpdate"
                    onClick={() => handleUpdatePermission()}
                    className="btn btn-md my-4 mb-10 bg-primary"
                  >
                    Update
                  </button>
                  }
                  </div>
                 </>
                ) : null}
              </div>
          
          </div>
       
      )}
    </Popup>
  );
};

export default EditPermissions;
