/* eslint-disable */
import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useDeleteNotesMutation, useUpdateNotesMutation } from "../../services/notesApi";
import { toast } from "react-toastify";

function EditNotesEmployee({ notesNote, notesId, employeeInfo, getNotes, activeEmployeeUpdatePermissions, myprofileReadOnly = false }) {

  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [notesData, setNotesData] = useState();
  const [updateNotes, { isSuccess: isUpdateNotesSuccess, isError: isUpdateError, error }] = useUpdateNotesMutation();
  const [deleteNotes, { isSuccess: isDeleteNotesSuccess, isError: isDeleteError, error: deleteError }] = useDeleteNotesMutation();
  const closeModal = () => {
    setIsEdit(false);
  };
  useEffect(() => {
    setNotesData(notesNote);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[notesNote]);
  useEffect(() => {
    if (isUpdateNotesSuccess) {
      document?.getElementById("editClose")?.click();
      getNotes(employeeInfo?.id);
      toast.success("Notes edited successfully.",
        {
          render: "Notes edited successfully.",
          // position: "top-center",
          position:"top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        }
      );
    }
    if (isUpdateError) {

      document?.getElementById("editClose")?.click();
      toast.error(error.data.message ? error.data.message : "Something went wrong.",
        {
          render: error?.data?.message ? error?.data?.message : "Something went wrong.",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        }
      );
    }
    if (isDeleteNotesSuccess) {
      getNotes(employeeInfo?.id);
      document?.getElementById("delete")?.click();
      toast.success("Notes Delete Successfully",
        {
          render: "Notes Delete Successfully",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        }
      );
    }
    if (isDeleteError) {
      document?.getElementById("delete")?.click();
      toast.error(deleteError?.data?.message ? deleteError?.data?.message : "Something went wrong",
        {
          render: deleteError?.data?.message ? deleteError?.data?.message : "Something went wrong",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        }
      );
    }

  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isUpdateNotesSuccess, isUpdateError, isDeleteNotesSuccess, isDeleteError]);
  const handelEditNote = () => {
    updateNotes({ id: employeeInfo?.id, note: notesData, noteId: notesId });
  };
  const handelDelete = () => {
    deleteNotes({ id: employeeInfo?.id, noteId: notesId });
  };
  return (
    <>
      {((activeEmployeeUpdatePermissions[0]?.status === true) || myprofileReadOnly) &&
        <Popup
          open={isEdit}
          onClose={() => closeModal()}
          closeOnDocumentClick={false}
          trigger={
            <button
              className="actionBtn  mr-2"
              onClick={() => {
                setIsEdit((o) => !o);
              }}
            >
              <span className="fa fa-edit" />
            </button>
          }
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <button
                className="close"
                id="editClose"
                onClick={() => {
                  closeModal();
                  close();
                }}
              >
                &times;
              </button>
              <div className="header">
                <h3>Edit Note</h3>
              </div>
              <div className="content">
                <>
                  <div className="flex flex-wrap ">
                    <div className="  w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	"> Notes</label>
                        <textarea
                        id="notes"
                          onChange={(e) => setNotesData(e.target.value)}
                          value={notesData}
                          className="formControl h-24"
                        />
                        <div className="help-block" />
                      </div>
                    </div>

                    <div className="lg:w w-full  ">
                      <div className="w-full mt-4">
                        <div className="form-group pt-4 pb-3 flex justify-center ">
                          <button
                            onClick={handelEditNote}
                            className="btn save mr-2"
                            id="save"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            id="cancel"
                            onClick={() => close()}
                            className="btn cancel  "
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </Popup>}
      {((activeEmployeeUpdatePermissions[0]?.status === true) || myprofileReadOnly) &&
        <Popup
          open={open}
          onClose={() => closeModal()}
          closeOnDocumentClick={false}
          trigger={
            <div onClick={() => setOpen((o) => !o)} className="actionBtn">
              <i className="fa fa-trash" />
            </div>
          }
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <button
                className="close"
                id="delete"

                onClick={() => {
                  closeModal();
                  close();
                }}
              >
                &times;
              </button>
              <div className="header">
                {" "}
                <h3>Deleted</h3>
              </div>
              <div className="content">
                <p>Are you sure you want to delete this ?</p>
                <div className="w-full mt-8">
                  <div className="form-group pt-4 pb-3 flex justify-center ">
                    <button className="btn save mr-2" id="yesDelete" onClick={handelDelete}>Yes</button>
                    <button
                      type="button"
                      id="noDelete"
                      onClick={() => close()}
                      className="btn cancel "
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>}
    </>
  );
}

export default EditNotesEmployee;
