/* eslint-disable no-debugger*/
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';
export const approverListAPI = createApi({
    reducerPath: "approverListAPI",
    baseQuery: fetchBaseQuery({
      baseUrl: ApiUrl
 }),
endpoints: (builder) => ({
getApproverListAPI:builder.mutation({
      query: (payload) => ({
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
    getDepartmentList:builder.mutation({
      query: () => ({
        url: `/department?page=1&status=1&limit=200`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
    getLeaveApproveList:builder.mutation({
      query: (payload) => ({
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })

    }),
    changesStatusApporve:builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
       return {
         url: `leaves/change/status/${id}`,
         method: 'PUT',
         body,
         headers: {
           'Content-type': 'application/json; charset=UTF-8',
           Authorization:  localStorage.getItem('accessToken')
         }
       };
     }

    }),
    changesBulkStatusApporve:builder.mutation({
      query: (payload) => {
       return {
         url: `leaves/change/bulk/status`,
         method: 'PUT',
         body: payload,
         headers: {
           'Content-type': 'application/json; charset=UTF-8',
           Authorization:  localStorage.getItem('accessToken')
         }
       };
     }

    }),
    filterEmployeeLeaveApporve:builder.mutation({
      query: (payload) => ({
        url: `employee/?first_name=${payload.firstName}&leave_approver=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })

    }),
    employeeOverTimeApporve:builder.mutation({
      query: (payload) => ({
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })

    }),
    changesStatusOverTimeApporve:builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
       return {
         url: `overtimes/change/status/${id}`,
         method: 'PUT',
         body,
         headers: {
           'Content-type': 'application/json; charset=UTF-8',
           Authorization:  localStorage.getItem('accessToken')
         }
       };
     }

    }),
    employeeExpenseApprover:builder.mutation({
      query: (payload) => ({
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })

    }),
    approvedOvertimeDelete :builder.mutation({
      query: (id) => ({
        url: `overtimes//${id}`,
        method: 'Delete',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
    changesStatusExpenseApporve:builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
       return {
         url: `/expenses/update/${id}`,
         method: 'PUT',
         body,
         headers: {
           'Content-type': 'application/json; charset=UTF-8',
           Authorization:  localStorage.getItem('accessToken')
         }
       };
     }
    }),
    bulkExpenseApporve:builder.mutation({
      query: (payload) => {
       return {
         url: `/expenses/change/status`,
         method: 'PUT',
         body: payload,
         headers: {
           'Content-type': 'application/json; charset=UTF-8',
           Authorization:  localStorage.getItem('accessToken')
         }
       };
     }
    }),
    bulkOvertimeApporve:builder.mutation({
      query: (payload) => {
       return {
         url: `/overtimes/change/bulk/status`,
         method: 'PUT',
         body: payload,
         headers: {
           'Content-type': 'application/json; charset=UTF-8',
           Authorization:  localStorage.getItem('accessToken')
         }
       };
     }
    }),
    employeeLeaveCountOndate:builder.mutation({
      query: (payload) => ({
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })

    })

}),
onError: (error
  //  { dispatch, getState }
   ) => {
  if (error.status === 401) {
    alert("Invalid Token Error");
    // Handle 401 Unauthorized error here
    // For example, you can dispatch an action to update the auth state:
    // dispatch(authActions.setAuthenticated(false));
    // Or show a notification/alert to the user:
    // showNotification("You are not authorized to perform this action.");
  }
}
});

export const { useApprovedOvertimeDeleteMutation, useGetApproverListAPIMutation, useGetDepartmentListMutation, useGetLeaveApproveListMutation, useChangesStatusApporveMutation, useFilterEmployeeLeaveApporveMutation, 
  useEmployeeOverTimeApporveMutation,
  useChangesBulkStatusApporveMutation,
  useChangesStatusOverTimeApporveMutation,
  useChangesStatusExpenseApporveMutation, 
  useBulkExpenseApporveMutation,
  useEmployeeExpenseApproverMutation,
  useEmployeeLeaveCountOndateMutation,
  useBulkOvertimeApporveMutation
} = approverListAPI;