import moment from "moment";
import { FormTextarea } from "../../../components/formInput";
import { useSelector } from 'react-redux';
import { useState } from "react";
// import PrimaryButton from "../../../components/primaryButton";

const ExpenseDetails = ({expense}) => {
  const fileName = expense?.expenseFiles[0]?.file_name;
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const [ isImageClicked, setImageClicked ] = useState(false);
  const handleImageClick = () => {
    // Toggle the state when the image is clicked
    setImageClicked(!isImageClicked);
  };
    return (
        <>
        <div className="header">
          <h3 className="flex secondry-text">          
           Expense Details
          </h3>
        </div>
        <div className="content">
        <form>
          <div className="flex flex-wrap ">
          <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
              <label className="formBlock	">
                  Date
              </label>
              <input
              value={moment(expense?.start_date).format(formattedDate)}
              className="formControl"
              />
              <div className="help-block" />
              </div>
          </div>
          <div className="md:w-1/2  w-full">
              <div className="form-group mb-6 ">
              <label className="formBlock	">
                  Expense Type
              </label>
              <input className="formControl"
              value={expense?.expenseTypeInfo?.expense_name}
              />

              <div className="help-block" />
              </div>
          </div>
          <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
              <label className="formBlock	">Amount</label>
              <input
              className="formControl"
              value={expense?.amount}
              />
              <div className="help-block" />
              </div>
          </div>
          <div className="w-full ">
              <div className="form-group mb-6 ">
              <label className="formBlock	">
                  Description{" "}
              </label>
              <FormTextarea
              disabled
              value={expense?.reason}
               />
              <div className="help-block" />
              </div>
          </div>
          <div className="md:pr-6 w-full">
              <div className="form-group mb-6 ">
              <p>
                <span className="font-medium">
                Attachments: <b>if this attachment is not visible, please download the file by clicking the attachment in the table section of Schedulize.</b><br />
                
                </span>{" "}
                        {fileName ? (
                          <div className="mt-4">
                            <img
                              src={fileName}
                              alt="Expense Image"
                              onClick={handleImageClick}
                              style={ isImageClicked ? { cursor: 'pointer', width: '800px', height: '500px', border: '1px solid grey', objectFit:'contain' } : { cursor: 'pointer', width: '150px', height: '88px', border: '1px solid grey', objectFit:'contain' } }
                            />
                            {/* <b> click on it to zoom.</b> */}
                          </div>
                        ) : (
                          "No File"
                        )}
              
              </p>
              <div className="help-block" />
              </div>
          </div>
          {/* <div className="lg:w w-full  ">
              <div className="w-full mt-4">
              <div className="form-group pt-4 pb-3 flex justify-center ">
              <button
              className="btn save mr-2"
              id="addExpenseSave"
              disabled
              >
              Next
              </button>
                  <PrimaryButton
                  btnText="Back"
                  //onClick={() => close()}
                  Btnclass="btn cancel"
                  buttonID="addExpenseCancel"
                  btntype="button"
                  disabled
                  />
              </div>
              </div>
          </div> */}
          </div>
        </form>
        </div>
                                    
      </>
    );
};
export default ExpenseDetails;