import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    leaveList : []
};

export const leaveListSlice = createSlice({
  name: "leaveListSlice",
  initialState,
  reducers: {
    leaveTypeInfo: (state, action) => {
      state.leaveList = action.payload;
    }
  }
});

export const { leaveTypeInfo } = leaveListSlice.actions;
export default leaveListSlice.reducer;