
import { useState } from "react";
import PrimaryButton from "../../components/primaryButton";
import Popup from "reactjs-popup";
import moment from "moment";
import { useSelector } from "react-redux";
import { FormSelect } from "../../components/formInput";
const ViewAbsence = ({ absence, allDepartmentListData, getEmployeeAllDayScheduleRespo, shouldShowReason }) => {

    const days = (absence?.total_duration / (getEmployeeAllDayScheduleRespo * 60)).toFixed(2);
    const [ open, setOpen ] = useState(false);
    const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
    const closeModal = () => {
      setOpen(false);
    };

    const handlerSave = (e) => {
        e.preventDefault();
          setOpen(true);
      };
    return (
        <>
        <div className="header">
            <span>
            <h3>Approved Leave Details</h3>
            </span>
          </div>
          
      <div className="stepIndicator">
        <ul>
          <li className="active"><span>1</span>Request</li>
          <li><span>2</span>Details</li>
        </ul>
      </div>
      <div className="content">
        <form>
          <div className="flex flex-wrap ">
          <div className=" w-full">
        <div className="form-group mb-6 ">
        <label className="formBlock	">
            Who for{" "}
            <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
        </label>
        <div className="flex flex-wrap ">
            <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0 w-full">
            
    <select className="formControl mr-2" id="editDepartment" name="department_id" defaultValue={absence?.department_id}
     //onChange ={(e) => handlerChange(e)}
     disabled
     >
            <FormSelect 
                optionValue={"0"}
                optionName={"Select Department"} />;

                {allDepartmentListData?.length > 0 ? allDepartmentListData?.map((departments, key) => { 
                    return   <FormSelect key={key} optionValue={departments?.value} optionName={departments?.label} />;
                }) : null}

            </select>

            
    
            {/* <div className="help-block text-red-700 mt-1">{error.errorDepartmentId ? error.errorDepartmentId :'' }</div> */}
            </div>
            <div className="md:w-1/2 w-full">
            
            <input className="formControl" value={absence?.employeeInfo?.first_name}/>
              {/* <div className="help-block text-red-700 mt-1">{error.errorEmployeeId ? error.errorEmployeeId :'' }</div> */}
            </div>
        </div>
        </div>
    </div>

          <div className=" w-full ">
            {shouldShowReason ? null :
          <div className="w-full ">
            <div className="form-group mb-4 ">
              <label className="formBlock ">Reason (optional) </label>
              <input value={absence?.reason} className="formControl" />
              <div className="help-block" />
            </div>
          </div>}
          <div className="form-group mb-4 ">
            <div className="flex">
            <div className='w-full'>
              <label className="formBlock">
                Select Date
                <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
              </label>
              <input
                className="formControl"
                value={`${moment(absence?.start_date).format(formattedDate)}`}
                />

            </div>
          </div>
        </div>        
      </div>
      <div className="lg:w w-full  ">
        <div className="w-full">
          <div className="form-group pt-4 pb-3 flex justify-center ">
            <PrimaryButton
              btnText="Next"
              Btnclass="btn save mr-2"
              btnype="button"
              buttonID="next"
              onClick={(e) => handlerSave(e)}
            />
            {/* <PrimaryButton
                  btnText="Back"
                  onClick={() => close()}
                  Btnclass="btn cancel"
                  buttonID="leaveCancel"
                  btntype="button"
                /> */}
          </div>
        </div>
      </div>
      <Popup
      open={open}
      onClose={() => closeModal()}
      closeOnDocumentClick={false}
      className="leavePopup"
      modal
      nested
    >
      {(close) => (
        <div className="modal" >
          <button
            className="close"
            id="requestLeaveClose"
            onClick={() => {
              closeModal();
              close();
            }}
          >
            &times;
          </button>
          <div style={{ maxHeight: "70vh", overflowY: "auto", paddingBottom:"24px" }}>
          <div className="stepIndicator">
            <ul>
              <li><span>1</span>Request</li>
              <li  className="active"><span>2</span>Details</li>
            </ul>
          </div>
          <div className="p-6" >
          <h3 className="text-lg primary-text mb-4">Approved Leave Details</h3> 
          <div>
            <div>
                <div
                  className="w-full flex justify-between pb-4  md:flex-nowrap flex-wrap "
                >
                  <div className="w-1/3">
                    <h3 className="text-base text-left pt-1">
                      {moment(absence?.start_date).format(formattedDate)}
                    </h3>
                  </div>
                  <div className=" md:w-2/3 w-full pt-2 md:pt-0 md:pl-4  ">
                    <div className="flex justify-between items-center w-full">
                      <input
                        className="formControl"
                        value={moment(
                            absence?.start_time,
                          "hh:mm:ss"
                        ).format("HH:mm")}
                      />
                      <div>to</div>

                      <input
                        className="formControl"
                        value={moment(
                            absence?.end_time,
                          "hh:mm:ss"
                        ).format("HH:mm")}
                      />

                    </div>
                    <div className="w-full mt-2">
                      <div className="timeDiv">
                        <span className="fa fa-clock secondry-text" />
                          <span>
                            <span className="secondry-text">
                            {Math.floor(absence?.total_duration / 60)}
                            </span>{" "}
                            Hours{" "}
                            <span className="secondry-text pl-2 pr-1">
                            {absence?.total_duration % 60}
                            </span>{" "}
                            Minutes
                          </span>
                      </div>
                    </div>
                  </div>
                </div>   
          </div></div>     
           
          </div>
          <div className="px-6 text-right pb-4">
            <h1 className="text-lg">Total: {Math.floor(absence?.total_duration  / 60)} hours {absence?.total_duration  % 60} minutes</h1>
            <p className="ml-10">Annual Leave</p>
            
          </div>
          <h1 className="p-2 pt-4 ml-2 text-lg border-t">Allowance Summary</h1>
  {absence?.days_or_hours === '1' ? (
          <p className="ml-4 text-lg">{Math.floor(absence?.total_duration  / 60)} hours {absence?.total_duration  % 60} minutes will be allocated as absence for {absence?.employeeInfo?.first_name}</p>
        ) : (
          <p className="ml-4 text-lg"> {days} days will be allocated as absence for {absence?.employeeInfo?.first_name}</p>
        )}
          </div>
          <div className="lg:w w-full  ">
            <div className="w-full mb-4">
              <div className="form-group pt-4 pb-3 flex justify-center ">
                {/* <PrimaryButton
                  btnText="Submit"
                  Btnclass="btn save mr-2"
                  btnype="button"
                  buttonID="leaveSave"
                  //onClick={(e) => handlerSubmit(e)}
                /> */}
                <PrimaryButton
                  btnText="Back"
                  onClick={() => close()}
                  Btnclass="btn cancel"
                  buttonID="leaveCancel"
                  btntype="button"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>

    </div>
  </form>
</div>
</>
    );
};

export default ViewAbsence;