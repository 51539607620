import { useEffect, useRef, useState } from "react";
import {useDeleteLeaveTypesMutation, useUpdateLeaveTypesMutation} from "../../services/leaveTypesApi";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { FormInput } from "../../components/formInput";
import PrimaryButton from "../../components/primaryButton";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Pagination from "../../components/inc/pagination";
import Popup from "reactjs-popup";

export const NonDeductibleLeaveType = ({LeaveTypesUpdatePermissions, LeaveTypesDeletePermissions, nonDeductibleLeaveType, deductibleLeaveType}) => {

  //Selector to carrying out data from redux
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const nondeductibleData = useSelector((state) => state?.persistedReducer?.leaveTypeSlice?.nondeductibleLeaveType);

  //Api calling
  const [updateLeaveTypes, { isSuccess: isSuccessUpdate, isError: isErrorUpdate, isLoading: isLoadingUpdate, error: apiUpdateError } ] = useUpdateLeaveTypesMutation();
  const [deleteLeaveTypes, { isSuccess: isSuccessDelete, isLoading: isLoadingDelete, isError: isErrorDelete, error: apiError } ] = useDeleteLeaveTypesMutation();

  //States
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const pageTerm = searchParams.get("page") || "";
  const [page, setPage] = useState(pageTerm ? pageTerm : 1);
  const [pages, setPages] = useState();
  const toastId = useRef(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [error, setError] = useState({
    nameError: "",
    colorError: "",
    statusError: "",
    typeError: "",
    iconError: ""
  });

  const [formData, setFormData] = useState({
    leave_name: "",
    companies_id: "",
    leave_type: "3",
    short_name: "assf",
    color: "",
    status: "",
    icon: "",
    type: "0",
    is_deductible: "0"
  });

  const closeModal = () => {
    setOpen(false);
    setFormData({
      leave_name: "",
      companies_id: "2",
      leave_type: "3",
      short_name: "assf",
      color: "",
      status: "",
      icon: "",
      type: "1"
    });
  };

  const leaveTypes = [
    { value: "", label: "", icon: "fa fa-umbrella-beach" },
    { value: "", label: "", icon: "fa fa-graduation-cap" },
    { value: "", label: "", icon: "fa fa-book" },
    { value: "", label: "", icon: "fa fa-truck-medical" },
    { value: "", label: "", icon: "fa fa-baby-carriage" },
    { value: "", label: "", icon: "fa fa-sun" },
    { value: "", label: "", icon: "fa fa-clock" },
    { value: "", label: "", icon: "fa fa-business-time" },
    { value: "", label: "", icon: "fa fa-list-check" },
    { value: "", label: "", icon: "fa fa-plane-departure" },
    { value: "", label: "", icon: "fa fa-handshake" },
    { value: "", label: "", icon: "fa fa-clipboard" },
    { value: "", label: "", icon: "fa fa-car" },
    { value: "", label: "", icon: "fa fa-house-user" },
    { value: "", label: "", icon: "fa fa-paw" },
    { value: "", label: "", icon: "fa fa-bed" },
    { value: "", label: "", icon: "fa fa-snowflake" },
    { value: "", label: "", icon: "fa fa-person" },
    { value: "", label: "", icon: "fa fa-person-dress" },
    { value: "", label: "", icon: "fa fa-basket-shopping" },
    { value: "", label: "", icon: "fa fa-cake-candles" },
    { value: "", label: "", icon: "fa fa-circle" },
    { value: "", label: "", icon: "fa fa-square" },
    { value: "", label: "", icon: "fa fa-triangle-exclamation" },
    { value: "", label: "", icon: "fa fa-user-doctor" },
    { value: "", label: "", icon: "fa fa-hand" },
    { value: "", label: "", icon: "fa fa-rotate" },
    { value: "", label: "", icon: "fa fa-spa" },
    { value: "", label: "", icon: "fa fa-star" },
    { value: "", label: "", icon: "fa fa-calendar-days" }
  ];
  const options = leaveTypes.map((leaveType) => ({
    value: leaveType.value,
    label: (
      <div>
        <i className={leaveType.icon} style={{ fontSize: "16px" }} />
        {leaveType.label}
      </div>
    ),
    icon: leaveType.icon
  }));

  const handleChange = (selectedOption) => {
    setFormData({ ...formData, ["icon"]: selectedOption?.icon });
    setSelectedIcon(selectedOption?.icon);
    if (selectedOption?.icon) {
      setError({ ...error, iconError: " " });
    }
  };

  const handlerChange = (e) => {
    e.preventDefault();

    const { name } = e.target;
    if (name === "leave_name") {
      if (name === "") {
        setError({ ...error, nameError: "please enter the name " });
      } else {
        setError({ ...error, nameError: " " });
      }
    }
    if (name === "color") {
      if (name === "") {
        setError({ ...error, colorError: "please choose Color " });
      } else {
        setError({ ...error, colorError: " " });
      }
    }
    if (name === "status") {
      if (name === "") {
        setError({ ...error, statusError: "please choose status " });
      } else {
        setError({ ...error, statusError: " " });
      }
    }
    if (name === "type") {
      if (name === "0") {
        setError({ ...error, typeError: "please choose type " });
      } else {
        setError({ ...error, typeError: " " });
      }
    }
    if (name === "icon") {
      if (name === "") {
        setError({ ...error, iconError: "please choose icon " });
      } else {
        setError({ ...error, iconError: " " });
      }
    }

    setFormData({ ...formData, [name]: e.target.value });
  };

  useEffect(
    () => {
      if (isLoadingDelete) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessDelete) {
        toast.dismiss(toastId.current);
        toast.success("Leave type deleted successfully", {
          render: "Leave type deleted successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false,
          theme: "colored"
        });
        nonDeductibleLeaveType(page);
      }
      if (isErrorDelete) {
        toast.dismiss(toastId.current);
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 10000,
          duplicate: false,
          isLoading: false
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isErrorDelete, isSuccessDelete, isLoadingDelete]
  );


  useEffect(
    () => {
      if (isLoadingUpdate) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessUpdate) {
        toast.dismiss(toastId.current);
        toast.success("Leave type updated successfully.", {
          render: "Leave type updated successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false,
          theme: "colored"
        });
        nonDeductibleLeaveType(page);
        deductibleLeaveType(page);
      }
      if (isErrorUpdate) {
        toast.dismiss(toastId.current);
        const toastMessage = apiUpdateError?.data.message
          ? apiUpdateError?.data.message
          : "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 2000,
          duplicate: false,
          isLoading: false
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessUpdate, isErrorUpdate, isLoadingUpdate]
  );

  const handlerFormSubmit = (e, edit = false, close, leave) => {
    e.preventDefault();

    if (edit) {
      const value = {
        leave_name: formData.leave_name
          ? formData.leave_name
          : leave.leave_name,
        leave_type: "3",
        short_name: "assf",
        color: formData.color ? formData.color : leave.color,
        status: formData.status ? formData.status : leave.status,
        icon: selectedIcon ? selectedIcon : leave?.icon,
        id: leave.id,
        is_deductible: formData.is_deductible
          ? formData?.is_deductible
          : leave?.is_deductible
      };
      updateLeaveTypes(value);
      close();
    }
  };

  const onDeleteClick = (e, index, close) => {
    deleteLeaveTypes(index);
    close();
  };

  const handlerChangCheck = (e, name) => {
    e.persist();
    setFormData({ ...formData, [name]: e.target.checked === true ? "1" : "0" });
  };

  //Pagination
  useEffect(() => {
    nonDeductibleLeaveType(page);
  }, [page]);
  
  useEffect(() => {
    if(nondeductibleData){
      setPages(nondeductibleData?.totalPages);
    }
  }, [nondeductibleData]);


  const decrementPage = () => {
    nonDeductibleLeaveType(nondeductibleData?.currentPage - 1);
    setPage(nondeductibleData?.currentPage - 1);
  };
  return (
    <div className="p-4">
      <div className="overflow-x-auto w-full whitespace-nowrap">
        <table className="table-auto table  w-full">
          <thead className="thead-dark ">
            <h3 className=" font-semibold text-lg ml-2 mt-2">
              Non Deductible Leave
            </h3>
            <tr>
              <th className="w-40">Name</th>
              <th>Color</th>
              <th>Icon</th>
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {nondeductibleData?.leaveType?.rows?.length > 0 &&
              nondeductibleData?.leaveType?.rows &&
              nondeductibleData?.leaveType?.rows?.map(
                (leave, index) =>
                  leave?.is_deductible === "0" && (
                    <tr key={index}>
                      <td className="max-wTd">{leave?.leave_name}</td>
                      <td>
                        <div
                          className="rounded"
                          style={{
                            backgroundColor: `${leave?.color}`,
                            width: "30px",
                            height: "25px"
                          }}
                        />
                      </td>
                      {/* <td> {leave?.is_deductible === "0" ? "Non-Deductible" : "Deductible" }</td> */}
                      <td>
                        <i className={`${leave.icon}`} />
                      </td>
                      <td>
                        {leave?.company_id !== null ? "Custom" : "Default"}
                      </td>
                      <td> {leave?.status === "1" ? "Active" : "InActive"}</td>
                      {roleIdData === 1 ? (
                        <td>
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <div
                                onClick={() => setOpen((o) => !o)}
                                className="actionBtn"
                              >
                                <i className="fa fa-eye" />
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="leaveTypeDe"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <AllLeaveType leaveDetails={leave} />
                              </div>
                            )}
                          </Popup>
                          {LeaveTypesUpdatePermissions[0]?.status === true ? (
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn"
                                >
                                  <i className="fa fa-pencil" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="EditLeaveTypeClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    <h3>Edit Leave Type</h3>
                                  </div>
                                  <div className="content">
                                    <form
                                      onSubmit={(e) => handlerFormSubmit(e)}
                                    >
                                      <div className="flex flex-wrap ">
                                        <div className="lg:w-1/2  w-full lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Name
                                            </label>
                                            <FormInput
                                              inputType="text"
                                              inputName="leave_name"
                                              inputID="LeaveType"
                                              value={leave.leave_name}
                                              handlerChange={handlerChange}
                                            />

                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w-1/2 w-full pr-0">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Color
                                            </label>
                                            <FormInput
                                              inputType="color"
                                              inputName="color"
                                              inputID="Color"
                                              value={leave.color}
                                              handlerChange={handlerChange}
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w-1/2  w-full lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              {" "}
                                            </label>
                                            <label className="formBlock	">
                                              {" "}
                                            </label>

                                            <label className="flex text-sm items-center pb-3">
                                              <input
                                                type="checkbox"
                                                className="mr-2 w-5 h-5"
                                                name="is_deductible"
                                                id="editAllowance"
                                                value={leave.is_deductible}
                                                defaultChecked={
                                                  leave.is_deductible === "1"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handlerChangCheck(
                                                    e,
                                                    "is_deductible"
                                                  )
                                                }
                                              />{" "}
                                              Take from Leave Allowance
                                            </label>
                                          </div>
                                        </div>

                                        <div className="lg:w-1/2 w-full  lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Icon
                                            </label>
                                            <div>
                                              <Select
                                                options={options}
                                                id="EditIcon"
                                                onChange={handleChange}
                                                isSearchable={true}
                                                placeholder="Select an icon"
                                                classNamePrefix="iconselect"
                                                defaultValue={options.find(
                                                  (option) =>
                                                    option?.icon === leave?.icon
                                                )}
                                              />
                                            </div>
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w-1/2  w-full lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Status{" "}
                                            </label>

                                            <select
                                              className="formControl"
                                              id="EditLeaveTypeStatus"
                                              name="status"
                                              defaultValue={leave.status}
                                              selected={leave.status}
                                              onChange={(e) => handlerChange(e)}
                                            >
                                              <option
                                                value="0"
                                                selected={
                                                  leave.status === 0
                                                    ? "selected"
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  handlerChange(e)
                                                }
                                              >
                                                InActive
                                              </option>
                                              <option
                                                value="1"
                                                selected={
                                                  leave.status === 1
                                                    ? "selected"
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  handlerChange(e)
                                                }
                                              >
                                                Active
                                              </option>
                                            </select>
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w w-full  ">
                                          <div className="w-full mt-4">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <PrimaryButton
                                                Btnclass="btn save mr-2"
                                                btnText="Update"
                                                id="EditLeaveTypeSave"
                                                onClick={(e) =>
                                                  handlerFormSubmit(
                                                    e,
                                                    true,
                                                    close,
                                                    leave
                                                  )
                                                }
                                              />
                                              <PrimaryButton
                                                Btnclass="btn cancel "
                                                onClick={() => close()}
                                                id="EditLeaveTypeCancel"
                                                btnText="Cancel"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          ) : (
                            <button className="actionBtn disabled">
                              <i className="fa fa-pencil" />
                            </button>
                          )}
                          {LeaveTypesDeletePermissions[0]?.status === true ? (
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn cancelBtn"
                                >
                                  <i className="fa fa-trash" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="DeleteLeaveTypeClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    {" "}
                                    <h3>Delete</h3>
                                  </div>
                                  <div className="content">
                                    <p>
                                      Are you sure you want to delete this{" "}
                                      {leave.leave_name} type ?
                                    </p>
                                    <div className="w-full mt-8">
                                      <div className="form-group pt-4 pb-3 flex justify-center ">
                                        <button
                                          id="DeleteLeaveTypeYes"
                                          className="btn save mr-2"
                                          onClick={(e) =>
                                            onDeleteClick(e, leave.id, close)
                                          }
                                        >
                                          Yes
                                        </button>
                                        <button
                                          type="button"
                                          id="DeleteLeaveTypeNo"
                                          onClick={() => close()}
                                          className="btn cancel "
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          ) : (
                            <button
                              className="actionBtn disabled"
                              id="actionBtnTrash"
                            >
                              <i className="fa fa-trash" />
                            </button>
                          )}
                        </td>
                      ) : leave?.company_id !== null ? (
                        <td>
                          {LeaveTypesUpdatePermissions[0]?.status === true ? (
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn"
                                >
                                  <i className="fa fa-pencil" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="EditLeaveTypeClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    <h3>Edit Leave Type</h3>
                                  </div>
                                  <div className="content">
                                    <form
                                      onSubmit={(e) => handlerFormSubmit(e)}
                                    >
                                      <div className="flex flex-wrap ">
                                        <div className="lg:w-1/2  w-full lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Name
                                            </label>
                                            <FormInput
                                              inputType="text"
                                              inputName="leave_name"
                                              inputID="LeaveType"
                                              value={leave.leave_name}
                                              handlerChange={handlerChange}
                                            />

                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w-1/2 w-full pr-0">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Color
                                            </label>
                                            <FormInput
                                              inputType="color"
                                              inputName="color"
                                              inputID="Color"
                                              value={leave.color}
                                              handlerChange={handlerChange}
                                            />
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w-1/2  w-full lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              {" "}
                                            </label>
                                            <label className="formBlock	">
                                              {" "}
                                            </label>

                                            <label className="flex text-sm items-center pb-3">
                                              <input
                                                type="checkbox"
                                                className="mr-2 w-5 h-5"
                                                name="is_deductible"
                                                id="editAllowance"
                                                value={leave.is_deductible}
                                                defaultChecked={
                                                  leave.is_deductible === "1"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handlerChangCheck(
                                                    e,
                                                    "is_deductible"
                                                  )
                                                }
                                              />{" "}
                                              Take from Leave Allowance
                                            </label>
                                          </div>
                                        </div>

                                        <div className="lg:w-1/2 w-full  lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Icon
                                            </label>
                                            <div>
                                              <Select
                                                options={options}
                                                id="EditIcon"
                                                onChange={handleChange}
                                                isSearchable={true}
                                                placeholder="Select an icon"
                                                classNamePrefix="iconselect"
                                                defaultValue={options.find(
                                                  (option) =>
                                                    option?.icon === leave?.icon
                                                )}
                                              />
                                            </div>
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w-1/2  w-full lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Status{" "}
                                            </label>

                                            <select
                                              className="formControl"
                                              id="EditLeaveTypeStatus"
                                              name="status"
                                              defaultValue={leave.status}
                                              selected={leave.status}
                                              onChange={(e) => handlerChange(e)}
                                            >
                                              <option
                                                value="0"
                                                selected={
                                                  leave.status === 0
                                                    ? "selected"
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  handlerChange(e)
                                                }
                                              >
                                                InActive
                                              </option>
                                              <option
                                                value="1"
                                                selected={
                                                  leave.status === 1
                                                    ? "selected"
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  handlerChange(e)
                                                }
                                              >
                                                Active
                                              </option>
                                            </select>
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w w-full  ">
                                          <div className="w-full mt-4">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <PrimaryButton
                                                Btnclass="btn save mr-2"
                                                btnText="Update"
                                                id="EditLeaveTypeSave"
                                                onClick={(e) =>
                                                  handlerFormSubmit(
                                                    e,
                                                    true,
                                                    close,
                                                    leave
                                                  )
                                                }
                                              />
                                              <PrimaryButton
                                                Btnclass="btn cancel "
                                                onClick={() => close()}
                                                id="EditLeaveTypeCancel"
                                                btnText="Cancel"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          ) : (
                            <button className="actionBtn disabled">
                              <i className="fa fa-pencil" />
                            </button>
                          )}
                          {LeaveTypesDeletePermissions[0]?.status === true ? (
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn cancelBtn"
                                >
                                  <i className="fa fa-trash" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="DeleteLeaveTypeClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    {" "}
                                    <h3>Delete</h3>
                                  </div>
                                  <div className="content">
                                    <p>
                                      Are you sure you want to delete this{" "}
                                      {leave.leave_name} type ?
                                    </p>
                                    <div className="w-full mt-8">
                                      <div className="form-group pt-4 pb-3 flex justify-center ">
                                        <button
                                          id="DeleteLeaveTypeYes"
                                          className="btn save mr-2"
                                          onClick={(e) =>
                                            onDeleteClick(e, leave.id, close)
                                          }
                                        >
                                          Yes
                                        </button>
                                        <button
                                          type="button"
                                          id="DeleteLeaveTypeNo"
                                          onClick={() => close()}
                                          className="btn cancel "
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          ) : (
                            <button
                              className="actionBtn disabled"
                              id="actionBtnTrash"
                            >
                              <i className="fa fa-trash" />
                            </button>
                          )}
                        </td>
                      ) : (
                        <td>
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <div
                                onClick={() => setOpen((o) => !o)}
                                className="actionBtn"
                              >
                                <i className="fa fa-eye" />
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="leaveTypeDe"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <AllLeaveType leaveDetails={leave} />
                              </div>
                            )}
                          </Popup>
                          {LeaveTypesUpdatePermissions[0]?.status === true ? (
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn"
                                >
                                  <i className="fa fa-pencil" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="EditLeaveTypeClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    <h3>Edit Leave Type</h3>
                                  </div>
                                  <div className="content">
                                    <form
                                      onSubmit={(e) => handlerFormSubmit(e)}
                                    >
                                      <div className="flex flex-wrap ">
                                        <div className="lg:w-1/2  w-full lg:pr-6">
                                          <div className="form-group mb-6 ">
                                            <label className="formBlock	">
                                              Status{" "}
                                            </label>

                                            <select
                                              className="formControl"
                                              id="EditLeaveTypeStatus"
                                              name="status"
                                              defaultValue={leave.status}
                                              selected={leave.status}
                                              onChange={(e) => handlerChange(e)}
                                            >
                                              <option
                                                value="0"
                                                selected={
                                                  leave.status === 0
                                                    ? "selected"
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  handlerChange(e)
                                                }
                                              >
                                                InActive
                                              </option>
                                              <option
                                                value="1"
                                                selected={
                                                  leave.status === 1
                                                    ? "selected"
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  handlerChange(e)
                                                }
                                              >
                                                Active
                                              </option>
                                            </select>
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="lg:w w-full  ">
                                          <div className="w-full mt-4">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <PrimaryButton
                                                Btnclass="btn save mr-2"
                                                btnText="Update"
                                                id="EditLeaveTypeSave"
                                                onClick={(e) =>
                                                  handlerFormSubmit(
                                                    e,
                                                    true,
                                                    close,
                                                    leave
                                                  )
                                                }
                                              />
                                              <PrimaryButton
                                                Btnclass="btn cancel "
                                                onClick={() => close()}
                                                id="EditLeaveTypeCancel"
                                                btnText="Cancel"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          ) : (
                            <button className="actionBtn disabled">
                              <i className="fa fa-pencil" />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  )
              )}
          </tbody>
        </table>

        <Pagination
          page={page}
          pages={pages}
          changePage={setPage}
          decrementPage={decrementPage}
        />
      </div>
    </div>
  );
};

const AllLeaveType = ({ leaveDetails }) => {
  return (
    <>
      <div className="header">
        {" "}
        <h3> Leave Details</h3>
      </div>
      <div className="content">
        <div className="w-full">
          <div className="mb-3">
            <h3 className="text-[14px]">
              <span className="w-36 inline-block"> Leave Name:</span>
              <span className=" text-black font-medium pl-2">
                {leaveDetails?.leave_name}
              </span>
            </h3>
          </div>

          <div className="mb-3">
            <h3 className="text-[14px] flex ">
              <span className="w-36 inline-block">Color:</span>
              <div
                className="ml-2 inline-block rounded"
                style={{
                  backgroundColor: `${leaveDetails?.color}`,
                  width: "30px",
                  height: "25px"
                }}
              />
            </h3>
          </div>
          <div className="mb-3">
            <h3 className="text-[14px]">
              <span className="w-36 inline-block">Type:</span>
              <span className=" text-black font-medium pl-2">
                {leaveDetails?.is_deductible === "1"
                  ? "Deductible"
                  : leaveDetails?.is_deductible === "0"
                  ? "Non Deductible"
                  : null}
              </span>
            </h3>
          </div>

          <div className="mb-3">
            <h3 className="text-[14px]">
              <span className="w-36 inline-block">Staus:</span>
              <span className=" text-black font-medium pl-2">
                {leaveDetails?.status === "1" ? "Active" : "InActive"}
              </span>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};
