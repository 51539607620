/* eslint-disable no-debugger*/
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';



export const holidayApi = createApi({
    reducerPath: "holidayAPI",
    baseQuery: fetchBaseQuery({
      baseUrl: ApiUrl
}),
endpoints: (builder) => ({
getAllHoliday:builder.mutation({
      query: (payload) => {
      
        return {
        url: `/holiday/?location_id=${payload.location_id}&start_year=${payload.start_year}&page=${payload.page}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      };
    }
    }),
    getAllBankHoliday:builder.mutation({
      query: (id) => {
      
        return {
        url: `/holiday/employee/${id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      };
    }
    }),
    getLocationBankHoliday:builder.mutation({
      query: (payload) => {
        return {
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      };
    }
    }),
createHoliday: builder.mutation({
      query: (payload) => ({
        url: '/holiday',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
  }),
  updateHoliday: builder.mutation({

    query: (payload) => { 
       const { id, ...body } = payload;
       
      return {
        url: `/holiday/${id}`,
        method: 'PUT',
        body,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      };
    },
    invalidatesTags: [ 'Post' ]
  }),
  deleteHoliday: builder.mutation({
    query: (id) => ({
      url: `/holiday/${id}`,
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
  }),
  importHolidayCsv: builder.mutation({
    query: (payload) => {
     return {
      url: '/holiday/import/csv',
      method: 'POST',
      body: payload,
      headers: {
          // 'Content-Type': `multipart/form-data`,
        Authorization:  localStorage.getItem('accessToken')
      }
    
  };
}
    
}),
getFilterHoliday:builder.mutation({
  query: (payload) => ({
    url: `/holiday?location_id=${payload.location_id}&start_year=${payload.year}`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: localStorage.getItem('accessToken')
    }
  })
})
})
});

export const { useGetLocationBankHolidayMutation, useGetAllBankHolidayMutation, useGetAllHolidayMutation, useCreateHolidayMutation, useUpdateHolidayMutation, useDeleteHolidayMutation, useImportHolidayCsvMutation, useGetFilterHolidayMutation} = holidayApi;