/* eslint-disable camelcase */
/* eslint-disable no-debugger*/
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import moment from "moment";
import {ApiUrl} from "../constant";
export const employeeAPI = createApi({
  reducerPath: "employeeAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),

  endpoints: (builder) => ({
    getEmployee: builder.mutation({
      query: (payload) => ({
        url: payload,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    addEmployee: builder.mutation({
      query: ({
        firstName,
        lastName,
        dob,
        gender,
        email,
        mobileNumber,
        department,
        employeeStartDate,
        annualAllowance,
        hoursOrDay,
        proRate,
        maximumLeaveCarry,
        note,
        leaveApprover,
        expenseApprover,
        overtimeApprover,
        isApproveLeave,
        isApproveExpense,
        isApproveOvertime,
        status,
        countryCode,
        rolesId,
        designation
      }) => ({
        url: "/employee",
        method: "POST",
        body: {
          first_name: firstName,
          last_name: lastName,
          dob: moment(dob).format("MM/DD/YYYY"),
          gender: gender,
          email: email,
          mobile_no: mobileNumber,
          department_id: department,
          m_roles_id: rolesId,
          employment_start_date: moment(employeeStartDate).format("MM/DD/YYYY"),
          annual_allowance: annualAllowance,
          hours_or_day: hoursOrDay,
          pro_rate: proRate,
          maximum_leave_carry: maximumLeaveCarry,
          note: note,
          leave_approver: leaveApprover === '0' ? '' : leaveApprover,
          expense_approver: expenseApprover === '0' ? '' : expenseApprover,
          overtime_lieu_approver: overtimeApprover === '0' ? '': overtimeApprover,
          employee_can_approve_leave: isApproveLeave ? "1" : "0",
          employee_can_approve_expense: isApproveExpense ? "1" : "0",
          employee_can_approve_ovetime_lieu: isApproveOvertime ? "1" : "0",
          status: status,
          country_code: countryCode,
          rolesId: rolesId,
          designation: designation
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      }),
      invalidatesTags: [ "Post" ]
    }),
    updateEmployee: builder.mutation({
      query: (payload) => {
        const {
          id,
          firstName,
          lastName,
          dob,
          gender,
          email,
          mobileNumber,
          department,
          employeeStartDate,
          annualAllowance,
          hoursOrDay,
          proRate,
          maximumLeaveCarry,
          note,
          leaveApprover,
          expenseApprover,
          overtimeApprover,
          isApproveLeave,
          isApproveExpense,
          isApproveOvertime,
          status,
          countryCode,
          designation,
          favorite_department_id,
          rolesId
        } = payload;

     
        return {
          url: `/employee/${id}`,
          method: "PUT",
          body: {
            first_name: firstName,
            last_name: lastName,
            dob: moment(dob).format("MM/DD/YYYY"),
            gender: gender,
            email: email,
            mobile_no: mobileNumber,
            department_id: department,
            m_roles_id: rolesId,
            employment_start_date:
            moment(employeeStartDate)?.format("MM/DD/YYYY"),
            annual_allowance: annualAllowance,
            hours_or_day: hoursOrDay,
            pro_rate: proRate,
            maximum_leave_carry: maximumLeaveCarry?.toString(),
            note: note,
            leave_approver: leaveApprover,
            expense_approver: expenseApprover,
            overtime_lieu_approver: overtimeApprover,
            employee_can_approve_leave: isApproveLeave,
            employee_can_approve_expense: isApproveExpense,
            employee_can_approve_ovetime_lieu: isApproveOvertime,
            status: status,
            country_code: countryCode,
            designation: designation,
            favorite_department_id: favorite_department_id
          },
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: [ "Post" ]
    }),
    filterEmployee: builder.mutation({
      query: (name = false, page) => ({
        url: `/employee/?page=${page ? page : 1}&limit=10&first_name=${name}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    filterEmployeeDepartment: builder.mutation({
      query: (payload) => {
        return {
        url: `/employee?department_id=${payload.departmentId}&status=${payload.parmStatus}&limit=100`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      };
      }
    }),
    totalReinviteEmployeeCount: builder.mutation({
      query: (payload) => {
        return {
        url: payload,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      };
      }
    }),
    getManagerEmployee: builder.mutation({
      query: () => ({
        url: "employee?designation=Manager",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getDepartmentManager: builder.mutation({
      query: () => ({
        url: "employee/managers",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    reInviteEmployee: builder.mutation({
      query: (payload) => ({
        url: "/employee/re-invite/",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    resetPasswordEmployee: builder.mutation({
      query: (payload) => ({
        url: "/auth/forget-password/",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: `employee/${id}`,
        method: "Delete",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    filterEmployeeById: builder.mutation({
      query: (id) => ({
        url: `/employee?id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    subscriptionEmployee: builder.mutation({
      query: () => ({
        url: `employee/login/data`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getAllEmployeeList: builder.mutation({
      query: () => ({
        url: `/employee?page1&limit=500`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeDetail: builder.mutation({
      query: (id) => ({
        url: `/employee?id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    employeeLeftDetails:builder.mutation({
      query: ({leaving_date, note, id, previewMode}) => ({
        url: `/employee/left/details/${id}`,
        method: "PUT",
        body: {
          leaving_date: leaving_date,
          note: note,
          status: "0",
          previewMode: previewMode
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    employeeLeft: builder.mutation({
      query: ({leaving_date, note, id}) => ({
        url: `/employee/left/details/${id}`,
        method: "PUT",
        body: {
          leaving_date: leaving_date,
          note: note,
          status: "0",
          previewMode: "0"
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    updateEmployeeById: builder.mutation({
      query: (payload) => {
        const {id, formData} = payload;
        return {
          url: `/employee/${id}`,
          method: "PUT",
          body:formData,
          headers: {
            // "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: [ "Post" ]
    }),
    getEmployeeWorkSchedule: builder.mutation({
      query: (payload) => ({
        url: `/employee?page=${payload?.page ? payload.page : 1}${
          payload?.parmStatus ? `&status=${payload.parmStatus}` : ""
        }`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    importAllEmployee: builder.mutation({
      query: (payload) => ({
        url: "/employee/csv/import/",
        method: "POST",
        body: payload,
        headers: {
          // "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeAllDaysSchedule: builder.mutation({
      query: (id) => ({
        url: `/workschedule?employee_id=${id}`,
        method: "GET",
       
        headers: {
           "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeAllowances: builder.mutation({
      query: (id) => ({
        url: `employee/allowance/${id}`,
        method: "GET",
       
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    employeeAllowanceToHour: builder.mutation({
      query: (payload) => ({
        url: `/employee/allowance/convert`,
        method: "POST",
        body: payload,
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    employeeAllowanceToDays: builder.mutation({
      query: (payload) => ({
        url: `/employee/allowance/convert/into/days`,
        method: "POST",
        body: payload,
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeAllowance: builder.query({
      query: (id) => ({
        url: `employee/allowance/${id}`,
        method: "GET",
       
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeGroupLeaves: builder.query({
      query: ({id}) => ({
        url: `leaves/by/group-identifier?leave_group_identifier=${id}`,
        method: "GET",
       
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    updateEmployeeAllowances: builder.mutation({
      query: (payload) => {
        return {
          url: `employee/createorupdate/leaveallowance`,
          method: "POST",
          body: payload,
          headers: {
           "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      }
      
    }),
    updateLeaveallowancePreviousYear: builder.mutation({
      query: (payload) => ({
        url: `/employee/createorupdate/leaveallowance/previousyear`,
        method: "POST",
        body: payload,
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    allEmployeeReinvite: builder.mutation({
      query: (payload) => ({
        url: `/employee/re-invite-all/`,
        method: "POST",
        body: payload,
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    allEmployeeCalendarViewDetails : builder.mutation({
      query: (id) => ({
        url: `employee/leave/calendar/${id}`,
        method: "GET",
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    employeeLeaveAllowance : builder.mutation({
      query: (url) => ({
        url: url,
        method: "GET",
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getCompanyHistory: builder.query({
      query: ({page, category, start_date, end_date, name, employee_name}) => ({
        url: `employee/history/?company_history=true&page=${page}&name=${name ? name : ""}&employee_name=${employee_name ? employee_name : ""}&category=${category ? category : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeHistory: builder.mutation({
      query: (id) => ({
        url: `/employee/history?id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getEmployeeWorkScheduleHistory: builder.query({
      query: ({id, page}) => ({
        url: `/employee/workschedule/history/?employeeId=${id}&page=${page}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    createLeaveAllowanceAllyear: builder.mutation({
      query: (payload) => ({
        url: `/employee/createorupdate/leaveallowance/allyear`,
        method: "POST",
        body: payload,
        headers: {
         "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    })
  }),
 
  onError: (error
    //  { dispatch, getState }
     ) => {
    if (error.status === 401) {
      alert("Invalid Token Error");
      // Handle 401 Unauthorized error here
      // For example, you can dispatch an action to update the auth state:
      // dispatch(authActions.setAuthenticated(false));
      // Or show a notification/alert to the user:
      // showNotification("You are not authorized to perform this action.");
    }
  }
});

export const {
  useAddEmployeeMutation,
  useGetEmployeeMutation,
  useUpdateEmployeeMutation,
  useFilterEmployeeMutation,
  useGetManagerEmployeeMutation,
  useReInviteEmployeeMutation,
  useFilterEmployeeDepartmentMutation,
  useDeleteEmployeeMutation,
  useFilterEmployeeByIdMutation,
  useGetAllEmployeeListMutation,
  useGetEmployeeDetailMutation,
  useEmployeeLeftMutation,
  useGetEmployeeWorkScheduleMutation,
  useUpdateEmployeeByIdMutation,
  useResetPasswordEmployeeMutation,
  useImportAllEmployeeMutation,
  useGetEmployeeAllDaysScheduleMutation,
  //useGetEmployeeAllowanceMutation,
  useUpdateEmployeeAllowancesMutation,
  useAllEmployeeReinviteMutation,
  useTotalReinviteEmployeeCountMutation,
  useAllEmployeeCalendarViewDetailsMutation,
  useGetEmployeeHistoryMutation,
  useEmployeeAllowanceToHourMutation,
  useGetDepartmentManagerMutation,
  useEmployeeAllowanceToDaysMutation,
  useSubscriptionEmployeeMutation,
  useGetEmployeeAllowancesMutation,
  useGetEmployeeAllowanceQuery,
  useEmployeeLeaveAllowanceMutation,
  useGetCompanyHistoryQuery,
  useGetEmployeeWorkScheduleHistoryQuery,
  useUpdateLeaveallowancePreviousYearMutation,
  useCreateLeaveAllowanceAllyearMutation,
  useGetEmployeeGroupLeavesQuery,
  useEmployeeLeftDetailsMutation
} = employeeAPI;
