import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'; 
import { combineReducers } from "redux";
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from "./AuthSlice/authSlice";
import departmentReducer from "./DepartmentSlice/departmentSlice";
import addressAutofillReducer from './AddressSlice/addressAutofillSlice';
import holidayReducer from "./HolidaySlice/holidaySlice";
import masterExpensesReducer from "./MasterExpenses/masterExpepesesSlice";
import overTimeReducer from "./OverTimeSlice/overTimeSlice";
import restrictedHolidayReducer from "./RestrictedSlice/restrictedHolidaySlice";
import leaveReducer from "./LeaveTypeSlice/leaveSlice";
import employeeReducer from "./EmployeeSlice/employeeSlice";
import { authApi } from "../services/authApi";
import {deptApi} from "../services/deptApi";
import { companyApi } from "../services/companyApi";
import { masterExpensesApi} from "../services/masterExpensesApi";
import { holidayApi } from "../services/holidayApi";
import { addressAutofillApi } from "../services/addressAutofillApi";
import { employeeAPI } from "../services/employeeApi";
import { approverListAPI } from "../services/approverListApi";
import { overTimeApi } from "../services/overTimeApi";
import { restrictedHolidayApi } from "../services/restrictedHolidayApi";
import {leaveApi} from '../services/leaveApi';
//import { apiSlice } from '../feature/api/apiSlice';
import {leaveTypesApi} from "../services/leaveTypesApi";
import {reportApi} from '../services/reportApi';
import { leaveAbsenceApi } from "../services/leaveAbsenceApi";
import {myOverTimeApi} from '../services/myOvertimeApi';
import {expenseApi} from '../services/expenseApi';
import { employeeScheduleApi } from "../services/employeeScheduleApi";
import joyceSlice  from "./JoyiceSlice/joyiceSlice";
import { dashboardApi } from "../services/dashboardAp";
import { superAdminApi } from "../services/superAdminApi";
import { employeeDocumentAPI } from "../services/employeeDocumentAPI";
import DashboardSlice from "./DashboardSlice/DashboardSlice";
import employeeSlice from "./EmployeeSlice/employeeSlice";
import absenceSlice from "./AbsenceSlice/AbsenceSlice";
import departmentSlice from "./DepartmentSlice/departmentSlice";
import reportSlice from "./ReportSlice/expenseSlice";
import leaveTypeSlice from './LeaveTypeSlice/leaveSlice';
import leaveListSlice from "./LeaveTypeSlice/leaveListSlice";
import overtimeListSlice from "./OverTimeSlice/overtimeListSlice";
import notificationSlice from "./NotificationSlice/notificationSlice";
import expenseSlice from "./ExpenseSlice/expenseSlice";
import companySlice from "./CompanyInformation/companySlice";
import { auditorsApi } from "../services/auditorsApi";
import auditorsSlice from "./AuditorsSlice/AuditorsSlice";

const persistConfig = {
  key: "root", // This is the key used to store the data in storage
  storage // Use the storage type you imported (e.g., localStorage, AsyncStorage, etc.)
};

const persistedReducer = persistReducer(persistConfig, combineReducers({
     userPermissionsStates: authReducer,
     DashboardSlice,
     employeeSlice,
     absenceSlice,
     departmentSlice,
     reportSlice,
     leaveTypeSlice,
     leaveListSlice,
     overtimeListSlice,
     notificationSlice,
     expenseSlice,
     companySlice,
     auditorsSlice
}));


export const store = configureStore({
     // ? Add the authReducer to the reducer object
     reducer: {
     joyceState: joyceSlice,
     authState: authReducer,
     persistedReducer,
     departmentState: departmentReducer,
     addressAutofillState: addressAutofillReducer,
     holidayState: holidayReducer,
     masterExpensesState: masterExpensesReducer,
     overTimeState: overTimeReducer,
     restrictedHolidayState: restrictedHolidayReducer,
     leaveState: leaveReducer,
     employeeState: employeeReducer,
     [authApi.reducerPath]: authApi.reducer,
     [companyApi.reducerPath]: companyApi.reducer,
     [deptApi.reducerPath]: deptApi.reducer,
     [masterExpensesApi.reducerPath] : masterExpensesApi.reducer,
     [holidayApi.reducerPath] : holidayApi.reducer,
     [addressAutofillApi.reducerPath] : addressAutofillApi.reducer,
     [employeeAPI.reducerPath]: employeeAPI.reducer,
     [approverListAPI.reducerPath]:approverListAPI.reducer,
     [overTimeApi.reducerPath] : overTimeApi.reducer,
     [restrictedHolidayApi.reducerPath] : restrictedHolidayApi.reducer,
     [leaveTypesApi.reducerPath]: leaveTypesApi.reducer,
     [leaveApi.reducerPath]: leaveApi.reducer,
     [reportApi.reducerPath]: reportApi.reducer,
     [leaveAbsenceApi.reducerPath]: leaveAbsenceApi.reducer,
     [myOverTimeApi.reducerPath]: myOverTimeApi.reducer,
     [expenseApi.reducerPath]: expenseApi.reducer,
     [dashboardApi.reducerPath]: dashboardApi.reducer,
     [employeeScheduleApi.reducerPath]: employeeScheduleApi.reducer,
     [superAdminApi.reducerPath]:superAdminApi.reducer,
     [employeeDocumentAPI.reducerPath]:employeeDocumentAPI.reducer,
     [auditorsApi?.reducerPath]: auditorsApi.reducer
     },
    // devTools: import.meta.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(authApi.middleware).concat(deptApi.middleware).concat(companyApi.middleware).concat(masterExpensesApi.middleware).concat(holidayApi.middleware).concat(addressAutofillApi.middleware).concat(employeeAPI.middleware).concat(approverListAPI.middleware).concat(overTimeApi.middleware).concat(restrictedHolidayApi.middleware).concat(leaveTypesApi.middleware).concat(leaveApi.middleware).concat(reportApi.middleware).concat(leaveAbsenceApi.middleware).concat(myOverTimeApi.middleware).concat(expenseApi.middleware).concat(dashboardApi.middleware).concat(employeeScheduleApi.middleware).concat(superAdminApi.middleware).concat(employeeDocumentAPI.middleware).concat(auditorsApi.middleware)
  });

  // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
export const persistor = persistStore(store);