
import { Link } from "react-router-dom";


export function EmployeeThisWeek({ thisWeekLeaves, tableNames }) {
  // Create a map to store the combined dayName for each employee ID
  const uniqueEmployeeIds = new Set();
  const combinedDayNames = new Map();
  const daysOfWeek = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
  // Iterate through the thisWeekLeaves array

  const thisWeekLeavesArray = thisWeekLeaves ? Object?.values(thisWeekLeaves) : [];
  thisWeekLeavesArray?.length > 0 &&  thisWeekLeavesArray?.forEach((thisWeek) => {
    const employeeId = thisWeek?.employee_id;
    const dayName = daysOfWeek[new Date(thisWeek?.start_date).getDay()];
        
    // If the employee ID is already in the map, append the dayName
    if (combinedDayNames.has(employeeId)) {
      combinedDayNames.set(employeeId, combinedDayNames.get(employeeId) + `, ${dayName}`);
    } else {
      combinedDayNames.set(employeeId, dayName);
      
    }
  });

  combinedDayNames.forEach((value, key) => {
    const reversedDayNames = value?.split(', ')?.reverse()?.join(', ');
    combinedDayNames.set(key, reversedDayNames);
  });

  return (
    <div className="overflow-x-auto w-full whitespace-nowrap">
      <table className="table-auto table w-full">
        <thead className="thead-dark">
          <tr>
            {tableNames?.map((name, index) => (
              <th key={index} className="w-50">
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {thisWeekLeaves?.length > 0 &&
            thisWeekLeaves.map((thisWeek, index) => {
              const employeeId = thisWeek?.employee_id;

              const fullname = thisWeek?.employeeInfo
                ? `${thisWeek?.employeeInfo?.first_name} ${thisWeek?.employeeInfo?.last_name}`
                : thisWeek?.first_name;

                if (!uniqueEmployeeIds.has(employeeId)) {
                uniqueEmployeeIds.add(employeeId); // Mark the employee ID as displayed
              return (
                <tr key={index}>
                  <td>

                    <Link to={`/employees/detail/${thisWeek?.employee_id}`} className="listImage flex items-center">
                      {fullname}
                    </Link>
                  </td>
                  {thisWeek?.start_date && (
                  <td>
                    {combinedDayNames?.get(thisWeek?.employee_id)
                      ?.split(', ')
                      ?.sort((a, b) => {
                        // Define the order of days
                        const order = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                        return order?.indexOf(a) - order?.indexOf(b);
                      })?.join(', ')}
                  </td>
                )}
                  
                </tr>
               
              );
            }
              return null;
            })}
        </tbody>
      </table>
    </div>
  );
}
