/* eslint-disable no-debugger*/
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ApiUrl} from "../constant";

export const superAdminApi = createApi({
  reducerPath: "superAdminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),
  endpoints: (builder) => ({
    getSuperAdminDashboardChartData: builder.mutation({
        query: (payload) => ({
          url: payload,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
      }),
      getSuperAdminCompanyList: builder.mutation({
        query: (payload) => ({
          url:  `/admin/list/company?page=${payload.page}&start_date=${payload.start_date}&end_date=${payload.end_date}&name=${payload.name}&status=${payload.status}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        })
      }),
      getSuperAdminEmployeeList: builder.mutation({
        query: (payload) => {
          return {
            url: payload,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: localStorage.getItem("accessToken")
            }
          };
        }
      }),      
    getSuperAdminDashboardReports: builder.mutation({
      query: () => ({
        url: `/admin/get-dashboard-reports`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    changePasswordImmediately: builder.mutation({
      query: (payload) => ({
        url: `/admin/change-password-immediately`,
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
    }),
    deleteCompany: builder.mutation({
      query: (id) => ({
        url: `/admin/${id}`,
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
    }),
    updateCompanyDetail: builder.mutation({
      query: (payload) => {
        return {
          url: `/admin/${payload.get("id")}`,
          method: 'PUT',
          body: payload,
          headers: {
            Authorization:  localStorage.getItem('accessToken')
          }
        };
      }
      
    })
  })
});
export const { useGetSuperAdminDashboardChartDataMutation, useGetSuperAdminDashboardReportsMutation, useGetSuperAdminCompanyListMutation, useGetSuperAdminEmployeeListMutation, useDeleteCompanyMutation, useUpdateCompanyDetailMutation, useChangePasswordImmediatelyMutation } = superAdminApi;
