/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import PracticeInformation from "./practiceInformation";
import LeaveType from "./leaveType";
import ExpenseTypes from "./Expenses/expenseTypes";
import OverTime from "./OverTime/overTime";
import Department from "./departments";
import CarryForward from "./carryForward";
import PlanSetting from "./planSetting";
import ImportData from "./importData";
import LeaveYear from "./leaveYear";
import Billing from "./billing";
import PublicHoliday from "./publicHoliday";
import CompanyRestrictedDays from "./taskCategories";
import PrivacyContent from "./privacyContent";
import GeneralSetting from "./generalSetting";
import { useGetCompanyMutation } from "../../services/companyApi";
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// import TableContent from "../../components/tableContent";
import UserPermissions from "./Permissions/userPermissions";
import { useSelector } from "react-redux";
import { useGetPlanMutation } from "../../services/authApi";
import CompanyWorkSchedule from "./companyWorkSchedule";
import AdminUser from "./Auditors";
import Auditors from "./Auditors";
import EmailNotification from "./emailNotification";
import CompanyStyles from "./CompanyStyles";
// import Backup from "./backup";
// import Maintance from "./maintance";

function SettingTab({ tab }) {
  const ignore = useRef(false);
  const [getCompany, { data: companyData, isSuccess: isCompanySuccess, isLoading: isCompanyLoading }] = useGetCompanyMutation();
  const [ getplan, {data: planData} ] = useGetPlanMutation();
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  // // eslint-disable-next-line no-unused-vars
  // const [ open, setOpen ] = useState(false);
  // // eslint-disable-next-line no-unused-vars
  // const closeModal = (ccValue = "") => {
  //   setOpen(false);
  // };
  useEffect(() => {
    if (!ignore.current){
    getplan();
    getCompany();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
  const permissionsSelector = useSelector((state) => state.persistedReducer.userPermissionsStates.userPermissions);

  const companyReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Company Information" && obj?.module_permission_name === 'Read');
  const companyUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Company Information" && obj?.module_permission_name === 'Update');
  const LeaveTypesReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Leave Types" && obj?.module_permission_name === 'Read');
  const LeaveTypesUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Leave Types" && obj?.module_permission_name === 'Update');
  const LeaveTypesCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Leave Types" && obj?.module_permission_name === 'Create');
  const LeaveTypesDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Leave Types" && obj?.module_permission_name === 'Delete');
  const expenseTypesReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Expense Types" && obj?.module_permission_name === 'Read');
  const expenseTypesUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Expense Types" && obj?.module_permission_name === 'Update');
  const expenseTypesCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Expense Types" && obj?.module_permission_name === 'Create');
  const expenseTypesDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Expense Types" && obj?.module_permission_name === 'Delete');
  const overTimeTypesReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Overtime Types" && obj?.module_permission_name === 'Read');
  const overTimeTypesUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Overtime Types" && obj?.module_permission_name === 'Update');
  const overTimeTypesCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Overtime Types" && obj?.module_permission_name === 'Create');
  const overTimeTypesDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Overtime Types" && obj?.module_permission_name === 'Delete');
  const restrictedDaysReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Restricted Days" && obj?.module_permission_name === 'Read');
  const restrictedDaysUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Restricted Days" && obj?.module_permission_name === 'Update');
  const restrictedDaysCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Restricted Days" && obj?.module_permission_name === 'Create');
  const restrictedDaysDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Restricted Days" && obj?.module_permission_name === 'Delete');
  const departmentsReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Departments" && obj?.module_permission_name === 'Read');
  const departmentsUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Departments" && obj?.module_permission_name === 'Update');
  const departmentsCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Departments" && obj?.module_permission_name === 'Create');
  const departmentsDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Departments" && obj?.module_permission_name === 'Delete');
  const carryForwardReadPermission = permissionsSelector?.filter((obj) => obj?.module_name === "Carry Forwarded" && obj?.module_permission_name === 'Read');
  const carryForwardUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Carry Forwarded" && obj?.module_permission_name === 'Update');
  //  const  carryForwardCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Carry Forwarded" && obj?.module_permission_name === 'Create');
  //  const  carryForwardDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Carry Forwarded" && obj?.module_permission_name === 'Delete');
  const bankHolidayReadPermission = permissionsSelector?.filter((obj) => obj?.module_name === "Bank Holidays" && obj?.module_permission_name === 'Read');
  const bankHolidayUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Bank Holidays" && obj?.module_permission_name === 'Update');
  const bankHolidayCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Bank Holidays" && obj?.module_permission_name === 'Create');
  const bankHolidayDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Bank Holidays" && obj?.module_permission_name === 'Delete');
  const importDataReadPermission = permissionsSelector?.filter((obj) => obj?.module_name === "Import Data" && obj?.module_permission_name === 'Read');
  const importDataUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Import Data" && obj?.module_permission_name === 'Update');
  //  const  importDataCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Import Data" && obj?.module_permission_name === 'Create');
  //  const  importDataDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Import Data" && obj?.module_permission_name === 'Delete');
  const billingReadPermission = permissionsSelector?.filter((obj) => obj?.module_name === "Billing" && obj?.module_permission_name === 'Read');
  const billingUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Billing" && obj?.module_permission_name === 'Update');
  const billingCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Billing" && obj?.module_permission_name === 'Create');
  const billingDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Billing" && obj?.module_permission_name === 'Delete');
  const privacyReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Privacy" && obj?.module_permission_name === 'Read');
  const privacyUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Privacy" && obj?.module_permission_name === 'Update');
  const privacyCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Privacy" && obj?.module_permission_name === 'Create');
  const privacyDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "Privacy" && obj?.module_permission_name === 'Delete');
  const generalSettingsReadPermissions = permissionsSelector?.filter((obj) => obj?.module_name === "General Settings" && obj?.module_permission_name === 'Read');
  const generalSettingsUpdatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "General Settings" && obj?.module_permission_name === 'Update');
  const generalSettingsCreatePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "General Settings" && obj?.module_permission_name === 'Create');
  const generalSettingsDeletePermissions = permissionsSelector?.filter((obj) => obj?.module_name === "General Settings" && obj?.module_permission_name === 'Delete');

  const checkReadPermissionModule = permissionsSelector?.find((obj) => obj?.module_name === "Permissions" && obj?.module_permission_name === 'Read') ;
  const companyReadUserPermissions =  checkReadPermissionModule?.status ? checkReadPermissionModule.status: false; 
  const checkUpdatPermissionModule = permissionsSelector?.find((obj) => obj?.module_name === "Permissions" && obj?.module_permission_name === 'Update');
  const companyUpdateUserPermissions =  checkUpdatPermissionModule?.status ? checkUpdatPermissionModule.status: false; 

  //const userRoleId = JSON.parse(localStorage.getItem("employeeData"));
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);




  return (
    <>

      <div className="settingTabArea flex justify-between flex-wrap md:flex-nowrap">
        {/* <ToastContainer containerId={'mainId'} /> */}

        <div className="tabList w-full md:w-1/3 lg:w-1/4 xl:p-5 p-4 rounded-md mb-4 md:mb-0">

        {planData?.current_active_plan === null ? (
            // Show only billing tab when current_active_plan is null
            <ul>
                  {billingReadPermission[0]?.status === true && roleIdData !== 1 && (
                <li>
                  <Link
                    to='/setting/billing'
                    className={tab === 'billing' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-file " />
                    Billing
                  </Link>
                </li>
              )}
                </ul>
          ) : (
            // Render the list of navigation links based on permissions
            <ul>
              {/* Company Information */}
              {companyReadPermissions[0]?.status === true && roleIdData !== 1 && (
                <li key="info">
                  <Link
                    to="/setting/info"
                    onClick={() => toggleTab(1)}
                    className={tab === 'info' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-building" />
                    Company Information
                  </Link>
                </li>
              )}

              {/* General Settings */}
              {generalSettingsReadPermissions[0]?.status === true && roleIdData !== 1 && (
                <li>
                  <Link
                    to='/setting/general'
                    className={tab === 'general' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-cog" />
                    General
                  </Link>
                </li>
              )}
              <li>
                  <Link
                    to='/setting/email-notification'
                    className={tab === 'email-notification' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa-solid fa-bell" />
                    Email Notifications
                  </Link>
                </li>
              <li>
                  <Link
                    to='/setting/workschedule'
                    className={tab === 'workschedule' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-tasks" />
                    Work Schedule
                  </Link>
                </li>

              {/* Leave Types */}
              {LeaveTypesReadPermissions[0]?.status === true && (
                <li>
                  <Link
                    to="/setting/leave"
                    onClick={() => toggleTab(2)}
                    className={tab === 'leave' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-calendar" />
                    Leave Types
                  </Link>
                </li>
              )}

              {/* Expense Types */}
              {expenseTypesReadPermissions[0]?.status === true && (
                <li>
                  <Link
                    to="/setting/expenses"
                    onClick={() => toggleTab(3)}
                    className={tab === 'expenses' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-credit-card" />
                    Expense Types
                  </Link>
                </li>
              )}

              {/* Overtime Types */}
              {overTimeTypesReadPermissions[0]?.status === true && (
                <li>
                  <Link
                    to="/setting/overtime"
                    className={tab === 'overtime' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-clock" />
                    Overtime Types
                  </Link>
                </li>
              )}

                <li>
                  <Link
                    to="/setting/auditors"
                    className={tab === 'auditors' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-user" />
                    External Users
                  </Link>
                </li>

              {/* Restricted Days */}
              {restrictedDaysReadPermissions[0]?.status === true && roleIdData !== 1 && (
                <li>
                  <Link
                    to="/setting/restrciteddays"
                    onClick={() => toggleTab(12)}
                    className={tab === 'restrciteddays' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-calendar-times " />
                    Restricted Days
                  </Link>
                </li>
              )}

              {/* Departments */}
              {departmentsReadPermissions[0]?.status === true && (
                <li>
                  <Link
                    to="/setting/department"
                    className={tab === 'department' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fas fa-sitemap " />
                    Departments
                  </Link>
                </li>
              )}

              {/* User Permissions */}
              {(companyReadUserPermissions || companyUpdateUserPermissions) && roleIdData !== 1 && (
                <li>
                  <Link
                    to="/setting/permissions"
                    className={tab === 'permissions' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fas fa-lock" />
                    User Permissions
                  </Link>
                </li>
              )}

              {/* Carry Forward */}
              {carryForwardReadPermission[0]?.status === true && roleIdData !== 1 && (
                <li>
                  <Link
                    to='/setting/carryforward'
                    className={tab === 'carryforward' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-arrow-right " />
                    Carry Forward
                  </Link>
                </li>
              )}

              {/* Bank Holidays */}
              {bankHolidayReadPermission[0]?.status === true && (
                <li>
                  <Link
                    to='/setting/holiday'
                    className={tab === 'holiday' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-umbrella-beach fa-flip-horizontal " />
                    Bank Holidays
                  </Link>
                </li>
              )}

              {/* Plan Settings */}
              {roleIdData === 1 && (
                <li>
                  <Link
                    to='/setting/plansetting'
                    className={tab === 'plansetting' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-cogs" />
                    Plan Settings
                  </Link>
                </li>
              )}

              {/* Import Data */}
              {importDataReadPermission[0]?.status === true && roleIdData !== 1 && (
                <li>
                  <Link
                    to='/setting/importdata'
                    className={tab === 'importdata' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-download " />
                    Import Data
                  </Link>
                </li>
              )}

              {/* Billing */}
              {billingReadPermission[0]?.status === true && roleIdData !== 1 && (
                <li>
                  <Link
                    to='/setting/billing'
                    className={tab === 'billing' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-file " />
                    Billing
                  </Link>
                </li>
              )}

              {/* Privacy */}
              {privacyReadPermissions[0]?.status === true && roleIdData !== 1 && (
                <li>
                  <Link
                    to='/setting/privacy'
                    className={tab === 'privacy' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-lock " />
                    Privacy
                  </Link>
                </li>
              )}
                 {/* Modify Company styles */}
                 {privacyReadPermissions[0]?.status === true && roleIdData !== 1 && (
                <li>
                  <Link
                    to='/setting/styles'
                    className={tab === 'styles' ? "tabBtn tabBtn-active" : "tabBtn"}
                  >
                    <span className="fa fa-lock " />
                    Choose Company Styles
                  </Link>
                </li>
              )}

            </ul>
          )}

        </div>
        <div className="settingTab w-full md:w-2/3 lg:w-9/12 md:pl-6">
        {companyReadPermissions[0]?.status === true &&
          <div
            className={
              tab === 'info' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <PracticeInformation companyUpdatePermissions={companyUpdatePermissions} companyData={companyData} isCompanySuccess={isCompanySuccess} isCompanyLoading={isCompanyLoading} />
            </div>
          </div>
          }
          {
            LeaveTypesReadPermissions[0]?.status === true &&

            <div
              className={
                tab === 'leave' ? "tabContent tabContentActive" : "tabContent"
              }
            >
              <div className="headingContent card2 pt-0 w-full">
                <LeaveType LeaveTypesUpdatePermissions={LeaveTypesUpdatePermissions} LeaveTypesCreatePermissions={LeaveTypesCreatePermissions} LeaveTypesDeletePermissions={LeaveTypesDeletePermissions} />
              </div>
            </div>
          }
           {expenseTypesReadPermissions[0]?.status === true &&
          <div
            className={
              tab === 'expenses' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <ExpenseTypes expenseTypesUpdatePermissions={expenseTypesUpdatePermissions} expenseTypesCreatePermissions={expenseTypesCreatePermissions} expenseTypesDeletePermissions={expenseTypesDeletePermissions} />
            </div>
          </div>}
          {overTimeTypesReadPermissions[0]?.status === true &&
          <div
            className={
              tab === 'overtime' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <OverTime overTimeTypesUpdatePermissions={overTimeTypesUpdatePermissions} overTimeTypesCreatePermissions={overTimeTypesCreatePermissions} overTimeTypesDeletePermissions={overTimeTypesDeletePermissions} />
            </div>
          </div>}
          {departmentsReadPermissions[0]?.status === true &&
          <div
            className={
              tab === 'department' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <Department departmentsUpdatePermissions={departmentsUpdatePermissions} departmentsCreatePermissions={departmentsCreatePermissions}
                departmentsDeletePermissions={departmentsDeletePermissions} />
            </div>
          </div>}
          {companyReadPermissions[0]?.status === true &&
          <div
            className={
              tab === 'permissions' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <UserPermissions 
              userRoleId={roleIdData}
              companyReadUserPermissions={companyReadUserPermissions}
              companyUpdateUserPermissions={companyUpdateUserPermissions}
              />
            </div>
          </div>}
          {companyReadPermissions[0]?.status === true &&
          <div
            className={
              toggleState === 6 ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <LeaveYear />
            </div>
          </div>}
          {carryForwardReadPermission[0]?.status === true &&
          <div
            className={
              tab === 'carryforward' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <CarryForward carryForwardUpdatePermissions={carryForwardUpdatePermissions} companyData={companyData} isCompanySuccess={isCompanySuccess} isCompanyLoading={isCompanyLoading} />
            </div>
          </div>}

          {roleIdData === 1 &&
          <div
            className={
              tab === 'plansetting' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <PlanSetting companyData={companyData} isCompanySuccess={isCompanySuccess} isCompanyLoading={isCompanyLoading} />
            </div>
          </div>}

          {bankHolidayReadPermission[0]?.status === true &&
          <div
            className={
              tab === 'holiday' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <PublicHoliday bankHolidayCreatePermissions={bankHolidayCreatePermissions} bankHolidayDeletePermissions={bankHolidayDeletePermissions} bankHolidayUpdatePermissions={bankHolidayUpdatePermissions} />
            </div>
          </div>}
          {importDataReadPermission[0]?.status === true &&

          <div
            className={
              tab === 'importdata' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <ImportData importDataUpdatePermissions={importDataUpdatePermissions} />
            </div>
          </div>}
          {billingReadPermission[0]?.status === true &&
          <div
            className={
              tab === 'billing' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <Billing billingUpdatePermissions={billingUpdatePermissions} billingCreatePermissions={billingCreatePermissions} billingDeletePermissions={billingDeletePermissions} />
            </div>
          </div>}
          {restrictedDaysReadPermissions[0]?.status === true &&
          <div
            className={
              tab === 'restrciteddays' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <CompanyRestrictedDays restrictedDaysUpdatePermissions={restrictedDaysUpdatePermissions}
                restrictedDaysCreatePermissions={restrictedDaysCreatePermissions} restrictedDaysDeletePermissions={restrictedDaysDeletePermissions} />
            </div>
          </div>}
          {privacyReadPermissions[0]?.status === true &&
          <div
            className={
              tab === 'privacy' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <PrivacyContent privacyUpdatePermissions={privacyUpdatePermissions} privacyCreatePermissions={privacyCreatePermissions}
                privacyDeletePermissions={privacyDeletePermissions} companyData={companyData} isCompanySuccess={isCompanySuccess} isCompanyLoading={isCompanyLoading} />
            </div>
          </div>}
          {/* {privacyReadPermissions[0]?.status === true && */}
          <div
            className={
              tab === 'styles' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <CompanyStyles />
            </div>
          </div>
          {/* } */}
          {generalSettingsReadPermissions[0]?.status === true &&
          <div
            className={
              tab === 'general' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <GeneralSetting generalSettingsUpdatePermissions={generalSettingsUpdatePermissions} generalSettingsDeletePermissions={generalSettingsDeletePermissions}
                generalSettingsCreatePermissions={generalSettingsCreatePermissions} companyData={companyData} isCompanySuccess={isCompanySuccess} isCompanyLoading={isCompanyLoading} />
            </div>
          </div>}
          <div
            className={
              tab === 'workschedule' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <CompanyWorkSchedule companyData={companyData} isCompanySuccess={isCompanySuccess} isCompanyLoading={isCompanyLoading} />
            </div>
          </div>
          <div
            className={
              tab === 'email-notification' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <EmailNotification/>
            </div>
          </div>
          <div
            className={
              tab === 'auditors' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
              <Auditors/>
            </div>
          </div>
          {/* <div
            className={
              tab === 'backup' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
            <Backup/>
            </div>
          </div>
          <div
            className={
              tab === 'maintance' ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <div className="headingContent card2 pt-0 w-full">
           <Maintance/>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default SettingTab;
