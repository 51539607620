import { useEffect, useRef, useState } from "react";
import { useGetNotificationMailMutation, useSendNotificationMailMutation } from "../../services/companyApi";
import SwitchBtnNotification from "../../components/switchBtnNotification";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/primaryButton";

const EmailNotification = () => {

  //states and hooks
  const [notification, setNotification] = useState({
    absenceNotification: true,
    leaveNotification: true,
    expensesNotification: true,
    overtimeNotification: true
  });
  const toastGeneralId = useRef(null);

  const [sendNotificationMail, {isSuccess, isError, isLoading, error}] = useSendNotificationMailMutation();
  const [getNotificationMail, {data: notificationData}] = useGetNotificationMailMutation();

  useEffect(() => {
    getNotificationMail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(notificationData){
      setNotification({
        absenceNotification: notificationData?.data?.send_absence_mail === "1" ? true : false,
        leaveNotification: notificationData?.data?.send_leave_mail === "1" ? true : false,
        expensesNotification: notificationData?.data?.send_expense_mail === "1" ? true : false,
        overtimeNotification: notificationData?.data?.send_overtime_mail === "1" ? true : false
      });
    }
  }, [notificationData]);


  const handleNotificationChange = (e) => {
    const { name, checked } = e.target;
    setNotification({ ...notification, [name]: checked });
  };

  
  useEffect(() => {
    if (isLoading) {
      toastGeneralId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccess) {
      setTimeout(() => {
        toast.dismiss(toastGeneralId.current);
      }, 10000);
      toast.update(
        toastGeneralId.current,
        ("Email notification setting updated successfully.",
          {
            toastId: 'privacyId',
            render: "Email notification setting updated successfully.",
            type: "success",
            autoClose: 10000,
            isLoading: false
          })
      );
      getNotificationMail();
    }
    if (isError) {
      setTimeout(() => {
        toast.dismiss(toastGeneralId.current);
      }, 10000);
      const toastMessage = error.data.message
        ? error.data.message
        : "Something went wrong";
      toast.update(
        toastGeneralId.current,
        (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 10000,
            isLoading: false
          })
      );
    }
  }, [isSuccess, isError, isLoading, error]);

  const handleSave = (e) => {
    e.preventDefault();
    let val = {
      sendExpenseMail: notification?.expensesNotification === true ? "1" : "0",
      sendLeaveMail: notification?.leaveNotification === true ? "1" : "0",
      sendOvertimeMail: notification?.overtimeNotification === true ? "1" : "0",
      sendAbsenceMail: notification?.absenceNotification === true ? "1" : "0"
    };
    sendNotificationMail(val);
  };

  return (
    <>
      <div className="general-settings">
        <div className="p-4">
          <div className="block">
            <div className="heading_title py-4 flex justify-between items-center">
              <h3 className="font-semibold text-lg">Email Notifications Setting </h3>
            </div>
            <div className="flex items-center mb-4 mt-8">
              <h4 className="w-48">Absence email</h4>
              <SwitchBtnNotification
                value={notification?.absenceNotification}
                onChange={(e) => handleNotificationChange(e)}
                name="absenceNotification"
              />
            </div>
            <div className="flex items-center mb-4 mt-8">
              <h4 className="w-48">Leave email</h4>
              <SwitchBtnNotification
                value={notification?.leaveNotification}
                onChange={(e) => handleNotificationChange(e)}
                name="leaveNotification"
              />
            </div>
            <div className="flex items-center mb-4 mt-8">
              <h4 className="w-48">Expenses email</h4>
              <SwitchBtnNotification
                value={notification?.expensesNotification}
                onChange={(e) => handleNotificationChange(e)}
                name="expensesNotification"
              />
            </div>
            <div className="flex items-center mb-4 mt-8">
              <h4 className="w-48">Overtime email</h4>
              <SwitchBtnNotification
                value={notification?.overtimeNotification}
                onChange={(e) => handleNotificationChange(e)}
                name="overtimeNotification"
              />
            </div>
            <div className="w-full mt-8">
              <div className="form-group pt-4 pb-3 flex">
                <PrimaryButton
                  Btnclass="btn save mr-2"
                  buttonID="save"
                  onClick={(e) => handleSave(e)}
                  btnText="Save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailNotification;
