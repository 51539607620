import { useEffect, useRef, useState } from "react";
import { FormInput } from "../../components/formInput";
import { toast } from "react-toastify";
import { useCreateCompanyMutation, useGetCompanyMutation } from '../../services/companyApi';
import { useDispatch } from "react-redux";
import { companyAllData } from "../../redux/AuthSlice/authSlice";
import SearchLocation from "../Employees/addressComponent";


const PracticeInformation = ({ joyceData, isSignup, toggleWelTab, children, companyUpdatePermissions }) => {

  const [ role_id, setRole_id ] = useState();
  const ignore = useRef(false);
  const [ updatePermissionStatus, setUpdatePermissionStatus ] = useState();


  const [createCompany, { isSuccess, isLoading, isError, error: companyError }] = useCreateCompanyMutation();
  const [getCompany, { data: companyData, isSuccess: isCompanySuccess }] = useGetCompanyMutation();

  useEffect(() => {
    if(companyUpdatePermissions?.length > 0){
      setUpdatePermissionStatus(companyUpdatePermissions ? companyUpdatePermissions[0]?.status : false);
      setRole_id(companyUpdatePermissions ? companyUpdatePermissions?.role_id : companyUpdatePermissions[0]?.role_id);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ companyUpdatePermissions ]);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    MobileNumber: "",
    Website: "",
    streetAddress: "",
    address: "",
    address2: "",
    city: "",
    postal_code: "",
    state: "",
    country: "",
    company_logo: "",
    payment_processor_email:""
  });

  const [mobileError, setMobileError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [cityError, setCityError] = useState("");
  const [postalError, setPostalError] = useState("");

  
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!ignore.current){
    getCompany();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

  useEffect(() => {
    if (companyData?.companyData !== undefined) {
      setFormData({
        name: companyData?.companyData?.name,
        email: companyData?.companyData?.email,
        MobileNumber: companyData?.companyData?.tel_no,
        Website: companyData?.companyData?.website,
        streetAddress: companyData?.companyData?.address,
        address: companyData?.companyData?.address,
        address2: companyData?.companyData?.address2,
        city: companyData?.companyData?.city,
        postal_code: companyData?.companyData?.postal_code,
        state: companyData?.companyData?.state,
        country: companyData?.companyData?.country,
        company_logo: companyData?.companyData?.company_logo,
        payment_processor_email: companyData?.companyData?.payment_processor_email

      });
      setPreview(companyData?.companyData?.company_logo);
      localStorage.setItem('LogoImage', formData?.company_logo);
      dispatch(companyAllData(companyData?.companyData));
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isCompanySuccess]);


  useEffect(() => {
    if (isSignup, joyceData)
      setFormData({
        name: joyceData?.company_name,
        email: joyceData?.email,
        MobileNumber: joyceData?.mobile_no
      });
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isSignup, joyceData]);

  const [file, setFile] = useState("");

  const toastId = useRef(null);
  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Company updated successfully.", {
        render: "Company updated successfully.",
             type: "success",
             autoClose: 2000,
           isLoading: false,
           theme: "colored"
      });
      getCompany();
      setTimeout(() => {
        if (isSignup) {
          toggleWelTab("Expense");
        }
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[500]);
    }
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage = companyError?.data?.message
        ? companyError?.data?.message
        : "Something went wrong.";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });

    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);

  const handleUpload = async (event) => {
    if (event.target.files[0]) {
      var fileSize = Math.round(event.target.files[0].size / 1024);

      if (fileSize <= 5 * 1024) {
        setFile(event.target.files[0]);
        setPreview(URL.createObjectURL(event.target.files[0]));
      } else {
        alert('Please select a file size less than 5kb.');
      }
    }
  };

  const handlerChange = (event) => {
    const { name, value } = event.target;
    if (name === 'MobileNumber') {
      if (value.length !== 10) {
        setMobileError('Mobile number must be 10 digits');
      } else {
        setMobileError('');
      }
    }
    if (name === 'country') {
      if (/\d/.test(value)) {
        setCountryError('Country should not contain numbers');
      }
      // eslint-disable-next-line
      else if (/[\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/.test(value)) {
        setCountryError('Country should not contain numbers or special characters');
      }
      else {
        setCountryError('');
      }
    }
    if (name === 'city') {
      if (/[^a-zA-Z0-9\s]/.test(value)) {
        setCityError('City/Town should not contain special characters');
      } else {
        setCityError('');
      }
    }
    if (name === 'postcode') {
      if (
        !(/^\d{6}$/.test(value) || /^[A-Za-z]{1,2}\d{1,2} ?\d[A-Za-z]{2}$|^[A-Za-z]{1,2}\d[A-Za-z] ?\d[A-Za-z]{2}$|^[A-Za-z]\d{1,2} ?\d[A-Za-z]{2}$|^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]{2}$/.test(value) )
      ) {
        setPostalError('Invalid postal code format');
      } else {
        setPostalError('');
      }
    }

    setFormData({ ...formData, [name]: value });

  };

  const handleChangeAddress = (e) => {
    e.preventDefault();
    setFormData({ ...formData, ['address']: e.target.value });
  };

  const handlerSave = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("name", formData?.name);
    formdata.append("email", formData?.email);
    formdata.append("tel_no", formData?.MobileNumber);
    // eslint-disable-next-line
    formData?.Website ? formdata?.append("website", formData?.Website) : "";
    formdata.append("address", formData.address);
    formdata.append("address2", formData.address2);
    formdata.append("city", formData.city);
    // eslint-disable-next-line
    formData.postal_code ? formdata.append("postal_code", formData.postal_code) : "";
    formdata.append("state", formData.state);
    formdata.append("country", formData.country);
    formdata.append("company_logo", file);
    formdata.append("payment_processor_email", formData?.payment_processor_email);

    createCompany(formdata);
  };
  const [websiteError, setwebsiteError] = useState({
    website: ''
  });
  const handlerWebsiteChange = (e) => {
     const { name, value } = e.target;
     const isValidWebsite = value.match(/^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/\S*)?$/);

    setFormData({
      ...formData,
      [name]: value
    });

    setwebsiteError({
      ...websiteError,
      website: isValidWebsite ? '' : 'Invalid website format.'
    });
  };

  return (
    <>
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <h3 className=" font-semibold text-lg"> Company Information</h3>

      </div>
      {/* <ToastContainer /> */}
      <div className="p-4">
        {/* <form> */}
        <div className="flex flex-wrap ">
          <div className=" lg:w-1/2 lg:pr-6 w-full">
            <div className="form-group mb-6 ">
              <label className="formBlock	">Company Name<span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
              <FormInput
                disableVal={(role_id == 4 && updatePermissionStatus) ? false : (role_id !== 4 && updatePermissionStatus) ? false : true}
                inputType="text"
                inputName="name"
                inputID="companyName"
                value={formData.name}
                handlerChange={handlerChange}
              />
            </div>
          </div>
          <div className="lg:w-1/2 pr-0 w-full">
            <div className="form-group mb-6 ">
              <label className="formBlock	">Email <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
              <FormInput inputType="text"
                disableVal={(role_id == 4) ? false : (role_id == 3) ? false : (role_id == 2) ? false : true}
                inputName="email"
                inputID="email"
                value={formData.email}
                handlerChange={handlerChange}
              />
            </div>
          </div>
          <div className="lg:w-1/2 lg:pr-6  w-full">
            <div className="form-group mb-6 ">
              <label className="formBlock	">Mobile Number <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
              <FormInput
                disableVal={(role_id == 4 && updatePermissionStatus) ? false : (role_id !== 4 && updatePermissionStatus) ? false : true}
                inputType="number"
                inputName="MobileNumber"
                inputID="MobileNumber"
                value={formData.MobileNumber}
                handlerChange={handlerChange}
              />
              <div className="help-block mt-2">{mobileError && <span className="text-red-500">{mobileError}</span>}</div>
            </div>
          </div>
          <div className="lg:w-1/2 pr-0 w-full">
            <div className="form-group mb-6 ">
              <label className="formBlock	">Website <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
              <input
                type="text"
                name="Website"
                id="Website"
                className="formControl"
                value={formData?.Website}
                disabled={(role_id == 4 && updatePermissionStatus) ? false : (role_id !== 4 && updatePermissionStatus) ? false : true}
                onChange={(e) => handlerWebsiteChange(e)}
            />
               <div className="help-block mt-2 text-red-500">{websiteError?.website}</div>
            </div>
          </div>
          <div className=" lg:w-1/2 lg:pr-6 w-full">
            <div className="form-group mb-6 ">
              <label className="formBlock"> Address <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>             
              <SearchLocation
                  handleChangeAddress={handleChangeAddress}
                  setEmployeeInfo={setFormData}
                  employeeInfo={formData}
                />
            </div>
          </div>
          
          <div className="lg:w-1/2 pr-0 w-full">
            <div className="form-group mb-6 ">
              <label className="formBlock	">City/Town <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
              <FormInput
                disableVal={(role_id == 4 && updatePermissionStatus) ? false : (role_id !== 4 && updatePermissionStatus) ? false : true}
                inputType="text"
                inputName="city"
                inputID="city"
                value={formData.city}
                handlerChange={handlerChange}
              />
              <div className="help-block mt-2">
                {cityError && <span className="text-red-500">{cityError}</span>}
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 lg:pr-6  w-full">
            <div className="form-group mb-6 ">
              <label className="formBlock	">Post Code <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
              <FormInput
                disableVal={(role_id == 4 && updatePermissionStatus) ? false : (role_id !== 4 && updatePermissionStatus) ? false : true}
                inputType="text"
                inputName="postal_code"
                inputID="postal_code"
                value={formData?.postal_code}
                handlerChange={handlerChange}
              />
              <div className="help-block mt-2">
                {postalError && <span className="text-red-500">{postalError}</span>}
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 pr-0 w-full">
            <div className="form-group mb-6 ">
              <label className="formBlock	">Country <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
              <FormInput
                disableVal={(role_id == 4 && updatePermissionStatus) ? false : (role_id !== 4 && updatePermissionStatus) ? false : true}
                inputType="text"
                inputName="country"
                inputID="country"
                value={formData?.country}
                handlerChange={handlerChange}
              />
              <div className="help-block mt-2">
                {countryError && <span className="text-red-500">{countryError}</span>}
              </div>
            </div>
          </div>
          <div className="w-full">

            <div className="relative">
              <label className="formBlock	">Company Logo</label>
              <div className="relative" >
                <div className="imageUpload">
                  {preview ?
                    <img
                      style={{ height: 80, width: 80 }}
                      src={preview}
                      className="rounded-lg"
                    />
                    : companyData?.companyData?.company_logo ?
                      <>

                        <img
                          style={{ height: 80, width: 80 }}
                          src={companyData?.companyData?.company_logo}
                          className="rounded-lg"
                        />
                      </>
                      :
                      <img src="/images/no-image1.jpg" className="rounded-lg"
                      />
                  }
                  <div className="file-upload">
                    <input type="file" onChange={handleUpload} id="fileUpload"
                      disabled={(role_id == 4 && updatePermissionStatus) ? false : (role_id !== 4 && updatePermissionStatus) ? false : true}
                    /> <i className="fa fa-file-image" /></div>
                </div>

              </div>
              <div className="help-block" />
            </div>
          </div>
          <div className="lg:w w-full  ">
            <div className="w-full mt-4">
              <div className="form-group pt-6 pb-3 flex border-t  ">
                {companyUpdatePermissions?.length > 0 ? companyUpdatePermissions[0]?.status === true &&
                  (<button className="btn save mr-2" id="update"
                    disabled={(role_id == 4 && updatePermissionStatus) ? false : (role_id !== 4 && companyUpdatePermissions[0]?.status === true) ? false : true}
                    onClick={(e) => {
                      if (!mobileError && !countryError && !cityError && !postalError) {
                        handlerSave(e);
                      }
                    }}
                  >
                    Update
                  </button>)
                  : null}

                {isSignup ? children : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PracticeInformation;
