/* eslint-disable no-debugger */
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isJoyce: false,
  isSignup: false,
  joyceData:[]
};

export const joyceSlice = createSlice({
  name: 'joyceSlice',
  initialState,
  reducers: {
    openJoyce: (state, action) => {
      state.isJoyce = action.payload.isJoyce;
      state.isSignup = action.payload.isSignup;
      state.joyceData = action.payload.data;
    },
    closeJoyce: (state, action) => {
      state.isSignup = action.payload.isSignup;
    }
  
  }
});

export const { openJoyce, closeJoyce } = joyceSlice.actions;

export default joyceSlice.reducer;