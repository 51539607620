/* eslint-disable camelcase */

import { useEffect } from "react";
import { useGetEmployeeFilesMutation } from "../../services/employeeDocumentAPI";

/* eslint-disable no-debugger */
const FilesListingForSharedEmployee  = ({ selectedFolder }) => {

    const [ getEmployeefiles, {data} ] = useGetEmployeeFilesMutation();

    useEffect(() => {
        if(selectedFolder){
            getEmployeefiles(selectedFolder);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps        
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ selectedFolder ]);
    
    // icons code
    const iconMap = {
      jpg: '/images/doc_icons/gallery.png',
      png: '/images/doc_icons/gallery.png',
      pdf: '/images/doc_icons/pdf.png',
      txt: '/images/doc_icons/file.png',
      docx: '/images/doc_icons/word.png',
      xlsx: '/images/doc_icons/sheets.png',
      csv: '/images/doc_icons/sheets.png'
      // zip: '/images/doc_icons/zip.png'
      // Add more file extensions and corresponding icons as needed
    };
   
  return (
    <>   
    <div className="p-4 grid lg:grid-cols-10 gap-2 max-[420px]:grid-cols-1 grid-cols-2 sm:grid-cols-4 md:grid-cols-6">
    {
                            data?.documents?.length > 0 ? (
                                data?.documents?.map((item, index) => {

                                  const extension = (item?.name.split('.').pop());
                                return (
                                 
                                  <div key={index} className="text-center my-5 relative">
                                   <div className="border  p-2 pt-5 mb-2 w-full rounded h-[100px] flex flex-wrap items-center justify-center text-xl">
                                   { (extension && iconMap[extension.toLowerCase()]) ?
                                  <img src={process.env.PUBLIC_URL + iconMap[extension.toLowerCase()]} alt="File Icon" width='44px' height='44px' />
                                      :
                                    // If extension not found, you can render a default icon or text
                                    <span>File</span> }
                                    </div>
                                     <div className="flex  absolute top-2 right-2">
                                  <a
                                    href={item?.file_name} 
                                    download={item?.name}
                                    className="fa fa-download text-[13px] text-white p-1 rounded primary-text w-6 ml-1"
                                  />                                
                                  </div>
                                    <p>{item?.name ?? ''}</p>
                                  </div>
                                  
                                );
                              })
                            )
                              :
                              ''
                          }  
    </div>
      
    </>
  );
};

export default FilesListingForSharedEmployee;
