/* eslint-disable no-unused-vars */

import React from "react";
import { Link } from "react-router-dom";
import moment, * as moments from "moment";

export function AbsenceThisWeek({ thisWeekAbsence, tableNames }) {
  return (
    <div className="overflow-x-auto w-full whitespace-nowrap">
      <table className="table-auto table  w-full ">
        <thead className="thead-dark ">
          <tr>
            {tableNames?.map((name, index) => {
              return (<th key={index} className="w-50">{name}</th>);
            })}
          </tr>
        </thead>
        <tbody>
          {thisWeekAbsence?.length>0 ?
            thisWeekAbsence.map((thisWeek, index) => {

              let fullname = thisWeek?.employeeInfo ? thisWeek?.employeeInfo?.first_name + " " + thisWeek?.employeeInfo?.last_name
                : thisWeek?.first_name;
                { /* const date = moment(thisWeek?.start_date).format("DD MMM");  */ }
                
                const dt = new Date(thisWeek?.start_date);
              const daysOfWeek = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
              const dayName = daysOfWeek[dt.getDay()];

              return (
                <tr key={index}>
                  <td>
                    <Link to={`/employees/detail/${thisWeek?.employee_id}`}  className="listImage flex items-center">
                      {fullname}
                    </Link>
                  </td>
                  <td>{dayName}</td>
                </tr>
              );
            })
          :
          <tr>
            <td colSpan="12" className="text-center mt-3 text-red-700 "> 
            <div className=" flex justify-center items-center  ">
            <p className="mt-5 pt-2">
            No employees absent this week.
            </p>
            </div>

            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  );
}
