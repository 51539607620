import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';

export const deptApi = createApi({
    reducerPath: "deptAPI",
    baseQuery: fetchBaseQuery({
      baseUrl: ApiUrl
}),
endpoints: (builder) => ({
 
getAllDept:builder.mutation({
      query: (page=1) => ({
        url: `/department?page=${page}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
  getAllManagerList:builder.mutation({
    query: () => ({
      url: '/department/all/list',
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: localStorage.getItem('accessToken')
      }
    })
  }),
createDept: builder.mutation({
      query: (payload) => ({
        url: '/department',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
  }),
  updateDept: builder.mutation({
    query: (payload) => {
       const { id, ...body } = payload;
      return {
        url: `/department/${id}`,
        method: 'PUT',
        body,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      };
    },
    invalidatesTags: [ 'Post' ]
  }),
  deleteDept: builder.mutation({
    query: (id) => ({
      url: `/department/${id}`,
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
  }) 

}),

onError: (error
  //  { dispatch, getState }
   ) => {
  if (error.status === 401) {
    alert("Invalid Token Error");
    // Handle 401 Unauthorized error here
    // For example, you can dispatch an action to update the auth state:
    // dispatch(authActions.setAuthenticated(false));
    // Or show a notification/alert to the user:
    // showNotification("You are not authorized to perform this action.");
  }
}
});

export const { useGetAllDeptMutation, useCreateDeptMutation, useUpdateDeptMutation, useDeleteDeptMutation, useGetAllManagerListMutation } = deptApi;