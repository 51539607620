/* eslint-disable no-empty-pattern */
import { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from 'react-csv';
import { Editor } from '@tinymce/tinymce-react';
export function FormInput({
  inputType,
  inputName,
  inputID,
  value,
  handlerChange,
  disableVal = false,
  checked
}) {

  return (
    <>
      <input
        disabled={disableVal}
        type={inputType}
        className="formControl"
        id={inputID}
        name={inputName}
        defaultValue={value}
        checked={checked}
        onChange={(e) => handlerChange(e)}
      />
    </>
  );
}

export function DateFiled({ handlerChange, value, dateFormat }) {
  return (
    <>
      <DatePicker
        dateFormat={dateFormat}
        className="formControl"
        selected={value}
        onChange={handlerChange}
      />
    </>
  );
}

export function FormSelect({ optionValue, optionName, selectID, disabled }) {
  return (
    <>
      <option label={optionName} value={optionValue} id={selectID} disabled={disabled}> {optionName}</option>
    </>
  );
}

export function FormTextarea({ textName, handlerChange, value, inputID, disabled }) {
  return (
    <>
      <textarea
        className="formControl h-24"
        name={textName}
        onChange={handlerChange}
        defaultValue={value}
        id={inputID}
        disabled={disabled}
      />
    </>
  );
}

export function WorkShift({ optionValue }) {
  return (
    <>
      <select className="p-1 px-1 border rounded text-sm  md:w-auto w-full">
        <option>{optionValue}</option>
      </select>
    </>
  );
}

export function ExportCsvFile({ tableData, nameCsv }) {
  return (
    <>
      <button className="btn-md btn-add " id="exportCsv" style={{ minHeight: "auto", padding: "0" }}>

        <CSVLink className="exportCsvClass"
          data={tableData ? tableData : ""} filename={nameCsv} >
          <span className="fa fa-file-export mr-2" />
          Export to CSV
        </CSVLink>
        {/* Export CSV File */}
      </button>
    </>
  );
}


export function EditorField() {
  const editorRef = useRef(null);
  return (

    <>

      <Editor
        onInit={(evt, editor) => editorRef.current = editor}

        init={{
          height: 300,
          menubar: true,

          language: "en_SA",
          directionality: 'ltr',
          selector: 'textarea',
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',



          contentStyle: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    </>
  );

}
