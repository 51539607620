import {useState, useEffect, useMemo} from 'react';
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import NoDataFound from '../../../helper/noDataFound';
import  moments from 'moment';
import OvertimeDetails from './OvertimeDetails';
import Pagination from "../../../components/inc/pagination";
import ApproveCancel from './ApproveCancel';
import { LoadingIndicator } from '../../../components/loading-indicator';
import { useSelector } from 'react-redux';

const ApprovedOverTime = ({isLoading, allApproveToOverTime, employeeOverTimeApporve, employeeId}) => {

  //selector for carrying out data from redux
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);

  //States and hooks
  const [ open, setOpen ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(0);
  const [ approvedList, setApprovedList ] = useState(); 
  const pageSize = 10 ;
  const closeModal = () => {
    setOpen(false);
  };
  
  useEffect(() => {
    if(allApproveToOverTime) {
      let totalPages = Math.ceil(allApproveToOverTime.length /pageSize);
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allApproveToOverTime?.slice(firstPageIndex, lastPageIndex);
      setApprovedList(data);
      setPages(totalPages);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ allApproveToOverTime ]);

  // eslint-disable-next-line no-unused-vars
  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    const data = allApproveToOverTime?.slice(firstPageIndex, lastPageIndex);
    setApprovedList(data);
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ page ]);

  // eslint-disable-next-line no-unused-vars
  const decrementPage = () => {
    const lastPageIndex = (page-1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allApproveToOverTime?.slice( lastPageIndex, firstPageIndex);
    setApprovedList(data);
  };
    
    return (
        <div className="overflow-x-auto w-full whitespace-nowrap">
                <table className="table-auto table  w-full">
                  <thead className="thead-dark ">
                    <tr>
                      <th className="w-40"> Employee</th>
                      <th>Overtime Type</th>
                      <th>Date Type</th>
                      <th>Time Period</th>
                      <th>Approved By</th>
                      <th>Duration</th>
                      <th>Date Approved</th>
                      <th>Time in Lieu</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  {isLoading ?<LoadingIndicator/> :
                  <tbody>
                  {allApproveToOverTime?.length > 0 ?                      
                  <> 
                  {approvedList && approvedList.map((over, index) => {

                    //over?.duration format to hours and min 
                    const hours = Math.floor(over?.duration / 60);
                    const minutes = over?.duration % 60;
                    let formattedDuration = '';
                    //if duration is less than 1 hour then do not show hour 
                    if (hours > 0) {
                      formattedDuration = `${hours} hour${hours !== 1 ? 's' : ''}`;
                    }
                    //if min = 0 then do not show min 
                    if (minutes > 0) {
                      formattedDuration += `${formattedDuration ? ' ' : ''}${minutes} min${minutes !== 1 ? 's' : ''}`;
                    }

                    // removing seconds in time period code
                    const startTimeArray = over?.start_time?.split(':');
                    const endTimeArray = over?.end_time?.split(':');
                    const formattedStartTime = startTimeArray?.slice(0, 2).join(':');
                    const formattedEndTime = endTimeArray?.slice(0, 2).join(':');
                    //---------------------------
                    return (
                    <tr key ={index}>
                      <td>
                        <Link
                          to={`/employees/detail/${over?.employees_id}`}
                          className="listImage flex items-center "
                        >
                          {`${over?.employeeInfo?.first_name} ${over?.employeeInfo?.last_name}`}
                        </Link>
                      </td>
                      <td>{over?.mOverTimeInfo?.overtime_name}</td>
                      <td>{moments(over?.start_date).format(formattedDate)}</td>
                      <td>{formattedStartTime}-{formattedEndTime}</td>
                      <td>{over?.approvedByInfo?.first_name}</td>
                      <td>{formattedDuration}</td>
                      <td>{moments(over?.approved_at).format(formattedDate)}</td>
                      <td>{over?.time_in_lieu === '1' ? 'Yes' : 'No'}</td>
                      <td>
                      <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa fa-eye" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="overtimeDetailClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <OvertimeDetails overtimeDetails={over}/>
                            </div>
                          )}
                        </Popup>
                      

                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn cancelBtn"
                            >
                              <i className="fa-solid fa-xmark" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="overtimeCancelClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <ApproveCancel over={over}
                              close={close}
                              employeeOverTimeApporve={employeeOverTimeApporve}
                              employeeId={employeeId}
                              />
                            </div>
                          )}
                        </Popup>
                      </td>
                    </tr>
                      );
                    })}
                    </> :
                  <center className="mt-4">{<NoDataFound/>}</center>}
                  </tbody>}
                </table>
                <Pagination page={page} pages={pages} changePage={setPage} decrementPage={setPage}/>
              </div>
    );
};
export default  ApprovedOverTime;