// import { isThisQuarter } from "date-fns/esm";

/* eslint-disable no-debugger*/
export const checkValidations = (name, value) => {
    const nameRegex = /^[a-zA-Z0-9_ .-]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    const notesRegex =  /^.{5,}$/;
   
 if(name === 'first_name' || name === "firstName"){
    if (/\d/.test(value)) {
        return 'Please avoid taking number as first name';
    }
    if(value.length < 3){
        return "First Name must be greater than 3 characters";
    }
    if(nameRegex.test(value) === false){
        return "First Name must be a string";
    }else {
        return true;
    }
 }else if( name === "lastName" || name === "last_name"){
    if (/\d/.test(value)) {
        return 'Please avoid taking number as last name';
    }
    if(value.length < 3){
        return "Last Name must be greater than 3 characters";
    }else if(nameRegex.test(value) === false){
        return "Last Name must be a string";
    }else {
        return true;
    }
 }else if( name === "email"){
    if(emailRegex.test(value) === false){
        return "Please enter a valid email address";
    }else return   "";
 }else if( name === "gender"){
  if(value === "0") { return "Please Select a gender"; }else
  return "";
 }else if( name === "mobileNumber" || name === "mobile_no"){
    if(mobileRegex.test(value) === false){
        return "Please Enter a valid Mobile number";
    } else return  true;
 }else if( name === "department"){
   if(value === "0") {
    return "Please Select a department";
} else return true;
 }else if( name === "rolesId"){
    if(value === "0") {
        return "Please Select a Role";
    }
    if(!value){
        return "Please Select a Role";
    } else return true;
  }
  else if( name === "annualAllowance"){
    if(value === "") {
        return "AnnualAllowance cannot be empty";
    } else return true;
  }else if( name === "hoursOrDay"){
    if(value === "0") {
        return "Please Select a hoursOrDay";
    } else return true;
  }else if( name === "maximumLeaveCarry"){
    if(value > 100 ) {
        return "Maximum Leave Carry must be in two digits";
    } else if(value === "" ){
        return 'Maximum Leave Carry cannot be empty';
    } else return true;
  }else if( name === "notes"){
    if(notesRegex.test(value) === false){
        return "Notes must be at least five characters long";
    }else return true;
  }else if( name === "leaveApprover"){
    if(value === "0") {
        return "Please Select a leaveApprover";
    } else return true;
  }else if( name === "overtimeApprover"){
    if(value === "0") {
        return "Please Select a overtimeApprover";
    } else return true;
  }else if( name === "expenseApprover"){
    if(value === "0") {
        return "Please Select a expenseApprover";
    } else return true;
  }else if (name === 'annualAllowance') {
    if (!value ) {
      return 'Please fill the annual holiday allowance.';
    } else {
      return true;
    }
  }

  else if (name === 'maximumLeaveCarry') {
    if (!value) {
      return 'Please fill the maximum leave to carry forward.';
    } else {
      return true;
    }
  }
  return true;
};