import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';

export const expenseApi = createApi({
    reducerPath: "expenseAPI",
    baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
}),
endpoints: (builder) => ({ 
 
getAllMainExpenses:builder.mutation({
      query: (payload) => ({
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
    filterExpenses: builder.mutation({
      query: (name = false, page) => ({
        url: `/expenses/filter/search/?page=${page ? page : 1}&limit=10&first_name=${name}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
 
createMainExpenses: builder.mutation({
      query: (payload) => ({
        url: '/expenses',
        method: 'POST',
        body: payload,
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
           Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
  }),
  updateMainExpenses: builder.mutation({
    query: (payload) => {
       const { id, ...body } = payload;
      return {
        url: `/expenses/${id}`,
        method: 'PUT',
        body,
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      };
    },
    invalidatesTags: [ 'Post' ]
  }),
  deleteMainExpenses: builder.mutation({
    query: (id) => ({
      url: `/expenses/${id}`,
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
  }),
  getAllMainExpensesCSVRecord:builder.mutation({
    query: (payload) => ({
      url: payload,
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: localStorage.getItem('accessToken')
      }
    })
  }),
  
  deleteByFile: builder.mutation({
    query: (id) => ({
      url: `expenses/delete/file/${id}`,
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
  })
})
});

export const { useFilterExpensesMutation, useGetAllMainExpensesMutation, useCreateMainExpensesMutation, useUpdateMainExpensesMutation, useDeleteMainExpensesMutation, useGetAllManagerListMutation,  useGetAllMainExpensesCSVRecordMutation,
  useDeleteByFileMutation
} = expenseApi;