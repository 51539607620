/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import DatePicker from "react-datepicker";
import { FormInput, FormTextarea, FormSelect } from "../../components/formInput";
import { nameValidator } from "./validation";
import PrimaryButton from "../../components/primaryButton";
import "react-datepicker/dist/react-datepicker.css";
import { tableData, CompanyRestrictedTypes} from "../../components/tableData";
import TableContent from "../../components/tableContent";
import {useGetAllRestrictedHolidayMutation, useCreateRestrictedHolidayMutation, useUpdateRestricedHolidayMutatio, useDeleteRestricedHolidayMutation} from '../../services/restrictedHolidayApi';
import {useCreateHolidayMutation, useGetAllHolidayMutation, useUpdateHolidayMutation, useDeleteHolidayMutation} from "../../services/holidayApi";
import { useDispatch, useSelector } from "react-redux";
import {addRestrictedHoliday, restrictedHolidayInfo, updatedRestrictedHoliday, deleteRestrictedHoliday } from '../../redux/RestrictedSlice/restrictedHolidaySlice';
import { ToastContainer, toast } from "react-toastify";
import moment, * as moments from 'moment';
import Pagination from "../../components/inc/pagination";
import {useSearchParams} from 'react-router-dom';
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import { formatDate } from "../../helper/LeaveHelper";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";

const CompanyRestrictedDays = ({ restrictedDaysCreatePermissions, restrictedDaysDeletePermissions, restrictedDaysUpdatePermissions}) => {
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const ignore = useRef(false);
  const[ getAllRestrictedHoliday, {data: allData, isSuccess: isSuccessAll} ] = useGetAllRestrictedHolidayMutation();
  const [ createRestrictedHoliday, {data: createData, isSuccess: isSuccessCreate, isError:isErrorCreate, isLoading: isLoadingCreate} ] = useCreateRestrictedHolidayMutation();
  const [ updateHoliday, {data: updateHolidayData, isSuccess: isSuccessUpdate, isError: isErrorUpdate, isLoading: isLoadingUpdate} ] = useUpdateHolidayMutation();
  const [ deleteHoliday, {data: deleteHolidayData, isSuccess: isSuccessDelete, isError: isErrorDelete, isLoading: isLoadingDelete} ] = useDeleteHolidayMutation();
  const [ getDepartmentList, {data:departmentApiResponse, isSuccess: isSuccessDepartmentApi} ] = useGetDepartmentListMutation();
  const [ open, setOpen ] = useState(false);
  const [ checked, setChecked ] = useState(false);
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ holidayId, setHolidayId ] = useState('');
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);
  const [ allDepartmentListData, setAllDepartmentListData ] = useState();
  const dispatch = useDispatch();
  const today = new Date(); 
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const [ error, setError ] = useState(
    {
      nameError: '',
      deptError: ''   
    }
  );
  const [ formData, setFormData ] = useState({
    name: '',
    start_date:'',
    end_date:'',
    is_restricted:"1",
    is_restricted_for_all:"0",
    description:"",
    department_id:""
  });

  const closeModal = () => {
    setOpen(false);
    setStartDate();
    setEndDate();
    setFormData({
      name: '',
      start_date:'',
      end_date:'',
      is_restricted:"1",
      is_restricted_for_all:"0",
      description:"",
      department_id:"",
      location_id:"0",
      "days": "1"
    });
  };

  const toastId = useRef(null);
  const getAllRestrictedHolidayState = useSelector((state) => state.restrictedHolidayState);
  const {restrictedHoliday} = getAllRestrictedHolidayState;
 
  useEffect(() => {
    if (!ignore.current){
    getDepartmentList();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
useEffect(() => {
  if(isSuccessDepartmentApi){
    dispatch(departmentListAction(departmentApiResponse));
  }
 },
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessDepartmentApi ]);

  useEffect(() => {
    if(departmentApiData){
      setAllDepartmentListData(departmentApiData?.data);
    }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ departmentApiData ]);


 
  useEffect(() => {
    getAllRestrictedHoliday(page);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

  useEffect(() => {
    //eslint-disable-next-line no-undef
    if (isSuccessAll) {
      if(allData?.data?.length < 1 && allData?.currentPage >1 ) {
        getAllRestrictedHoliday(allData?.currentPage - 1);
      }
      setPages(allData?.totalPages);
    } 
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessAll ]);

  const decrementPage = () => {
    getAllRestrictedHoliday(allData?.currentPage - 1 );
    setPage(allData?.currentPage - 1);
  };

  const handlerChange = (e) => {
    setChecked(false);
    e.preventDefault();
    const { name, value } = e.target;
  
    if (name === 'name') {
      if (value === '') {
        setError({ ...error, nameError: 'Enter the holiday Name' });
      } else {
        setError({ ...error, nameError: '' });
      }
    } else if (name === 'department_id') {
      if (value === '') {
        setError({ ...error, deptError: 'Enter the holiday Name' });
      } else {
        setError({ ...error, deptError: '' });
      }
    }
  
    if (value === '1') {
      setChecked(true);
      setFormData({ ...formData, [name]: value === '1' ? '1' : '0', "is_restricted_for_all" : "1"});
      
    } else {
      setChecked(false);
      setFormData({ ...formData, [name]: value,  "is_restricted_for_all" : "0"  });
    }
  };
  
  
  const validForm = () => {
    const {name, department_id, start_date, end_date} = formData;
    let isFormValidated = true;
    if(name === '') {
      setError((errorValue) => {
        return {
          ...errorValue,
          nameError: "Please enter the name ",
          deptError: "Select the department"
        };
      });
      isFormValidated = false;
    } 
    else if(department_id === '' && formData?.is_restricted_for_all === '0') {
      setError((errorValue) => {
        return {
          ...errorValue,         
          deptError: "Select the dept first"
        };
      });
      isFormValidated = false;
    }
    
    return isFormValidated;
  };
  
 
  const handlerDateChange = (e, name) => {
   
     if(name === 'startDate') {  
       setFormData((formValue) => {
       return {
        ...formValue, ['start_date']: e
       };
      }); 
       setStartDate(e);
       setEndDate(e);
    }   
     if(name === 'endDate') {  
      setFormData((formValue) => {
        return {
         ...formValue, ['end_date']: e
        };
       }); 
       setEndDate(e);
      }
  };

  const handlerChangCheck = (e, name) => {
    
    e.persist();
    // setChecked(e.target.checked);
    setFormData({...formData, [name]: "1"});
  };
 


  useEffect(() => {
    if(isLoadingCreate) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
     }
    if(isSuccessCreate) {
     
      toast.update(
        toastId.current,
        ("Restricted days created successfully!",
        {
          render: "Restricted days created successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
      getAllRestrictedHoliday(page);
    }
    if (isErrorCreate) {
      const toastMessage = 
      // apiError.data.message
      //   ? apiError.data.message
      //   :
         "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessCreate, isErrorCreate, isLoadingCreate ] );

  useEffect(() => {
    if(isLoadingUpdate) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
     }
    if(isSuccessUpdate) {
     
      toast.update(
        toastId.current,
        ("Restricted days updated successfully!",
        {
          render: "Restricted days updated successfully!",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
     
      getAllRestrictedHoliday(page);
    }
    if (isErrorUpdate) {
      const toastMessage = 
      // apiError.data.message
      //   ? apiError.data.message
      //   :
         "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessUpdate, isErrorUpdate, isLoadingUpdate ] );


  const handlerSave = async(e, edit= false, close, restrict) => {
    e.preventDefault();
      if(edit) {
        const value= {
          name : formData.name? formData.name: restrict.name,
          start_date: formData.start_date ?  formData.start_date:  restrict.start_date,
          end_date: formData.end_date ?  formData.end_date :  restrict.end_date,
          department_id:  formData?.department_id?.toString() ?  formData?.department_id?.toString() : restrict?.department_id.toString(),
          id: restrict.id,
          description: formData?.description ? formData?.description : restrict?.description,
          is_restricted_for_all: formData?.is_restricted_for_all ? formData?.is_restricted_for_all : restrict?.is_restricted_for_all,
          is_restricted:"1",
          location_id: '1',
          "days": "1"
        };
        updateHoliday(value);
        close();
      } else {
        if(validForm()) {
          const value= {
            name : formData.name? formData.name: restrict.name,
            start_date: formData.start_date ?  formData.start_date: new Date(),
            end_date: formData.end_date ?  formData.end_date :   new Date(),
            department_id: formData?.department_id?.toString(),
            description: formData?.description,
            is_restricted_for_all: formData?.is_restricted_for_all,
            location_id: '1',
            is_restricted:"1",
            "days": "1"
          };
        createRestrictedHoliday(value);
        close();
        }
      }
  };

  const onEditClick = (e, index) => {
    e.preventDefault();
    setHolidayId(index);
    setOpen(false);
    setOpenEdit(true);
  
    const holidayData = restrictedHoliday && restrictedHoliday.data.find((holiday) => holiday.id === index);
    setStartDate(holidayData.start_date);
    setEndDate(holidayData.endDate);
     const d = {...formData, ...holidayData};
     setFormData(d);
    
  };

  const getDeleteDataHoliday = () => {
    // if(isSuccessDelete) {
    //   // setOpenDelete(false);
    //   // dispatch(deleteRestrictedHoliday(holidayId));
    //   // closeModal();
    // }
  };

   useEffect(() => {
   
    if(isLoadingDelete) {
      toastId.current = toast.loading("Please wait...", {
        toastId: 'delete1',
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
     }
    if(isSuccessDelete) {
      // getDeleteDataHoliday();
     
      toast.update(
        toastId.current,
        ("Restricted day deleted successfully!",
        {
          toastId: 'restrictDelete',
          render: "Restricted day deleted successfully!",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
      getAllRestrictedHoliday(page);
    }
    if (isErrorDelete) {
      const toastMessage = 
      // apiError.data.message
      //   ? apiError.data.message
      //   :
         "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isErrorDelete, isSuccessDelete, isLoadingDelete ]);
  
  const handlerDelete = (e, id, close) => {
    e.preventDefault();
    deleteHoliday(id);
   close();
  };
   

   return (
    <>
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <div>
          <h3 className=" font-semibold text-lg">Company Restricted Days</h3>
          <p>Users can't book time off during this period.</p>
        </div>
        {restrictedDaysCreatePermissions[0]?.status === true &&
        <Popup
          open={open}
          onClose={() => closeModal()}
          closeOnDocumentClick={false}
          trigger={
            <button
              className="btn-md btn-add"
              id="AddRestrictedDays"
              onClick={() => setOpen((o) => !o)}
            >
              <span className="fa fa-plus mr-2" />Add Restricted Days
            </button>
          }
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <button
                className="close"
                id="AddRestrictedClose"
                onClick={() => {
                  closeModal();
                  close();
                }}
              >
                &times;
              </button>
              <div className="header">
                <h3>Add Company restricted days</h3>
              </div>
              <div className="content">
                <form onSubmit ={(e) => handlerSave(e, false, close, '')}>
                  <div className="flex flex-wrap ">
                    <div className="lg:w-1/2 w-full md:pr-6 ">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Name
                        <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="name"
                          inputID="Name"
                          handlerChange={handlerChange}
                        />
                        <div className="help-block text-red-700 mt-2">{error.nameError ?error.nameError : ''} </div>
                      </div>
                    </div>
                    <div className="lg:w-1/2 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Who for <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
                        <select className="formControl" id="taskDepartment" name="department_id" value={formData.department_id} defaultValue={allData?.data?.department_id} selected={formData.department_id} onChange={(e) => handlerChange(e) }
                        // disabled={checked}
                        >
                        <FormSelect 
                            optionValue={"0"}
                            optionName={"Select Department"} 
                            // optionValue={"1"}
                            // optionName={"Select All Department"}
                            />;
                          <FormSelect optionValue="1" optionName={"All Departments"}  />

                          {allDepartmentListData && allDepartmentListData.length > 0 && allDepartmentListData.map((dept, index) => {

                            
                            return<FormSelect  key={index}
                                optionValue={dept?.id} 
                                optionName={dept?.department_name} /> ;
                         })}
                         
                        </select>
                        <div className="help-block text-red-700 mt-2">{error.deptError ?error.deptError : ''} </div>
                        <div className="help-block" />
                      </div>
                    </div>

                    <div className="lg:w-1/2 lg:pr-6 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Start Date</label>
                        <DatePicker
                          showIcon
                          id="taskStartDate"
                          dateFormat="dd/MM/yyyy"
                          className="formControl"
                          name = "star_date"
                          selected={formData.start_date ? startDate : new Date()}
                          onChange={(e) => handlerDateChange(e, "startDate")}
                          selectsStart
                           startDate={startDate}
                           minDate={today}
                        />
                        

                        <div className="help-block" />
                      </div>
                    </div>
                    <div className="lg:w-1/2 w-full lg:pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">End Date</label>
                        <DatePicker
                          showIcon
                          id="taskEndDate"
                          dateFormat="dd/MM/yyyy"
                          className="formControl"
                          name = "end_date"
                          selected={formData.end_date ? endDate: new Date()}
                          onChange={(e) => handlerDateChange(e, "endDate")}
                          minDate={startDate ? startDate : today}
                        />
                      
                        <div className="help-block" />
                      </div>
                    </div>
                    <div>
                         
                         {/* <label className="flex text-sm items-center pb-3">
                             <input type="checkbox" className="mr-2 w-5 h-5" value="1"  defaultChecked={checked}  onChange={(e) => handlerChangCheck(e, 'is_restricted_for_all') } /> Restricted for all</label> */}
                        
                    </div>
                    <div className=" w-full ">
                      <div className="form-group mb-6 ">
                        <label className="formBlock" >Reason</label>
                        <FormTextarea textName="description" inputID="reason" handlerChange = {handlerChange} />
                        <div className="help-block" />
                      </div>
                    </div>

                    <div className="lg:w w-full  ">
                      <div className="w-full mt-4 justify-center flex">
                        <div className="form-group pt-4 pb-3 flex  ">
                          <PrimaryButton
                            Btnclass="btn save mr-2"
                            btnText="Save"
                            buttonID="restrictedSave"
                            onClick = {(e) =>  handlerSave(e, false, close, '')}
                          />
                          <PrimaryButton
                            Btnclass="btn cancel "
                            btnText="Cancel"
                            id="restrictedCancel"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Popup> }
      </div>

      <div className="p-4">
        <div className="overflow-x-auto w-full whitespace-nowrap">
          <table className="table-auto table  w-full">
            <thead className="thead-dark ">
               <tr>
                <th className="w-40">Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Department</th>
                <th>Action</th>
              </tr> 
            </thead>
            <tbody>{
              allData?.data?.length > 0 ?
              <>
              {allData && allData?.data && allData.data.map((restrict, index) => (
                
             <tr key={index}>
                <td>{restrict?.name}</td>
                <td>{moment(restrict?.start_date).format(formattedDate)}</td>
              <td>{moment(restrict?.end_date).format(formattedDate)}</td>
                <td>{restrict?.department_name}</td>
                <td>
                  {restrictedDaysUpdatePermissions[0]?.status === true ? 
                  <Popup
                    open={open}
                    onClose={() => closeModal()}
                    closeOnDocumentClick={false}
                    trigger={
                      <div
                        onClick={() => setOpen((o) => !o)}
                        className="actionBtn"
                      >
                        <i className="fa fa-pencil"/>
                      </div>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button
                          className="close"
                          onClick={() => {
                            closeModal();
                            close();
                          }}
                        >
                          &times;
                        </button>
                        <div className="header">
                          <h3>Edit Company restricted days</h3>
                        </div>
                        <div className="content">
                          <form>
                            <div className="flex flex-wrap ">
                              <div className="lg:w-1/2 w-full md:pr-6 ">
                                <div className="form-group mb-6 ">
                                  <label className="formBlock	">Name</label>
                                  <FormInput
                                    inputType="text"
                                    inputName="name"
                                    inputID="Name"
                                    value= {restrict.name}
                                    handlerChange={handlerChange}
                                  />
                                  <div className="help-block" />
                                </div>
                              </div>
                              <div className="lg:w-1/2 w-full">
                                <div className="form-group mb-6 ">
                                  <label className="formBlock	">Who for</label>
                                  <select
                                     className="formControl"
                                      id="taskDepartment"
                                     name="department_id"
                                     value={formData.department_id}
                                     onChange={handlerChange}
                                   >
                                  <FormSelect optionValue="0" optionName="Select Department" />
                                  {allDepartmentListData && allDepartmentListData.map((dept, index) => (
                                   <FormSelect key={index} optionValue={dept.id.toString()} optionName={dept.department_name} />
                                   ))}
                                  </select>
                                  <div className="help-block" />
                                </div>
                              </div>

                              <div className="lg:w-1/2 lg:pr-6 w-full">
                                <div className="form-group mb-6 ">
                                  <label className="formBlock	">
                                    Start Date
                                  </label>
                                  <DatePicker
                                    showIcon
                                    id="editTaskStartDate"
                                    dateFormat="dd/MM/yyyy"
                                    className="formControl"
                                    selected={startDate ? startDate : new Date(restrict.start_date)}
                                    onChange={(e) => handlerDateChange(e, "startDate")}
                                    selectsStart
                                    startDate={startDate}
                                    minDate={new Date()}
                                 />

                                  <div className="help-block" />
                                </div>
                              </div>
                              <div className="lg:w-1/2 w-full lg:pr-0">
                                <div className="form-group mb-6 ">
                                  <label className="formBlock	">End Date</label>
                                  <DatePicker
                                    showIcon
                                    id="editTaskEndDate"
                                    dateFormat="dd/MM/yyyy"
                                    className="formControl"
                                    selected={endDate ? endDate : new Date(restrict.end_date)}
                                    onChange={(e) => handlerDateChange(e, "endDate")}
                                    minDate={startDate ? startDate : today}
                                 />
                                  <div className="help-block" />
                                </div>
                              </div>
                              <div className=" w-full ">
                                <div className="form-group mb-6 ">
                                  <label className="formBlock	">Reason</label>
                                  <FormTextarea textName="description" inputID="editTaskReason" handlerChange = {handlerChange} value={restrict?.description}/>
                                  <div className="help-block" />
                                </div>
                              </div>

                              <div className="lg:w w-full  ">
                                <div className="w-full mt-4 justify-center flex">
                                  <div className="form-group pt-4 pb-3 flex  ">
                                    <PrimaryButton
                                      Btnclass="btn save mr-2"
                                      btnText="Update"
                                      buttonID="editTaskUpdate"
                                      onClick = {(e) =>  handlerSave(e, true, close, restrict)}
                                    />
                                    <PrimaryButton
                                      Btnclass="btn cancel "
                                      onClick={() => close()}
                                      btnText="Cancel"
                                      buttonID="editTaskCancel"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </Popup> : <></> }
                  {restrictedDaysDeletePermissions[0]?.status === true ? 
                  <Popup
                    open={open}
                    onClose={() => closeModal()}
                    closeOnDocumentClick={false}
                    trigger={
                      <div
                        onClick={() => setOpen((o) => !o)}
                        className="actionBtn cancelBtn"
                      >
                        <i className="fa fa-trash"/>
                      </div>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button
                          className="close"
                          id="taskDeleteClose"
                          onClick={() => {
                            closeModal();
                            close();
                          }}
                        >
                          &times;
                        </button>
                        <div className="header">
                          {" "}
                          <h3>Delete</h3>
                        </div>
                        <div className="content">
                          <p>Are you sure you want to delete this ?</p>
                          <div className="w-full mt-8">
                            <div className="form-group pt-4 pb-3 flex justify-center ">
                              <button className="btn save mr-2" id="taskDeleteYes" onClick={(e) => handlerDelete(e, restrict.id, close)}>Yes</button>
                              <button
                                id="taskDeleteNo"
                                type="button"
                                onClick={() => close()}
                                className="btn cancel "
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup> : <></>}
                </td>
              </tr>
             
              ))}
              </> : 
              <center className="text-red-700 mt-4 ml-[240%]">No Data Found</center>}
            </tbody>
            

          </table>
          <Pagination page={page} pages={pages} changePage={setPage}/>
          {/* <TableContent
          data={restrictedHoliday && restrictedHoliday.data ?restrictedHoliday.data : '' }
          columns={columns} */}

          {/* /> */}
         
        </div>
      </div>
    </>
  );
};

export default CompanyRestrictedDays;