/* eslint-disable no-debugger*/
/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiStatusHandler } from '../helper/apiErrorHandler';
import { ApiUrl } from '../constant';
export const dashboardApi = createApi({
    reducerPath: "dashboardAPI",
    baseQuery: fetchBaseQuery({ baseUrl: ApiUrl
    }),
    endpoints: (builder) => ({
      getAllDashborad:builder.mutation({
          query: (id) => ({
            url: `/reports/get-dashboard-reports?company_id=${id}`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization:  localStorage.getItem('accessToken')
            }
          })
        }),
        getEmployeeDashborad:builder.mutation({
          query: () => ({
            url: `employee/dashboard`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization:  localStorage.getItem('accessToken')
            }
          })
        }),
        getAllChartDashborad:builder.mutation({
          query: (payload) => ({
            url: payload,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization:  localStorage.getItem('accessToken')
            }
          })
        }),
        postAllDashboard: builder.mutation({
          query: (payload) => {
            return {
              url: '/reports/send-message',
              method: 'POST',
              body: payload,
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization:  localStorage.getItem('accessToken')
              }
            };
          }
        }),
        getAllMessage: builder.mutation({
          query: (payload) => {
            return {
              url: '/reports/message',
              method: 'GET',
              body: payload,
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization:  localStorage.getItem('accessToken')
              }
            };
          }
        })
    }),
    

    onError: (error
      //  { dispatch, getState }
       ) => {
        
      if (error.status === 401) {
        apiStatusHandler(error.status);
        alert("Invalid Token Error");
        localStorage.removeItem('accessToken');
        // Handle 401 Unauthorized error here
        // For example, you can dispatch an action to update the auth state:
        // dispatch(authActions.setAuthenticated(false));
        // Or show a notification/alert to the user:
        // showNotification("You are not authorized to perform this action.");
        
      }
    }
  });
  
  
  
  export const { useGetAllDashboradMutation, usePostAllDashboardMutation,
    useGetAllChartDashboradMutation,
    useGetEmployeeDashboradMutation,
    useGetAllMessageMutation
  } = dashboardApi;
  
