/* eslint-disable camelcase */
import {useEffect, useState, useRef} from "react";
import { toast } from "react-toastify";
import {
    
    FormTextarea
  } from "../../../components/formInput";
  import PrimaryButton from "../../../components/primaryButton";
  import {useChangesStatusApporveMutation} from '../../../services/approverListApi';
  

  const CancelLeavesApprover = ({leave, getLeaveApproveList, employeeId, close}) => {
    const [ changesStatusApporve, { isSuccess, isError, isLoading, error: apiError} ] = useChangesStatusApporveMutation();
    const toastId = useRef(null);
    const [ formData, setFormData ] = useState({
        "status":"2",
        notes:''
      });
      const [ error, setError ] = useState({
        errorNotes: ''
      });
      const closeModal = () => {
        close();
      };
  
      const handlerChange = (e) => {
        e.preventDefault();
        const {name} = e.target;
        if (name === 'notes') {
            if(name === '') {
              setError({...error, ['errorNotes '] : 'Please enter the notes'});
            } else {
              setError({...error, ['errorNotes'] : ''});
            }
          }
        setFormData({...formData, [name]: e.target.value});
    };

    const validForm = () => {
        const { notes } = formData;
        let isFormValid = true ;
        if(notes === '') {
            setError((errorValue) => { 
            return {
              ...errorValue,
              errorNotes: 'Please enter notes '
            };
          });
          isFormValid = false;
        } 
        return isFormValid;
    };

      useEffect(() => {
        if(isLoading){
          toastId.current = toast.loading("Please wait...", {
            // position: "top-center",
            position:"top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            type: "info"
          });
        }
        if(isSuccess) {
          //const filteredData = leaveApproveData.filter((f) => f.id !== deletedId);  
          // setLeaveApproveData(filteredData);
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 10000);
            toast.update(
            toastId.current,
            ("Leave Cancelled Successfully.",
            {
              render: "Leave Cancelled Successfully.",
              type: "success",
              autoClose: 8000,
              isLoading: false
            })
          );
          let payload = `/leaves/?leave_approver=${employeeId}&type=1` ;
          getLeaveApproveList(payload);
          close();
        }
        if (isError) {
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 10000);
          const toastMessage = 
          apiError.data.message
            ? apiError.data.message
            :
             "Something went wrong";
          toast.update(
            toastId.current,
            (toastMessage,
            {
              render: toastMessage,
              type: "error",
              autoClose: 8000,
              isLoading: false
            })
          );
        }
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);

    const handlerCancel = (e, leave) => {
        e.preventDefault();
        const value = {
          status: '2',
          employee_id: `${leave?.employee_id}`,
          cancel_reason :formData?.notes,
          id: leave?.id
         };
         if(validForm()) {
         changesStatusApporve(value);
         }
         
      };
    return (
     <>
       <div className="header">
                        {" "}
                        <h3>Decline</h3>
                      </div>
                      <div className="content">
                        <form>
                          <label className="formBlock	">
                            
                            Reason
                            <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                            </label>
                          <FormTextarea textName="notes" handlerChange = {handlerChange} inputID="notesCancelLeave" />
                          <div className="help-block text-red-700 mt-1">{error?.errorNotes ? error?.errorNotes  :'' } </div>
                          <div className="w-full mt-8">
                            <div className="form-group pt-4 pb-3 flex justify-center ">
                             <button
                             id="saveCancelLeave"
                             className="btn save mr-2"
                             onClick= {(e) => handlerCancel(e, leave, close)}
                             >
                              Save
                             </button>
                              {/* <PrimaryButton
                                btnText="Save"
                                Btnclass="btn save mr-2"
                                btnype="button"
                              /> */}
                              <PrimaryButton
                                btnText="Cancel"
                                Btnclass="btn cancel"
                                btntype="button"
                                buttonID="cancelCancelLeave"
                                onClick={() => closeModal()}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
     </>
    );
  };

  export default  CancelLeavesApprover;