/* eslint-disable no-unused-vars */
import DatePicker from "react-datepicker";
import { SearchField } from "../../components/filters";
import { useCallback } from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useGetEmployeeHistoryMutation } from "../../services/employeeApi";
import Pagination from "../../components/inc/pagination";
import { FormSelect } from "../../components/formInput";
import { useSelector } from "react-redux";
import Popupfeature from "../../components/Popupfeature";
import { CSVLink } from "react-csv";
const EmployeeHistory  = () => {  

    const [ getEmployeeHistory, { data: employeeHistory, isSuccess } ] =
    useGetEmployeeHistoryMutation();
    const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
    const [ allHistoryInfo, setAllHistoryInfo ] = useState();
    const [ page, setPage ] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [ pages, setPages ] = useState(0);
    const createCsvFileName = () => `EmployeeHistory_report_${moment().format()}.csv`;
    const [ excelData, setExcelData ] = useState([]);
    const totalPages = employeeHistory?.totalPages;
    const PageIndex = employeeHistory?.currentPage;
    const { id } = useParams();

    useEffect(() => {
        if (id) {
          getEmployeeHistory(id);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps        
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ id ]);

      useEffect(() => {
        let payload =  `${id}&page=${page}`;
        getEmployeeHistory(payload); 
// eslint-disable-next-line react-hooks/exhaustive-deps        
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]); 

    const onDateChange = (e) => {
        let arr = [];
        if (id && e[0] !== null && e[1] !== null) {
          let payload = `${id}&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}`;
          getEmployeeHistory(payload);
    
        } else {
          let payload = `${id}`;
          getEmployeeHistory(payload);
        }
      };

      const filterEmployee = useCallback((searchTerm) => {
        let payload =  `${id}&name=${searchTerm}`;
        getEmployeeHistory(payload);
// eslint-disable-next-line react-hooks/exhaustive-deps
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

      const handlerCategoryFilter = (e) => {
        if(e.target.value === "0"){
          getEmployeeHistory(id);
        }
        else{
          let payload = `${id}&category=${encodeURIComponent(e.target.value)}`;
          getEmployeeHistory(payload);
        }    
      };

    useEffect(() => {
        if (isSuccess) {
          setAllHistoryInfo(employeeHistory?.data);
          if(employeeHistory?.data){
            let csvData = exportTOCSV(employeeHistory?.data);
            setExcelData(csvData);
          }
          
      }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ]);
    
        useEffect(() => {
          if(employeeHistory){
            
              const firstPageIndex = 0;
              const lastPageIndex = 10;
              const data = employeeHistory?.data?.slice(firstPageIndex, lastPageIndex);
              setAllHistoryInfo(data);
              setPages(totalPages);
          }
// eslint-disable-next-line react-hooks/exhaustive-deps          
        }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ employeeHistory ]);
        
        // const currentTableData = useMemo(() => {
        //   const firstPageIndex = 0;
        //   const lastPageIndex = 11;
        //   const data = employeeHistory?.data?.slice(firstPageIndex, lastPageIndex);
        //   setAllHistoryInfo(data);

        // }, [ page ]);
      
        // const decrementPage = () => {
        //   if(employeeHistory?.currentPage - 1 >= 0){
        //     getEmployeeHistory(employeeHistory?.currentPage - 1);
        //     setPage(employeeHistory?.currentPage - 1);
        //   }
         
        // };
        const categoryType = [
          {value :"0", label: "Select Category Type"},
          {value :"Work Schedule", label: "Work Schedule"},
          {value :"Employee Profile", label: "Employee Profile"},
          {value :"Leave Section", label: "Leave Section"},
          {value :"Signed Up", label: "Signed Up"},
          {value :"Logged In", label: "Logged In"},
          {value :"Logged Out", label: "Logged Out"}
        ];  

        let  headers = [
          { label: 'Name', key : 'name'},
          { label: 'Updated at', key : 'updatedAt'},
          { label: 'Old Value', key : 'oldValue'},
          { label: 'New Value', key : 'newValue'},
          { label: 'Description', key : 'description'},
          { label: 'Category', key : 'category'}
          ];
          const exportTOCSV = (report) => {
            let  dataAbence =[];
           if(report){
            report?.forEach((dExcel) => {
              let val ={
                name: dExcel?.name,
                updatedAt: dExcel?.updatedAt,
                oldValue: dExcel?.old_value,
                newValue: dExcel?.new_value,
                description: dExcel?.description,
                category: dExcel?.category
             };
              dataAbence.push(val);
            });
            return dataAbence;
           }
         };
  return (
    <>
      <div className="p-4">
        <div className="heading_title px-7 justify-between flex  items-center py-4 ">
          <h3 className="text-lg"> History </h3>
          <div className="headerButton" />
          <div className="mt-4 ml-[100px]">
            <CSVLink
              data={excelData}
              headers={headers}
              filename={createCsvFileName()}
              // target="_blank"
              style={{textDecoration: "none", outline: "none", height: "5vh"}}
            >
              <button className="btn-md btn-add " id="employeeHistoryCsv">
                <span className="fa fa-file-export mr-2" /> Export to CSV
              </button>
            </CSVLink>
          </div>
        </div>
        <div className="flex w-full lg:w-3/4 flex-wrap pt-2 filterSection mb-3 ">
          <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 mt-4">
            <SearchField
              placeholderText="Search By Name"
              searchId="historySearch"
              onChange={(e) => filterEmployee(e.target.value)}
            />
          </div>
          <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 p-4">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              id="datePicker"
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              className="formControl"
              placeholderText="Select Date Range"
              onChange={(update) => {
                setDateRange(update);
                onDateChange(update);
              }}
              isClearable={true}
            />
          </div>
          <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 p-4">
            <select
              className="formControl"
              onChange={(e) => handlerCategoryFilter(e)}
            >
              {categoryType?.map((category, key) => {
                return (
                  <FormSelect
                    key={key}
                    optionValue={category?.value}
                    optionName={category?.label}
                  />
                );
              })}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto w-full whitespace-nowrap">
          <table className="table-auto table  w-full">
            <thead className="thead-dark ">
              <tr>
                <th className="w-40"> Name</th>
                <th>Old Value</th>
                <th>New Value</th>
                <th>Description</th>
                <th>Category</th>
                <th>Created By</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {allHistoryInfo &&
                allHistoryInfo?.map((history, index) => {
                  return (
                    <tr key={index}>
                      <td>{history?.name}</td>

                      <td>
                        {history?.old_value ? (
                          history?.category === "Work Schedule" ? (
                            <Popupfeature
                              text={"old value"}
                              data={history?.old_value}
                            />
                          ) : (
                            history?.old_value
                          )
                        ) : (
                          "Unavailable"
                        )}
                      </td>
                      <td>
                        {history?.new_value ? (
                          history?.category === "Work Schedule" ? (
                            <Popupfeature
                              text={"new value"}
                              data={history?.new_value}
                            />
                          ) : (
                            history?.new_value
                          )
                        ) : (
                          "Unavailable"
                        )}
                      </td>
                      <td>
                        {history?.description
                          ? history?.description
                          : "Unavailable"}
                      </td>
                      <td>
                        {history?.category ? history?.category : "Unavailable"}
                      </td>
                      <td>
                        {history?.addedByForHistory?.first_name} {history?.addedByForHistory?.last_name}
                      </td>
                      <td>
                        {moment(history?.updatedAt)?.format(formattedDate)}{" "}
                        <span className="text-gray-700">
                          {moment(history?.updatedAt)?.format("hh:mm")}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          pages={pages}
          changePage={setPage}
          decrementPage={setPage}
        />
      </div>
    </>
  );
};

export default EmployeeHistory;
