/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import Popup from "reactjs-popup";
import { useEffect, useState, useRef } from "react";
import SharedEmployeeFile from "./sharedEmployeeFile";
import { useDeleteSharedEmployeeFileMutation, useGetSharedEmployeeFilesMutation } from "../../services/employeeDocumentAPI";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SharedFileData  = ({ fileSelected }) => {

    const [ sharedEmployeeFiles, { data: sharedEmployeeFilesData } ] = useGetSharedEmployeeFilesMutation();
    const [ deleteSharedEmployeeFile, { isLoading: deleteSharedEmployeeFileLoading, isSuccess: deleteSharedEmployeeFileSuccess, isError: deleteSharedEmployeeFileError } ] = useDeleteSharedEmployeeFileMutation();
    const [ open, setOpen ] = useState(false);
    const closeModal = () => {
      setOpen(false);
    };

    useEffect(() => {
        if (fileSelected?.id) {
            sharedEmployeeFiles(fileSelected?.id);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps        
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ fileSelected?.id ]);

    const deleteshareFileEmployee = (e, id) => {
        e.preventDefault();
        deleteSharedEmployeeFile(id);
    };

    const toastId = useRef(null);
    useEffect(() => {
        if (deleteSharedEmployeeFileLoading) {
            toastId.current = toast.loading("Please wait...", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            type: "info"
          });
        }
        if (deleteSharedEmployeeFileSuccess) {
          toast.dismiss(toastId.current);
          toast.success("Folder removed successfully",
            {
              render: "Folder removed successfully",
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "colored",
              type: "success"
            }
          );
          sharedEmployeeFiles(fileSelected?.id);
        document?.getElementById("closeDeleteFilePopup")?.click();
        }
        if (deleteSharedEmployeeFileError) {
          const message = "Something went wrong";
          toast.update(
            toastId.current,
            (message,
            {
              render: message,
              type: "error",
              autoClose: 5000,
              isLoading: false
            })
          );
        }
// eslint-disable-next-line react-hooks/exhaustive-deps        
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ deleteSharedEmployeeFileLoading, deleteSharedEmployeeFileSuccess, deleteSharedEmployeeFileError ]);

   
  return (
    <>
     <div className="p-6">
     <div className="flex justify-end mb-4">
      <Popup
                                    open={open}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                        <button
                                          id="closePopup"
                                          className="btn-md btn-add "
                                          aria-describedby="popup-8"
                                          onClick={() => setOpen((o) => !o)}
                                        >
                                          <span className="fa fa-folder-plus mr-2" />
                                          Share file with employee
                                        </button>
                                      }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          className="close"
                                          id="closeSharefolderPopup"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                        <h3>Share file to employee</h3>
                                        </div>
                                        
                                        <div className="content">
                                           <SharedEmployeeFile
                                           fileSelected={fileSelected}
                                           sharedEmployeeFiles={sharedEmployeeFiles}
                                           />
                                        </div>
                                      </div>
                                    )}
                                  </Popup> 
      </div>
    <div className="overflow-x-auto w-full whitespace-nowrap">
 
                                  <table className="table-auto table  w-full">
                                    <thead className="thead-dark ">
                                    <tr>
                                        <th className="w-40"> Name</th>
                                        <th>Email</th>
                                        <th>Remove</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {sharedEmployeeFilesData?.sharedFileWithEmployees?.length > 0 ? 
                                    <>
                                    {sharedEmployeeFilesData?.sharedFileWithEmployees?.length > 0 && sharedEmployeeFilesData?.sharedFileWithEmployees?.map((employeeList, index) => {
                                        return (
                                    <tr key={index}>
                                        <td>
                                        <Link
                                            to="/employees/detail/1"
                                            className="listImage flex items-center "
                                        >
                                            {employeeList?.first_name} {employeeList?.last_name} 
                                        </Link>
                                        </td>
                                        <td>{employeeList?.email}</td>
                                        <td>
                                        <Popup
                                                open={open}
                                                onClose={() => closeModal()}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                    <span onClick={() => setOpen((o) => !o)} className="fa fa-trash actionBtn cancelBtn" />
                                                }
                                                modal
                                                nested
                                            >
                                                {(close) => (
                                                <div className="modal">
                                                    <button
                                                    className="close"
                                                    id="closeDeleteFilePopup"
                                                    onClick={() => {
                                                        closeModal();
                                                        close();
                                                    }}
                                                    >
                                                    &times;
                                                    </button>
                                                    <div className="content">
                                                    <p>Are you sure you want to delete share folder for {employeeList?.first_name} {employeeList?.last_name}  ?</p>
                                                    <div className="w-full mt-8">
                                                        <div className="form-group pt-4 pb-3 flex justify-center ">
                                                        <button className="btn save mr-2" 
                                                        id="deleteYes"
                                                        onClick={(e) => deleteshareFileEmployee(e, employeeList?.id)}
                                                        >Yes</button>
                                                        <button
                                                            type="button"
                                                            id="deleteNo"
                                                            onClick={() => close()}
                                                            className="btn cancel "
                                                        >
                                                            No
                                                        </button>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                )}
                                            </Popup> 
                                        </td>
                                    </tr>
                                        );
                                        })}
                                        </> :
                                    <td className="mt-4  text-center" colSpan="12">No Data found</td>}
                                    </tbody>
                                </table>
    </div>
      </div>
    </>
  );
};

export default SharedFileData;
