import { Helmet, HelmetProvider } from "react-helmet-async";
import {useGetAllNotificationMutation,  useDeleteNotificationMutation,  useUpdateEmployeeNotificationMutation, useGetAllNotificationCountMutation} from "../../services/notesApi";
import { useEffect, useState, useRef } from "react";
import Popup from "reactjs-popup";
import {  toast } from "react-toastify";
import { LoadingIndicator } from "../../components/loading-indicator";
import moment from "moment";
import { useDispatch } from "react-redux";
import {notification, notificationCount} from '../../redux/AuthSlice/authSlice';
import NoDataFoundLeave from "../myleave/noDataFoundLeave";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NotificationNavigation } from "../../helper/notificationNavigateHandler";

const Notification = () => {
  const ignore = useRef(false);

  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
 const [ getAllNotification, {data: getNotificationRespo, isSuccess: isSuccessNotification, isLoading: isLoadingGetRespo} ] = useGetAllNotificationMutation();
 const [ deleteNotification, {isSuccess, isLoading, isError, error:apiError} ] = useDeleteNotificationMutation();
 const [ updateEmployeeNotification, {isSuccess: isSuccessUpdate} ] =  useUpdateEmployeeNotificationMutation();
 const [ getAllNotificationCount, {data: notificationCountRespo, isSuccess:isSuccessNotificationCount} ] = useGetAllNotificationCountMutation(); 
 const [ open, setOpen ] = useState(false);
 const dispatch = useDispatch();
 const closeModal = () => {
  setOpen(false);
};
const notificationData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.noticationData);
const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);

 useEffect(() => {
  if (!ignore.current){
 let  payload = `/employeenotification?employee_id=${employeeIdData}`;
  getAllNotification(payload);
}
return () => { ignore.current = true; };
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);


 useEffect(() => {
 if (isSuccessNotification) {
  getNotificationRespo?.data?.forEach((notification) => {

    updateEmployeeNotification(notification?.id);
  });
  
 }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessNotification ]);

 useEffect(() => {
  if(isSuccessUpdate) {
    let payload1 =`/employeenotification/count/?employee_id=${employeeIdData}`;
    
    getAllNotificationCount(payload1);
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessUpdate ]);

 useEffect(() => {
  if(isSuccessNotificationCount) {
    dispatch(notificationCount(notificationCountRespo?.unreadCount));
  }
  if(isSuccessNotification){
    dispatch(notification(getNotificationRespo?.data));
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessNotificationCount, isSuccessNotification ]);


 const toastId = useRef(null);
 useEffect(() => {
  if (isLoading) {
    toastId.current = toast.loading("Please wait...", {
    // position: "top-center",
    position: "top-center",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    theme: "colored",
    type: "info"
  });
  }
  if (isSuccess) {
    toast.dismiss(toastId.current);
    toast.success("Notification deleted successfully.", {
      render: "Notification deleted successfully..",
           type: "success",
           autoClose: 2000,
         isLoading: false,
         theme: "colored"
    });
    let  payload = `/employeenotification?employee_id=${employeeIdData}`;
    getAllNotification(payload);
   // close();
    
  }
  if (isError) {
    toast.dismiss(toastId.current);
    const toastMessage = apiError?.data?.message
      ? apiError?.data?.message
      : "Something went wrong";
        toast.error(toastMessage, {
          render:toastMessage,
               type: "error",
               autoClose: 2000,
               duplicate: false,
               isLoading: false
        });
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);

 const handlerDelete = (e, notification, close) => {
  e.preventDefault();
  let payload = `/EmployeeNotification/${notification?.id}`;
  deleteNotification(payload);
  close();
 };

const navigate =  useNavigate();
 
  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Notification</title>
          </Helmet>
        </HelmetProvider>
        <div>
          <div className="py-6">
          {isLoadingGetRespo ? <LoadingIndicator/> :
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Notification </h2>
              <div className="card bg-white p-6 reportList ">
              {notificationData?.length > 0 ?
              <>
              {notificationData && notificationData?.map((notification) => {

                const handleClick = () => {
                  NotificationNavigation(notification, employeeIdData, navigate);
                };
            
                return(
              <>
           <div className="w-full ">
             <div className="w-full listDiv flex mb-4 bg-yellow-light2  p-4 text-gray-600 rounded-md justify-between flex-wrap md:flex-nowrap ">
              <div className="mr-3 w-8">
                <span className=" w-8 h-8 flex justify-center items-center rounded-full bg-yellow-light secondry-text">
                <i className={`fa ${notification?.fa_icon ? notification?.fa_icon : ''} secondry-text`} />
                </span>               
              </div>            
             
              <div className="flex w-full items-center flex-wrap md:flex-nowrap text-gray-600 ">             
                <div className="w-full pr-4 cursor-pointer" onClick={handleClick} >
                <h3 className="text-lg font-medium">{notification?.title}</h3>             
                <span className="block overflow-ellipsis text-sm py-1 text-black">{(notification?.message)}</span>
                 <span className=" mr-3 text-sm font-medium text-gray-600">{moment(notification?.createdAt).format(formattedDate+'        HH:mm')}</span>
                </div>
                <div className=" xl:ml-2 md:w-auto w-full flex items-center justify-between xl:justify-end xl:mt-0 mt-2">
                {/* <button type="button" title="delete" className="p-1 pl-4 pr-4 mr-1 inline-block bg-white border border-red-400 rounded text-red-800 font-medium">
                <i className="fa fa-trash"/></button> */}
                <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn cancelBtn"
                    >
                      <i className="fa fa-trash"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Delete</h3>
                      </div>
                      <div className="content">
                        <p>Are you sure you want to delete notification ?</p>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" onClick={(e) => handlerDelete(e, notification, close)}>Yes</button>
                            <button
                              type="button"
                              onClick={() => close()}
                              className="btn cancel  "
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>                
                </div>               
              </div>
             </div>            
            </div>
            </>
                );
                })}
              </>
              : <center>{<NoDataFoundLeave/>}</center>}
         </div>
              </div>
          } 
        </div>   
      </div>
      </div>
    </>
  );
};

export default Notification;
