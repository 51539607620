import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Popup from "reactjs-popup";
import IntroJoyRide from "./introJoyride";
import { BarChart } from "./barChart";
import { useSelector } from "react-redux";
import { useGetAllDashboradMutation, usePostAllDashboardMutation, useGetAllChartDashboradMutation, useGetAllMessageMutation } from "../../services/dashboardAp";
import { EmployeeThisWeek } from "./Birthday/employeeThisWeek";
import DashBoardCardBox from "./dashBoardCardBox";
import moments from 'moment';
import { toast } from "react-toastify";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { dashboardMessageData, nextcarryForward, startMonthYear } from "../../redux/AuthSlice/authSlice";
import { EmployeeBirthdayThisWeek } from "./empBirthdayThisWeek";
import { EmployeeAnniversariesThisWeek } from "./employeeWorkAnniversary";
import { AbsenceThisWeek } from "./Birthday/absencethisWeek";
import SuperDashboard from "../superAdmin/superDashboard/dashboard/superDashboard";
import EmpDashboard from "../employeeDashboard/dashboard/dashboard";
import { REACT_APP_AUDITOR_ROLE_ID, REACT_APP_COMPANY_ADMIN_ROLE_ID, REACT_APP_EMPLOYEE_ROLE_ID, REACT_APP_MANAGER_ROLE_ID, REACT_APP_SUPER_ADMIN_ROLE_ID } from "../../helper/constant";
import DashboardboxExpense from "./dashboardboxExpense";
import DashBoardBoxExpenseApprovals from "./dashboardBoxExpenseApprovals";
import DashBoardBoxOvertimeApprovals from "./dashboardBoxOvertimeApprovals";
import { BarChartExpense } from "./barChartExpense";
import {  useGetCompanyMutation } from "../../services/companyApi";
import { dashboardDataAction } from "../../redux/DashboardSlice/DashboardSlice";
import { getCompanyDataAction } from "../../redux/CompanyInformation/companySlice";
import { FormSelect } from "../../components/formInput";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { useFilterEmployeeDepartmentMutation } from "../../services/employeeApi";
const Dashboard = () => {
  const ignore = useRef(false);

  // useSelector
  const loggedInUserData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
  const dashboardAllData = useSelector((state) => state?.persistedReducer?.DashboardSlice?.dashboardData);
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const companyData = useSelector((state) => state?.persistedReducer?.companySlice?.companyData);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  // Mutations
 
  const [getDepartmentList, { data: departmentApiResponse, isSuccess: isSuccessDepartmentApi }] = useGetDepartmentListMutation();
  const [filterEmployeeDepartment, { data: filterDepartmentApi, isSuccess: isSuccessFilterDepartment }] = useFilterEmployeeDepartmentMutation();
  const [getCompany, { data: companyListData, isSuccess: isCompanySuccess }] = useGetCompanyMutation();
  const [getAllDashboard, { data: dashboardGetData, isSuccess: isSuccessDashBoard, isError, error: apiError }] = useGetAllDashboradMutation();
  const [postAllDashboard, { isSuccess: isMessageSuccess, isError: isMessageError, isLoading: isMessageLoading, error: messageError }] = usePostAllDashboardMutation();
  const [getAllChartDashborad, { data: dashboardChartApiRespo, isSuccess }] = useGetAllChartDashboradMutation();
  const [getAllMessage, { data: allMessageData }] = useGetAllMessageMutation();
  const [expense, setExpense] = useState();
  const [mileage, setMileage] = useState();
  const [leave, setLeave] = useState();
  const [overtime, setOvertime] = useState();
  const [absence, setAbsence] = useState();
  const [textError, setTextError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [filterEmployee, setFilterEmployee] = useState();
  const [departmentIdValue, setDepartmentValue] = useState();
  const [employeeIdValue, setEmployeeId] = useState(0);
  const [allDepartmentListData, setAllDepartmentListData] = useState();
  
  const navigate = useNavigate();
  const cuurentYear = new Date().getFullYear();
  const [formData, setFormData] = useState({
    dayHours: "8",
    dateFormat: "DD/MM/YY",
    startMonth: ""
  });

  useEffect(() => {
    getAllMessage();
    getDepartmentList();
    // eslint-disable-next-line no-unused-vars
  }, []);

  useEffect(() => {
    if (!ignore.current) {
      getCompany();
      let payload = `/reports/get-chart-data?company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&type=1&year=${cuurentYear}`;
      getAllChartDashborad(payload);
    }
    return () => { ignore.current = true; };
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  useEffect(() => {
    if (isSuccessDepartmentApi) {
      dispatch(departmentListAction(departmentApiResponse));
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessDepartmentApi]);

  useEffect(() => {
    if (isCompanySuccess) {
      dispatch(getCompanyDataAction(companyListData));
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCompanySuccess]
  );

  useEffect(() => {
    if (companyData?.companyData !== undefined) {
      setFormData({
        dayHours: companyData?.companyData?.day_hours ? companyData?.companyData?.day_hours : '8',
        dateFormat: companyData?.companyData?.date_format,
        startMonth: companyData?.companyData?.start_month
      });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyData]);

  useEffect(() => {
    if (isSuccessFilterDepartment) {
      const arr = [];
      filterDepartmentApi.data.length > 0 && filterDepartmentApi.data.forEach((emp) => {
        let empOption = {
          value: emp?.id,
          label: `${emp?.first_name} ${emp?.last_name}`
        };
        arr.push(empOption);
      });
      setFilterEmployee(arr);
      //  setFilterEmployee(filterDepartmentApi.data);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessFilterDepartment]);

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };

  const getEndDate = (startMonth, year) => {
    const leapYear = isLeapYear(year);

    switch (parseInt(startMonth, 10)) {
      case 1:
        return `31-Dec-${year}`;
      case 2:
        return `31-jan-${year + 1}`;
      case 3:
        return leapYear ? `29-Feb-${year + 1}` : `28-Feb-${year + 1}`;
      case 4:
        return `31-Mar-${year + 1}`;
      case 5:
        return `30-Apr-${year + 1}`;
      case 6:
        return `31-May-${year + 1}`;
      case 7:
        return `30-Jun-${year + 1}`;
      case 8:
        return `31-Jul-${year + 1}`;
      case 9:
        return `31-Aug-${year + 1}`;
      case 10:
        return `30-Sep-${year + 1}`;
      case 11:
        return `31-Oct-${year + 1}`;
      case 12:
        return `30-Nov-${year + 1}`;
      default:
        return "";
    }
  };

  const currentYear = new Date().getFullYear();
  const endDate = getEndDate(formData?.startMonth, currentYear);

  useEffect(() => {
    if (formData?.startMonth !== null) {
      dispatch(nextcarryForward(endDate));
      dispatch(startMonthYear(formData?.startMonth));
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData?.startMonth]);


  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccessDashBoard) {
      dispatch(dashboardDataAction(dashboardGetData));
    }
    if (allMessageData?.data[0]?.message !== undefined) {
      dispatch(dashboardMessageData(allMessageData?.data[0]?.message));
    }
    if (isError) {
      if (apiError?.data?.status === 401) {
        localStorage.removeItem('accessToken');
        // dispatch(logout());
        navigate('/');
      }
    }

  },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessDashBoard, isError]);

  const messageData = useSelector((state) => state?.authState?.message);

  useEffect(() => {
    getAllDashboard(auditorRoleId === 5 ? auditorCompanyId : companyId);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, auditorCompanyId]);

  const leaveNavigate = useNavigate();
  const handleOutstandingLeavesClick = () => {
    if (dashboardAllData?.data?.TotalOustandingLeaves > 0) {
      leaveNavigate('/approvals', { state: "outstandingLeaves" });
    }
  };

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const isJoyceState = useSelector((state) => state.joyceState);
  const [text, setText] = useState();
  const [subject, setSubject] = useState();
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };


  const [toggleState2, setToggleState2] = useState(4);
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };

  const [toggleState3, setToggleState3] = useState(1);
  const toggleTab3 = (index) => {
    setToggleState3(index);
  };

  useEffect(() => {
    let arr = [];
    if (departmentApiData) {
      departmentApiData?.data?.length > 0 && departmentApiData?.data?.forEach((dept) => {
        if (loggedInUserData?.roleId == REACT_APP_MANAGER_ROLE_ID && loggedInUserData?.department_id && dept?.id == loggedInUserData?.department_id) {
          let deptOption = {
            value: dept?.id,
            label: dept?.department_name
          };
          arr.push(deptOption);
        }
        if (loggedInUserData?.roleId == REACT_APP_COMPANY_ADMIN_ROLE_ID) {
          let deptOption = {
            value: dept?.id,
            label: dept?.department_name
          };
          arr.push(deptOption);
        }
        if (loggedInUserData?.roleId == REACT_APP_EMPLOYEE_ROLE_ID && loggedInUserData.department_id) {
          let deptOption = {
            value: dept?.id,
            label: dept?.department_name
          };
          arr?.push(deptOption);
        }
      });
      setAllDepartmentListData(arr);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedInUserData, departmentApiData]);

  const handlerChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    if (name === 'department_id') {
      let value = {
        departmentId: e.target.value,
        parmStatus: 1
      };
      filterEmployeeDepartment(value);
      setDepartmentValue(e.target.value);
    }
    if (name === 'employee_id') {
      setEmployeeId(e.target.value);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      let chartData = dashboardChartApiRespo?.data;
      if (chartData?.type === "1") {
        setExpense(chartData);
      } else if (chartData?.type === "2") {
        setMileage(chartData);
      } else if (chartData?.type === "3") {
        setLeave(chartData);
      } else if (chartData?.type === "4") {
        setOvertime(chartData);
      } else if (chartData?.type === "5") {
        setAbsence(chartData);
      }

    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess]);


  const [toggleState4, setToggleState4] = useState(1);

  const toggleTab4 = (index) => {
    setToggleState4(index);
    let payload = `/reports/get-chart-data?company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&type=${index}&year=${cuurentYear}`;
    getAllChartDashborad(payload);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const sendMessageHandler = (e) => {
    e.preventDefault();

    // Reset errors
    setTextError('');
    setSubjectError('');

    // Validate inputs
    let isValid = true;
    if (!text) {
      setTextError('Please enter a message.');
      isValid = false;
    }
    if (!subject) {
      setSubjectError('Please enter a subject.');
      isValid = false;
    }

    if (!isValid) return;

    // Proceed with the API call if valid
    let val = {
      message: text,
      company_id: dashboardAllData?.CompanyMessage?.id,
      subject: subject,
      targetType: departmentIdValue === "all" ? "all" : (departmentIdValue !== "all" && employeeIdValue === 0) ? "department" : (departmentIdValue !== "all" && employeeIdValue !== 0) ? "individual" : "all",
      targetId: departmentIdValue === "all" ? 0 : (departmentIdValue !== "all" && employeeIdValue === 0) ? departmentIdValue :  (departmentIdValue !== "all" && employeeIdValue !== 0) ? employeeIdValue : 0
    };
    postAllDashboard(val);
    setText('');
    setSubject('');
  };

  const toastId = useRef();
  useEffect(() => {
    if (isMessageLoading) {
      toastId.current = toast.loading("Please wait...", {
        // position: "top-center",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isMessageSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Message added successfully.", {
        render: "Message added successfully.",
        type: "success",
        autoClose: 8000,
        isLoading: false,
        theme: "colored"
      });
      document?.getElementById("closeMessage").click();
      getAllDashboard(auditorRoleId === 5 ? auditorCompanyId : companyId);
      getAllMessage();
    }
    if (isMessageError) {
      toast.dismiss(toastId.current);
      const toastMessage = messageError?.data?.message
        ? messageError?.data?.message
        : "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 8000,
        duplicate: false,
        isLoading: false
      });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMessageLoading, isMessageSuccess, isMessageError]);

  const [showPopup, setShowPopup] = useState(false);
  const [currentViewers, setCurrentViewers] = useState([]);

  const handleMoreClick = (viewers) => {
    setCurrentViewers(viewers);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setCurrentViewers([]);
  };

  return (
    <>{
      roleIdData === REACT_APP_SUPER_ADMIN_ROLE_ID ? <SuperDashboard /> : roleIdData === REACT_APP_EMPLOYEE_ROLE_ID ? <EmpDashboard dashboardAllData={dashboardAllData} /> :
        <div className="">
          <HelmetProvider>
            <Helmet>
              <title>Schedulize | Dashboard</title>
            </Helmet>
          </HelmetProvider>
          <div>
            <div className="py-6 dashboardPage  ">
              <div className="container mx-auto ">
                <div className="flex justify-between flex-wrap  items-center">
                  <h2 className="text-xl mb-3 font-semibold  ">Dashboard</h2>
                  {/* {isJoyceState.isJoyce && ( */}
                  <IntroJoyRide
                    isJoyce={isJoyceState?.isJoyce}
                    isSignup={isJoyceState?.isSignup}
                    joyceData={isJoyceState.joyceData}
                  />
                  {/* )}  */}
                </div>
                <div className="bg-white md:p-6 p-4 rounded-md shadow-2xl">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-1/3 md:pr-3 lg:pr-6 md:mb-0 mb-4 flex">
                      <div className=" w-full relative flex flex-col overflow-hidden rounded-lg h-auto  bg-gradient-to-br from-blue-400 to-blue-900 p-2.5 py-8 shadow-xl">
                        <p className="text-xs uppercase text-sky-100">
                          Active Employees
                        </p>
                        <div className="flex items-end justify-between space-x-2">
                          <p className="mt-4 text-4xl font-medium text-white">
                            {dashboardAllData?.data?.TotalEmployees}
                          </p>
                          <Link
                            to="/employees" state={{ filter: "register" }}
                            className="border-b border-dotted border-current pb-0.5 text-xs font-medium text-pink-100 outline-none transition-colors duration-300 line-clamp-1 hover:text-white focus:text-white"
                          >
                            View All
                          </Link>
                        </div>
                        <div className="mask is-reuleaux-triangle absolute top-0 right-0 -m-3 h-16  rounded-md w-16 bg-white/20" />
                      </div>
                    </div>
                    <div className="w-full flex md:w-1/3 md:pr-3 lg:pr-6 md:mb-0 mb-4">
                      <div className="relative w-full flex flex-col overflow-hidden h-auto lg:h-auto rounded-lg bg-gradient-to-br from-amber-600 to-orange-700 p-2.5 py-8 shadow-xl">
                        <p className="text-xs uppercase text-pink-100">
                          Employees unregistered for portal
                        </p>
                        <div className="flex items-end justify-between space-x-2">
                          <p className="mt-4 text-4xl font-medium text-white">
                            {dashboardAllData?.data?.TotalUnregisteredEmployees
                              ? dashboardAllData?.data?.TotalUnregisteredEmployees
                              : 0}
                          </p>
                          <Link
                            to="/employees" state={{ filter: "unregister" }}
                            className="border-b border-dotted border-current pb-0.5 text-xs font-medium text-pink-100 outline-none transition-colors duration-300 line-clamp-1 hover:text-white focus:text-white"
                          >
                            View All
                          </Link>
                        </div>
                        <div className="mask is-hexagon-2 absolute top-0 right-0 -m-3 h-16 rounded-md w-16 bg-white/20" />
                      </div>
                    </div>
                    <div className="w-full flex md:w-1/3  md:mb-0 mb-4">
                      <div className="w-full relative flex flex-col overflow-hidden h-auto lg:h-auto rounded-lg bg-gradient-to-br from-blue-400 to-blue-900   p-2.5 py-3 md:py-2 lg:py-3 shadow-xl">
                        <div className="flex justify-between">
                          <p className="text-xs uppercase text-amber-50">
                            Company message
                            {allMessageData?.data[0]?.senderInfo?.first_name &&
                              <span className="md:ml-4 block md:inline">
                                {" "}
                                From:{" "}
                                <span className="font-medium">
                                  {allMessageData?.data[0]?.senderInfo?.first_name}
                                </span>
                              </span>}
                          </p>
                          {allMessageData?.data[0]?.message && (
                            <span className="text-xs uppercase text-amber-100">
                              {moments(allMessageData?.data[0]?.createdAt).format(formattedDate + '   HH:mm')}
                            </span>)}
                        </div>
                        <div className="text-xs mt-3 text-amber-100 break-words">Subject : {allMessageData?.data[0]?.subject}</div>

                        <div className="break-words ">
                          {
                            (allMessageData?.data[0]?.message)
                              ?
                              <p
                                className="mt-3 md:mt-1 lg:mt-3 text-white lg:h-16 lg:overflow-visible"
                                style={{ fontSize: "12px" }}
                              >
                                {typeof messageData === 'string' && messageData.split(' ').slice(0, 25).join(' ')}
                                {
                                  (typeof messageData === 'string' && messageData.split(' ').length > 25)
                                    ?
                                    <Popup
                                      open={open}
                                      onClose={() => closeModal()}
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <span
                                          onClick={() => setOpen((o) => !o)}
                                          className=" font-bold cursor-pointer"
                                        >
                                          ... read more
                                        </span>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            id="readMoreClose"
                                            onClick={() => {
                                              closeModal();
                                              close();
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <div className="header">
                                            <h3> Message Detail</h3>
                                          </div>
                                          <div className="p-6">
                                            <div className="w-1/2">
                                              <h3 className="mb-0 text-[15px] font-semibold">
                                                From:</h3>
                                              <h4 className="text-[15px] font-normal"> {allMessageData?.data[0]?.senderInfo?.first_name}</h4>
                                              {dashboardAllData?.data[0]?.message && (
                                                <h2>
                                                  {moments(allMessageData?.data[0]?.createdAt).format('DD-MM-YYYY HH:mm')}
                                                </h2>
                                              )}
                                            </div>
                                            <div className="form-group mt-3">
                                              <h3 className="mb-0 text-[15px] font-semibold">Message:</h3>
                                              <div className="  break-words ">
                                                <h4 className="mb-0 text-[15px] font-normal">
                                                  {allMessageData?.data[0]?.message
                                                    ? allMessageData?.data[0]?.message
                                                    : <h3 className="mt-4 text-xs font-medium text-white" >No data found</h3>}
                                                </h4>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                    :
                                    ''
                                }

                              </p>
                              :
                              <center className="text-white mt-5">No messages available!</center>
                          }
                          <div className="flex justify-end lg:mt-4 md:mt-4 mt-2 xl:mt-1 relative z-10">
                            {
                              (allMessageData?.data[0]?.message)
                                ?
                                <Popup
                                  open={open}
                                  onClose={() => closeModal()}
                                  closeOnDocumentClick={false}
                                  trigger={
                                    <Link
                                      onClick={() => setOpen((o) => !o)}
                                      to="#"
                                      className="table mr-3 border-b border-dotted border-current pb-0.5 text-xs mt-1 font-medium text-amber-50 outline-none transition-colors"
                                    >
                                      View All
                                    </Link>
                                  }
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div className="modal">
                                      <button
                                        className="close"
                                        id="viewAllClose"
                                        onClick={() => {
                                          closeModal();
                                          close();
                                        }}
                                      >
                                        &times;
                                      </button>
                                      <div className="header">
                                        <h3> Message</h3>
                                      </div>
                                      <div className="content">
                                        <div className="w-full">
                                          {allMessageData?.data?.length > 0 ?
                                            <>
                                              {allMessageData?.data?.map((message, index) => {
                                                return (

                                                  <div className="card p-4 mb-4 border" key={index}>
                                                    <div className="w-full ">
                                                      <div className="flex mb-3 w-full">
                                                        <div className="w-1/2">
                                                          <h3 className="mb-0 text-[15px] font-semibold">Subject:
                                                          </h3>
                                                          <h4 className="text-[15px] font-normal" >
                                                            {message?.subject}
                                                          </h4>
                                                        </div>
                                                        <div className="w-1/2">
                                                          <h3 className="mb-0 text-[15px] font-semibold">Date & Time:</h3>
                                                          <h4 className=" text-[15px] font-normal" >
                                                            {moments(message?.createdAt).format(formattedDate)}
                                                            <span className="ml-1">{moments(message?.createdAt).format('HH:mm')}</span>
                                                          </h4>
                                                        </div>
                                                      </div>
                                                      <div className="flex mb-3 w-full">
                                                        <div className="w-1/2">
                                                          <h3 className="mb-0 text-[15px] font-semibold">Added by: </h3>
                                                          <h3 className="font-medium text-[15px]" >
                                                            {message?.senderInfo?.first_name}
                                                          </h3>
                                                        </div>
                                                        <div className="w-1/2">
                                                          <h3 className="mb-0 text-[15px] font-semibold">Department: </h3>
                                                          <h3 className="font-medium text-[15px]" >
                                                            {message?.departmentInfo?.department_name}
                                                          </h3>
                                                        </div>
                                                      </div>
                                                      <div className="w-full mb-3">
                                                        <h3 className="mb-0 text-[15px] font-semibold">Message:</h3>
                                                        <Popup
                                                          open={open}
                                                          onClose={() => closeModal()}
                                                          closeOnDocumentClick={false}
                                                          trigger={
                                                            <h4 onClick={() =>
                                                              setOpen((o) => !o)
                                                            } className=" text-[15px] font-normal break-words text-[ 14px ]" >

                                                              {typeof message?.message === 'string' && message?.message.split(' ').slice(0, 18).join(' ')}
                                                              {
                                                                (typeof message?.message === 'string' && message?.message.split(' ').length > 18)
                                                                  ?
                                                                  <>
                                                                    <span className="primary-text-msg cursor-pointer">... read more</span>
                                                                  </>
                                                                  :
                                                                  ''
                                                              }
                                                            </h4>
                                                          }
                                                          modal
                                                          nested
                                                        >
                                                          {(close) => (
                                                            <div className="modal">
                                                              <button
                                                                className="close"
                                                                id="messageDetailClose"
                                                                onClick={() => {
                                                                  closeModal();
                                                                  close();
                                                                }}
                                                              >
                                                                &times;
                                                              </button>
                                                                <div className="form-group mt-3 p-6">
                                                                  <h3 className="mb-0 text-[15px] font-semibold">
                                                                    Message:
                                                                  </h3>
                                                                  <div className=" break-words  ">
                                                                    <h4 className="mb-0 text-[15px] font-normal">
                                                                      {message?.message}
                                                                    </h4>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                          )}
                                                        </Popup>
                                                      </div>
                                                    </div>

                                                    <div className="w-full ">
                                                      <h3 className="mb-0 text-[15px] font-semibold">Read by:</h3>
                                                      <h3 className="mb-1 text-[15px] font-normal">
                                                        {message?.messageViewsInfo?.slice(0, 3)?.map((viewer) => (
                                                          <span key={viewer?.id}>
                                                            {viewer?.viewerInfo?.first_name} {viewer?.viewerInfo?.last_name},{" "}
                                                          </span>
                                                        ))}
                                                        {message?.messageViewsInfo?.length > 3 && (
                                                          <button onClick={() => handleMoreClick(message?.messageViewsInfo)} className="primary-text-msg cursor-pointer">
                                                            ...more
                                                          </button>
                                                        )}
                                                      </h3>
                                                    </div>

                                                  </div>
                                                );
                                              })}
                                              <Popup
                                                open={showPopup}
                                                onClose={closeModal}
                                                closeOnDocumentClick={false}
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      onClick={() => {
                                                        closePopup();
                                                        close();
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                    <div className="header">
                                                      <h3>Read by</h3>
                                                    </div>
                                                    <div className="content">
                                                      {currentViewers?.map((viewer) => (
                                                        <p key={viewer?.id} style={{ color: '#000', fontWeight: 'bold' }}>
                                                          {viewer?.viewerInfo?.first_name} {viewer?.viewerInfo?.last_name}
                                                        </p>
                                                      ))}
                                                    </div>
                                                  </div>
                                                )}
                                              </Popup>

                                            </> :
                                            <center className="mt-1 text-red-700 ">No Message Found</center>}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                                :
                                ''
                            }
                            {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <Link
                                  id="closeMessage"
                                  onClick={() => setOpen((o) => !o)}
                                  to="#"
                                  className="table  border-b border-dotted border-current pb-0.5 text-xs mt-1 font-medium text-amber-50 outline-none transition-colors"
                                >
                                  Add Message
                                </Link>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="addMessage"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    {" "}
                                    <h3> Add Message</h3>
                                  </div>
                                  <div className="content">
                                    <form>
                                      <div className="flex flex-wrap ">
                                          <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0 w-full">
                                            <label className="formBlock">
                                              Department
                                            </label>
                                            <select
                                              className="formControl mr-2"
                                              id="department"
                                              name="department_id"
                                              onChange={(e) => handlerChange(e)}
                                            >
                                              <FormSelect
                                                optionValue={"0"}
                                                optionName={"Select Department"}
                                              />
                                              <FormSelect
                                                optionValue={"all"}
                                                optionName={"All"}
                                              />
                                              {allDepartmentListData?.length > 0
                                                ? allDepartmentListData?.map((departments, key) => {
                                                  return (
                                                    <FormSelect
                                                      key={key}
                                                      optionValue={departments?.value}
                                                      optionName={departments?.label}
                                                    />
                                                  );
                                                })
                                                : null}
                                            </select>
                                          </div>
                                          <div className="md:w-1/2 w-full">
                                            <label className="formBlock">
                                              Employee
                                            </label>
                                            <select
                                              className="formControl mr-2"
                                              id="employee"
                                              name="employee_id"
                                              onChange={(e) => handlerChange(e)}
                                              disabled={departmentIdValue === undefined}
                                            >
                                              <FormSelect
                                                optionValue={0}
                                                optionName={"All"}
                                              />
                                              ;
                                              {filterEmployee?.length > 0
                                                ? filterEmployee?.map((employee, key) => {
                                                  return (
                                                    <FormSelect
                                                      key={key}
                                                      optionValue={employee?.value}
                                                      optionName={employee?.label}
                                                    />
                                                  );
                                                })
                                                : null}
                                            </select>
                                          </div>
                                        <div className="w-full mt-2">
                                          <div className="form-group lg:mb-6 mb-4 md:mb-6">
                                            <label className="formBlock	mt-4">
                                              Subject
                                            </label>
                                            <textarea
                                              id="subject"
                                              name="subject"
                                              type="text"
                                              className="formControl"
                                              value={subject}
                                              onChange={handleSubjectChange}
                                            />
                                            {subjectError && <span className="text-red-500">{subjectError}</span>}
                                            <div className="help-block" />
                                          </div>
                                        </div>
                                        <div className="w-full ">
                                          <div className="form-group lg:mb-6 mb-4 md:mb-6">
                                            <label className="formBlock	">
                                              Message
                                            </label>
                                            <textarea
                                              id="Message"
                                              name="price"
                                              type="text"
                                              className="formControl h-24"
                                              value={text}
                                              onChange={handleTextChange}
                                            />
                                            {textError && <span className="text-red-500">{textError}</span>}
                                            <div className="help-block" />
                                          </div>
                                        </div>

                                        <div className="w-full mt-4">
                                          <div className="form-group pt-4 pb-3 flex justify-center ">
                                            <button className="btn save mr-2" id="messageSend"
                                              onClick={(e) => sendMessageHandler(e)}
                                            >
                                              Send
                                            </button>
                                            <button
                                              type="button"
                                              id="messageCancel"
                                              onClick={() => close()}
                                              className="btn cancel "
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </Popup>)}
                          </div>
                        </div>
                        <div className="mask is-diamond absolute top-0 right-0 -m-3 h-16 w-16 rounded-md  bg-white/20" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap md:mt-6  ">
                    <div className="lg:w-1/3 w-full lg:mb-0 mb-4 md:mb-6 lg:pr-6">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              Employee Birthdays
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="cardtabBtn ">
                            <button
                              onClick={() => toggleTab(1)}
                              id="employeeThisWeek"
                              className={
                                toggleState === 1
                                  ? "btn-sm  mr-2 active"
                                  : "btn-sm  mr-2"
                              }
                            >
                              This Week
                            </button>

                            <button
                              onClick={() => toggleTab(2)}
                              id="employeeNextWeek"
                              className={
                                toggleState === 2 ? "btn-sm  active" : "btn-sm  "
                              }
                            >
                              Next Week
                            </button>
                          </div>
                          <div className="tabCard">
                            <div
                              className={
                                toggleState === 1
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <EmployeeBirthdayThisWeek
                                  thisWeekBirthday={dashboardAllData?.data?.EmployeeBirthdayThisWeek}
                                  tableNames={["Name", "Birthday Date"]}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState === 2
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <EmployeeBirthdayThisWeek
                                  thisWeekBirthday={dashboardAllData?.data?.EmployeeBirthdayNextWeek}
                                  tableNames={["Name", "Birthday Date"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-1/3 w-full mb-4 md:mb-6 lg:pr-6">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              Employees on Leave
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="cardtabBtn ">
                            <button
                              onClick={() => toggleTab2(4)}
                              id="leaveThisWeek"
                              className={
                                toggleState2 === 4
                                  ? "btn-sm  mr-2 active"
                                  : "btn-sm  mr-2"
                              }
                            >
                              This Week
                            </button>

                            <button
                              onClick={() => toggleTab2(6)}
                              id="leaveNextWeek"
                              className={
                                toggleState2 === 6 ? "btn-sm  active" : "btn-sm  "
                              }
                            >
                              Next Week
                            </button>
                          </div>
                          <div className="tabCard">
                            <div
                              className={
                                toggleState2 === 4
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <EmployeeThisWeek
                                  thisWeekLeaves={dashboardAllData?.data?.EmployeeLeaveThisWeek ? dashboardAllData?.data?.EmployeeLeaveThisWeek : []}
                                  tableNames={["Name", "Days"]}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState2 === 6
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <EmployeeThisWeek
                                  thisWeekLeaves={dashboardAllData?.data?.EmployeeLeaveNextWeek}
                                  tableNames={["Name", "Days"]}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState === 5
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <table className="table-auto table mt-2 w-full">
                                  <thead className="thead-dark ">
                                    <tr>
                                      <th className="w-50"> Name</th>
                                      <th>Reason</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Link
                                          to="/employees/detail"
                                          className="listImage flex items-center "
                                        >
                                          Sahion
                                        </Link>
                                      </td>
                                      <td>Bithday</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Link
                                          to="/employees/detail"
                                          className="listImage flex items-center "
                                        >
                                          Lee
                                        </Link>
                                      </td>
                                      <td>Sick </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Link
                                          to="/employees/detail"
                                          className="listImage flex items-center "
                                        >
                                          Akbar
                                        </Link>
                                      </td>
                                      <td>Holiday</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-1/3 w-full  mb-4 md:mb-6 ">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              Work Anniversaries
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="cardtabBtn ">
                            <button
                              onClick={() => toggleTab3(1)}
                              id="anniversariesThisWeek"
                              className={
                                toggleState3 === 1
                                  ? "btn-sm  mr-2 active"
                                  : "btn-sm  mr-2"
                              }
                            >
                              This Week
                            </button>

                            <button
                              onClick={() => toggleTab3(2)}
                              id="anniversariesNextWeek"
                              className={
                                toggleState3 === 2 ? "btn-sm  active" : "btn-sm  "
                              }
                            >
                              Next Week
                            </button>
                          </div>
                          <div className="tabCard">
                            <div
                              className={
                                toggleState3 === 1
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="w-full">
                                {/* <EmployeeWorkAnniversary/> */}
                                <EmployeeAnniversariesThisWeek
                                  thisWeekAnniversaries={dashboardAllData?.data?.EmployeeAnniversaryThisWeek}
                                  tableNames={["Name", "Date", "Year(s)"]}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState3 === 2
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="w-full ">
                                <EmployeeAnniversariesThisWeek
                                  thisWeekAnniversaries={dashboardAllData?.data?.EmployeeAnniversaryNextWeek}
                                  tableNames={["Name", "Date", "Year(s)"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DashBoardCardBox
                      title={"Outstanding Leave Approvals"}
                      value={dashboardAllData?.data?.TotalOustandingLeaves}
                      onClick={handleOutstandingLeavesClick}
                    />
                    <DashboardboxExpense
                      title={"Expenses to Pay"}
                      value={dashboardAllData?.data?.TotalExpenseToPay}
                    />
                    <DashBoardBoxExpenseApprovals
                      title={"Outstanding Expense Approvals"}
                      value={dashboardAllData?.data?.TotalOustandingExpenses}
                    />
                    <DashBoardBoxOvertimeApprovals
                      title={"Outstanding Overtime Approvals"}
                      value={dashboardAllData?.data?.TotalOustandingOvertimes}
                    />
                    <div className="lg:w-1/3 w-full mb-4 md:mb-6 flex ">
                      <div
                        className="card w-full infoList headingHeight "
                        style={{ minHeight: "178px" }}
                      >
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              Absence This week
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="absenseWeek ">
                            <div className="overflow-x-auto w-full whitespace-nowrap">
                              <AbsenceThisWeek
                                thisWeekAbsence={dashboardAllData?.data?.EmployeeAbsenceThisWeek}
                                tableNames={["Name", "Day of absence"]}
                                showColoumns={'Days'}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" w-full  ">
                      <div className="card w-full infoList ChartDiv">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">Trends</h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="cardtabBtn  flex-wrap">
                            <button
                              onClick={() => toggleTab4(1)}
                              id="puchase"
                              className={
                                toggleState4 === 1
                                  ? "btn-sm  mr-2 active mb-1 sm:mb-0"
                                  : "btn-sm  mr-2 mb-1 sm:mb-0"
                              }
                            >
                              Expenses - Purchase
                            </button>

                            <button
                              onClick={() => toggleTab4(2)}
                              id="mileage"
                              className={
                                toggleState4 === 2
                                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                  : "btn-sm mr-2 mb-1 sm:mb-0  "
                              }
                            >
                              Expenses - Mileage
                            </button>
                            <button
                              onClick={() => toggleTab4(3)}
                              id="leave"
                              className={
                                toggleState4 === 3
                                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                  : "btn-sm mr-2 mb-1 sm:mb-0 "
                              }
                            >
                              Leave
                            </button>
                            <button
                              onClick={() => toggleTab4(4)}
                              id="overtime"
                              className={
                                toggleState4 === 4
                                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                  : "btn-sm mr-2 mb-1 sm:mb-0"
                              }
                            >
                              Overtime
                            </button>
                            <button
                              onClick={() => toggleTab4(5)}
                              id="absence"
                              className={
                                toggleState4 === 5
                                  ? "btn-sm active mb-1 sm:mb-0"
                                  : "btn-sm mb-1 sm:mb-0 "
                              }
                            >
                              Absence
                            </button>
                          </div>
                          <div className="tabCard">
                            <div
                              className={
                                toggleState4 === 1
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChartExpense chart={expense} />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState4 === 2
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChart chart={mileage} />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState4 === 3
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChart chart={leave} />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState4 === 4
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChart chart={overtime} />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState4 === 5
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChart chart={absence} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    }
    </>
  );
};

export default Dashboard;
