
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' 
    },
    title: {
      display: true
      // text: 'Chart.js Bar Chart'
    }
  }
};

const labels = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];

export const data = {
  labels,
  datasets: [
    // {
    //   label: 'Dataset 1',   
    //   data: [ 10, 12, 25, 52, 25, 6, 35, 20, 15, 25, 45, 31 ],
    //   backgroundColor: 'rgba(255, 99, 132, 0.5)'
    // },
    {
      label: `${currentYear}`,
      data: [ 10, 12, 25, 52, 25, 6, 35, 20, 15, 25, 45, 31 ],
      backgroundColor: '#023A51'
    }
  ]
};

export function BarChart({chart}) {

  const data1 = {
    labels,
    datasets: [
      // {
      //   label: 'Dataset 1',   
      //   data: [ 10, 12, 25, 52, 25, 6, 35, 20, 15, 25, 45, 31 ],
      //   backgroundColor: 'rgba(255, 99, 132, 0.5)'
      // },
      {
        label: `${currentYear}`,
        data: chart?.ChartDataSets?.data,
        backgroundColor: '#023A51'
      }
    ]
  };
  return <Bar options={options} data={data1} 
  
  
    responsive={true}/>;
}
