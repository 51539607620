/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback, useRef} from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "react-datepicker/dist/react-datepicker.css";
import {
  SearchField
} from "../../components/filters";
import { 
  FormSelect
} from "../../components/formInput";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import {useGetDepartmentListMutation} from "../../services/approverListApi";
import Pagination from "../../components/inc/pagination";
import { useMemo } from "react";
import moments from "moment";
import DatePicker from "react-datepicker";
import moment from "moment";
import { LoadingIndicator } from "../../components/loading-indicator";
import NoDataFound from "../../helper/noDataFound";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllLeaveAbsenceMutation } from "../../services/leaveAbsenceApi";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { toast } from "react-toastify";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const AbsenceReport  = () => {
  const ignore = useRef(false);
  const [ allDepartmentListData, setAllDepartmentListData ] = useState();
  const [ allAbscenceData, setAllAbscenceData ] = useState();
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(0);
  const dispatch = useDispatch();
  const pageSize = 10 ;
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const createCsvFileName = () => `Absences_report_${moments().format()}.csv`;
  const [ excelData, setExcelData ] = useState([]);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);
  const [ getDepartmentList, {data:departmentApiResponse, isSuccess: isSuccessDepartmentApi} ] = useGetDepartmentListMutation();
  const [ allAbsenceData, {data: allAbscenceResponse, isSuccess: isSuccessAbscence, isLoading }  ] = useGetAllLeaveAbsenceMutation();
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);

  useEffect(() => {
    if (!ignore.current){
      let payload= `/leaves/absenceList?company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&limit=100&page=${page}`;
    // let payload=`/leaves?type=${2}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
    allAbsenceData(payload);
    getDepartmentList();
  }
  return () => { ignore.current = true; };
 
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  
  const handlerChangeByDeptName = (e) => {
    e.preventDefault();
    if (e.target.value === '0') {
     
      let payload =  `/leaves/absenceList?company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&limit=100&page=${page}`;
    // let payload =  `/leaves?type=${2}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
    allAbsenceData(payload);    
    } else {
      let payload =  `/leaves/absenceList?company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&department_id=${e.target.value}&limit=100&page=${page}`;
    //  let payload = `/leaves?type=${2}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&department_id=${e.target.value}`;
     allAbsenceData(payload); 
    } 
 };

  useEffect(() => {
    if(isSuccessAbscence) {
      setAllAbscenceData(allAbscenceResponse?.data);
      if(allAbscenceResponse?.data){
        let csvData = exportTOCSV(allAbscenceResponse?.data);
        setExcelData(csvData);
      }
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessAbscence ]);

 
useEffect(() => {
  if(isSuccessDepartmentApi){
    dispatch(departmentListAction(departmentApiResponse));
  }
},
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessDepartmentApi ]);

  useEffect(() => {
    if(departmentApiData) {
      setAllDepartmentListData(departmentApiData?.data);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ departmentApiData ]);

  useEffect(() => {
    if(allAbscenceResponse?.data){
      let totalPages = Math.ceil(allAbscenceResponse?.data?.length /pageSize);
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allAbscenceResponse?.data?.slice(firstPageIndex, lastPageIndex);
        setAllAbscenceData(data);
        setPages(totalPages);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ allAbscenceResponse?.data ]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    const data = allAbscenceResponse?.data?.slice(firstPageIndex, lastPageIndex);
    setAllAbscenceData(data);
  
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

  const decrementPage = () => {
    const lastPageIndex = (page-1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allAbscenceResponse?.data?.slice( lastPageIndex, firstPageIndex);
    setAllAbscenceData(data);
  };
  const filterEmployees = useCallback((searchTerm) => {
    let payload = `/leaves/absenceList?company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&name=${searchTerm}&limit=100&page=${page}`;
    allAbsenceData(payload);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

  let  headers = [
    { label: 'Employee Name', key : 'employeeName'},
    { label: 'Date Of Absence', key : 'date_of_absence'},
    { label: 'Department', key : 'department'},
    { label: 'Reason', key : 'reason'},
    { label: 'Hours Absent', key : 'hours_absent'}
    ];
    const exportTOCSV = (report) => {
      let  dataAbence =[];
      if(report){
        report?.forEach((dExcel) => {
          const startTime = new Date(`1970-01-01T${dExcel?.start_time}`);
          const endTime = new Date(`1970-01-01T${dExcel?.end_time}`);
          const durationMs = (endTime - startTime) ? (endTime - startTime) : "";
          const durationHours = durationMs / (1000 * 60 * 60) ? durationMs / (1000 * 60 * 60) : "";
       // let totalHours = dExcel?.total_duration / 60;
        let val ={
          employeeName: `${dExcel?.employeeInfo?.first_name} ${dExcel?.employeeInfo?.last_name}`,
          date_of_absence: moment(dExcel?.start_date)?.format(formattedDate),
          department: dExcel?.departmentInfo?.department_name,
          reason: dExcel?.reason,
          hours_absent: `${durationHours} ${"hours"}`
       };
        dataAbence?.push(val);
      });
      return dataAbence;
      }
   };

   const onDateChange = (e) => {
    if(e[0] !== null && e[1] !== null){
      let payload=`/leaves/absenceList?company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}&limit=100&page=${page}`;
      allAbsenceData(payload); 
 
    } else {
      let payload=`/leaves/absenceList?company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&limit=100&page=${page}`;
      allAbsenceData(payload); 
    }
  };
  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Absence Report </h2>
              <div className="card ">
                <div className="p-4 ">
                  <div className="headingContent  pt-0 w-full">
                    <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                      <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 ">
                        <SearchField
                          placeholderText="Search By Name"
                          searchId="reportAbsenceSearch"
                          onChange={(e) => filterEmployees(e.target.value)}
                        />
                      </div>
                      <div className="searchSec md:w-1/4 lg:w-1/5 md:pr-4 w-full md:mb-0 mb-2 ">
                        <DatePicker
                          selectsRange={true}
                          id="reportAbsenceStartDate"
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          className="formControl"
                          placeholderText="Select Date Range"
                          onChange={(update) => {
                            setDateRange(update);
                            onDateChange(update);
                          }}
                          isClearable={true}
                          locale="en-GB"
                        />
                      </div>
                      <div className=" lg:w-1/5 md:w-1/4  w-full md:mb-0 mb-2 md:pr-4">
                        <select
                          className="formControl"
                          id="reportAbsenceDepartment"
                          name="department_name"
                          onChange={(e) => handlerChangeByDeptName(e)}
                        >
                          <FormSelect
                            optionValue={"0"}
                            optionName={"Select Department"}
                          />
                          ;
                          {allDepartmentListData?.length > 0
                            ? allDepartmentListData?.map((departments, key) => {
                                return (
                                  <FormSelect
                                    key={key}
                                    optionValue={departments?.id}
                                    optionName={departments?.department_name}
                                  />
                                );
                              })
                            : null}
                        </select>
                      </div>
                      <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full ml-[15%]">
                      <CSVLink
                        className=""
                        data={excelData}
                        headers={headers}
                          filename={createCsvFileName()}
                          target="_blank"
                        style={{ textDecoration: 'none', outline: 'none', height: '5vh' }}
                        >
                        <button  className="btn-md btn-add " id="reportExpenseCsv" >
                        <span className="fa fa-file-export mr-2" /> Export to CSV
                        </button>
                        </CSVLink>
                      </div>
                    </div>
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                      <table className="table-auto table  w-full">
                        <thead className="thead-dark ">
                          <tr>
                            <th className="w-40"> Name</th>
                            <th>Date Of Absence</th>
                            <th>Department</th>
                            <th>Bradford Factor <br/> In Days</th>
                            <th>Reason</th>
                            <th>Hours Absent</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <LoadingIndicator />
                        ) : (
                          <tbody>
                            {allAbscenceData?.length > 0 ? (
                              <>
                                {allAbscenceData &&
                                  allAbscenceData.map((abscence, index) => {
                                    const startTime = new Date(
                                      `1970-01-01T${abscence?.start_time}`
                                    );
                                    const endTime = new Date(
                                      `1970-01-01T${abscence?.end_time}`
                                    );

                                    const durationMs = endTime - startTime;

                                    const durationHours =
                                      durationMs / (1000 * 60 * 60);

                                    //let totalHours = abscence?.total_duration / 60;
                                    //let totalHoursText = totalHours < 1 ? "hour" : "hours";
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <Link
                                            to="/employees/detail"
                                            class="listImage flex items-center "
                                          >
                                            {abscence?.employeeInfo?.first_name}{" "}
                                            {abscence?.employeeInfo?.last_name}
                                          </Link>
                                        </td>
                                        <td>
                                          {moment(abscence?.start_date).format(
                                            formattedDate
                                          )}
                                        </td>
                                        <td>
                                          {
                                            abscence?.departmentInfo
                                              ?.department_name
                                          }
                                        </td>
                                        <td>
                                          {abscence?.employeeInfo?.bradford_factor_in_days 
                                            ? Number(abscence.employeeInfo.bradford_factor_in_days).toFixed(2) 
                                            : '0.00'}
                                        </td>
                                        <td>
                                          {abscence?.reason
                                            ? abscence?.reason
                                            : "-"}
                                        </td>
                                        <td>
                                          {durationHours} {"Hours"}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </>
                            ) : (
                              <center className="mt-4">
                                {<NoDataFound />}
                              </center>
                            )}
                          </tbody>
                        )}
                      </table>
                      <Pagination
                        page={page}
                        pages={pages}
                        changePage={setPage}
                        decrementPage={setPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AbsenceReport;
