import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";


const Report = () => {


  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  "> Reports </h2>
              <div className="card bg-white p-6 reportList ">
              <ul>
                <li><Link to="/report/employee">Employee information report <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/expense" >Expenses report <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/leave" >Leave report <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/overtime" >Overtime report <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/timeinlieu">Time in lieu report <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/absense" >Absence report <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/approvar">Approver report <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/company-message">Company messages report <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/allowanceOverview">Allowance Overview <span className="fa fa-angle-right pl-2"/></Link></li>
                <li><Link to="/report/company-report">Company report <span className="fa fa-angle-right pl-2"/></Link></li>
              </ul>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
