/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger */
import {useState, useRef} from "react";
import Popup from "reactjs-popup";
import {FormInput} from "../../../components/formInput";
import PrimaryButton from "../../../components/primaryButton";
import {
  useCreateOverTimeMutation,
  useGetAllOverTimeMutation,
  useUpdateOverTimeMutation,
  useDeleteOverTimeMutation
} from "../../../services/overTimeApi";
import {useEffect} from "react";
import {ToastContainer, toast} from "react-toastify";
import Pagination from "../../../components/inc/pagination";
import {useSearchParams} from "react-router-dom";
import AddOverTimeSetting from "./AddOverTimeSetting";
import EditOverTimeSetting from "./EditOverTimeSetting";
import { useSelector } from "react-redux";

const OverTime = ({overTimeTypesUpdatePermissions, overTimeTypesCreatePermissions, overTimeTypesDeletePermissions}) => {
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const [
    createOverTime,
    {
      data: createData,
      isSuccess: isSuccessCreate,
      isLoading: isLoadingCreate,
      isError: isErrorCreate
    }
  ] = useCreateOverTimeMutation();
  const [
    getAllOverTime,
    {data: overTimeApiResponse, isSuccess: isOverTimeSuccess}
  ] = useGetAllOverTimeMutation();
  const [
    updateOverTime,
    {
      data: updateData,
      isSuccess: isSuccessUpdate,
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate
    }
  ] = useUpdateOverTimeMutation();
  const [
    deleteOverTime,
    {
      data: deleteData,
      isSuccess: isSuccessDelete,
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      error: apiError
    }
  ] = useDeleteOverTimeMutation();
  const [ open, setOpen ] = useState(false);
  const [ checked, setChecked ]  = useState(false);
  const [ overTimeId, setOverTimeId ] = useState("");
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get("page") || "";
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);
  const [ overTimeData, setOverTimeData ] = useState();
  const toastId = useRef(null);
  const closeModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (isOverTimeSuccess) {
      if (
        overTimeApiResponse?.data?.length < 1 &&
        overTimeApiResponse?.currentPage > 1
      ) {
        let url = `/masterovertime?page=${overTimeApiResponse?.currentPage - 1}&userSpecific=${0}&limit=50`;
        getAllOverTime(url);
      }
      setOverTimeData(overTimeApiResponse.data);
      setPages(overTimeApiResponse?.totalPages);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isOverTimeSuccess ]);
  const decrementPage = () => {
    let url = `/masterovertime?page=${overTimeApiResponse?.currentPage - 1}&userSpecific=${0}&limit=50`;
    getAllOverTime(url);
    setPage(overTimeApiResponse?.currentPage - 1);
  };

  const [ formData, setFormData ] = useState({
    overtime_name: "",
    color: "",
    time_in_lieu: "0",
    available_to: "0",
    status: "0"
  });

  const [ error, setError ] = useState({
    errorOvertimeName: "",
    errorColor: "",
    errorAvailableTo: "",
    errorStatus: ""
  });

  //Reading Data from Api
  useEffect(() => {
    let url = `/masterovertime?page=${page}&userSpecific=${0}&limit=50`;
    getAllOverTime(url);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

  const handlerChange = (e) => {
    e.preventDefault();
    const {name} = e.target;
    if (name === "overtime_name") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorOvertimeName: "Please select overTime name"
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorOvertimeName: ""
          };
        });
      }
    }
    if (name === "color") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorColor: "Please select color"
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorColor: ""
          };
        });
      }
    }
    if (name === "available_to") {
      if (name === "0") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorAvailableTo: "Please select available "
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorAvailableTo: ""
          };
        });
      }
    }
    if (name === "status") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorStatus: "Please select status "
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorStatus: ""
          };
        });
      }
    }
    setFormData({...formData, [name]: e.target.value});
  };
  const [ showResults, setShowResults ] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const onClickFilter = () => setShowResults(!showResults);

  const onEditClick = (e, index) => {
    setOpen(false);
    setOpenEdit(true);
  };
  const handlerChangCheck = (e, name) => {
    setChecked(e.target.cheked);
    setFormData({...formData, [name]: e.target.checked ? "1" : "0"});
  };

  // const getUpdateOverTime = () => {
  //    dispatch(updatedOverTime(updateData));
  //    setOpen(false);
  //    closeModal();
  // };

  const validation = () => {
    const {overtime_name, color, available_to, status} = formData;
    let isFormValidated = true;
    if (overtime_name === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          errorOvertimeName: "Please select overTime name"
        };
      });
      isFormValidated = false;
    }
    if (color === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          errorColor: "Please select color"
        };
      });
      isFormValidated = false;
    }
    if (available_to === "0") {
      setError((errorValue) => {
        return {
          ...errorValue,
          errorAvailableTo: "Please select available "
        };
      });
      isFormValidated = false;
    }
    if (status === " ") {
      setError((errorValue) => {
        return {
          ...errorValue,
          errorStatus: "Please select status "
        };
      });
      isFormValidated = false;
    }
    return isFormValidated;
  };

  // const overTimeSubmit = (e, edit=false, close, over=false) => {
  //   e.preventDefault();
  //     if(edit) {
  //       const value = {
  //         overtime_name: formData.overtime_name ? formData.overtime_name : over.overtime_name,
  //         color: formData.color ? formData.color : over.color,
  //         time_in_lieu: formData.time_in_lieu ? formData.time_in_lieu : over.time_in_lieu,
  //         available_to: formData.available_to ? formData.available_to : `${over.available_to}`,
  //         status: formData.status ? formData.status: over.status,
  //         id : over.id
  //       };

  //     updateOverTime(value);
  //     close();
  //     }else {
  //     if(validation()) {
  //       createOverTime(formData);
  //       close();
  //     }
  //     }
  // };

  useEffect(() => {
    if (isLoadingDelete) {
      toastId.current = toast.loading("Please wait...", {
        toastId: "overtime11",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (isSuccessDelete) {
      toast.dismiss(toastId.current);
      toast.success("Overtime created successfully.", {
        render: "Overtime created successfully.",
             type: "success",
             autoClose: 10000,
             theme: "colored",
           isLoading: false
      });

      let url = `/masterovertime?page=${page}&userSpecific=${0}&limit=50`;
      getAllOverTime(url);
    }
    if (isErrorDelete) {
        toast.dismiss(toastId.current);
        const toastMessage = 
            apiError.data.message
              ? apiError.data.message
              :
               "Something went wrong";
            toast.error(toastMessage, {
              render:toastMessage,
                   type: "error",
                   autoClose: 10000,
                   duplicate: false,
                   isLoading: false
            });
      }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isErrorDelete, isLoadingDelete, isSuccessDelete ]);

  const handlerDelete = (e, id, close) => {
    deleteOverTime(id);
    close();
  };

  return (
    <>
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <h3 className=" font-semibold text-lg">Overtime Types</h3>
        <div className="headerButton">
          {overTimeTypesCreatePermissions[0]?.status === true &&
          <Popup
            open={open}
            onClose={() => closeModal()}
            closeOnDocumentClick={false}
            trigger={
              <button
                className="btn-md btn-add"
                id="AddOvertimeTypes"
                onClick={() => setOpen((o) => !o)}
              >
                <span className="fa fa-plus mr-2" />
                Add Overtime Type
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button
                  className="close"
                  id="AddOvertimeTypesClose"
                  onClick={() => {
                    closeModal();
                    close();
                  }}
                >
                  &times;
                </button>
                <AddOverTimeSetting
                  getAllOverTime={getAllOverTime}
                  page={page}
                  close={close}
                
                />
              </div>
            )}
          </Popup>}
        </div>
      </div>

      <div className="p-4">
        <div className="overflow-x-auto w-full whitespace-nowrap">
          <table className="table-auto table  w-full">
            <thead className="thead-dark ">
              <tr>
                <th className="w-44"> Name</th>
                {/* <th>Color</th> */}
                <th>Status</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {overTimeData?.length > 0 &&
                overTimeData.map((over, index) => (
                  <tr key={index}>
                    <td>{over.overtime_name}</td>
                    {/* <td>
                      <div
                        className="rounded"
                        style={{
                          backgroundColor: `${over.color}`,
                          width: "30px",
                          height: "25px"
                        }}
                      />
                    </td> */}
                    <td>{over?.status === "1" ? "Active" : "InActive"}</td>
                    <td>{over?.company_id !== null ? 'Custom' : 'Default'}</td>
                    {roleIdData === 1 ? (
                      <td>
                        <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-eye" />
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="DetailOvertimeTypes"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <AllDetailsOvertime overDetails={over} />
            
                    </div>
                  )}
                        </Popup>
                      {overTimeTypesUpdatePermissions[0]?.status === true ? 
                      <Popup
                        open={openEdit}
                        onClose={() => closeModal()}
                        closeOnDocumentClick={false}
                        trigger={
                          <div
                            onClick={() => setOpen((o) => !o)}
                            className="actionBtn"
                          >
                            <i className="fa fa-pencil" />
                          </div>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button
                              className="close"
                              id="editOvertimeTypes"
                              onClick={() => {
                                closeModal();
                                close();
                              }}
                            >
                              &times;
                            </button>
                            <EditOverTimeSetting
                              getAllOverTime={getAllOverTime}
                              page={page}
                              close={close}
                              over={over}
                            />
                          </div>
                        )}
                      </Popup> :  <button className="actionBtn disabled"><i className="fa fa-pencil" /></button> }
                      {overTimeTypesDeletePermissions[0]?.status === true ? 
                      <Popup
                        open={openDelete}
                        onClose={() => closeModal()}
                        closeOnDocumentClick={false}
                        trigger={
                          <div
                            onClick={() => setOpen((o) => !o)}
                            className="actionBtn cancelBtn"
                          >
                            <i className="fa fa-trash" />
                          </div>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button
                              className="close"
                              id="DeleteOvertimeTypes"
                              onClick={() => {
                                closeModal();
                                close();
                              }}
                            >
                              &times;
                            </button>
                            <div className="header">
                              {" "}
                              <h3>Are you sure?</h3>
                            </div>
                            <div className="content">
                              <p>
                                Are you sure you want to delete this
                                {over.overtime_name} Over Time?
                              </p>
                              <div className="w-full mt-8">
                                <div className="form-group pt-4 pb-3 flex justify-center ">
                                  <button
                                  id="DeleteOvertimeTypesYes"
                                    className="btn save mr-2"
                                    onClick={(e) =>
                                      handlerDelete(e, over.id, close)
                                    }
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    id="DeleteOvertimeTypesNo"
                                    onClick={() => close()}
                                    className="btn cancel  "
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popup> :  <button className="actionBtn disabled" id="DeleteOvertimeTypesTrash"><i  className="fa fa-trash" /></button>}
                    </td>
                    ) : over?.company_id !== null ? (
                      <td>
                        {overTimeTypesUpdatePermissions[0]?.status === true ? 
                        <Popup
                          open={openEdit}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa fa-pencil" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="editOvertimeTypes"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <EditOverTimeSetting
                                getAllOverTime={getAllOverTime}
                                page={page}
                                close={close}
                                over={over}
                              />
                            </div>
                          )}
                        </Popup> :  <button className="actionBtn disabled"><i className="fa fa-pencil" /></button> }
                        {overTimeTypesDeletePermissions[0]?.status === true ? 
                        <Popup
                          open={openDelete}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn cancelBtn"
                            >
                              <i className="fa fa-trash" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="DeleteOvertimeTypes"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <div className="header">
                                {" "}
                                <h3>Are you sure?</h3>
                              </div>
                              <div className="content">
                                <p>
                                  Are you sure you want to delete this
                                  {over.overtime_name} Over Time?
                                </p>
                                <div className="w-full mt-8">
                                  <div className="form-group pt-4 pb-3 flex justify-center ">
                                    <button
                                    id="DeleteOvertimeTypesYes"
                                      className="btn save mr-2"
                                      onClick={(e) =>
                                        handlerDelete(e, over.id, close)
                                      }
                                    >
                                      Yes
                                    </button>
                                    <button
                                      type="button"
                                      id="DeleteOvertimeTypesNo"
                                      onClick={() => close()}
                                      className="btn cancel  "
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup> :  <button className="actionBtn disabled" id="DeleteOvertimeTypesTrash"><i  className="fa fa-trash" /></button>}
                      </td>
                    ) :
                    <td>
                        <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-eye" />
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="DetailOvertimeTypes"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <AllDetailsOvertime overDetails={over} />
            
                    </div>
                  )}
                        </Popup>
                        {overTimeTypesUpdatePermissions[0]?.status === true ? 
                      <Popup
                        open={openEdit}
                        onClose={() => closeModal()}
                        closeOnDocumentClick={false}
                        trigger={
                          <div
                            onClick={() => setOpen((o) => !o)}
                            className="actionBtn"
                          >
                            <i className="fa fa-pencil" />
                          </div>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button
                              className="close"
                              id="editOvertimeTypes"
                              onClick={() => {
                                closeModal();
                                close();
                              }}
                            >
                              &times;
                            </button>
                            <EditOverTimeSetting
                              getAllOverTime={getAllOverTime}
                              page={page}
                              close={close}
                              over={over}
                            />
                          </div>
                        )}
                      </Popup> :  <button className="actionBtn disabled"><i className="fa fa-pencil" /></button> }
                    </td>
                    }
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            page={page}
            pages={pages}
            changePage={setPage}
            decrementPage={decrementPage}
          />
        </div>
      </div>
    </>
   
  );
};

export default OverTime;

const AllDetailsOvertime = ({overDetails}) => {
  return (
    <>
    <div className="header">
      {" "}
      <h3> OverTime Details</h3>
    </div>
        <div className="content">
        <div className="w-full">
        <div className="mb-3">
          <h3 className="text-[14px]">
          <span className="w-36 inline-block">  OverTime Name:</span>
                <span className=" text-black font-medium pl-2">
              {overDetails?.overtime_name}
            </span>
          </h3>
      </div>
    
      <div className="mb-3">
          <h3 className="text-[14px]">
          <span className="w-36 inline-block"> Status:</span>
                <span className=" text-black font-medium pl-2">
              {overDetails?.status === '1' ? 'Active' : 'Inactive'}
            </span>
          </h3>
      </div>
      {/* <div className="mb-3">
          <h3 className="text-[14px]">
          <span className="w-36 inline-block">Approver:</span>
                <span className=" text-gray font-medium pl-2">
              {overDetails?.approver}
            </span>
          </h3>
      </div> */}
    
      <div className="mb-3">
        <h3 className="text-[14px] flex">
        <span className="w-36 inline-block">Color</span>
              <div
                className="rounded inline-block ml-2"
                style={{
                  backgroundColor: `${overDetails.color}`,
                  width: "30px",
                  height: "25px"
                }}
              />
        </h3>
      </div>
      
        </div>
        </div>
                   
   </>
  );

};