import { useState, useRef } from "react";
import { MultiStepForm, Step } from "react-multi-form";
import { DateFiled, FormInput, FormSelect, FormTextarea } from "../../components/formInput";
import { useEffect } from "react";
import { useUpdateEmployeeMutation } from "../../services/employeeApi";
import { useGetApproverListAPIMutation } from "../../services/approverListApi";
import { toast } from "react-toastify";
import { checkValidations } from "./empolyeeValidation";
import { parseISO } from "date-fns";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { calculateProRataLeave } from "../../helper/helper";

const EditEmployee = ({ allDepartmentListData, editData, closeModal, getEmployee, currentPage, toggleState, roles }) => {

  //Selectors for carrying out data from redux store
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const nextcarryForward = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.nextcarryForward);

  //States and Hooks
  const toastId = useRef(null);
  const [active, setActive] = useState(1);
  const [isSame, setIsSame] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [ispopupChecked, setIspopupChecked] = useState(false);
  const [approverListData, setApproverListData] = useState();
  const [leaveListData, setLeaveListData] = useState();
  const [expenseListData, setExpenseListData] = useState();
  const [errorValidations, setErrorValidations] = useState({
    multipleApproverError: ""
  });
  const [proRateStatus, setProRateStatus] = useState();
  const [employeeData, setEmployeeData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "0",
    email: "",
    mobileNumber: "",
    department: 0,
    rolesId: "",
    employeeStartDate: "",
    annualAllowance: "",
    hoursOrDay: "0",
    proRate: "",
    maximumLeaveCarry: "",
    note: "",
    leaveApprover: "0",
    expenseApprover: "0",
    overtimeApprover: "0",
    isApproveLeave: "0",
    isApproveExpense: "0",
    isApproveOvertime: "0",
    status: "1",
    countryCode: "",
    departmentInfo: null,
    designation: ""
  });

  //Api Calling
  const [updateEmployee, { isSuccess, isError, error: apiError, isLoading }] = useUpdateEmployeeMutation();
  const [getApproverListAPI, { data: approverList, isSuccess: isApproveSuccess }] = useGetApproverListAPIMutation();
  
  const genderData = [
    { value: 0, name: "Select" },
    { value: 1, name: "Male" },
    { value: 2, name: "Female" },
    { value: 3, name: "Other" }
  ];
  const hourData = [
    { value: 0, name: "Select" },
    { value: 1, name: "Hours" },
    { value: 2, name: "Days" }
  ];

  useEffect(() => {
      if (isLoading) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccess) {
        toast.dismiss(toastId.current);
        toast.success("Employee profile updated successfully.", {
          render: "Employee profile updated successfully.",
          type: "success",
          autoClose: 8000,
          isLoading: false,
          theme: "colored"
        });
        let payload = `/employee?page=${currentPage}&status=${
          toggleState === "Leavers" ? "0" : "1"
        }`;
        getEmployee(payload);
        closeModal();
      }
      if (isError) {
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.dismiss(toastId.current);
        toast.success(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          duplicate: false,
          isLoading: false
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, isError, isLoading]);

  useEffect(() => {
      if (approverList?.data?.leaveApprover.length > 0) {
        setLeaveListData(approverList.data.leaveApprover);
      }
      if (approverList?.data?.expenseApprover.length > 0) {
        setExpenseListData(approverList.data.expenseApprover);
      }
      if (approverList?.data?.ovetime_lieuApprover.length > 0) {
        setApproverListData(approverList.data.ovetime_lieuApprover);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isApproveSuccess]);

  useEffect(() => {
      if (isChecked) {
        setEmployeeData({ ...employeeData, proRate: 1 });
      } else {
        setEmployeeData({ ...employeeData, proRate: 0 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked]);

  useEffect(() => {
      if (
        employeeData?.employeeStartDate &&
        employeeData?.annualAllowance !== ""
      ) {
        let data = calculateProRataLeave(employeeData?.annualAllowance, employeeData?.employeeStartDate, nextcarryForward, "editProrata");
        setProRateStatus(data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employeeData?.employeeStartDate, employeeData?.annualAllowance]);

  useEffect(() => {
      let arrinfo = editData?.rolesInfo;
      let y = [...arrinfo].reverse();
      let editRoleID = y[0]?.m_roles_id;

      setEmployeeData({
        ...employeeData,
        firstName: editData?.first_name,
        lastName: editData?.last_name,
        dob: editData?.dob !== null ? parseISO(editData?.dob) : new Date(),
        gender: editData?.gender ? editData?.gender : 0,
        email: editData?.email,
        mobileNumber: editData?.mobile_no,
        department: editData?.department_id,
        rolesId: editRoleID ? editRoleID : "",
        employeeStartDate: editData?.employment_start_date !== null ? parseISO(editData?.employment_start_date) : new Date(),
        annualAllowance: editData?.annual_allowance,
        hoursOrDay: editData?.hours_or_day,
        proRate: editData?.pro_rata,
        maximumLeaveCarry: editData?.maximum_leave_carry,
        designation: editData?.designation,
        note: editData?.note,
        leaveApprover: editData?.leave_approver?.toString(),
        expenseApprover: editData?.expense_approver?.toString(),
        overtimeApprover: editData?.overtime_lieu_approver?.toString(),
        isApproveLeave: editData?.employee_can_approve_leave ? editData?.employee_can_approve_leave: "0",
        isApproveExpense: editData?.employee_can_approve_expense ? editData?.employee_can_approve_expense : "0",
        isApproveOvertime: editData?.employee_can_approve_ovetime_lieu ? editData?.employee_can_approve_ovetime_lieu : "0",
        status: "1",
        countryCode: editData?.country_code,
        id: editData?.id,
        departmentInfo: editData?.departmentInfo
      });
      setIsChecked(editData === "1" ? true : false);
      if (
        editData?.leave_approver === editData?.expense_approver &&
        editData?.leave_approver === editData?.overtime_lieu_approver &&
        editData?.expense_approver === editData?.overtime_lieu_approver
      ) {
        if (
          editData?.leave_approver !== null &&
          editData?.leave_approver !== "undefined" &&
          editData?.overtime_lieu_approver !== "undefined" &&
          editData?.overtime_lieu_approver !== null &&
          editData?.expense_approver !== null &&
          editData?.expense_approver !== "undefined"
        ) {
          setIsSame(true);
        }
      } else {
        setIsSame(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editData]);

  useEffect(() => {
      if (isSame) {
        setEmployeeData({
          ...employeeData,
          overtimeApprover: employeeData.leaveApprover,
          expenseApprover: employeeData.leaveApprover
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSame]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    let message = checkValidations(name, value);
    setErrorValidations({ ...errorValidations, [name]: message });
    setEmployeeData({ ...employeeData, [name]: value });
    if (name === "annualAllowance") {
      let data = calculateProRataLeave(value, employeeData?.employeeStartDate, nextcarryForward, "editProrata");
      setProRateStatus(data);
    }
  };

  const handleDate = (date, name) => {
    setEmployeeData({ ...employeeData, [name]: date });
    if (name === "employeeStartDate" && employeeData.annualAllowance !== "") {
      let data = calculateProRataLeave(employeeData?.annualAllowance, date, nextcarryForward, "editProrata");
      setProRateStatus(data);
    }
  };

  const handleCheckApprovers = (e) => {
    let { name } = e.target;
    if (name === "isApproveLeave") {
      setEmployeeData({
        ...employeeData,
        [name]: e.target.checked ? "1" : "0"
      });
    } else if (name === "isApproveExpense") {
      setEmployeeData({
        ...employeeData,
        [name]: e.target.checked ? "1" : "0"
      });
    } else if (name === "isApproveOvertime") {
      setEmployeeData({
        ...employeeData,
        [name]: e.target.checked ? "1" : "0"
      });
    }
  };

  const handelCountryCode = (e) => {
    const codeRegex = /^\+[0-9]{1,3}$/;
    const plusRegex = /\+/;
    let isCode = codeRegex.test(e.target.value);
    setEmployeeData({ ...employeeData, countryCode: e.target.value });
    if (!plusRegex.test(e.target.value)) {
      setErrorValidations({
        ...errorValidations,
        countryCode: "Country code must Contains + "
      });
    } else if (isCode) {
      setErrorValidations({ ...errorValidations, countryCode: "" });
    } else {
      setErrorValidations({
        ...errorValidations,
        countryCode: "Please enter a valid country code"
      });
    }
  };

  const handelMultiApprove = () => {
    if (
      approverList?.data?.leaveApprover.length > 0 &&
      approverList?.data?.expenseApprover.length > 0 &&
      approverList?.data?.ovetime_lieuApprover.length > 0
    ) {
      setIsSame(!isSame);
    } else {
      setErrorValidations({
        ...errorValidations,
        ["multipleApproverError"]: "No data in list so unable to check"
      });
      setIsSame(false);
    }
  };

  const validForm = () => {
    const {firstName, email, gender, employeeStartDate, department, mobileNumber, annualAllowance, hoursOrDay, lastName, maximumLeaveCarry, designation, dob, countryCode } = employeeData;
    let isFormValidated = true;
    if (firstName === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          firstName: "Please enter first name"
        };
      });
      isFormValidated = false;
    }
    if (employeeStartDate === "" || employeeStartDate === null) {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          employeeStartDate: "Please enter start date"
        };
      });
      isFormValidated = false;
    }
    isFormValidated = true;
    if (lastName === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          lastName: "Please enter last name"
        };
      });
      isFormValidated = false;
    }
    if (email === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          email: "Please enter the email"
        };
      });
      isFormValidated = false;
    }
    if (gender === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          gender: "Please select gender"
        };
      });
      isFormValidated = false;
    }
    if (department === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          department: "Please select department"
        };
      });
      isFormValidated = false;
    }
    if (mobileNumber === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          mobileNumber: "Please enter the mobile number"
        };
      });
      isFormValidated = false;
    }
    if (annualAllowance === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          annualAllowance: "Please enter the annual allowance"
        };
      });
      isFormValidated = false;
    }
    if (hoursOrDay === "0") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          hoursOrDay: "Please select Hours Or Day"
        };
      });
      isFormValidated = false;
    }

    if (maximumLeaveCarry === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          maximumLeaveCarry: "Please enter data"
        };
      });
      isFormValidated = false;
    }
    if (designation === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          designation: "Please enter designation"
        };
      });
      isFormValidated = false;
    }
    if (dob === "" || dob === null) {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          dob: "Please enter date of birth"
        };
      });
      isFormValidated = false;
    }

    if (countryCode === "") {
      setErrorValidations((errorValue) => {
        return {
          ...errorValue,
          countryCode: "Please enter country code"
        };
      });
      isFormValidated = false;
    }
    return isFormValidated;
  };

  const handleNext2 = (e) => {
    e.preventDefault();
    if (validForm()) {
      if (ispopupChecked) {
        setEmployeeData((prevData) => ({
          ...prevData,
          annualAllowance: proRateStatus.toString()
        }));
      }
      if (employeeData.department === "") {
        alert("Please Fill all the Fields");
      } else {
        let payload = `employee/approver/list/?department_id=${employeeData?.department}`;
        getApproverListAPI(payload);

        setActive(active + 1);
      }
      setIsChecked(true);
      setIspopupChecked(false);
    }
  };

  const handelNext = (e) => {
    e.preventDefault();
    if (validForm()) {
      setIspopupChecked(true);
    }
  };

  const handelAddEmployee = () => {
    updateEmployee(employeeData);
  };

  const handleNoClick = (e) => {
    e.preventDefault();
    if (validForm()) {
      if (employeeData.department === "") {
        alert("Please Fill all the Fields");
      } else {
        let payload = `employee/approver/list/?department_id=${employeeData?.department}`;
        getApproverListAPI(payload);

        setActive(active + 1);
      }
      setIsChecked(false);
      setIspopupChecked(false);
    }
  };

  return (
    <>
      <div className="content">
        <div className="MultiFrom">
          <MultiStepForm activeStep={active} accentColor="#023a51">
            <Step label=" General Information" className="MultiFrom">
              <div className="card border p-3">
                <form>
                  <h3 className="sm:hidden block mb-3 text-base">
                    General Information
                  </h3>
                  <div className="flex flex-wrap ">
                    <div className="md:w-1/2 md:pr-6 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">First Name</label>
                        <FormInput
                          inputType="text"
                          inputName="firstName"
                          inputID="FirstName"
                          value={employeeData.firstName}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.firstName}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full md:pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Last Name</label>
                        <FormInput
                          inputType="text"
                          inputName="lastName"
                          inputID="LastName"
                          value={employeeData.lastName}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.lastName}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 md:pr-6 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Date of Birth</label>
                        <DatePicker
                          name="dob"
                          id="dob"
                          dateFormat="dd/MM/yyyy"
                          selected={
                            employeeData?.dob
                              ? new Date(employeeData?.dob)
                              : editData?.dob
                              ? new Date(editData?.dob)
                              : new Date()
                          }
                          onChange={(date) => handleDate(date, "dob")}
                          placeholderText={"DD/MM/YYYY"}
                          className="formControl"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.dob}
                        </div>
                      </div>
                    </div>

                    <div className="md:w-1/2 w-full lg:pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock">Gender</label>

                        <select
                          name="gender"
                          id="gender"
                          value={
                            employeeData?.gender ? employeeData?.gender : "0"
                          }
                          onChange={handleChange}
                          className="formControl"
                        >
                          {genderData?.map((gender, index) => {
                            return (
                              <FormSelect
                                key={index}
                                optionValue={gender.value}
                                optionName={gender.name}
                              />
                            );
                          })}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.gender}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full md:pr-6">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Email</label>
                        <FormInput
                          inputType="text"
                          inputName="email"
                          inputID="Email"
                          value={employeeData.email}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.email}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Mobile Number</label>
                        <div className="flex">
                          <input
                            type="text"
                            id="mobile"
                            name="countryCode"
                            value={employeeData.countryCode}
                            onChange={(e) => handelCountryCode(e)}
                            className="appearance-none w-20 bg-gray-100 border mr-2 rounded text-gray-700 py-2 px-3 focus:outline-none focus:border-gray-500"
                            placeholder="+44"
                          />
                          <FormInput
                            inputType="text"
                            inputName="mobileNumber"
                            inputID="MobileNumber"
                            value={employeeData.mobileNumber}
                            handlerChange={handleChange}
                          />
                        </div>
                        <div className="help-block text-red-700">
                          {errorValidations?.countryCode
                            ? errorValidations?.countryCode
                            : errorValidations?.mobileNumber}
                        </div>
                      </div>
                    </div>

                    <div className="md:w-1/2 w-full md:pr-6">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Department</label>
                        <select
                          name="department"
                          id="department"
                          defaultValue={editData?.department_id}
                          onChange={handleChange}
                          disabled={roleIdData !== 2}
                          className="formControl"
                        >
                          <FormSelect optionValue={"0"} optionName={"Select"} />
                          ;
                          {allDepartmentListData?.length > 0
                            ? allDepartmentListData?.map((departments, key) => {
                                return (
                                  <FormSelect
                                    key={key}
                                    optionValue={departments?.id}
                                    optionName={departments?.department_name}
                                  />
                                );
                              })
                            : null}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.department}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Role</label>
                        <select
                          id="role"
                          className="formControl"
                          name="rolesId"
                          value={employeeData?.rolesId}
                          onChange={handleChange}
                          disabled={roleIdData !== 2}
                        >
                          <>
                            <FormSelect
                              key={0}
                              optionValue={""}
                              optionName={"Select"}
                            />
                            {roles?.roles?.map((role, index) => {
                              return (
                                <FormSelect
                                  key={index + 1}
                                  optionValue={role.id}
                                  optionName={role?.role_name}
                                />
                              );
                            })}
                          </>
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.rolesId}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 md:pr-6 w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock">Start date</label>
                        <DateFiled
                          id="startDate"
                          name="employeeStartDate"
                          dateFormat="dd/MM/yyyy"
                          value={
                            employeeData?.employeeStartDate
                              ? new Date(employeeData?.employeeStartDate)
                              : editData?.employment_start_date
                              ? new Date(editData?.employment_start_date)
                              : new Date()
                          }
                          handlerChange={(date) =>
                            handleDate(date, "employeeStartDate")
                          }
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.employeeStartDate}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 md:pr-0 w-full ">
                      <div className="flex flex-wrap md:flex-nowrap">
                        <div className="form-group mb-6 md:pr-2 w-full ">
                          <label className="formBlock	">
                            Annual Allowance
                            <span
                              className="fa fa-question w-4 ml-1 text-center h-4 inline-flex text-sm items-center rounded-full text-white bg-gray-800 p-1"
                              title="Please enter the employees standard annual leave allowance."
                            />
                          </label>
                          <FormInput
                            inputType="text"
                            inputName="annualAllowance"
                            inputID="AnnualAllowance"
                            value={employeeData?.annualAllowance}
                            handlerChange={handleChange}
                          />
                          <div className="help-block text-red-700">
                            {errorValidations?.annualAllowance}
                          </div>
                          <Popup
                            open={ispopupChecked}
                            className="proRata"
                            position="right center"
                            modal
                          >
                            <div className="modal">
                              <button
                                className="close"
                                id="proRataClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              />
                              <h1 className="p-4 text-xl">
                                Pro Rata Allowance?
                              </h1>
                              <div className="w-full px-4 ">
                                <p>
                                  {employeeData?.firstName} is starting part way
                                  through a leave year. You might want to pro
                                  rata their allowance.
                                </p>
                                <p className="flex items-center mt-4 ">
                                  Set there allowance to{" "}
                                  <input
                                    type="text"
                                    id="leaveDayAdd"
                                    value={proRateStatus}
                                    style={{ borderBottom: "1px solid #000" }}
                                    className="leaveDayAdd"
                                    disabled
                                  />
                                </p>
                              </div>
                              <div className="form-group pt-10 pb-8 flex justify-center gap-6">
                                <button
                                  onClick={handleNext2}
                                  className="btn save"
                                  id="proRataYes"
                                >
                                  Yes
                                </button>
                                <button
                                  onClick={handleNoClick}
                                  className="btn cancel mr-2"
                                  id="proRataNo"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </Popup>
                        </div>
                        <div className="form-group mb-6 w-full  md:w-10/12">
                          <label className="formBlock	">Hours or Days</label>
                          <select
                            name="hoursOrDay"
                            id="hoursOrDay"
                            value={employeeData?.hoursOrDay}
                            onChange={handleChange}
                            className="formControl"
                          >
                            {hourData?.map((hour, index) => {
                              return (
                                <FormSelect
                                  key={index}
                                  optionValue={hour.value}
                                  optionName={hour.name}
                                />
                              );
                            })}
                          </select>
                          <div className="help-block text-red-700">
                            {errorValidations?.hoursOrDay}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full md:pr-6 ">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Maximum leave to carry over
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="maximumLeaveCarry"
                          inputID="carryOver"
                          value={employeeData?.maximumLeaveCarry}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.maximumLeaveCarry}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full md:pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">
                          Designation
                          <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <FormInput
                          inputType="text"
                          inputName="designation"
                          inputID="designation"
                          value={employeeData?.designation}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.designation
                            ? errorValidations?.designation
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="w-full pr-0">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Notes </label>
                        <FormTextarea
                          textName="note"
                          inputID="noteEditEmp"
                          value={employeeData?.note}
                          handlerChange={handleChange}
                        />
                        <div className="help-block text-red-700">
                          {errorValidations?.note}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Step>

            <Step label=" Authorization Settings">
              <div className="card border p-3">
                <h3 className="sm:hidden block mb-3 text-base">
                  Authorization Settings
                </h3>
                <div className="flex justify-between w-full flex-wrap">
                  <div className="md:w-1/2 md:pr-6 w-full">
                    <h4 className="mb-3 bg-primary text-white p-3 rounded text-base  ">
                      Approver settings:
                    </h4>
                    <p>
                      This is used to allocate which employee will approve this
                      employee’s requests
                    </p>
                    <div className="w-full mt-4">
                      <div className="form-group mb-2 ">
                        <label className="formBlock	">Leave Approver</label>
                        <select
                          name="leaveApprover"
                          disabled={isSame}
                          id="editLeaveApprove"
                          value={employeeData.leaveApprover}
                          onChange={handleChange}
                          className="formControl"
                        >
                          <FormSelect optionValue={"0"} optionName={"Select"} />
                          ;
                          {leaveListData?.map((approver, index) => {
                            return (
                              <FormSelect
                                key={index}
                                optionValue={approver?.id}
                                optionName={`${approver?.first_name} ${approver?.last_name}`}
                              />
                            );
                          })}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.leaveApprover}
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="form-group mb-3 ">
                        <label className="formBlock flex	">
                          <input
                            type="checkbox"
                            onChange={handelMultiApprove}
                            checked={
                              employeeData.leaveApprover == 0 ? false : isSame
                            }
                            disabled={
                              employeeData.leaveApprover == 0 ? true : false
                            }
                            className="mr-2 w-5 h-5"
                            id="editApprovalCategories"
                          />
                          Apply the same approver across all approval categories
                        </label>
                        <div className="help-block text-red-700">
                          {errorValidations.multipleApproverError
                            ? errorValidations.multipleApproverError
                            : ""}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="form-group mb-3 ">
                        <label className="formBlock	">Overtime Approver</label>
                        <select
                          name="overtimeApprover"
                          id="editOvertimeApprover"
                          disabled={isSame}
                          value={employeeData.overtimeApprover}
                          onChange={handleChange}
                          className="formControl"
                        >
                          <FormSelect optionValue={"0"} optionName={"Select"} />
                          ;
                          {approverListData?.map((approver, index) => {
                            return (
                              <FormSelect
                                key={index}
                                optionValue={approver.id}
                                optionName={`${approver?.first_name} ${approver?.last_name}`}
                              />
                            );
                          })}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.overtimeApprover}
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="form-group mb-6 ">
                        <label className="formBlock	">Expenses Approver</label>
                        <select
                          name="expenseApprover"
                          disabled={isSame}
                          id="editExpenseApprover"
                          value={employeeData.expenseApprover}
                          onChange={handleChange}
                          className="formControl"
                        >
                          <FormSelect optionValue={"0"} optionName={"Select"} />
                          ;
                          {expenseListData?.map((approver, index) => {
                            return (
                              <FormSelect
                                key={index}
                                optionValue={approver?.id}
                                optionName={`${approver?.first_name} ${approver?.last_name}`}
                              />
                            );
                          })}
                        </select>
                        <div className="help-block text-red-700">
                          {errorValidations?.expenseApprover}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:w-1/2 w-full lg:pr-0">
                    <h4 className="mb-3 bg-secondry text-white p-3 rounded text-base">
                      Make employee an approver:
                    </h4>
                    <p>
                      This is used to give an employee ‘approver status’ so they
                      can approve requests from their allocated employees.
                    </p>
                    <div className="form-group mb-10 mt-4">
                      <label className="formBlock flex	">
                        <input
                          type="checkbox"
                          name="isApproveLeave"
                          id="editApproveLeave"
                          onChange={handleCheckApprovers}
                          defaultChecked={
                            editData?.employee_can_approve_leave == 1
                          }
                          className="mr-2 w-5 h-5"
                        />{" "}
                        Employee can approve leave requests
                      </label>
                      <div className="help-block text-red-700" />
                    </div>
                    <div className="form-group mb-10 ">
                      <label className="formBlock flex	">
                        <input
                          type="checkbox"
                          id="editApproveExpense"
                          name="isApproveExpense"
                          onChange={handleCheckApprovers}
                          defaultChecked={
                            editData?.employee_can_approve_expense == 1
                          }
                          className="mr-2 w-5 h-5"
                        />{" "}
                        Employee can approve expenses claims
                      </label>

                      <div className="help-block text-red-700" />
                    </div>
                    <div className="form-group mb-6 ">
                      <label className="formBlock flex	">
                        <input
                          type="checkbox"
                          id="editApproveOvertime"
                          name="isApproveOvertime"
                          onChange={(e) => handleCheckApprovers(e)}
                          defaultChecked={
                            editData?.employee_can_approve_ovetime_lieu == 1
                          }
                          className="mr-2 w-5 h-5"
                        />
                        Employee can approve overtime/time in lieu requests
                      </label>
                      <div className="help-block text-red-700" />
                    </div>
                  </div>
                </div>
              </div>
            </Step>
          </MultiStepForm>
          <div className="form-group pt-4 pb-3 flex justify-center ">
            {active !== 1 && (
              <>
                <button
                  onClick={() => setActive(active - 1)}
                  className="btn cancel mr-2"
                  id="editBack"
                >
                  Back
                </button>
                <button
                  onClick={handelAddEmployee}
                  className="btn save"
                  id="editSave"
                >
                  Save
                </button>
              </>
            )}
            {active !== 2 && (
              <button
                className="btn save"
                id="editNext"
                onClick={handelNext}
                style={{ float: "right" }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmployee;
