/* eslint-disable camelcase */
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "react-datepicker/dist/react-datepicker.css";
import { SearchField } from "../../components/filters";
import { FormSelect } from "../../components/formInput";
import "react-datepicker/dist/react-datepicker.css";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import Pagination from "../../components/inc/pagination";
import { CSVLink } from "react-csv";
import moment from "moment/moment";
import NoDataFound from "../../helper/noDataFound";
import DatePicker from "react-datepicker";
import { LoadingIndicator } from "../../components/loading-indicator";
import { useDispatch, useSelector } from "react-redux";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { overTimeDataAction } from "../../redux/OverTimeSlice/overtimeListSlice";
import { useMyOvertimeListMutation } from "../../services/myOvertimeApi";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const OvertimeReport = () => {

  const dispatch = useDispatch();
  const ignore = useRef(false);
  /* API calling */
  const [ getDepartmentList, { data: departmentApiResponse, isSuccess: isSuccessDepartmentApi } ] = useGetDepartmentListMutation();
  const [ myOvertimeList, { data: getAllOverTimeApiResponseData, isSuccess, isLoading } ] = useMyOvertimeListMutation();

  /*Carrying Out data from redux*/
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const getAllOverTimeApiResponse = useSelector((state) => state?.persistedReducer?.overtimeListSlice?.overTimeData);
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);

  /*States are mentioned here*/
  const createCsvFileName = () => `MyOverTime_report_${moment().format()}.csv`;
  const [ allOverTime, setAllOverTime ] = useState();
  const [ allDepartmentListData, setAllDepartmentListData ] = useState();
  const [ excelData, setExcelData ] = useState([  ]);
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const statusData = [ 
    { value: "0", label: "Awaiting approval" },
    { value: "1", label: "Approved" },
    { value: "2", label: "Rejected" }
  ];

  //pagination state are defined here
  const [ searchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);

  useEffect(() => {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&time_in_lieu=${0}`;
      myOvertimeList(payload);
   
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ page ]
  );

  useEffect(() => {
      if (getAllOverTimeApiResponse) {
        setAllOverTime(getAllOverTimeApiResponse?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ getAllOverTimeApiResponse ]
  );

  useEffect(() => {
      if (isSuccess) {
        dispatch(overTimeDataAction(getAllOverTimeApiResponseData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccess ]
  );

  useEffect(() => {
    if (!ignore.current){
      getDepartmentList();
    }
    return () => { ignore.current = true; };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [  ]
  );

  useEffect(() => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessDepartmentApi ]
  );

  useEffect(() => {
      if (departmentApiData) {
        setAllDepartmentListData(departmentApiData?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ departmentApiData ]
  );

  useEffect(() => {
    if(getAllOverTimeApiResponse?.data){
        setPages(getAllOverTimeApiResponse?.totalPages);
        if(getAllOverTimeApiResponse?.data){
          let csvData = exportTOCSV(getAllOverTimeApiResponse?.data);
          setExcelData(csvData);
        }
       
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ getAllOverTimeApiResponse?.data ]);

  /*EXPORT TO CSV*/
  let headers = [ 
    { label: "Employee Name", key: "employeeName" },
    { label: "Department Name", key: "departmentName" },
    { label: "Type", key: "type" },
    { label: "Approver", key: "Approver" },
    { label: "Date", key: "date" },
    { label: "Start Time", key: "startTime" },
    { label: "End Time", key: "endTime" }
   ];
  const exportTOCSV = (overTimeinFo) => {
    let dataAbence = [  ];
    if (overTimeinFo) {
      overTimeinFo?.forEach((dExcel) => {
        let val = {
          employeeName:  `${dExcel?.employeeInfo?.first_name} ${dExcel?.employeeInfo?.last_name}`,
          departmentName: dExcel?.department_info?.department_name,
          type: dExcel?.mOverTimeInfo?.overtime_name,
          Approver: `${dExcel?.approvedByInfo?.first_name} ${dExcel?.approvedByInfo?.last_name}`,
          date: moment(dExcel?.start_date).format(formattedDate),
          startTime: dExcel?.start_time,
          endTime: dExcel?.end_time
        };
        dataAbence.push(val);
      });
      return dataAbence;
    }
  };

  /*Filters are defined here*/
  const handlerDepartmentFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "0") {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&time_in_lieu=${0}`;
      myOvertimeList(payload);
    } else {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&department_id=${e.target.value}&time_in_lieu=${0}`;
      myOvertimeList(payload);
    }
  };

  const myOvertimeFilter = useCallback(
    (searchTerm) => {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&first_name=${searchTerm}&time_in_lieu=${0}`;
      myOvertimeList(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [  ]
  );

  const handlerStatusFilter = (e) => {
    if (e.target.value === "0") {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&time_in_lieu=${0}`;
      myOvertimeList(payload);
    } else {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&status=${e.target.value}&time_in_lieu=${0}`;
      myOvertimeList(payload);
    }
  };

  const onDateChange = (e) => {
    if (e[0] !== null && e[1] !== null) {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&start_date=${moment(
        e[0]
      ).format("YYYY-MM-DD")}&end_date=${moment(e[1]).format("YYYY-MM-DD")}&time_in_lieu=${0}`;
      myOvertimeList(payload);
    } else {
      let payload = `/overtimes/?page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&time_in_lieu=${0}`;
      myOvertimeList(payload);
    }
  };

  const decrementPage = () => {
    if(getAllOverTimeApiResponse?.currentPage - 1 >= 0){
      myOvertimeList(getAllOverTimeApiResponse?.currentPage - 1);
      setPage(getAllOverTimeApiResponse?.currentPage - 1);
    }
  };

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Overtime Report </h2>
              <div className="card ">
                <div className="p-4 ">
                  <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                    <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 ">
                      <SearchField
                        placeholderText="Search By Name"
                        searchId="reportOvertimeSearch"
                        onChange={(e) => myOvertimeFilter(e.target.value)}
                      />
                    </div>
                    <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full md:mb-0 mb-2 ">
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        id="reportOvertimeStartDate"
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        className="formControl"
                        placeholderText="Select Date Range"
                        onChange={(update) => {
                          setDateRange(update);
                          onDateChange(update);
                        }}
                        isClearable={true}
                        locale="en-GB"
                      />
                    </div>
                    <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 md:pr-4">
                      <select
                        className="formControl"
                        id="reportOvertimeDepartment"
                        name="department_name"
                        onChange={(e) => handlerDepartmentFilter(e)}
                      >
                        <FormSelect
                          optionValue={"0"}
                          optionName={"Select Department"}
                        />
                        ;
                        {allDepartmentListData?.length > 0
                          ? allDepartmentListData?.map((departments, key) => {
                              return (
                                <FormSelect
                                  key={key}
                                  optionValue={departments?.id}
                                  optionName={departments?.department_name}
                                />
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-4 lg:pr-0">
                      <select
                        className="formControl"
                        id="reportOvertimStatus"
                        onChange={(e) => handlerStatusFilter(e)}
                      >
                        <FormSelect
                          optionValue={"0"}
                          optionName={"Select Status"}
                        />
                        {statusData?.map((status, key) => {
                          return (
                            <FormSelect
                              key={key}
                              optionValue={status?.value}
                              optionName={status.label}
                            />
                          );
                        })}
                      </select>
                    </div>
                    <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full ">
                      <CSVLink
                        className=""
                        data={excelData}
                        headers={headers}
                        filename={createCsvFileName()}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          outline: "none",
                          height: "5vh"
                        }}
                      >
                        <button
                          className="btn-md btn-add "
                          id="reportOvertimeCsv"
                        >
                          <span className="fa fa-file-export mr-2" /> Export to
                          CSV
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                  <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th className="w-40"> Name</th>
                          <th>Department</th>
                          <th>Type </th>
                          <th>Approver</th>
                          <th>Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Total Paid Duration</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {isLoading ? (
                        <LoadingIndicator />
                      ) : (
                        <tbody>
                          {allOverTime?.length > 0 ? (
                            <>
                              {allOverTime?.length > 0 &&
                                allOverTime?.map((overtime, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Link
                                          to="/employees/detail"
                                          className="listImage flex items-center "
                                        >
                                          {overtime?.employeeInfo?.first_name +
                                            " " +
                                            overtime?.employeeInfo?.last_name}
                                        </Link>
                                      </td>
                                      <td>
                                        {
                                          overtime?.department_info
                                            ?.department_name
                                        }
                                      </td>
                                      <td>{overtime?.mOverTimeInfo?.overtime_name}</td>
                                      <td>
                                        {overtime?.addedByInfo?.first_name}{" "}
                                        {overtime?.addedByInfo?.last_name}
                                      </td>
                                      <td>
                                        {moment(overtime?.date).format(
                                          formattedDate
                                        )}
                                      </td>
                                      <td>
                                       {overtime?.start_time}
                                      </td>
                                      <td>
                                       {overtime?.end_time}
                                      </td>
                                      <td>
                                       {(overtime?.duration/60).toFixed(1)} Hours
                                      </td>
                                      <td>
                                          <span className={
                                        overtime?.status === '0' ? 'yellow-bedge' :
                                        overtime?.status === '1' ? 'greenBedge' :
                                        overtime?.status === '3' ? 'amberBedge' :
                                        'blue-bedge'
                                        }>{overtime?.status === "1" ? "Approved" : overtime?.status === "3" ? "Redeemed" : overtime?.status === "0" ? "Awaiting approvals" : " "}</span>
                                        </td>
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <center className="mt-4">{<NoDataFound />}</center>
                          )}
                        </tbody>
                      )}
                    </table>
                    <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>      </div>
    </>
  );
};

export default OvertimeReport;
