/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState, useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Popup from "reactjs-popup";
import "react-datepicker/dist/react-datepicker.css";
import { FormSelect } from "../../components/formInput";
import {useGetAllMainExpensesMutation, useDeleteMainExpensesMutation, useGetAllMainExpensesCSVRecordMutation} from '../../services/expenseApi';
import DatePicker from "react-datepicker";
import AddExpense from "./AddExpense";
import AddMileageExpense from './AddMileageExpense';
import {useGetAllExpensesListMutation, useGetAllMileageExpensesMutation} from '../../services/masterExpensesApi';
import Pagination from "../../components/inc/pagination";
import {useSearchParams} from 'react-router-dom';
import {toast } from "react-toastify";
import  moments from 'moment';
import moment from 'moment/moment';
import EditMyExpense from "./EditMyExpenses";
import { CSVLink } from 'react-csv';
import EditMyMileage from "./EditMyMileage";
import { useDispatch, useSelector } from "react-redux";
import ViewPopup from "../../components/ViewPopup";
import { allExpenseDataAction, allMainExpenseDataAction, allMileageExpensesDataAction } from "../../redux/ExpenseSlice/expenseSlice";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale
import { REACT_APP_AUDITOR_ROLE_ID } from "../../helper/constant";

registerLocale('en-GB', enGB);

const Expense = () => {

  const ignore = useRef(false);
  let employee = localStorage.getItem('employeeData') ? JSON.parse(localStorage.getItem("employeeData")) : '';
  // useSelector
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const  expensesTypeResponseApi = useSelector((state) => state?.persistedReducer?.expenseSlice?.allExpenseDataList);
  const  MileageTypeRespons = useSelector((state) => state?.persistedReducer?.expenseSlice?.allMileageExpensesData);
  const  expenseApiRespo = useSelector((state) => state?.persistedReducer?.expenseSlice?.allMainExpenseData);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  // mutation
  const [ getAllExpensesList, {data: expensesTypeResponseData, isSuccess: isSuccessExpenseType, isLoading:expenseLoading} ] = useGetAllExpensesListMutation();
  const [ deleteMainExpenses, { isSuccess: isSuccessDelete, isError: isErrorDelete, isLoading:isLoadingDelete, error:apiError} ] = useDeleteMainExpensesMutation();
  const [ getAllMileageExpenses, {data: MileageTypeResponsData, isSuccess } ] = useGetAllMileageExpensesMutation();
  const [ getAllMainExpenses, { data: expenseApiRespoData, isSuccess: isSuccessExpenses, isLoading} ] = useGetAllMainExpensesMutation();
  const [ getAllMainExpensesCSVRecord, {data:expenseCSVApiRespo, isSuccess:isSuccessExpenseCSV} ]=useGetAllMainExpensesCSVRecordMutation();
 

  const [ open, setOpen ] = useState(false);
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const createCsvFileName = () => `MyExpense_report_${moment().format()}.csv`;
  const [ allExpense, setAllExpense ] = useState();
  const [ allExpenseType, setAllExpenseType ] = useState();
  const [ allMileageType, setAllMileageType ] = useState();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);
  const [ excelData, setExcelData ] = useState([]);
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const [ expenseFilterType, setExpenseFilterType] = useState();
  const [ endDateFilter, setEndDateFilter] = useState();
  const [ startDateFilter, setStartDateFilter] = useState();
  const [ claimTypeFilter, setClaimTypeFilter ] = useState();
  const [ statusFilter, setStatusFilter ] = useState();
  const [ type, setType ] = useState();

  const closeModal = () => {
    setOpen(false);
  };

  const statusData = [
    {value :"", label: "Select Status"},
    {value :"0", label: "Applied"},
    {value :"1", label: "Approved"},
    {value :"2", label: "Paid"},
    {value :"3", label: "Rejected"}
    
  ];

  const claimType = [
    {value :"0", label: "Select Claim Type"},
    {value :"1", label: "Expense"},
    {value :"2", label: "Mileage"}
  ]; 
 
 useEffect(() => {
  if(claimTypeFilter && claimTypeFilter != '0') {
    let payload = `/expenses/filter/search?page=${page}&is_mileage=${claimTypeFilter}&employee_id=${employee?.employee_id}`;
    getAllMainExpenses(payload);
  } else if(type && type !== '0') {
    let payload = `/expenses/filter/search?page=${page}&expense_type=${type}&employee_id=${employee?.employee_id}`;
      getAllMainExpenses(payload); 
  }else {
  let payload = `/expenses/filter/search?page=${page}&employee_id=${employee?.employee_id}`;
  getAllMainExpenses(payload);
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]); 

 useEffect(() => {
  if (!ignore.current){
    getAllExpensesList();
    getAllMileageExpenses();
  }
  return () => { ignore.current = true; };
 
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
useEffect(() => {
  if(isSuccess){
     dispatch(allMileageExpensesDataAction(MileageTypeResponsData));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ]);

useEffect(() => {
 if(MileageTypeRespons) {
  setAllMileageType(MileageTypeRespons?.data);
 }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ MileageTypeRespons ]);

 useEffect(() => {
  if (!ignore.current){
  let payload=`/expenses/filter/search?page=${1}&employee_id=${employee?.employee_id}&limit=100`;
  getAllMainExpensesCSVRecord(payload);
}
return () => { ignore.current = true; };
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

useEffect(() => {
  if(isSuccessExpenseCSV) {
    if(expenseCSVApiRespo?.data){
      let csvData = exportTOCSV(expenseCSVApiRespo?.data);
      setExcelData(csvData);
    }
   
  }

}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessExpenseCSV ]);

let  headers = [
  { label: 'Claim Type', key : 'claimType'},
  { label: 'Type', key : 'type'},
  { label: 'Start Date', key : 'start_date'},
  { label:  'End Date', key : 'end_date'},
  { label: 'Status', key : 'status'},
  { label: "Amount", key: 'amount'},
  { label: "amount of miles", key: 'amount_of_miles'},
  { label: 'Notes', key : 'notes'},
  { label: 'Reason', key : 'reason'},
  {label: 'IsMileage', key: 'is_mileage'},
  { label: 'CreatedAt', key : 'createdAt'},
  { label: 'UpdatedAt', key : 'updatedAt'}
 
  ];
  const exportTOCSV = (expensInfo) => {
    let  dataAbence =[];
    if(expensInfo){
      expensInfo?.forEach((dExcel) => {
        let hour = parseInt(dExcel.duration) /60;
        let status = dExcel?.status;
        let statusOvertime = (status === '0' ? 'Applied' : status === '1' ? 'Approved' : status === '2' ? 'Paid' : status === '3' ? 'Rejected' : '');
        let val ={
          claimType:dExcel?.expenseTypeInfo?.is_mileage === '0' ? 'Claim Expense' : 'Claim Mileage',
           type: dExcel?.expenseTypeInfo?.expense_name,
           start_date: moment(dExcel?.start_date).format('DD-MM-YYYY'),
           end_date: moment(dExcel?.end_date).format('DD-MM-YYYY'),
          // total_duration: hour,
           status: statusOvertime,
           amount: dExcel?.amount? dExcel?.amount: '',
           amount_of_miles : dExcel?.amount_of_miles ?dExcel?.amount_of_miles:'', 
          // time_in_lieu:  dExcel?.time_in_lieu === '1' ? 'Yes' : 'No',
           notes: dExcel?.notes,
           reason: dExcel?.reason,
          is_mileage: dExcel?.is_mileage === 0 ? 'No' : 'Yes',
           createdAt: moment(dExcel?.createdAt).format('DD-MM-YYYY'),
           updatedAt: moment(dExcel?.updatedAt).format('DD-MM-YYYY')
       };
        dataAbence?.push(val);
      });
      return dataAbence;
    }
 };

  const  handlerFilterName = (e) => {
    e.preventDefault();
    if(e.target.value === '0') {
      setExpenseFilterType("");
      let payload = `/expenses/filter/search?page=${page}&employee_id=${employee?.employee_id}`;
      getAllMainExpenses(payload);
    } else {
      let payload = `/expenses/filter/search?page=${page}&expense_type=${e.target.value}&employee_id=${employee?.employee_id}`;
      setExpenseFilterType(e.target.value);
      getAllMainExpenses(payload); 
      setType(e.target.value);
    }
  };

 const  handlerStatusFilter = (e) => {
  e.preventDefault();
  if(e.target.value === '0') {
    setStatusFilter(e.target.value);
    let payload =`/expenses/filter/search?page=${page}&employee_id=${employee?.employee_id}&status=${e.target.value}`;
    getAllMainExpenses(payload);
  } else {
    setStatusFilter(e.target.value);
    let payload = `/expenses/filter/search?page=${page}&status=${e.target.value}&employee_id=${employee?.employee_id}`;
    getAllMainExpenses(payload); 
  }
 };
 
 useEffect(() => {
  if(isSuccessExpenses) {
    dispatch(allMainExpenseDataAction(expenseApiRespoData));
  }
  
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessExpenses ]);

 useEffect(() => {
  if(expenseApiRespo) {
    if(expenseApiRespo?.appliedExpense?.rows < 1 && expenseApiRespo?.currentPage >1 ) {
      getAllExpensesList(expenseApiRespo?.currentPage - 1);
    }
      setPages(expenseApiRespo?.totalPages);
      setAllExpense(expenseApiRespo?.data);
  } 
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ expenseApiRespo ]);
useEffect(() => {
  if(isSuccessExpenseType){
     dispatch(allExpenseDataAction(expensesTypeResponseData));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessExpenseType ]);
 useEffect(() => {
    if(expensesTypeResponseApi?.data) {
      setAllExpenseType(expensesTypeResponseApi?.data);
    }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ expensesTypeResponseApi ]);

 useEffect(() => {
//   if (isLoadingDelete) {
//     setTimeout(() => {
//       toast.dismiss(toastId.current);
//     }, 10000);
//     toastId.current = toast.loading("Please wait...", {
//     position: "top-center",
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     theme: "colored",
//     type: "info"
//   });
// }
  if (isSuccessDelete) {
    setTimeout(() => {
      toast.dismiss(toastId.current);
    }, 10000);
    toast.success("Expense deleted successfully.", {
      render: "Expense deleted successfully..",
           type: "success",
           autoClose: 2000,
         isLoading: false,
         theme: "colored"
    });
    let payload = `/expenses/filter/search?page=${1}&employee_id=${employee?.employee_id}`;
    getAllMainExpenses(payload);
    let payload1=`/expenses/filter/search?page=${1}&employee_id=${employee?.employee_id}&limit=100`;
  getAllMainExpensesCSVRecord(payload1);
  }
  if (isErrorDelete) {
    setTimeout(() => {
      toast.dismiss(toastId.current);
    }, 10000);
    toast.dismiss(toastId.current);
    const toastMessage = apiError?.data?.message
      ? apiError?.data?.message
      : "Something went wrong";
        toast.error(toastMessage, {
          render:toastMessage,
               type: "error",
               autoClose: 2000,
               duplicate: false,
               isLoading: false
        });
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessDelete, isErrorDelete, isLoadingDelete ]);

 const handlerDelete = (e, id, close) => {
  e.preventDefault();
  deleteMainExpenses(id);
  close();

 };
 
 useEffect(() => {
  allFilters();
 }, [ expenseFilterType, statusFilter, startDateFilter, endDateFilter, claimTypeFilter, page, employee?.employee_id]);

 const allFilters = () => {
  let payload =`/expenses/filter/search?page=${page}&employee_id=${employee?.employee_id}&expense_type=${expenseFilterType ? expenseFilterType : ""}&start_date=${startDateFilter ? startDateFilter : ""}&end_date=${endDateFilter ? endDateFilter : ""}&is_mileage=${claimTypeFilter ? claimTypeFilter : ""}&status=${statusFilter ? statusFilter : ""}`;
  getAllMainExpenses(payload);
};


 const onDateChange = (e) => {
  if(e[0] !== null && e[1] !== null){
    setEndDateFilter(moment(e[1]).format('YYYY-MM-DD'));
    setStartDateFilter(moment(e[0]).format('YYYY-MM-DD'));
    let payload=`/expenses/filter/search?page=${page}&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}&employee_id=${employee?.employee_id}`;
    getAllMainExpenses(payload); 

  } else {
    setEndDateFilter("");
    setStartDateFilter("");
    let payload = `/expenses/filter/search?page=${page}&employee_id=${employee?.employee_id}`;
  getAllMainExpenses(payload);
  }
};

const handlerClaimTypeFilter = (e) => {
  if(e.target.value === '0') {
    setClaimTypeFilter("");
    let payload =`/expenses/filter/search?page=${page}&employee_id=${employee?.employee_id}`;
    getAllMainExpenses(payload);
  } else {
    let isMileage = e.target.value === '1' ? 0 : e.target.value === '2' ? 1 :0;
    let payload = `/expenses/filter/search?page=${page}&is_mileage=${isMileage}&employee_id=${employee?.employee_id}`;
    setClaimTypeFilter(isMileage);
    getAllMainExpenses(payload); 
  }
};

const decrementPage = () => {
  if(expenseApiRespo?.currentPage - 1 >= 0){
    getAllMainExpenses(expenseApiRespo?.currentPage - 1);
     setPage(expenseApiRespo?.currentPage - 1);
  }
};

 return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | My Expense </title>
          </Helmet>
        </HelmetProvider>

        <div>
          {/* {isLoading ?<LoadingIndicator/> : */}
          <div className="py-6 myExpensPage">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  "> My Expenses</h2>
              <div className="card bg-white  mb-3">
                <div className="heading_title px-4 flex justify-end items-center py-4">
                  <div className="headerButton">
                  <CSVLink
                      className=""
                     data={excelData}
                     headers={headers}
                       filename={createCsvFileName()}
                       target="_blank"
                      style={{ textDecoration: 'none', outline: 'none', height: '5vh' }}
                  >
                      <button  className="btn-md btn-add mr-2" id="exportCSV">
                      <span className="fa fa-file-export mr-2" /> Export to CSV
                      </button>
                  </CSVLink>
                  {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                    <Popup
                      open={open}
                      onClose={() => closeModal()}
                      closeOnDocumentClick={false}
                      className="claimExpensePopup"
                      trigger={
                        <button
                          className="btn-md btn-add "
                          aria-describedby="popup-8"
                          id="claimExpensePopup"
                          onClick={() => setOpen((o) => !o)}
                        >
                          <span className="fa fa-plus mr-2" />Claim Expense
                        </button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            onClick={() => {
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                          <AddExpense allExpenseType={allExpenseType} 
                          close={close}
                          getAllMainExpenses={getAllMainExpenses}
                          getAllMainExpensesCSVRecord={getAllMainExpensesCSVRecord}
                          />
                        </div>
                      )}
                    </Popup>)}
                    {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                    <Popup
                      open={open}
                      onClose={() => closeModal()}
                      className="claimMileagePopup"
                      closeOnDocumentClick={false}
                      trigger={
                        <button
                          className="ml-2 btn-md btn-add"
                          aria-describedby="popup-8"
                          id="claimMileagePopup"
                          onClick={() => setOpen((o) => !o)}
                        >
                          <span className="fa fa-plus mr-2" />Claim Mileage
                        </button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button
                            className="close"
                            id="claimMileagePopupClose"
                            onClick={() => {
                              closeModal();
                              close();
                            }}
                          >
                            &times;
                          </button>
                          <AddMileageExpense allExpenseType={allMileageType}
                          getAllMainExpenses={getAllMainExpenses}
                          close={close}
                          getAllMainExpensesCSVRecord={getAllMainExpensesCSVRecord}
                          />
                        </div>
                      )}
                    </Popup>)}
                  </div>
                </div> 
                {/* {expenseLoading ? <LoadingIndicator/> :               */}
                <div className="p-4">
                  <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                    <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full md:mb-0 mb-2 ">
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        id="expenseStartDate"
                        dateFormat="dd/MM/yyyy"
                        className="formControl"
                        locale="en-GB"
                        placeholderText="Select Date Range"
                        onChange={(update) => {
                          setDateRange(update);
                          onDateChange(update);
                        }}
                        isClearable={true}
                        />
                    </div>
                    <div className="lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 md:pr-4">
                      <select className="formControl"
                      id="expenseType"
                       onChange={(e) => handlerFilterName(e)}
                      >
                        <FormSelect 
                          optionValue={"0"}
                          optionName={"Select Expense Type"}
                        />
                        {allExpenseType?.length > 0 ? allExpenseType?.map((exp, key) => { 
                            return   <FormSelect key={key} optionValue={exp?.id} optionName={exp?.expense_name} />;
                        }) : null}
                      </select>
                    </div>
                    <div className="lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 md:pr-4">
                      <select className="formControl" id="expensStatus" onChange={(e) => handlerStatusFilter(e)}>
                        {statusData?.map((status, key) => {
                          return <FormSelect key={key} optionValue={status?.value} optionName={status.label} />;
                        })}
                      </select>
                    </div>
                    <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-4 md:pr-0 lg:pr-4">
                      <select className="formControl" id="claimType" onChange = {(e) => handlerClaimTypeFilter(e)} >
                       
                          {claimType?.map((claim, key) => { 
                              return   <FormSelect key={key} optionValue={claim?.value} optionName={claim.label} />;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th>Date of the expense</th>
                          <th>Claim Type</th>
                          <th>Type of expense</th>
                          <th>Amount Claimed</th>
                          <th>Status</th>
                          <th>Approved By</th>
                          <th>Date of Approval</th>
                          <th>Reason</th>
                          {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (<th>Action</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {expenseApiRespo?.data?.length > 0 ?
                        <>
                        {expenseApiRespo?.data?.map((expense, index) => {
                          return (
                        <tr key={index}>
                          {/* <td>
                            <Link
                              to="/employees/detail"
                              class="listImage flex items-center "
                            >
                              {expense?.employeeInfo?.first_name}
                            </Link>
                          </td> */}
                          <td>{moments(expense?.start_date).format(formattedDate)}</td>
                          <td><span className="font-medium">{expense?.expenseTypeInfo?.is_mileage === "0" ? 'Claim Expense' : 'Claim Mileage'}</span></td>
                          <td>{expense?.expenseTypeInfo?.expense_name}</td>
                          <td>£{expense?.amount}</td>
                          <td>
                          <span className={
                           expense.status === '0' ? 'blue-bedge' :
                           expense.status === '1' ? 'amberBedge' :
                           expense.status === '2' ? 'greenBedge' :
                           expense.status === '3' ? 'redBedge':
                           ''
                           }>
                           {expense.status === '0' ? 'Applied' :
                           expense.status === '1' ? 'Approved' :
                           expense.status === '2' ? 'Paid' :
                           expense.status === '3' ? 'Rejected' :
                           ''
                            }
                           </span>
                           </td>

                          <td>{expense?.approvedByInfo?.first_name}</td>
                          <td>{moments(expense?.approved_at).format(formattedDate)}</td>
                          <td>{expense?.status === "0" ? <p className="text-red-700">Approval Pending</p> : expense?.notes ? <ViewPopup text={"Reason"}  data={ expense?.notes } /> : '' } </td>
                          {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                          <td>
                          {expense?.expenseTypeInfo?.is_mileage === "0" ?
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              className="claimExpensePopup"
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn"
                                >
                                  <i className="fa fa-pencil" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    id="editExpenseclose"
                                    className="close"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <EditMyExpense expense = {expense}
                                  allExpenseType={allExpenseType}
                                  close={close}
                                  getAllMainExpenses={getAllMainExpenses}
                                  getAllMainExpensesCSVRecord={getAllMainExpensesCSVRecord}
                                  />
                                  
                                </div>
                              )}
                            </Popup>
                            :
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              className="claimMileagePopup"
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn"
                                >
                                  <i className="fa fa-pencil" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="editMileageClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <EditMyMileage expense = {expense}
                                  allExpenseType={allMileageType}
                                  close={close}
                                  getAllMainExpenses={getAllMainExpenses}
                                  getAllMainExpensesCSVRecord={getAllMainExpensesCSVRecord}
                                  />
                                  
                                </div>
                              )}
                            </Popup> }
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn cancelBtn"
                                >
                                  <i className="fa fa-trash" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="expenseDeleteClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    {" "}
                                    <h3>Deleted</h3>
                                  </div>
                                  <div className="content">
                                    <p>
                                      Are you sure you want to delete this ?
                                    </p>
                                    <div className="w-full mt-8">
                                      <div className="form-group pt-4 pb-3 flex justify-center ">
                                        <button className="btn save mr-2"  id="expenseYes" onClick={(e) => handlerDelete(e, expense?.id, close )}>
                                          Yes
                                        </button>
                                        <button
                                          type="button"
                                          id="expenseNo"
                                          onClick={() => close()}
                                          className="btn cancel "
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </td>)}
                        </tr>
                           );
                          })}
                          </> :
                          <center className="mt-4 text-red-700 ml-[260%]">No Data Found</center>}
                      </tbody>
                    </table>
                    <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
                  </div>
                </div>
                {/* } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expense;
