/* eslint-disable no-debugger*/
import { FormInput, FormSelect } from "../../components/formInput";
import { useEffect, useState, useRef} from 'react';
import { toast } from "react-toastify";
import PrimaryButton from '../../components/primaryButton';
import DatePicker from "react-datepicker";
import { useGetRolesMutation } from '../../services/reportApi';
import { useParams } from 'react-router-dom';
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import { departmentListAction } from '../../redux/DepartmentSlice/departmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import SearchLocation from "./addressComponent";
import { parseISO } from "date-fns";

const ProfileDetails = ({ employeeInfo, handelChange, setEmployeeInfo, updateEmployeeById, isProfileUpdate, file, activeEmployeeUpdatePermissions, getEmployeeDetail, myprofileReadOnly = false }) => {
  //Selector
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const userRoleId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  const isDisabled = userRoleId === 4 || userRoleId === 3;
  //Api Calling
  const [ getDepartmentList, {data:departmentApiResponse, isSuccess: isSuccessDepartmentApi} ] = useGetDepartmentListMutation();

  //States and Hooks
  const toastId = useRef();
  const ignore = useRef(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [getRoles, { data: roles }] = useGetRolesMutation();
  const [dobDate, setDobDate] = useState();
  const [joiningDate, setJoiningDate] = useState();
  const [genderError, setGenderError] = useState('');
  const genderData = [
    { value: "", name: "Select Gender" },
    { value: 1, name: "Male" },
    { value: 2, name: "Female" },
    { value: 3, name: "Other" }
  ];

  useEffect(() => {
    if(isSuccessDepartmentApi){
      dispatch(departmentListAction(departmentApiResponse));
    }
   },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccessDepartmentApi ]);

  useEffect(() => {
    if (!ignore?.current){
      getRoles();
      getDepartmentList();
    }
    return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
  []);


  useEffect(() => {
    if (isProfileUpdate) {
      toast.dismiss(toastId.current);
      toast.success("Employee Updated Successfully.",
        {
          render: "Employee Updated Successfully.",
          // position: "top-center",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        }
      );
    }

  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[isProfileUpdate]);

  const handleChangeAddress = (e) => {
    e.preventDefault();
    setEmployeeInfo({ ...employeeInfo, ['address']: e.target.value });
  }; 

  const handleDate = (e, name) => {
    // debugger;
    setEmployeeInfo({ ...employeeInfo, [name]: e });
    setDobDate(e);
  };

  const handleJoiningDate = (e, name) => {
    // debugger;
    setEmployeeInfo({ ...employeeInfo, [name]: e });
    setJoiningDate(e);
  };

  const handelUpdateEmployee = () => {
    if (employeeInfo?.gender === 0) {
      setGenderError('Please select a gender.');
      return; // Prevent form submission
    }

    // Reset gender error if gender is selected
    setGenderError('');

    var formdata = new FormData();
    formdata.append("first_name", employeeInfo?.first_name);
    formdata.append("last_name", employeeInfo?.last_name);
    formdata.append("dob", employeeInfo?.dob);
    formdata.append("employment_start_date", employeeInfo?.employment_start_date);
    formdata.append("gender", employeeInfo.gender);
    formdata.append("email", employeeInfo?.email);
    formdata.append("mobile_no", employeeInfo?.mobile_no);
  const departmentId = employeeInfo?.department_id !== null && employeeInfo?.department_id !== undefined
    ? employeeInfo?.department_id
    : '';

  formdata.append("department_id", departmentId);
    formdata.append("designation", employeeInfo?.designation);
    formdata.append("m_roles_id", employeeInfo?.m_roles_id);
    formdata.append("address", employeeInfo?.address);
    formdata.append("address2", employeeInfo?.address2);
    formdata.append("postal_code", employeeInfo?.postal_code ? employeeInfo?.postal_code : employeeInfo?.postalCode);
    formdata.append("city", employeeInfo?.city);
    formdata.append("country", employeeInfo?.country);

    if (file) {
      formdata.append("profile_image", file ? file : employeeInfo?.profile_image);
    }

    updateEmployeeById({ id: employeeInfo?.id, formData: formdata });
    getEmployeeDetail(id);
  };

  return (
    <>
      <div className="p-7">
        <>
          <div className="flex flex-wrap ">
            <div className=" md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">First Name</label>
                <FormInput
                  inputType="text"
                  inputName="first_name"
                  value={employeeInfo?.first_name}
                  defaultValue={employeeInfo?.first_name}
                  inputID="FirstName"
                  handlerChange={handelChange}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className=" md:w-1/2 md:pr-0 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Last Name</label>
                <FormInput
                  inputType="text"
                  inputName="last_name"
                  inputID="LastName"
                  value={employeeInfo?.last_name}
                  defaultValue={employeeInfo?.last_name}
                  handlerChange={handelChange}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Date of Birth</label>
                <DatePicker
                  name="dob"
                  id="dob"
                  dateFormat="dd/MM/yyyy"
                  selected={dobDate ? dobDate : employeeInfo?.dob}
                  onChange={(date) => handleDate(date, "dob")}
                  placeholderText={localStorage.getItem('format')}
                  className="formControl"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-0  w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Gender</label>
                <select
                  name="gender"
                  id="gender"
                  value={employeeInfo?.gender}
                  onChange={handelChange}
                  defaultValue={employeeInfo?.gender}
                  className="formControl"
                >
                  {genderData?.map((gender, index) => {
                    return (
                      <FormSelect
                        key={index}
                        optionValue={gender?.value}
                        optionName={gender?.name}
                      />
                    );
                  })}
                </select>
                {genderError && <div className="text-red-500 text-sm">{genderError}</div>}
                <div className="help-block" />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Joining Date</label>{
                }
                <DatePicker
                  name="employment_start_date"
                  id="employment_start_date"
                  dateFormat="dd/MM/yyyy"
                  selected={joiningDate ? joiningDate : employeeInfo?.employment_start_date ? parseISO(employeeInfo?.employment_start_date) : new Date() }
                  onChange={(date) => handleJoiningDate(date, "employment_start_date")}
                  placeholderText={localStorage.getItem('format')}
                  className="formControl"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  disabled={isDisabled}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Email</label>
                <FormInput
                  inputType="text"
                  inputName="email"
                  inputID="Email"
                  value={employeeInfo?.email}
                  handlerChange={handelChange}
                  disableVal={myprofileReadOnly}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Mobile Number</label>
                <FormInput
                  inputType="text"
                  inputName=" Mobile Number"
                  inputID="MobileNumber"
                  value={employeeInfo?.mobile_no}
                  handlerChange={handelChange}
                />
                <div className="help-block" />
              </div>
            </div>

            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Department</label>
                <select
                  id="department"
                  value={employeeInfo?.department_id}
                  onChange={handelChange}
                  name="department_id"
                  className="formControl"
                  disabled={myprofileReadOnly}
                >
                  <FormSelect
                    optionValue={"0"}
                    optionName={"Select Department"}
                  />
                  ;
                  {departmentApiData && departmentApiData?.data?.length > 0
                    ? departmentApiData?.data?.map((departments, key) => {
                      return (
                        <FormSelect
                          key={key}
                          optionValue={departments?.id}
                          optionName={departments?.department_name}
                        />
                      );
                    })
                    : null}
                </select>
                <div className="help-block" />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Role</label>
                <select
                  id="role"
                  value={employeeInfo?.m_roles_id}
                  onChange={handelChange}
                  className="formControl"
                  defaultValue={employeeInfo?.m_roles_id}
                  name='m_roles_id'
                  disabled={myprofileReadOnly}
                >
                  {roles?.roles?.map((role, index) => {
                    return (
                      <FormSelect
                        key={index}
                        optionValue={role?.id} optionName={role?.role_name}
                      />
                    );
                  })}
                </select>
                <div className="help-block" />
              </div>
            </div>

            <div className="lg:w-1/2 lg:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	"> Address</label>
                <SearchLocation
                  handleChangeAddress={handleChangeAddress}
                  setEmployeeInfo={setEmployeeInfo}
                  employeeInfo={employeeInfo}
                />
              </div>
            </div>
            
            <div className="lg:w-1/2 lg:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">City/Town</label>
                <FormInput
                  inputType="text"
                  inputName="city"
                  inputID="City"
                  value={employeeInfo?.city == "undefined" || employeeInfo?.city === "null" || employeeInfo?.city === null || employeeInfo?.city === "" ? "" : employeeInfo?.city}
                  handlerChange={handelChange}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="lg:w-1/2 lg:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Postcode</label>
                <FormInput
                  inputType="text"
                  inputName="postal_code"
                  inputID="postal_code"
                  value={employeeInfo?.postal_code ? employeeInfo?.postal_code : employeeInfo?.postalCode}
                  handlerChange={handelChange}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="lg:w-1/2 lg:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Country</label>
                <FormInput
                  inputType="text"
                  inputName="country"
                  inputID="country"
                  value={employeeInfo?.country === "undefined" || employeeInfo?.country === "null" || employeeInfo?.country === null || employeeInfo?.country === "" ? "" : employeeInfo?.country}
                  handlerChange={handelChange}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="lg:w-1/2 lg:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Job Title</label>
                <FormInput
                  inputType="text"
                  inputName="designation"
                  inputID="designation"
                  value={employeeInfo?.designation}
                  handlerChange={handelChange}
                  disableVal={myprofileReadOnly}
                />
                <div className="help-block" />
              </div>
            </div>
            {((employeeInfo.status === "1" && activeEmployeeUpdatePermissions[0]?.status === true) || myprofileReadOnly) ?
              <div className="lg:w w-full ">
                <div className="w-full mt-4">
                  <div className="form-group pt-6 pb-3 flex border-t  ">
                    <PrimaryButton btnText="Save" buttonID="profileSave" onClick={handelUpdateEmployee} Btnclass="btn save mr-2" />
                    <PrimaryButton btnText="Cancel" buttonID="profileCancel"   Btnclass="btn cancel " />
                  </div>
                </div>
              </div> : null}
          </div>
        </>
      </div>
    </>
  );
};

export default ProfileDetails;
