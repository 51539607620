/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-debugger*/
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';


export const authApi = createApi({
  reducerPath: "authAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),
  endpoints: (builder) => ({
    signupUser: builder.mutation({
      query:({firstName, lastName, email, mobileNo, password, role, countryCode, otp, otpId, companyName, hearAboutUs
      }) => {
        return {
          url: `/auth/register`,
          method: "post",
          body: {
              mobile_no: mobileNo,
              email : email,
              role : role,
              first_name : firstName,
              last_name : lastName,
              country_code : countryCode,
              name: companyName,
              password : password,
              otp : otp,
              otpid: `${otpId}`,
              heardFromWhere: hearAboutUs
        }
      };
    }
    }),
    loginUser: builder.mutation({
      query : ({formData, fcmToken}) => {
        return {
          url: `/auth/login`,
          method: "post",
          body: {
            email:formData?.email,
            password:formData?.password,
            remember_me:formData?.remember_me,
            fcm_token: fcmToken,
            "device_type": "web" 
          }
        };
      }
    }),
    verifyUser: builder.mutation({
      query : ({email, mobileNo, companyName}) => {
        return {
          url: `/auth/filterUser?company_name=${companyName}&email=${email}&mobile_no=${mobileNo}`,
          method: "GET"
        };
      }
    }),
    forgotPassword: builder.mutation({
      query : (email) => {
        return {
          url: `/auth/forget-Password`,
          method: 'POST',
          body: { "email": email }
        };
      }
    }),
    resetPassword: builder.mutation({
      query : ({ formData, passwords }) => {
        return {
          url: "/auth/reset-password",
          method: 'POST',
          body:{ 
            email: formData.email,
            token: formData.token,
            password: passwords
          }
        };
      }
    }),
    changePassword: builder.mutation({
      query : (payload) => {
        return {
          url: "/auth/change/password",
          method: 'POST',
          body:payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          }
        };
      }
    }),
    getPlan: builder.mutation({
      query : () => {
        return {
          url: `/subscription/getPlans`,
          method: "GET",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          }
        };
      }
    }),
    logoutUser: builder.mutation({
      query : () => {
        return {
          url: `/auth/logout`,
          method: "GET",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          }
        };
      }
    }),
    createSubscription: builder.mutation({
      query : ({firstName, email, planId, paymentMethodId, priceId}) => {
        return {
          url: `/subscription/create-subscription`,
          method: "Post",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          },
          body:{ 
            "first_name": firstName,
            "email": email,
            "plan_id": planId,
             "paymentMethodId": paymentMethodId,
             "priceId": priceId
          }
        };
      }
    }),
    updateTrialPlanDay: builder.mutation({
      query : (payload) => {
        return {
          url: `/subscription/change-trial-plan-days`,
          method: "Post",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          },
          body: payload
        };
      }
    }),
    createCardSubscription: builder.mutation({
      query : ({planId, paymentMethodId}) => {
        return {
          url: `/subscription/create-card-payment-intent`,
          method: "Post",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          },
          body:{ 
            "paymentMethod":paymentMethodId,
            "plan_id": planId
            
          }
        };
      }
    }),
    sendPayment: builder.mutation({
      query : ({companyId, sessionId}) => {
        return {
          url: `/subscription/save-subscription-data`,
          method: "Post",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          },
          body:{ 
            "company_id": companyId,
            "session_id": sessionId
          }
        };
      }
    }),
    sendOtp: builder.mutation({
      query : (payload) => {
        return {
          url: `/otp`,
          method: "post",
          body: payload
        };
      }
    }),
    addCard: builder.mutation({
      query : (payload) => {
        return {
          url: `/subscription/add-payment-method`,
          method: "post",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          },
          body: payload
        };
      }
    }),
    removeCard: builder.mutation({
      query : (payload) => {
        return {
          url: `/subscription/remove-payment-method`,
          method: "post",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          },
          body: payload
        };
      }
    }),
    cancelSubscription: builder.mutation({
      query : (payload) => {
        return {
          url: `/subscription/cancel-subscription`,
          method: "POST",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          },
          body: payload
        };
      }
    }),
    getCard: builder.mutation({
      query : () => {
        return {
          url: `/subscription/getSavedCards`,
          method: "get",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          }
        
        };
      }
    }),
    getInvoice: builder.mutation({
      query : () => {
        return {
          url: `/subscription/get-customer-invoices`,
          method: "get",
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: localStorage.getItem('accessToken')
          }
        
        };
      }
    })
  
  })
});



export const { 
  useSignupUserMutation,
  useLoginUserMutation,
  useSendOtpMutation,
  useForgotPasswordMutation,
  useVerifyUserMutation,
  useResetPasswordMutation,
  useGetPlanMutation,
  useCreateSubscriptionMutation,
  useUpdateTrialPlanDayMutation,
  useSendPaymentMutation,
  useAddCardMutation,
  useGetCardMutation,
  useCreateCardSubscriptionMutation,
  useGetInvoiceMutation,
  useRemoveCardMutation,
  useCancelSubscriptionMutation,
  useLogoutUserMutation,
  useChangePasswordMutation
 } = authApi;
