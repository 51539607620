/* eslint-disable no-debugger */

/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {  useEffect, useState, useRef } from "react";
import Popup from "reactjs-popup";
import {  toast } from "react-toastify";
import { FormInput, FormSelect } from "../../components/formInput";
import PrimaryButton from "../../components/primaryButton";
import { useGetAllDeptMutation, useCreateDeptMutation,  useUpdateDeptMutation, useDeleteDeptMutation, useGetAllManagerListMutation} from "../../services/deptApi";
import { useDispatch, useSelector } from "react-redux";
import { useGetDepartmentManagerMutation } from '../../services/employeeApi';
import Pagination from "../../components/inc/pagination";
import {useSearchParams}from 'react-router-dom';


const Department =({departmentsUpdatePermissions, departmentsCreatePermissions}) => {
  const ignore = useRef(false);
  const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const [ getAllDepartment, {data: departmentData, isSuccess: departmentIsSuccess} ] = useGetAllDeptMutation();
  const [ createDept, {isSuccess, isError, isLoading, error: apiError} ] = useCreateDeptMutation();
  const [ updateDept, {isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate, isError: isErrorUpdate, error:apiErrorUpdate} ] =  useUpdateDeptMutation();
  const [ deleteDept, {isSuccess: isSuccessDelete, isLoading: isLoadingDelete, isError: isErrorDelete, error:apiErrorDelete} ] = useDeleteDeptMutation();
  const [ getDepartmentManager, {data: departmentManagerList} ] = useGetDepartmentManagerMutation();
  const [ open, setOpen ] = useState(false);
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);
  const dispatch = useDispatch();
  const toastId = useRef(null); 
  const statusData = [  
  {value :"1", label: "Active"},
  {value :"2", label: "InActive"}
  ];

  useEffect(() => {
    getAllDepartment(page);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ] );

  useEffect(() => {
    if (!ignore.current){
    getDepartmentManager();
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
  useEffect(() => {
   if (departmentIsSuccess) {
    if(departmentData?.data?.length < 1 && departmentData?.currentPage >1 ) {
      getAllDepartment(departmentData?.currentPage - 1);
    }
    setPages(departmentData?.totalPages ? departmentData?.totalPages : 1);
   } 
   
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ departmentIsSuccess ]);
 
const decrementPage = () => {
  getAllDepartment(departmentData?.currentPage - 1 );
  setPage(departmentData?.currentPage - 1);
};
  
  
  const [ formData, setFormData ] = useState({
    department_name : "",
    department_manager: "",
    status: "1",
    department_off_at_once: "",
    department_color_code: "",
    id: ""
  });
  const [ error, setError ] = useState({
    departmentNameError : "",
    departmentManagerError: "",
    statusError: "",
    departmentOffAtOnceError: ""
  });


 
 
 
  const closeModal = () => {
    setOpen(false);
    // window.location.reload();
    setFormData({
      department_name : "",
      department_manager: "",
      status: "",
      department_off_at_once: "",
      department_color_code: "",
      id: ""
    });
  };
  const handleChange = (e) => {
    const selectedValue = e.target.value;
  
    setFormData((prevData) => ({
      ...prevData,
      department_manager: selectedValue === '0' ? '' : selectedValue
    }));
  };
  
  
  
  
  const handlerChange = (e) => {
    e.preventDefault();
    const {name} = e.target;
    if(name === 'department_name') {
      if(name === '') {
      setError({...error, ['departmentNameError']: 'Please enter the department name'});
      } else {
        setError({...error, ['departmentNameError']: ''});
      }
    } 
    // else if(name === ' department_manager') {
    //   if (e.target.value === '0') {
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       department_manager:""
    //     }));
    //   }
    // } 
    else if(name === 'department_off_at_once') {
      if(name === '') {
      setError({...error, ['departmentOffAtOnceError']: 'Please enter the details'});
      } else 
      {
        setError({...error, ['departmentOffAtOnceError']: ''});
      }
    } else if(name === 'department_color_code') {
      if(name === '') {
      setError((errorValue) => {
        return {
          ...errorValue,
          departmentColorCodeError :  'please enter the color code '
        };
    });
    } else {
      setError((errorValue) => {
        return {
          ...errorValue,
          departmentColorCodeError :  ' '
        };
    });
    }
  }
  if(name === 'status') {
    if(name === '0') {
    setError({...error, ['statusError']: 'Please select Status'});
    } else {
      setError({...error, ['statusError']: ''});
    }
  } 

   
    setFormData((formValue) => { 
      return {
        ...formValue,
        [name] :e.target.value
      };
    });
  };
  
  const handlerChangeManager = (e) => {
    setFormData((formValue) => { 
      return {
        ...formValue,
        ['department_manager'] :e.target.value
      };
    });
  };

   const validaForm = () => {
    const { department_name, department_manager,
    status, department_off_at_once } = formData;
    let isFormValidated = true;
    if(department_name === '') {
      setError((errorValue) => {
        return {
          errorValue,
          departmentNameError : 'Please enter the department name '
        };
    });
    isFormValidated = false;
    } 
    
   
    if(department_off_at_once === '') {
      setError((errorValue) => {
        return {
          ...errorValue,
          departmentOffAtOnceError : 'please select no of employee'
        };
    });
    isFormValidated = false;
    } 
    //  if(department_color_code === '') {
    //   setError((errorValue) => {
    //     return {
    //       ...errorValue,
    //       departmentColorCodeError :  'please enter the color code '
    //     };
    // });
    // isFormValidated = false;
    // } 
    // if(status === '0') {
    //   setError((errorValue) => {
    //     return {
    //       ...errorValue,
    //      statusError : 'Please select Status '
    //     };
    // });
    // isFormValidated = false;
    // } 
    return isFormValidated;
  };
  
  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      autoClose: 2000,
      theme: "colored",
      type: "info"
    });
    }
    getAllDepartment(page);
    if (isSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Department created successfully!", {
        render: "Department created successfully.",
             type: "success",
             autoClose: 2000,
             theme: "colored",
           isLoading: false
      });

          }
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage =
        apiError.data.message
          ? apiError.data.message
          :
        "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );

    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);

  useEffect(() => {
    if (isLoadingUpdate) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccessUpdate) {
      toast.dismiss(toastId.current);
      toast.success("Department type updated Successfully!.", {
        render: "Department type updated Successfully!",
             type: "success",
             autoClose: 2000,
           isLoading: false,
           theme: "colored"
      });
      getAllDepartment(page);
    }
    if (isErrorUpdate) {
      toast.dismiss(toastId.current);
      const toastMessage = apiErrorUpdate?.data?.message
        ? apiErrorUpdate?.data?.message
        : "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessUpdate, isErrorUpdate, isLoadingUpdate ]);
  
  const handleFormSubmit = async(e, edit=false, close, dept) => {
    e.preventDefault();

    if(formData.department_manager !== '') {
      if(edit) {
        const value = {
          "department_name" : formData.department_name ? formData.department_name : dept.department_name,
          "department_manager": formData.department_manager ? formData.department_manager : dept.department_manager,
          "status": formData.status ? formData.status : dept.status,
          "department_off_at_once": formData.department_off_at_once ? formData.department_off_at_once : dept.department_off_at_once,
          "department_color_code": formData.department_color_code ? formData.department_color_code : dept.department_color_code,
          "id": dept?.id
        };
        updateDept(value);
        close();
       } else {
         if(validaForm()) {
          const value = {
            "department_name" : formData.department_name,
            status:formData.status ? formData.status === '2' ? '0': '1' : '1',
            "department_off_at_once": formData.department_off_at_once,
            "department_color_code": formData.department_color_code,
            "department_manager": formData.department_manager === '0' ? '' : formData.department_manager
          };
          createDept(value);
        close();
       }
     }
    } else {
      if(edit) {
        const value = {
          "department_name" : formData.department_name ? formData.department_name : dept.department_name,
        
          "status": formData.status ? formData.status : dept.status,
          "department_off_at_once": formData.department_off_at_once ? formData.department_off_at_once : dept.department_off_at_once,
          "department_color_code": formData.department_color_code ? formData.department_color_code : dept.department_color_code,
          "id": dept?.id
        };
        updateDept(value);
        close();
       } else {
         if(validaForm()) {
          const value = {
            "department_name" : formData.department_name,
            status:formData.status ? formData.status === '2' ? '0': '1' : '1',
            "department_off_at_once": formData.department_off_at_once,
            "department_color_code": formData.department_color_code
            
          };
          createDept(value);
        close();
       }
     }
    }
      
  };
 
  useEffect(() => {
    if(isLoadingDelete) {
      toastId.current = toast.loading("Please wait...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccessDelete) {
      toast.dismiss(toastId.current);
      toast.success("Department deleted successfully.", {
        render: "Department deleted successfully.",
             type: "success",
             autoClose: 2000,
           isLoading: false,
           theme: "colored"
      });
      getAllDepartment(page);
    }
    if (isErrorDelete) {
      toast.dismiss(toastId.current);
      const toastMessage = apiError?.data?.message
        ? apiError?.data?.message
        : "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 2000,
                 duplicate: false,
                 isLoading: false
          });
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isErrorDelete, isSuccessDelete, isLoadingDelete ]);
  
  
  
  const handlerDelete = (e, id, close) => {
    e.preventDefault();
    deleteDept(id);
    close();
  };
 
  return (
    <>
    <div className="heading_title px-4 flex justify-between items-center py-4">
      <div>
        <h3 className=" font-semibold text-lg">Departments</h3>
      </div>
     {departmentsCreatePermissions [0]?.status === true &&
      <Popup
        open={open}
        onClose={() => closeModal()}
        closeOnDocumentClick={false}
        trigger={
          <button
            className="btn-md btn-add"
            id="addDepartments"
            onClick={() => setOpen((o) => !o)}
          >
            <span className="fa fa-plus mr-2"/>Add Departments
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <button
              className="close"
              id="addDepartmentsClose"
              onClick={() => {
                closeModal();
               close(); 
              }}
            >
              &times;
            </button>
            <div className="header">
              {" "}
              <h3>Add Department</h3>
            </div>
            <div className="content">
            <form onSubmit={(e) => handleFormSubmit(e)}>
                  <div className="flex flex-wrap ">
                     <div className="w-full md:w-1/2 md:pr-6">
                       <div className="form-group mb-6">
                         <label className="formBlock	">
                          Department Name{" "}
                           <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                         </label>
                         <FormInput
                           inputType="text"
                           inputName="department_name"
                           inputID="Name"
                          //  value = {formData.department_name }
                           handlerChange={handlerChange}
                        />
                       <div className="help-block text-red-700">{error.departmentNameError ? error.departmentNameError:''}</div>
                       </div>
                     </div>
                     <div className="w-full md:w-1/2 pr-0">
                       <div className="form-group mb-6 ">
                         <label className="formBlock	">
                           Department Manager(Optional)
                         </label>
                         <select className="formControl" id="addDepartmentsManager" name ={formData?.department_manager} onClick={(e) => handlerChangeManager(e)}>
                            <FormSelect 
                            optionValue={"0"}
                            optionName={"Select Department Manager"} />;
                        
                           {departmentManagerList && departmentManagerList?.data?.map((manager, index) => { 
                              return  <FormSelect key={index} 
                              optionValue={manager?.id} 
                                optionName={manager.first_name} />;
                            })};
                         </select>

                         <div className="help-block text-red-700">{error.departmentManagerError ? error.departmentManagerError:''}</div>
                       </div>
                     </div>
                     <div className="w-full md:w-1/2 md:pr-6">
                       <div className="form-group mb-6">
                        <label className="formBlock	">
                          Maximum employees off at one time
                           <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                         </label>
                         <FormInput
                           inputType="number"
                           inputName="department_off_at_once"
                           inputID="MaximumEmployees"
                          //  value = {formData.department_off_at_once}
                           handlerChange={handlerChange}
                        />
                        <div className="help-block text-red-700">{error.departmentOffAtOnceError ? error.departmentOffAtOnceError:''}</div>
                       </div>
                     </div>
                     {/* <div className="w-full md:w-1/2 pr-0">
                       <div className="form-group mb-6">
                         <label className="formBlock	">
                           Color{" "}
                           <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                         </label>
                         <FormInput
                           inputType="color"
                           inputName="department_color_code"
                           inputID="Color"
                           value ={formData.department_color_code}
                           handlerChange={handlerChange}
                         /> 
                         <div className="help-block text-red-700">{error.departmentColorCodeError ? error.departmentColorCodeError:''}</div>
                       </div>
                     </div> */}
                     <div className="w-full md:w-1/2 pr-0">
                       <div className="form-group mb-6 ">
                         <label className="formBlock	">
                           Status{" "}
                         <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                         </label>
                         <select className="formControl" id="addDepartmentStatus" value={formData.status} label = "status" name="status" onChange={(e) => handlerChange( e)} >
                           {/* <option value="1">Active</option>
                          <option value="2">InActive</option> */}
                          {statusData?.map((status, index) => { 
                              return  <FormSelect key={index} 
                              optionValue={status.value} 
                                optionName={status.label} />;
                            })};
                        </select>

                        <div className="help-block text-red-700">{error.statusError ? error.statusError:''}</div>
                       </div>
                     </div>
                     <div className="w-full mt-4">
                       <div className="form-group pt-4 pb-3 flex justify-center ">
                       <PrimaryButton Btnclass="btn save mr-2" btnText="Save" buttonID="addDepartmentSave"  onClick={(e) => handleFormSubmit(e, false, close, '')}/>
                       <PrimaryButton Btnclass="btn cancel mr-2" btnText="Cancel" buttonID="addDepartmentCancel"   onClick={() => close()} />
                      </div>
                    </div>
                  </div>
                </form>
           </div>
          </div>
        )}
      </Popup>}
    </div>
    <div className="p-4">
      <div className="overflow-x-auto w-full whitespace-nowrap">
        <table className="table-auto table  w-full">
          <thead className="thead-dark ">
            <tr>
              <th className="w-40">Department</th>
              <th>Manager Name</th>
              <th className="text-center">
                Maximum employees<br /> off at once <br />{" "}
              </th>
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {departmentData && departmentData?.data && departmentData?.data.map((dept, index) => (
            <tr key={index}>
              <td>{dept.department_name}</td>
              <td>{dept?.departManagerInfo?.first_name}</td>
              <td className="text-center">{dept.department_off_at_once}</td>
              <td>{dept?.company_id !== null ? 'Custom' : 'Default' }</td>
              <td>{dept?.status === '1' ? "Active" : "InActive"}</td>
               {roleIdData === 1 ? (
                <td key ={index}>
                  <Popup
                open={open}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpen((o) => !o)}
                    className="actionBtn"
                  >
                    <i className="fa fa-eye" />
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      id="departmentDetailClose"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <DepartmentDetails departmentDetails={dept} />
          
                  </div>
                )}
                      </Popup>
                {departmentsUpdatePermissions[0]?.status === true ? 
              <Popup
                open={openEdit}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpen((o) => !o)}
                    className="actionBtn"
                  >
                    <i className="fa fa-edit"/>
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      id="editDepartmentClose"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <div className="header">
                      {" "}
                      <h3>Edit Department</h3>
                    </div>
                    <div className="content">
                    <form onSubmit={(e) => handleFormSubmit(e, true, close, dept)}>
                <div className="flex flex-wrap ">
                   <div className="w-full md:w-1/2 md:pr-6">
                     <div className="form-group mb-6">
                       <label className="formBlock	">
                        Department Name{" "}
                         <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                       </label>
                       <FormInput
                         inputType="text"
                         inputName="department_name"
                         inputID="Name"
                          value = {formData.department_name ? formData.department_name : dept.department_name }
                         handlerChange={handlerChange}
                      />
                     <div className="help-block">{error.departmentNameError ? error.departmentNameError:''}</div>
                     </div>
                   </div>
                   <div className="w-full md:w-1/2 pr-0">
                     <div className="form-group mb-6 ">
                       <label className="formBlock	">
                         Department Manager(Optional)
                       </label>
                       <select className="formControl" id="editDepartmentManager" value={dept.department_manager} name="department_manager" onChange={(e) => handleChange(e)}>
                       <FormSelect 
                          optionValue={"0"}
                          optionName={"Select"} />;
                      
                         {departmentManagerList && departmentManagerList.data?.map((manager, index) => { 
                            return  <FormSelect key={index} 
                            optionValue={manager?.id} 
                              optionName={manager.first_name} />;
                          })};
                       </select>

                       <div className="help-block">{error.departmentManagerError ? error.departmentManagerError:''}</div>
                     </div>
                   </div>
                   <div className="w-full md:w-1/2 md:pr-6">
                     <div className="form-group mb-6">
                      <label className="formBlock	">
                        Maximum employees off at one time
                         <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                       </label>
                       <FormInput
                         inputType="text"
                         inputName="department_off_at_once"
                         inputID="MaximumEmployees"
                          value = {dept.department_off_at_once}
                         handlerChange={handlerChange}
                      />
                      <div className="help-block">{error.departmentOffAtOnceError ? error.departmentOffAtOnceError:''}</div>
                     </div>
                   </div>
                   {/* <div className="w-full md:w-1/2 pr-0">
                     <div className="form-group mb-6">
                       <label className="formBlock	">
                         Color{" "}
                         <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                       </label>
                       <FormInput
                         inputType="color"
                         inputName="department_color_code"
                         inputID="Color"
                         value ={dept.department_color_code}
                         handlerChange={handlerChange}
                       /> 
                       <div className="help-block">{error.departmentColorCodeError ? error.departmentColorCodeError:''}</div>
                     </div>
                   </div> */}
                   <div className="w-full md:w-1/2 md:pr-6">
                   <div className="form-group mb-6 ">
                        <label className="formBlock	">Status </label>
                        <select className="formControl" name = "status" id="editDepartmentStatus" defaultValue ={dept.status} selected={dept.status} onChange ={(e) => handlerChange(e)}>
                          {/* <FormSelect optionValue="Active" />
                          <FormSelect optionValue="Inactive" /> */}
                         
                          <option value="0" selected={dept.status === 0 ? "selected" : ""} onChange={(e) => handlerChange(e)}>Inactive</option>
                          <option value="1" selected={dept.status === 1  ? "selected" : ""} onChange={(e) => handlerChange(e)}>Active</option>
                        </select>
                        <div className="help-block" />
                      </div>
                   </div>

                   <div className="w-full mt-4">
                     <div className="form-group pt-4 pb-3 flex justify-center ">
                     <PrimaryButton Btnclass="btn save mr-2" btnText="Update" buttonID="editDepartmentUpdate"  onClick={(e) => handleFormSubmit(e, true, close, dept)}/>
                     <PrimaryButton Btnclass="btn cancel mr-2" btnText="Cancel"  buttonID="editDepartmentCancel"  onClick={() => close()} />
                    </div>
                  </div>
                </div>
              </form>
                    </div>
                  </div>
                )}
              </Popup> : <button className="actionBtn disabled"><i className="fa fa-pencil" /></button> }
              {departmentsUpdatePermissions[0]?.status === true ?
              <Popup
                open={openDelete}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpen((o) => !o)}
                    className="actionBtn cancelBtn"
                  >
                    <i className="fa fa-trash"/>
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      id="deleteDepartmentClose"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <div className="header">
                      {" "}
                      <h3>Delete</h3>
                    </div>
                    <div className="content">
                      <p>Are you sure you want to delete {dept.department_name} Departments?</p>
                      <div className="w-full mt-8">
                        <div className="form-group pt-4 pb-3 flex justify-center ">
                          <button className="btn save mr-2" id="deleteDepartmentYes" onClick={(e) => handlerDelete(e, dept.id, close)}>Yes</button>
                          <button
                            type="button"
                            onClick={() => close()}
                            id="deleteDepartmentNo"
                            className="btn cancel  "
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup> :  <button className="actionBtn disabled"><i  className="fa fa-trash" /></button> }
            </td>
               ) : dept?.company_id !== null ? <>
               
                <td key ={index}>
                  {departmentsUpdatePermissions[0]?.status === true ? 
                <Popup
                  open={openEdit}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn"
                    >
                      <i className="fa fa-edit"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="editDepartmentClose"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Edit Department</h3>
                      </div>
                      <div className="content">
                      <form onSubmit={(e) => handleFormSubmit(e, true, close, dept)}>
                  <div className="flex flex-wrap ">
                     <div className="w-full md:w-1/2 md:pr-6">
                       <div className="form-group mb-6">
                         <label className="formBlock	">
                          Department Name{" "}
                           <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                         </label>
                         <FormInput
                           inputType="text"
                           inputName="department_name"
                           inputID="Name"
                            value = {formData.department_name ? formData.department_name : dept.department_name }
                           handlerChange={handlerChange}
                        />
                       <div className="help-block">{error.departmentNameError ? error.departmentNameError:''}</div>
                       </div>
                     </div>
                     <div className="w-full md:w-1/2 pr-0">
                       <div className="form-group mb-6 ">
                         <label className="formBlock	">
                           Department Manager(Optional)
                         </label>
                         <select className="formControl" id="editDepartmentManager" value={dept.department_manager} name="department_manager" onChange={(e) => handleChange(e)}>
                         <FormSelect 
                            optionValue={"0"}
                            optionName={"Select"} />;
                        
                           {departmentManagerList && departmentManagerList.data?.map((manager, index) => { 
                              return  <FormSelect key={index} 
                              optionValue={manager?.id} 
                                optionName={manager.first_name} />;
                            })};
                         </select>

                         <div className="help-block">{error.departmentManagerError ? error.departmentManagerError:''}</div>
                       </div>
                     </div>
                     <div className="w-full md:w-1/2 md:pr-6">
                       <div className="form-group mb-6">
                        <label className="formBlock	">
                          Maximum employees off at one time
                           <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                         </label>
                         <FormInput
                           inputType="text"
                           inputName="department_off_at_once"
                           inputID="MaximumEmployees"
                            value = {dept.department_off_at_once}
                           handlerChange={handlerChange}
                        />
                        <div className="help-block">{error.departmentOffAtOnceError ? error.departmentOffAtOnceError:''}</div>
                       </div>
                     </div>
                     {/* <div className="w-full md:w-1/2 pr-0">
                       <div className="form-group mb-6">
                         <label className="formBlock	">
                           Color{" "}
                           <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                         </label>
                         <FormInput
                           inputType="color"
                           inputName="department_color_code"
                           inputID="Color"
                           value ={dept.department_color_code}
                           handlerChange={handlerChange}
                         /> 
                         <div className="help-block">{error.departmentColorCodeError ? error.departmentColorCodeError:''}</div>
                       </div>
                     </div> */}
                     <div className="w-full md:w-1/2 pr-0">
                     <div className="form-group mb-6 ">
                          <label className="formBlock	">Status </label>
                          <select className="formControl" name = "status" id="editDepartmentStatus" defaultValue ={dept.status} selected={dept.status} onChange ={(e) => handlerChange(e)}>
                            {/* <FormSelect optionValue="Active" />
                            <FormSelect optionValue="Inactive" /> */}
                           
                            <option value="0" selected={dept.status === 0 ? "selected" : ""} onChange={(e) => handlerChange(e)}>Inactive</option>
                            <option value="1" selected={dept.status === 1  ? "selected" : ""} onChange={(e) => handlerChange(e)}>Active</option>
                          </select>
                          <div className="help-block" />
                        </div>
                     </div>

                     <div className="w-full mt-4">
                       <div className="form-group pt-4 pb-3 flex justify-center ">
                       <PrimaryButton Btnclass="btn save mr-2" btnText="Update" buttonID="editDepartmentUpdate"  onClick={(e) => handleFormSubmit(e, true, close, dept)}/>
                       <PrimaryButton Btnclass="btn cancel mr-2" btnText="Cancel"  buttonID="editDepartmentCancel"  onClick={() => close()} />
                      </div>
                    </div>
                  </div>
                </form>
                      </div>
                    </div>
                  )}
                </Popup> : <button className="actionBtn disabled"><i className="fa fa-pencil" /></button> }
                {departmentsUpdatePermissions[0]?.status === true ?
                <Popup
                  open={openDelete}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  trigger={
                    <div
                      onClick={() => setOpen((o) => !o)}
                      className="actionBtn cancelBtn"
                    >
                      <i className="fa fa-trash"/>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="deleteDepartmentClose"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div className="header">
                        {" "}
                        <h3>Delete</h3>
                      </div>
                      <div className="content">
                        <p>Are you sure you want to delete {dept.department_name} Departments?</p>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" id="deleteDepartmentYes" onClick={(e) => handlerDelete(e, dept.id, close)}>Yes</button>
                            <button
                              type="button"
                              onClick={() => close()}
                              id="deleteDepartmentNo"
                              className="btn cancel  "
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup> :  <button className="actionBtn disabled"><i  className="fa fa-trash" /></button> }
              </td>
               </> : (
                <Popup
                open={open}
                onClose={() => closeModal()}
                closeOnDocumentClick={false}
                trigger={
                  <div
                    onClick={() => setOpen((o) => !o)}
                    className="actionBtn"
                  >
                    <i className="fa fa-eye" />
                  </div>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button
                      className="close"
                      id="departmentDetailClose"
                      onClick={() => {
                        closeModal();
                        close();
                      }}
                    >
                      &times;
                    </button>
                    <DepartmentDetails departmentDetails={dept} />
          
                  </div>
                )}
                      </Popup>
               )}
             
            </tr>
           
            ))
            }
          </tbody>
        </table>
        <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
      </div>
    </div>
  </>
  );
};

export default Department;
const DepartmentDetails = ({departmentDetails}) => {
  return (
    <>
    <div className="header">
      {" "}
      <h3> Department Details</h3>
    </div>
        <div className="content">
        <div className="w-full">
        <div className="mb-3">
          <h3 className="text-[14px] flex">
          <span className="w-36 inline-block">  Department Name:</span>
                <span className=" text-black font-medium pl-2">
              {departmentDetails?.department_name}
            </span>
          </h3>
      </div>
    
      <div className="mb-3">
          <h3 className="text-[14px] flex">
          <span className="w-36 inline-block"> Status:</span>
                <span className=" text-black font-medium pl-2">
              {departmentDetails?.status === '1' ? 'Active' : 'Inactive'}
            </span>
          </h3>
      </div>
      {/* <div className="mb-3">
          <h3 className="text-[14px]">
          <span className="w-36 inline-block">Approver:</span>
                <span className=" text-gray font-medium pl-2">
              {overDetails?.approver}
            </span>
          </h3>
      </div> */}
    
      <div className="mb-3">
        <h3 className="text-[14px] flex">
        <span className="w-36 inline-block">Color</span>
              <div
                className="rounded ml-2"
                style={{
                  backgroundColor: `${departmentDetails?.department_color_code}`,
                  width: "30px",
                  height: "25px"
                }}
              />
        </h3>
      </div>
      
        </div>
        </div>
                   
   </>
  );
};
