

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
          <div className="loading-spinner"/>
        </div>
      );
};

export default LoadingScreen;