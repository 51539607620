/* eslint-disable camelcase */
import { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "react-datepicker/dist/react-datepicker.css";
import {
  FormSelect
} from "../../components/formInput";
import "react-datepicker/dist/react-datepicker.css";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import { useGetEmployeeMutation} from "../../services/employeeApi";
import { useEffect } from "react";
import  moments from 'moment';
import { CSVLink } from "react-csv";
import Pagination from "../../components/inc/pagination";
import NoDataFound from "../../helper/noDataFound";
import { LoadingIndicator } from "../../components/loading-indicator";
import { useGetRolesMutation } from "../../services/reportApi";
import { useDispatch, useSelector } from "react-redux";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { allEmployeeInfoAction } from "../../redux/EmployeeSlice/employeeSlice";

const EmployeeInfoReport  = () => {
  
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const allEmployeeInfoReponse = useSelector((state) => state?.persistedReducer?.employeeSlice?.allEmployeeInfoData);
  const dispatch =useDispatch();
  const ignore = useRef(false);
  const [ getEmployee, {data:allEmployeeInfoReponseList, isSuccess:isSuccessEmployeeInfo, isLoading} ] = useGetEmployeeMutation();
  const [ getDepartmentList, {data:departmentApiResponse, isSuccess:isSuccessDepartmentApi} ] = useGetDepartmentListMutation();
  const [ getRoles, {data:roles} ] = useGetRolesMutation();
  const [ allDepartmentListData, setAllDepartmentListData ] =useState({});
  const [ excelData, setExcelData ] = useState([]);
  const createCsvFileName = () => `EmployeeInfo_report_${moments().format()}.csv`;
  const [ page, setPage ] = useState(1);
  const [ role, setRole ] = useState();
  const [ pages, setPages ] = useState(0);
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);
  const [ employeeInfo, setEmployeeInfo ] = useState();
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  useEffect(() => {
    if (!ignore.current){
    let payload = `/employee?page=${page}&status=${1}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
    getEmployee(payload);
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[] );

useEffect(() => {
  if(isSuccessEmployeeInfo){
    dispatch(allEmployeeInfoAction(allEmployeeInfoReponseList));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessEmployeeInfo ]);

useEffect(() => {
  if(allEmployeeInfoReponse){
    setEmployeeInfo(allEmployeeInfoReponse?.data);
    setPages(allEmployeeInfoReponse?.totalPages);
    if(allEmployeeInfoReponse?.data){
      let csvData = exportTOCSV(allEmployeeInfoReponse?.data);
      setExcelData(csvData);
    }
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ allEmployeeInfoReponse ]);

const decrementPage = () => {
  if(allEmployeeInfoReponse?.currentPage - 1 >= 0){
    getEmployee(allEmployeeInfoReponse?.currentPage - 1);
     setPage(allEmployeeInfoReponse?.currentPage - 1);
  }
};

  useEffect(() => {
    if(role){
      let payload = `/employee?page=${page}&status=${1}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&role_type_id=${role}`;
      getEmployee(payload); 
    }
    else{
      let payload = `/employee?page=${page}&status=${1}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
      getEmployee(payload);
      getDepartmentList();
      getRoles();  
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);


  let  headers = [
    { label: 'Employee Name', key : 'employeeName'},
    { label: 'DOB', key : 'dob'},
    { label: 'Gender', key : 'gender'},
    { label: 'Email', key : 'email'},
    { label: 'Mobile_no', key : 'mobile_no'},
    { label: 'Department', key : 'department'},
    { label: 'Role', key : 'role'},
    { label: 'Employee Start Date', key : 'start_date'},
    { label: 'Address', key : 'address'}
    
    ];
    const exportTOCSV = (report) => {
      let  dataAbence =[];
      if(report){
        report?.forEach((dExcel) => {
          // let hour = parseInt(dExcel.duration) /60;
          let val ={
            employeeName: dExcel?.first_name + dExcel?.last_name,
            dob: moments(dExcel?.dob).format('DD-MM-YYYY'),
            gender: dExcel?.gender === "1" ? 'Male' : dExcel?.gender === "2" ? 'Female' : 'Unknown',
             email: dExcel?.email,
             mobile_no:dExcel?.mobile_no,
             department: dExcel?.departmentInfo?.department_name,
             role: dExcel?.rolesInfo[0]?.roleType?.role_name,
             start_date: moments(dExcel?.employment_start_date).format(formattedDate),
             address: dExcel?.address
         };
          dataAbence?.push(val);
        });
        return dataAbence;
      }
   };
   useEffect(() => {
    if(isSuccessDepartmentApi){
      dispatch(departmentListAction(departmentApiResponse));
    }
   },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccessDepartmentApi ]);
  useEffect(() => {
    if(departmentApiData){
      setAllDepartmentListData(departmentApiData?.data);
    }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ departmentApiData ]);

  const filterEmployees = useCallback((searchTerm) => {
    let payload =  `/employee?page=${page}&status=${1}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&first_name=${searchTerm}`;
    getEmployee(payload);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

  const handlerChangeByDeptName = (e) => {
    e.preventDefault();
     if (e.target.value === ' ') {
      let payload =  `/employee?page=${page}&status=${1}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
      getEmployee(payload);    
     } else {
      let payload =  `/employee?page=${page}&status=${1}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&department_id=${e.target.value}`;
      getEmployee(payload); 
     } 
     
  };
  const handlerChangeByRoleName = (e) => {
    e.preventDefault();
     if (e.target.value === null) {
      let payload =  `/employee?page=${page}&status=${1}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
      getEmployee(payload);    
     } else {
      setRole(e.target.value);
      let payload =  `/employee?page=${page}&status=${1}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&role_type_id=${e.target.value}`;
      getEmployee(payload); 
     } 
     
  };

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Employee Information Report </h2>             
              <div className="card ">            
                <div className="p-4 ">
                  <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                      <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 ">
                      <input placeholder="Search By Name" id="employeeInfoSearch"
                        className="formControl w-full"
                        onChange={(e) => filterEmployees(e.target.value)} 
                      />
                      </div>
                      <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 md:pr-4">
                          <select className="formControl"  id="employeeInfoStartDate"  name="department_name" onChange={(e) => handlerChangeByDeptName(e)}>
                        <FormSelect 
                              optionValue={"0"}
                              optionName={"Select Department"} />;
                        {allDepartmentListData && allDepartmentListData.length > 0 && allDepartmentListData.map((dept, index) => {
                            return<FormSelect  key={index}
                                  optionValue={dept?.id} 
                                  optionName={dept?.department_name} />;
                        })}
                        </select>
                          </div>
                          <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2">
                          <select className="formControl" id="employeeInfoRoles"  name="rolesId"
                          onChange={(e) => handlerChangeByRoleName(e)}
                          >
                        <FormSelect 
                              optionValue={null}
                              optionName={"Select Role"} />;
                        {roles?.roles?.map((role, index) => (
                            <option key={index} value={role.id}>
                              {role?.role_name}
                            </option>
                        ))}
                        </select>
                          </div>
                      <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full ml-[150px]">
                      <CSVLink
                        className=""
                        data={excelData}
                        headers={headers}
                          filename={createCsvFileName()}
                          target="_blank"
                        style={{ textDecoration: 'none', outline: 'none', height: '5vh' }}
                        >
                        <button  className="btn-md btn-add " id="employeeInfoCsv">
                        <span className="fa fa-file-export mr-2" /> Export to CSV
                        </button>
                        </CSVLink>
                      </div>
                    </div>
                      <div className="overflow-x-auto w-full whitespace-nowrap">
                        <table className="table-auto table  w-full">
                          <thead className="thead-dark ">
                            <tr>
                              <th className="w-40"> Name</th>
                              <th>DateOfBirth</th>
                              <th>Gender</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Department</th>
                              <th>Role</th>
                              <th>Start Date</th>
                              <th>Address</th>
                            </tr>
                          </thead>
                          {isLoading ?<LoadingIndicator/> :
                          <tbody>   
                          {employeeInfo?.length > 0 ?
                          <>{ employeeInfo?.length > 0 &&  employeeInfo?.map((employee, index) => { 
                            return<>
                           <tr key = {index}>
                              <td>
                                <Link
                                  to={`/employees/detail/${employee.id}`}
                                  class="listImage flex items-center "
                                >
                                  {employee?.first_name+' '+employee?.last_name}
                                </Link>
                              </td>
                              <td>{moments(employee?.dob).format(formattedDate)}</td>
                              <td>
                                {employee?.gender === "1" ? 'Male' : employee?.gender === "2" ? 'Female' : 'Unknown'}
                              </td>
                              <td>{employee?.email}</td>
                              <td>{employee?.mobile_no}</td>
                              <td>{employee?.departmentInfo?.department_name}</td>
                              <td>{employee?.rolesInfo[0]?.roleType?.role_name}</td>
                              <td>{moments(employee?.employment_start_date).format(formattedDate)}</td>
                              <td>
                                {employee?.address  ? (employee?.address !== 'null' ? (employee.address + " ") : " " ) : ' '}
                                
                                {employee?.address2 ? (employee?.address2 !== 'null' ? (employee.address2 + " ") : " " ) : ' '}

                                {employee?.city  ? (employee?.city !== 'null' ? (employee.city + " ") : " " ) : ' ' }
                                {employee?.country  ? (employee?.country !== 'null' ? (employee.country + " ") : " " ) : ' '}
                                {employee?.postal_code  ? (employee?.postal_code !== 'null' ? (employee.postal_code + " ") : " " ) : ' '}
                              </td>
                            </tr>
                            </>;
                            })}</>:
                            <center className="mt-4">{<NoDataFound/>}</center>}
                          
                          </tbody>}
                        </table>
                        <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
                      </div>
                    </div>
             
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default EmployeeInfoReport;
