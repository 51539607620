import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import AddAuditors from "./AddAuditors";
import { useAuditorsListsMutation, useDeleteAuditorsMutation  } from "../../services/auditorsApi";
import { auditorsListsAction } from "../../redux/AuditorsSlice/AuditorsSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Auditors = () => {

  //selector for carrying out data from redux store
  const auditorListing = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorsLists);
  
  //States and hooks
  const toastId = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  //Api Calling
  const [ listOfAuditors, { data: auditorsLists, isSuccess: auditorListsSuccess } ] = useAuditorsListsMutation();
  const [ deleteAuditor, { isSuccess: deleteAuditorSuccess, isLoading: deleteAuditorLoading, isError: deleteAuditorError, error: apiErrordeleteAuditor } ] = useDeleteAuditorsMutation();

  //api data dispatched into redux store
  useEffect(() => {
      listOfAuditors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //api data dispatched into redux store
  useEffect(() => {
    if (auditorListsSuccess) {
      dispatch(auditorsListsAction(auditorsLists));
    }
  }, [auditorListsSuccess]);

  useEffect(() => {
    if (deleteAuditorLoading) {
      toastId.current = toast.loading("Please wait...", {
        toastId: "overtime11",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (deleteAuditorSuccess) {
      toast.dismiss(toastId.current);
      toast.success("Auditor deleted successfully.", {
        render: "Auditor deleted successfully.",
        type: "success",
        autoClose: 10000,
        theme: "colored",
        isLoading: false
      });
      listOfAuditors();
    }
    if (deleteAuditorError) {
      toast.dismiss(toastId.current);
      const toastMessage = apiErrordeleteAuditor.data.message
        ? apiErrordeleteAuditor.data.message
        : "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 10000,
        duplicate: false,
        isLoading: false
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [deleteAuditorLoading, deleteAuditorSuccess, deleteAuditorError]);

  const handlerDelete = (id, close) => {
    deleteAuditor(id);
    close();
  };

  return (
    <>
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <h3 className=" font-semibold text-lg">External Users</h3>
        <div className="headerButton">
          <Popup
            open={open}
            onClose={() => closeModal()}
            closeOnDocumentClick={false}
            trigger={
              <button
                className="btn-md btn-add"
                id="AddOvertimeTypes"
                onClick={() => setOpen((o) => !o)}
              >
                <span className="fa fa-plus mr-2" />
                Add External User
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button
                  className="close"
                  id="AddOvertimeTypesClose"
                  onClick={() => {
                    closeModal();
                    close();
                  }}
                >
                  &times;
                </button>
                <AddAuditors 
                  listOfAuditors={listOfAuditors} 
                  close={close}
                />
              </div>
            )}
          </Popup>
        </div>
      </div>

      <div className="p-4">
        <div className="overflow-x-auto w-full whitespace-nowrap">
          <table className="table-auto table  w-full">
            <thead className="thead-dark ">
              <tr>
                <th className="w-44"> Name</th>
                <th>Email</th>
                <th>Mobile No</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {auditorListing?.data?.length > 0 &&
                auditorListing?.data?.map((auditors, index) => (
                  <tr key={index}>
                    <td>{auditors.name}</td>
                    <td>{auditors?.email}</td>
                    <td>{auditors?.mobile_no}</td>
                    <td>
                      <Popup
                        onClose={() => closeModal()}
                        closeOnDocumentClick={false}
                        trigger={
                          <div
                            onClick={() => setOpen((o) => !o)}
                            className="actionBtn cancelBtn"
                          >
                            <i className="fa fa-trash" />
                          </div>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button
                              className="close"
                              id="DeleteOvertimeTypes"
                              onClick={() => {
                                closeModal();
                                close();
                              }}
                            >
                              &times;
                            </button>
                            <div className="header">
                              {" "}
                              <h3>Are you sure?</h3>
                            </div>
                            <div className="content">
                              <p>Are you sure you want to delete this</p>
                              <div className="w-full mt-8">
                                <div className="form-group pt-4 pb-3 flex justify-center ">
                                  <button
                                    id="DeleteOvertimeTypesYes"
                                    className="btn save mr-2"
                                    onClick={() =>
                                      handlerDelete(auditors?.id, close)
                                    }
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    id="DeleteOvertimeTypesNo"
                                    onClick={() => close()}
                                    className="btn cancel  "
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popup>
                      <Popup
                        onClose={() => closeModal()}
                        closeOnDocumentClick={false}
                        trigger={
                          <div
                            onClick={() => setOpen((o) => !o)}
                            className="actionBtn"
                          >
                            <i className="fa fa-pencil" />
                          </div>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button
                              className="close"
                              id="editOvertimeTypes"
                              onClick={() => {
                                closeModal();
                                close();
                              }}
                            >
                              &times;
                            </button>
                            <AddAuditors
                              auditors={auditors}
                              listOfAuditors={listOfAuditors} 
                              close={close}
                            />
                          </div>
                        )}
                      </Popup>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Auditors;
