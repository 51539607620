/* eslint-disable camelcase */
import { useState, useEffect, useCallback, useRef } from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SearchField } from "../../components/filters";
import { useGetAllInformationMutation } from "../../services/reportApi";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { CSVLink } from "react-csv";
import { LoadingIndicator } from "../../components/loading-indicator";
import { useDispatch, useSelector } from "react-redux";
import moments from "moment";
import Pagination from "../../components/inc/pagination";
import DatePicker from "react-datepicker";
import moment from "moment";
import Popup from "reactjs-popup";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);


const CompanyMessagesReport = () => {
  const ignore = useRef(false);
  //carrying out data from redux
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);

  //api calling
  const [ getDepartmentList, { data: departmentApiResponse, isSuccess: isSuccessDepartmentApi } ] = useGetDepartmentListMutation();
  const [ getAllInformation, { data: allEmployeeInfoReponse, isSuccess: isSuccessEmployeeInfo, isLoading } ] = useGetAllInformationMutation();

  //States are defined here
  const dispatch = useDispatch();
  const [ employeeInfo, setEmployeeInfo ] = useState();
  const [ excelData, setExcelData ] = useState([ ]);
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const [ open, setOpen ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(0);
  const pageSize = 10;
  const createCsvFileName = () => `Company_message_report_${moments().format()}.csv`;
  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!ignore.current){
      getAllInformation(`/reports?type=company_messages&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`);
      getDepartmentList();
    }
    return () => { ignore.current = true; };
    },
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
      if (isSuccessEmployeeInfo) {
        setEmployeeInfo(allEmployeeInfoReponse?.data?.rows);
        if (allEmployeeInfoReponse?.data) {
          let csvData = exportTOCSV(allEmployeeInfoReponse?.data);
          setExcelData(csvData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccessEmployeeInfo ]);


  useEffect(() => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [ isSuccessDepartmentApi ]);

  useEffect(() => {
    if (allEmployeeInfoReponse?.data?.rows) {
      let totalPages = Math.ceil(
        allEmployeeInfoReponse?.data?.rows?.length / pageSize
      );
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allEmployeeInfoReponse?.data?.rows?.slice(
        firstPageIndex,
        lastPageIndex
      );
      setEmployeeInfo(data);
      setPages(totalPages);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
[ allEmployeeInfoReponse?.data?.rows ]);

  //Filters
  const filterEmployees = useCallback(
    (searchTerm) => {
      let payload = `/reports?type=company_messages&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&first_name=${searchTerm}`;
      getAllInformation(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [ ]);
  
  const onDateChange = (e) => {
    if (e[0] !== null && e[1] !== null) {
      let payload = `/reports?type=company_messages&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&start_date=${moment(e[0]).format("YYYY-MM-DD")}&end_date=${moment(e[1]).format("YYYY-MM-DD")}`;
      getAllInformation(payload);
    } else {
      let payload = `/reports?type=company_messages&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
      getAllInformation(payload);
    }
  };
  
  //Pagination Logic Defined Here
  // eslint-disable-next-line no-unused-vars
  const currentTableData = useMemo(() => {
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allEmployeeInfoReponse?.data?.rows?.slice(
        firstPageIndex,
        lastPageIndex
      );
      setEmployeeInfo(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ page ]
  );

  // eslint-disable-next-line no-unused-vars
  const decrementPage = () => {
    const lastPageIndex = (page - 1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allEmployeeInfoReponse?.data?.rows?.slice(
      lastPageIndex,
      firstPageIndex
    );
    setEmployeeInfo(data);
  };
  
  //Export To CSV Func.
  let headers = [
    { label: "Date added", key: "date_added" },
    { label: "Message", key: "message" },
    { label: "Added by", key: "added_by" }
  ];

  const exportTOCSV = (report) => {
    let dataAbence = [];
    if (report) {
      report?.rows?.forEach((dExcel) => {
        let val = {
          date_added: moments(dExcel?.createdAt).format("DD-MM-YYYY"),
          message: dExcel?.message,
          added_by: dExcel?.senderInfo?.first_name
        };
        dataAbence?.push(val);
      });
      return dataAbence;
    }
  };


  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>
        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">
                Company Message Report{" "}
              </h2>
              <div className="card ">
                <div className="p-4 ">
                  <div className="headingContent  pt-0 w-full">
                    <div className="flex pt-2 pb-6 filterSection flex-wrap md:flex-nowrap">
                      <div className="flex  filterSection flex-wrap md:flex-nowrap w-full">
                        <div className="searchSec lg:w-1/5 md:w-1/3 md:pr-4 w-full mb-2 lg:mb-0 ">
                          <SearchField
                            placeholderText="Search By Name"
                            searchId="companyMessageSearch"
                            onChange={(e) => filterEmployees(e.target.value)}
                          />
                        </div>
                        <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 ">
                          <DatePicker
                            selectsRange={true}
                            id="companyMessageStartDate"
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd/MM/yyyy"
                            className="formControl"
                            placeholderText="Select Date Range"
                            onChange={(update) => {
                              setDateRange(update);
                              onDateChange(update);
                            }}
                            isClearable={true}
                            locale="en-GB"
                          />
                        </div>
                      </div>
                      <div className="headerButton flex items-center exportFile md:w-96  w-full justify-end ">
                        <CSVLink
                          className=""
                          data={excelData}
                          headers={headers}
                          filename={createCsvFileName()}
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            outline: "none",
                            height: "5vh"
                          }}
                        >
                          <button
                            className="btn-md btn-add "
                            id="companyMessageCsv"
                          >
                            <span className="fa fa-file-export mr-2" /> Export
                            to CSV
                          </button>
                        </CSVLink>
                      </div>
                    </div>
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                      <table className="table-auto table  w-full">
                        <thead className="thead-dark ">
                          <tr>
                            <th>Sender Name</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <LoadingIndicator />
                        ) : (
                          <tbody>
                            {employeeInfo?.length > 0 ? (
                              <>
                                {employeeInfo?.length > 0 &&
                                  employeeInfo?.map((employee, index) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>
                                            <Link
                                              to={`/employees/detail/${employee.id}`}
                                              class="listImage flex items-center "
                                            >
                                              {employee?.senderInfo
                                                ?.first_name +
                                                " " +
                                                employee?.senderInfo?.last_name}
                                            </Link>
                                          </td>
                                          <td>{<Popup
                                                open={open}
                                                onClose={() => closeModal()}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                  <p
                                                    className="break-words text-[14px] "
                                                    onClick={() =>
                                                      setOpen((o) => !o)
                                                    }
                                                  >
                                                    {typeof employee?.subject ===
                                                      "string" &&
                                                      employee?.subject
                                                        .split(" ")
                                                        .slice(0, 8)
                                                        .join(" ")}
                                                    {typeof employee?.subject ===
                                                      "string" &&
                                                    employee?.subject.split(" ")
                                                      .length > 12 ? (
                                                      <>
                                                        <span className="primary-text cursor-pointer">
                                                          ... read more
                                                        </span>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                }
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      id="messageDetailClose"
                                                      onClick={() => {
                                                        closeModal();
                                                        close();
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                    <div className="header">
                                                      <h3> Message</h3>
                                                    </div>
                                                    <div className="OpenMeassage p-4">
                                                      <h3 className="text-[14px]">
                                                        From:{" "}
                                                        <span className="font-medium">
                                                          {
                                                            employee?.senderInfo
                                                              ?.first_name
                                                          }
                                                        </span>
                                                      </h3>
                                                      <div className="form-group mt-3">
                                                        <label className="formBlock">
                                                          Subject:
                                                        </label>
                                                        <div className=" break-words  ">
                                                          <p>
                                                            {employee?.message}
                                                          </p>
                                                          <h3>
                                                            Date :{" "}
                                                            {moments(
                                                              employee?.createdAt
                                                            ).format(
                                                              formattedDate
                                                            )}
                                                          </h3>
                                                          <h5>
                                                            Time :{" "}
                                                            {moment(
                                                              employee?.createdAt
                                                            )?.format("hh:mm")}
                                                          </h5>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </Popup>}</td>
                                          <td>
                                            {
                                              <Popup
                                                open={open}
                                                onClose={() => closeModal()}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                  <p
                                                    className="break-words text-[14px] "
                                                    onClick={() =>
                                                      setOpen((o) => !o)
                                                    }
                                                  >
                                                    {typeof employee?.message ===
                                                      "string" &&
                                                      employee?.message
                                                        .split(" ")
                                                        .slice(0, 8)
                                                        .join(" ")}
                                                    {typeof employee?.message ===
                                                      "string" &&
                                                    employee?.message.split(" ")
                                                      .length > 12 ? (
                                                      <>
                                                        <span className="primary-text cursor-pointer">
                                                          ... read more
                                                        </span>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                }
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      id="messageDetailClose"
                                                      onClick={() => {
                                                        closeModal();
                                                        close();
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                    <div className="header">
                                                      <h3> Message</h3>
                                                    </div>
                                                    <div className="OpenMeassage p-4">
                                                      <h3 className="text-[14px]">
                                                        From:{" "}
                                                        <span className="font-medium">
                                                          {
                                                            employee?.senderInfo
                                                              ?.first_name
                                                          }
                                                        </span>
                                                      </h3>
                                                      <div className="form-group mt-3">
                                                        <label className="formBlock">
                                                          Message:
                                                        </label>
                                                        <div className=" break-words  ">
                                                          <p>
                                                            {employee?.message}
                                                          </p>
                                                          <h3>
                                                            Date :{" "}
                                                            {moments(
                                                              employee?.createdAt
                                                            ).format(
                                                              formattedDate
                                                            )}
                                                          </h3>
                                                          <h5>
                                                            Time :{" "}
                                                            {moment(
                                                              employee?.createdAt
                                                            )?.format("hh:mm")}
                                                          </h5>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </Popup>
                                            }
                                          </td>
                                          <td>
                                            {moments(
                                              employee?.createdAt
                                            ).format(formattedDate)}{" "}
                                            <span className="text-gray-700">
                                              {moment(
                                                employee?.createdAt
                                              )?.format("hh:mm")}
                                            </span>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <center className="mt-4 ml-[120%] text-red-700">
                                No Data Found
                              </center>
                            )}
                          </tbody>
                        )}
                      </table>
                      <Pagination
                        page={page}
                        pages={pages}
                        changePage={setPage}
                        decrementPage={setPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyMessagesReport;
