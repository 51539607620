import {useEffect, useState} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { onMessageListener } from './firebase';
import { useGetAllNotificationCountMutation, useGetAllNotificationMutation } from './services/notesApi';
import { useDispatch, useSelector } from 'react-redux';
import { notificationCount } from './redux/AuthSlice/authSlice';
import { apiStatusHandler } from './helper/apiErrorHandler';


const Notification = () => {
  const [ notification, setNotification ] = useState({title: '', body: ''});
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);
  const [ getAllNotification ] = useGetAllNotificationMutation();
  const [ getAllNotificationCount, { data: notificationCountRespo, isSuccess: isSuccessNotification, isError: isNotiError, error: notiError } ] = useGetAllNotificationCountMutation();
  const notify = () =>  toast(<ToastDisplay/>);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccessNotification) {
      dispatch(notificationCount(notificationCountRespo?.unreadCount));
    }
    if(isNotiError) {
      // alert("Session Expired");
      apiStatusHandler(notiError?.status);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessNotification, isNotiError ]);

  const  notificationDataApi = () => {
    if (auditorRoleId === 5) {
      return;
    }
    let payload = `/employeenotification?employee_id=${employeeIdData}`;
    getAllNotification(payload);
    let payload1 = `/employeenotification/count/?employee_id=${employeeIdData}`;
    getAllNotificationCount(payload1);
  };

  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body
      });
      if (payload.notification) {
        notify();
        notificationDataApi();
      }
    })
    .catch((err) => console.log("failed: ", err));

  return (
     <Toaster/>
  );
};

export default Notification;