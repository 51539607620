/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* eslint-disable no-use-before-define */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
// var regEmail=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
// var regPhone=/^\d{10}$/;   // Javascript reGex for Phone Number validation.
// var regName = /\d+$/g;      // Javascript reGex for Name validation

const NAME_REGEX = /[^a-zA-Z -]{3,}/;

export const nameValidator = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '')
        return `${fieldName} is required`;

    if (NAME_REGEX.test(fieldValue))
        return 'Invalid characters';

    if (fieldValue.trim().length < 3)
        return ` Please enter atleast three characters`;

    return null;
};
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const emailValidator = (email) => {
    if (!EMAIL_REGEX.test(email)) {
        return "Please enter a valid email";
    }
    if (email.trim() === '')
        return 'Email is required';
    return "";
};

// const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;

export const passwordValidator = (password) => {
    if (password.trim() === '') 
        return 'Password is required';
    if (password.length < 8) 
        return 'Please enter at least 8 characters.';
    if (password.length > 20) 
        return 'Password must be less than or equal to 20 characters.';
    return '';
};



const validationMobile = (mobileNo) => {
    var phoneno = /^\d{10}$/;
    if (mobileNo.match(phoneno)) {
        return {
            mobileError: true,
            mobileNo: mobileNo
        };
    }
    else
        return {
            mobileError: false
        };

};

const startDateValidator = (startDate) => {
    if (startDate === '') {
        return 'Please enter the start date ';
    }
};

const websiteValidator = (websiteurl) => {
    if (websiteurl === '') {
        return 'Requiered WebsiteName';
    }
};

const endDateValidator = (endDate) => {
    if (endDate === '') {
        return 'Please enter End Date';
    }
};

export const handleValidation = (name, formData, isSubmit) => {

    const errors = {};
    if (isSubmit === true) {
        if (Object.keys(formData).length === 0) {
            formData = {
                Name: '',
                email: '',
                Website: '',
                AddressLine1: '',
                city: '',
                country: '',
                postcode: '',
                startDate: '',
                endDate: '',
                password: ''

            };
        }

        for (let fieldName in formData) {

            if (fieldName === 'Name') {
                const name = nameValidator(fieldName, formData[fieldName]);
                errors.name = name;
            } else if (fieldName === 'email') {
                const emailError = emailValidator(formData[fieldName]);
                errors.email = emailError;
            } else if (fieldName === 'Website') {
                const websiteError = websiteValidator(formData[fieldName]);
                errors.website = websiteError;
            } else if (fieldName === 'AddressLine1') {
                if (formData[fieldName] === '') {
                    errors.address1 = 'Please enter the address ';
                }
            } else if (fieldName === 'city') {
                if (formData[fieldName] === '') {
                    errors.city = 'Please enter the City/Town ';
                }
            } else if (fieldName === 'country') {
                if (formData[fieldName] === '') {
                    errors.country = 'Please enter the Country ';
                }

            } else if (fieldName === 'postcode') {
                if (formData[fieldName] === '') {
                    errors.postcode = 'Please enter the Postcode ';
                }
            } else if (fieldName === 'startDate') {
                if (formData[fieldName] === '') {
                    errors.startDate = startDateValidator(formData[fieldName]);
                }
            } else if (fieldName === 'endDate') {
                if (formData[fieldName] === '') {
                    errors.endDate = endDateValidator(formData[fieldName]);
                }

            } else if (fieldName === 'password') {
                if (formData[fieldName] === '') {
                    errors.password = 'Please enter the Password';
                }
            }
        }

    }
    else {
        switch (name) {
            case 'Name':
                const nameError = nameValidator(name, formData);
                errors.name = nameError;
                break;
            case 'Website':
                const websiteError = websiteValidator(formData);
                errors.website = websiteError;
                break;
            case 'AddressLine1':
                if (formData === '') {
                    errors.AddressLine1 = 'Please enter the address ';
                }
                break;
            case 'city':
                if (formData === '') {
                    errors.AddressLine1 = 'Please enter the City/Town ';
                }
                break;
            case 'country':
                if (formData === '') {
                    errors.country = 'Please enter the Country ';
                }
                break;
            case 'postcode':
                if (formData === '') {
                    errors.postcode = 'Please enter the Postcode ';
                }
                break;
            case 'fileUpload':
                if (formData === '') {
                    errors.fileUpload = 'Please Select file';
                }
                break;
            case 'startDate':
                if (formData === '') {
                    errors.startDate = startDateValidator(formData);
                }
                break;
            case 'endDate':
                if (formData === '') {
                    errors.endDate = endDateValidator(formData);
                } break;
            case 'password':
                if (formData === '') {
                    errors.password = 'Please fill password';
                }
        }
    }
    return errors;

};

export const signUphandleValidation = (name, formData, isSubmit) => {
    const errors = {};
    if (isSubmit === true) {
        if (Object.keys(formData).length === 0) {
            formData = {
                first_name: '',
                last_name: '',
                company_name: '',
                email: '',
                password: ''
            };
        }

        for (let fieldName in formData) {
            if (fieldName === 'first_name') {
                errors.FirstName = nameValidator(fieldName, formData[fieldName]);

            } else if (fieldName === 'last_name') {
                errors.LastName = nameValidator(fieldName, formData[fieldName]);
            } else if (fieldName === 'company_name') {
                errors.CompanyName = nameValidator(fieldName, formData[fieldName]);
            } else if (fieldName === 'email') {
                const emailError = emailValidator(formData[fieldName]);
                errors.email = emailError;
            }
        }

    }
    else {
        switch (name) {
            case 'first_name':
                errors.FirstName = nameValidator(name, formData);
                break;
            case 'last_name':
                errors.LastName = nameValidator(name, formData);
                break;
            case 'company_name':
                errors.CompanyName = nameValidator(name, formData);
                break;
            case 'Name':
                const nameError = nameValidator(name, formData);
                errors.name = nameError;
                break;
            case 'email':
                const emailError = emailValidator(formData);

                errors.email = emailError;
                break;

        }
    }
    return errors;

};

