/* eslint-disable no-debugger*/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardData:[],
  empDashboardData: [],
  superDashboardChartData: [],
  superDashboardReportData: []
};

export const dashboardSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    dashboardDataAction: (state, action) => {
      state.dashboardData = action?.payload;
    },
    empDashboardDataAction: (state, action) => {
      state.empDashboardData = action?.payload;
    },
    superDashboardChartDataAction: (state, action) => {
      state.superDashboardChartData = action?.payload;
    },
    superDashboardReportDataAction: (state, action) => {
      state.superDashboardReportData = action?.payload;
    } 
  }
});

export const {  dashboardDataAction, empDashboardDataAction, superDashboardChartDataAction, superDashboardReportDataAction } = dashboardSlice.actions;
export default dashboardSlice.reducer;