import { Link, useNavigate } from "react-router-dom";
import { ModifiedToast } from "./toaster";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FormInput } from "./formInput";
import PrimaryButton from "./primaryButton";
import backgroundImage from "../loginBg.webp";
import { useEffect, useRef, useState } from "react";
import { useLoginAuditorMutation } from "../services/auditorsApi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { allUserPermissions, userInfo } from "../redux/AuthSlice/authSlice";

const AuditorLogin = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastId = useRef();
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();
  // eslint-disable-next-line no-unused-vars
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const [loginAuditor, {data: auditorLoginData, isSuccess: loginSuccess, isLoading: loginLoading, isError: loginError, error: apiErrorLogin}] = useLoginAuditorMutation();
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  
  useEffect(() => {
    if (loginLoading) {
      toastId.current = toast.loading("Please wait...", {
        toastId: "overtime11",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if (loginSuccess) {
      toast.dismiss(toastId.current);
      localStorage.setItem('accessToken', auditorLoginData.accessToken);
      dispatch(userInfo(auditorLoginData?.data));
      if (auditorLoginData) {
        dispatch(allUserPermissions(auditorLoginData?.data?.permissions));
      }
      toast.success("Auditor login successfully.", {
        render: "Auditor login successfully.",
        type: "success",
        autoClose: 10000,
        theme: "colored",
        isLoading: false
      });
      navigate("/auditor/company-select");
    }
    if (loginError) {
      toast.dismiss(toastId.current);
      const toastMessage = apiErrorLogin.data.message
        ? apiErrorLogin.data.message
        : "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 10000,
        duplicate: false,
        isLoading: false
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [loginLoading, loginSuccess, loginError]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let val = {
      email: formData?.email,
      password: formData?.password
    };
    loginAuditor(val);
  };

  return (
    <div
      className="flex loginGradient w-full"
      style={{ background: `url(${backgroundImage})` }}
    >
      <HelmetProvider>
        <Helmet>
          <title>Schedulize | Login </title>
        </Helmet>
      </HelmetProvider>
      <div className=" flex align-center w-full">
        <div className=" w-full  ">
          <div className="md:flex align-center justify-center h-full items-center flex-wrap w-full 	">
            <div className=" lg:p-8 p-4 lg:w-1/2 w-full relative">
              <div className=" w-full">
                <div className="w-full max-w-lg m-auto bg-white lg:p-8 p-4 border shadow-lg rounded-lg ">
                  <div className="text-center  w-full">
                    <img
                      src="../images/logo-full.webp"
                      width="220"
                      alt="logo"
                      className="mx-auto"
                    />
                  </div>
                  <h1 className="text-xl font-semibold my-6 mb-8 text-center">
                    External user account login
                  </h1>
                  <form className="text-left" id="loginForm">
                    <div className="parentGroup mb-5">
                      <label htmlFor="email" className="formBlock">
                        {" "}
                        Email
                      </label>
                      <FormInput
                        inputType="text"
                        inputName="email"
                        inputID="email"
                        value={formData?.email}
                        handlerChange={handleChange}
                      />
                      <div className="help-block text-red-700">
                        {error?.emailError ? error?.emailError : ""}
                      </div>
                    </div>
                    <div className="parentGroup mb-2 eyeIcon">
                      <label htmlFor="password" className="formBlock">
                        Password
                      </label>
                      <FormInput
                        inputType={passwordShown ? "text" : "password"}
                        inputName="password"
                        inputID="password"
                        value={formData?.password}
                        handlerChange={handleChange}
                      />

                      <i
                        className={
                          passwordShown
                            ? "fa fa-eye cursor-pointer"
                            : "fa fa-eye-slash cursor-pointer"
                        }
                        onClick={togglePasswordVisiblity}
                      />
                    </div>
                    <div className="help-block text-red-700">
                      {error?.passwordError ? error?.passwordError : ""}
                    </div>
                    <p className="primary-text text-right mb-4">
                      <Link to="/forgot-password" className="font-medium">
                        Forgot password?
                      </Link>
                    </p>
                    <div className=" mt-8 text-center">
                      {/* <Link to="/dashboard"> */}
                      <PrimaryButton
                        btnText="Login"
                        buttonID="login"
                        Btnclass={`btn save w-full mb-6 ${
                          isSubmitDisabled ? "disabled" : ""
                        }`}
                        onClick={(e) => handleFormSubmit(e)}
                        disabled={isSubmitDisabled} // Disable the button based on state
                      />
                      <p className="text-gray-600 font-medium">
                        Login as admin/manager/employee:{" "}
                        <Link
                          to="/login"
                          className="primary-text font-semibold"
                        >
                          Login
                        </Link>
                      </p>
                      <p className="text-gray-600 font-medium">
                        Not a Member yet?{" "}
                        <Link
                          to="/signup"
                          className="primary-text font-semibold"
                        >
                          Signup
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModifiedToast />
    </div>
  );
};

export default AuditorLogin;
