import {useEffect, useState} from "react";
import {useGetEmployeeWorkScheduleHistoryQuery} from "../../services/employeeApi";
import {useDispatch, useSelector} from "react-redux";
import {employeeWorkScheduleHistoryAction} from "../../redux/EmployeeSlice/employeeSlice";
import Pagination from "../../components/inc/pagination";
import moment from "moment";

const WorkScheduleHistory = ({employeeInfo}) => {
  // Selector for carrying out data from redux store
  const formattedDate = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.dateFormats
  );
  const historyData = useSelector(
    (state) =>
      state?.persistedReducer?.employeeSlice?.employeeWorkScheduleHistory?.data
  );

  // States and hooks
  const dispatch = useDispatch();
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);

  // Api calling
  const {data: getWorkScheduleHistoryData, refetch} =
    useGetEmployeeWorkScheduleHistoryQuery({
      id: employeeInfo?.id,
      page: page
    });
  const calculateTotalWorkingHours = (
    startTime,
    endTime,
    breakTime,
    breakPaid
  ) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Convert start and end times to minutes
    const startTimeInMinutes = startHour * 60 + startMinute;
    const endTimeInMinutes = endHour * 60 + endMinute;

    // Calculate total working time in minutes
    let totalWorkingMinutes = endTimeInMinutes - startTimeInMinutes;

    // Subtract the break time if not paid
    if (breakPaid === "no") {
      totalWorkingMinutes -= Number(breakTime);
    }

    return totalWorkingMinutes; // Returning the total working minutes
  };

  const sumTotalWorkingHours = (data, type) => {
    return data?.reduce((acc, item) => {
      const oldValue = JSON.parse(item?.old_value);
      const newValue = JSON.parse(item?.new_value);

      // Check whether to include this entry based on whether it's a weekend
      if (type === "Old") {
        const {
          start_time,
          end_time,
          break: breakTime,
          is_weekend,
          break_paid
        } = oldValue;
        if (is_weekend === "yes") return acc; // Skip weekends
        const workingMinutes = calculateTotalWorkingHours(
          start_time,
          end_time,
          breakTime,
          break_paid
        );
        return acc + workingMinutes;
      } else {
        const {
          start_time,
          end_time,
          break: breakTime,
          is_weekend,
          break_paid
        } = newValue;
        if (is_weekend === "yes") return acc; // Skip weekends
        const workingMinutes = calculateTotalWorkingHours(
          start_time,
          end_time,
          breakTime,
          break_paid
        );
        return acc + workingMinutes;
      }
    }, 0);
  };

  useEffect(() => {
    if (getWorkScheduleHistoryData) {
      refetch();
      dispatch(employeeWorkScheduleHistoryAction(getWorkScheduleHistoryData));
      setPages(getWorkScheduleHistoryData?.totalPages);
    }
  }, [getWorkScheduleHistoryData]);

  // Helper function to format schedule details
  const formatScheduleDetails = (data) => {
    if (!data)
      return {
        day: "Unavailable",
        shift: "Unavailable",
        nonWorkingDays: "Unavailable",
        breakTime: "Unavailable",
        totalWorkingHours: "Unavailable"
      };

    try {
      const {
        //is_holiday,
        is_weekend,
        break_paid,
        break: brk,
        start_time,
        end_time,
        day
      } = JSON.parse(data);

      // Calculate total working hours
      const startTime = moment(start_time, "HH:mm:ss");
      const endTime = moment(end_time, "HH:mm:ss");

      const durationMinutes = endTime.diff(startTime, "minutes");
      const durationMinutesBreakUnPaid = durationMinutes - brk;
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;

      const hoursWithOutBreak = Math.floor(durationMinutesBreakUnPaid / 60);
      const minutesWithOutBreak = durationMinutesBreakUnPaid % 60;

      // Format duration as HH:mm
      const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      const formattedDurationWithOutBreak = `${hoursWithOutBreak
        .toString()
        .padStart(2, "0")}:${minutesWithOutBreak.toString().padStart(2, "0")}`;

      // Format start and end times as HH:mm
      const formattedStartTime = startTime.format("HH:mm");
      const formattedEndTime = endTime.format("HH:mm");

      return {
        day: day || "N/A",
        shift: `${formattedStartTime} - ${formattedEndTime}`,
        nonWorkingDays: is_weekend === "yes" ? "Yes" : "No",
        breakTime: `${brk} min ${break_paid === "yes" ? "(Paid)" : "(Unpaid)"}`,
        totalWorkingHours:
          break_paid === "yes"
            ? formattedDuration
            : formattedDurationWithOutBreak
      };
    } catch (error) {
      return {
        day: "Invalid JSON",
        shift: "Invalid JSON",
        nonWorkingDays: "Invalid JSON",
        breakTime: "Invalid JSON",
        totalWorkingHours: "Invalid JSON"
      };
    }
  };

  return (
    <div className="p-6 min-h-screen">
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="w-28 p-2 text-left border border-gray-300">Day</th>
              <th className="w-36 p-2 text-left border border-gray-300">
                Shift
                <div className="text-xs text-gray-600">
                  <span>Old / New</span>
                </div>
              </th>
              <th className="w-40 p-2 text-left border border-gray-300">
                Non-working Days
                <div className="text-xs text-gray-600">
                  <span>Old / New</span>
                </div>
              </th>
              <th className="w-36 p-2 text-left border border-gray-300">
                Break (min)
                <div className="text-xs text-gray-600">
                  <span>Old / New</span>
                </div>
              </th>
              <th className="w-36 p-2 text-left border border-gray-300">
                Total Working Hours
                <div className="text-xs text-gray-600">
                  <span>Old / New</span>
                </div>
              </th>
              <th className="w-40 p-2 text-left border border-gray-300">
                Updated At
              </th>
              <th className="w-40 p-2 text-left border border-gray-300">
                Updated By
              </th>
            </tr>
          </thead>
          <tbody>
            {historyData &&
              historyData.map((history, index) => {
                const oldDetails = formatScheduleDetails(history?.old_value);
                const newDetails = formatScheduleDetails(history?.new_value);
                return (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td className="py-3 px-4 border border-gray-200">
                      <span>{oldDetails?.day}</span>
                    </td>
                    <td className="py-3 px-4 border border-gray-200">
                      <div className="flex flex-col">
                        <span>{oldDetails?.shift} to</span>
                        <span className="font-semibold">
                          {newDetails?.shift}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-4 border border-gray-200">
                      <div>
                        {oldDetails?.nonWorkingDays} to{" "}
                        <span className="font-semibold">
                          {newDetails?.nonWorkingDays}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-4 border border-gray-200">
                      <div className="flex flex-col">
                        <span>{oldDetails?.breakTime} to</span>
                        <span className="font-semibold">
                          {newDetails?.breakTime}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-4 border border-gray-200">
                      <div>
                        {oldDetails?.nonWorkingDays === "No"
                          ? oldDetails?.totalWorkingHours
                          : "00:00"}{" "}
                        to{" "}
                        <span className="font-semibold">
                          {newDetails?.nonWorkingDays === "No"
                            ? newDetails?.totalWorkingHours
                            : "00:00"}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-4 border border-gray-200">
                      <span>
                        {moment(history?.updatedAt)?.format(formattedDate)}{" "}
                      </span>
                      <span>{moment(history?.updatedAt)?.format("hh:mm")}</span>
                    </td>
                    <td className="py-3 px-4 border border-gray-200">
                      {history?.addedByForHistory?.first_name}{" "}
                      {history?.addedByForHistory?.last_name}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex items-center">
          <div className="font-semibold">
            Total New Hours{" "}
            <p>{(sumTotalWorkingHours(historyData, "new") / 60)?.toFixed(2)} hours</p>
          </div>
          <div className="font-semibold  ml-4">
            Total Old Hours{" "}
            <p>{(sumTotalWorkingHours(historyData, "Old") / 60)?.toFixed(2)} hours</p>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <Pagination
          page={page}
          pages={pages}
          changePage={setPage}
          decrementPage={setPage}
        />
      </div>
    </div>
  );
};

export default WorkScheduleHistory;
