/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState, useEffect, useMemo, useRef } from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import NoDataFound from "../../../helper/noDataFound";
import moments from "moment";
import Pagination from "../../../components/inc/pagination";
import ExpenseDetails from "./ExpenseDetails";
import ExpenseCancel from "./ExpenseCancel";
import { FormTextarea } from "../../../components/formInput";
import PrimaryButton from "../../../components/primaryButton";
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import { useChangesStatusExpenseApporveMutation } from "../../../services/approverListApi";
import { LoadingIndicator } from "../../../components/loading-indicator";
import { useSelector } from "react-redux";

const ApprovedExpensesToPay = ({ expenseLoading, allApproveToExpense,
  employeeExpenseApprover,
  employeeId
}) => {
  const [ changesStatusExpenseApporve, { isSuccess: isSuccessStatus,
    isError: isErrorStatus, isLoading: isLoadingStatus
   } ] = useChangesStatusExpenseApporveMutation();
    const [ open, setOpen ] = useState(false);
    const closeModal = () => {
    setOpen(false);
    };
    const toastId = useRef(null);
    const [ page, setPage ] = useState(1);
    const [ pages, setPages ] = useState(0);
    const [ approvedList, setApprovedList ] = useState(); 
    const pageSize = 10 ;
    const [ formData, setFormData ] = useState({
      notes :""
    });
    const [ selectedDate, setSelectedDate ] = useState(null);
    const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
    const handleDateChange = (date) => {
    setSelectedDate(date);
    setError((prevError) => ({
      ...prevError,
      errorPaymentDate: ''
    }));
    };

    const [ error, setError ] = useState({
      errorNotes:'',
      errorPaymentDate: ''
    });
    useEffect(() => {
      if(allApproveToExpense) {
        let totalPages = Math.ceil(allApproveToExpense.length /pageSize);
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allApproveToExpense?.slice(firstPageIndex, lastPageIndex);
        setApprovedList(data);
        setPages(totalPages);
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ allApproveToExpense ]);
    const currentTableData = useMemo(() => {
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allApproveToExpense?.slice(firstPageIndex, lastPageIndex);
      setApprovedList(data);
    
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);
  
    const decrementPage = () => {
      const lastPageIndex = (page-1) * pageSize;
      const firstPageIndex = lastPageIndex - pageSize;
      const data = allApproveToExpense?.slice( lastPageIndex, firstPageIndex);
      setApprovedList(data);
    };

    const handlerChange = (e) => {
      e.preventDefault();
      const {name} = e.target;
      if (name === 'notes') {
          if(name === '') {
            setError({...error, ['errorNotes '] : 'Please enter the notes'});
          } else {
            setError({...error, ['errorNotes'] : ''});
          }
        }
      setFormData({...formData, [name]: e.target.value});
  };

  const validForm = () => {
      const { notes } = formData;
      let isFormValid = true ;
      if(notes === '') {
          setError((errorValue) => { 
          return {
            ...errorValue,
            errorNotes: 'Please enter notes '
          };
        });
        isFormValid = false;
      } 
      if (selectedDate === null) {
        setError((errorValue) => ({
          ...errorValue,
          errorPaymentDate: 'Please select a payment date'
        }));
        isFormValid = false;
      }
      return isFormValid;
  };
  useEffect(() => {
    if(isLoadingStatus){
      toastId.current = toast.loading("Please wait...", {
        // position: "top-center",
        position:"top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
    }
    if(isSuccessStatus) {
      toast.dismiss(toastId.current);
      toast.update(
        toastId.current,
        ("Expense approved successfully!",
        {
          render: "Expense approved successfully!",
          type: "success",
          autoClose: 8000,
          isLoading: false
        })
      );
      let payload = `/expenses/filter/search/?expense_approver=${employeeId}&page=1&limit=100`;
      employeeExpenseApprover(payload);
    }
    if (isErrorStatus) {
      toast.dismiss(toastId.current);
      const toastMessage = 
         "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          isLoading: false
        })
      );
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessStatus, isErrorStatus, isLoadingStatus ]);
    const  onHandlerPaid = (e, expense, close) => {
      e.preventDefault();
    if(validForm()) {
      const value = {
        status: '2',
        employee_id: `${expense?.employee_id}`,
        notes:formData?.notes,
        id: expense?.id,
        payment_date: selectedDate
    };
    changesStatusExpenseApporve(value);
    setFormData("");
    close();
    } 
    };
  return (
    <div>
      <div className="overflow-x-auto w-full whitespace-nowrap">
                <table className="table-auto table  w-full">
                  <thead className="thead-dark ">
                    <tr>
                      <th className="w-40"> Employee</th>
                      <th>Expenses Type</th>
                      <th>Date Applied</th>
                      <th>Date of Spend</th>                     
                      <th>Amount</th>                      
                      <th>Attachments</th>
                      <th>Approved By</th>
                      <th>Date of Approval</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {expenseLoading ?<LoadingIndicator/> :
                  <tbody>
                  {allApproveToExpense?.length > 0 ?                      
                  <>
                  {approvedList && approvedList.map((expense, index) => {
                    const fileName = expense?.expenseFiles[0]?.file_name;
                     const handleImageClick = () => {
                      // Check if fileName exists
                      if (fileName) {
                        // Create a link element
                        const downloadLink = document.createElement('a');
                        // Set the href attribute to the file path
                        downloadLink.href = fileName;
                        // Set the download attribute with the desired file name
                        downloadLink.download = 'your_desired_file_name.extension';
                        // Append the link to the document
                        document.body.appendChild(downloadLink);
                        // Trigger a click on the link element
                        downloadLink.click();
                        // Remove the link from the document
                        document.body.removeChild(downloadLink);
                      }
                     };
                    return (
                    <tr key={index}>
                      <td>
                        <Link
                          to={`/employees/detail/${expense?.employee_id}`}
                          className="listImage flex items-center "
                        >
                          {`${expense?.employeeInfo?.first_name} ${expense?.employeeInfo?.last_name}`}
                        </Link>
                      </td>
                      <td>{expense?.expenseTypeInfo?.expense_name}</td>
                      <td>{moments(expense?.createdAt).format(formattedDate)}</td>
                      <td>{moments(expense?.start_date).format(formattedDate)}</td>                      
                      <td>£{expense?.amount}</td>            
                      <td>
                        {fileName ? (
                          <div>
                            <img
                              src={fileName}
                              alt="Expense Image"
                              onClick={handleImageClick}
                              style={{ cursor: 'pointer', width: '80px', height: '44px', border: '1px solid grey', objectFit:'contain'}}
                            />
                            <p className="font-semibold">Download</p>
                          </div>
                        ) : (
                          "No File"
                        )}
                      </td>
                      <td>{expense?.approvedByInfo?.first_name}</td>
                      <td>{moments(expense?.approved_at).format(formattedDate)}</td>

                      <td>
                      <Popup
                          open={open}
                          onClose={() => closeModal()}
                          
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa fa-eye" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="expensePayClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <ExpenseDetails expense={expense} fileName={fileName} handleImageClick={handleImageClick} />
                            </div>
                          )}
                        </Popup>
                      
                      <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn payBtn"
                            >
                              <i className="fa-solid fa-check " /><span className=" font-semibold "> Pay</span>
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="expenseConfirmPayment"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <div className="header">
                                {" "}
                                <h3>Confirm payment for the expense</h3>
                              </div>
                              <div className="content">
                                <div className="form-group mb-6 ">
                                {/* <label className="formBlock	">Select Date</label> */}
                                  <DatePicker
                                    selected={selectedDate}
                                    id="confirmPaymentDate"
                                    onChange={handleDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    showYearDropdown
                                    placeholderText="Confirm payment date"
                                    scrollableYearDropdown
                                    className="formControl"
                                  />
                                </div>
                                <div className="text-red-700">{error?.errorPaymentDate}</div>
                                <label className="formBlock	mt-2">Notes</label>
                                <FormTextarea textName="notes" handlerChange = {handlerChange} inputID="notes"/>
                                <div className="text-red-700 mt-2">{error?.errorNotes}</div>
                                <div className="w-full mt-8">
                                  <div className="form-group pt-4 pb-3 flex justify-center ">
                                    <button
                                      type="submit"
                                      className="btn save mr-2"
                                      onClick={(e) => onHandlerPaid(e, expense, close)}
                                      id="confirmSave"
                                    >
                                      Submit
                                    </button>
                                    <PrimaryButton
                                      btnText="Cancel"
                                      Btnclass="btn cancel"
                                      btntype="button"
                                      onClick={() => close()}
                                      id="confirmCancel"
                                    />
                                  </div>
                                </div>
                              </div>
                           </div>
                         )}
                       </Popup> 
                      
 
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn cancelBtn"
                            >
                              <i className="fa-solid fa-xmark" />
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="declineClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <ExpenseCancel expense={expense}
                                employeeExpenseApprover={ employeeExpenseApprover}
                                employeeId={employeeId}
                                close={close}
                              />
                            </div>
                          )}
                        </Popup>
                      </td>
                    </tr>
                    );
                    })}
                    </> : 
                    <center className="mt-4">{<NoDataFound/>}</center>}
                    </tbody>}
                </table>
                <Pagination page={page} pages={pages} changePage={setPage} decrementPage={setPage}/>
              </div>
    </div>
  );
};

export default ApprovedExpensesToPay;
