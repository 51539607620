import { useState } from "react";
import FullCalendar2 from "./fullCalendar";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import moment from "moment";
import NoDataFoundLeave from "./noDataFoundLeave";
import { useSelector } from "react-redux";

const CalendarViewToggle = ({
  setStartDate,
  setSelectedYearCalendar,
  setEndDate,
  employeeDataResponse,
  getEmployeeAllDayScheduleRespo,
  filterEmployeeById,
  employeeData,
  allHolidayData,
  isLoading,
  employeeDetailsViewRespo,
  formData,
  nonDeductLeave,
  roleIdData,
  setToggleState2
}) => {

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const year = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.nextcarryForward
  );
  const formatYear = (inputYear) => {
    const dateObject = new Date(inputYear);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"  
    ];

    const currentMonth = dateObject.getMonth();
    const currentYear = dateObject.getFullYear();

    const newDate = new Date(currentYear, currentMonth - 11);

    const formattedYear = `${monthNames[currentMonth]} ${currentYear}`;
    const formattedNextYear = `${
      monthNames[newDate.getMonth()]
    } ${newDate.getFullYear()}`;

    return [formattedYear, formattedNextYear];
  };

  const [formattedYear, formattedNextYear] = formatYear(year);

  const { id } = useParams();
  const handleDateChange = (info) => {
    setStartDate(new Date(info.start));
    setEndDate(new Date(info.end));
  };

  return (
    <>
      <div className=" p-4">
        <div className="flex justify-between flex-wrap">
          <div className="lg:w-3/4 w-full lg:mb-0 mb-6 lg:pr-3">
            <FullCalendar2
              setOpen={setOpen}
              leaveData={employeeDataResponse?.data[0]?.leaveInfo}
              getEmployeeAllDayScheduleRespo={getEmployeeAllDayScheduleRespo}
              handleDateChange={handleDateChange}
              filterEmployeeById={filterEmployeeById}
              employeeId={id ? id : employeeData?.employee_id}
              allHolidayData={allHolidayData}
              workSchedule={employeeDataResponse?.data[0]?.workScheduleInfo}
              isLoading={isLoading}
              id={id}
              roleId={employeeData?.roleId}
              employeeStartDate={
                employeeDataResponse?.data[0]?.employment_start_date
              }
              dateOfBirth={employeeDataResponse?.data[0]?.dob}
              setSelectedYearCalendar={setSelectedYearCalendar}
              roleIdData={roleIdData}
            />
          </div>
          <div className="lg:w-1/4 w-full">
            <div className="card p-3 md:ml-2 ml-0 calendarContent">
              <h3 className="font-semibold pl-2">
                Holiday Year {formattedNextYear} - {formattedYear}{" "}
              </h3>

              <div className="overflow-x-auto w-full whitespace-nowrap daysRemaning mt-2">
                <table className="table-auto table  w-full">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>
                        {employeeDetailsViewRespo?.data?.hours_or_day === "1"
                          ? "Hours"
                          : "Days"}{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Brought forward</td>
                      <td>{formData?.Brought_forward}</td>
                    </tr>
                    <tr>
                      <td> Allowance</td>
                      <td>{formData?.Allowance}</td>
                    </tr>
                      <td>
                        <div className="flex">
                          <span className="text-base font-medium">Total </span>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <span className="text-xl font-bold">
                          {Math.round(formData?.total * 100) / 100}
                        </span>
                      </td>
                    {/* </tr> */}
                    <tr>
                      <td>
                        <button onClick={() => setToggleState2("leaveRequest")}>
                          Leave Booked + Taken
                        </button>
                      </td>
                      <td>{formData?.leave_taken}</td>
                    </tr>
                    <tr className="py-4 bg-yellow-Elight ">
                      <td>
                        <div className="flex">
                          <span className="text-base font-medium">
                            Leave remaining{" "}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className="text-xl font-bold">
                          {formData?.leave_remaining}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card p-3 md:ml-2 ml-0 calendarContent mt-5">
              <div className="overflow-x-auto w-full whitespace-nowrap daysRemaning ">
                <table className="table-auto table  w-full">
                  <tbody>
                    <tr className="py-4 ">
                      <td>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <Link
                              className="underline"
                              onClick={() => setOpen((o) => !o)}
                            >
                              Leave not deducted
                            </Link>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="leaveNotDeductedClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <LeaveNonDeduct
                                nonDeductLeave={nonDeductLeave}
                                getEmployeeAllDayScheduleRespo={
                                  getEmployeeAllDayScheduleRespo
                                }
                              />
                            </div>
                          )}
                        </Popup>
                      </td>
                      <td>
                        <span className="pr-4">
                          {employeeDetailsViewRespo?.leaveNotDeducted
                            ? employeeDetailsViewRespo?.leaveNotDeducted.toFixed(
                                2
                              )
                            : 0}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card p-3 md:ml-2 ml-0 calendarContent">
              <div className="overflow-x-auto w-full whitespace-nowrap daysRemaning ">
                <table className="table-auto table  w-full">
                  <tbody>
                    <tr className="py-4 "> 
                      <td>
                        <h2>Time In Lieu - Current Year</h2>
                      </td>
                      </tr>
                      <tr className="py-4">
                        <td>Authorised</td>
                        <td>{employeeDetailsViewRespo?.timeInLieuCurrentYearDetails?.totalDurationInHours ? `${employeeDetailsViewRespo?.timeInLieuCurrentYearDetails?.totalDurationInHours.toFixed(2)} hours` : 0 }</td>
                      </tr>
                      <tr className="py-4">
                        <td>Taken</td>
                        <td>{employeeDetailsViewRespo?.timeInLieuCurrentYearDetails?.timeInLieuUsedInHours ? `${employeeDetailsViewRespo?.timeInLieuCurrentYearDetails?.timeInLieuUsedInHours.toFixed(2)} hours` : 0 }</td>
                      </tr>
                      <tr className="py-4">
                        <td>Balance</td>
                        <td>{employeeDetailsViewRespo?.timeInLieuCurrentYearDetails?.remainingTimeInLieu ? `${employeeDetailsViewRespo?.timeInLieuCurrentYearDetails?.remainingTimeInLieu.toFixed(2)} hours` : 0 }</td>
                      </tr>
                      </tbody>
                      </table>
                      </div>
             </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarViewToggle;

const LeaveNonDeduct = ({ nonDeductLeave }) => {
  return (
    <>
      <div className="header">
        <h3>Leave not deducted from Allowance</h3>
      </div>
      <div className="content">
        <div className="overflow-x-auto w-full whitespace-nowrap">
          <table className="table-auto table w-full">
            <thead className="thead-dark">
              <tr>
                <th className="w-40">Start Date</th>
                <th>End Date</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              {nonDeductLeave.length > 0 ? (
                nonDeductLeave.map((leave, index) => (
                  <tr key={index}>
                    <td>{moment(leave?.start_date).format("DD-MM-YYYY")}</td>
                    <td>{moment(leave?.end_date).format("DD-MM-YYYY")}</td>
                    <td>
                      {leave.total_duration / 60 <= 1
                        ? `${leave.total_duration / 60} Hour`
                        : `${leave.total_duration / 60} Hours`}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <NoDataFoundLeave />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

