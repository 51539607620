/* eslint-disable camelcase */
import { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "react-datepicker/dist/react-datepicker.css";
import { SearchField } from "../../components/filters";
import { FormSelect } from "../../components/formInput";
import moments from "moment";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import Pagination from "../../components/inc/pagination";
import { useMemo } from "react";
import NoDataFound from "../../helper/noDataFound";
import { LoadingIndicator } from "../../components/loading-indicator";
import { useGetLeaveTypeListMutation } from "../../services/leaveTypesApi";
import { useDispatch, useSelector } from "react-redux";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { leaveTypeInfo } from "../../redux/LeaveTypeSlice/leaveListSlice";
import { useGetAllLeaveAbsenceMutation } from "../../services/leaveAbsenceApi";

const LeaveReport = () => {
  const ignore = useRef(false);
  /* states are defined here */
  const [ allDepartmentListData, setAllDepartmentListData ]  = useState();
  const [ allLeaveInfo, setAllLeaveInfo ]  = useState();
  const [ excelData, setExcelData ]  = useState([  ] );
  const [ page, setPage ]  = useState(1);
  const [ pages, setPages ]  = useState(0);

  const dispatch = useDispatch();
  const pageSize = 10;
  const createCsvFileName = () => `Leave_report_${moments().format()}.csv`;

  /* api calling */
  const [ getLeaveTypeList, { data: allLeaveApiResponse } ]  =useGetLeaveTypeListMutation();
  const [ getAllLeaveAbsence, { data: allLeaveResponseList, isSuccess: isSuccessLeave, isLoading } ]  = useGetAllLeaveAbsenceMutation();
  const [ getDepartmentList, { data: departmentApiResponse, isSuccess: isSuccessDepartmentApi } ]  = useGetDepartmentListMutation();
  
  /* carrying out data from redux */
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);
  const allLeaveInfoReponse = useSelector((state) => state?.persistedReducer?.leaveListSlice?.leaveList);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);

  useEffect(() => {
    if (!ignore.current){
      let payload = `/leaves?type=${1}&page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&limit=100&group=true`;
      getAllLeaveAbsence(payload);
      getDepartmentList();
      getLeaveTypeList();
    }
    return () => { ignore.current = true; };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [  ] 
  );

  useEffect(() => {
      if (isSuccessLeave) {
        dispatch(leaveTypeInfo(allLeaveResponseList));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessLeave ] 
  );

  useEffect(() => {
      if (allLeaveInfoReponse) {
        setAllLeaveInfo(allLeaveInfoReponse?.data);
      }
      if (allLeaveInfoReponse?.data) {
        let csvData = exportTOCSV(allLeaveInfoReponse?.data);
        setExcelData(csvData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allLeaveInfoReponse ] 
  );

  useEffect(() => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessDepartmentApi ] 
  );

  useEffect(() => {
      if (departmentApiData) {
        setAllDepartmentListData(departmentApiData?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ departmentApiData ] 
  );

  useEffect(() => {
      if (allLeaveInfoReponse?.data) {
        let totalPages = Math.ceil(
          allLeaveInfoReponse?.data?.length / pageSize
        );
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        const data = allLeaveInfoReponse?.data?.slice(
          firstPageIndex,
          lastPageIndex
        );
        setAllLeaveInfo(data);
        setPages(totalPages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allLeaveInfoReponse?.data ] 
  );

  const filterEmployee = useCallback(
    (searchTerm) => {
      let payload = `/leaves?type=${1}&page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&first_name=${searchTerm}&limit=100&group=true`;
      getAllLeaveAbsence(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [  ] 
  );

  // eslint-disable-next-line no-unused-vars
  const currentTableData = useMemo(() => {
      const firstPageIndex = (page - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      const data = allLeaveInfoReponse?.data?.slice(
        firstPageIndex,
        lastPageIndex
      );
      setAllLeaveInfo(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ page ] 
  );

  // eslint-disable-next-line no-unused-vars
  const decrementPage = () => {
    const lastPageIndex = (page - 1) * pageSize;
    const firstPageIndex = lastPageIndex - pageSize;
    const data = allLeaveInfoReponse?.data?.slice(
      lastPageIndex,
      firstPageIndex
    );
    setAllLeaveInfo(data);
  };

  const handlerChangeByDeptName = (e) => {
    e.preventDefault();
    if (e.target.value === "0") {
      let payload = `/leaves?type=${1}&page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&limit=100&group=true`;
      getAllLeaveAbsence(payload);
    } else {
      let payload = `/leaves?type=${1}&page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&department_id=${e.target.value}&limit=100&group=true`;
      getAllLeaveAbsence(payload);
    }
  };

  const handlerChangeByleaveType = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      let payload = `/leaves?type=${1}&page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&limit=100&group=true`;
      getAllLeaveAbsence(payload);
    } else {
      let payload = `/leaves?type=${1}&page=${page}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&leave_type=${e.target.value}&limit=100&group=true`;
      getAllLeaveAbsence(payload);
    }
  };

  /* export to CSV */
  let headers = [ 
    { label: "Employee Name", key: "employeeName" },
    { label: "Department", key: "department" },
    { label: "Approver", key: "approver" },
    { label: "Leave type", key: "leave_type" },
    { label: "Date To", key: "dateTo" },
    { label: "Date From", key: "dateFrom" },
    { label: "Total Duration", key: "totalDuration" }
   ] ;
  const exportTOCSV = (report) => {
    let dataAbence = [  ] ;
    if (report) {
      report?.forEach((dExcel) => {
        let val = {
          employeeName: dExcel?.employeeInfo?.first_name,
          department: dExcel?.departmentInfo?.department_name,
          approver: `${dExcel?.approvedByInfo?.first_name} ${dExcel?.approvedByInfo?.last_name}`,
          leave_type: dExcel?.leaveTypeInfo?.leave_name,
          dateFrom: moments(dExcel?.leave_start_date).format(formattedDate),
          dateTo: moments(dExcel?.leave_end_date).format(formattedDate),
          totalDuration: `${dExcel?.totalDuration / 60} ${"Hours"}`
        };
        dataAbence.push(val);
      });
      return dataAbence;
    }
  };

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Leave Report </h2>
              <div className="card ">
                <div className="p-4 ">
                  <div className="headingContent  pt-0 w-full">
                    <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                      <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 ">
                        <SearchField
                          placeholderText="Search By Name"
                          searchId="leaveReportSearch"
                          onChange={(e) => filterEmployee(e.target.value)}
                        />
                      </div>
                      <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-2 md:pr-4">
                        <select
                          className="formControl"
                          id="leaveReportDepartment"
                          name="department_name"
                          onChange={(e) => handlerChangeByDeptName(e)}
                        >
                          <FormSelect
                            optionValue={"0"}
                            optionName={"Select Department"}
                          />
                          ;
                          {allDepartmentListData?.length > 0
                            ? allDepartmentListData?.map((departments, key) => {
                                return (
                                  <FormSelect
                                    key={key}
                                    optionValue={departments?.id}
                                    optionName={departments?.department_name}
                                  />
                                );
                              })
                            : null}
                        </select>
                      </div>
                      <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-4 lg:pr-0">
                        <select
                          className="formControl"
                          id="leaveReportLeaveType"
                          name="department_name"
                          onChange={(e) => handlerChangeByleaveType(e)}
                        >
                          <FormSelect
                            optionValue={""}
                            optionName={"Select Leave Type"}
                          />
                          ;
                          {allLeaveApiResponse?.leaveType?.rows
                            ? allLeaveApiResponse?.leaveType?.rows?.map(
                                (leave, key) => {
                                  return (
                                    <FormSelect
                                      key={key}
                                      optionValue={leave?.id}
                                      optionName={leave?.leave_name}
                                    />
                                  );
                                }
                              )
                            : null}
                        </select>
                      </div>
                      <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full ml-[150px]">
                        <CSVLink
                          className=""
                          data={excelData}
                          headers={headers}
                          filename={createCsvFileName()}
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            outline: "none",
                            height: "5vh"
                          }}
                        >
                          <button
                            className="btn-md btn-add "
                            id="leaveReportCsv"
                          >
                            <span className="fa fa-file-export mr-2" /> Export
                            to CSV
                          </button>
                        </CSVLink>
                      </div>
                    </div>
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                      <table className="table-auto table  w-full">
                        <thead className="thead-dark ">
                          <tr>
                            <th className="w-40"> Name</th>
                            <th>Department</th>
                            <th>Approver</th>
                            <th>Leave Type</th>
                            <th>Date From</th>
                            <th>Date To</th>
                            <th>Total Duration</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <LoadingIndicator />
                        ) : (
                          <tbody>
                            {allLeaveInfo?.length > 0 ? (
                              <>
                                {allLeaveInfo &&
                                  allLeaveInfo.map((leave, index) => {
                                    const leaveTotalDurationInHours = leave?.leave_total_duration / 60; 
                                    const leaveTotalDurationInDays = leaveTotalDurationInHours / leave?.hoursInDays;
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <Link
                                            to="/employees/detail"
                                            class="listImage flex items-center "
                                          >
                                            {leave?.employeeInfo?.first_name +
                                              " " +
                                              leave?.employeeInfo?.last_name}
                                          </Link>
                                        </td>
                                        <td>
                                          {
                                            leave?.departmentInfo
                                              ?.department_name
                                          }
                                        </td>
                                        <td>
                                          {leave?.approvedByInfo?.first_name}{" "}{leave?.approvedByInfo?.last_name}
                                        </td>
                                        <td>
                                          {leave?.leaveTypeInfo?.leave_name}
                                        </td>
                                        <td>{moments(leave?.leave_start_date).format(formattedDate)}</td>
                                        <td>{moments(leave?.leave_end_date).format(formattedDate)}</td>
                                        <td>{leave?.employeeInfo?.hours_or_day === "2" ? `${leaveTotalDurationInDays.toFixed(2)} ${"Days"}` : `${(leave?.leave_total_duration/60).toFixed(2)} ${"Hours"}`}</td>
                                      </tr>
                                    );
                                  })}
                              </>
                            ) : (
                              <center className="mt-4">
                                {<NoDataFound />}
                              </center>
                            )}
                          </tbody>
                        )}
                      </table>
                      <Pagination
                        page={page}
                        pages={pages}
                        changePage={setPage}
                        decrementPage={setPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveReport;
