/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger */
import React, {useState, useRef, useEffect} from "react";
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import {
    FormSelect,
    FormTextarea
  } from "../../components/formInput";
  import PrimaryButton from "../../components/primaryButton";
  import DatePicker from "react-datepicker";
  import {useUpdateLeaveAbsenceMutation, useLeaveByDateRangeMutation} from "../../services/leaveAbsenceApi";
  import { toast } from "react-toastify";
  import { useFilterEmployeeDepartmentMutation, useGetAllEmployeeListMutation, useGetEmployeeAllDaysScheduleMutation} from "../../services/employeeApi";
  import { getDay}  from 'date-fns';
  import moment from 'moment/moment';
  import { useGetLeaveTypeListMutation } from "../../services/leaveTypesApi";
import { allEmployeeListDataAction, employeeScheduleInfoAction } from "../../redux/EmployeeSlice/employeeSlice";
import { allLeaveDataAction } from "../../redux/LeaveTypeSlice/leaveSlice";


  const EditAbsence = ({absence, allDepartmentListData, getAllLeaveAbsence, page, close, setAllEmployeeData, shouldShowReason}) => {
   
    const ignore = useRef(false);

    const getEmployeeAllDayScheduleRespo = useSelector((state) => state?.persistedReducer?.employeeSlice?.employeeScheduleInfo);
    const allLeaveApiResponse = useSelector((state) => state?.persistedReducer?.leaveTypeSlice?.allLeaveData);

    const [ getLeaveTypeList, {data: allLeaveApiResponseList, isSuccess: isSuccessLeave} ] = useGetLeaveTypeListMutation();
    const [ workingHours, setWorkingHours ] = useState(0);
    const [ open, setOpen ] = useState(false);
    const dispatch = useDispatch();
    const [ updateLeaveAbsence, {isSuccess, isLoading, isError, error:apiError} ] = useUpdateLeaveAbsenceMutation();
    const [ leaveByDateRange, { data: leaveByDateRangeData, isSuccess: leaveByDateRangeDataSuccess, isError:leaveByDateRangeDataError, error: leaveError  } ] = useLeaveByDateRangeMutation();
    const [ filterEmployeeDepartment, {data: filterDepartmentApi, isSuccess: isSuccessFilterDepartment} ] = useFilterEmployeeDepartmentMutation();
    const [ getAllEmployeeList, {data:filterEmployeeResponseData, isSuccess:isSuccessAllEmployee} ] = useGetAllEmployeeListMutation();
    const [ getEmployeeAllDaysSchedule, {data: EmployeeAllDayScheduleRespo, isSuccess: isSuccessSchedule} ] = useGetEmployeeAllDaysScheduleMutation(); 
    const [ employeeSchedule, setEmployeeSchedule ] = useState();
    const [ endTimeSlots, setEndTimeSlots ] = useState();
    const [ employeeSlots, setEmployeeSlots ] = useState();
    const [ employeeId, setEmployeeId ] = useState();
    const [ employeeSlotsend, setEmployeeSlotsEnd ] = useState([]);
    const defaultStartDate = new Date(absence?.start_date);
    const defaultEndDate = new Date(absence?.end_date);
    const [ dateRange, setDateRange ] = useState([ defaultStartDate, defaultEndDate ]);
    const [ startDate, endDate ] = dateRange;
    const [ startTime, setStartTime ] = useState("");
    const [ endTime, setEndTime ] = useState("");
    const [ leaveDateRange, setLeaveDateRange ] = useState();
    const [ startNewTime, setNewStartTime ] = useState();
    const [ endNewTime, setNewEndTiming ] = useState();
    const [ selectedTimes, setSelectedTimes ] = useState({});
    const [ hourOrDay, setHourOrDay ] = useState(null);
    const [ selectedTimeType, setSelectedTimeType ] = useState([]);
    const [ startTimingSchedule, setStartTimingSchedule ] = useState();
    const [ endTimingSchedule, setEndTimingSchedule ] = useState();
   const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
   const [ selectedTimeData, setSelectedTimeData ] = useState([
     {
       employee_id: `${employeeId}`,
       date: "",
       end_date: "",
       leave_days: "",
       start_time:""
     }
   ]);
 
   useEffect(() => {
    if (!ignore.current){
     getLeaveTypeList();
     getAllEmployeeList();
     getEmployeeAllDaysSchedule(employeeIdData?.employee_id);
    }
    return () => { ignore.current = true; };
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);
useEffect(() => {
  if(isSuccessAllEmployee){
     dispatch(allEmployeeListDataAction(filterEmployeeResponseData));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessAllEmployee ]);
 
    const [ formData, setFormData ] =  useState({
     department_id: "",
     employee_id: "",
     leave_type: "64",
     start_date: '',
     start_time: "",
     end_time: "",
     end_date: '',
     reason: "",
     type: "2",
     notes: ''
     });

     const closeModal = () => {
      close();
    };

    const [ reason, setReason ] = useState("");

    const [ error, setError ] = useState({
     errorDeprtment:'',
     errorEmployee: '',
     errornotes:'',
     errorEndDate: '',
     errorReason: ''
     });
    useEffect(() => {
      getEmployeeAllDaysSchedule(absence.employee_id);
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ absence ]);
useEffect(() => {
  if(isSuccessSchedule){
    dispatch(employeeScheduleInfoAction(EmployeeAllDayScheduleRespo));
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessSchedule ]);

    useEffect(() => {
      const scheduleData = [];
      if(getEmployeeAllDayScheduleRespo){
        getEmployeeAllDayScheduleRespo?.data?.length > 0 && getEmployeeAllDayScheduleRespo?.data?.forEach((schedule) => {
          let Data = {
            day:schedule.day,
            is_weekend: schedule?.is_weekend,
            start_time: schedule?.startTimeWithDummyDate,
            end_time: schedule?.endTimeWithDummyDate
  
          };
          scheduleData.push(Data);
        });
        setEmployeeSchedule(scheduleData);
      }
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ getEmployeeAllDayScheduleRespo ]);

     useEffect(() => {
      if(isSuccessFilterDepartment){
         
      setAllEmployeeData(filterDepartmentApi.data);
      }
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessFilterDepartment ]);


  const isWeekday = (date) => {
    const availableDays = [];
    const day = getDay(date);
    employeeSchedule && employeeSchedule.forEach((days) => {
      if(days.day === '7') {
        days.day= 0;
        if(days.is_weekend == 0) { 
          availableDays.push(parseInt(days.day));
        }
       } else {
        if(days.is_weekend === 0) { 
          availableDays.push(parseInt(days.day));
        }
      }
    });
    return availableDays.includes(day);
  
  };
 
  useEffect(() => {
    let slots =[];
  
    let endSlots = [];
    
   if(employeeSchedule){
    let dateDaySlots = employeeSchedule?.find((f) => parseInt(f.day)  === getDay(new Date()));
   
    let start= moment(dateDaySlots?.start_time).format('HH:mm');
    let end = moment(dateDaySlots?.end_time).format('HH:mm');
    
    slots.push('Select');
      slots.push("Morning");
      slots.push("Afternoon");
      slots.push('---------');
      endSlots.push('Select');
      endSlots.push('Lunch');
      endSlots.push('EOD');
    let  slotInterval =15;
    while (start <= end) {
      slots.push(start);
      endSlots.push(start);
      start = moment(start, 'HH:mm').add(slotInterval, 'minutes').format('HH:mm');
    }
    
  
  
    setEmployeeSlots(slots);
    setEndTimeSlots(endSlots);
  }
    
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ employeeSchedule ]);


  useEffect(() => {
    if(employeeSlots) {
    employeeSlots.forEach((f, index) => {
        if(f === absence?.time_field1) {
          setStartTime(index);
        } else if(f == absence.start_time && absence?.time_field1 === '') {
          setStartTime(index);

        }
        
      });
    }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ employeeSlots ]);

    
    let endTimeData = [
      "Select",
      "Lunch",
      "End of Day"
    ];
    let endNewTimeData = [
      "Select",
      "End of Day"
    ];


  const toastId = useRef(null);

  useEffect(() => {
    if (isLoading) {
      toastId.current = toast.loading("Please wait...", {
      // position: "top-center",
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      type: "info"
    });
    }
    if (isSuccess) {
      toast.dismiss(toastId.current);
      toast.success(" Absence updated successfully!", {
        render:" Absence updated successfully!",
             type: "success",
             autoClose: 8000,
           isLoading: false,
           theme: "colored"
      });
      let payload=`/leaves?type=${2}&page=${page}`;
      getAllLeaveAbsence(payload);
      close();
    }
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage = apiError?.data?.message
        ? apiError?.data?.message
        : "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 8000,
                 duplicate: false,
                 isLoading: false
          });

    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);

  
  useEffect(() => {
    if (leaveByDateRangeDataSuccess) {
      setLeaveDateRange(leaveByDateRangeData?.data);
      setHourOrDay(leaveByDateRangeData?.hoursOrDay === 'inHour' ? 'Hours' : 'Days');
      setWorkingHours(leaveByDateRangeData?.hoursInDays);
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveByDateRangeDataSuccess ]);


  useEffect(() => {
    if (leaveByDateRangeDataError) {
      toast.dismiss(toastId.current);
      const toastMessage = leaveError?.data?.message
        ? leaveError?.data?.message
        : "Something went wrong";
          toast.error(toastMessage, {
            render:toastMessage,
                 type: "error",
                 autoClose: 8000,
                 duplicate: false,
                 isLoading: false
          });
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveByDateRangeDataError ]);

useEffect(() => {
  if(isSuccessLeave){
    dispatch(allLeaveDataAction(allLeaveApiResponseList));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessLeave ]);

  useEffect(() => {
    if(allLeaveApiResponse) {
      let deduct = [];
      deduct.push({id : '',
    leave_name:'Deductible',
    isDisabled: true,
    icon: 'Nondeductible'}
    );
    allLeaveApiResponse?.leaveType?.rows.forEach((leave) => {
      if(leave?.is_deductible === '1' && leave?.status === '1') {
       let value = {
          id : leave.id,
          leave_name:leave.leave_name,
          icon: leave.icon,
          is_deductible:1
        };
        deduct.push(value);
      }
     
    });
    deduct.push({id : '',
    leave_name:'Non-Deductible',
    isDisabled: true,
    icon: 'Nondeductible'}
    );
    allLeaveApiResponse?.leaveType?.rows.forEach((leave) => {
      if(leave?.is_deductible === '0' && leave?.status === '1') {
      let value = {
        id : leave.id,
        leave_name:leave.leave_name,
        icon: leave.icon,
        is_deductible:0
      };
      deduct.push(value);
    }
     
    });
     
    }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ allLeaveApiResponse ]);
       
  useEffect(() => {
    if(isSuccessFilterDepartment){
      const arr = [];
      filterDepartmentApi.data.length > 0 && filterDepartmentApi.data.forEach((emp) => {
        let empOption = {
          value: emp?.id,
          label: `${emp?.first_name} ${emp?.last_name}`
        };
        arr.push(empOption);
      });
    }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessFilterDepartment ]);
    const scheduleData = [];

    useEffect(() => {
      if(getEmployeeAllDayScheduleRespo) {
        getEmployeeAllDayScheduleRespo?.data?.length > 0 && getEmployeeAllDayScheduleRespo?.data?.forEach((schedule) => {
          let Data = {
            day:schedule.day,
            is_weekend: schedule?.is_weekend,
            start_time: schedule?.startTimeWithDummyDate,
            end_time: schedule?.endTimeWithDummyDate,
            break:schedule?.break,
            daysHours: getEmployeeAllDayScheduleRespo?.dayHours
          };
          scheduleData.push(Data);
        });
        setEmployeeSchedule(scheduleData);
      }
     }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ getEmployeeAllDayScheduleRespo ]);
   
   useEffect(() => {
    let slots =[];
    slots.push('Select');
    slots.push('Morning');
    slots.push('Afternoon');
    slots.push('.......');
    if(employeeSchedule){
    let dateDaySlots = employeeSchedule?.find((f) => parseInt(f.day)  === getDay(new Date()));
    let start= moment(dateDaySlots?.start_time).format('HH:mm');
    let end = moment(dateDaySlots?.end_time).format('HH:mm');
    setEndTimingSchedule(end);
    setStartTimingSchedule(start);
   
    let endTime = [];
   
    let  slotInterval =15;
    while (start <= end) {
      slots.push(start);
      endTime.push(start);
      start = moment(start, 'HH:mm').add(slotInterval, 'minutes').format('HH:mm');
    }
    setEmployeeSlots(slots);
    setEndTimeSlots(endTime);
  }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveByDateRangeDataSuccess ]);

   useEffect(() => {
    let slots =[];
    slots.push('Select');
    if(employeeSchedule){
    let dateDaySlots = employeeSchedule?.find((f) => parseInt(f.day)  === getDay(new Date()));
    let start= moment(dateDaySlots?.start_time).format('HH:mm');
    
    let end = moment(dateDaySlots?.end_time).format('HH:mm');
   
    let endTime = [];
   
    let  slotInterval =15;
    while (start <= end) {
      slots.push(start);
      endTime.push(start);
      start = moment(start, 'HH:mm').add(slotInterval, 'minutes').format('HH:mm');
    }
    setEmployeeSlotsEnd(slots);
    setEndTimeSlots(endTime);
  }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveByDateRangeDataSuccess ]);

   useEffect(() => {
    let totalDuration = 0;

    leaveByDateRangeData?.data?.forEach((entry) => {
      const startTime = new Date(`2000-01-01 ${entry?.start_time}`);
      const endTime = new Date(`2000-01-01 ${entry?.end_time}`);

      if (entry.is_weekend) {
        return; // skip weekends
      }

      const workDuration = endTime - startTime; 
      totalDuration += workDuration;
    });

    // const totalDurationInMinutes = totalDuration / (60 * 1000); // convert total duration to minutes
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ leaveByDateRangeData ]);

    
const handleStartTimeChange = (date, event, leave, index) => {

    
  const selectedStartTime = event.target.value;
  setNewStartTime(selectedStartTime);
  const newDuration = calculateDuration(selectedStartTime, endNewTime ? endNewTime : leave?.end_time, index); 

  const updatedSchedule = [ ...leaveDateRange ];
  
  updatedSchedule[index] = {
    break: updatedSchedule[index].break,
    break_paid: updatedSchedule[index].break_paid,
    date: updatedSchedule[index].date,
    day: updatedSchedule[index].day,
    end_time: updatedSchedule[index].end_time,
    hours_in_day: updatedSchedule[index]?.hours_in_day,
    has_leave_on_date: updatedSchedule[index].has_leave_on_date,
    holiday_name: updatedSchedule[index].holiday_name,
    is_weekend: updatedSchedule[index].is_weekend,
    location_holiday: updatedSchedule[index].location_holiday,
    start_time: updatedSchedule[index].start_time,
    ["duration"]: newDuration,
    ["leave_day"] : (newDuration / (updatedSchedule[index]?.hours_in_day * 60))?.toFixed(2)
  };

  // updatedSchedule[index]?.duration = newDuration;
  setLeaveDateRange(updatedSchedule);

  setSelectedTimeType((prevSelectedTimeType) => {
    const updatedTimeType = [ ...prevSelectedTimeType ];
    updatedTimeType[index] = { start_time: event.target.value };
    return updatedTimeType;
  });
  setSelectedTimeData((prevSelectedTimeData) => {
    const updatedTimeData = [ ...prevSelectedTimeData ];
    updatedTimeData[index] = {
      ["end_time"]:selectedTimeData[index]?.end_time ? selectedTimeData[index]?.end_time : endTimingSchedule,
      ["start_time"]:
        selectedStartTime === "Morning"
          ? startTimingSchedule
          : selectedStartTime === "Afternoon"
          ? moment(startTimingSchedule, "HH:mm").add(5, "hours").format("HH:mm")
          : selectedStartTime,
      ["date"]: date
    };

    return updatedTimeData;
  });
  if (selectedStartTime !== 'Morning' && selectedStartTime !== 'Afternoon') {
    setEndTime('Select');
  } else {
    let filteredEndTimes = [ 'Select' ];

    if (selectedStartTime === 'Morning') {
      filteredEndTimes.push('Lunch', 'EOD');
    } else if (selectedStartTime === 'Afternoon') {
      filteredEndTimes.push('EOD');
    }

    setEndTime('Select');
    setEndTimeSlots(filteredEndTimes);
  }
  const newSelectedTimes = { ...selectedTimes, [date]: { ...selectedTimes[date], start_time: event.target.value } };
  setSelectedTimes(newSelectedTimes);

  setLeaveDateRange((prevSchedule) => {
    return prevSchedule.map((item) => {
      if (item?.day === leave?.day) {
        return { ...item, start_time: startTime === "Morning" ? startTimingSchedule : startTime === "Afternoon" ? moment(startTimingSchedule).add(5, 'h').format('HH:mm') : startTime};
      }
      return item;
    });
  });
  setStartTime(selectedStartTime);
};

const handleEndTimeChange = (date, event, leave, index) => {
  const endTiming = event?.target?.value;
  setNewEndTiming(endTiming);
  const newDuration = calculateDuration(startNewTime ? startNewTime : leave?.start_time, endTiming, index); 

  const updatedSchedule = [ ...leaveDateRange ];
  
  updatedSchedule[index] = {
    break: updatedSchedule[index].break,
    break_paid: updatedSchedule[index].break_paid,
    date: updatedSchedule[index].date,
    day: updatedSchedule[index].day,
    end_time: updatedSchedule[index].end_time,
    hours_in_day: updatedSchedule[index]?.hours_in_day,
    has_leave_on_date: updatedSchedule[index].has_leave_on_date,
    holiday_name: updatedSchedule[index].holiday_name,
    is_weekend: updatedSchedule[index].is_weekend,
    location_holiday: updatedSchedule[index].location_holiday,
    start_time: updatedSchedule[index].start_time,
    ["duration"]: newDuration,
    ["leave_day"] : (newDuration / (updatedSchedule[index]?.hours_in_day * 60))?.toFixed(2)
  };

  // updatedSchedule[index]?.duration = newDuration;
  setLeaveDateRange(updatedSchedule);
  
  const newSelectedTimes = { ...selectedTimes, [date]: { ...selectedTimes[date], end_time: event.target.value } };
  // setSelectedTimeData([
  //   ...selectedTimeData,
  //  { end_time:
  //   event.target.value === "Lunch"
  //       ? moment(event.target.value).add(4, "h").format("HH:mm")
  //       : endTime === "EOD"
  //       ? endTimingSchedule
  //       :  event.target.value,
  //   start_date: moment(
  //     `${formData?.date} ${event.target.value}`,
  //     "YYYY-MM-DD"
  //   ).format("YYYY-MM-DD HH:mm")}
  //   ]);
    setSelectedTimeData((prevSelectedTimeData) => {
      const updatedTimeData = [ ...prevSelectedTimeData ];
      updatedTimeData[index] = {
        ["start_time"]:selectedTimeData[index]?.start_time ? selectedTimeData[index]?.start_time : startTimingSchedule,
        ["end_time"]:
           event.target.value === "Lunch"
        ? moment(startTimingSchedule, "HH:mm").add(4, "h").format("HH:mm")
        : event.target.value === "End of Day"
        ? endTimingSchedule
        :  event.target.value,
        ["date"]: date
      };
  
      return updatedTimeData;
    });
  setSelectedTimes(newSelectedTimes);
  setLeaveDateRange((prevSchedule) => {
    return prevSchedule.map((item) => {
      if (item?.day === leave?.day) {
        return { ...item, end_time: endTime === "Lunch" ? moment(endTimingSchedule, "HH:mm").add(4, 'h').format('HH:mm') : endTime === "EOD" ? endTimingSchedule : endTime };
      }
      return item;
    });
  });
};

  
  const handlerChange = (e) => {
   
    e.preventDefault();
    const{name} = e.target;
    if( name === 'department_id') {
      if ( name === 0 || name === '') {
        setError({...error, ['errorDepartmentId']: 'Please select EmployeeId'});
      } else {
        setError({...error, ['errorDepartmentId']: ''});
      }
    }
    if(name === 'reason'){
      setReason(e.target.value);
    }
   
    if( name === 'employee_id') {
      if ( name === 0 || name === '') {
        setError({...error, ['errorEmployeeId']: 'Please select EmployeeId'});
      } else {
        setError({...error, ['errorEmployeeId']: ''});
      }
      getEmployeeAllDaysSchedule(e.target.value);
      setEmployeeId(e.target.value);
    }
    setFormData((formValue) => {
        return {
         ...formValue, ['department_id']: `${e.value}`
        };
       }); 
    setFormData({...formData, [name]:e.target.value});
  
    if(name === 'department_id'){
      let value ={
        departmentId: e.target.value, 
        parmStatus: 1
      };
    filterEmployeeDepartment(value);
    }
   };

  const handlerSave = (e) => {
    e.preventDefault();
  
    let departmentIdValue = (employeeIdData?.department_id !== undefined && employeeIdData?.department_id !== null && !isNaN(employeeIdData.department_id)) ? employeeIdData.department_id : '';
  
      let value = {
        department_id: departmentIdValue?.toString(),
        employee_id:`${absence?.employee_id}` ? `${absence?.employee_id}` : `${employeeId}`,
        leave_type: formData.leave_type,
        end_time: formData?.end_time ? formData?.end_time : '',
        start_date: absence?.start_date ? absence?.start_date : formData?.start_date,
        start_time: formData?.start_time ? formData?.start_time : '',
        end_date: absence?.end_date ? absence?.end_date : formData?.end_date,
        reason: formData?.reason,
        type: "2",
        status: "0",
        time_in_lieu_deductions: (formData?.leave_type === process.env.REACT_APP_TIME_IN_LIEU_LEAVE_TYPE_ID) ? "1" : "",
        is_private: formData?.is_private
      };
      
      leaveByDateRange(value);
      //restrictedDayInfoAbsence(value);
      setOpen(true);
      setDateRange([ null, null ]);
  };
  const handlerSubmit = (e) => {

    const mergedData = leaveDateRange.map((unchangedItem) => {
      const changedItem = selectedTimeData.find((item) => moment(item?.date).format("YYYY-MM-DD") === unchangedItem?.date);
      if (changedItem) {
        // Merge changed data with unchanged data
        return {
          ...unchangedItem,
          ...changedItem
        };
      } else {
        // Keep unchanged data if no matching changed data
        return unchangedItem;
      }
    });

    const updatedEvents = mergedData?.map((event) => {
      const format = "HH:mm:ss";
      const startMoment = moment(event?.start_time, format);
      const endMoment = moment(event?.end_time, format);
      const duration222= moment.duration(endMoment?.diff(startMoment));
      const duration = duration222.asMinutes();
      const start_date = event?.date;
      const end_date = event?.date;
      const total_duration = event?.duration;
      const leave_day = event?.leave_day;
      return {
        ...event,
        duration,
        start_date,
        end_date,
        total_duration,
        leave_day
      };
    });

    const updatedStartTimes = updatedEvents.map((event) => event.start_time);
    const updatedEndTimes = updatedEvents.map((event) => event.end_time);
    const updatedTimeDuration = updatedEvents.map((event) => event.duration);

    // Find the earliest start_time and latest end_time
    const earliestStartTime = moment.min(updatedStartTimes.map((time) => moment(time, "HH:mm:ss"))).format("HH:mm:ss");
    const latestEndTime = moment.max(updatedEndTimes.map((time) => moment(time, "HH:mm:ss"))).format("HH:mm:ss");
    const timeDuration = moment.max(updatedTimeDuration.map((time) => time));
   
    e.preventDefault();
      let departmentIdValue = (employeeIdData?.department_id !== undefined && employeeIdData?.department_id !== null && !isNaN(employeeIdData.department_id)) ? employeeIdData.department_id : '';
      let value = {
        department_id: departmentIdValue?.toString(),
        employee_id: `${absence?.employee_id}`,
        leave_type: formData?.leave_type,
        end_time:latestEndTime,
        start_date: `${moment(absence?.start_date).format("YYYY-MM-DD")}`,
        start_time:earliestStartTime,
        end_date: `${moment(absence?.end_date).format("YYYY-MM-DD")}`,
        reason: reason,
        type: "2",
        status: "0",
        time_in_lieu_deductions: (formData?.leave_type==process.env.REACT_APP_TIME_IN_LIEU_LEAVE_TYPE_ID) ? "1" : "",
        is_private: formData?.is_private,
        total_duration: timeDuration,
        leave_entries: updatedEvents,
        id:absence?.id
      };
      updateLeaveAbsence(value);   
  };
      const onDateChange = (update) => {
        setFormData({
          ...formData,
          start_date: moment(update[0])?.format("YYYY-MM-DD"),
          end_date: moment(update[1])?.format("YYYY-MM-DD")
        });
      };
  
    const calculateDuration = (startTime, endTime) => {
      const format = "HH:mm:ss";
      const startMoment = moment(startTime === "Morning" ? startTimingSchedule : startTime === "Afternoon" ? moment(startTimingSchedule, "HH:mm").add(5, "h").format("HH:mm") : startTime, format);
      const endMoment = moment(endTime === "Lunch" ? moment(startTimingSchedule, "HH:mm").add(4, "h").format("HH:mm") : endTime === "End of Day" ? endTimingSchedule : endTime, format);
      const duration = moment.duration(endMoment.diff(startMoment));
      return duration.asMinutes();
    };
    
    return (
      <>
      <div className="header">
          <span>
          <h3>Edit Absence Details</h3>
          </span>
        </div>
        
    <div className="stepIndicator">
      <ul>
        <li className="active"><span>1</span>Request</li>
        <li><span>2</span>Details</li>
      </ul>
    </div>
    <div className="content">
      <form>
    <div className=" w-full">
        <div className="form-group mb-6 ">
        <label className="formBlock	">
            Who for{" "}
            <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
        </label>
        <div className="flex flex-wrap ">
            <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0 w-full">
    
    <select className="formControl mr-2" id="editDepartment" name="department_id" disabled defaultValue={absence?.department_id} onChange ={(e) => handlerChange(e)}>
            <FormSelect 
                optionValue={"0"}
                optionName={"Select Department"} />;

                {allDepartmentListData?.length > 0 ? allDepartmentListData?.map((departments, key) => { 
                    return   <FormSelect key={key} optionValue={departments?.value} optionName={departments?.label} />;
                }) : null}

            </select>

            
    
            {/* <div className="help-block text-red-700 mt-1">{error.errorDepartmentId ? error.errorDepartmentId :'' }</div> */}
            </div>
            <div className="md:w-1/2 w-full">
            
            <input className="formControl" value={absence?.employeeInfo?.first_name}/>
              {/* <div className="help-block text-red-700 mt-1">{error.errorEmployeeId ? error.errorEmployeeId :'' }</div> */}
            </div>
        </div>
        </div>
    </div>
        <div className="flex flex-wrap ">
        <div className=" w-full ">
          {shouldShowReason ? null : 
        <div className="w-full ">
          <div className="form-group mb-4 ">
            <label className="formBlock ">Reason  <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
            <FormTextarea textName="reason" handlerChange={handlerChange} value={formData?.reason? formData?.reason: absence?.reason} inputID="reason" />
            <div className="help-block" />
          </div>
        </div>}
        <div className="form-group mb-4 ">
          <div className="flex">
          <div className='w-full'>
            <label className="formBlock">
              Select Date
              <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
            </label>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              disabled
              id="startDate"
              dateFormat="dd/MM/yyyy"
              className="formControl"
              placeholderText="Select Date Range"
              onChange={(update) => {
                setDateRange(update);
                onDateChange(update);
              }}
              //isClearable={true}
              filterDate={(date) => date >= new Date() && isWeekday(date)} 
            />
    
          </div>
        </div>
      </div>        
    </div>
    <div className="lg:w w-full  ">
      <div className="w-full">
        <div className="form-group pt-4 pb-3 flex justify-center ">
          <PrimaryButton
            btnText="Next"
            Btnclass="btn save mr-2"
            btnype="button"
            buttonID="next"
            onClick={(e) => handlerSave(e)}
          />
          <PrimaryButton
            btnText="Cancel"
            onClick={() => close()}
            Btnclass="btn cancel"
            buttonID="cancel"
            btntype="button"
          />
        </div>
      </div>
    </div>
    <Popup
    open={open}
    onClose={() => closeModal()}
    closeOnDocumentClick={false}
    className="leavePopup"
    modal
    nested
    >
    {(close) => (
      <div className="modal" >
        <button
          className="close"
          id="requestLeaveClose"
          onClick={() => {
            closeModal();
            close();
          }}
        >
          &times;
        </button>
        <div style={{ maxHeight: "70vh", overflowY: "auto", paddingBottom:"24px" }}>
        <div className="stepIndicator">
          <ul>
            <li><span>1</span>Request</li>
            <li  className="active"><span>2</span>Details</li>
          </ul>
        </div>
        <div className="p-6" >
        <h3 className="text-lg primary-text mb-4">Leave Details</h3>       
        {leaveDateRange &&
          leaveDateRange?.map((leave, index) => {
            const date = moment(leave?.date)?.format("YYYY-MM-DD");
            const startTime = selectedTimes[date]?.start_time || "Select";
            const endTime = selectedTimes[date]?.end_time || "Select";
            //const durationInMinutes = selectedTimes[date]?.start_time ? calculateDuration(selectedTimes[date].start_time, selectedTimes[date].end_time) : durationInMinutess;
            const defaultStartTime = moment(absence?.start_time, "HH:mm:ss").format('HH:mm') ? moment(absence?.start_time, "HH:mm:ss").format('HH:mm') : leave?.start_time ? moment( leave?.start_time, "HH:mm:ss").format("HH:mm") : "Select";
            const defaultEndTime = moment(absence?.end_time, "HH:mm:ss").format('HH:mm') ? moment(absence?.end_time, "HH:mm:ss").format('HH:mm') : moment(leave?.end_time, "HH:mm:ss").format('HH:mm');
    
            return (
              <div
                className="w-full flex justify-between pb-4  md:flex-nowrap flex-wrap "
                key={index}
              >
                <div className="w-1/3">
                  <h3 className="text-base text-left pt-1"> {date}</h3>
                </div>
                {leave?.is_weekend === 1 ? (
                  <div>
                  <p>Non working day</p>
                </div> 
                )
                : ( 
                <div className=" md:w-2/3 w-full pt-2 md:pt-0 md:pl-4  ">
                  <div className="flex justify-between items-center w-full">
                    <select
                      className="formControl"
                      name={`start_time_${date}`}
                      value={
                        startTime === "Select"
                          ? defaultStartTime
                          : startTime
                      }
                      // defaultValue={leave?.start_time ? moment(leave?.start_time).format('HH:mm') : startTime}
                      onChange={(event) =>
                        handleStartTimeChange(date, event, leave, index) 
                      }
                    >
                      {employeeSlots?.map((time, index) => (
                        <option key={index} value={time}>
                         
                          {time}
                        </option>
                      ))}
                    </select>
                    <div>to</div>
    
                    <select
                      className="formControl"
                      name={`end_time_${date}`}
                      value={
                        endTime === "Select" ? defaultEndTime : endTime
                      }
                      defaultValue={
                        leave?.end_time
                          ? moment(leave?.end_time).format("HH:mm")
                          : endTime
                      }
                      onChange={(event) =>
                        handleEndTimeChange(date, event, leave, index)
                      }
                    >
                      {selectedTimeType?.length > 0 ? (
                        <>
                          {selectedTimeType[index]?.start_time === "Morning"
                            ? endTimeData?.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))
                            : 
                            selectedTimeType[index]?.start_time === "Afternoon"
                            ? endNewTimeData?.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))
                            :
                            selectedTimeType[index]?.start_time !==
                                "Morning" &&
                              selectedTimeType[index]?.start_time !==
                                "Afternoon"
                            ? employeeSlotsend?.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))
                            : endTimeSlots?.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                        </>
                      ) : (
                        endTimeSlots?.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="w-full mt-2">
                    <div className="timeDiv">
                      <span className="fa fa-clock secondry-text" />
                      <span>
                        <span className="secondry-text">
                          {leave?.duration ? Math.floor(leave?.duration / 60) : Math.floor(absence?.total_duration / 60)}
                        </span>{" "}
                        Hours{" "}
                        <span className="secondry-text pl-2 pr-1">
                          {leave?.duration ? leave?.duration % 60 : absence?.total_duration % 60}
                        </span>{" "}
                        Minutes
                      </span>
                    </div>
                  </div>
                </div>
                )
          }
              </div>
            );
          })}
      
        </div>
      
        </div>
        <div className="lg:w w-full  ">
          <div className="w-full mb-4">
            <div className="form-group pt-4 pb-3 flex justify-center ">
              <PrimaryButton
                btnText="Submit"
                Btnclass="btn save mr-2"
                btnype="button"
                buttonID="leaveSave"
                onClick={(e) => handlerSubmit(e)}
              />
              <PrimaryButton
                btnText="Back"
                onClick={() => close()}
                Btnclass="btn cancel"
                buttonID="leaveCancel"
                btntype="button"
              />
            </div>
          </div>
        </div>
      </div>
    )}
    </Popup>
    
    </div>
    </form>
    </div>
    </>
    );
  };

  export default EditAbsence;