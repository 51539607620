import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';

export const masterExpensesApi = createApi({
    reducerPath: "masterExpensesAPI",
    baseQuery: fetchBaseQuery({ 
      baseUrl: ApiUrl
}),
endpoints: (builder) => ({
 
 
getAllExpenses:builder.mutation({ 
      query: (page=1) => ({
        url: `/masterexpense?mileage=0&page=${page}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
    getAllExpensesList:builder.mutation({
 
      query: () => ({
        url: `/masterexpense?mileage=0&?page=1&status=1&limit=200`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
    getAllMileageExpenses:builder.mutation({
 
      query: (page) => ({
        url: `/masterexpense?is_mileage=1&page=${page}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      })
    }),
createExpenses: builder.mutation({
      query: (payload) => ({
        url: '/masterexpense',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
  }),
   updateExpenses: builder.mutation({
     query: (payload) => {
       const { id, ...body } = payload;
       return {
         url: `/masterexpense/${id}`,
         method: 'PUT',
         body,
         headers: {
          'Content-type': 'application/json; charset=UTF-8',
           Authorization:  localStorage.getItem('accessToken')
         }
       };
     },
     invalidatesTags: [ 'Post' ]
   }),
   deleteExpenses: builder.mutation({
    query: (id) => ({
      url: `/masterexpense/${id}`,
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
  })

})
});

export const { useGetAllExpensesListMutation, useGetAllExpensesMutation, useCreateExpensesMutation, useUpdateExpensesMutation, useDeleteExpensesMutation,  useGetAllMileageExpensesMutation } = masterExpensesApi;