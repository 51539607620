/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState, useRef } from "react";
import { useGetEmployeeMutation } from "../../services/employeeApi";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/inc/pagination";
import Popup from "reactjs-popup";
import { usePostShareEmployeeFileMutation } from "../../services/employeeDocumentAPI";
import { toast } from "react-toastify";
//import { toast } from "react-toastify";

const SharedEmployeeFile  = ({ fileSelected, sharedEmployeeFiles}) => {

    const [ getEmployee, {data:employeeListData, isSuccess: isEmployeeAPISuccess} ] = useGetEmployeeMutation();
    const [ postShareEmployeeFile, { isSuccess: postShareEmployeeFileSuccess, isLoading: postShareEmployeeFileLoading, isError:postShareEmployeeFileError} ] = usePostShareEmployeeFileMutation();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const pageTerm = searchParams.get("page") || "";
    const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
    const [ pages, setPages ] = useState(0);
    const [ open, setOpen ] = useState(false);

    const closeModal = () => {
      setOpen(false);
    };

    useEffect(() => {
        let payload=`/employee?page=${page}&status=${1}`;
        getEmployee(payload);
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);

    useEffect(() => {
        if(isEmployeeAPISuccess){
          setPages(employeeListData?.totalPages);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isEmployeeAPISuccess ]);

    const decrementPage = () => {
        if(employeeListData?.currentPage - 1 >= 0){
          getEmployee(employeeListData?.currentPage - 1);
           setPage(employeeListData?.currentPage - 1);
        }
    };

    const handleShareFileToEmployee = (e, id) => {
        e.preventDefault(); 
        const value = {
            "document_id": fileSelected?.id,
            "employee_id": id
        };
        postShareEmployeeFile(value);
    };

    const toastId = useRef(null);
    useEffect(() => {
        if (postShareEmployeeFileLoading) {
            toastId.current = toast.loading("Please wait...", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            type: "info"
          });
        }
        if (postShareEmployeeFileSuccess) {
          toast.dismiss(toastId.current);
          toast.success("File share successfully",
            {
              render: "File share successfully",
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "colored",
              type: "success"
            }
          );
        sharedEmployeeFiles(fileSelected?.id);
        document?.getElementById("closeSharefilePopup")?.click();
        }
        if (postShareEmployeeFileError) {
          const message = "Something went wrong";
          toast.update(
            toastId.current,
            (message,
            {
              render: message,
              type: "error",
              autoClose: 5000,
              isLoading: false
            })
          );
        }// eslint-disable-next-line react-hooks/exhaustive-deps
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ postShareEmployeeFileLoading, postShareEmployeeFileSuccess, postShareEmployeeFileError ]);


   
  return (
    <>  
        <div className="overflow-x-auto w-full whitespace-nowrap">
                      <table className="table-auto table  w-full">
                        <thead className="thead-dark ">
                          <tr>
                            <th className="w-40"> Name</th>
                            <th>Department</th>
                            <th>Shared</th>
                          </tr>
                        </thead>
                        <tbody>
                        {employeeListData?.data?.length > 0 ? 
                        <>
                          {employeeListData?.data?.length > 0 && employeeListData?.data?.map((employeeList, index) => {
                            return (
                          <tr key={index}>
                            <td>
                              <Link
                                to="/employees/detail/1"
                                className="listImage flex items-center "
                              >
                                {employeeList?.first_name}
                              </Link>
                            </td>
                            <td>{employeeList?.departmentInfo?.department_name}</td>
                            <td>
                            <Popup
                                    open={open}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                        <span onClick={() => setOpen((o) => !o)} className="fa fa-share text-[13px] text-white p-1 rounded bg-green-500 w-6" />
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          className="close"
                                          id="closeSharefilePopup"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="content">
                                          <p>Are you sure you want to share this folder to {employeeList?.first_name} ?</p>
                                          <div className="w-full mt-8">
                                            <div className="form-group pt-4 pb-3 flex justify-center ">
                                              <button className="btn save mr-2" 
                                              id="deleteYes"
                                              onClick={(e) => handleShareFileToEmployee(e, employeeList?.id)}
                                              >Yes</button>
                                              <button
                                                type="button"
                                                id="deleteNo"
                                                onClick={() => close()}
                                                className="btn cancel "
                                              >
                                                No
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup> 
                            </td>
                          </tr>
                            );
                            })}
                            </> :
                          <td className="mt-4  text-center" colSpan="12">No Data found</td>}
                        </tbody>
                      </table>
                      <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
                    </div>
    </>
  );
};

export default SharedEmployeeFile;
