import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null
};

export const addressAutofillSlice = createSlice({
  name: "addressAutofillSlice",
  initialState,
  reducers: {
    addressInfo: (state, action) => {
     
      state.addressAutofill= action.payload;
      
    }
  }
});

export const { addressInfo } = addressAutofillSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default addressAutofillSlice.reducer;