/* eslint-disable camelcase */
/* eslint-disable no-debugger*/
import moments from "moment";
import {getDay} from "date-fns";

export const getTodayDate = () => {
  return new Date().getDate();
};

export const getTodayMonth = () => {
  let month = new Date().getMonth();
  if (month === (10 || 11 || 12)) return month + 1;
  else return "0" + (month + 1);
};

export const getWeekDay = (date = false) => {
  if (date) {
    return new Date(date).getDay();
  }
  return new Date().getDay();
};

export const getTodayYear = () => {
  return new Date().getFullYear();
};

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};
export const getCurrentMonthData = (monthCount, leaveInfo) => {
  let leaveData = [];
  leaveInfo?.forEach((leave) => {
    // let startDate =  moments(leave.start_date).month(monthCount-1).format('DD');
    // let endDate =  moments(leave.end_date).month(monthCount-1).format('DD');
    let startDate = moments(leave.start_date).format("DD");
    let endDate = moments(leave.end_date).format("DD");
    let leaveArr = [];
    for (let i = startDate; i <= endDate; i++) {
      leaveArr.push(parseInt(i));
    }
    let value = {
      addedBy: leave?.addedByInfo,
      leaveArray: leaveArr,
      leaveColor: leave?.leaveTypeInfo?.color
    };
    leaveData.push(value);
  });
  return leaveData;
};

export const getCurrentMonthLeaveDate = (
  monthCount,
  leave,
  id,
  userRoleId,
  employeeIdData,
  yearData,
  dob,
  joining_date,
  holidayData
) => {
  if (leave) {
    let leaveArray = [];
    let leaveColor = [];
    let leaveIcon = [];
    let bookingDate = [];
    let addedInfo = [];
    let leaveId = [];
    let reason = [];
    let duration = [];
    let startTime = [];
    let endTime = [];
    let leaveTypeName = [];
    let leaveAngle = [];
    let leaveHalfDay = [];
    if (holidayData) {
      holidayData?.forEach((holiday) => {
        if (holiday?.start_date) {
          if (moments(holiday?.start_date).format("YYYY") === yearData) {
            if (moments(holiday?.start_date).format("MM") === monthCount) {
              let holidayDate = moments(holiday?.start_date).format("DD");
              if (holidayDate) {
                leaveArray.push(holidayDate);
                leaveColor[holidayDate] = "#ddadaf";
                leaveIcon[holidayDate] = "bhIcon";
                leaveTypeName[holidayDate] = holiday?.name;
              }
            }
          }
        }
      });
    }
    if (moments(dob).format("MM") === monthCount) {
      let dateOfBirth = moments(dob).format("DD");
      if (dateOfBirth) {
        leaveArray.push(dateOfBirth);
        leaveColor[dateOfBirth] = "red";
        leaveIcon[dateOfBirth] = "fa fa-birthday-cake";
        leaveTypeName[dateOfBirth] = "Birthday";
      }
    }
    if (moments(joining_date).format("YYYY") === yearData) {
      if (moments(joining_date).format("MM") === monthCount) {
        let joiningDate = moments(joining_date).format("DD");
        if (joiningDate) {
          leaveArray.push(joiningDate);
          leaveColor[joiningDate] = "orange";
          leaveIcon[joiningDate] = "fas fa-glass-cheers";
          leaveTypeName[joiningDate] = "Joining Date";
        }
      }
    }
    leave?.forEach((l) => {
      if (l.is_private === "1") {
        // area to change
        // Check if the user's roleId is 2 or 3 to see the leave
        if (id === employeeIdData || userRoleId === 2 || userRoleId === 3) {
          if (l.status === "0" || l.status === "1") {
            if (l.start_date !== null || l.end_date !== null) {
              if (moments(l.start_date).format("YYYY") === yearData) {
                if (moments(l.start_date).format("MM") === monthCount) {
                  let startDate = moments(l.start_date).format("DD");
                  let endDate = moments(l.end_date).format("DD");
                  if (startDate === endDate) {
                    if (parseFloat(l?.leave_day) < 1) {
                      // Convert leave_day to angle (assuming full day is 360 degrees)
                      const convertedAngle = parseFloat(l?.leave_day) * 100;
                      // Push converted angle into leaveAngle array
                      leaveAngle[startDate] = convertedAngle;
                      // Set leaveHalfDay to true
                      leaveHalfDay[startDate] = true;
                    }
                    leaveArray.push(startDate);
                    leaveColor[startDate] =
                      l?.leaveTypeInfo !== undefined
                        ? l?.leaveTypeInfo !== null
                          ? l?.leaveTypeInfo?.color
                          : "red"
                        : "red";
                    leaveIcon[startDate] = l.status === "0" ? "fa-sharp fa-solid fa-r" : (l?.leaveTypeInfo?.icon || "fa fa-times-circle");
                    bookingDate[startDate] = l?.updatedAt;
                    addedInfo[startDate] = l?.addedByInfo;
                    leaveId[startDate] = l?.id;
                    reason[startDate] = l?.reason;
                    duration[startDate] = l?.total_duration;
                    startTime[startDate] = l?.time_field1;
                    endTime[startDate] = l?.time_field2;
                    leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                      ? l?.leaveTypeInfo?.leave_name
                      : "Absence";
                  } else if (startDate <= endDate) {
                    for (let i = startDate; i <= endDate; i++) {
                      // let start = parseInt(i) <= 9 ? i.split('0')[1] : ;
                      leaveArray.push(`${i}`);
                      // let result = l?.leaveTypeInfo !== undefined;
                      leaveColor[i] =
                        l?.leaveTypeInfo !== undefined
                          ? l?.leaveTypeInfo !== null
                            ? l?.leaveTypeInfo?.color
                            : "red"
                          : "red";
                      leaveIcon[i] = l.status === "0" ? "fa-sharp fa-solid fa-r" : (l?.leaveTypeInfo?.icon || "fa fa-times-circle");
                      addedInfo[i] = l?.addedByInfo;
                      leaveId[startDate] = l?.id;
                      reason[startDate] = l?.reason;
                      duration[startDate] = l?.total_duration;
                      bookingDate[startDate] = l?.updatedAt;
                      startTime[startDate] = l?.time_field1;
                      endTime[startDate] = l?.time_field2;
                      leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                        ? l?.leaveTypeInfo?.leave_name
                        : "Absence";
                      leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                        ? l?.leaveTypeInfo?.leave_name
                        : "Absence";
                    }
                  } else if (
                    moments(l.end_date).format("MM") ===
                    moments(monthCount).add({months: 1}).format("MM")
                  ) {
                    // let startDate = moments(l.start_date)
                    //   .month(monthCount)
                    //   .format("DD");
                    let startDate = moments(l.start_date).format("DD");
                    for (let i = startDate; i <= 31; i++) {
                      leaveArray.push(`${i}`);
                      leaveColor[i] =
                        l?.leaveTypeInfo !== undefined
                          ? l?.leaveTypeInfo !== null
                            ? l?.leaveTypeInfo?.color
                            : "red"
                          : "red";
                      leaveIcon[i] = l.status === "0" ? "fa-sharp fa-solid fa-r" : (l?.leaveTypeInfo?.icon || "fa fa-times-circle");
                      bookingDate[i] = l?.updatedAt;
                      addedInfo[i] = l?.addedByInfo;
                      leaveId[i] = l?.id;
                      reason[i] = l?.reason;
                      duration[i] = l?.total_duration;
                      startTime[i] = l?.time_field1;
                      endTime[i] = l?.time_field2;
                      leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                        ? l?.leaveTypeInfo?.leave_name
                        : "Absence";
                    }
                  }
                } else if (moments(l.end_date).format("MM") === monthCount) {
                  let endDate = moments(l.end_date).format("DD");
                  for (let j = 1; j >= endDate; j--) {
                    let end_date = j <= 9 ? "0" + j : j;
                    leaveArray.push(`${end_date}`);
                    leaveColor[end_date] =
                      l?.leaveTypeInfo !== undefined
                        ? l?.leaveTypeInfo !== null
                          ? l?.leaveTypeInfo?.color
                          : "red"
                        : "red";
                    leaveIcon[end_date] = l.status === "0" ? "fa-sharp fa-solid fa-r" : (l?.leaveTypeInfo?.icon || "fa fa-times-circle");
                    bookingDate[end_date] = l?.updatedAt;
                    addedInfo[end_date] = l?.addedByInfo;
                    leaveId[end_date] = l?.id;
                    reason[end_date] = l?.reason;
                    duration[end_date] = l?.total_duration;
                    startTime[end_date] = l?.time_field1;
                    endTime[end_date] = l?.time_field2;
                    leaveTypeName[end_date] = l?.leaveTypeInfo?.leave_name
                      ? l?.leaveTypeInfo?.leave_name
                      : "Absence";
                  }
                }
              }
            }
          }
        } else {
          if (l.start_date !== null || l.end_date !== null) {
            if (moments(l.start_date).format("MM") === monthCount) {
              let startDate = moments(l.start_date).format("DD");
              let endDate = moments(l.end_date).format("DD");
              if (startDate === endDate) {
                if (parseFloat(l?.leave_day) < 1) {
                  // Convert leave_day to angle (assuming full day is 360 degrees)
                  const convertedAngle = parseFloat(l?.leave_day) * 100;
                  // Push converted angle into leaveAngle array
                  leaveAngle[startDate] = convertedAngle;
                  // Set leaveHalfDay to true
                  leaveHalfDay[startDate] = true;
                }
                leaveArray.push(startDate);
                leaveColor[startDate] = "#e4e3e4";
                leaveTypeName[startDate] = "OFF";
              } else if (startDate <= endDate) {
                for (let i = startDate; i <= endDate; i++) {
                  leaveArray.push(`${i}`);
                  leaveColor[i] = "#e4e3e4";
                  leaveTypeName[endDate] = "OFF";
                }
              } else if (
                moments(l.end_date).format("MM") ===
                moments(monthCount).add({months: 1}).format("MM")
              ) {
                let startDate = moments(l.start_date).format("DD");
                for (let i = startDate; i <= 31; i++) {
                  leaveArray.push(`${i}`);
                  leaveColor[i] = "#e4e3e4";

                  leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                    ? l?.leaveTypeInfo?.leave_name
                    : "Absence";
                }
              }
            } else if (moments(l.end_date).format("MM") === monthCount) {
              let endDate = moments(l.end_date).format("DD");
              for (let j = 1; j >= endDate; j--) {
                let end_date = j <= 9 ? "0" + j : j;
                leaveArray.push(`${end_date}`);
                leaveColor[end_date] = "#e4e3e4";
                leaveTypeName[end_date] = l?.leaveTypeInfo?.leave_name
                  ? l?.leaveTypeInfo?.leave_name
                  : "Absence";
              }
            }
          }
        }
      } else {
        if (l.status === "0" || l.status === "1") {
          if (l.start_date !== null || l.end_date !== null) {
            if (moments(l.start_date).format("YYYY") === yearData) {
              if (moments(l.start_date).format("MM") === monthCount) {
                let startDate = moments(l.start_date).format("DD");
                let endDate = moments(l.end_date).format("DD");
                if (startDate === endDate) {
                  // Check if leave_day is less than 1
                  if (parseFloat(l?.leave_day) < 1) {
                    // Convert leave_day to angle (assuming full day is 360 degrees)
                    const convertedAngle = parseFloat(l?.leave_day) * 100;
                    // Push converted angle into leaveAngle array
                    leaveAngle[startDate] = convertedAngle;
                    // Set leaveHalfDay to true
                    leaveHalfDay[startDate] = true;
                  }
                  leaveArray.push(startDate);
                  leaveColor[startDate] =
                    l?.leaveTypeInfo !== undefined
                      ? l?.leaveTypeInfo !== null
                        ? l?.leaveTypeInfo?.color
                        : "red"
                      : "red";
                  leaveIcon[startDate] = l.status === "0" ? "fa-sharp fa-solid fa-r" : (l?.leaveTypeInfo?.icon || "fa fa-times-circle");
                  bookingDate[startDate] = l?.updatedAt;
                  addedInfo[startDate] = l?.addedByInfo;
                  leaveId[startDate] = l?.id;
                  reason[startDate] = l?.reason;
                  duration[startDate] = l?.total_duration;
                  startTime[startDate] = l?.time_field1;
                  endTime[startDate] = l?.time_field2;
                  leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                    ? l?.leaveTypeInfo?.leave_name
                    : "Absence";
                } else if (startDate <= endDate) {
                  for (let i = startDate; i <= endDate; i++) {
                    leaveArray.push(`${i}`);
                    leaveColor[i] =
                      l?.leaveTypeInfo !== undefined
                        ? l?.leaveTypeInfo !== null
                          ? l?.leaveTypeInfo?.color
                          : "red"
                        : "red";
                    leaveIcon[i] = l.status === "0" ? "fa-sharp fa-solid fa-r" : (l?.leaveTypeInfo?.icon || "fa fa-times-circle");
                    addedInfo[i] = l?.addedByInfo;
                    leaveId[startDate] = l?.id;
                    reason[startDate] = l?.reason;
                    duration[startDate] = l?.total_duration;
                    bookingDate[startDate] = l?.updatedAt;
                    startTime[startDate] = l?.time_field1;
                    endTime[startDate] = l?.time_field2;
                    leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                      ? l?.leaveTypeInfo?.leave_name
                      : "Absence";
                    leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                      ? l?.leaveTypeInfo?.leave_name
                      : "Absence";
                  }
                } else if (
                  moments(l.end_date).format("MM") ===
                  moments(monthCount).add({months: 1}).format("MM")
                ) {
                  // let startDate = moments(l.start_date)
                  //   .month(monthCount)
                  //   .format("DD");
                  let startDate = moments(l.start_date).format("DD");
                  for (let i = startDate; i <= 31; i++) {
                    leaveArray.push(`${i}`);
                    leaveColor[i] =
                      l?.leaveTypeInfo !== undefined
                        ? l?.leaveTypeInfo !== null
                          ? l?.leaveTypeInfo?.color
                          : "red"
                        : "red";
                    leaveIcon[i] = l.status === "0" ? "fa-sharp fa-solid fa-r" : (l?.leaveTypeInfo?.icon || "fa fa-times-circle");
                    bookingDate[i] = l?.updatedAt;
                    addedInfo[i] = l?.addedByInfo;
                    leaveId[i] = l?.id;
                    reason[i] = l?.reason;
                    duration[i] = l?.total_duration;
                    startTime[i] = l?.time_field1;
                    endTime[i] = l?.time_field2;
                    leaveTypeName[startDate] = l?.leaveTypeInfo?.leave_name
                      ? l?.leaveTypeInfo?.leave_name
                      : "Absence";
                  }
                }
              } else if (moments(l.end_date).format("MM") === monthCount) {
                let endDate = moments(l.end_date).format("DD");
                // let endDate = moments(l.end_date)
                //   .month(monthCount)
                //   .format("DD");
                for (let j = 1; j >= endDate; j--) {
                  let end_date = j <= 9 ? "0" + j : j;
                  leaveArray.push(`${end_date}`);
                  leaveColor[end_date] =
                    l?.leaveTypeInfo !== undefined
                      ? l?.leaveTypeInfo !== null
                        ? l?.leaveTypeInfo?.color
                        : "red"
                      : "red";
                  leaveIcon[end_date] = l.status === "0" ? "fa-sharp fa-solid fa-r" : (l?.leaveTypeInfo?.icon || "fa fa-times-circle");
                  bookingDate[end_date] = l?.updatedAt;
                  addedInfo[end_date] = l?.addedByInfo;
                  leaveId[end_date] = l?.id;
                  reason[end_date] = l?.reason;
                  duration[end_date] = l?.total_duration;
                  startTime[end_date] = l?.time_field1;
                  endTime[end_date] = l?.time_field2;
                  leaveTypeName[end_date] = l?.leaveTypeInfo?.leave_name
                    ? l?.leaveTypeInfo?.leave_name
                    : "Absence";
                }
              }
            }
          }
        }
      }
    });
    return [
      leaveArray,
      leaveColor,
      leaveIcon,
      bookingDate,
      addedInfo,
      leaveId,
      reason,
      duration,
      startTime,
      endTime,
      leaveTypeName,
      leaveAngle,
      leaveHalfDay
    ];
  }

  return [];
};

export const getStartEndDate = (leaveArray, selectedDate) => {
  let endDate = null;
  let startDate = null;
  for (let i = selectedDate; i <= 31; i++) {
    if (leaveArray.indexOf(`${i}`) > -1) {
      endDate = i;
    } else {
      break;
    }
  }
  for (let j = selectedDate; j >= 1; j--) {
    if (leaveArray.indexOf(`${j}`) > -1) {
      startDate = j;
    } else {
      break;
    }
  }

  return [startDate, endDate];
};

// export const isPresent = (attList = false, date = 0, month = false, year = false) => {
// //     let matchDate;
// //     let dateArray = [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ];
// //     if (attList) {
// //         if (dateArray.includes(date)) {
// //             matchDate = `${year}-${month}-0${date}`;
// //         } else {
// //             matchDate = `${year}-${month}-${date}`;
// //         }
// //             matchDate = `${year}-${month}-0${date}`;
// //         for (let yy = 0; yy < attList?.length; yy++) {

// //             //  const splitArray = (attList[yy]?.createdAt).split("T");
// //             // if (splitArray[0] === matchDate) {
// //             //      return true;

// //         // }
// //     }
// // }
//     return false;
// };

export const createSlots = (start, end) => {
  let slots = [];
  const mins = 15 * 60 * 1000; // 15 mins

  const date = (dt) => new Date(dt);

  while (start <= end) {
    start += mins;

    // only mins
    //slots.push(date(start).getMinutes());

    // hrs +  mins
    slots.push(`${date(start).getHours()}:${date(start).getMinutes()}`);
  }
  return slots;
};

export const formatDate = (date) => {
  let formatDate = localStorage.getItem("format");
  return moments(date).format(formatDate);
};

export const getAllLeaveDates = (leaveData) => {
  let eventDate = [];
  leaveData &&
    leaveData.forEach((leave) => {
      if (leave?.status === "0" || leave?.status === "1") {
        if (leave.start_date !== null && leave.end_date !== null) {
          if (leave.start_date === leave.end_date) {
            eventDate.push(
              moments(new Date(leave.start_date)).format("YYYY-MM-DD")
            );
          } else if (leave.start_date <= leave.end_date) {
            let currentDate = new Date(leave.start_date);
            while (currentDate <= new Date(leave.end_date)) {
              eventDate.push(
                moments(new Date(currentDate)).format("YYYY-MM-DD")
              );
              currentDate.setDate(currentDate.getDate() + 1);
            }
          }
        }
      }
    });

  return eventDate;
};

export const getDuration = (
  startDate,
  endDate,
  startTime,
  endTime,
  employeeSchedule
) => {
  if (startDate <= endDate) {
    let start_date = new Date(
      moments(startDate).format("YYYY-MM-DD") + " " + startTime
    );
    let end_date = new Date(
      moments(endDate).format("YYYY-MM-DD") + " " + endTime
    );
    let timeDifferenceInMinutes = 0;

    let current = start_date;
    while (current <= end_date) {
      let currentTime = current.getHours() + current.getMinutes() / 60;
      let workSchedule = employeeSchedule?.find(
        (schedule) => parseInt(schedule?.day) === getDay(current)
      );
      let workHoursStart = moments(workSchedule?.start_time).format("HH");
      let workHoursEnd = moments(workSchedule?.end_time).format("HH");

      if (workSchedule?.is_weekend === 1) {
        // Skip weekends
        current.setDate(current.getDate() + 1);
        continue;
      }
      if (
        currentTime >= workHoursStart &&
        currentTime < workHoursEnd &&
        workSchedule?.is_weekend !== 1
      ) {
        timeDifferenceInMinutes++;
      }

      current.setTime(current.getTime() + 1000 * 60);
    }

    return Math.round(timeDifferenceInMinutes);
  }
};

export const getTimeCalculation = (dura, hourDuration) => {
  var hour = dura / 60;
  var breakdays = Math.floor(hour / hourDuration) * 60;
  let hours = dura - breakdays;

  //  let min = hour%60;

  let h = Math.floor(hours / 60);
  let _day = h / hourDuration;

  if (h <= 6) {
    return `1/2`;
  } else {
    if (h >= 6 && h % 8 !== 0) {
      return `${parseInt(_day)}&1/2`;
    } else {
      return `${_day}`;
    }
  }
};
