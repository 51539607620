/* eslint-disable camelcase */
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NoDataFound from "../../helper/noDataFound";
import "react-datepicker/dist/react-datepicker.css";
import { SearchField } from "../../components/filters";
import moments from "moment";
import { CSVLink } from "react-csv";
import { FormSelect } from "../../components/formInput";
import Pagination from "../../components/inc/pagination";
import "react-datepicker/dist/react-datepicker.css";
import { useGetDepartmentListMutation } from "../../services/approverListApi";
import { LoadingIndicator } from "../../components/loading-indicator";
import { useDispatch, useSelector } from "react-redux";
import { departmentListAction } from "../../redux/DepartmentSlice/departmentSlice";
import { useGetAllMainExpensesMutation } from "../../services/expenseApi";
// import { expenseReportInfoAction } from "../../redux/ReportSlice/expenseSlice";
import { allMainExpenseDataAction } from "../../redux/ExpenseSlice/expenseSlice";

const ExpenseReport  = () => {

  const dispatch =useDispatch();
  const ignore = useRef(false);
  /* api calling */
  const [ getAllMainExpenses, { data: allExpenseInfoReponseList, isSuccess: isSuccessExpenses, isLoading} ] = useGetAllMainExpensesMutation();
  const [ getDepartmentList, {data:departmentApiResponse, isSuccess: isSuccessDepartmentApi} ] = useGetDepartmentListMutation();

  /*data from redux carried out*/
  const auditorCompanyId = useSelector((state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany);
  const auditorRoleId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
  const formattedDate = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.dateFormats);
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);
  // const allExpenseInfoReponse = useSelector((state) => state?.persistedReducer?.reportSlice?.expenseReportData);
  const departmentApiData = useSelector((state) => state?.persistedReducer?.departmentSlice?.allDepartmentList);
  const  allExpenseInfoReponse = useSelector((state) => state?.persistedReducer?.expenseSlice?.allMainExpenseData);
  /* states are defined here */
  const [ allDepartmentListData, setAllDepartmentListData ] = useState();
  const [ allExpenses, setAllExpeses ] = useState();
  const [ excelData, setExcelData ] = useState([]);
  const statusData = [
    {value :"0", label: "Applied"},
    {value :"1", label: "Approve"},
    {value :"2", label: "Paid"},
    {value :"3", label: "Rejected"}
  ];

  const [ searchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(0);
  
  const createCsvFileName = () => `Expense_report_${moments().format()}.csv`;
  
  useEffect(() => {
      let payload = `/expenses/filter/search?page=${page}&is_mileage=${0}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
      getAllMainExpenses(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ page ]
  );

  useEffect(() => {
      if (isSuccessExpenses) {
         dispatch(allMainExpenseDataAction(allExpenseInfoReponseList));
        // dispatch(expenseReportInfoAction(allExpenseInfoReponseList));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessExpenses ]
  );

  useEffect(() => {
      if (allExpenseInfoReponse) {
        setAllExpeses(allExpenseInfoReponse?.data);
        setPages(allExpenseInfoReponse?.totalPages);
        if (allExpenseInfoReponse?.data) {
          let csvData = exportTOCSV(allExpenseInfoReponse?.data);
          setExcelData(csvData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allExpenseInfoReponse ]
  );

  useEffect(() => {
    if (!ignore.current){
      getDepartmentList();
    }
    return () => { ignore.current = true; };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ ]
  );

  useEffect(() => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessDepartmentApi ]
  );

  useEffect(() => {
      if (departmentApiData) {
        setAllDepartmentListData(departmentApiData?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ departmentApiData ]
  );

  const filterEmployees = useCallback(
    (searchTerm) => {
      let payload = `/expenses/filter/search?page=${page}&is_mileage=${0}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&first_name=${searchTerm}`;
      getAllMainExpenses(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlerChangeByDeptName = (e) => {
    e.preventDefault();
    if (e.target.value === "0") {
      let payload = `/expenses/filter/search?page=${page}&is_mileage=${0}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
      getAllMainExpenses(payload);
    } else {
      let payload = `/expenses/filter/search?page=${page}&is_mileage=${0}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&department_id=${
        e.target.value
      }`;
      getAllMainExpenses(payload);
    }
  };

  const  handlerStatusFilter = (e) => {
    if (e.target.value === " ") {
      let payload =  `/expenses/filter/search?page=${page}&is_mileage=${0}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}`;
      getAllMainExpenses(payload);    
      } else {
       let payload = `/expenses/filter/search?page=${page}&is_mileage=${0}&company_id=${auditorRoleId === 5 ? auditorCompanyId : companyId}&status=${e.target.value}`;
       getAllMainExpenses(payload); 
      } 
  };

  /*export to CSV */
  let  headers = [
    { label: 'Employee Name', key : 'employeeName'},
    { label: 'Department', key : 'department'},
    { label: 'Expenses claimed', key : 'expenses_claimed'},
    { label: 'Approver', key : 'approver'},
    { label: 'Type of expenses', key : 'type_of_expenses'},
    { label: 'Date', key : 'date'},
    { label: 'Status', key : 'status'}
    ];
    const exportTOCSV = (report) => {
      let  dataAbence =[];
      if(report){
        report?.forEach((dExcel) => {
          let val ={
            employeeName: dExcel?.employeeInfo?.first_name,
            department: dExcel?.departmentInfo?.department_name ? dExcel?.departmentInfo?.department_name : "",
            expenses_claimed: dExcel?.amount,
            approver: `${dExcel?.approvedByInfo?.first_name} ${dExcel?.approvedByInfo?.last_name}`,
            type_of_expenses: dExcel?.expenseTypeInfo?.expense_name,
            date: moments(dExcel?.start_date).format(formattedDate),
            status: dExcel.status === '0' ? 'Applied' :
            dExcel.status === '1' ? 'Approved' :
            dExcel.status === '2' ? 'Paid' :
            dExcel.status === '3' ? 'Rejected' :
            ''
         };
          dataAbence?.push(val);
        });
        return dataAbence;
      }
     
   };
  

  /* pagination */
  const decrementPage = () => {
    if(allExpenseInfoReponse?.currentPage - 1 >= 0){
      getAllMainExpenses(allExpenseInfoReponse?.currentPage - 1);
      setPage(allExpenseInfoReponse?.currentPage - 1);
    }
  };
  
  return (
   
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Reports</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold  ">Expenses Report </h2>             
              <div className="card ">            
                <div className="p-4 ">
               
                <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap">
                      <div className="searchSec lg:w-1/5 md:w-1/4 md:pr-4 w-full mb-2 lg:mb-0 ">
                        <SearchField placeholderText="Search By Name" searchId="reportExpenseSearch"
                        onChange={(e) => filterEmployees(e.target.value)}
                        />
                      </div>
                      <div className=" lg:w-1/5 md:w-1/4  w-full md:mb-0 mb-2 md:pr-4">
                      <select className="formControl" id="reportExpenseDepartment"  name="department_name" onChange={(e) => handlerChangeByDeptName(e)}>
                        <FormSelect 
                            optionValue={"0"}
                            optionName={"Select Department"} />;
                            {allDepartmentListData?.length > 0 ? allDepartmentListData?.map((departments, key) => { 
                                return   <FormSelect key={key} optionValue={departments?.id} optionName={departments?.department_name} />;
                            }) : null}
                        </select>
                      </div>
                      <div className=" lg:w-1/5 md:w-1/4 w-full md:mb-0 mb-4 md:pr-0 lg:pr-0">
                      <select className="formControl" id="reportExpenseStatus"  onChange={(e) => handlerStatusFilter(e)}>
                        <FormSelect 
                          optionValue={" "}
                          optionName={"Select Status"}
                          />
                          {statusData?.map((status, key) => { 
                              return   <FormSelect key={key} optionValue={status?.value} optionName={status.label} />;
                          })}
                      </select>
                      </div>
                      <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full ml-[150px]">
                      <CSVLink
                        className=""
                        data={excelData}
                        headers={headers}
                          filename={createCsvFileName()}
                          target="_blank"
                        style={{ textDecoration: 'none', outline: 'none', height: '5vh' }}
                        >
                        <button  className="btn-md btn-add " id="reportExpenseCsv" >
                        <span className="fa fa-file-export mr-2" /> Export to CSV
                        </button>
                        </CSVLink>
                      </div>
                    </div>
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                      <table className="table-auto table  w-full">
                        <thead className="thead-dark ">
                          <tr>
                            <th className="w-40"> Name</th>
                            <th>Department</th>
                            <th>Expenses claimed</th>
                            <th>Approver</th>
                            <th>Type of expenses</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        {isLoading ?<LoadingIndicator/> :
                        <tbody>
                        {allExpenses?.length > 0 ? 
                        <>
                          {allExpenses?.length > 0 && allExpenses.map((expense, index) => {
                            return (
                          <tr key={index}>
                            <td>
                              <Link
                                to="/employees/detail/1"
                                className="listImage flex items-center "
                              >
                                {expense?.employeeInfo?.first_name+' '+expense?.employeeInfo?.last_name}
                              </Link>
                            </td>
                            <td>{expense?.departmentInfo?.department_name}</td>
                            <td>£{expense?.amount}</td>
                            <td>{expense?.approvedByInfo?.first_name} {expense?.approvedByInfo?.last_name}</td>
                            <td>{expense?.expenseTypeInfo?.expense_name}</td>
                            <td>{moments(expense?.start_date).format(formattedDate)}</td>
                            <td>
                              <span className={
                              expense.status === '0' ? 'blue-bedge' :
                              expense.status === '1' ? 'amberBedge' :
                              expense.status === '2' ? 'greenBedge' :
                              expense.status === '3' ? 'redBedge':
                              ''
                              }>
                              {expense.status === '0' ? 'Applied' :
                              expense.status === '1' ? 'Approved' :
                              expense.status === '2' ? 'Paid' :
                              expense.status === '3' ? 'Rejected' :
                              ''
                                }
                              </span>
                              </td>

                          </tr>
                            );
                            })}
                            </> :
                          <center className="mt-4">{<NoDataFound/>}</center>}
                        </tbody>}
                      </table>
                      <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
                    </div>
             
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseReport;
