/* eslint-disable no-debugger*/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  userPermissions:[],
  companies: [],
  message:[],
  dateFormats : 'DD/MM/YY',
  approvalsCountData: null, 
  nextcarryForward: null,
  startMonthYear: null,
  notificationCount: null,
  showApprovalsTab:null,
  dashboardData:[]
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
   
    logout: () => initialState,
    addUser: (state, action) => {
       state.push(action.payload);
    },
    allUserPermissions: (state, action) => {
     state.userPermissions = action?.payload;
   },
    userInfo: (state, action) => {
     
      if(action?.payload){
        state.user = action.payload;
      }
    },
    companyAllData: (state, action) => {
     
      state.companies = action?.payload;
    },
    dashboardMessageData: (state, action) => {
      state.message = action?.payload;
    },
    notificationCount: (state, action) => {
      state.noticationCountData = action?.payload;
    }, 
    notification: (state, action) => {
      state.noticationData = action?.payload;
    }, 
    approvalsCount: (state, action) => {
      state.approvalsCountData = action?.payload;
    },
    nextcarryForward: (state, action) => {
      state.nextcarryForward = action?.payload;
    },
    startMonthYear: (state, action) => {
      state.startMonthYear = action?.payload;
    },
    dateFormats: (state, action) => {
      state.dateFormats = action?.payload;
    },
    showApprovalsTab: (state, action) => {
      state.showApprovalsTab = action?.payload;
    }
  }
});

export const { logout, addUser, userInfo, showApprovalsTab, allUserPermissions, 
  companyAllData, dashboardMessageData, notification,
  notificationCount, approvalsCount, nextcarryForward, startMonthYear, dateFormats
} = authSlice.actions;
export default authSlice.reducer;