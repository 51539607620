import { Link } from "react-router-dom";

const DashboardboxExpense = ({title, value, onClick}) => {
  const handleCardClick = () => {
    // If an onClick function is provided, call it
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className="lg:w-2/12 w-full mb-4 md:mb-6 lg:pr-2 flex" onClick={handleCardClick}>
      <div className="card w-full infoList headingHeight" style={{ minHeight: "178px" }}>
        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
          <div>
            <h3 className="font-semibold text-lg leading-5">{title}</h3>
          </div>
        </div>
        <div className="p-2">
          <div className="flex p-2 w-full items-center justify-center">
          {title === "Expenses to Pay" && (
            <Link to="/approvals" state={{ activeTab: "Expenses" }}>
              <h2 className="text-3xl w-24 h-24 flex justify-center items-center rounded-full bg-yellow-light2 secondry-text">
              {value ? value : 0}
            </h2>
            </Link>
          )}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default DashboardboxExpense;