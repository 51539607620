
/* eslint-disable camelcase */

import { useState, useCallback, useRef} from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import {
  SearchField
  
} from "../../../components/filters";
import {
  EditorField
} from "../../../components/formInput";
import {useGetDepartmentListMutation} from "../../../services/approverListApi";
import {useGetEmployeeMutation, useGetAllEmployeeListMutation} from "../../../services/employeeApi";
import { useEffect } from "react";
import {useGetAllLeaveAbsenceMutation, useDeleteLeaveAbsenceMutation, useGetLeaveAbsenceAllRecordMutation} from "../../../services/leaveAbsenceApi";
import { useParams, useSearchParams} from 'react-router-dom';

import Pagination from "../../../components/inc/pagination";
import DatePicker from "react-datepicker";
import moment from 'moment/moment';
import { useGetEmployeeAllDaysScheduleMutation } from "../../../services/employeeApi";
import { useDispatch, useSelector } from "react-redux";
import { employeeInfo } from "../../../redux/EmployeeSlice/employeeSlice";
import { absenceAllRecordDataAction, absenceDataAction } from "../../../redux/AbsenceSlice/AbsenceSlice";

const AssignTicketTeam = () => {
  const dispatch = useDispatch();
  const ignore = useRef(false);
  const dataLeaveApiResponse = useSelector((state) => state?.persistedReducer?.absenceSlice?.absenceData);
  const dataLeaveApiAllRecordResponse = useSelector((state) => state?.persistedReducer?.absenceSlice?.absenceAllRecordData);
  const [ getDepartmentList ] = useGetDepartmentListMutation();
  const [ getEmployee, {data:employeeListAllData, isSuccess:isEmployeeAPISuccess} ] = useGetEmployeeMutation();
  const [ getAllLeaveAbsence, {data: LeaveApiResponse, isSuccess:isSuccessResponse} ] = useGetAllLeaveAbsenceMutation();
  const [ getLeaveAbsenceAllRecord, {data:LeaveApiAllDataRecordResponse, isSuccess:isSuccessAllRecordResponse} ] = useGetLeaveAbsenceAllRecordMutation();  
  const [ deleteLeaveAbsence, { isSuccess: isSuccessDelete, isLoading: isLoadingDelete, isError: isErrorDelete} ] = useDeleteLeaveAbsenceMutation();
  const [ getAllEmployeeList ] = useGetAllEmployeeListMutation();
  const [ open, setOpen ] = useState(false);
  const employeeData = sessionStorage.getItem("employeeData") ? JSON.parse(sessionStorage.getItem("employeeData") ): '' ;
  const [ getEmployeeAllDaysSchedule ] = useGetEmployeeAllDaysScheduleMutation();
  const [ searchParams  ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
  const [ page, setPage ] = useState(pageTerm ? pageTerm : 1);
  const [ pages, setPages ] = useState(1);
  const toastId = useRef(null);
  const {id} =useParams();
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  
  const [ filter, setFilter ] = useState({
    name:'',
    department:''
  });

  const filterEmployees = useCallback((searchTerm) => {
    setFilter({...filter, ['name'] : searchTerm});
    let payload=`/leaves?type=${2}&page=${page}&first_name=${searchTerm}`;
      getAllLeaveAbsence(payload);
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);
   
  useEffect(() => {
    if (!ignore.current){
    if(id){
      getEmployeeAllDaysSchedule(id);
    } else {
      getEmployeeAllDaysSchedule(employeeData?.employee_id);
    }
    getDepartmentList();
    getAllEmployeeList();
    let payload=`/leaves?type=${2}&page=${1}&limit=100`;
    getLeaveAbsenceAllRecord(payload);
  }
  return () => { ignore.current = true; };
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);


  const closeModal = () => {
    setOpen(false);
  };
  
    useEffect(() => { 
      if(filter.name) {
        let payload=`/leaves?type=${2}&page=${page}&first_name=${filter?.name}`;
        getAllLeaveAbsence(payload);
      } else if(filter.department) {
        let payload=`/leaves?type=${2}&page=${page}&department_id=${filter.department}`;
        getAllLeaveAbsence(payload); 
      }else {
        let payload=`/leaves?type=${2}&page=${page}`;
        getAllLeaveAbsence(payload);
      }
      getEmployee(`/employee?page=${page}`);
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ page ]);
 
useEffect(() => {
  if(isEmployeeAPISuccess) {
   dispatch(employeeInfo(employeeListAllData));
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isEmployeeAPISuccess ]);
useEffect(() => {
  if(isSuccessResponse){
    dispatch(absenceDataAction(LeaveApiResponse));
   } 
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessResponse ]);

    useEffect(
      () => {
        if (dataLeaveApiResponse) {
          if (
            dataLeaveApiResponse?.data?.length < 1 &&
            dataLeaveApiResponse?.currentPage > 1
          ) {
            getAllLeaveAbsence(dataLeaveApiResponse?.currentPage - 1);
          }
          setPages(dataLeaveApiResponse?.totalPages);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [ dataLeaveApiResponse ]
    );

useEffect(() => {
  if(isSuccessAllRecordResponse) {
   dispatch(absenceAllRecordDataAction(LeaveApiAllDataRecordResponse));
  }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessAllRecordResponse ]);
useEffect(() => {
      if(dataLeaveApiAllRecordResponse) {
        exportData(dataLeaveApiAllRecordResponse?.data);
      }
    }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ dataLeaveApiAllRecordResponse ]);
    
  var  dataAbence = [];
    
    const exportData = (data) => {
    
     
      data.forEach((dExcel) => {
        let hour = parseInt(dExcel.total_duration) /60;
        let days = hour /8 ;
  // id: dExcel.id,
  let val ={
  Employee: dExcel?.employeeInfo?.first_name,
  Department:  dExcel?.departmentInfo?.department_name,
  companyInfo: { name : `${dExcel?.companyInfo?.name}`},
  applied_by: dExcel?.applied_by?.first_name,
  addedByInfo: dExcel?.addedByInfo?.first_name,
  start_date: moment(dExcel?.start_date).format('DD-MM-YYYY'),
  end_date:moment(dExcel?.end_date).format('DD-MM-YYYY'),
  start_time: dExcel?.start_time,
  end_time: dExcel?.end_time,
  total_duration: `${days} days`,
  status: dExcel?.status === '1' ? 'Active' : 'Inactive',
  type: dExcel.type ? 'Absence' : 'Leave',
  approved_by: dExcel?.approvedByInfo?.first_name,
  approved_date:moment( dExcel?.approved_date).format('DD-MM-YYYY'),
  notes: dExcel?.notes,
  reason: dExcel.reason,
  cancel_reason: dExcel?.cancel_reason,
  createdAt: moment(dExcel?.createdAt).format('DD-MM-YYYY'),
  updatedAt: moment(dExcel?.updatedAt).format('DD-MM-YYYY'),
  deletedAt: dExcel?.deletedAt? moment(dExcel?.deletedAt).format('DD-MM-YYYY'):'null'

 
};
dataAbence.push(val);
        });
    return dataAbence;
    };

    const decrementPage = () => {
      getAllLeaveAbsence(dataLeaveApiResponse?.currentPage - 1 );
      setPage(dataLeaveApiResponse?.currentPage - 1);
    };

  const onDateChange = (e) => {
    if(e[0] !== null && e[1] !== null){
      let payload=`/leaves?type=${2}&page=${page}&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}`;
        getAllLeaveAbsence(payload); 
 
    } else {
      let payload=`/leaves?type=${2}&page=${page}`;
        getAllLeaveAbsence(payload); 
    }
  };

  
 
  useEffect(() => {
    if(isLoadingDelete) {
      toastId.current = toast.loading("Please wait...", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        type: "info"
      });
     }
    if(isSuccessDelete) {
      // getDeleteDataHoliday();
      toast.update(
        toastId.current,
        ("Absence deleted successfully.",
        {
          render: "Absence deleted successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false
        })
      );
      let payload=`/leaves?type=${2}&page=${page}`;
      getAllLeaveAbsence(payload);
    }
    if (isErrorDelete) {
      const toastMessage = 
      // apiError.data.message
      //   ? apiError.data.message
      //   :
         "Something went wrong";
      toast.update(
        toastId.current,
        (toastMessage,
        {
          render: toastMessage,
          type: "error",
          autoClose: 5000,
          isLoading: false
        })
      );
    }
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isErrorDelete, isSuccessDelete, isLoadingDelete ]);
  
 const handlerDelete = (e, id, close) => {
  e.preventDefault();
  deleteLeaveAbsence(id);
  close();
 };
 const [ replyActive, setReplyActive ] = useState(false);
  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | My Ticket</title>
          </Helmet>
        </HelmetProvider>

        <div>
          <div className="py-6">
            <div className="container mx-auto ">
              <h2 className="text-xl mb-3 font-semibold ">Tickets</h2>
              <div className="card bg-white  mb-3">
                <div className="heading_title px-4 flex justify-end items-center py-4"/>           
                
                <div className="p-4">
                  <div className="flex pt-2 mb-6 filterSection flex-wrap lg:flex-nowrap w-full justify-between ">
                    <div className=" flex flex-wrap w-full">
                      <div className="searchSec md:w-1/3 md:pr-4 w-full mb-2 lg:mb-0 ">
                      <SearchField placeholderText="Search by title" searchId="assignTeamSearch"
                       onChange={(e) => filterEmployees(e.target.value)} 
                      />
                      </div>
                      <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 ">
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        id="assignTeamDate"
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        className="formControl"
                        placeholderText="Select Date Range"
                        onChange={(update) => {
                          setDateRange(update);
                          onDateChange(update);
                        }}
                        isClearable={true}
                        />
                      </div>
                 
                    </div>
                  </div>
                  <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto table  w-full">
                      <thead className="thead-dark ">
                        <tr>
                          <th>Ticket Title</th>                        
                          <th>Decription</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td >Leave Calendar</td> 
                           {/* eslint-disable-next-line */}                         
                          <td>Don't add leave</td>                          
                          <td>Done</td>                      
                          <td>                  
                          <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <div
                              onClick={() => setOpen((o) => !o)}
                              className="actionBtn"
                            >
                              <i className="fa-solid fa-eye"/>
                            </div>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="assignTeamClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <div className="header">                               
                                <h3 className=" mr-2 secondry-text flex"><span className="text-lg">Ticket Details</span></h3>
                              </div>
                              <div className="content">
                              <div className="w-1/2 mb-5">
                           
                            <label className="formBlock">Ticket Title: </label> 
                         
                            <p>                            
                            Leave Calendar	</p>
                             </div>
                          
                             <div className="w-full mb-5 ">
                            <label className="formBlock">Decription:</label>
                            <p >Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                             </div>
                             <div className="w-1/2 mb-5">
                     
                            <label className="formBlock">Date: </label> 
                         <p>
                                                    
                           10/10/2023	</p>
                             </div>
                            
                            <div className="w-full flex justify-center mb-5" >
                            <button className="btn save mr-2 text-base" onClick={ () => setReplyActive(!replyActive)} >
                              <span className="fa fa-pencil mr-1"/>  Reply
                            </button>
                            </div>

                            { replyActive
                              && <>
                              <div className="w-full mb-5">
                              <label className="formBlock	">Comments:</label>
                              <EditorField/>
                              </div>
                              <div className="w-full">
                              <label className="formBlock	">Staus</label>
                                <select className="formControl">
                                  <option>Done</option>
                                </select>
                              </div>
                              <div className="w-full mt-8">
                                      <div className="form-group pt-4 pb-3 flex justify-center ">
                                        <button className="btn save mr-2" id="assignTeamSend" onClick={(e) => (e, close)}>
                                          Send
                                        </button>
                                        <button
                                          type="button"
                                          id="assignTeamNo"
                                          onClick={() => close()}
                                          className="btn cancel "
                                        >
                                          No
                                        </button>
                                      </div>
                               </div>
                              </>


                            }
                              </div>
                            </div>
                          )}
                        </Popup>
                        <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <div
                                  onClick={() => setOpen((o) => !o)}
                                  className="actionBtn cancelBtn"
                                >
                                  <i className="fa fa-trash" />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="assignTeamDeleteClose"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    {" "}
                                    <h3>Deleted</h3>
                                  </div>
                                  <div className="content">
                                    <p>
                                      Are you sure you want to delete this ?
                                    </p>
                                    <div className="w-full mt-8">
                                      <div className="form-group pt-4 pb-3 flex justify-center ">
                                        <button className="btn save mr-2" id="assignTeamDeleteYes" onClick={(e) => handlerDelete(e, close)}>
                                          Yes
                                        </button>
                                        <button
                                          type="button"
                                          id="assignTeamDeleteNo"
                                          onClick={() => close()}
                                          className="btn cancel "
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popup>
          
                      </td>
                         
                        </tr>
                      </tbody>
                    </table>
                    <Pagination page={page} pages={pages} changePage={setPage} decrementPage={decrementPage}/>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignTicketTeam;

