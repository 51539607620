import { useEffect, useState, useCallback, useRef } from "react";
import { SearchField } from "../../../components/filters";
import { FormSelect } from "../../../components/formInput";
import {useEmployeeOverTimeApporveMutation} from "../../../services/approverListApi";
import OverTimeApprove from "./OverTimeApporve";
import ApprovedOverTime from "./ApprovedOverTime";
import DatePicker from "react-datepicker";
import {useGetAllOverTimeMutation} from "../../../services/overTimeApi";
import  moment from 'moment';
import { CSVLink } from 'react-csv';
import { useSelector } from "react-redux";
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // Import the locale

registerLocale('en-GB', enGB);

const OverTime = ({ setOverTimeCount }) => {
  const ignore = useRef(false);
  const [ getAllMaterOverTimeList, {data: allMasterOverTimeResponse, isSuccess} ] = useGetAllOverTimeMutation();
  const [ employeeOverTimeApporve, {data: getOverTimeApproveStatusResp, isSuccess: isSuccessOverTimeStatus, isLoading} ] = useEmployeeOverTimeApporveMutation();
  
  const [ allOverTimeToApporve, setAllOverTimeToApprove ] = useState();
  const [ allApproveToOverTime, setAllApproveToOverTime ] = useState();
  const [ toggleState2, setToggleState2 ] = useState("ExpenseApprove");
  const [ dateRange, setDateRange ] = useState([ null, null ]);
  const [ startDate, endDate ] = dateRange;
  const [ overTimeType, setOverTimeType ] = useState();
  const [ excelData, setExcelData ] = useState([]);
  const createCsvFileName = () => `MyOverTime_Approval_report_${moment().format()}.csv`;
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };

 useEffect(() => {
  if (!ignore.current){
  const url = `/masterovertime?page=${1}&limit=50`;
  getAllMaterOverTimeList(url);
  let payload=`overtimes?overtime_lieu_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
  employeeOverTimeApporve(payload);
}
return () => { ignore.current = true; };
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

 useEffect(() => {
  setOverTimeCount(allOverTimeToApporve?.length);
});


 useEffect(() => {
  if(isSuccess) {
  setOverTimeType(allMasterOverTimeResponse.data);
  }
 }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess ]);
 const employeeIdData =  useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);
 const onDateChange = (e) => {
  if(e[0] !== null && e[1] !== null){
    let payload=`overtimes?overtime_lieu_approver=${employeeIdData}&start_date=${moment(e[0]).format('YYYY-MM-DD')}&end_date=${moment(e[1]).format('YYYY-MM-DD')}&exclude_self=true`;
    employeeOverTimeApporve(payload);

  } else {
    let payload=`overtimes?overtime_lieu_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
    employeeOverTimeApporve(payload);
  }
};

 const handlerChangeByOvertimeName = (e) => {
  e.preventDefault();
  if (e.target.value === '0') {
    let payload=`overtimes?overtime_lieu_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
    employeeOverTimeApporve(payload);
  } else {
  let payload=`/overtimes?m_overtime_type_id=${e.target.value}&overtime_lieu_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
  employeeOverTimeApporve(payload);
  }
 };
// .........................By name filter
const myOvertimeFilter = useCallback((searchTerm) => {
  let payload=`/overtimes?first_name=${searchTerm}&overtime_lieu_approver=${employeeIdData}&page=1&limit=100&exclude_self=true`;
    employeeOverTimeApporve(payload);
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[]);

  

  useEffect(() => {
    if( isSuccessOverTimeStatus) {
      let overtimeApprove = [];
      let approvedOverTime = [];
      getOverTimeApproveStatusResp?.data?.forEach((over) => {
       if(over.status === '0') {
        overtimeApprove.push(over);
       } else {
        approvedOverTime.push(over);
       }
      });
      setAllOverTimeToApprove(overtimeApprove);
      setAllApproveToOverTime(approvedOverTime);
      if(getOverTimeApproveStatusResp?.data){
        let csvData = exportTOCSV(getOverTimeApproveStatusResp?.data);
        setExcelData(csvData);
      }
      
    } 
    
   }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessOverTimeStatus ]);
  
   let  headers = [
    { label: 'Employee Name', key : 'employeeName'},
    { label: 'Department Name', key : 'departmentName'},
    { label: 'Type', key : 'type'},
    { label: 'Start Date', key : 'start_date'},
    { label: 'Start Time', key : 'start_time'},
    { label: 'End Time', key : 'end_time'},
    { label: 'Duration(Days/Hours)', key : 'total_duration'},
    { label: 'Status', key : 'status'},
    { label: "Time in Lieu", key: 'time_in_lieu'},
    { label: 'Notes', key : 'notes'},
    { label: 'CreatedAt', key : 'createdAt'},
    { label: 'UpdatedAt', key : 'updatedAt'}
   
    ];

    const exportTOCSV = (overTimeinFo) => {
      let  dataAbence =[];
      if(overTimeinFo){
        overTimeinFo?.forEach((dExcel) => {
          let hour = parseInt(dExcel.duration) /60;
        
          let val ={
            employeeName: dExcel?.employeeInfo?.first_name,
            departmentName: dExcel?.department_info?.department_name,
            type: dExcel?.mOverTimeInfo?.overtime_name,
            start_date: moment(dExcel?.start_date).format('DD-MM-YYYY'),
            start_time: dExcel?.start_time,
            end_time: dExcel?.end_time,
            total_duration: hour,
            status: dExcel?.status === '1' ? 'Active' : 'Unactive',
            time_in_lieu:  dExcel?.time_in_lieu === '1' ? 'Yes' : 'No',
            notes: dExcel?.notes,
            createdAt: moment(dExcel?.createdAt).format('DD-MM-YYYY'),
            updatedAt: moment(dExcel?.updatedAt).format('DD-MM-YYYY')
         };
          dataAbence.push(val);
        });
        return dataAbence;
      }
      
   };
  
  return (
    <>
       <div className="   mb-3">
      <div className="p-4">
        <div className="flex pt-2 pb-6 filterSection flex-wrap lg:flex-nowrap w-full justify-between ">
          <div className="w-full flex flex-wrap">
            <div className="searchSec md:w-1/3 md:pr-4 w-full mb-2 lg:mb-0 ">
            <SearchField placeholderText="Search By Name" id="overtimeSearch"
                     onChange={(e) => myOvertimeFilter(e.target.value)}
                      />
            </div>
            <div className="searchSec md:w-1/3 md:pr-4 w-full md:mb-0 mb-2 ">
            <DatePicker
               id="overtimeDate"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                className="formControl"
                placeholderText="Select Date Range"
                onChange={(update) => {
                  setDateRange(update);
                  onDateChange(update);
                }}
                isClearable={true}
                locale="en-GB"
              />
            </div>
            <div className=" searchSec md:w-1/3 w-full md:mb-0 mb-4 lg:pr-4">
                 <select
                  className="formControl"
                  onChange={(e) => handlerChangeByOvertimeName(e)}
                  id="overtimeApproveFilter"
                >
                  <FormSelect optionValue={""} optionName={"Select Overtime"} />
                  ;
                  {overTimeType?.map((overtime, key) => {
                    return (
                      <FormSelect
                        key={key}
                        optionValue={overtime?.id}
                        optionName={overtime?.overtime_name}
                      />
                    );
                  })}
                </select>
            </div>
          </div>
          <div className="headerButton flex justify-end  items-center exportFile lg:w-96  w-full">
          <CSVLink
            className=""
            data={excelData}
            headers={headers}
              filename={createCsvFileName()}
              target="_blank"
            style={{ textDecoration: 'none', outline: 'none', height: '5vh' }}
        >
            <button  className="btn-md btn-add " id="addCsv">
            <span className="fa fa-file-export mr-2" /> Export to CSV
            </button>
          </CSVLink>
          </div>
        </div>
          <ul className="flex approvalSubTab mb-8 ">
            <li>
              <span
                onClick={() => toggleTab2("ExpenseApprove")}
                className={toggleState2 === "ExpenseApprove" ? " active" : "  "}
              >
                Overtime/Time in Lieu to Approve
              </span>
            </li>
            <li>
              <span
                onClick={() => toggleTab2("ApprovedExpense")}
                className={
                  toggleState2 === "ApprovedExpense" ? " active" : "  "
                }
              >
                Approved Overtime/Time in Lieu
              </span>
            </li>
          </ul>
          <div className="tabCard ">
            <div
              className={
                toggleState2 === "ExpenseApprove"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
            <OverTimeApprove allOverTimeToApporve={allOverTimeToApporve}
               employeeOverTimeApporve={ employeeOverTimeApporve}
              employeeId= {employeeIdData}
              isLoading={isLoading}
            />         
            </div>
            <div
              className={
                toggleState2 === "ApprovedExpense"
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
             <ApprovedOverTime allApproveToOverTime={allApproveToOverTime}
             employeeOverTimeApporve={ employeeOverTimeApporve}
             isLoading={isLoading}
             employeeId= {employeeIdData}
             />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverTime;
