
import {Header} from "./header";
import {Outlet} from "react-router-dom";





export function Layout() {
 
    return (
        <div>
            <div className="xl:w-full mx-auto min-h-screen relative flex justify-between  ">              
                <div className="contentArea w-full ">              
                  <Header/>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}