import { useState, useEffect, useRef } from "react";
import { read, utils } from "xlsx";
import { useImportAllEmployeeMutation } from "../../services/employeeApi";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Popup from "reactjs-popup";

const ImportData = ({ importDataUpdatePermissions }) => {
  //States
  const [file, setFile] = useState();
  const [tableData, setTableData] = useState();
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  const tableData1 = [
    {
      first_name: "ABC",
      last_name: "XYZ",
      email: "asd@gmail.com",
      mobile_no: "9089786756",
      country_code: "+91",
      dob: "15/09/1990",
      employment_start_date: "02/07/2023",
      annual_allowance: 30,
      maximum_leave_carry: 5,
      allowance_type: 1,
      address: "ADDRESS",
      postcode: "232334",
      gender: "male",
      department: "random",
      job_title: "Employee"
    }
  ];

  const handleMultipleUpload = (e) => {
    let file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { raw: true });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 0,
            defval: ""
          });
          if (rows.length > 0) {
            let columns = Object.keys(rows[0]);

            let available_columns = columns
              .map((col) => {
                let val = col
                  .replace("-", "_")
                  .replace(/[^\w ]/g, "")
                  .replace(/^\s+|\s+$/gm, "")
                  .split(" ")
                  .join("_")
                  .replace("-", "_")
                  .toLowerCase();
                return {
                  label: col,
                  value: val
                };
              })
              .filter(Boolean);
            setTableData({
              file_rows: rows,
              file_columns: columns,
              available_file_columns: available_columns
            });
            setOpen(true);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <div className="heading_title px-4 flex justify-between items-center py-4">
        <div>
          <h3 className=" font-semibold text-lg">Import Employees</h3>
        </div>
      </div>
      <div className="p-4">
        <div className=" mb-4">
          {importDataUpdatePermissions[0]?.status === true && (
            <div className="file_Upload">
              <input
                type="file"
                name="file"
                className="upload-button"
                id="exampleFile"
                onClick={(event) => (event.target.value = null)}
                onChange={handleMultipleUpload}
              />
              <p>File Uplaod</p>
            </div>
          )}

          <Popup
            open={open}
            onClose={() => closeModal()}
            closeOnDocumentClick={false}
            className="importPopup"
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button
                  className="close"
                  onClick={() => {
                    closeModal();
                    close();
                  }}
                >
                  &times;
                </button>
                <ExcelData
                  tableData={tableData}
                  title={"Employee Information"}
                  file={file}
                  close={close}
                  setFile={setFile}
                  setOpen={setOpen}
                />
              </div>
            )}
          </Popup>
        </div>
        <CSVLink
          className="text-blue cursor-pointer text-base	mt-4"
          data={tableData1 ? tableData1 : ""}
          filename={"Employee Import Spreadsheet.csv"}
        >
          <span className="fa fa-download mr-2" /> Import File
        </CSVLink>
        <div className="mt-4">
          <h1>Please follow these instruction while updating your excel file</h1>
          <p className="mt-2">1. Date of Birth :- DD/MM/YYYY</p>
          <p>2. Employment Start Date :-	DD/MM/YYYY</p>
          <p>3. Allowance Type :- 1 = Hours,	2 = Days</p>
          <p>4. Gender Info :-	male, female, other	</p>
        </div>
      </div>
    </>
  );
};

export default ImportData;

const ExcelData = ({ tableData, title, file, close, setFile }) => {

  //Api Calling
  const [ importAllEmployee, { data: importAllData, isSuccess: isSuccessImport, isError: isErrorImport, isLoading: isLoadingImport, error: apiError }] = useImportAllEmployeeMutation();

  //States
  const [employeImportListPopup, setEmployeeImportListPopup] = useState(false);
  const toastId = useRef(null);


  const handlerFileSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const blob = new Blob([file], { type: "text/csv" });
    formData.append("csvfile", blob);
    importAllEmployee(formData);
    setEmployeeImportListPopup(true);
  };

  const closeModalEmployeeImport = () => {
    setEmployeeImportListPopup(false);
  };

  useEffect(
    () => {
      if (isLoadingImport) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessImport) {
        toast.update(
          toastId.current,
          ("Employee data added successfully!",
          {
            render: "Employee data added successfully!",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
      }
      if (isErrorImport) {
        const toastMessage =
          apiError.data.message
            ? apiError.data.message
            :
          "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingImport, isSuccessImport, isErrorImport]
  );

  return (
    <>
      <div className="header">
        {" "}
        <h3>{title}</h3>
      </div>
      <div className="content">
        <div className="w-full">
          <div className="view_tbl_bdr">
            <div className="row">
              <div className="col-md-12">
                <div className=" view_tbl_exp">
                  <table className="table file_preview_tbl table-fixed">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        {tableData?.file_columns?.length > 0 ? (
                          tableData?.file_columns?.map((cols, index) =>
                            // Check if the column is "Instruction"
                            cols !== "Instruction" ? (
                              <th scope="col" key={index}>
                                {cols}
                              </th>
                            ) : null // If it's "Instruction", don't render anything
                          )
                        ) : (
                          <center>No data found</center>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.file_rows?.length > 0
                        ? tableData?.file_rows?.map((file_row, index) => {
                            // Filter out the Instruction key from file_row
                            // eslint-disable-next-line no-unused-vars
                            const { Instruction, ...dataWithoutInstruction } =
                              file_row;
                            let colVals = Object.values(dataWithoutInstruction);
                            return (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                {colVals.map((colVal, idx) => {
                                  return <td key={idx}>{colVal}</td>;
                                })}
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                  <Popup
                    open={employeImportListPopup}
                    onClose={() => closeModalEmployeeImport()}
                    closeOnDocumentClick={false}
                    className="importPopup importSuccessPopup"
                    modal
                    nested
                  >
                    {() => (
                      <div className="modal" style={{ paddingBottom: "10px" }}>
                        <button
                          className="close"
                          onClick={() => {
                            closeModalEmployeeImport();
                          }}
                        >
                          &times;
                        </button>
                        <h3 className="header">{title}</h3>
                        <div className=" view_tbl_exp">
                          <table className="table file_preview_tbl table-fixed mt-2">
                            <thead>
                              <th scope="col">First name</th>
                              <th scope="col">Last Name</th>
                              <th scope="col">Email address</th>
                              <th scope="col">Mobile no</th>
                              <th scope="col">Country Code</th>
                              <th scope="col">DOB</th>
                              <th scope="col">Start Date</th>
                              <th scope="col">Annual Allowance</th>
                              <th scope="col">
                                Maximum Leaves to Carry Forward
                              </th>
                              <th scope="col">Hours or Day</th>
                              <th scope="col">Address</th>
                              <th scope="col">Postcode</th>
                              <th scope="col">Gender</th>
                              <th scope="col">Department</th>
                              <th scope="col">Job Title</th>
                              <th scope="col">Role</th>
                              <th scope="col">Status</th>
                            </thead>
                            <tbody>
                              {importAllData?.importedData.created.map(
                                (employee, index) => (
                                  <tr key={`created_${index}`} style={{ backgroundColor: '#ccffcc'}}>
                                    <td>{employee?.first_name}</td>
                                    <td>{employee?.last_name}</td>
                                    <td>{employee?.email}</td>
                                    <td>{employee?.mobile_no}</td>
                                    <td>{employee?.country_code}</td>
                                    <td>{employee?.dob}</td>
                                    <td>{employee?.employment_start_date}</td>
                                    <td>{employee?.annual_allowance}</td>
                                    <td>{employee?.maximum_leave_carry}</td>
                                    <td>{employee?.hours_or_day}</td>
                                    <td>{employee?.address}</td>
                                    <td>{employee?.postcode}</td>
                                    <td>{employee?.gender}</td>
                                    <td>{employee?.department}</td>
                                    <td>{employee?.job_title}</td>
                                    <td>{employee?.role}</td>
                                    <td>{employee?.status}</td>
                                  </tr>
                                )
                              )}
                              {importAllData?.importedData.skipped.map(
                                (employee, index) => (
                                  <tr key={`skipped_${index}`} style={{ backgroundColor: '#ffcccc'}}>
                                    <td>{employee?.first_name}</td>
                                    <td>{employee?.last_name}</td>
                                    <td>{employee?.email}</td>
                                    <td>{employee?.mobile_no}</td>
                                    <td>{employee?.country_code}</td>
                                    <td>{employee?.dob}</td>
                                    <td>{employee?.employment_start_date}</td>
                                    <td>{employee?.annual_allowance}</td>
                                    <td>{employee?.maximum_leave_carry}</td>
                                    <td>{employee?.hours_or_day}</td>
                                    <td>{employee?.address}</td>
                                    <td>{employee?.postcode}</td>
                                    <td>{employee?.gender}</td>
                                    <td>{employee?.department}</td>
                                    <td>{employee?.job_title}</td>
                                    <td>{employee?.role}</td>
                                    <td>{employee?.status}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w w-full  ">
          <div className="w-full mt-4">
            <div className="form-group pt-4 pb-3 flex justify-center flex-col md:flex-row ">
              <button
                className="btn save mr-2 mb-2 sm:mb-0"
                id="emportDataSave"
                onClick={(e) => handlerFileSubmit(e)}
              >
                {" "}
                Save{" "}
              </button>
              <button
                className="btn cancel"
                id="emportDataCancel"
                onClick={() => {
                  close();
                  setFile();
                }}
              >
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
