
/* eslint-disable camelcase */
import {FormTextarea
} from "../../../components/formInput";
import {useApprovedOvertimeDeleteMutation} from '../../../services/approverListApi';
import { useEffect, useRef, useState} from 'react';
import { toast } from "react-toastify";

const ApproveCancel = ({over, close,  employeeOverTimeApporve, employeeId}) => {
    const [ approvedOvertimeDelete, { isSuccess: isSuccessStatus, isError: isErrorStatus, isLoading: isLoadingStatus, error: apiError } ] =  useApprovedOvertimeDeleteMutation();
       const [ formData, setFormData ] = useState();
       const [ error, setError ] = useState({
        errorNotes:''
      });
       const toastId = useRef(null);
       const handlerChange = (e) => {
        e.preventDefault();
        const {name} = e.target;
        if (name === 'notes') {
            if(name === '') {
              setError({...error, ['errorNotes '] : 'Please enter the notes'});
            } else {
              setError({...error, ['errorNotes'] : ''});
            }
          }
        setFormData({...formData, [name]: e.target.value});
    };

       useEffect(() => {
        if(isLoadingStatus){
          toastId.current = toast.loading("Please wait...", {
            // position: "top-center",
            position:"top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            type: "info"
          });
        }
        if(isSuccessStatus) {
          toast.dismiss(toastId.current);
          toast.update(
            toastId.current,
            ("Overtime deleted successfully!",
            {
              render: "Overtime deleted successfully!",
              type: "success",
              autoClose: 8000,
              isLoading: false
            })
          );
          let payload=`overtimes?overtime_lieu_approver=${employeeId}&page=1&limit=100`;
          employeeOverTimeApporve(payload);
          close();
        }
        if (isErrorStatus) {
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 10000);
          const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
          toast.update(
            toastId.current,
            (toastMessage,
            {
              render: toastMessage,
              type: "error",
              autoClose: 8000,
              isLoading: false
            })
          );
        }
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccessStatus, isErrorStatus, isLoadingStatus ]);

    const handlerCancel = (e, over) => {
        e.preventDefault();
         approvedOvertimeDelete(over?.id);
      };
    return (
        <>
        <div className="header">
                        {" "}
                        <h3>Decline</h3>
                      </div>
                      <div className="content">
                        <form>
                          {" "}
                          <label className="formBlock">
                          Reason <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /></label>
                          <FormTextarea textName="notes" handlerChange = {handlerChange} inputID="reason" />
                          <div className="help-block text-red-700 mt-1">{error?.errorNotes ? error?.errorNotes :''}</div>
                        </form>
                        <div className="w-full mt-8">
                          <div className="form-group pt-4 pb-3 flex justify-center ">
                            <button className="btn save mr-2" 
                            id="overtimeApprovalSubmit"
                            onClick = {(e) => handlerCancel(e, over)}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              id="overtimeApprovalCancel"
                              onClick={() => close()}
                              className="btn cancel "
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
        </>
    );
};

export default ApproveCancel;