// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useRef } from 'react';
import {Link} from 'react-router-dom';
import {ModifiedToast} from './toaster';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {FormInput} from './formInput';
import PrimaryButton from './primaryButton';
import { useForgotPasswordMutation } from '../services/authApi';
import { toast } from "react-toastify";
import { emailValidator } from '../pages/settings/validation';
import { apiStatusHandler } from '../helper/apiErrorHandler';
import backgroundImage from '../loginBg.webp';
const ForgotPassword = () => { 
    const [ email, setEmail ] = useState('');
    const [ error, setError ] = useState('');
    const [ forgotPassword, { isSuccess, isLoading, isError, error:apiError } ] = useForgotPasswordMutation();

    const handlerChangeSubmit = (e) => {       
        let item = emailValidator(e.target.value);
        if(item) {
            setError(true);
        } 
        else{
            setError(false);
        }
        setEmail(e.target.value);  

    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if(!error){
            forgotPassword(email);
        }  
    };

    const toastId = useRef(null);
    //const accessToken = localStorage?.getItem("accessToken");
    useEffect(() => {
        if (isLoading) {
          toastId.current = toast.loading("Please wait...", {
        //   position: "top-center",
        position:"top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
        }
        if (isSuccess) {
            toast.dismiss(toastId.current);
            toast.update(
                toastId.current,
                ("Reset link sent successfully on email.",
            {
              
              render: "Reset link sent successfully on email.",
              type: "success",
              autoClose: 8000,
              isLoading: false
            })
          );
        }
        if (isError) {
            toast.dismiss(toastId.current);

            const toastMessage = apiError?.data?.message
            ? apiError?.data?.message
            : "Something went wrong";
            toast.update(
            toastId.current,
            (toastMessage,
            {
              render: toastMessage,
              type: "error",
              autoClose: 8000,
              isLoading: false
            })
          );
          apiStatusHandler(apiError?.response?.status);
        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
      }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ isSuccess, isError, isLoading ]);

    return (
        <div className=" flex loginGradient w-full" style={{background: `url(${backgroundImage})`}}>
            <HelmetProvider>
                <Helmet>
                    <title>Schedulize | Login </title>
                </Helmet>
            </HelmetProvider>           
            <ModifiedToast/>
            <div className=" flex align-center  w-full">
                <div className=" w-full  ">
                    <div
                        className="md:flex align-center justify-between h-full items-center flex-wrap md:flex-nowrap 	">
                        <div className=" lg:p-8 p-2 lg:w-1/2 w-full relative mx-auto">
                            <div className=" pt-0">
                                <div
                                    className="w-full max-w-lg m-auto bg-white p-8 border shadow-lg rounded-lg ">
                                     <div className="text-center  w-full 	">
                                        <img
                                            src="../images/logo-full.webp"
                                            width="220"
                                            className="mx-auto"
                                        />
                                    </div>
                                    <h1 className="text-xl font-semibold my-6 mb-8 text-center">
                                    Forgot your password?

                                    </h1>
                                    <form
                                        onSubmit={handleFormSubmit}
                                        className="text-left"
                                        id="loginForm"
                                    >
                                        <div className="parentGroup mb-5">
                                            <label
                                                htmlFor="email"
                                                className="formBlock"
                                            >
                                            {""} 
                                            Email
                                            </label>
                                          <FormInput inputType="text" inputName="email" inputID="email" handlerChange={(e) => handlerChangeSubmit(e)}
                                          />
                                            <div className="help-block" />
                                        </div>
                                        <div className="help-block text-red-700">{error && <p>Please enter a valid email</p>}</div>
                                        
                                        <div className=" mt-8 text-center">
                                        <PrimaryButton btnText="Submit " id="forgotPasswordSubmit"  Btnclass="btn save w-full  mb-6"/>
                                         
                                            <p className='text-gray-600 font-medium'><Link to="/login" className='primary-text font-semibold'>Login</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div> 
                        </div>
                     
                    </div>
                </div>
            </div>
            <ModifiedToast/>
        </div>
    );
};

export default ForgotPassword;