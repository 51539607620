import { useState, useRef } from "react";
import { FormInput } from "../../../components/formInput";
import PrimaryButton from "../../../components/primaryButton";
import { useCreateOverTimeMutation } from "../../../services/overTimeApi";
import { useEffect } from "react";
import { toast } from "react-toastify";

const AddOverTimeSetting = ({ getAllOverTime, page, close }) => {

  //create overtime api calling
  const [createOverTime, {isSuccess: isSuccessCreate, isLoading: isLoadingCreate, isError: isErrorCreate, error: createOvertimeError }] = useCreateOverTimeMutation();

  //States and Hooks
  const toastId = useRef(null);
  const [formData, setFormData] = useState({
    overtime_name: "",
    color: "#000000",
    time_in_lieu: "0",
    available_to: "0",
    status: "1",
    multiplyOvertimeBy: 1,
    available_to_all: null
  });
  const [error, setError] = useState({
    errorOvertimeName: "",
    errorColor: "",
    errorAvailableTo: "",
    errorStatus: ""
  });

  const handlerChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    if (name === "overtime_name") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorOvertimeName: "Please select overtime name"
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorOvertimeName: ""
          };
        });
      }
    }
    if (name === "color") {
      if (name === "") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorColor: "Please select color"
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorColor: ""
          };
        });
      }
    }
    if (name === "available_to") {
      if (name === "0") {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorAvailableTo: "Please select available "
          };
        });
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            errorAvailableTo: ""
          };
        });
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        available_to_all: e.target.value === "1" ? "1" : ""
      }));
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: e.target.value
    }));
  };

  const handlerChangCheck = (e, name) => {
    setFormData({ ...formData, [name]: e.target.checked ? "1" : "0" });
  };
  const validation = () => {
    const { overtime_name, color, available_to } = formData;
    let isFormValidated = true;
    if (overtime_name === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          errorOvertimeName: "Please select overTime name"
        };
      });
      isFormValidated = false;
    }
    if (color === "") {
      setError((errorValue) => {
        return {
          ...errorValue,
          errorColor: "Please select color"
        };
      });
      isFormValidated = false;
    }
    if (available_to === "0") {
      setError((errorValue) => {
        return {
          ...errorValue,
          errorAvailableTo: "Please select available "
        };
      });
      isFormValidated = false;
    }
    return isFormValidated;
  };

  const overTimeSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      const value = {
        available_to: formData.available_to,
        color: formData.color,
        overtime_name: formData.overtime_name,
        status: formData.status ? (formData.status === "2" ? "0" : "1") : "1",
        time_in_lieu: formData.time_in_lieu,
        available_to_all: formData?.available_to_all === "1" ? "1" : "0",
        multiplyOvertimeBy: parseFloat(formData?.multiplyOvertimeBy)
      };
      createOverTime(value);
    }
  };

  const closeModal = () => {
    close();
  };

  useEffect(
    () => {
      if (isLoadingCreate) {
        toastId.current = toast.loading("Please wait...", {
          toastId: "overtime11",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessCreate) {
        toast.dismiss(toastId.current);
        toast.success("Overtime created successfully.", {
          render: "Overtime created successfully.",
          type: "success",
          autoClose: 10000,
          theme: "colored",
          isLoading: false
        });
        const url = `/masterovertime?page=${page}&limit=50`;
        getAllOverTime(url);
        close();
      }
      if (isErrorCreate) {
        toast.dismiss(toastId.current);
        const toastMessage = createOvertimeError?.data?.message
          ? createOvertimeError?.data?.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingCreate, isSuccessCreate, isErrorCreate]
  );
  return (
    <>
      <div className="header">
        <h3>Add Overtime Type</h3>
      </div>
      <div className="content">
        <form onSubmit={overTimeSubmit}>
          <div className="flex flex-wrap ">
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Name
                  <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                </label>
                <FormInput
                  inputType="text"
                  inputName="overtime_name"
                  inputID="Name"
                  handlerChange={handlerChange}
                />
                <div className="help-block text-red-700">
                  {error.errorOvertimeName ? error.errorOvertimeName : ""}{" "}
                </div>
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Status
                  <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                </label>
                <select
                  className="formControl"
                  id="overtimeTypesStatus"
                  name="status"
                  value={formData.status}
                  onChange={(e) => handlerChange(e)}
                >
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                </select>
                <div className="help-block text-red-700">
                  {error.errorStatus ? error.errorStatus : ""}{" "}
                </div>
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Multiply Overtime By</label>
                <FormInput
                  inputType="number"
                  inputName="multiplyOvertimeBy"
                  inputID="multiplyOvertimeBy"
                  handlerChange={handlerChange}
                />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Available to
                  <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                </label>
                <select
                  className="formControl"
                  id="overtimeTypesAvailable"
                  name="available_to"
                  value={formData.available_to}
                  onChange={(e) => handlerChange(e)}
                >
                  <option value="">Select</option>
                  <option value="1">All</option>
                  <option value="2">Company Admin/HR</option>
                  <option value="3">Manager</option>
                  <option value="4">Employee</option>
                </select>
                <div className="help-block text-red-700">
                  {error.errorAvailableTo ? error.errorAvailableTo : ""}{" "}
                </div>
              </div>
            </div>
            <div className="md:w-1/2 pr-0">
              <div className="form-group mb-6 ">
                <label className="formBlock flex	">
                  <input
                    type="checkbox"
                    className="mr-2 w-5 h-5"
                    id="overtimeTypesAllowTime"
                    name="time_in_lieu"
                    onChange={(e) => handlerChangCheck(e, "time_in_lieu")}
                  />
                  Allow time in lieu on request
                </label>

                <div className="help-block" />
              </div>
            </div>
            <div className="md:w w-full  ">
              <div className="w-full mt-4">
                <div className="form-group pt-4 pb-3 flex justify-center ">
                  <PrimaryButton
                    Btnclass="btn save mr-2"
                    btnText="Save"
                    buttonID="overtimeTypesSave"
                    onClick={(e) => overTimeSubmit(e, false, close, "")}
                  />
                  <PrimaryButton
                    Btnclass="btn cancel "
                    btnText="Cancel"
                    buttonID="overtimeTypesCancel"
                    onClick={() => closeModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddOverTimeSetting;
