import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useLeaveAbsenceListMutation} from "../../services/leaveAbsenceApi";
import {employeeLeaveAndAbsenceAction} from "../../redux/LeaveTypeSlice/leaveSlice";
import {LoadingIndicator} from "../../components/loading-indicator";
import NoDataFound from "../../helper/noDataFound";
import moment from "moment";
import {
  REACT_APP_COMPANY_ADMIN_ROLE_ID,
  REACT_APP_SUPER_ADMIN_ROLE_ID
} from "../../helper/constant";

const MyAbsenceEmployee = ({employeeInfo}) => {
  //Selector for carrying out data from redux
  const employeeAbsenceData = useSelector(
    (state) =>
      state?.persistedReducer?.leaveTypeSlice?.employeeLeaveAndAbsence?.data
  );
  const formattedDate = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.dateFormats
  );
  const roleIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  //Api calling
  const [employeeAbsence, {data, isSuccess, isLoading}] =
    useLeaveAbsenceListMutation(employeeInfo?.id);

  //storing data into redux using dispatch
  const dispatch = useDispatch();
  useEffect(
    () => {
      if (isSuccess) {
        dispatch(employeeLeaveAndAbsenceAction(data));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess]
  );

  useEffect(() => {
    if (employeeInfo?.id) {
      employeeAbsence(employeeInfo?.id);
    }
  }, [employeeInfo?.id]);

  return (
    <>
      <div className="lg:p-7 p-4 timeInLieuPage">
        <div className="overflow-x-auto w-full whitespace-nowrap">
          <table className="table-auto table  w-full">
            <thead className="thead-dark ">
              <tr>
                {roleIdData !== REACT_APP_COMPANY_ADMIN_ROLE_ID &&
                  roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID && (
                    <th className="w-40"> Name</th>
                  )}
                <th>Department</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Absence Duration</th>
                <th>
                  Bradford Factor <br /> In Days
                </th>
                <th>Reason</th>
                {(roleIdData == REACT_APP_COMPANY_ADMIN_ROLE_ID ||
                  roleIdData == REACT_APP_SUPER_ADMIN_ROLE_ID) && (
                  <th>Created By</th>
                )}
              </tr>
            </thead>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <tbody>
                {employeeAbsenceData?.length > 0 ? (
                  <>
                    {employeeAbsenceData &&
                      employeeAbsenceData.map((leave, index) => {
                        //leave?.duration format to hours and min
                        const hours = Math.floor(leave?.total_duration / 60);
                        const minutes = leave?.total_duration % 60;
                        let formattedDuration = "";
                        //if duration is less than 1 hour then do not show hour
                        if (hours > 0) {
                          formattedDuration = `${hours} hour${
                            hours !== 1 ? "s" : ""
                          }`;
                        }
                        //if min = 0 then do not show min
                        if (minutes > 0) {
                          formattedDuration += `${
                            formattedDuration ? " " : ""
                          }${minutes} min${minutes !== 1 ? "s" : ""}`;
                        }
                        return (
                          <tr key={index}>
                            {roleIdData !== REACT_APP_COMPANY_ADMIN_ROLE_ID &&
                              roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID && (
                                <td>
                                  {leave?.addedByInfo?.first_name +
                                    " " +
                                    leave?.addedByInfo?.last_name}
                                </td>
                              )}
                            <td>
                              {leave?.departmentInfo?.department_name
                                ? leave?.departmentInfo?.department_name
                                : "Department not found"}
                            </td>
                            <td>
                              {moment(leave?.start_date).format(formattedDate)}
                            </td>
                            <td>
                              {moment(leave?.end_date).format(formattedDate)}
                            </td>
                            <td>{formattedDuration}</td>
                            <td>
                              {leave?.employeeInfo?.bradford_factor_in_days
                                ? Number(
                                    leave.employeeInfo.bradford_factor_in_days
                                  ).toFixed(2)
                                : "0.00"}
                            </td>
                            <td>
                              {leave?.reason
                                ? leave?.reason
                                : "No reason found"}
                            </td>
                            {(roleIdData == REACT_APP_COMPANY_ADMIN_ROLE_ID ||
                              roleIdData == REACT_APP_SUPER_ADMIN_ROLE_ID) && (
                              <td>
                                {leave?.addedByInfo?.first_name +
                                  " " +
                                  leave?.addedByInfo?.last_name}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  <center className="mt-4">{<NoDataFound />}</center>
                )}
              </tbody>
            )}
          </table>
          {/* <Pagination page={page} pages={pages} changePage={setPage} decrementPage={setPage}/> */}
        </div>
      </div>
    </>
  );
};

export default MyAbsenceEmployee;
