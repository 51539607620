import { useState, useRef } from "react";
import { FormSelect, FormTextarea } from "../../components/formInput";
import DatePicker from "react-datepicker";
import PrimaryButton from "../../components/primaryButton";
import { useGetAllOverTimeMutation } from "../../services/overTimeApi";
import { useCreateMyOvertimeMutation } from "../../services/myOvertimeApi";
import { useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { setMinutes } from "date-fns";
import { useSelector } from "react-redux";
import { calculateTimeDifferenceInMinutes } from "../../helper/helper";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB"; // Import the locale

registerLocale("en-GB", enGB);

const EditMyOvertime = ({ over, close, allOvertimeList }) => {
  const ignore = useRef(false);
  const [ getAllMaterOverTimeList, { data: allMasterOverTimeResponse, isSuccess } ] = useGetAllOverTimeMutation();
  const [ createMyOvertime, { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate, isError: isErrorUpdate, error: apiError } ] = useCreateMyOvertimeMutation();
  const [allOverTimeData, setAllOverTimeData] = useState();
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [duration, setDuration] = useState();
  const [endDays, setEndDays] = useState();
  const [breakTime, setBreakTime] = useState();
  const [multiplyOvertimeBy, setMultiplyOvertimeBy] = useState(1);
  const [result, setResult] = useState("");
  const toastGeneralId = useRef(null);
  const closeModal = () => {
    close();
  };
  const companyId = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id);
  const employeeIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id);
  const [formData, setFormData] = useState({
    start_date: null,
    start_time: "",
    end_time: "",
    duration: "",
    break_type: "20",
    break_time: breakTime,
    time_in_lieu: "0",
    is_paid: "0",
    company_id: companyId,
    m_overtime_types_id: "",
    employee_id: employeeIdData,
    notes: "",
    status: "0"
  });
  const [error, setError] = useState({
    overtimeTypesError: "",
    startTimeError: "",
    endTimeError: "",
    notesError: ""
  });

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 60).toString().padStart(2, '0');
    const minutes = (duration % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if(over !== null){
      const selectedOvertimeId = over?.m_overtime_types_id;
      const selectedOvertime = allOverTimeData?.find(
        (overtime) => overtime?.id === parseInt(selectedOvertimeId)
      );
      setMultiplyOvertimeBy(selectedOvertime?.multiplyOvertimeBy);
      setResult(formatDuration(over?.duration));
    }
  }, [over, allOverTimeData]);

  useEffect(() => {
       setFormData({
        ...formData,
        notes: over.notes,
        m_overtime_types_id: over?.m_overtime_types_id,
        duration: formatDuration(over?.duration)
       });
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[ over ]);

  useEffect(() => {
      if (!ignore.current) {
        let url = `/masterovertime?page=${1}&userSpecific=${1}&limit=50`;
        getAllMaterOverTimeList(url);
      }
      return () => {
        ignore.current = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
      if (isSuccess) {
        setAllOverTimeData(allMasterOverTimeResponse?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess]
  );

  // Example usage:
  var startTimes = over?.start_time;
  var endTimes = over?.end_time;

  let diffInMinutes;
  if (startTime && endTime) {
    diffInMinutes = Math.abs((startTime - endTime) / (1000 * 60));
  } else {
    diffInMinutes = calculateTimeDifferenceInMinutes(startTimes, endTimes);
  }

  const handlerChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((formValue) => {
      return {
        ...formValue,
        [name]: e.target.value
      };
    });
    if (name === "m_overtime_types_id") {
      setFormData((formValue) => {
        return {
          ...formValue,
          m_overtime_types_id: e.target.value
        };
      });
    }
    if (name === "break_time") {
      setBreakTime(e.target.value);
      // Parse the selected break time into minutes
      const breakTimeInMinutes = parseInt(value?.split(":")[0]) * 60 + parseInt(value?.split(":")[1]);

      const newDurationInMinutes = diffInMinutes - breakTimeInMinutes/multiplyOvertimeBy;

      // Convert the new duration back to the "HH:mm" format
      const hours = Math.floor(newDurationInMinutes / 60);
      const minutes = newDurationInMinutes % 60;
      const newDuration = `${hours?.toString().padStart(2, "0")}:${minutes
        ?.toString()
        .padStart(2, "0")}`;

      setDuration(newDuration);
    }
    const selectedOvertimeId = e.target.value;
    const selectedOvertime = allOverTimeData?.find(
      (overtime) => overtime.id === parseInt(selectedOvertimeId)
    );

    if (selectedOvertime) {
      setMultiplyOvertimeBy(selectedOvertime?.multiplyOvertimeBy);
    }
  };

  const handlerDateChange = (e, name) => {
    if (name === "start_date") {
      setFormData((formValue) => {
        return {
          ...formValue,
          start_date: e
        };
      });
      setStartDate(e);
    }
    if (name === "start_time") {
      setFormData((formValue) => {
        return {
          ...formValue,
          ["start_time"]: moment(e)?.format("HH:mm")
        };
      });

      setStartTime(e);

      let endDays = new Date(
        moment(e)?.format("YYYY-MM-DD") + " " + "23:59:59"
      );
      setEndDays(endDays);

      if (endTime) {
        setStartTime(e);
        let difference = getTimeDifferenceCalculator(e, endTime);
        let hours = parseInt(difference / 60);
        let min = difference % 60;
        let duraTion = `${hours < 10 ? `0${hours}` : hours}:${
          min < 10 ? `0${min}` : min
        }`;
        setDuration(duraTion);
      }
    }

    if (name === "end_time") {
      var a = startTime
        ? new Date(startTime)?.getTime()
        : new Date()?.getTime();
      var b = new Date(e)?.getTime();
      if (a < b) {
        setError((errorValue) => {
          return {
            ...errorValue,
            endTimeError: ""
          };
        });

        setFormData((formValue) => {
          return {
            ...formValue,
            ["end_time"]: moment(e).format("HH:mm")
          };
        });
        setEndTime(e);

        if (startTime) {
          let difference = getTimeDifferenceCalculator(startTime, e);
          let hours = parseInt(difference / 60);
          let min = difference % 60;
          let duraTion = `${hours < 10 ? `0${hours}` : hours}:${
            min < 10 ? `0${min}` : min
          }`;

          setDuration(duraTion);
        }
      } else {
        setError((errorValue) => {
          return {
            ...errorValue,
            endTimeError: "please select greater time "
          };
        });
      }
    }
  };
  const getTimeDifferenceCalculator = (startTime, endTime) => {
    if (startTime === undefined) {
      let date_start = moment(over?.start_date).format("YYYY-MM-DD");
      let timeStart = new Date(date_start + " " + over.start_time);
      let timeDiff = endTime?.getTime() - timeStart?.getTime();
      let hourhsDifference = Math.round(timeDiff / 60000);
      return hourhsDifference;
    } else if (endTime === undefined) {
      let date_start = moment(over?.start_date).format("YYYY-MM-DD");
      let timeEnd = new Date(date_start + " " + over.end_time);
      let timeDiff = timeEnd?.getTime() - startTime?.getTime();
      let hourhsDifference = Math.round(timeDiff / 60000);
      return hourhsDifference;
    } else if (startTime !== undefined && endTime !== undefined) {
      let timeDiff = endTime?.getTime() - startTime?.getTime();
      let hourhsDifference = Math?.round(timeDiff / 60000);
      return hourhsDifference;
    }
  };

  const handlerChangCheck = (e) => {
    setFormData({
      ...formData,
      ["time_in_lieu"]: e.target.checked ? "1" : "0"
    });
  };
  const handlerChangCheckIsPaid = (e) => {
    setFormData({ ...formData, ["is_paid"]: e.target.checked ? "1" : "0" });
  };

  useEffect(() => {
    if (duration && multiplyOvertimeBy > 0) {
      const [hh, mm] = duration.split(":").map(Number);
      const totalMinutes = hh * 60 + mm;
      const multipliedMinutes = totalMinutes * multiplyOvertimeBy;
      const resultHours = Math.floor(multipliedMinutes / 60).toString().padStart(2, '0');
      const resultMinutes = (multipliedMinutes % 60).toString().padStart(2, '0');
      setResult(`${resultHours}:${resultMinutes}`);
    } 
  }, [duration, multiplyOvertimeBy]);

  useEffect(
    () => {
      if (isLoadingUpdate) {
        toastGeneralId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessUpdate) {
        toast.dismiss(toastGeneralId.current);
        toast.success("Overtime data is updated!", {
          render: "Overtime data is updated!",
          type: "success",
          autoClose: 2000,
          isLoading: false,
          theme: "colored"
        });
        let payload = `/overtimes/?page=${1}&employee_id=${employeeIdData}`;
        allOvertimeList(payload);
      }
      if (isErrorUpdate) {
        toast.dismiss(toastGeneralId.current);
        const toastMessage = apiError?.data?.message
          ? apiError?.data?.message
          : "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 2000,
          duplicate: false,
          isLoading: false
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessUpdate, isErrorUpdate, isLoadingUpdate]
  );

  const breakTimeData = [
    { value: "", label: "Select Break Time" },
    { value: "00:00", label: "No Break" },
    { value: "00:10", label: "10min" },
    { value: "00:15", label: "15min" },
    { value: "00:20", label: "20min" },
    { value: "00:25", label: "25min" },
    { value: "00:30", label: "30min" },
    { value: "00:35", label: "35min" },
    { value: "00:40", label: "40min" },
    { value: "00:45", label: "45min" }
  ];
  let start_date = moment(over.start_date).format("YYYY-MM-DD");

  let startTimeData = new Date(
    `${start_date} ${over.start_time}`
  )?.toISOString();
  let endTimeData = new Date(`${start_date} ${over.end_time}`)?.toISOString();
  const handlerSubmit = (e, over) => {
    e.preventDefault();

    //start time and end time in minutes
    const startMoment = moment(formData.start_time, "HH:mm");
    const endMoment = moment(formData.end_time, "HH:mm");

    //break in minutes
    const breakMinutes = moment.duration(formData?.break_time).asMinutes();

    //difference between start time and end time in minutes
    const durationWithoutBreak = endMoment.diff(startMoment, "minutes");

    //(duration * multiplyOvertimeBy)
    const multipliedDurationWithoutBreak = durationWithoutBreak * multiplyOvertimeBy;

    //(duration * multiplyOvertimeBy) - break 
    const multipliedDuration = (durationWithoutBreak * multiplyOvertimeBy) - breakMinutes;

    let value = {
      start_date: formData?.start_date !== null ? formData?.start_date : over?.date !== null ? over?.date : new Date(),
      start_time: formData?.start_time ? formData?.start_time : moment(startTimeData)?.format("HH:mm"),
      end_time: formData?.end_time ? formData?.end_time : moment(endTimeData)?.format("HH:mm"),
      duration: formData?.is_paid === "0" ? multipliedDuration.toString() : formData?.is_paid === "1" ? multipliedDurationWithoutBreak.toString() : over?.duration.toString,
      break_type: "20",
      break_time: formData?.break_time ? formData?.break_time : over?.break,
      m_overtime_types_id: formData?.m_overtime_types_id,
      time_in_lieu: formData.time_in_lieu ? formData.time_in_lieu : over.time_in_lieu,
      is_paid: formData.is_paid ? formData.is_paid : over.is_paid,
      company_id: companyId,
      employee_id: employeeIdData,
      notes: formData.notes,
      status: "0",
      overtime_id: over?.id
    };
    createMyOvertime(value);
  };

  return (
    <>
      <div className="header">
        <h3>Edit My Overtime & Timeback </h3>
      </div>
      <div className="content">
        <form>
          <div className="flex flex-wrap ">
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Overtime type</label>
                <select
                  id="editOvertimeType"
                  className="formControl"
                  value={
                    formData?.m_overtime_types_id
                      ? formData?.m_overtime_types_id
                      : over?.m_overtime_types_id
                  }
                  name="m_overtime_types_id"
                  onChange={(e) => handlerChange(e)}
                >
                  <FormSelect optionValue={"0"} optionName={"Select"} />;
                  {allOverTimeData?.length > 0
                    ? allOverTimeData?.map((overtime, key) => {
                        return (
                          <FormSelect
                            key={key}
                            optionValue={overtime?.id}
                            optionName={overtime?.overtime_name}
                          />
                        );
                      })
                    : null}
                </select>
                <div className="help-block" />
              </div>
            </div>
            <div className="md:w-1/2 md:pr-0 w-full mb-4 md:mb-0">
              <label className="formBlock	">Date </label>
              <DatePicker
                // showIcon
                dateFormat="dd/MM/yyyy"
                id="editDate"
                className="formControl"
                name="start_date"
                maxDate={new Date()}
                selected={
                  formData?.start_date !== undefined &&
                  formData?.start_date !== null
                    ? formData?.start_date
                    : over?.date !== undefined && over?.date !== null
                    ? over?.date
                    : new Date()
                }
                onChange={
                  (e) => handlerDateChange(e, "start_date")
                  //  setStartDate(e);
                }
                selectsStart
                startDate={startDate}
                locale="en-GB"
              />
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Time</label>
                <div className="flex">
                  <div className="w-full pr-4">
                    <DatePicker
                      className="formControl"
                      id="editStartTime"
                      onChange={(e) => handlerDateChange(e, "start_time")}
                      selected={startTime ? startTime : new Date(startTimeData)}
                      popperPlacement="top-start"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeFormat="HH:mm"
                      dateFormat="hh:mm aa"
                    />
                  </div>
                  <div className="w-full">
                    <DatePicker
                      className="formControl"
                      id="editEndTime"
                      selected={endTime ? endTime : new Date(endTimeData)}
                      onChange={(e) => handlerDateChange(e, "end_time")}
                      popperPlacement="top-start"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeFormat="HH:mm"
                      dateFormat="hh:mm aa"
                      minTime={
                        startTime ? setMinutes(startTime, 30) : startDate
                      }
                      maxTime={endDays ? endDays : startDate}
                      minDate={endDays ? endDays : startDate}
                    />
                  </div>
                </div>
                <div className="help-block text-red-700 mt-2">
                  {error?.startTimeError}
                </div>
              </div>
            </div>
            <div className="md:w-1/2  w-full">
              <div className="form-group mb-2 ">
                <label className="formBlock	">Break</label>
                <select
                  className="formControl"
                  name="break_time"
                  id="editTimeBreak"
                  defaultValue={over?.break}
                  onChange={(e) => handlerChange(e)}
                >
                  {breakTimeData?.map((breakTime, index) => {
                    return (
                      <FormSelect
                        key={index}
                        optionValue={breakTime?.value}
                        optionName={breakTime?.label}
                      />
                    );
                  })}
                  ;
                </select>
                <div className="help-block" />
              </div>
              <div className="form-group mb-6  flex">
                <label className="formBlock flex	">
                  <input
                    type="checkbox"
                    name="is_paid"
                    id="editPaid"
                    className="mr-2 w-5 h-5"
                    value={over.is_Paid}
                    defaultChecked={over?.is_Paid === "1" ? true : false}
                    onChange={(e) => handlerChangCheckIsPaid(e, "is_paid")}
                  />
                  Paid
                </label>
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-3 ">
                <label className="formBlock	">Duration (Hours)</label>
                <input
                  type="search"
                  id="editDuration"
                  className="formControl"
                  value={result}
                  disabled
                />
                <div className="help-block" />
              </div>
              <div className="form-group mb-6 ">
                <label className="formBlock flex	">
                  <input
                    type="checkbox"
                    id="editBookLieu"
                    className="mr-2 w-5 h-5"
                    name="time_in_lieu"
                    onChange={(e) => handlerChangCheck(e, "time_in_lieu")}
                    value={over?.time_in_lieu}
                    defaultChecked={over?.time_in_lieu === "1" ? true : false}
                  />
                  Book as time in Lieu
                </label>
                <div className="help-block" />
              </div>
            </div>

            <div className="w-full ">
              <div className="form-group mb-6 ">
                <label className="formBlock	">Notes </label>
                <FormTextarea
                  textName="notes"
                  inputID="editNotes"
                  value={over.notes}
                  handlerChange={handlerChange}
                />
                <div className="help-block" />
              </div>
            </div>
            <div className="lg:w w-full  ">
              <div className="w-full mt-4">
                <div className="form-group pt-4 pb-3 flex justify-center ">
                  <PrimaryButton
                    btnText=" Send for Approval"
                    Btnclass="btn save mr-2"
                    btnype="button"
                    buttonID="editSend"
                    onClick={(e) => handlerSubmit(e, over)}
                  />
                  <PrimaryButton
                    btnText="Cancel"
                    Btnclass="btn cancel"
                    btntype="button"
                    buttonID="editCancel"
                    onClick={() => closeModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditMyOvertime;
